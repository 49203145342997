<template>
  <div id="ops-calendar">
    <breadcrumb title="Data Management" subtitle="Lenovo Sales Ops Calendar" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row d-flex align-items-end mb-4">
                    <div class="col-lg-5">
                      <label class="text-label">Fiscal Year</label>
                      <select
                        id="year"
                        v-model="target_year"
                        class="form-control"
                        @change="onFilterChanged($event)"
                      >
                        <option v-for="(year, index) of fiscalYearList" :key="index">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-5">
                      <label class="text-label">Quarter</label>
                      <select
                        id="type"
                        v-model="target_quarter"
                        class="form-control"
                        @change="onFilterChanged($event)"
                      >
                        <option v-for="(type, index) of quarterList" :key="index" :value="type.id">
                          {{ type.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="toggleUploadModal"
                      >
                        <i class="fa fa-upload"></i> Upload
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <VueBootstrap4Table
                        :classes="classes"
                        :rows="dataList"
                        :columns="columns"
                        :config="config"
                        :total-rows="total"
                        @on-change-query="onChangeQuery"
                      >
                        <template slot="sort-asc-icon">
                          <i class="mdi mdi-sort-ascending"></i>
                        </template>
                        <template slot="sort-desc-icon">
                          <i class="mdi mdi-sort-descending"></i>
                        </template>
                        <template slot="no-sort-icon">
                          <i class="mdi mdi-sort-variant"></i>
                        </template>
                        <template slot="empty-results">
                          <div
                            v-if="!isDataLoading"
                            class="bold-text"
                          > No Data Found!
                          </div>
                          <div
                            v-if="isDataLoading"
                            class="loading mt-3"
                          ></div>
                        </template>
                      </VueBootstrap4Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BModal ref="ops-calendar-upload" size="xl" hide-footer title="Upload">
      <excelUploadForm
        :close-modal="toggleUploadModal"
        :validations="validateFields"
        :sample-file="sampleFile"
        :content="content"
        :set-post-data="setPostData"
        :show-data-list="true"
        :submit-button-text="'Submit'"
        :get-upload-file="getUploadFile"
        :file-columns="fileColumnStructure"
        :file-data="excelSampleData"
      />
    </BModal>
  </div>
</template>

<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { BModal } from "bootstrap-vue";
import excelUploadForm from "../../components/excel_upload/index";
import {
  GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST,
  UPLOAD_OPS_CALENDAR_REQUEST
} from "../../store/modules/ops-calendar/actions";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "../../store/modules/ui/actions";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';
import { FISCAL_YEAR_LIST } from "@/constants";

export default {
  name: "OpsCalendar",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    breadcrumb: breadcrumb,
    BModal,
    excelUploadForm: excelUploadForm
  },
  data() {
    return {
      sampleFile: "opsCalendarUpload",
      content:
        "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the colunm labels in the sample file and make sure the file extension is .xlsx file.",
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Target",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [],
        // per_page: 15,
        preservePageOnDataChange: true,
        pagination: false,
        pagination_info: false,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "month",
          label: "Month",
          column_text_alignment: "text-center"
        },
        {
          name: "week",
          label: "Week",
          column_text_alignment: "text-center"
        },
        {
          name: "weekStart",
          label: "Start Date",
          sort: true,
          initial_sort: true, 
          initial_sort_order: "asc",
          column_text_alignment: "text-center"
        },
        {
          name: "weekEnd",
          label: "End Date",
          sort: true,
          column_text_alignment: "text-center"
        },
      ],
      total: 0,
      dataList: [],
      target_quarter: null,
      target_year:null,
      quarterList: [],
      fiscalYearList: FISCAL_YEAR_LIST,
      isDataLoading: false,
      fileColumnStructure: [
        "Fiscal Year",
        "Quarter",
        "Month",
        "Week",
        "Start Date",
        "End Date"
      ],
      uploadData: [],
      excelSampleData: [["FY1920", "Q4", "January", "W3", "2019-01-15", "2019-01-21"]]
    };
  },
  methods: {
    setPostData(pdata) {
      let tdata = [];
      console.log("data", pdata)
      pdata.forEach((row, index) => {
        tdata[index] = {};
        tdata[index]["fiscalYear"] = row[0];
        tdata[index]["quarter"] = row[1];
        tdata[index]["month"] = row[2];
        tdata[index]["week"] = row[3];
        tdata[index]["weekStart"] = row[4];
        tdata[index]["weekEnd"] = row[5];
      });

      this.uploadData = tdata;
      if (this.uploadData.length > 0) {
        this.submitData();
      }
    },
    getUploadFile() {},
    submitData() {
      let pdata = this.uploadData;
      pdata.forEach(el => {
        el.year = Number(el.weekStart.match(/\d+/)[0]);
        el.quarterNumeric = Number(el.quarter.match(/\d+/)[0]);
        el.weekNumeric = Number(el.week.match(/\d+/)[0]);
        el.monthNumeric = this.getNumericMonth(el.month);
      })

      // console.log(pdata)
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Ops Calendar updates are submitting...",
        timeOut: true
      });
      this.$store.dispatch(UPLOAD_OPS_CALENDAR_REQUEST, pdata).then(res => {
        if (res && res.resultCode === 0) {
          this.getSalesDataList();
          this.toggleUploadModal();
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Ops Calendar successfully updated",
            timeOut: true
          });
        } else {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: "Invalid data. Please try again.",
            timeOut: true
          });
        }
      }).catch(err => {
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: err,
          timeOut: true
        });
      });
    },
    validateFields(value, field) {
      let isValid = false;

      function notEmptyField(val){
        return /^(?!\s*$).+/.test(val) && val !== null && val !== undefined
      }

      switch (field) {
        case "Fiscal Year":
          isValid = notEmptyField(value);
          break;
        case "Quarter":
          isValid = notEmptyField(value);
          break;
        case "Month":
          isValid = notEmptyField(value)
          break;
        case "Week":
          isValid = notEmptyField(value)
          break;
        case "Start Date":
          isValid = notEmptyField(value)
          break;
        case "End Date":
          isValid = notEmptyField(value)
          break;
        default:
          isValid = true;
          break;
      }

      if (!isValid) {
        value = '<span class="invalid-field">' + value + "</span>";
      }

      return {
        value: value,
        valid: isValid
      };
    },
    toggleUploadModal() {
      this.$refs["ops-calendar-upload"].toggle();
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getSalesDataList({});
    },
    getMonth(num){
      const month = new Array;
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month[num];
    },
    getNumericMonth(monthr){
      const month = new Array;
      month[1] = "January";
      month[2] = "February";
      month[3] = "March";
      month[4] = "April";
      month[5] = "May";
      month[6] = "June";
      month[7] = "July";
      month[8] = "August";
      month[9] = "September";
      month[10] = "October";
      month[11] = "November";
      month[12] = "December";
      return month.indexOf(month.find(el => el && el.match(new RegExp('s?' + monthr))));
    },

    getSalesDataList(postd) {
      this.isDataLoading = true;
      this.$store.dispatch(GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST, postd).then(res => {
        if (res.resultCode === 0) {
          res.content.map(item => {
            item["month"] = this.getMonth(item.monthNumeric);
          })
          this.dataList = res.content;
          this.isDataLoading = false;
        }
      });
    },
    setDefaultData() {
      let tdata = {};
      if (!this.target_year) {
        this.target_year = this.fiscalYearList.find(item => item === defaultFiscalYear());
      }
      if (!this.target_quarter) {
        this.target_quarter = this.quarterList.find(item => item.text === defaultQuarter())["id"];
      }
      tdata["year"] = this.target_year;
      tdata["quarter"] = this.target_quarter;
      this.getSalesDataList(tdata);
    },
    onFilterChanged() {
      const tdata = {
        year: this.target_year,
        quarter: this.target_quarter,
      };
      this.getSalesDataList(tdata);
    },
    getquarterList() {
      this.quarterList = [
        { id: 1, text: "Q1" },
        { id: 2, text: "Q2" },
        { id: 3, text: "Q3" },
        { id: 4, text: "Q4" }
      ];
    },
  },
  mounted() {
    this.getquarterList();
    this.setDefaultData();
  }
};
</script>
