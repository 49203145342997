<template>
  <div id="pending-list">
    <breadcrumb title="Dashboard" subtitle="Change Requests" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row">
                <div
                    class="col-lg-12 add-employee-button d-flex align-items-center"
                >
                  <b-field class="mr-auto" grouped group-multiline>
                    <div
                        class="control clickable"
                        @click="filterList('normal')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large" v-text="$ml.get('requests_list_title')"></b-tag>
                        <b-tag type="is-info" size="is-large">
                          <strong>{{ pendingList.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                    <div
                        class="control clickable"
                        @click="filterList('pending')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large" v-text="$ml.get('pending_status_text')"></b-tag>
                        <b-tag type="is-warning" size="is-large">
                          <strong style="color:white">{{ pendingListPending.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                    <div
                        class="control clickable"
                        @click="filterList('approved')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large" v-text="$ml.get('approve_status_text')"></b-tag>
                        <b-tag type="is-success" size="is-large">
                          <strong>{{ pendingListApproved.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                    <div
                        class="control clickable"
                        @click="filterList('rejected')"
                    >
                      <b-taglist attached>
                        <b-tag type="is-dark" size="is-large" v-text="$ml.get('rejected_status_text')"></b-tag>
                        <b-tag type="is-danger" size="is-large">
                          <strong>{{ pendingListRejected.length }}</strong>
                        </b-tag>
                      </b-taglist>
                    </div>
                  </b-field>
                  <br>
                  <br>
                  <br>
                </div>

                <!-- <div v-if="isCapAdmin || isCap || isCountryAdmin || isGLNCountryAdmin" class="col-lg-11">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                    ref="country"
                    v-model="filters.countrySelected"
                    :searchable="false"
                    :disabled="isCountryAdmin || isGLNCountryAdmin ? true : false"
                    :options="countryOptions"
                    label="name"
                    track-by="value"
                    :loading="!countryOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-4">
                  <label class="text-label" v-text="$ml.get('sd_title_fiscal_year')"></label>
                  <Multiselect
                    ref="year"
                    v-model="filters.yearSelected"
                    :searchable="false"
                    :options="yearOptions"
                    label="name"
                    track-by="value"
                    :loading="!yearOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-3">
                  <label class="text-label" v-text="$ml.get('sd_title_quarter')"></label>
                  <Multiselect
                    ref="quarter"
                    v-model="filters.quarterSelected"
                    :searchable="false"
                    :options="quarterOptions"
                    label="name"
                    track-by="value"
                    :loading="!quarterOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-1 pl-0">
                  <button class="btn btn-sm btn-primary" style="margin-top:24px;height:40px;width: 100%" :disabled="isDataLoading" @click="getList()">
                    <span v-text="$ml.get('button_search_text')"></span>
                  </button>
                </div> -->
                <div class="col-lg-12 mt-5 d-inline-flex justify-content-between mb-4">
                  <div class="b-tabs mb-0 w-100">
                    <nav class="tabs mb-0">
                      <ul class="mb-0">
                        <b-nav-item v-if="canViewTabList.includes(0)" :class="activeTab === 1 && 'is-active'" @click="activeTab = 1; getList(1)">
                          <span v-text="$ml.get('dealer_title')"></span>
                          <span v-if="pendingApprovalCountDealer > 0 && (isCap || isCapAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                        <b-nav-item v-if="canViewTabList.includes(1)" :class="activeTab === 0 && 'is-active'" @click="activeTab = 0; getList(0)">
                          <span v-text="$ml.get('outlet_title')"></span>
                          <span v-if="pendingApprovalCountDealerOutlet > 0 && (isCap || isCapAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                        <b-nav-item v-if="canViewTabList.includes(2)" :class="activeTab === 2 && 'is-active'" @click="activeTab = 2; getList(2)">
                          <span v-text="$ml.get('promoter_supervisor_title')"></span>
                          <span v-if="pendingApprovalCountPromoterLeader > 0 && (isCap || isCapAdmin) || pendingApprovalCountPromoterLeaderUpdate > 0 && (isCountryAdmin || isGLNCountryAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                        <b-nav-item v-if="canViewTabList.includes(3)" :class="activeTab === 3 && 'is-active'" @click="activeTab = 3; getList(3)">
                          <span v-text="$ml.get('promoter_title')"></span>
                          <span v-if="pendingApprovalCountPromoter > 0 && (isCap || isCapAdmin) || pendingApprovalCountPromoterUpdate > 0 && (isCountryAdmin || isGLNCountryAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                        <b-nav-item v-if="canViewTabList.includes(4)" :class="activeTab === 4 && 'is-active'" @click="activeTab = 4; getList(4)">
                          <span>Merchandiser</span>
                          <span v-if="pendingApprovalCountMerchandiser > 0 && (isCap || isCapAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                        <b-nav-item v-if="canViewTabList.includes(5)" :class="activeTab === 5 && 'is-active'" @click="activeTab = 5; getList(5)">
                          <span>Merchandiser Leader</span>
                          <span v-if="pendingApprovalCountMerchandiserLeader > 0 && (isCap || isCapAdmin)" class="unread-signal-nav pull-right" />
                        </b-nav-item>
                      </ul>
                    </nav>
                  </div>
                </div>


              </div>
              <br />

              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                    ref="change-requests-table"
                    :classes="classes"
                    :rows="filterState"
                    :columns="activeTab === 0 ? columnsOutlet : 
                              activeTab === 1 ? columnsDealer : 
                              activeTab === 2 ? columnsPromoterLeader : 
                              activeTab === 3 ? columnsPromoter : 
                              activeTab === 4 ? columnsMerchandiser : 
                              activeTab === 5 ? columnsMerchandiserLeader :
                              null"
                    :config="config"
                    :total-rows="total"
                    @on-change-query="onChangeQuery"
                    @refresh-data="getList"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isDataLoading"
                        class="bold-text"
                        v-text="$ml.get('no_pending_approvals_found')"
                      ></div>
                      <div
                        v-if="isDataLoading"
                        class="loading mt-3"
                      ></div>
                    </template>
                    <template slot="status" slot-scope="props">
                      <div v-if="(isCap || isCapAdmin || ((isGLNCountryAdmin || isCountryAdmin) && (activeTab === 3 || activeTab === 2) && !props.row.newApprovalRequest && props.row.updateField !== 'Promoter Status' && props.row.updateField !== 'Promoter Outlet Assignment')) && props.row.status === 'Pending'" class="inner-buttons position-relative">
                        <button
                          type="button"
                          class="btn btn-info"
                          @click="doAction(0, props.row)"
                        >
                          <i class="fa fa-check color-info"></i>
                          {{ $ml.get('button_approve_text') }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-warning"
                          @click="doAction(1, props.row)"
                        >
                          <i class="fa fa-times color-warning"></i>
                          {{ $ml.get('button_reject_text') }}
                        </button>
                      </div>
                      <lnvStatusTextBox v-else :status="props.row.status" />
                    </template>

                    <template slot="id" slot-scope="props">
                      <span>{{ props.row.id }}</span>
                    </template>

                    <template slot="submittedOn" slot-scope="props">
                      <span>{{ changeDate(props.row.submittedOn) }}</span>
                    </template>

                    <template slot="remarks" slot-scope="props">
                      <span v-if="props.row.newApprovalRequest">
                        <p> <b>New: </b> Registration approval </p>
                      </span>
                      <span v-else-if="props.row.updateField === 'Promoter Status' || props.row.updateField === 'Merchandiser Status' || props.row.updateField === 'Merchandiser Leader Status'">
                        <p> <b>Status Update: </b>{{ props.row.oldValue === 'true' ? 'ACTIVE' : 'DEACTIVATED' }} &#10142;  {{ props.row.newValue === 'true' ? 'ACTIVE' : 'DEACTIVATED' }}</p>
                      </span>
                      <span v-else-if="props.row.updateField === 'Status' || props.row.updateField === 'Dealer Status'">
                        <p> <b>Status Update: </b>{{ props.row.oldValue }} &#10142;  {{ props.row.newValue }}</p>
                      </span>
                      <span v-else>
                        <p v-if="activeTab === 1 || activeTab === 0">
                          <b>Update: {{ activeTab === 1 ? 'Dealer Information' : 'Outlet Information' }} </b>
                        </p>
                        <p v-else>
                          {{ props.row.updateField }} {{ props.row.updateField !== 'Promoter Outlet Assignment' ? '=' : '' }} {{ props.row.oldValue }} <br /> &#10142;  {{ props.row.newValue }}
                        </p>
                      </span>
                    </template>

                    <template slot="dealerInfo" slot-scope="props">
                      <div class="inner-data">
                        <span class="bold-text">{{ props.row.dealerName }}</span>
                        <br />
                        <span>{{ props.row.dealerLppCode }}</span>
                      </div>
                    </template>

                    <template slot="outletInfo" slot-scope="props">
                      <div class="inner-data">
                        <span class="bold-text">{{ props.row.dealerOutletName }}</span>
                        <br />
                        <span>{{ props.row.dealerOutletCode }}</span>
                        <br />
                      </div>
                    </template>        

                    <template slot="promoterInfo" slot-scope="props">
                      <div class="inner-data">
                        <span class="bold-text">{{ props.row.userFullName }}</span>
                        <br />
                        <span>{{ props.row.userEmail }}</span>
                        <br />
                      </div>
                    </template>

                    <template slot="merchandiserInfo" slot-scope="props">
                      <div class="inner-data">
                        <span class="bold-text">{{ props.row.userFullName }}</span>
                        <br />
                        <span>{{ props.row.userEmail }}</span>
                        <br />
                      </div>
                    </template>    

                    <template slot="merchandiserLeaderInfo" slot-scope="props">
                      <div class="inner-data">
                        <span class="bold-text">{{ props.row.userFullName }}</span>
                        <br />
                        <span>{{ props.row.userEmail }}</span>
                        <br />
                      </div>
                    </template>    

                    <template slot="actionBy" slot-scope="props">
                      <div class="inner-data">
                        <p v-if="props.row.status !== 'Pending'">{{ props.row.actionBy }}</p>
                      </div>
                    </template>        
                    <template slot="details" slot-scope="props">
                      <div v-if="showViewButton(props)" class="inner-data">
                        <button
                          type="button"
                          class="btn btn-warning"
                          @click="toggleModal(props.row)"
                        >
                          {{ $ml.get('button_view_text') }}
                        </button>
                      </div>
                    </template>        
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BModal ref="details-modal" size="lg" hide-footer :title="modalTitle">
        <outletForm
          v-if="activeTab === 0"
          :close-modal="toggleModal"
          :selected-outlet="outlet"
          current-action="edit"
          :can-edit="true"
        />
        <dealerForm
          v-else-if="activeTab === 1"
          :close-modal="toggleModal"
          :selected-dealer="dealer"
          current-action="edit"
          :can-edit="true"
        />
        <leaderForm
          v-else-if="activeTab === 2"
          :close-modal="toggleModal"
          :leader-selected="promoterLeader"
          current-action="edit"
          :can-edit="true"
        />
        <promoterForm
          v-else-if="activeTab === 3"
          :close-modal="toggleModal"
          :promoter-selected="promoter"
          current-action="edit"
          :can-edit="true"
        />
        <merchandiserForm
          v-else-if="activeTab === 4"
          :close-modal="toggleModal"
          :detail-passed-in="merchandiser"
          action="view"
          :can-edit="false"
        />
        <merchandiserLeaderForm
          v-else-if="activeTab === 5"
          :close-modal="toggleModal"
          :detail-passed-in="merchandiserLeader"
          action="view"
          :can-edit="false"
        />
      </BModal>
    </div>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import outletForm from "../../components/dealer-outlets/outlet_form";
import dealerForm from "../../components/dealer/dealer_form";
import promoterForm from "../../components/staff-management/promoter_form";
import leaderForm from "../../components/staff-management/promo_leader_form";
import merchandiserForm from "../../components/staff-management/merchandiser_form";
import merchandiserLeaderForm from "../../components/staff-management/merchandiser_leader_form";
import {
  PENDING_DEALER_LIST_REQUEST,
  PENDING_DEALER_OUTLET_LIST_REQUEST,
  PENDING_PROMOTER_LEADER_LIST_REQUEST,
  PENDING_PROMOTER_LIST_REQUEST,
  PENDING_MERCHANDISER_LIST_REQUEST,
  PENDING_MERCHANDISER_LEADER_LIST_REQUEST,
  UPDATE_APPROVAL_PROMOTER_REQUEST,
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST,
  UPDATE_APPROVAL_DEALER_REQUEST,
  UPDATE_APPROVAL_MERCHANDISER_REQUEST,
  UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST,
  UPDATE_APPROVAL_PROM_REQUEST
} from "../../store/modules/staff/actions";
import { GET_UNREAD_MESSAGE_COUNT_REQUEST } from "../../store/modules/message/actions";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import lnvStatusTextBox from "../../components/ui/statusTextBox";
import { EventBus } from "@/utils/EventBus";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS
} from "../../store/modules/ui/actions";
import { convertDateFormat} from "../../utils/helpers";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import { BModal } from "bootstrap-vue";
import $ from 'jquery';

export default {
  name: "PromoterPendingApproval",
  components: {
    breadcrumb: breadcrumb,
    VueBootstrap4Table: VueBootstrap4Table,
    BModal: BModal,
    lnvStatusTextBox: lnvStatusTextBox,
    outletForm: outletForm,
    dealerForm: dealerForm,
    promoterForm: promoterForm,
    leaderForm: leaderForm,
    merchandiserForm: merchandiserForm,
    merchandiserLeaderForm: merchandiserLeaderForm
  },
  computed: {
    filterState() {
      if (this.normal) {
        return this.pendingList;
      } else if (this.pending) {
        return this.pendingListPending;
      } else if (this.approved) {
        return this.pendingListApproved;
      } else if (this.rejected) {
        return this.pendingListRejected;
      } else {
        return false;
      }
    },
    pendingApprovalCountDealer: {
      get: function () {
        return this.$store.getters.pendingApprovalCountDealer;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountDealer;
      }
    },
    pendingApprovalCountDealerOutlet: {
      get: function () {
        return this.$store.getters.pendingApprovalCountDealerOutlet;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountDealerOutlet;
      }
    },
    pendingApprovalCountPromoter: {
      get: function () {
        return this.$store.getters.pendingApprovalCountPromoter;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountPromoter;
      }
    }, 
    pendingApprovalCountPromoterLeader: {
      get: function () {
        return this.$store.getters.pendingApprovalCountPromoterLeader;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountPromoterLeader;
      }
    }, 
    pendingApprovalCountPromoterUpdate: {
      get: function () {
        return this.$store.getters.pendingApprovalCountPromoterUpdate;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountPromoterUpdate;
      }
    }, 
    pendingApprovalCountPromoterLeaderUpdate: {
      get: function () {
        return this.$store.getters.pendingApprovalCountPromoterLeaderUpdate;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountPromoterLeaderUpdate;
      }
    },
    pendingApprovalCountMerchandiser: {
      get: function () {
        return this.$store.getters.pendingApprovalCountMerchandiser;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountMerchandiser;
      }
    }, 
    pendingApprovalCountMerchandiserLeader: {
      get: function () {
        return this.$store.getters.pendingApprovalCountMerchandiserLeader;
      },
      set: function () {
        return this.$store.getters.pendingApprovalCountMerchandiserLeader;
      }
    }, 
  },
  data() {
    return {
      config: {
        emitQuery: true,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Pending Approvals",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey",
        deep_global_search: true,
        deep_column_sort: true
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columnsDealer: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "dealerInfo",
          label: "Dealer Info",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      columnsOutlet: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "outletInfo",
          label: "Outlet Info",
          width: "15%",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      columnsPromoter: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "promoterInfo",
          label: "Promoter Info",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      columnsPromoterLeader: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "promoterInfo",
          label: "Promoter Supervisor Info",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      columnsMerchandiser: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "5%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "merchandiserInfo",
          label: "Merchandiser Info",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      columnsMerchandiserLeader: [
        {
          name: "id",
          label: "No",
          visibility: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sort: true
          // initial_sort: true, // "false" by default
          // initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "submittedBy",
          label: "Requested by",
          sort: true,
          width: "5%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "userRole",
          label: "User Role",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "merchandiserLeaderInfo",
          label: "Merchandiser Leader Info",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "submittedOn",
          label: "Request Date",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "remarks",
          label: "Description",
          sort: true,
          width: "35%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actionBy",
          label: "Action By",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          sortCaseSensitive: false
        },
        {
          name: "details",
          label: "Details",
          sort: true,
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "14%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      total: 0,
      pendingList: [],
      pendingListPending: [],
      pendingListApproved: [],
      pendingListRejected: [],
      normal: true,
      pending: false,
      approved: false,
      rejected: false,
      isCapAdmin: this.$store.getters.isCapAdmin,
      isCap: this.$store.getters.isCap,
      isGLNCountryAdmin: this.$store.getters.isGLNCountryAdmin,
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isPromoLeader: this.$store.getters.isPromoLeader,
      isMercLeader: this.$store.getters.isMercLeader,
      countries: [],
      // search: {},
      isCountriesLoading: false,
      isDataLoading: false,
      outlet: {},
      dealer: {},
      promoterLeader: {},
      promoter: {},
      merchandiser: {},
      merchandiserLeader: {},
      modalTitle: "",
      // yearOptions: [],
      // quarterOptions: [],
      // filters: {
      //   countrySelected: {},
        // yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        // quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      // },
      activeTab: 0,
      canViewTabList: [],
    };
  },
  watch: {
    pendingApprovalCountDealer: function(newVal) {
      this.pendingApprovalCountDealer = newVal;
    },
    pendingApprovalCountDealerOutlet: function(newVal) {
      this.pendingApprovalCountDealerOutlet = newVal;
    },
    pendingApprovalCountPromoter: function(newVal) {
      this.pendingApprovalCountPromoter = newVal;
    },
    pendingApprovalCountPromoterLeader: function(newVal) {
      this.pendingApprovalCountPromoterLeader = newVal;
    },
    pendingApprovalCountPromoterUpdate: function(newVal) {
      this.pendingApprovalCountPromoterUpdate = newVal;
    },
    pendingApprovalCountPromoterLeaderUpdate: function(newVal) {
      this.pendingApprovalCountPromoterLeaderUpdate = newVal;
    },
  },
  methods: {
    toggleModal(item){
      if(this.activeTab === 0){
        this.outlet = {...item};
        this.outlet.firstApprovalId = item.id
        this.outlet.id = item.profileId;
        this.modalTitle = "Outlet Details"
      } else if(this.activeTab === 1){
        this.dealer = {...item};
        this.dealer.firstApprovalId = item.id
        this.dealer.id = item.profileId;
        this.modalTitle = "Dealer Details"
      } else if(this.activeTab === 2){
        this.promoterLeader = {...item};
        this.promoterLeader.changeRequest = true;
        this.promoterLeader.id = item.accId;
        this.modalTitle = "Promoter Leader Details"
      } else if(this.activeTab === 3){
        this.promoter = {...item};
        this.promoter.changeRequest = true;
        this.promoter.id = item.profileId;
        this.modalTitle = "Promoter Details"
      } else if(this.activeTab === 4) {
        if(item) {
          this.merchandiser = {...item};
          this.merchandiser.changeRequest = true;
          this.merchandiser.id = item.profileId;
        }
        this.modalTitle = "Merchandiser Details"
      } else if(this.activeTab === 5) {
        if(item) {
          this.merchandiserLeader = {...item};
          this.merchandiserLeader.changeRequest = true;
          this.merchandiserLeader.id = item.profileId;
        }
        this.modalTitle = "Merchandiser Leader Details"
      }
      this.$refs["details-modal"].toggle("#toggle-btn");
    },
    changeDate(date) {
      return convertDateFormat(date, "YYYY-MM-DD");
    },
    doAction(action, row) {
      let id = row.id
      let newApprovalRequest = row.newApprovalRequest
      let profileId = row.profileId

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: this.$ml.get("request_submitting")
      });
      let pdata = {};
      pdata.id = newApprovalRequest ? profileId : id;
      pdata.action = action === 0 ? true : false;
      let url;
      if(newApprovalRequest){
        if(this.activeTab === 0){
          url = UPDATE_APPROVAL_DEALER_OUTLET_REQUEST;
        } else if(this.activeTab === 1){
          url = UPDATE_APPROVAL_DEALER_REQUEST;
        } else if(this.activeTab === 4) {
          url = UPDATE_APPROVAL_MERCHANDISER_REQUEST;
        } else if(this.activeTab === 5) {
          url = UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST;
        } else {
          url = UPDATE_APPROVAL_PROM_REQUEST;
        }
      } else {
        url = UPDATE_APPROVAL_PROMOTER_REQUEST;
      }
      this.$store.dispatch(url, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Request is successfully updated"
          });
          EventBus.$emit("getUnreadData", 1);
          
          this.pendingList.forEach((el) => {
            if (el.id === row.id) {
              el.status = action === 0 ? "Approved" : "Rejected"
            }
          })
          this.refreshListLocally()
          this.$refs["change-requests-table"].customEditQueryParams(this.queryParams);
          this.refreshFilterStateKey++
          this.getUnreadMsgCount()

        } else {
          this.isDataLoading = false;
        }
      });
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getList();
    },
    // doSearch() {
    //   this.countryId = this.search.countryId ? this.search.countryId.id : null;
    //   this.getList(this.activeTab);
    // },
    getList(activeTab) {
      this.isDataLoading = true;
      let pdata = {};
      this.pendingList = [];
      // if (this.isCapAdmin && this.isCap && this.isCountryAdmin && this.isGLNCountryAdmin && !this.countryId) {
      //   this.$store.dispatch(NOTIFICATION_ERROR, {
      //     message: "Select a country"
      //   });
      // }
      if (!this.isCapAdmin && !this.isCap) {
        pdata.ccf = this.$store.getters.getCountryCode;
      }

      if(!activeTab){
        activeTab = this.activeTab;
      }
      let url;
      if(activeTab === 0){
        url = PENDING_DEALER_OUTLET_LIST_REQUEST;
      } else if(activeTab === 1){
        url = PENDING_DEALER_LIST_REQUEST;
      } else if(activeTab === 2){
        url = PENDING_PROMOTER_LEADER_LIST_REQUEST;
      } else if(activeTab === 3){
        url = PENDING_PROMOTER_LIST_REQUEST;
      } else if(activeTab === 4){
        url = PENDING_MERCHANDISER_LIST_REQUEST;
      } else if(activeTab === 5){        
        url = PENDING_MERCHANDISER_LEADER_LIST_REQUEST;
      } 
      this.getUnreadMsgCount()
      this.$store.dispatch(url, pdata).then(res => {
        if (res.res.content && res.res.resultCode === 0) {
          if(
            (res.list === 'outlet' && this.activeTab === 0) || 
            (this.activeTab === 1 && res.list === 'dealer') || 
            (this.activeTab === 2 && res.list === 'promoter-leader') || 
            (this.activeTab === 3 && res.list === 'promoter') ||
            (this.activeTab === 4 && res.list === 'merchandiser') ||
            (this.activeTab === 5 && res.list === 'merchandiserLeader')
          ){
            const formattedContent = [...res.res.content];
            formattedContent.forEach(el => 
              el['sortRemarkKey'] = el.newApprovalRequest ? 'New' : el.updateField === 'Promoter Status' ? 'Status Update' : 'Update');
            
            this.refreshListLocally(formattedContent)

            this.total = this.pendingList.length;
            this.isDataLoading = false
          }
        } else {
          this.isDataLoading = false;
        }
      }).catch(() => {
        this.isDataLoading = false;
      });
    },
    getUnreadMsgCount() {
      let pdata1 = {
        countryId: this.$store.getters.getCountryId
      };
      this.$store.dispatch(GET_UNREAD_MESSAGE_COUNT_REQUEST, pdata1).then(res => {
        if (res.resultCode === 0) {
          this.pendingApprovalCountDealer = res.content.pendingApprovalCountDealer;
          this.pendingApprovalCountDealerOutlet = res.content.pendingApprovalCountDealerOutlet;
          this.pendingApprovalCountPromoter = res.content.pendingApprovalCountPromoter;
          this.pendingApprovalCountPromoterLeader = res.content.pendingApprovalCountPromoterLeader;
          this.pendingApprovalCountPromoterUpdate = res.content.pendingApprovalCountPromoterUpdate;
          this.pendingApprovalCountPromoterLeaderUpdate = res.content.pendingApprovalCountPromoterLeaderUpdate;
          this.pendingApprovalCountMerchandiser = res.content.pendingApprovalCountMerchandiser;
          this.pendingApprovalCountMerchandiserLeader = res.content.pendingApprovalCountMerchandiserLeader;
        }
      });
    },
    refreshListLocally(listFromApi = null) {
      const list = listFromApi || this.pendingList

      let status = ['Pending', 'Rejected', 'Approved'];
      this.pendingList = list.sort(function (a, b) {
        return status.indexOf(a.status) - status.indexOf(b.status) || b.submittedOn - a.submittedOn;
      });

      this.getStats();
    },
    getStats() {
      this.pendingListPending = [];
      this.pendingListApproved = [];
      this.pendingListRejected = [];

      this.pendingListPending = this.pendingList.filter(list => {
        if (list.status == "Pending") {
          return list;
        }
      });

      this.pendingListApproved = this.pendingList.filter(list => {
        if (list.status == "Approved") {
          return list;
        }
      });

      this.pendingListRejected = this.pendingList.filter(list => {
        if (list.status == "Rejected") {
          return list;
        }
      });
    },
    filterList(list) {
      if (list == "normal") {
        this.normal = true;
        this.pending = false;
        this.approved = false;
        this.rejected = false;
      } else if (list == "pending") {
        this.normal = false;
        this.pending = true;
        this.approved = false;
        this.rejected = false;
      } else if (list == "approved") {
        this.normal = false;
        this.pending = false;
        this.approved = true;
        this.rejected = false;
      } else if (list == "rejected") {
        this.normal = false;
        this.pending = false;
        this.approved = false;
        this.rejected = true;
      }
    },
    showViewButton(props) {
      const check1 = props.row.newApprovalRequest 
        || props.row.updateField === 'Promoter Status' 
        || props.row.updateField === 'Dealer Status'
        || props.row.updateField === 'Merchandiser Status'
        || props.row.updateField === 'Merchandiser Leader Status'
        || props.row.updateField === 'Status'
      const check2 = (props.row.newApprovalRequest == false 
      && props.row.oldValue != "ACTIVE" 
      && props.row.newValue != "ACTIVE" 
      && props.row.oldValue != "DEACTIVATED" 
      && props.row.newValue != "DEACTIVATED")
      return check1 || check2
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
        const countryCode = this.isCap || this.isCapAdmin ? "CAP" : this.$store.getters.getCountryCode;
        this.filters.countrySelected = res.content.countryDropdown.find(country => country.value === countryCode);
      }
    },
    limitShowTab() {
      this.canViewTabList = [1, 0, 2, 3, 4, 5] // default all can see

      if (this.isPromoLeader) {
        this.canViewTabList = [3, 2]
      } else if (this.isMercLeader) {
        this.canViewTabList = [4]
      }

      // the first element will be the default tab
      this.activeTab = this.canViewTabList[0]
    }
  },
  async mounted() {
    $("#main-wrapper").toggleClass("menu-toggle");
    $(".hamburger").toggleClass("is-active");
    this.limitShowTab()
    if(this.isCapAdmin || this.isCap){
      this.columnsDealer.splice(1, 0, {
        name: "submitterCountryCode",
        label: "CTRY",
        sort: true,
        width: "10%",
        row_text_alignment: "text-left",
        column_text_alignment: "text-left"
      })
      this.columnsOutlet.splice(1, 0, {
        name: "submitterCountryCode",
        label: "CTRY",
        sort: true,
        width: "10%",
        row_text_alignment: "text-left",
        column_text_alignment: "text-left"
      })
      this.columnsPromoter.splice(1, 0, {
        name: "submitterCountryCode",
        label: "CTRY",
        sort: true,
        width: "10%",
        row_text_alignment: "text-left",
        column_text_alignment: "text-left"
      })
      
    }
    this.getList(this.activeTab);
  }
};
</script>