<template>
  <div id="promoter-outlet-list">
    <p
      v-if="pendingApprovalStatus"
      class="required-title"
      v-text="$ml.get('promoter_pending_approval_request')"
    ></p>
    <div class="row">
      <div class="col-lg-12">
        <VueBootstrap4Table
          :classes="classes"
          :rows="promoterOutletList"
          :columns="columns"
          :config="config"
          :total-rows="total"
          @on-change-query="onChangeQuery"
          @refresh-data="getPromoterOutletList"
        >
          <template slot="sort-asc-icon">
            <i class="mdi mdi-sort-ascending"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="mdi mdi-sort-descending"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="mdi mdi-sort-variant"></i>
          </template>
          <template slot="empty-results">
            <div
              v-if="isLoadingOutlets"
              class="loading mt-3"
            ></div>
            <div v-else>
              No Outlets found
            </div>
          </template>

          <template slot="id" slot-scope="props">
            <span>{{ props.row.id }}</span>
          </template>

          <template slot="dealerName" slot-scope="props">
            <div class="inner-data">
              <span class="bold-text">{{ props.row.dealerName }}</span>
              <br />
              <span>{{ props.row.lppCode }}</span>
            </div>
          </template>
          <template slot="dealerOutletCode" slot-scope="props">
            <div class="inner-data">
              <span>{{ props.row.dealerOutletCode || props.row.dealerCode }}</span>
            </div>
          </template>
          <template slot="name" slot-scope="props">
            <div class="inner-data">
              <span class="bold-text">{{ props.row.name || props.row.outletName }}</span>
              <br />
              <!-- <span>{{ props.row.dealerCode }}</span> -->
              <br />
              <span>
                <i class="fa fa-map-marker"></i>
                {{ props.row.address || props.row.outletAddress }}
              </span>
            </div>
          </template>

          <template slot="Action" slot-scope="props">
            <div class="d-flex" style="flex-direction: column">
              <span v-if="props.row.assigned" style="line-height: 1.5">Start Week: {{ props.row.startWeek }}</span>
              <span v-if="props.row.assigned" style="line-height: 1.5">End Week: {{ props.row.endWeek }}</span>
            </div>
            <button
              v-if="!props.row.assigned"
              class="btn btn-success btn-xs"
              @click="assignOutlet(props.row, 1)"
            >
              <i class="fa fa-plus"></i> ASSIGN
            </button>
            <button
              v-if="props.row.assigned"
              class="btn btn-warning btn-xs mt-3"
              @click="assignOutlet(props.row, 2)"
            >
              <i class="fa fa-edit"></i> EDIT
            </button>
          </template>
        </VueBootstrap4Table>
      </div>
      <BModal
        ref="assign-outlet-modal"
        size="lg"
        hide-footer
        :title="type === 1 ? 'Assign Outlet (Start & End Week)' : 'Edit Outlet (Start & End Week)'"
      >
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-lg-12">
              <p
                class="required-title"
                v-text="$ml.get('form_required_title')"
              ></p>
            </div>
            <div class="col-lg-6">
              <div class="form-group flex-grow-1">
                <label class="text-label" v-text="$ml.get('form_promoter_start_week')"></label>
                <span class="required-star">*</span>
                <input
                  id="startWeek"
                  v-model="startWeek"
                  name="startWeek"
                  type="number"
                  class="form-control"
                  placeholder
                  @keyup="formErrors.startWeek = ''"
                />
                <span v-if="formErrors.startWeek" class="invalid-field">{{ formErrors.startWeek }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group flex-grow-1">
                <label class="text-label" v-text="$ml.get('form_promoter_end_week')"></label>
                <span class="required-star">*</span>
                <input
                  id="endWeek"
                  v-model="endWeek"
                  name="endWeek"
                  type="number"
                  class="form-control"
                  placeholder
                  @keyup="formErrors.endWeek = ''"
                />
                <span v-if="formErrors.endWeek" class="invalid-field">{{ formErrors.endWeek }}</span>
              </div>
            </div>
            <div class="col-lg-12">
              <button type="submit" class="btn btn-secondary pull-right">
                <span
                  v-text="type === 1 ? $ml.get('form_promoter_assign_outlet') : $ml.get('form_promoter_edit_outlet')"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="assignOutlet()"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </BModal>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { BModal } from "bootstrap-vue";

import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { OUTLETS_LIST_WITH_DEALERS_REQUEST,  } from "../../store/modules/outlet/actions";
import {GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG} from "../../store/modules/staff/actions";

import { POST_PROMOTER_OUTLET_REQUEST } from "../../store/modules/staff/actions";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS
} from "../../store/modules/ui/actions";

export default {
  name: "PromoterOutlets",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    BModal
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    promoterSelected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {},
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Outlets",
        card_mode: false,
        show_refresh_button: true,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          sort: true,
          visibility: false
        },
        {
          name: "dealerName",
          label: "Dealer Info",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "name",
          label: "Outlet Info",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "dealerOutletCode",
          label: "Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "address",
          label: "Address",
          visibility: false
        },
        {
          name: "Action",
          label: "Action",
          row_text_alignment: "text-center",
          column_text_alignment: "text-center"
        }
      ],
      total: 0,
      promoterOutletList: [],
      pendingApprovalStatus: false,
      promoter: this.promoterSelected,
      assignedOutlets: this.promoterSelected.promoterOutlets,
      assignedOutletsTotal: this.promoterSelected.promoterOutlets.length,
      isCapAdmin: false,
      isCap: false,
      isLoadingOutlets: false,
      startWeek: "",
      endWeek: "",
      formErrors: [],
      selectedOutlet: 0,
      type: 1
    };
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPromoterOutletList();
    },
    assignOutlet(row, type){
      if(row){
        this.selectedOutlet = row.id;
      }
      this.type = type;
      this.$refs["assign-outlet-modal"].toggle("#toggle-btn3");
    },
    handleSubmit(){
      if(this.validateFields()){
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: "Submitting..."
        });
        let pdata = {};
        pdata.type = this.type;
        if(this.type === 2){
          pdata.id = this.assignedOutlets.find(elem => elem.outletId === this.selectedOutlet).id;
        }
        pdata.outletId = this.selectedOutlet;
        pdata.accId = this.promoter.accId;
        pdata.startWeek = Number(this.startWeek);
        pdata.endWeek = Number(this.endWeek);
        pdata.fiscalYear = this.filters.yearSelected.value;
        pdata.quarter = this.filters.quarterSelected.value;

        this.$store.dispatch(POST_PROMOTER_OUTLET_REQUEST, pdata).then(res => {
          if (res.resultCode === 0 && res.content.status === 'success') {
            this.success();
            if(this.type === 1){
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "Outlet Assigned."
              });
            } else {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "Outlet is updated."
              });
            }
          }
        });
      }
    },
    checkForPendingApprovalFlag(){
      this.$store.dispatch(GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG, this.promoter.accId).then(res => {
        if (res.resultCode === 0) {
          this.pendingApprovalStatus = res.content;
        }
      });
    },
    validateFields(){
      this.formErrors = {};
      let error = 0;

      if (!this.startWeek) {
        this.formErrors.startWeek = this.$ml.get("form_promoter_start_week_required");
        error++;
      } else {
        if(Number(this.startWeek) <= 0 || Number(this.startWeek) > 13 || Number(this.startWeek) > Number(this.endWeek)){
          this.formErrors.startWeek = this.$ml.get("form_promoter_start_week_validation");
          error++;
        }
      }

      if (!this.endWeek) {
        this.formErrors.endWeek = this.$ml.get("form_promoter_end_week_required");
        error++;
      } else {
        if(Number(this.endWeek) <= 0 || Number(this.endWeek) > 13 || Number(this.endWeek) < Number(this.startWeek)){
          this.formErrors.endWeek = this.$ml.get("form_promoter_end_week_validation");
          error++;
        }
      }

      return !error;
    },
    getPromoterOutletList() {
      // if(!this.isCap && !this.isCapAdmin){
      //   this.promoterOutletList = this.assignedOutlets;
      // } else {
        let lst = [];
        this.isLoadingOutlets = true;
        let pdata = {};
        pdata.ccf = this.promoterSelected.createdCountryCode;
        this.$store.dispatch(OUTLETS_LIST_WITH_DEALERS_REQUEST, pdata).then(res => {
          if (res.resultCode === 0) {
            let list = [...res.content];
            list.forEach(item => {
              let element = {...item};
              element.assigned = this.checkOutletAssigned(element.id);
              if(element.assigned){
                element.startWeek = this.assignedOutlets.find(el => el.outletId === element.id).startWeek;
                element.endWeek = this.assignedOutlets.find(el => el.outletId === element.id).endWeek;
              }
              lst.push(element);
            });
  
            lst.sort(function(a, b) {
              return b.assigned - a.assigned;
            });
  
            this.promoterOutletList = lst;
            this.total = this.promoterOutletList.length;
            this.isLoadingOutlets = false;
          } else {
            this.isLoadingOutlets = false;
          }
        });
      // }
    },
    checkOutletAssigned(id) {
      const hvPic = this.assignedOutlets.filter(function(elem) {
        if (elem.outletId == id) return elem;
      });
      return !!hvPic[0];
    },
    addOrRemoveOutlet(row, type) {
      let pdata = {};

      if (type === 0) {
        pdata.type = 0;
      } else {
        pdata.type = 1;
      }

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Submitting..."
      });

      pdata.outletId = row.id;
      pdata.accId = this.promoter.accId;
      this.$store.dispatch(POST_PROMOTER_OUTLET_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.success();
          if (pdata.type === 1) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Outlet Assigned."
            });
          } else {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Outlet Removed."
            });
          }
        }
      });
    },
    formatDate: function(date) {
      return moment(String(date)).format("YYYY-MM-DD HH:MM A");
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    }
  },
  mounted() {
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.getPromoterOutletList();
    this.checkForPendingApprovalFlag();
  },
  created() {
  }
};
</script>
