<template>
  <div v-if="!isGetActualSObySubRegionLoading"> 
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <div class="mt-auto mb-auto">
        <span>
          <strong>{{ actualSObySubRegion.name }}</strong>
        </span>
      </div>
      <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
        ></a>
        <span 
          class="enlarge-icon mt-auto mb-auto mr-2" 
          @click="enlargeChart(actualSObySubRegion, 'stackedBarChart')"
        > 
          &#10070;
        </span>
      </div>
    </div>
    <stacked-bar-chart
      id="actualsubRegionLowerSection"
      :got-lower-section="true"
      :data.sync="actualSObySubRegion.data"
      lower-section-id="subRegionLowerSection"
      title=""
      chart-left="160"
      :max-characters="18"
    ></stacked-bar-chart>
    <div id="subRegionLowerSection"></div>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(242, 142, 43);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">To Go</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(154, 205, 50);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Over Achievement</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>

<script>
import { ACTUAL_SO_BY_SUB_REGION_GET, EXPORT_ACTUAL_SO_BY_SUB_REGION } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import stackedbarChart from "../dashboard/stacked-bar-chart";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data() {
    return{
      actualSObySubRegion: {},
      isGetActualSObySubRegionLoading: false,
      link: ""
    }
  },
  methods: {
    exportChart(event){
      event.preventDefault();
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }
      this.$store.dispatch(EXPORT_ACTUAL_SO_BY_SUB_REGION, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      });
    },
    async getActualSObySubRegion(){
      this.isGetActualSObySubRegionLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      // this.isLoading = true;
      await this.$store.dispatch(ACTUAL_SO_BY_SUB_REGION_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }).then(res => {
        if(res){
          this.actualSObySubRegion = res;
        }
        this.isGetActualSObySubRegionLoading = false;
      })
    },
  },
}
</script>