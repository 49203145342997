export const PROMOTER_ATTENDANCE_DATE_REQUEST = "PROMOTER_ATTENDANCE_DATE_REQUEST";
export const PROMOTER_ATTENDANCE_DATE_SUCCESS = "PROMOTER_ATTENDANCE_DATE_SUCCESS";
export const PROMOTER_ATTENDANCE_DATE_ERROR = "PROMOTER_ATTENDANCE_DATE_ERROR";

export const DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST = "DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST";
export const DOWNLOAD_PROMOTER_ATTENDANCE_SUCCESS = "DOWNLOAD_PROMOTER_ATTENDANCE_SUCCESS";
export const DOWNLOAD_PROMOTER_ATTENDANCE_ERROR = "DOWNLOAD_PROMOTER_ATTENDANCE_ERROR";

export const DOWNLOAD_INCENTIVE_PAYABLE_REQUEST = "DOWNLOAD_INCENTIVE_PAYABLE_REQUEST";
export const DOWNLOAD_INCENTIVE_PAYABLE_SUCCESS = "DOWNLOAD_INCENTIVE_PAYABLE_SUCCESS";
export const DOWNLOAD_INCENTIVE_PAYABLE_ERROR = "DOWNLOAD_INCENTIVE_PAYABLE_ERROR";

export const DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST = "DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST";
export const DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_SUCCESS = "DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_SUCCESS";
export const DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_ERROR = "DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_ERROR";

export const DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST = "DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST";
export const DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_SUCCESS = "DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_SUCCESS";
export const DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_ERROR = "DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_ERROR";

export const DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_REQUEST = "DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST";
export const DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_SUCCESS = "DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_SUCCESS";
export const DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_ERROR = "DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_ERROR";

export const DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST = "DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST";
export const DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_SUCCESS = "DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_SUCCESS";
export const DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_ERROR = "DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_ERROR";

export const DOWNLOAD_VISITATION_REQUEST = "DOWNLOAD_VISITATION_REQUEST";
export const DOWNLOAD_VISITATION_SUCCESS = "DOWNLOAD_VISITATION_SUCCESS";
export const DOWNLOAD_VISITATION_ERROR = "DOWNLOAD_VISITATION_ERROR";

export const DOWNLOAD_VISITATION_SUMMARY_REQUEST = "DOWNLOAD_VISITATION_SUMMARY_REQUEST";
export const DOWNLOAD_VISITATION_DASHBOARD_REQUEST = "DOWNLOAD_VISITATION_DASHBOARD_REQUEST";

export const SEND_VISITATION_REQUEST = "SEND_VISITATION_REQUEST";
export const SEND_VISITATION_SUCCESS = "SEND_VISITATION_SUCCESS";
export const SEND_VISITATION_ERROR = "SEND_VISITATION_ERROR";

export const SEND_VISITATION_SUMMARY_REQUEST = "SEND_VISITATION_SUMMARY_REQUEST";

export const DOWNLOAD_WEARTEAR_REQUEST = "DOWNLOAD_WEARTEAR_REQUEST";
export const DOWNLOAD_WEARTEAR_SUCCESS = "DOWNLOAD_WEARTEAR_SUCCESS";
export const DOWNLOAD_WEARTEAR_ERROR = "DOWNLOAD_WEARTEAR_ERROR";

export const SEND_WEARTEAR_REQUEST = "SEND_WEARTEAR_REQUEST";
export const SEND_WEARTEAR_SUCCESS = "SEND_WEARTEAR_SUCCESS";
export const SEND_WEARTEAR_ERROR = "SEND_WEARTEAR_ERROR";

export const DOWNLOAD_HEARTBEAT_REQUEST = "DOWNLOAD_HEARTBEAT_REQUEST";
export const DOWNLOAD_HEARTBEAT_SUCCESS = "DOWNLOAD_HEARTBEAT_SUCCESS";
export const DOWNLOAD_HEARTBEAT_ERROR = "DOWNLOAD_HEARTBEAT_ERROR";

export const SEND_HEARTBEAT_REQUEST = "SEND_HEARTBEAT_REQUEST";
export const SEND_HEARTBEAT_SUCCESS = "SEND_HEARTBEAT_SUCCESS";
export const SEND_HEARTBEAT_ERROR = "SEND_HEARTBEAT_ERROR";