<template>
  <div v-if="!isLoading" id="lenovo-vs-competitor">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <div class="d-flex align-items-center">
        <span class="mr-4">
          <strong>Lenovo vs Competitor Share of Space</strong>
        </span>
        <div v-if="filters.countryIdSelected != ''" class="mr-4">
          <Multiselect
              ref="storeIdentitySelection"
              v-model="subRegionSelected"
              :searchable="false"
              :options="filteredSubRegionOptions"
              label="text"
              track-by="id"
              :loading="!filteredSubRegionOptions"
              :allow-empty="false"
              :show-labels="false"
              @input="(el)=>{subRegionSelected=el}"
            ></Multiselect>
        </div>
      </div>
      <div class="position-relative d-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="d-none"
        ></a>
      </div>
    </div>

    <div v-if="response.series.length !== 0">
      <div class="donut-container">
        <DonutChart
          chart-title="Lenovo vs Competitor View"
          :series="response.series"
          :labels="response.labels"
          :colors="response.colors"
          :label-formatter="labelFormatter"
          :height="400"
          :hide-tooltip="true"
          :hide-legends="true"
        />
        <div class="center-text">{{ 
          filters.countryIdSelected == "" ? 'CAP'
          : response.series.length > 0 ? subRegionSelected.text
          : "No result"
        }}</div>
      </div>
    </div>
    <div v-else class="no-data chart-box">NO DATA</div>

    <div v-if="sortedLegends.length > 0" class="legend-row">
      <span
        v-for="el in sortedLegends"
        :key="el"
      >
        <span class="circle" :style="`background-color: ${colorMap[el]}`">&nbsp;</span>
        {{ el }}
      </span>
    </div>
  </div>
  <div v-else style="height: 100%"><div class="loading loading-big"></div></div>
</template>
<script>
import DonutChart from '@/components/dashboard/donut-chart.vue';
import cloneDeep from "lodash/cloneDeep"
import Multiselect from "vue-multiselect";

import { DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET } from "@/store/modules/charts/actions";
import { GET_ALL_REGION_AND_SUBREGION_REQUEST } from "@/store/modules/utility/actions";
import { limitDecimalNum } from "@/utils/helpers"
import { ALL_BRAND_COLOR_MAP } from "@/constants"

export default {
  components: {
    DonutChart, 
    Multiselect
  },
  props: {
    filters: {type: Object, default: () => {}},
    enlargeChart: { type: Function, default: () => {} }
  },
  computed: {
    filteredSubRegionOptions() {
      if (this.filters.countryIdSelected != null) {
        return this.subRegionList.filter(el => el.countryId == this.filters.countryIdSelected || el.id == null)
      } else {
        return []
      }
    },
  },
  data() {
    return {
      isLoading: true,
      response: {},
      subRegionList: [],
      subRegionSelected: {},
      colorMap: ALL_BRAND_COLOR_MAP,
      link: "",
      arbitraryNum: 0,
      sortedLegends: [],
    }
  },
  watch: {
    subRegionSelected() { this.getData(false) },
  },
  methods: {
    async getSubRegionOptions() {
      this.subRegionList = []
      this.subRegionSelected = {}
      if (this.filters.countryIdSelected) {
        await this.$store.dispatch(GET_ALL_REGION_AND_SUBREGION_REQUEST)
          .then(res => {
            if (res.resultCode === 0) {
              this.subRegionList = cloneDeep(res.content).map(el => {
                el.text = el.subRegion
                return el
              })

              const regionsInCountry = res.content.find(el => el.countryId == this.filters.countryIdSelected)
              const allSelection = {
                id: null,
                text: regionsInCountry.country
              }
              this.subRegionList.unshift(allSelection)
              this.subRegionSelected = allSelection
            }
          });
      }
    },
    async getData(getOption = true) {
      this.isLoading = true

      if (getOption) await this.getSubRegionOptions()
      
      let pdata = { filters: this.filters }
      pdata.filters.subRegionSelectedName = this.subRegionSelected.subRegion

      this.$store.dispatch(DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET, pdata).then(res => {
        if (res) {
          this.response = this.parseData(cloneDeep(res.content))
        }
      })
      .finally(() => this.isLoading = false)
    },
    parseData(res) {
      const resSorted = res.sort((a) => {
        if (a.brandName == 'Lenovo') return -1
        else return 0
      }) // move lenovo to first

      const labels = resSorted.map(el => el.brandName)

      this.sortedLegends = cloneDeep(labels).sort()
      this.sortedLegends = this.sortedLegends.filter(el => el !== "Lenovo")
      this.sortedLegends.unshift("Lenovo")

      return {
        series: resSorted.map(el => Number(limitDecimalNum(el.percentage, 1))),
        labels,
        colors: resSorted.map(el => {
          return this.colorMap[el.brandName]
        })
      }
    },
    labelFormatter(val) {
      const value = limitDecimalNum(val, 1) + "%"
      // making alternating label up and down
      if (this.arbitraryNum++ % 2 === 0) {
        return [value, ""]
      }
      return ["", value]
    },
    exportChart(event){
      event.preventDefault();

      let pdata = {
       filters: cloneDeep(this.filters)
      }
      pdata.filters.isExport = true
      this.$store.dispatch(DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      })
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss">
#lenovo-vs-competitor {
  position: relative;
  .dropdown {
    z-index: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .center-text {
    position: absolute;
    top: 44%;
    right: 0;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .no-data {
    background: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
  }
  .legend-row {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    >span {
      display: flex;
      align-items: center;
    }
    .circle {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: auto 5px;
    }
  }
}
</style>