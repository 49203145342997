<template>
  <div v-if="!isLoading" id="visitation-score-grade-sub-region">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <div class="d-flex align-items-center">
        <span class="mr-4">
          <strong>Sub Region Score & Grade</strong>
        </span>
        <div class="mr-4">
          <Multiselect
            v-if="regionOptions.length > 0"
            ref="subRegionSelection"
            v-model="regionSelected"
            :searchable="false"
            :options="regionOptions"
            label="text"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            @input="(el)=>{regionSelected=el}"
          ></Multiselect>
        </div>
      </div>
      <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="d-none"
        ></a>
      </div>
    </div>
    <stacked-bar-chart
      id="visitationScoreGradeSubRegion"
      :data.sync="data"
      chart-left="160"
      title=""
      :max-characters="18"
      :got-lower-section="true"
    />
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <ScoreTable />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import stackedbarChart from "../dashboard/stacked-bar-chart";
import cloneDeep from "lodash/cloneDeep"
import Multiselect from "vue-multiselect";
import ScoreTable from "../dashboard/score-table.vue"
import { DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET } from "@/store/modules/charts/actions";
import { GET_ALL_REGION_AND_SUBREGION_REQUEST } from "@/store/modules/utility/actions";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
    ScoreTable,
    Multiselect
  },
  props: {
    filters: {type: Object, default: () => {}},
  },
  data() {
    return {
      isLoading: false,
      data: [[]],
      seriesOptions: {},
      link: "",
      regionOptions: [],
      regionSelected: {}
    }
  },
  watch: {
    regionSelected() { this.getData(false) },
  },
  methods: {
    async getData(getOption = true) {
      this.isLoading = true
      if (getOption) await this.getRegionOptions()
      this.filters.regionSelected = this.regionSelected

      let pdata = { filters: this.filters }

      this.$store.dispatch(DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET, pdata).then(res => {
        if (res) {
          this.data = res.data
          this.seriesOptions = res.seriesOptions
        }
      })
      .finally(() => this.isLoading = false)
    },
    async getRegionOptions() {
      this.regionOptions = []
      this.regionSelected = {}
      if (this.filters.countryIdSelected) {
        await this.$store.dispatch(GET_ALL_REGION_AND_SUBREGION_REQUEST)
          .then(res => {
            if (res.resultCode === 0) {
              this.regionOptions = cloneDeep(res.content)
              .filter(el => el.countryId == this.filters.countryIdSelected)
              .filter((value, index, self) => {
                return self.findIndex(el => el.region == value.region) === index
              })
              .map(el => {
                el.text = el.region
                return el
              })

              const regionsInCountry = res.content.find(el => el.countryId == this.filters.countryIdSelected)
              const allSelection = {
                id: null,
                text: regionsInCountry.country
              }
              this.regionOptions.unshift(allSelection)
              this.regionSelected = allSelection
            }
          });
      }
    },
    exportChart(event){
      event.preventDefault();
      let pdata = {
       filters: cloneDeep(this.filters)
      }
      pdata.filters.isExport = true
      this.$store.dispatch(DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      })
    },
  }
}
</script>
<style lang="scss">
#visitation-score-grade-sub-region {
  .multiselect {
    min-width: 150px;
  }
}
</style>