import {
  getMediaList,
  addMedia,
  updateMedia,
  getProductCategoryAndGroup,
  deleteMediaById,
  getMediaById
} from "@/api/media";

import {
  MEDIA_LIST_REQUEST,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_ERROR,
  ADD_MEDIA_REQUEST,
  ADD_MEDIA_SUCCESS,
  ADD_MEDIA_ERROR,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_SUCCESS,
  UPDATE_MEDIA_ERROR,
  PRODUCT_CATEGORY_AND_GROUP_REQUEST,
  PRODUCT_CATEGORY_AND_GROUP_SUCCESS,
  PRODUCT_CATEGORY_AND_GROUP_ERROR,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_ERROR,
  MEDIA_BY_ID_REQUEST,
  MEDIA_BY_ID_SUCCESS,
  MEDIA_BY_ID_ERROR
} from "./actions";

const state = {
  mediaList: [],
  productCategoryAndGroup: [],
  status: "",
  hasLoadedOnce: false
};

const getters = {
  getMediaList: state => state.mediaList
};

const mutations = {
  MEDIA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  MEDIA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      mediaList: data,
      hasLoadedOnce: true
    });
  },
  MEDIA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      mediaList: [],
      hasLoadedOnce: true
    });
  },
  ADD_MEDIA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADD_MEDIA_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  ADD_MEDIA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_MEDIA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_MEDIA_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_MEDIA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DELETE_MEDIA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DELETE_MEDIA_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  DELETE_MEDIA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  MEDIA_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  MEDIA_BY_ID_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  MEDIA_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PRODUCT_CATEGORY_AND_GROUP_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_CATEGORY_AND_GROUP_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      productCategoryAndGroup: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_CATEGORY_AND_GROUP_ERROR: state => {
    Object.assign(state, {
      status: "error",
      productCategoryAndGroup: [],
      hasLoadedOnce: true
    });
  },
}

const actions = {
  [MEDIA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(MEDIA_LIST_REQUEST);
      getMediaList(postData)
        .then(resp => {
          commit(MEDIA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(MEDIA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [ADD_MEDIA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ADD_MEDIA_REQUEST);
      addMedia(postData)
        .then(resp => {
          commit(ADD_MEDIA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADD_MEDIA_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_MEDIA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_MEDIA_REQUEST);
      updateMedia(postData)
        .then(resp => {
          commit(UPDATE_MEDIA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_MEDIA_ERROR, err);
          reject(err);
        });
    });
  },
  [DELETE_MEDIA_REQUEST]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit(DELETE_MEDIA_REQUEST);
      deleteMediaById(id)
        .then(resp => {
          commit(DELETE_MEDIA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DELETE_MEDIA_ERROR, err);
          reject(err);
        });
    });
  },
  [MEDIA_BY_ID_REQUEST]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      commit(MEDIA_BY_ID_REQUEST);
      getMediaById(id)
        .then(resp => {
          commit(MEDIA_BY_ID_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(MEDIA_BY_ID_ERROR, err);
          reject(err);
        });
    });
  },
  [PRODUCT_CATEGORY_AND_GROUP_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_CATEGORY_AND_GROUP_REQUEST);
      getProductCategoryAndGroup()
        .then(resp => {
          commit(PRODUCT_CATEGORY_AND_GROUP_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PRODUCT_CATEGORY_AND_GROUP_ERROR, err);
          reject(err);
        });
    });
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};