<template>
  <div v-if="!isLoading">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <span class="mt-auto mb-auto">
        <strong>{{ countryNameSelected ? countryNameSelected : "Top Dealer Score & Grade" }}</strong>
      </span>
      <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="d-none"
        ></a>
      </div>
    </div>
    <stacked-bar-chart
      id="visitationScoreGradeTopDealer"
      :data.sync="data"
      chart-left="160"
      title=""
      :max-characters="18"
      :got-lower-section="true"
    />
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <ScoreTable />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep"
import stackedbarChart from "../dashboard/stacked-bar-chart";
import ScoreTable from "../dashboard/score-table.vue"
import { DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET } from "@/store/modules/charts/actions";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
    ScoreTable
  },
  props: {
    filters: {type: Object, default: () => {}},
    countryIdSelected: {type: Number, default: null},
    countryNameSelected: {type: String, default: null},
  },
  data() {
    return {
      isLoading: false,
      data: [[]],
      seriesOptions: {},
      link: ""
    }
  },
  watch: {
    storeIdentitySelected() { this.getData() }
  },
  methods: {
    getData() {
      this.isLoading = true
      let pdata = { filters: cloneDeep(this.filters) }

      if (this.countryIdSelected != null) {
        pdata.filters.countryIdSelected = this.countryIdSelected
      }

      this.$store.dispatch(DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET, pdata).then(res => {
        if (res) {
          this.data = res.data
          this.seriesOptions = res.seriesOptions
        }
      })
      .finally(() => this.isLoading = false)
    },
    exportChart(event, countryFilter = null){
      event.preventDefault();
      let pdata = {
       filters: cloneDeep(this.filters)
      }
      if (this.countryIdSelected != null && countryFilter !== "CAP") {
        pdata.filters.countryIdSelected = this.countryIdSelected
      }
      pdata.filters.isExport = true
      this.$store.dispatch(DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      })
    },
  }
}
</script>