export const API_URL = process.env.VUE_APP_API_URL;
export const API_URL_STATIC = process.env.VUE_APP_API_URL_STATIC;
export const APP_NAME = process.env.VUE_APP_TITLE;
export const LAYOUT_ADMIN = "admin";
export const LAYOUT_AUTH = "auth";
export const ROLE_ADMIN = "Admin";
export const ROLE_CAP_ADMIN = "CAP_Admin";
export const ROLE_CAP_MARKETING = "CAP_Marketing"; //View dashboard (Regional) access only
export const ROLE_CAP_MEDIA = "CAP_Media"; 
export const ROLE_CAP = "CAP";
export const ROLE_COUNTRY_ADMIN = "Country_Head";
export const ROLE_COUNTRY_MARKETING = "Country_Head_Marketing"; //View dashboard (country) access only
export const ROLE_GLN_COUNTRY_ADMIN = "GLN_Country_Head";
export const ROLE_COUNTRY_VIEW = "Country_Head_View_Only";
export const ROLE_COUNTRY_TRAINER = "Country_Trainer";
export const ROLE_COUNTRY_MEDIA = "Country_Media";
export const ROLE_DEALER = "Dealer";
export const ROLE_PROMOTER_LEADER = "Promoter_Leader";
export const ROLE_OUTLET_PIC = "Outlet_PIC";
export const ROLE_AGENCY = "Agency";
export const ROLE_COUNTRY_AGENCY = "Country_Agency";
export const ROLE_CHECKER = "Checker";
export const ROLE_REGION_MANAGER = "Regional_Manager";
export const ROLE_STATE_MANAGER = "State_Manager";
export const ROLE_MERC_LEADER = "Merchandiser_Leader";
export const DEFAULT_TIMEOUT = 120000;
export const EXTENDED_TIMEOUT = 600000000;
export const NO_TIMEOUT = 0;

export const ALL_STORE_IDENTITY_COLOR = [
  { label: "All", value: null, color: "black" },
  { label: "LES", value: "LES", color: "#1796D4" },
  { label: "Key Store", value: "KEY_STORE", color: "#428A3F" },
  {
    label: "Medium Priority",
    value: "MEDIUM_PRIORITY_STORE",
    color: "#6B4192",
  },
  { label: "Low Priority", value: "LOW_PRIORITY_STORE", color: "#B65946" },
];

export const QUATERLY_WEEK_LIST = [
  { name: "ALL", value: null },
  { name: "Week 01", value: "1" },
  { name: "Week 02", value: "2" },
  { name: "Week 03", value: "3" },
  { name: "Week 04", value: "4" },
  { name: "Week 05", value: "5" },
  { name: "Week 06", value: "6" },
  { name: "Week 07", value: "7" },
  { name: "Week 08", value: "8" },
  { name: "Week 09", value: "9" },
  { name: "Week 10", value: "10" },
  { name: "Week 11", value: "11" },
  { name: "Week 12", value: "12" },
  { name: "Week 13", value: "13" },
];

export const ALL_BRAND_COLOR_MAP = {
  Lenovo: "#F75757",
  Asus: "#00D8A5",
  Acer: "#954980",
  HP: "#FF854C",
  Dell: "#5E1A9F",
  MSI: "#FFD800",
  Apple: "#2E82EB",
  Others: "grey",
};

export const FISCAL_YEAR_LIST = [
  "FY1920",
  "FY2021",
  "FY2122",
  "FY2223",
  "FY2324",
  "FY2425",
];
