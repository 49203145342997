import {
  getBankList,
  getCategoryList,
  getStateList,
  getCountryList,
  getGenderList,
  getNewsTargetList,
  getProductTypeList,
  getProductGroupList,
  getSalesStatusList,
  getSerialTypeList,
  getCheckStatusList,
  getFailedReasonList,
  getIncentiveTargetTypeList,
  getTargetSaleTypeList,
  getPromoterTypeList,
  getStoreType,
  getAccreditation,
  getFocusProduct,
  getRegionAndSubRegion,
  getStoreIdentity,
  getAllRegionAndSubRegion,
  uploadFileAndGetLink,
  getDashboardFiscalYearQuarter,
} from "@/api/utility";
import {
  BANK_LIST_SUCCESS,
  BANK_LIST_REQUEST,
  BANK_LIST_ERROR,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_ERROR,
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  STATE_LIST_ERROR,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_ERROR,
  GENDER_LIST_REQUEST,
  GENDER_LIST_SUCCESS,
  GENDER_LIST_ERROR,
  NEWS_TARGET_LIST_REQUEST,
  NEWS_TARGET_LIST_SUCCESS,
  NEWS_TARGET_LIST_ERROR,
  PRODUCT_TYPE_LIST_REQUEST,
  PRODUCT_TYPE_LIST_SUCCESS,
  PRODUCT_TYPE_LIST_ERROR,
  PRODUCT_GROUP_LIST_REQUEST,
  PRODUCT_GROUP_LIST_SUCCESS,
  PRODUCT_GROUP_LIST_ERROR,
  SALES_STATUS_LIST_REQUEST,
  SALES_STATUS_LIST_SUCCESS,
  SALES_STATUS_LIST_ERROR,
  SERIAL_TYPE_LIST_REQUEST,
  SERIAL_TYPE_LIST_SUCCESS,
  SERIAL_TYPE_LIST_ERROR,
  CHECK_STATUS_REQUEST,
  CHECK_STATUS_SUCCESS,
  CHECK_STATUS_ERROR,
  FAILED_REASON_REQUEST,
  FAILED_REASON_SUCCESS,
  FAILED_REASON_ERROR,
  INC_TARGET_TYPE_REQUEST,
  INC_TARGET_TYPE_SUCCESS,
  INC_TARGET_TYPE_ERROR,
  TARGET_SALE_TYPE_REQUEST,
  TARGET_SALE_TYPE_SUCCESS,
  TARGET_SALE_TYPE_ERROR,
  PROMOTER_TYPE_LIST_REQUEST,
  PROMOTER_TYPE_LIST_SUCCESS,
  PROMOTER_TYPE_LIST_ERROR,
  STORE_TYPE_LIST_REQUEST,
  STORE_TYPE_LIST_SUCCESS,
  STORE_TYPE_LIST_ERROR,
  ACCREDITATION_LIST_REQUEST,
  ACCREDITATION_LIST_SUCCESS,
  ACCREDITATION_LIST_ERROR,
  FOCUS_PRODUCT_LIST_REQUEST,
  FOCUS_PRODUCT_LIST_SUCCESS,
  FOCUS_PRODUCT_LIST_ERROR,
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID,
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_SUCCESS,
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_ERROR,
  STORE_IDENTITY_REQUEST,
  STORE_IDENTITY_SUCCESS,
  STORE_IDENTITY_ERROR,
  GET_ALL_REGION_AND_SUBREGION_REQUEST,
  GET_ALL_REGION_AND_SUBREGION_REQUEST_SUCCESS,
  GET_ALL_REGION_AND_SUBREGION_REQUEST_ERROR,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST,
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_SUCCESS,
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  banks: [],
  states: [],
  categories: [],
  countries: [],
  genders: [],
  ntargets: [],
  productGroups: [],
  getProductTypes: [],
  saleStatus: [],
  serialTypes: [],
  checkStatus: [],
  failedReasons: [],
  status: "",
  hasLoadedOnce: false,
  targetTypes: [],
  targetSaleTypes: [],
  promoterTypes: [],
  storeTypes: [],
  accreditation: [],
  focusProducts: [],
  storeIdentities: [],
  defaultSelectedPeriod: {},
};

const getters = {
  getBanks: state => state.banks,
  getStates: state => state.states,
  getCategories: state => state.categories,
  getNewsTargets: state => state.ntargets,
  getProductTypes: state => state.productTypes,
  productGroups: state => state.productGroups,
  getGenders: state => state.genders,
  getSerialTypes: state => state.serialTypes,
  getFailedReasons: state => state.failedReasons,
  getIncTargetTypes: state => state.targetTypes,
  getTargetSaleTypes: state => state.targetSaleTypes,
  getPromoterTypes: state => state.promoterTypes,
  getStoreTypes: state => state.storeTypes,
  getAccreditation: state => state.accreditation,
  getFocusProduct: state => state.focusProducts,
  getStoreIdentities: state => state.storeIdentities
};

const actions = {
  [BANK_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(BANK_LIST_REQUEST);
      getBankList(postData)
        .then(resp => {
          commit(BANK_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(BANK_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [CATEGORY_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CATEGORY_LIST_REQUEST);
      getCategoryList(postData)
        .then(resp => {
          commit(CATEGORY_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CATEGORY_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [STATE_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(STATE_LIST_REQUEST);
      getStateList(postData)
        .then(resp => {
          commit(STATE_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(STATE_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [BANK_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(BANK_LIST_REQUEST);
      getBankList(postData)
        .then(resp => {
          commit(BANK_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(BANK_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [COUNTRY_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(COUNTRY_LIST_REQUEST);
      getCountryList(postData)
        .then(resp => {
          commit(COUNTRY_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(COUNTRY_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GENDER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GENDER_LIST_REQUEST);
      getGenderList(postData)
        .then(resp => {
          commit(GENDER_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GENDER_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [NEWS_TARGET_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(NEWS_TARGET_LIST_REQUEST);
      getNewsTargetList(postData)
        .then(resp => {
          commit(NEWS_TARGET_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(NEWS_TARGET_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [PRODUCT_TYPE_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_TYPE_LIST_REQUEST);
      getProductTypeList(postData)
        .then(resp => {
          commit(PRODUCT_TYPE_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PRODUCT_TYPE_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [PRODUCT_GROUP_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_GROUP_LIST_REQUEST);
      getProductGroupList(postData)
        .then(resp => {
          commit(PRODUCT_GROUP_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PRODUCT_GROUP_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [SALES_STATUS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SALES_STATUS_LIST_REQUEST);
      getSalesStatusList(postData)
        .then(resp => {
          commit(SALES_STATUS_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SALES_STATUS_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [SERIAL_TYPE_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SERIAL_TYPE_LIST_REQUEST);
      getSerialTypeList(postData)
        .then(resp => {
          commit(SERIAL_TYPE_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SERIAL_TYPE_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [CHECK_STATUS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CHECK_STATUS_REQUEST);
      getCheckStatusList(postData)
        .then(resp => {
          commit(CHECK_STATUS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CHECK_STATUS_ERROR, err);
          reject(err);
        });
    });
  },
  [FAILED_REASON_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(FAILED_REASON_REQUEST);
      getFailedReasonList(postData)
        .then(resp => {
          commit(FAILED_REASON_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(FAILED_REASON_ERROR, err);
          reject(err);
        });
    });
  },
  [INC_TARGET_TYPE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(INC_TARGET_TYPE_REQUEST);
      getIncentiveTargetTypeList(postData)
        .then(resp => {
          commit(INC_TARGET_TYPE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(INC_TARGET_TYPE_ERROR, err);
          reject(err);
        });
    });
  },
  [TARGET_SALE_TYPE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TARGET_SALE_TYPE_REQUEST);
      getTargetSaleTypeList(postData)
        .then(resp => {
          commit(TARGET_SALE_TYPE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(TARGET_SALE_TYPE_ERROR, err);
          reject(err);
        });
    });
  },
  [PROMOTER_TYPE_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_TYPE_LIST_REQUEST);
      getPromoterTypeList(postData)
        .then(resp => {
          commit(PROMOTER_TYPE_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PROMOTER_TYPE_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [STORE_TYPE_LIST_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(STORE_TYPE_LIST_REQUEST);
      getStoreType()
        .then(resp => {
          commit(STORE_TYPE_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(STORE_TYPE_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [ACCREDITATION_LIST_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(ACCREDITATION_LIST_REQUEST);
      getAccreditation()
          .then(resp => {
            commit(ACCREDITATION_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(ACCREDITATION_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [FOCUS_PRODUCT_LIST_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(FOCUS_PRODUCT_LIST_REQUEST);
      getFocusProduct()
          .then(resp => {
            commit(FOCUS_PRODUCT_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(FOCUS_PRODUCT_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID]: ({ commit }, stateId) => {
    return new Promise((resolve, reject) => {
      commit(GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID);
      getRegionAndSubRegion(stateId)
          .then(resp => {
            commit(GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [STORE_IDENTITY_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(STORE_IDENTITY_REQUEST);
      getStoreIdentity()
          .then(resp => {
            commit(STORE_IDENTITY_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(STORE_IDENTITY_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_ALL_REGION_AND_SUBREGION_REQUEST]: ({ commit }, stateId) => {
    return new Promise((resolve, reject) => {
      commit(GET_ALL_REGION_AND_SUBREGION_REQUEST);
      getAllRegionAndSubRegion(stateId)
          .then(resp => {
            commit(GET_ALL_REGION_AND_SUBREGION_REQUEST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_ALL_REGION_AND_SUBREGION_REQUEST_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_FILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_FILE_REQUEST);
      uploadFileAndGetLink(postData)
        .then(resp => {
          commit(UPLOAD_FILE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_FILE_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST);
      getDashboardFiscalYearQuarter(postData)
        .then(resp => {
          commit(GET_DASHBOARD_FISCAL_YEAR_QUARTER_SUCCESS, resp.content);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_DASHBOARD_FISCAL_YEAR_QUARTER_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  BANK_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  BANK_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      banks: data,
      hasLoadedOnce: true
    });
  },
  BANK_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      banks: [],
      hasLoadedOnce: true
    });
  },
  CATEGORY_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CATEGORY_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      categories: data,
      hasLoadedOnce: true
    });
  },
  CATEGORY_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      categories: [],
      hasLoadedOnce: true
    });
  },
  COUNTRY_LIST_REQUEST: (state, data) => {
    Object.assign(state, {
      state: "",
      countries: data,
      hasLoadedOnce: true
    });
  },
  COUNTRY_LIST_SUCCESS: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  COUNTRY_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      countries: [],
      hasLoadedOnce: true
    });
  },
  STATE_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  STATE_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      states: data,
      hasLoadedOnce: true
    });
  },
  STATE_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      states: [],
      hasLoadedOnce: true
    });
  },
  NEWS_TARGET_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  NEWS_TARGET_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      ntargets: data,
      hasLoadedOnce: true
    });
  },
  NEWS_TARGET_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      ntargets: [],
      hasLoadedOnce: true
    });
  },
  PRODUCT_TYPE_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_TYPE_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      productTypes: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_TYPE_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      productTypes: [],
      hasLoadedOnce: true
    });
  },
  PRODUCT_GROUP_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_GROUP_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      productGroups: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_GROUP_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      productGroups: [],
      hasLoadedOnce: true
    });
  },
  SALES_STATUS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SALES_STATUS_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      saleStatus: data,
      hasLoadedOnce: true
    });
  },
  SALES_STATUS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      saleStatus: [],
      hasLoadedOnce: true
    });
  },
  SERIAL_TYPE_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SERIAL_TYPE_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      serialTypes: data,
      hasLoadedOnce: true
    });
  },
  SERIAL_TYPE_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialTypes: [],
      hasLoadedOnce: true
    });
  },
  CHECK_STATUS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CHECK_STATUS_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      checkStatus: data,
      hasLoadedOnce: true
    });
  },
  CHECK_STATUS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      checkStatus: [],
      hasLoadedOnce: true
    });
  },
  FAILED_REASON_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  FAILED_REASON_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      failedReasons: data,
      hasLoadedOnce: true
    });
  },
  FAILED_REASON_ERROR: state => {
    Object.assign(state, {
      status: "error",
      failedReasons: [],
      hasLoadedOnce: true
    });
  },
  GENDER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GENDER_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      genders: data,
      hasLoadedOnce: true
    });
  },
  GENDER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      genders: [],
      hasLoadedOnce: true
    });
  },
  INC_TARGET_TYPE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  INC_TARGET_TYPE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      targetTypes: data,
      hasLoadedOnce: true
    });
  },
  INC_TARGET_TYPE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      targetTypes: [],
      hasLoadedOnce: true
    });
  },
  TARGET_SALE_TYPE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TARGET_SALE_TYPE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      targetSaleTypes: data,
      hasLoadedOnce: true
    });
  },
  TARGET_SALE_TYPE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      targetSaleTypes: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_TYPE_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_TYPE_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      promoterTypes: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_TYPE_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      promoterTypes: [],
      hasLoadedOnce: true
    });
  },
  STORE_TYPE_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  STORE_TYPE_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      storeTypes: data,
      hasLoadedOnce: true
    });
  },
  STORE_TYPE_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      storeTypes: [],
      hasLoadedOnce: true
    });
  },
  ACCREDITATION_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ACCREDITATION_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      accreditation: data,
      hasLoadedOnce: true
    });
  },
  ACCREDITATION_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      accreditation: [],
      hasLoadedOnce: true
    });
  },
  FOCUS_PRODUCT_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  FOCUS_PRODUCT_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      focusProducts: data,
      hasLoadedOnce: true
    });
  },
  FOCUS_PRODUCT_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      focusProducts: [],
      hasLoadedOnce: true
    });
  },
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  STORE_IDENTITY_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_ALL_REGION_AND_SUBREGION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  STORE_IDENTITY_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      storeIdentities: data,
      hasLoadedOnce: true
    });
  },
  STORE_IDENTITY_ERROR: state => {
    Object.assign(state, {
      status: "error",
      storeIdentities: [],
      hasLoadedOnce: true
    });
  },
  GET_ALL_REGION_AND_SUBREGION_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_ALL_REGION_AND_SUBREGION_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_FILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_FILE_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  UPLOAD_FILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      defaultSelectedPeriod: data,
      hasLoadedOnce: true
    });
  },
  GET_DASHBOARD_FISCAL_YEAR_QUARTER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      defaultSelectedPeriod: {},
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
