import {
  getCalendarItemList,
  uploadOpsCalendar
} from "@/api/ops-calendar";
import {
  GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST,
  GET_ALL_APP_CALENDAR_ITEMS_LIST_SUCCESS,
  GET_ALL_APP_CALENDAR_ITEMS_LIST_ERROR,
  UPLOAD_OPS_CALENDAR_REQUEST,
  UPLOAD_OPS_CALENDAR_SUCCESS,
  UPLOAD_OPS_CALENDAR_ERROR
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  data: [],
  status: "",
  hasLoadedOnce: false
};

const actions = {
  [GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST);
      getCalendarItemList(postData)
        .then(resp => {
          commit(GET_ALL_APP_CALENDAR_ITEMS_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_ALL_APP_CALENDAR_ITEMS_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_OPS_CALENDAR_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_OPS_CALENDAR_REQUEST);
      uploadOpsCalendar(postData)
        .then(resp => {
          commit(UPLOAD_OPS_CALENDAR_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_OPS_CALENDAR_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  GET_ALL_APP_CALENDAR_ITEMS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_ALL_APP_CALENDAR_ITEMS_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_ALL_APP_CALENDAR_ITEMS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_OPS_CALENDAR_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_OPS_CALENDAR_SUCCESS: (state, data) => {
    Object.assign(state, {
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_OPS_CALENDAR_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};
