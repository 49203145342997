export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";

export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_ERROR = "BANK_LIST_ERROR";

export const STATE_LIST_REQUEST = "STATE_LIST_REQUEST";
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_ERROR = "STATE_LIST_ERROR";

export const COUNTRY_LIST_REQUEST = "COUNTRY_LIST_REQUEST";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_ERROR = "COUNTRY_LIST_ERROR";

export const GENDER_LIST_REQUEST = "GENDER_LIST_REQUEST";
export const GENDER_LIST_SUCCESS = "GENDER_LIST_SUCCESS";
export const GENDER_LIST_ERROR = "GENDER_LIST_ERROR";

export const NEWS_TARGET_LIST_REQUEST = "NEWS_TARGET_LIST_REQUEST";
export const NEWS_TARGET_LIST_SUCCESS = "NEWS_TARGET_LIST_SUCCESS";
export const NEWS_TARGET_LIST_ERROR = "NEWS_TARGET_LIST_ERROR";

export const PRODUCT_TYPE_LIST_REQUEST = "PRODUCT_TYPE_LIST_REQUEST";
export const PRODUCT_TYPE_LIST_SUCCESS = "PRODUCT_TYPE_LIST_SUCCESS";
export const PRODUCT_TYPE_LIST_ERROR = "PRODUCT_TYPE_LIST_ERROR";

export const PRODUCT_GROUP_LIST_REQUEST = "PRODUCT_GROUP_LIST_REQUEST";
export const PRODUCT_GROUP_LIST_SUCCESS = "PRODUCT_GROUP_LIST_SUCCESS";
export const PRODUCT_GROUP_LIST_ERROR = "PRODUCT_GROUP_LIST_ERROR";

export const SALES_STATUS_LIST_REQUEST = "SALES_STATUS_LIST_REQUEST";
export const SALES_STATUS_LIST_SUCCESS = "SALES_STATUS_LIST_SUCCESS";
export const SALES_STATUS_LIST_ERROR = "SALES_STATUS_LIST_ERROR";

export const SERIAL_TYPE_LIST_REQUEST = "SERIAL_TYPE_LIST_REQUEST";
export const SERIAL_TYPE_LIST_SUCCESS = "SERIAL_TYPE_LIST_SUCCESS";
export const SERIAL_TYPE_LIST_ERROR = "SERIAL_TYPE_LIST_ERROR";

export const CHECK_STATUS_REQUEST = "CHECK_STATUS_REQUEST";
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS";
export const CHECK_STATUS_ERROR = "CHECK_STATUS_ERROR";

export const FAILED_REASON_REQUEST = "FAILED_REASON_REQUEST";
export const FAILED_REASON_SUCCESS = "FAILED_REASON_SUCCESS";
export const FAILED_REASON_ERROR = "FAILED_REASON_ERROR";

export const INC_TARGET_TYPE_REQUEST = "INC_TARGET_TYPE_REQUEST";
export const INC_TARGET_TYPE_SUCCESS = "INC_TARGET_TYPE_SUCCESS";
export const INC_TARGET_TYPE_ERROR = "INC_TARGET_TYPE_ERROR";

export const TARGET_SALE_TYPE_REQUEST = "TARGET_SALE_TYPE_REQUEST";
export const TARGET_SALE_TYPE_SUCCESS = "TARGET_SALE_TYPE_SUCCESS";
export const TARGET_SALE_TYPE_ERROR = "TARGET_SALE_TYPE_ERROR";

export const PROMOTER_TYPE_LIST_REQUEST = "PROMOTER_TYPE_LIST_REQUEST";
export const PROMOTER_TYPE_LIST_SUCCESS = "PROMOTER_TYPE_LIST_SUCCESS";
export const PROMOTER_TYPE_LIST_ERROR = "PROMOTER_TYPE_LIST_ERROR";

export const STORE_TYPE_LIST_REQUEST = "STORE_TYPE_LIST_REQUEST";
export const STORE_TYPE_LIST_SUCCESS = "STORE_TYPE_LIST_SUCCESS";
export const STORE_TYPE_LIST_ERROR = "STORE_TYPE_LIST_ERROR";

export const ACCREDITATION_LIST_REQUEST = "ACCREDITATION_LIST_REQUEST";
export const ACCREDITATION_LIST_SUCCESS = "ACCREDITATION_LIST_SUCCESS";
export const ACCREDITATION_LIST_ERROR = "ACCREDITATION_LIST_ERROR";

export const FOCUS_PRODUCT_LIST_REQUEST = "FOCUS_PRODUCT_LIST_REQUEST";
export const FOCUS_PRODUCT_LIST_SUCCESS = "FOCUS_PRODUCT_LIST_SUCCESS";
export const FOCUS_PRODUCT_LIST_ERROR = "FOCUS_PRODUCT_LIST_ERROR";

export const GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID = "GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID";
export const GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_SUCCESS = "GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_SUCCESS";
export const GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_ERROR = "GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID_ERROR";

export const STORE_IDENTITY_REQUEST = "STORE_IDENTITY_REQUEST";
export const STORE_IDENTITY_SUCCESS = "STORE_IDENTITY_SUCCESS";
export const STORE_IDENTITY_ERROR = "STORE_IDENTITY_ERROR";

export const GET_ALL_REGION_AND_SUBREGION_REQUEST = "GET_ALL_REGION_AND_SUBREGION_REQUEST";
export const GET_ALL_REGION_AND_SUBREGION_REQUEST_SUCCESS = "GET_ALL_REGION_AND_SUBREGION_REQUEST_SUCCESS";
export const GET_ALL_REGION_AND_SUBREGION_REQUEST_ERROR = "GET_ALL_REGION_AND_SUBREGION_REQUEST_ERROR";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";

export const GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST = "GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST"
export const GET_DASHBOARD_FISCAL_YEAR_QUARTER_SUCCESS = "GET_DASHBOARD_FISCAL_YEAR_QUARTER_SUCCESS"
export const GET_DASHBOARD_FISCAL_YEAR_QUARTER_ERROR = "GET_DASHBOARD_FISCAL_YEAR_QUARTER_ERROR"