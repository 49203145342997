import { mapState } from "vuex";
import isEqual from "lodash/isEqual";

export const generalMixin = {
  computed: {
    ...mapState({
      defaultSelectedPeriod: state => state.utility.defaultSelectedPeriod
    }),
  },
  mounted() {
    this.checkAndUpdateFilters(this.defaultSelectedPeriod);
  },
  watch: {
    defaultSelectedPeriod(currentValue) {
      this.checkAndUpdateFilters(currentValue);
    },
  },
  methods: {
    checkAndUpdateFilters(defaultPeriod) {
      const { fiscalYear, quarter } = defaultPeriod;
      const { yearSelected, quarterSelected } = this.filters;

      if (!isEqual(fiscalYear, yearSelected.value) || !isEqual(quarter, quarterSelected.value)) {
        this.updateFilters();
      }
    },
    updateFilters() {
      this.filters.yearSelected = { 
        name: this.defaultSelectedPeriod.fiscalYear, 
        value: this.defaultSelectedPeriod.fiscalYear 
      };
      this.filters.quarterSelected = { 
        name: this.defaultSelectedPeriod.quarter, 
        value: this.defaultSelectedPeriod.quarter 
      };
    }
  }
}