<template>
  <div v-if="!isLoading" id="store-vs-category">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <div class="d-flex align-items-center">
        <span class="mr-4">
          <strong>Store Identity / Category Score</strong>
        </span>
        <div class="mr-4">
          <Multiselect
              ref="storeIdentitySelection"
              v-model="storeIdentitySelected"
              :searchable="false"
              :options="storeIdentityOptions"
              label="label"
              track-by="value"
              :loading="!storeIdentityOptions"
              :allow-empty="false"
              :show-labels="false"
              @input="(el)=>{storeIdentitySelected=el}"
            ></Multiselect>
        </div>
      </div>
      <div class="position-relative d-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="d-none"
        ></a>
      </div>
    </div>
        
    <div v-if="Object.keys(response).length !== 0">
      <RadarChart
        chart-title=" "
        height="600"
        width="100%"
        :series="totalScoringList"
        :polygon-fill-colors-array="polygonFillColorsArray"
        :polygon-stroke-color="polygonStrokeColor"
        :series-fill-opacity="0"
        :series-stroke-colors-array="getSectionColorList"
        :categories-array="sectionList"
        :y-axis-precision-number="polygonFillColorsArray.length"
        :custom-tooltip="customTooltip"
      />
    </div>
    <div v-else class="no-data">NO DATA</div>

  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import RadarChart from '@/components/dashboard/radar-chart.vue';
import cloneDeep from "lodash/cloneDeep"
import { DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET } from "@/store/modules/charts/actions";
import { ALL_STORE_IDENTITY_COLOR } from "@/constants"
import { limitDecimalNum } from "@/utils/helpers"
import Multiselect from "vue-multiselect";

export default {
  components: {
    RadarChart, 
    Multiselect
  },
  props: {
    filters: {type: Object, default: () => {}},
    enlargeChart: { type: Function, default: () => {} }
  },
  computed: {
    getSectionColorList() {
      let result = []
      this.totalScoringList.forEach(el => {
        const foundStoreIdty = ALL_STORE_IDENTITY_COLOR.find(el2 => el._value == el2.value)
        if (foundStoreIdty) result.push(foundStoreIdty.color)
      })
      return result
    },
    polygonStrokeColor() {
      let result = []

      for(var i = 0; i < this.polygonFillColorsArray.length; i++){
        if (i % 4 === 0) result.push('#c0a89e')
        else result.push('transparent')
      }
      return result
    }
  },
  data() {
    return {
      isLoading: true,
      response: {},
      totalScoringList: [],
      storeIdentitySelected: { label: "All", value: null },
      storeIdentityOptions: [
        ...ALL_STORE_IDENTITY_COLOR
      ],
      sectionList: [],
      allDetails: [],
      polygonFillColorsArray: [],
      link: ""
    }
  },
  watch: {
    storeIdentitySelected() { this.getData() }
  },
  methods: {
    getData() {
      this.isLoading = true
      
      let pdata = { filters: this.filters }
      pdata.filters.storeIdentity = this.storeIdentitySelected

      this.$store.dispatch(DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET, pdata).then(res => {
        if (res) {
          this.response = cloneDeep(res.content)
          if(!this.response) return
          else this.filterData()
        }
      })
      .finally(() => this.isLoading = false)

    },
    filterData() {
      this.allDetails = cloneDeep(this.response)

      const storeIdentityList = ALL_STORE_IDENTITY_COLOR.map(el => el.value)
      this.sectionList = [...new Set(this.allDetails.map(el => el.section))] // only return unique

      const getTotalScorePercByStoreInSectionList /* in each section */ = (storeIdty) => {
        const showAll = storeIdty == null
        const detailByStore = this.allDetails
        .filter(el => showAll ? true : el.storeIdentity == storeIdty)
        .sort((a, b) => this.sectionList.indexOf(a.section) - this.sectionList.indexOf(b.section))

        return this.sectionList.map(sec => {
          const secFiltered = detailByStore.filter(el => el.section == sec)
          let totalSubScore = 0
          let numOfSubScore = 0

          secFiltered.forEach(el => {
            totalSubScore += el.details.reduce(((total, el2) => total + +el2.score/el2.maxScore), 0)
            numOfSubScore += el.details.length
          })
          
          return limitDecimalNum(totalSubScore/numOfSubScore*100,1)
        })
      }

      // For the chart
      // Assume all store have the same section
      this.totalScoringList = storeIdentityList.map(storeIdty => {
        return {
          _value: storeIdty,
          name: ALL_STORE_IDENTITY_COLOR.find(el => el.value == storeIdty).label,
          data: getTotalScorePercByStoreInSectionList(storeIdty)
        }
      })
      .filter(el => {
        let isEmptyData = false
        let hideAll = this.storeIdentitySelected.value != null && el._value == null
        el.data.forEach(el2 => {
          isEmptyData = el2 == 0 || el2 == null
        })
        return !(isEmptyData || hideAll)
      })
    },

    getGrade(score) {
      if      (score >= 90 && score <= 100) return "A"
      else if (score >= 70 && score < 90)   return "B"
      else if (score >= 36 && score < 70)   return "C"
      else if (score >= 0 && score < 36)    return "D"
      else                                  return ""
    },
    initPolygonFillColorsArray() {
      const precision = 5 // make sure each score following divide by precision must be whole number
      // (90 <= score <= 100)
      for (let i = 0; i < (100-90)/precision; i++) this.polygonFillColorsArray.push('#ACD363')
      // (70 <= score <= 89)
      for (let i = 0; i < (90-70)/precision; i++) this.polygonFillColorsArray.push('#FDF751')
      // (36 <= score <= 69)
      for (let i = 0; i < (70-35)/precision; i++) this.polygonFillColorsArray.push('#EDA639')
      // (0 <= score <= 35)
      for (let i = 0; i < (35-0)/precision; i++) this.polygonFillColorsArray.push('#B02617')
    },
    customTooltip({ seriesIndex, dataPointIndex }) {
      // dataPointIndex == category index
      // seriesIndex == store identity index
      const storeIdentity = this.totalScoringList[seriesIndex]._value
      const storeIdentityName = this.totalScoringList[seriesIndex].name
      const category = this.sectionList[dataPointIndex]

      let result = `<div class="tooltip-container">`
      result += `
        <div class="tooltip-title">
          <span class="mr-2">${category}</span>
          <span class="float-right">(${storeIdentityName})</span>
        </div>
      `
      const detailHtml = (desc, score, grade) => `
        <div>
          <span class="mr-2" style="font-size: 1rem">${desc}</span>
          <span class="float-right"> ${score} (${grade})</span>
        </div>
      `
      this.getScoringDetailWithGrade(category, storeIdentity)
      .forEach(detail => {
        result += detailHtml(detail.desc, detail.score, detail.grade)
      })

      result += "</div>"
      return result
    },
    getScoringDetailWithGrade(category, storeIdentity) {
      let details = []

      const isShowAllStore = storeIdentity == null
      let filteredSections = this.allDetails.filter(el =>  el.section == category)
      if (!isShowAllStore) filteredSections = filteredSections.filter(el => el.storeIdentity == storeIdentity)

      filteredSections[0].details.forEach(subSec => {
        let avgScore = 0
        let maxScore = 0
        filteredSections.forEach(sec => {
          const weightage = sec.weightage
          sec.details
            .filter(el => el.subSection == subSec.subSection)
            .forEach(subSection2 => {
              if (isShowAllStore) {
                avgScore += subSection2.score * weightage
              } else {
                avgScore = subSection2.score
              }
              maxScore = subSection2.maxScore
            })
        })
        details.push({
          desc: subSec.subSection,
          score: limitDecimalNum(avgScore,1),
          grade: this.getGrade(avgScore*100/maxScore)
        })
      })

      return details
    },
    exportChart(event){
      event.preventDefault();
      let pdata = {
       filters: cloneDeep(this.filters)
      }
      pdata.filters.storeIdentity = this.storeIdentitySelected
      pdata.filters.isExport = true
      this.$store.dispatch(DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      })
    },
  },
  created() {
    this.initPolygonFillColorsArray()
  }
}
</script>
<style lang="scss">
#store-vs-category {
  position: relative;
  .dropdown {
    z-index: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .tooltip-container {
    padding: 1rem;
  }
  .tooltip-title {
    span {
      text-decoration: underline;
    }
  }
  .no-data {
    height: 500px;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
  }
  .multiselect {
    min-width: 150px;
  }
}
</style>