<template>
  <div>
    <p
      v-if="currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="currentAction === 'edit'" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_product_mtm')"></label>
            <input
              v-model="product.mtmNo"
              disabled
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.mtmNo = ''"
            />
            <span v-if="formErrors.mtmNo" class="invalid-field">{{ formErrors.mtmNo }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_product_srp')"></label>
            <span class="required-star">*</span>
            <input
              v-model="srp"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.srp = ''"
            />
            <span v-if="formErrors.srp" class="invalid-field">{{ formErrors.srp }}</span>
          </div>
        </div>        
      </div>
      <div class="row">
        <div class="col-lg-12">
          <!-- <button
              v-if="!outlet.id"
              type="submit"
              class="btn btn-secondary pull-right"
              v-text="$ml.get('form_outlet_add_button')"
          ></button> -->
          <button
              v-if="product"
              type="submit"
              class="btn btn-secondary pull-right"
              v-text="$ml.get('button_submit_text')"
          ></button>
          <button
              type="button"
              class="btn btn-light pull-right"
              data-dismiss="modal"
              @click="close()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>

</template>

<script>
import { PRODUCT_SKU_UPDATE_SRP } from "../../store/modules/product/actions";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";

export default {
  components: {},
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    productSelected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    currentAction: { type: String, default: "" },
    countryCode: { type: String, default: "" },
  },
  computed: {},
  data() {
    return {
      product: {
        mtmNo: this.productSelected.mtmNo,
        countryCode: this.productSelected.countryCode
      },
      srp: this.productSelected.srp,
      formErrors: [],
      submitted: false,
    };
  },
  methods: {
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },

    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.srp) {
        this.formErrors.srp = "SRP is required!";
        error++;
      }
      return !error;
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (!this.checkFormValidity(e)) {
        return;
      } else {
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_submitting")
        });
        let product = {...this.product};
        product.srp = Number(this.srp);
        this.$store.dispatch(PRODUCT_SKU_UPDATE_SRP, product).then(res => {
          if (res.resultCode === 0) {
            this.success();
            if (this.product.mtmNo) {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: this.$ml.get("form_product_srp_updated")
              });
            }
          }
        });
      }
    },
  },
  created() {},
  mounted() {}
};
</script>

