export const OUTLET_PIC_LIST_REQUEST = "OUTLET_PIC_LIST_REQUEST";
export const OUTLET_PIC_LIST_SUCCESS = "OUTLET_PIC_LIST_SUCCESS";
export const OUTLET_PIC_LIST_ERROR = "OUTLET_PIC_LIST_ERROR";

export const GET_PIC_LIST_BY_DEALER_ID_REQUEST = "GET_PIC_LIST_BY_DEALER_ID_REQUEST";
export const GET_PIC_LIST_BY_DEALER_ID_SUCCESS = "GET_PIC_LIST_BY_DEALER_ID_SUCCESS";
export const GET_PIC_LIST_BY_DEALER_ID_ERROR = "GET_PIC_LIST_BY_DEALER_ID_ERROR";

export const OUTLET_PIC_REQUEST = "OUTLET_PIC_REQUEST";
export const OUTLET_PIC_SUCCESS = "OUTLET_PIC_SUCCESS";
export const OUTLET_PIC_ERROR = "OUTLET_PIC_ERROR";
export const OUTLET_PIC_POST_REQUEST = "OUTLET_PIC_POST_REQUEST";
export const OUTLET_PIC_POST_SUCCESS = "OUTLET_PIC_POST_SUCCESS";
export const OUTLET_PIC_POST_ERROR = "OUTLET_PIC_POST_ERROR";

export const OUTLET_LIST_REQUEST = "OUTLET_LIST_REQUEST";
export const OUTLET_LIST_SUCCESS = "OUTLET_LIST_SUCCESS";
export const OUTLET_LIST_ERROR = "OUTLET_LIST_ERROR";
export const OUTLETS_LIST_WITH_DEALERS_REQUEST = "OUTLETS_LIST_WITH_DEALERS_REQUEST";
export const OUTLETS_LIST_WITH_DEALERS_SUCCESS = "OUTLETS_LIST_WITH_DEALERS_SUCCESS";
export const OUTLETS_LIST_WITH_DEALERS_ERROR = "OUTLETS_LIST_WITH_DEALERS_ERROR";
export const OUTLET_WITH_PIC_LIST_REQUEST = "OUTLET_WITH_PIC_LIST_REQUEST";
export const OUTLET_WITH_PIC_LIST_SUCCESS = "OUTLET_WITH_PIC_LIST_SUCCESS";
export const OUTLET_WITH_PIC_LIST_ERROR = "OUTLET_WITH_PIC_LIST_ERROR";

export const OUTLET_REQUEST = "OUTLET_REQUEST";
export const OUTLET_SUCCESS = "OUTLET_SUCCESS";
export const OUTLET_ERROR = "OUTLET_ERROR";
export const OUTLET_POST_REQUEST = "OUTLET_POST_REQUEST";
export const OUTLET_POST_SUCCESS = "OUTLET_POST_SUCCESS";
export const OUTLET_POST_ERROR = "OUTLET_POST_ERROR";
export const OUTLET_BULK_UPLOAD_REQUEST = "OUTLET_BULK_UPLOAD_REQUEST";
export const OUTLET_BULK_UPLOAD_SUCCESS = "OUTLET_BULK_UPLOAD_SUCCESS";
export const OUTLET_BULK_UPLOAD_ERROR = "OUTLET_BULK_UPLOAD_ERROR";
export const OUTLET_CHANGES_REQUEST = "OUTLET_CHANGES_REQUEST";
export const OUTLET_CHANGES_SUCCESS = "OUTLET_CHANGES_SUCCESS";
export const OUTLET_CHANGES_ERROR = "OUTLET_CHANGES_ERROR";

export const DEALER_OUTLET_LIST_REQUEST = "DEALER_OUTLET_LIST_REQUEST";
export const DEALER_OUTLET_LIST_SUCCESS = "DEALER_OUTLET_LIST_SUCCESS";
export const DEALER_OUTLET_LIST_ERROR = "DEALER_OUTLET_LIST_ERROR";

export const EXPORT_DEALER_OUTLET_DATA_REQUEST = "EXPORT_DEALER_OUTLET_DATA_REQUEST";
export const EXPORT_DEALER_OUTLET_DATA_SUCCESS = "EXPORT_DEALER_OUTLET_DATA_SUCCESS";
export const EXPORT_DEALER_OUTLET_DATA_ERROR = "EXPORT_DEALER_OUTLET_DATA_ERROR";

export const ALL_DEALER_OUTLETS_BY_REGION_REQUEST = "ALL_DEALER_OUTLETS_BY_REGION_REQUEST";
export const ALL_DEALER_OUTLETS_BY_REGION_SUCCESS = "ALL_DEALER_OUTLETS_BY_REGION_SUCCESS";
export const ALL_DEALER_OUTLETS_BY_REGION_ERROR = "ALL_DEALER_OUTLETS_BY_REGION_ERROR";

export const MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST = "MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST";
export const MERCHANDISER_VISIT_BY_OUTLET_ID_SUCCESS = "MERCHANDISER_VISIT_BY_OUTLET_ID_SUCCESS";
export const MERCHANDISER_VISIT_BY_OUTLET_ID_ERROR = "MERCHANDISER_VISIT_BY_OUTLET_ID_ERROR";

export const TASK_BY_VISITATION_ID_REQUEST = "TASK_BY_VISITATION_ID_REQUEST";
export const TASK_BY_VISITATION_ID_SUCCESS = "TASK_BY_VISITATION_ID_SUCCESS";
export const TASK_BY_VISITATION_ID_ERROR = "TASK_BY_VISITATION_ID_ERROR";