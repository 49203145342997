//Regional Dashboard
export const DASHBOARD_TOP_ROW_DATA_GET = "DASHBOARD_TOP_ROW_DATA_GET";
export const DASHBOARD_TOP_ROW_DATA_GET_SUCCESS = "DASHBOARD_TOP_ROW_DATA_GET_SUCCESS";
export const DASHBOARD_TOP_ROW_DATA_GET_ERROR = "DASHBOARD_TOP_ROW_DATA_GET_ERROR";
export const DASHBOARD_TOP_FILTERS_GET = "DASHBOARD_TOP_FILTERS_GET";
export const DASHBOARD_TOP_FILTERS_GET_SUCCESS = "DASHBOARD_TOP_FILTERS_GET_SUCCESS";
export const DASHBOARD_TOP_FILTERS_GET_ERROR = "DASHBOARD_TOP_FILTERS_GET_ERROR";
export const DASHBOARD_PRODUCT_FILTERS_GET = "DASHBOARD_PRODUCT_FILTERS_GET";
export const DASHBOARD_PRODUCT_FILTERS_GET_SUCCESS = "DASHBOARD_PRODUCT_FILTERS_GET_SUCCESS";
export const DASHBOARD_PRODUCT_FILTERS_GET_ERROR = "DASHBOARD_PRODUCT_FILTERS_GET_ERROR";
export const DASHBOARD_COMPONENT_FILTERS_GET = "DASHBOARD_COMPONENT_FILTERS_GET";
export const DASHBOARD_COMPONENT_FILTERS_GET_SUCCESS = "DASHBOARD_COMPONENT_FILTERS_GET_SUCCESS";
export const DASHBOARD_COMPONENT_FILTERS_GET_ERROR = "DASHBOARD_COMPONENT_FILTERS_GET_ERROR";
export const REGIONAL_DASHBOARD_DATA_GET = "REGIONAL_DASHBOARD_DATA_GET";
export const REGIONAL_DASHBOARD_DATA_GET_SUCCESS = "REGIONAL_DASHBOARD_DATA_GET_SUCCESS";
export const REGIONAL_DASHBOARD_DATA_GET_ERROR = "REGIONAL_DASHBOARD_DATA_GET_ERROR";
export const REGIONAL_V2_DASHBOARD_DATA_GET = "REGIONAL_V2_DASHBOARD_DATA_GET";
export const REGIONAL_V2_DASHBOARD_DATA_GET_SUCCESS = "REGIONAL_V2_DASHBOARD_DATA_GET_SUCCESS";
export const REGIONAL_V2_DASHBOARD_DATA_GET_ERROR = "REGIONAL_V2_DASHBOARD_DATA_GET_ERROR";

//Country Dashboard
export const CDASHBOARD_TOP_FILTERS_GET = "CDASHBOARD_TOP_FILTERS_GET";
export const CDASHBOARD_TOP_FILTERS_GET_SUCCESS = "CDASHBOARD_TOP_FILTERS_GET_SUCCESS";
export const CDASHBOARD_TOP_FILTERS_GET_ERROR = "CDASHBOARD_TOP_FILTERS_GET_ERROR";
export const CDASHBOARD_PRODUCT_FILTERS_GET = "CDASHBOARD_PRODUCT_FILTERS_GET";
export const CDASHBOARD_PRODUCT_FILTERS_GET_SUCCESS = "CDASHBOARD_PRODUCT_FILTERS_GET_SUCCESS";
export const CDASHBOARD_PRODUCT_FILTERS_GET_ERROR = "CDASHBOARD_PRODUCT_FILTERS_GET_ERROR";
export const CDASHBOARD_COMPONENT_FILTERS_GET = "CDASHBOARD_COMPONENT_FILTERS_GET";
export const CDASHBOARD_COMPONENT_FILTERS_GET_SUCCESS = "CDASHBOARD_COMPONENT_FILTERS_GET_SUCCESS";
export const CDASHBOARD_COMPONENT_FILTERS_GET_ERROR = "CDASHBOARD_COMPONENT_FILTERS_GET_ERROR";
export const CDASHBOARD_DATA_GET = "CDASHBOARD_DATA_GET";
export const CDASHBOARD_DATA_GET_SUCCESS = "CDASHBOARD_DATA_GET_SUCCESS";
export const CDASHBOARD_DATA_GET_ERROR = "CDASHBOARD_DATA_GET_ERROR";

// Dealer Dashboard
export const DASHBOARD_DEALER_TOP_ROW_DATA_GET = "DASHBOARD_DEALER_TOP_ROW_DATA_GET";
export const DASHBOARD_DEALER_TOP_ROW_DATA_GET_SUCCESS = "DASHBOARD_DEALER_TOP_ROW_DATA_GET_SUCCESS";
export const DASHBOARD_DEALER_TOP_ROW_DATA_GET_ERROR = "DASHBOARD_DEALER_TOP_ROW_DATA_GET_ERROR";
