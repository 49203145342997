<template>
  <div class="row page-titles">
    <div class="col p-md-0">
      <h4>{{ this.$route.name }}</h4>
    </div>
    <div class="col p-md-0">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li v-if="subtitle" class="breadcrumb-item" :class="{ active: !subSubTitle }">
          <router-link 
            v-if="!subSubTitle" 
            :to="subTitleUrl || this.$route"
          >{{ subtitle }}</router-link>

          <router-link 
            v-else-if="this.$router.resolve(subtitle).route.name !== ''" 
            :to="subTitleUrl || { name: '' }"
          >{{ subtitle }}</router-link>

          <router-link 
            v-else 
            :to="subTitleUrl || { name: '' }"
          >{{ subtitle }}</router-link>
        </li>
        <li v-if="subSubTitle" class="breadcrumb-item active">
          <router-link :to="subSubTitleUrl || this.$route">{{ subSubTitle }}</router-link>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    subtitle: { type: String, default: "" },
    subSubTitle: { type: String, default: "" },

    subTitleUrl: { type: String, default: null  },
    subSubTitleUrl: { type: String, default: null },
  }
};
</script>

<style></style>
