<script>
import { mapGetters, mapState } from "vuex";
import Header from "../components/header/header.vue";
import Sidebar from "../components/sidebar/sidebar.vue";
import Footer from "../components/footer/footer.vue";
import { EventBus } from "@/utils/EventBus";
import { GET_UNREAD_MESSAGE_COUNT_REQUEST } from "../store/modules/message/actions";
import { GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST } from "@/store/modules/utility/actions";
import store from "@/store";

export default {
  name: "Admin",
  components: {
    "admin-header": Header,
    "admin-sidebar": Sidebar,
    "admin-footer": Footer
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading"
    })
  },
  data() {
    return {
      unreadCount: 0,
      pendingApprovalCount: 0,
      missingSRPCount: 0,
      unknownSerialNumber: 0,
      countryId: null,
      getUserId: 0,
      reRenderComponent: false,
      pendingApprovalCountDealer: 0,
      pendingApprovalCountDealerOutlet: 0,
      pendingApprovalCountPromoter: 0,
      pendingApprovalCountPromoterLeader: 0,
      pendingApprovalCountPromoterUpdate: 0,
      pendingApprovalCountPromoterLeaderUpdate: 0,
      pendingApprovalCountMerchandiser: 0,
      pendingApprovalCountMerchandiserLeader: 0,
    };
  },
  watch: {
    getUserId: function() {}
  },
  methods: {
    getUnreadMessages() {
      if (this.getUserId > 0) {
        let pdata = {
          countryId: this.countryId
        };
        this.$store
          .dispatch(GET_UNREAD_MESSAGE_COUNT_REQUEST, pdata)
          .then(res => {
            if (res.resultCode === 0) {
              this.unreadCount = res.content.privateMessageCount;
              this.pendingApprovalCount = res.content.pendingApprovalCount;
              this.missingSRPCount = res.content.missingSRPCount;
              this.unknownSerialNumber = res.content.unknownSerialNumber;
              this.pendingApprovalCountDealer = res.content.pendingApprovalCountDealer;
              this.pendingApprovalCountDealerOutlet = res.content.pendingApprovalCountDealerOutlet;
              this.pendingApprovalCountPromoter = res.content.pendingApprovalCountPromoter;
              this.pendingApprovalCountPromoterLeader = res.content.pendingApprovalCountPromoterLeader;
              this.pendingApprovalCountPromoterUpdate = res.content.pendingApprovalCountPromoterUpdate;
              this.pendingApprovalCountPromoterLeaderUpdate = res.content.pendingApprovalCountPromoterLeaderUpdate;
              this.pendingApprovalCountMerchandiser = res.content.pendingApprovalCountMerchandiser;
              this.pendingApprovalCountMerchandiserLeader = res.content.pendingApprovalCountMerchandiserLeader;
            }
          });
      }
    },
    getDefaultFiscalYearQuarter() {
      const payload = {code: "dashboard_fiscal_year_quarter"}

      this.$store.dispatch(GET_DASHBOARD_FISCAL_YEAR_QUARTER_REQUEST, payload)
    },
  },
  mounted() {
    this.getUserId = this.$store.getters.getUserId;
    this.countryId = this.$store.getters.getCountryId;
    this.getUnreadMessages();

    EventBus.$on("getUnreadData", () => {
      this.getUnreadMessages();
    });

    EventBus.$on("userLogout", () => {
      const url = new URL('/signin', window.location.origin)
      window.location.href = url.toString()
    });

    EventBus.$on("reRenderApp", data => {
      let userData = JSON.parse(localStorage.getItem("user")) || {};
      let userId = userData.user ? userData.user.id : 0;

      if (userId != this.getUserId) {
        location.reload();
      }
      this.reRenderComponent = data;
    });

    if (store.getters.isAuthenticated) {
      this.getDefaultFiscalYearQuarter();
    }
  }
};

// eslint-disable-next-line no-unused-vars
(function($) {
  "use strict";

  var vis = (function() {
    var stateKey,
      eventKey,
      keys = {
        hidden: "visibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
        msHidden: "msvisibilitychange"
      };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    return function(c) {
      if (c) document.addEventListener(eventKey, c);
      return !document[stateKey];
    };
  })();

  // eslint-disable-next-line no-unused-vars
  var visible = vis(); // gives current state

  vis(); // registers a handler for visibility changes`

  vis(function() {
    if (vis()) {
      EventBus.$emit("reRenderApp", true);
      document.title = "Lenovo CMS ";
    } else {
      EventBus.$emit("reRenderApp", false);
      document.title = "Lenovo CMS | Offline";
    }
  });
  // eslint-disable-next-line no-undef
})(jQuery);
</script>

<template>
  <div>
    <div id="main-wrapper" class="lnv-wrapper">
      <admin-header
        :pending-approval-count="pendingApprovalCount"
        :unread-messages="unreadCount"
        :recount-messages="getUnreadMessages"
        :re-render-component="reRenderComponent"
      />
      <admin-sidebar
        :pending-approval-count="pendingApprovalCount"
        :pending-approval-count-dealer="pendingApprovalCountDealer"
        :pending-approval-count-dealer-outlet="pendingApprovalCountDealerOutlet"
        :pending-approval-count-promoter="pendingApprovalCountPromoter"
        :pending-approval-count-promoter-leader="pendingApprovalCountPromoterLeader"
        :pending-approval-count-promoter-update="pendingApprovalCountPromoterUpdate"
        :pending-approval-count-promoter-leader-update="pendingApprovalCountPromoterLeaderUpdate"
        :pending-approval-count-merchandiser="pendingApprovalCountMerchandiser"
        :pending-approval-count-merchandiser-leader="pendingApprovalCountMerchandiserLeader"
        :missing-srp-count="missingSRPCount"
        :unread-messages="unreadCount"
        :unknown-serial-number="unknownSerialNumber"
        :recount-messages="getUnreadMessages"
        :re-render-component="reRenderComponent"
      />
      <div class="lnv-container">
        <div class="content-body main-container">
          <div class="container-fluid admin-layout-body">
            <!-- <router-view></router-view> -->
            <slot
              :pending-approval-count="pendingApprovalCount"
              :count-messages="getUnreadMessages"
              :re-render-component="reRenderComponent"
            />
          </div>
        </div>
        <admin-footer />
      </div>
    </div>
  </div>
</template>
