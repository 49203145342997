<template>
  <div id="promoter-attendance-download">
    <breadcrumb
      title="Dashboard"
      subtitle="Download"
      sub-sub-title="Promoter Attendance"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row">
                <div class="col-lg-12 add-employee-button d-flex align-items-center mb-4 flex-row-reverse">
                  <button class="btn btn-sm btn-primary m-0" :disabled="isDataLoading" @click="doExport">
                    <i class="fa fa-file-excel-o"></i>
                    <span v-text="$ml.get('export_to_excel')"></span>
                  </button>
                  <br>
                  <br>
                  <br>
                </div>
              </div>
              <div class="row mb-3">
                <div v-if="isCapAdmin || isCap" class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-4'" class="d-flex justify-content-end" style="flex-direction: column">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                      @input="getPromoterAttendanceDate"
                  ></Multiselect>
                </div>
                <div :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-4'" class="d-flex justify-content-end" style="flex-direction: column">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                      @input="getPromoterAttendanceDate"
                  ></Multiselect>
                </div>
                <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
                  <div class="form-group flex-grow-1 mb-0 d-flex justify-content-end" style="flex-direction: column; margin-bottom: 0!important">
                    <label
                      class="text-label"
                      v-text="$ml.get('att_title_period')"
                    ></label>
                    <DateRangePicker
                      ref="picker"
                      v-model="dateRange"
                      :date-range="dateRange"
                      :opens="datePicker.opens"
                      :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                      :min-date="datePicker.minDate"
                      :max-date="datePicker.maxDate"
                      :single-date-picker="datePicker.singleDatePicker"
                      :time-picker="datePicker.timePicker"
                      :time-picker24hour="datePicker.timePicker24Hour"
                      :show-week-numbers="datePicker.showWeekNumbers"
                      :show-dropdowns="datePicker.showDropdowns"
                      :auto-apply="datePicker.autoApply"
                      :linked-calendars="datePicker.linkedCalendars"
                      :date-format="datePicker.dateFormat"
                      :ranges="datePicker.ranges"
                    >
                      <div
                        slot="input"
                        slot-scope="picker"
                        style="min-width: 310px; width: 100%; float: left;"
                      >
                        <span v-if="picker.startDate && picker.endDate">{{ formatDate(picker.startDate) }} to
                          {{ formatDate(picker.endDate) }}</span>
                      </div>
                    </DateRangePicker>
                  </div>
                </div>
                <div class="col-lg-1 pl-0 d-flex justify-content-end" style="flex-direction: column">
                  <button class="btn btn-sm btn-primary mb-0 ml-0" style="margin-top:24px;height:40px;width: 100%" :disabled="isDataLoading" @click="getPromoterAttendance">
                    <span>Search</span>
                  </button>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-lg-12">
                  <div class="form-group flex-grow-1">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="searchAttendanceData(1)"
                    >
                      <i class="fa fa-download"></i>
                      <span v-text="$ml.get('button_download_text')"></span>
                    </button>
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col-lg-12 promoterAttendanceTheaderNoWrap" style="width: 1px;">
                  <VueBootstrap4Table
                    :classes="classes"
                    :rows="dataList"
                    :columns="columns"
                    :config="config"
                    :total-rows="total"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isDataLoading"
                        class="bold-text"
                        v-text="$ml.get('no_data_found')"
                      ></div>
                      <div
                        v-if="isDataLoading"
                        class="loading mt-3"
                      ></div>
                    </template>

                    <template slot="checkInDateTime" slot-scope="props">
                      <span v-if="props.row.checkInDateTime">
                        {{
                          changeDateFormat(props.row.checkInDateTime,"YYYY-MM-DD hh:mm A")
                        }}</span>
                    </template>

                    <template slot="checkOutDateTime" slot-scope="props">
                      <span v-if="props.row.checkOutDateTime">
                        {{
                          changeDateFormat(props.row.checkOutDateTime,"YYYY-MM-DD hh:mm A")
                        }}</span>
                    </template>

                    <template slot="checkInDistance" slot-scope="props">
                      <span v-if="props.row.checkInDistance" :style="props.row.checkInDistance > 2 && 'color: red'">
                        {{
                          props.row.checkInDistance.toFixed(2)
                        }}</span>
                    </template>

                    <template slot="checkOutDistance" slot-scope="props">
                      <span v-if="props.row.checkOutDistance" :style="props.row.checkOutDistance > 2 && 'color: red'">
                        {{
                          props.row.checkOutDistance.toFixed(2)
                        }}</span>
                    </template>

                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { convertDateFormat, defaultFiscalYear, defaultQuarter } from "../../utils/helpers";
import { export_json_to_excel } from "../../utils/Export2Excel";
import DateRangePicker from "../../components/ui/vue2-daterange-picker";
import "../../components/ui/vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST,
  PROMOTER_ATTENDANCE_DATE_REQUEST
} from "../../store/modules/download/actions";
import { Message } from "element-ui";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
//import {GET_STAFF_PROMOTER_LIST_REQUEST} from "@/store/modules/staff/actions";

const today = new Date();

export default {
  name: "PromoterAttDPage",
  components: {
    breadcrumb: breadcrumb,
    DateRangePicker: DateRangePicker,
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table,
  },
  computed: {},
  data() {
    return {
      total: 0,
      dataList: [],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      dateOption:[],
      filters: {
        countrySelected: null,
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      },
      dateRange: {
        startDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth(), 1),
          "YYYY-MM-DD"
        ),
        endDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
          "YYYY-MM-DD"
        )
      },
      datePicker: {
        opens: "center",
        // minDate: "2019-08-01",
        // maxDate: "2019-12-31",
        singleDatePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        timePicker: false,
        autoApply: false,
        ranges: false
      },
      search: {},
      isCapAdmin: false,
      isCap: false,
      countries: [],
      isCountriesLoading: false,
      isDataLoading:false,
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Attendance",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "country",
          label: "CTRY",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "region",
          label: "Region",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "subRegion",
          label: "Sub Region",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "outletCode",
          label: "Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "promoterName",
          label: "Promoter Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "checkInDateTime",
          label: "CI Date Time",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          initial_sort: true,
          initial_sort_order: "asc",
        },
        {
          name: "checkOutDateTime",
          label: "CO Date Time",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "checkInDistance",
          label: "CI Distance (km)",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "checkOutDistance",
          label: "CO Distance (km)",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
      ]
    };
  },
  methods: {
    changeDateFormat(date, format) {
      return convertDateFormat(date, format);
    },

    doExport() {
      let tHeader = [];
      let filterVal = [];

      tHeader = [
        "Promoter Name",
        "User ID",
        "User Role",
        "Promoter Type",
        "Join Date",
        "Promoter Status",
        "Supervisor Name",
        "Supervisor Code",
        "Supervisor Email",
        "Supervisor Status",
        "Outlet Name",
        "Outlet Code",
        "Outlet Category",
        "Address",
        "Region",
        "Sub Region",
        "State",
        "Country",
        "Outlet Type",
        "Focus Product",
        "Accreditation",
        "Status",
        "Dealer Name",
        "Dealer LPP Code",
        "Dealer Account Manager Name",
        "Dealer Status",
        "Check-In Date Time",
        "Check-Out Date Time",
        "Default Time Check-In",
        "Default Time Check-Out",
        "Check-in distance (km)",
        "Check-out distance (km)",
        "Late Check-In",
        "Early Check-Out",
        "Fiscal Year",
        "Quarter",
        "Month",
        "Week",
        "Image URL"
      ];
      filterVal = [
        "promoterName",
        "promoterId", //new 20/03/10
        "userRole",
        "promoterTypeName",
        "joinedDate",
        "staffStatus",
        "supervisorName",
        "supervisorId",
        "supervisorEmail",
        "supervisorStatus",
        "outletName",
        "outletCode",
        "outletCategory",
        "outletAddress",
        "region",
        "subRegion",
        "state",
        "country",
        "outletType",
        "focusProduct",
        "accreditation",
        "outletStatus",
        "dealerName", //new 19/10/02
        "lppCode", //new 19/10/02
        "accountManager",
        "dealerStatus",
        "checkInDateTime",
        "checkOutDateTime",
        "defaultCheckInTime",
        "defaultCheckOutTime",
        "checkInDistance",
        "checkOutDistance",
        "lateCheckInDuration",
        "earlyCheckOutDuration",
        "fiscalYear",
        "quarter",
        "month",
        "week",
        "checkInPhotoUrl"
      ];

      const data = this.formatJson(filterVal, this.dataList);
      const fileName = "promoterAttendance-" + this.dateRange.startDate + "-" + this.dateRange.endDate;

      if (this.dataList.length <= 0) {
        Message({
          message: "No attendance records found on selected period!",
          type: "error",
          duration: 5 * 1000
        });
      } else {
        export_json_to_excel({
          header: tHeader,
          data,
          filename: fileName,
          sheetName: "promoterAttendance",
          autoWidth: true,
          bookType: "xlsx"
        });
      }
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "joinedDate" || j === "checkInDateTime" || j === "checkOutDateTime") {
            if (v[j] !== null) {
              return convertDateFormat(v[j], "YYYY-MM-DD h:m A")
            }
            return v[j];
          } else if(j === "staffStatus" || j === "supervisorStatus" || j === "outletStatus" || j === "dealerStatus"){
            if (v[j] === true || v[j] === "ACTIVE") {
              return "Active"
            }
            return "Deactivated";
          } else if(j === "userRole"){
            return v[j].charAt(0).toUpperCase() + v[j].slice(1).toLowerCase()
          } else if (j === "promoterTypeName") {
            if (v[j] === null) {
              return "-";
            }
            return v[j]
          } else if (j === "checkInDistance" || j === "checkOutDistance"){
            if(v[j] !== null){
              return v[j].toFixed(2)
            }
            return v[j]
          }else {
            return v[j];
          }
        })
      );
    },

    formatDate: function(date) {
      return convertDateFormat(String(date), "YYYY-MM-DD");
    },
    formatTime: function(date) {
      return convertDateFormat(String(date), "h:m A");
    },



    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },

    async getPromoterAttendanceDate() {
      let pdata = {
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
      };
      this.isDataLoading = true;
      this.dateOption = [];

      await this.$store.dispatch(PROMOTER_ATTENDANCE_DATE_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.dateOption = res.content;
          this.dateRange.startDate = convertDateFormat(this.dateOption.startDate);
          this.dateRange.endDate = convertDateFormat(this.dateOption.endDate);
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
      });
    },
    async getPromoterAttendance() {
      this.dataList = [];
      let startDate = convertDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      let endDate = convertDateFormat(this.dateRange.endDate, "YYYY-MM-DD");
      let countryCode = this.filters.countrySelected.value || this.filters.countrySelected;
      this.isDataLoading = true;

      let pData = {
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode
      };

      if (this.isCapAdmin && this.isCap) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      this.$store.dispatch(DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST, pData)
          .then(res => {
            if (res.content && res.resultCode === 0) {
              this.total = this.dataList.length;
              res.content.forEach(d => {
                let row = d;
                row.checkInDate = d.checkInDateTime;
                row.checkOutDate = d.checkOutDateTime;
                this.dataList.push(row);
              });
              this.isDataLoading = false;
              this.total = this.dataList.length;
            }
            else{
              this.isDataLoading = false;
            }
          });
    },
  },
  async mounted() {
    // this.getCountries();
    this.getFilters();
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.filters.countrySelected = this.isCapAdmin || this.isCap 
      ? this.$store.getters.topFilterCountryData : this.$store.getters.getCountryCode;
    await this.getPromoterAttendanceDate();
    this.getPromoterAttendance();
  },
  created() {
    // this.getNewsList();
  }
};
</script>
<style lang="scss">
.promoterAttendanceTheaderNoWrap {
  .table > thead > tr > th {
    white-space: nowrap !important;
  }
}
</style>
