import request from "@/utils/request";
import {
  GET_ALL_APP_CALENDAR_SERVICE,
  API_UPLOAD_OPS_CALENDAR
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

export async function getCalendarItemList(postData) {
  return await request({
    url: GET_ALL_APP_CALENDAR_SERVICE + `?fyf=${postData.year}` + `&qf=Q${postData.quarter}`,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadOpsCalendar(postData) {
  return await request({
    url: API_UPLOAD_OPS_CALENDAR,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}