export const UPLOAD_BULK_SALE_REQUEST = "UPLOAD_BULK_SALE_REQUEST";
export const UPLOAD_BULK_SALE_SUCCESS = "UPLOAD_BULK_SALE_SUCCESS";
export const UPLOAD_BULK_SALE_ERROR = "UPLOAD_BULK_SALE_ERROR";

export const GET_BULK_SALE_REQUEST = "GET_BULK_SALE_REQUEST";
export const GET_BULK_SALE_SUCCESS = "GET_BULK_SALE_SUCCESS";
export const GET_BULK_SALE_ERROR = "GET_BULK_SALE_ERROR";

export const DOWNLOAD_BULK_SALE_REQUEST = "DOWNLOAD_BULK_SALE_REQUEST";
export const DOWNLOAD_BULK_SALE_SUCCESS = "DOWNLOAD_BULK_SALE_SUCCESS";
export const DOWNLOAD_BULK_SALE_ERROR = "DOWNLOAD_BULK_SALE_ERROR";

export const UNKNOWN_SERIAL_REQUEST = "UNKNOWN_SERIAL_REQUEST";
export const UNKNOWN_SERIAL_SUCCESS = "UNKNOWN_SERIAL_SUCCESS";
export const UNKNOWN_SERIAL_ERROR = "UNKNOWN_SERIAL_ERROR";

export const GET_SALES_DATA_REQUEST = "GET_SALES_DATA_REQUEST";
export const GET_SALES_DATA_SUCCESS = "GET_SALES_DATA_SUCCESS";
export const GET_SALES_DATA_ERROR = "GET_SALES_DATA_ERROR";

export const UPDATE_SALES_DATA_REQUEST = "UPDATE_SALES_DATA_REQUEST";
export const UPDATE_SALES_DATA_SUCCESS = "UPDATE_SALES_DATA_SUCCESS";
export const UPDATE_SALES_DATA_ERROR = "UPDATE_SALES_DATA_ERROR";

export const DOWNLOAD_SALES_DATA_REQUEST = "DOWNLOAD_SALES_DATA_REQUEST";
export const DOWNLOAD_SALES_DATA_SUCCESS = "DOWNLOAD_SALES_DATA_SUCCESS";
export const DOWNLOAD_SALES_DATA_ERROR = "DOWNLOAD_SALES_DATA_ERROR";

export const CHECK_SERIAL_REQUEST = "CHECK_SERIAL_REQUEST";
export const CHECK_SERIAL_SUCCESS = "CHECK_SERIAL_SUCCESS";
export const CHECK_SERIAL_ERROR = "CHECK_SERIAL_ERROR";

export const SALES_DATA_SEND_EMAIL_REQUEST = "SALES_DATA_SEND_EMAIL_REQUEST";
export const SALES_DATA_SEND_EMAIL_SUCCESS = "SALES_DATA_SEND_EMAIL_SUCCESS";
export const SALES_DATA_SEND_EMAIL_ERROR = "SALES_DATA_SEND_EMAIL_ERROR";

export const EXPORT_SALES_DATA_REQUEST = "EXPORT_SALES_DATA_REQUEST";
export const EXPORT_SALES_DATA_SUCCESS = "EXPORT_SALES_DATA_SUCCESS";
export const EXPORT_SALES_DATA_ERROR = "EXPORT_SALES_DATA_ERROR";

export const VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST = "VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST";
export const VALIDATE_UNKNOWN_SERIAL_NUMBER_SUCCESS = "VALIDATE_UNKNOWN_SERIAL_NUMBER_SUCCESS";
export const VALIDATE_UNKNOWN_SERIAL_NUMBER_ERROR = "VALIDATE_UNKNOWN_SERIAL_NUMBER_ERROR";

export const DOWNLOAD_RETAIL_BMS_REQUEST = "DOWNLOAD_RETAIL_BMS_REQUEST";
export const DOWNLOAD_RETAIL_BMS_SUCCESS = "DOWNLOAD_RETAIL_BMS_SUCCESS";
export const DOWNLOAD_RETAIL_BMS_ERROR = "DOWNLOAD_RETAIL_BMS_ERROR";