import request from "@/utils/request";
import {
  GET_LOCK_SERIAL_SERVICE,
  ADD_LOCK_SERIAL_SERVICE,
  UPDATE_LOCK_SERIAL_SERVICE,
  CANCEL_LOCK_SERIAL_SERVICE,
  DOWNLOAD_LOCK_SERIAL_SERVICE
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

export async function getLockSerialList(postData) {
  return await request({
    url: GET_LOCK_SERIAL_SERVICE,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function lockSerialNumber(postData) {
  let url = ADD_LOCK_SERIAL_SERVICE;
  let method = "post";

  if (postData.id) {
    //Update
    url = UPDATE_LOCK_SERIAL_SERVICE;
    method = "put";
  }

  return await request({
    url: url,
    method: method,
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function cancelSerialNumber(postData) {
  return await request({
    url: CANCEL_LOCK_SERIAL_SERVICE,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadLockSerialNumber(postData) {
  if (postData) {
    let id = "/" + postData;
    return await request({
      url: DOWNLOAD_LOCK_SERIAL_SERVICE + id,
      method: "get",
      param: id,
      timeout: DEFAULT_TIMEOUT
    });
  } else {
    return await request({
      url: DOWNLOAD_LOCK_SERIAL_SERVICE,
      method: "get",
      timeout: DEFAULT_TIMEOUT
    });
  }
}
