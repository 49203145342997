export const MESSAGE_USER_LIST_REQUEST = "MESSAGE_USER_LIST_REQUEST";
export const MESSAGE_USER_LIST_SUCCESS = "MESSAGE_USER_LIST_SUCCESS";
export const MESSAGE_USER_LIST_ERROR = "MESSAGE_USER_LIST_ERROR";

export const USER_MESSAGE_LIST_REQUEST = "USER_MESSAGE_LIST_REQUEST";
export const USER_MESSAGE_LIST_SUCCESS = "USER_MESSAGE_LIST_SUCCESS";
export const USER_MESSAGE_LIST_ERROR = "USER_MESSAGE_LIST_ERROR";

export const MESSAGE_POST_REQUEST = "MESSAGE_POST_REQUEST";
export const MESSAGE_POST_SUCCESS = "MESSAGE_POST_SUCCESS";
export const MESSAGE_POST_ERROR = "MESSAGE_POST_ERROR";

export const ADD_MESSAGE_TREAD_REQUEST = "ADD_MESSAGE_TREAD_REQUEST";
export const ADD_MESSAGE_TREAD_SUCCESS = "ADD_MESSAGE_TREAD_SUCCESS";
export const ADD_MESSAGE_TREAD_ERROR = "ADD_MESSAGE_TREAD_ERROR";

export const SET_READ_MESSAGE_REQUEST = "SET_READ_MESSAGE_REQUEST";
export const SET_READ_MESSAGE_SUCCESS = "SET_READ_MESSAGE_SUCCESS";
export const SET_READ_MESSAGE_ERROR = "SET_READ_MESSAGE_ERROR";

export const GET_UNREAD_MESSAGE_COUNT_REQUEST = "GET_UNREAD_MESSAGE_COUNT_REQUEST";
export const GET_UNREAD_MESSAGE_COUNT_SUCCESS = "GET_UNREAD_MESSAGE_COUNT_SUCCESS";
export const GET_UNREAD_MESSAGE_COUNT_ERROR = "GET_UNREAD_MESSAGE_COUNT_ERROR";
