<template>
  <div id="promoter-normal-incentive-add">
    <Breadcrumb
      title="Dashboard"
      subtitle="Promoter Normal Incentive"
      sub-sub-title="Add Promoter Incentive"
    ></Breadcrumb>
    <div class="row lnv-main-card">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <form @submit.prevent="handleSubmit">
                <div class="row mb-5">
                  <div v-if="isCapAdmin || isCap" class="col-lg-12 mb-3">
                    <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-6">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                        ref="year"
                        v-model="filters.yearSelected"
                        :searchable="false"
                        :options="yearOptions"
                        label="name"
                        track-by="value"
                        :loading="!yearOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-6">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                        ref="quarter"
                        v-model="filters.quarterSelected"
                        :searchable="false"
                        :options="quarterOptions"
                        label="name"
                        track-by="value"
                        :loading="!quarterOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 d-inline-flex justify-content-between">
                    <h4>Incentive Scheme</h4>
                    <button
                      type="submit"
                      class="btn btn-primary pull-right ml-3"
                    >
                      <i class="fa fa-plus mr-2"></i>
                      Add
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <VueBootstrap4Table
                      :classes="classes"
                      :rows="productGroupLists"
                      :columns="columns"
                      :config="config"
                      :total-rows="total"
                      :show-loader="true"
                      @on-change-query="onChangeQuery"
                      @refresh-data="getProductGroups"
                    >
                      <template slot="sort-asc-icon">
                        <i class="mdi mdi-sort-ascending"></i>
                      </template>
                      <template slot="sort-desc-icon">
                        <i class="mdi mdi-sort-descending"></i>
                      </template>
                      <template slot="no-sort-icon">
                        <i class="mdi mdi-sort-variant"></i>
                      </template>
                      <template slot="empty-results">
                        <div class="bold-text">No Promoter Incentives Found!</div>
                      </template>
                      <template slot="No" slot-scope="props">
                        <span>{{ productGroupLists.indexof(props.row) + 1 }}</span>
                      </template>

                      <template slot="Action" slot-scope="props">
                        <button
                          v-if="!isCapAdmin || !isCap"
                          class="btn btn-warning btn-xs"
                          @click.prevent="openEditPage(props.row, 'edit')"
                        >
                          <i class="fa fa-edit"></i>
                          Edit
                        </button>
                      </template>
                    </VueBootstrap4Table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
// import { BModal, BFormFile } from "bootstrap-vue";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Multiselect from "vue-multiselect";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import moment from "moment";
// import Datepicker from "vuejs-datepicker";
import { export_json_to_excel } from "../../utils/Export2Excel";
import XLSX from "xlsx";

import { INCENTIVE_NORMAL_PROMOTER_ADD } from "../../store/modules/incentive/action.js";
// import { DEALER_OUTLET_LIST_REQUEST } from "../../store/modules/outlet/actions";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "../../store/modules/ui/actions";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';

export default {
  name: "PromoterNormalIncentiveAdd",
  components: {
    // Datepicker,
    Breadcrumb,
    Multiselect,
    VueBootstrap4Table,
    // BModal,
    // BFormFile
  },
  data() {
    return {
      productGroupLists: [],
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Outlets",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: false,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "Id",
          sort: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          uniqueId: true,
          visibility: false
        },
        {
          name: "No",
          label: "#",
          sort: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          uniqueId: true,
          visibility: true
        },
        {
          name: "productGroup",
          label: "Product Group",
          sort: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "Action",
          label: "Action",
          sort: false,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      today: new Date(),
      incentiveNormalPromoter: {
        name: "",
        countryId: 21,
        startDate: new Date(),
        endDate: "",
        dealerOutletsId: [],
        excelBase64: ""
      },
      dealerOutletsIsLoading: false,
      dealerOutlets: [],
      regionsSelected: [],
      statesList: [],
      statesSelected: [],
      outletList: [],
      outletsSelected: [],
      disabledDates: {
        to: "", // Disable all dates up to specific date
        from: "" // Disable all dates after specific date
      },
      fileUpload: [],
      submitted: false,
      incentiveNormalPromoterErrors: {},
      isCapAdmin: false,
                isCap: false,
      tHeader: ["Mtm","Incentive in local currency"],
      filterVal: ["mtmName", "mtmNo", "salesP", "promoter"],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      filters: {
        countrySelected: this.$store.getters.topFilterCountryData,
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      }
    };
  },
  methods: {
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    async getProductGroups() {
      console.log(this)
    },
    filterStates() {
      this.statesList = [];
      this.regionsSelected.map(region => {
        this.statesList.push(...region.stateList);
      });
    },
    filterOutlet() {
      this.outletList = [];
      this.statesSelected.map(state => {
        this.outletList.push(
          ...state.dealerOutletList.filter(item => item.status === "ACTIVE")
        );
      });
    },
    async outletInput(outlets) {
      const localOutlets = await outlets.selected_items;
      this.outletsSelected = [];
      // Filtering out deactivated dealers
      this.outletsSelected = [
        ...localOutlets.filter(item => item.status === "ACTIVE")
      ];
    },
    outletModalToggle() {
      this.$refs["outlet-editing-modal"].toggle();
    },
    toggleIncentiveListUploadModal() {
      this.$refs["upload-modal"].toggle("#upload-btn");
    },
    regionRemoved(regionRemoved) {
      if (this.statesSelected.length > 0) {
        const result = this.statesSelected.filter(state => {
          return !regionRemoved.stateList.includes(state);
        });
        this.statesSelected = result;
      }
    },
    stateRemoved(stateRemoved) {
      if (this.outletsSelected.length > 0) {
        const result = this.outletsSelected.filter(outlet => {
          stateRemoved.dealerOutletList.forEach(item => {
            if (item.id !== outlet.id) {
              return true;
            } else {
              return false;
            }
          });
        });

        this.outletsSelected = result;
      }
    },
    selectAll(inputType) {
      if (inputType === "region") {
        this.regionsSelected = [];
        this.regionsSelected = this.dealerOutlets;
        this.filterStates();
      } else if (inputType === "state") {
        this.statesSelected = [];
        this.statesSelected = this.statesList;
      } else if (inputType === "outlet") {
        this.outletsSelected = [];
        this.outletsSelected = this.outletList.filter(
          item => item.status === "ACTIVE"
        );
      }
    },
    dataAdjustmentsAndChecking() {
      // Checking Incentive Normal Start Date before Submitting
      if (
        !this.incentiveNormalPromoter.startDate ||
        this.incentiveNormalPromoter.startDate === "Invalid date"
      ) {
        this.incentiveNormalPromoterErrors.startDate = "Start date is required";
      } else {
        delete this.incentiveNormalPromoterErrors.startDate;
        this.incentiveNormalPromoter.startDate = moment(
          this.incentiveNormalPromoter.startDate
        ).format("YYYY-MM-DD 00:00:00");
      }

      // Checking Incentive Normal End Date before Submitting
      if (
        !this.incentiveNormalPromoter.endDate ||
        this.incentiveNormalPromoter.endDate === "Invalid date"
      ) {
        this.incentiveNormalPromoterErrors.endDate = "End date is required";
      } else {
        delete this.incentiveNormalPromoterErrors.endDate;
        this.incentiveNormalPromoter.endDate = moment(
          this.incentiveNormalPromoter.endDate
        ).format("YYYY-MM-DD 23:59:59");
      }

      // Checking Incentive Normal excel file upload before Submitting
      if (!this.incentiveNormalPromoter.excelBase64) {
        this.incentiveNormalPromoterErrors.file = "The file field is required";
      }

      // Extracting outlets ids to prepare for submission
      this.incentiveNormalPromoter.dealerOutletsId = this.outletsSelected.map(
        outlet => outlet.id
      );
    },
    // disablingDates() {
    //   const date = moment(this.today);
    //   const month = date.month();
    //   const year = date.year();

    //   this.disabledDates.to = new Date(year, month, 1);
    // },
    checkDates() {
      if (this.incentiveNormalPromoter.startDate && this.incentiveNormalPromoter.endDate) {
        let startDate = moment(this.incentiveNormalPromoter.startDate).format(
          "YYYY-MM-DD"
        );
        let endDate = moment(this.incentiveNormalPromoter.endDate).format("YYYY-MM-DD");

        if (
          !moment(startDate).isSame(endDate) &&
          !moment(startDate).isBefore(endDate)
        ) {
          this.incentiveNormalPromoterErrors.startDate =
            "Start date must be before end date";
        } else {
          delete this.incentiveNormalPromoterErrors.startDate;
        }

        if (
          !moment(endDate).isSame(startDate) &&
          !moment(endDate).isAfter(startDate)
        ) {
          this.incentiveNormalPromoterErrors.endDate =
            "End date must be after or equal to start date";
        } else {
          delete this.incentiveNormalPromoterErrors.endDate;
        }
      }
    },
    handleSubmit() {
      this.dataAdjustmentsAndChecking();

      this.$validator.validateAll().then(result => {
        if (result && Object.keys(this.incentiveNormalPromoterErrors).length === 0) {
          this.$store.dispatch(NOTIFICATION_REQUEST, {
            message: "Uploading promoter normal incentive"
          });

          this.$store
            .dispatch(INCENTIVE_NORMAL_PROMOTER_ADD, this.incentiveNormalPromoter)
            .then(res => {
              this.submitted = true;
              if (res.resultCode === 0) {
                this.$store.dispatch(NOTIFICATION_SUCCESS, {
                  message: "Incentive list Successfully Added."
                });
                this.$router.push("/dashboard/incentive/promoter-normal");
              }
            })
            .catch(err => {
              this.$store.dispatch(NOTIFICATION_ERROR, {
                message: err
              });
            });
        }
      });
    },
    resetUploadedFile() {
      this.fileUpload = [];
      // this.$refs["file"].reset();
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getDealerOutlets();
    },
    regionAdminCheck() {
      this.isCapAdmin = this.$store.getters.isCapAdmin;
      this.isCap = this.$store.getters.isCap;
      // TODO decide later regarding this part to keep or remove
      // if (!this.isCapAdmin || !this.isCap && this.$store.getters.getCountryCode == "MY") {
      // this.tHeader.push("Blind Bonus");
      //   this.filterVal.push("dealer");
      // }
    },
    checkUploadType(file) {
      let filename = file.split(".").pop();
      if (filename === "xlsx") {
        delete this.incentiveNormalPromoterErrors.file;
        return true;
      } else {
        this.incentiveNormalPromoterErrors.file = "File type must be .xlsx";
        return false;
      }
    },
    checkFileHeaders(headers) {
      // Normalizing column heads for comparision
      const formattedHeaders = JSON.stringify(headers);
      const formattedTheader = JSON.stringify(this.tHeader);

      return formattedHeaders === formattedTheader;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    handleFileUpload(event) {
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Validating file..."
      });

      const file = event.target.files[0];

      if (this.checkUploadType(file.name)) {
        this.getBase64(file).then(data => {
          var workbook = XLSX.read(data, { type: "base64" });
          var first_sheet_name = workbook.SheetNames[0];

          // Get worksheet
          let worksheet = workbook.Sheets[first_sheet_name];
          const sheet = XLSX.utils.sheet_to_json(worksheet, {
            raw: true,
            blankrows: true,
            header: this.tHeader
          });
          //Validating file upload columns
          if (sheet.length > 0) {
            if (this.checkFileHeaders(Object.keys(sheet[0]))) {
              this.incentiveNormalPromoter.excelBase64 = data;
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "File validation completed successfully",
                timeOut: true
              });
            } else {
              this.$store.dispatch(NOTIFICATION_ERROR, {
                message:
                  "File format incorrect. Please use provided incentive list template"
              });
            }
          } else {
            this.$store.dispatch(NOTIFICATION_ERROR, {
              message: "No data in file!"
            });
          }
        });
      }
    },
    doExport() {
      const data = "";
      const fileName = "promoterIncentiveNormalExample";
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: fileName,
        sheetName: "promoterIncentiveNormalExample",
        autoWidth: true,
        bookType: "xlsx"
      });
    }
  },
  mounted() {
    this.getFilters();
    this.getProductGroups();
    this.regionAdminCheck();
    // this.disablingDates();
  }
};
</script>
