import {
  getLockSerialList,
  lockSerialNumber,
  cancelSerialNumber,
  downloadLockSerialNumber
} from "@/api/serial_number.js";
import {
  LOCK_SERIAL_LIST_REQUEST,
  LOCK_SERIAL_LIST_SUCCESS,
  LOCK_SERIAL_LIST_ERROR,
  LOCK_SERIAL_UPDATE_REQUEST,
  LOCK_SERIAL_UPDATE_SUCCESS,
  LOCK_SERIAL_UPDATE_ERROR,
  LOCK_SERIAL_DOWNLOAD_REQUEST,
  LOCK_SERIAL_DOWNLOAD_SUCCESS,
  LOCK_SERIAL_DOWNLOAD_ERROR,
  CANCEL_SERIAL_REQUEST,
  CANCEL_SERIAL_SUCCESS,
  CANCEL_SERIAL_ERROR
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  lockSerial: [],
  status: "",
  hasLoadedOnce: false
};

const getters = {};

const actions = {
  [LOCK_SERIAL_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(LOCK_SERIAL_LIST_REQUEST);
      getLockSerialList(postData)
        .then(resp => {
          commit(LOCK_SERIAL_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(LOCK_SERIAL_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [LOCK_SERIAL_UPDATE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(LOCK_SERIAL_UPDATE_REQUEST);
      lockSerialNumber(postData)
        .then(resp => {
          commit(LOCK_SERIAL_UPDATE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(LOCK_SERIAL_UPDATE_ERROR, err);
          reject(err);
        });
    });
  },
  [LOCK_SERIAL_DOWNLOAD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(LOCK_SERIAL_DOWNLOAD_REQUEST);
      downloadLockSerialNumber(postData)
        .then(resp => {
          commit(LOCK_SERIAL_DOWNLOAD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(LOCK_SERIAL_DOWNLOAD_ERROR, err);
          reject(err);
        });
    });
  },
  [CANCEL_SERIAL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CANCEL_SERIAL_REQUEST);
      cancelSerialNumber(postData)
        .then(resp => {
          commit(CANCEL_SERIAL_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CANCEL_SERIAL_ERROR, err);
          reject(err);
        });
    });
  }
};

const mutations = {
  LOCK_SERIAL_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  LOCK_SERIAL_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      lockSerial: data,
      hasLoadedOnce: true
    });
  },
  LOCK_SERIAL_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lockSerial: [],
      hasLoadedOnce: true
    });
  },
  LOCK_SERIAL_UPDATE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  LOCK_SERIAL_UPDATE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      lockSerial: data,
      hasLoadedOnce: true
    });
  },
  LOCK_SERIAL_UPDATE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lockSerial: [],
      hasLoadedOnce: true
    });
  },
  LOCK_SERIAL_DOWNLOAD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  LOCK_SERIAL_DOWNLOAD_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      lockSerial: data,
      hasLoadedOnce: true
    });
  },
  LOCK_SERIAL_DOWNLOAD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lockSerial: [],
      hasLoadedOnce: true
    });
  },
  CANCEL_SERIAL_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CANCEL_SERIAL_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      lockSerial: data,
      hasLoadedOnce: true
    });
  },
  CANCEL_SERIAL_ERROR: state => {
    Object.assign(state, {
      status: "error",
      lockSerial: [],
      hasLoadedOnce: true
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
