import {
  ADMIN_CLEAN_CACHE_REQUEST,
  ADMIN_CLEAN_CACHE_REQUEST_ERROR,
  ADMIN_CLEAN_CACHE_REQUEST_SUCCESS,
  ADMIN_SERIAL_REMATCH_REQUEST,
  ADMIN_SERIAL_REMATCH_REQUEST_ERROR,
  ADMIN_SERIAL_REMATCH_REQUEST_SUCCESS,
  ADMIN_REGISTRATION_SUBMIT,
  ADMIN_REGISTRATION_SUBMIT_ERROR,
  ADMIN_REGISTRATION_SUBMIT_SUCCESS,
  SALES_PERSON_REGISTRATION_SUBMIT,
  SALES_PERSON_REGISTRATION_SUBMIT_ERROR,
  SALES_PERSON_REGISTRATION_SUBMIT_SUCCESS,
  SALES_PERSON_CHECK_REQUEST,
  SALES_PERSON_CHECK_REQUEST_ERROR,
  SALES_PERSON_CHECK_REQUEST_SUCCESS,
  REGIONAL_ADMIN_REGISTRATION_SUBMIT,
  REGIONAL_ADMIN_REGISTRATION_SUBMIT_ERROR,
  REGIONAL_ADMIN_REGISTRATION_SUBMIT_SUCCESS,
  REGIONAL_REGISTRATION_SUBMIT,
  REGIONAL_REGISTRATION_SUBMIT_ERROR,
  REGIONAL_REGISTRATION_SUBMIT_SUCCESS,
  COUNTRY_HEAD_REGISTRATION_SUBMIT,
  COUNTRY_HEAD_REGISTRATION_SUBMIT_ERROR,
  COUNTRY_HEAD_REGISTRATION_SUBMIT_SUCCESS,
  DEALER_REGISTRATION_SUBMIT,
  DEALER_REGISTRATION_SUBMIT_ERROR,
  DEALER_REGISTRATION_SUBMIT_SUCCESS,
  PROMOTER_LEADER_REGISTRATION_SUBMIT,
  PROMOTER_LEADER_REGISTRATION_SUBMIT_ERROR,
  PROMOTER_LEADER_REGISTRATION_SUBMIT_SUCCESS,
  PROMOTER_CHECK_REQUEST,
  PROMOTER_CHECK_REQUEST_ERROR,
  PROMOTER_CHECK_REQUEST_SUCCESS,
  PROMOTER_REGISTRATION_SUBMIT,
  PROMOTER_REGISTRATION_SUBMIT_ERROR,
  PROMOTER_REGISTRATION_SUBMIT_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_ERROR,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_WEB_REQUEST,
  RESET_PASSWORD_WEB_REQUEST_ERROR,
  RESET_PASSWORD_WEB_REQUEST_SUCCESS,
  AGENCY_REGISTRATION_SUBMIT,
  AGENCY_REGISTRATION_SUBMIT_ERROR,
  AGENCY_REGISTRATION_SUBMIT_SUCCESS,
  CHECKER_REGISTRATION_SUBMIT,
  CHECKER_REGISTRATION_SUBMIT_ERROR,
  CHECKER_REGISTRATION_SUBMIT_SUCCESS
} from "./actions";
import {
  adminCleanCache,
  adminSerialRematch,
  adminRegistrationSubmit,
  salesPersonRegistrationSubmit,
  salespersonCheckRequest,
  regionalAdminRegistrationSubmit,
  regionalRegistrationSubmit,
  countryHeadRegistrationSubmit,
  dealerRegistrationSubmit,
  promoterLeaderRegistrationSubmit,
  promoterCheckRequest,
  promoterRegistration,
  resetPasswordRequest,
  resetPasswordWebRequest,
  agencyRegistrationSubmit,
  checkerRegistrationSubmit
} from "@/api/admin";

const state = {
  status: "",
  hasLoadedOnce: false,
  data: {}
};

const actions = {
  [ADMIN_CLEAN_CACHE_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(ADMIN_CLEAN_CACHE_REQUEST);
      adminCleanCache()
        .then(resp => {
          commit(ADMIN_CLEAN_CACHE_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADMIN_CLEAN_CACHE_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [ADMIN_SERIAL_REMATCH_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(ADMIN_SERIAL_REMATCH_REQUEST);
      adminSerialRematch()
        .then(resp => {
          commit(ADMIN_SERIAL_REMATCH_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADMIN_SERIAL_REMATCH_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [ADMIN_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ADMIN_REGISTRATION_SUBMIT);
      adminRegistrationSubmit(postData)
        .then(resp => {
          commit(ADMIN_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADMIN_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [SALES_PERSON_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SALES_PERSON_REGISTRATION_SUBMIT);
      salesPersonRegistrationSubmit(postData)
        .then(resp => {
          commit(SALES_PERSON_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SALES_PERSON_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [SALES_PERSON_CHECK_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SALES_PERSON_CHECK_REQUEST);
      salespersonCheckRequest(postData)
        .then(resp => {
          commit(SALES_PERSON_CHECK_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SALES_PERSON_CHECK_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [REGIONAL_ADMIN_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(REGIONAL_ADMIN_REGISTRATION_SUBMIT);
      regionalAdminRegistrationSubmit(postData)
        .then(resp => {
          commit(REGIONAL_ADMIN_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(REGIONAL_ADMIN_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [REGIONAL_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(REGIONAL_REGISTRATION_SUBMIT);
      regionalRegistrationSubmit(postData)
        .then(resp => {
          commit(REGIONAL_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(REGIONAL_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [COUNTRY_HEAD_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(COUNTRY_HEAD_REGISTRATION_SUBMIT);
      countryHeadRegistrationSubmit(postData)
        .then(resp => {
          commit(COUNTRY_HEAD_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(COUNTRY_HEAD_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [DEALER_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DEALER_REGISTRATION_SUBMIT);
      dealerRegistrationSubmit(postData)
        .then(resp => {
          commit(DEALER_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DEALER_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [PROMOTER_LEADER_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_LEADER_REGISTRATION_SUBMIT);
      promoterLeaderRegistrationSubmit(postData)
        .then(resp => {
          commit(PROMOTER_LEADER_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PROMOTER_LEADER_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [PROMOTER_CHECK_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_CHECK_REQUEST);
      promoterCheckRequest(postData)
        .then(resp => {
          commit(PROMOTER_CHECK_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PROMOTER_CHECK_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [PROMOTER_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_REGISTRATION_SUBMIT);
      promoterRegistration(postData)
        .then(resp => {
          commit(PROMOTER_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PROMOTER_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [RESET_PASSWORD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(RESET_PASSWORD_REQUEST);
      resetPasswordRequest(postData)
        .then(resp => {
          commit(RESET_PASSWORD_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(RESET_PASSWORD_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [RESET_PASSWORD_WEB_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(RESET_PASSWORD_WEB_REQUEST);
      resetPasswordWebRequest(postData)
        .then(resp => {
          commit(RESET_PASSWORD_WEB_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(RESET_PASSWORD_WEB_REQUEST_ERROR, err);
          reject(err);
        });
    })
  },
  [AGENCY_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(AGENCY_REGISTRATION_SUBMIT);
      agencyRegistrationSubmit(postData)
        .then(resp => {
          commit(AGENCY_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(AGENCY_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
  [CHECKER_REGISTRATION_SUBMIT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CHECKER_REGISTRATION_SUBMIT);
      checkerRegistrationSubmit(postData)
        .then(resp => {
          commit(CHECKER_REGISTRATION_SUBMIT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CHECKER_REGISTRATION_SUBMIT_ERROR, err);
          reject(err);
        });
    })
  },
};

const mutations = {
  ADMIN_CLEAN_CACHE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADMIN_CLEAN_CACHE_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  ADMIN_CLEAN_CACHE_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  ADMIN_SERIAL_REMATCH_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADMIN_SERIAL_REMATCH_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  ADMIN_SERIAL_REMATCH_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  ADMIN_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADMIN_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  ADMIN_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SALES_PERSON_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SALES_PERSON_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SALES_PERSON_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SALES_PERSON_CHECK_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SALES_PERSON_CHECK_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SALES_PERSON_CHECK_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  REGIONAL_ADMIN_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  REGIONAL_ADMIN_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  REGIONAL_ADMIN_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  REGIONAL_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  REGIONAL_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  REGIONAL_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  COUNTRY_HEAD_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  COUNTRY_HEAD_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  COUNTRY_HEAD_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  DEALER_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DEALER_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  DEALER_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_LEADER_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_LEADER_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_LEADER_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_CHECK_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_CHECK_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_CHECK_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  RESET_PASSWORD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  RESET_PASSWORD_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  RESET_PASSWORD_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  RESET_PASSWORD_WEB_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  RESET_PASSWORD_WEB_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  RESET_PASSWORD_WEB_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  AGENCY_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  AGENCY_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  AGENCY_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  CHECKER_REGISTRATION_SUBMIT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CHECKER_REGISTRATION_SUBMIT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  CHECKER_REGISTRATION_SUBMIT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  actions,
  mutations
};
