<template>
  <div v-if="!isLoading" id="visitation-attainment-plan">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <span class="mt-auto mb-auto">
        <strong>Weekly Visitation Attainment vs. Plan</strong>
      </span>
       <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="d-none"
        ></a>
      </div>
    </div>
    <combo-bar-chart
      :data.sync="data"
      :is-stacked="true"
      :show-ttl="true"
      :series-options.sync="seriesOptions"
      :h-axis="hAxis"
    />
    <div style="height: 52px">&nbsp;</div>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" :style="getStyle('LES')"></span><span>LES</span>
              <span class="tag mr-2 is-white is-medium legendIcon" :style="getStyle('KEY_STORE')"></span><span>Key Store</span>
              <span class="tag mr-2 is-white is-medium legendIcon" :style="getStyle('MEDIUM_PRIORITY_STORE')"></span><span>Medium Priority Store</span>
              <span class="tag mr-2 is-white is-medium legendIcon" :style="getStyle('LOW_PRIORITY_STORE')"></span><span>Low Priority Store</span>
              <hr class="tag mr-2 is-white is-medium legendIcon">
              <span class="tag mr-2 is-white is-medium legendIcons planned" style="background: #F5CF66;"></span><span>Planned Visitation</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep"
import comboChart from "../dashboard/combo-chart";
import { DASHBOARD_MERCHANDISER_VISITATION_GET } from "@/store/modules/charts/actions";
import { defaultQuarter } from '@/utils/helpers';
import { ALL_STORE_IDENTITY_COLOR } from "@/constants"

export default {
  components: {
    "combo-bar-chart": comboChart,
  },
  props: {
    grouping: { type: String, default: "" }, // for api
    filters: {type: Object, default: () => {}},
  },
  data() {
    return {
      isLoading: false,
      data: [[]],
      seriesOptions: {},
      link: "",
      hAxis: {title: 'Week'}
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: defaultQuarter(), value: defaultQuarter()};
      }
      let pdata = { filters: this.filters }
      this.$store.dispatch(DASHBOARD_MERCHANDISER_VISITATION_GET, pdata).then(res => {
        if (res) {
          this.data = res.data
          this.seriesOptions = res.seriesOptions
          const obj = this.filters.quarterSelected
          this.hAxis = obj && Object.keys(obj).length === 0 ? null : {title: 'Week'}
        }
      })
      .finally(() => this.isLoading = false)
    },
    getStyle(value) {
      const color = ALL_STORE_IDENTITY_COLOR.find(el => el.value == value).color
      return `background: ${color};`
    },
    exportChart(event){
      event.preventDefault();
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: defaultQuarter(), value: defaultQuarter()};
      }
      let pdata = {
       filters: cloneDeep(this.filters)
      }
      pdata.filters.isExport = true
      this.$store.dispatch(DASHBOARD_MERCHANDISER_VISITATION_GET, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      })
    },
  }
}
</script>

<style lang="scss">
#visitation-attainment-plan {
  .tags {
    >span {
      font-family: 'Arial';
      font-size: 13px;
      color: black;
      padding-right: 5px;
      &.planned {
        width: 2em;
        border-radius: 15px;
      }
    }
    >hr {
      border: 0;
      width: 10px;
      height: 3px;
      background: #F5CF66;
    }
  }
  .google-visualization-tooltip {
    width: auto !important;
  }
}
</style>