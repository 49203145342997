<template>
  <div v-if="!isPromoterSOperformanceLoading">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <span class="mt-auto mb-auto">
        <strong>{{ promoterSOPerformance.name }}</strong>
      </span>
      <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-4"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
        ></a>
        <span class="enlarge-icon mr-2" @click="enlargeChart(promoterSOPerformance, 'comboBarChart')"> 
          &#10070;
        </span>
      </div>
    </div>
    <combo-bar-chart
      :data.sync="promoterSOPerformance.data"
      :title="''"
      :is-stacked="true"
      :show-ttl="true"
      :series-options.sync="promoterSOPerformance.seriesOptions"
      :country="localFilters.countrySelected.value || localFilters.countrySelected"
    ></combo-bar-chart>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
              <hr class="tag mr-2 is-white is-medium legendIcon" style="border: 0; width: 10px; height: 3px; background: rgb(238, 205, 85);">
              <span class="tag mr-2 is-white is-medium legendIcons" style="width:2em; border-radius: 15px; background: rgb(238, 205, 85);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Target</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import comboChart from "../dashboard/combo-chart";
import { PROMOTER_SO_PERFORMANCE_GET, EXPORT_PROMOTER_SO_PERFORMANCE } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "combo-bar-chart": comboChart,
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data(){
    return{
      isPromoterSOperformanceLoading: true,
      promoterSOPerformance: {},
      link: ""
    }
  },
  methods: {
    exportChart(event){
      event.preventDefault();
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }
      if(this.localFilters.countrySelected.value === 'PH' || this.localFilters.countrySelected === 'PH'){
        pdata.promoterType = this.localFilters.promoterTypeSelected.value;
      }
      this.$store.dispatch(EXPORT_PROMOTER_SO_PERFORMANCE, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      });
    },
    async getPromoterSOperformance() {
      this.isPromoterSOperformanceLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
      };
      if(this.localFilters.countrySelected.value === 'PH' || this.localFilters.countrySelected === 'PH'){
        pdata.promoterType = this.localFilters.promoterTypeSelected.value;
      }
      await this.$store.dispatch(PROMOTER_SO_PERFORMANCE_GET, pdata).then((res) => {
        if(res){
          this.promoterSOPerformance = res;
        }
        this.isPromoterSOperformanceLoading = false;
      })
    },
  },
  // mounted(){
  //   this.getPromoterSOperformance();
  // }
}
</script>