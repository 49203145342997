<template>
  <div id="sku-products">
    <div class="overlay">
      <h2 class="text-right mt-5 mr-5 pr-5 close-btn" style="color: white; cursor: pointer" @click="closeOverlay">&#10005;</h2>
      <div class="loading-wrapper h-100">
        <div class="loading loading-white"></div>
      </div>
      <div class="arrow-wrapper">
        <div class="content">
          <div class="arrow">
            <div class="curve"></div>
            <div class="point"></div>
          </div>
        </div> 
        <div class="content-text">
          <h3>Please wait for a file to be downloaded</h3>
        </div>
      </div>
    </div>
    <breadcrumb title="Dashboard" subtitle="MTM Check" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row mb-2 mt-4">
                <div class="col-lg-12 d-inline-flex justify-content-end">
                  <div>
                    <button class="btn btn-sm btn-primary" :disabled="isDataLoading" @click="doExport">
                      <i class="fa fa-download"></i> 
                      <span v-text="$ml.get('export_to_excel')"></span>
                    </button>
                  </div>
                  <div v-if="!isCountryView">
                    <button
                      class="btn btn-sm btn-primary mr-0"
                      @click="() => toggleUploadModal()"
                    >
                    <i class="fa fa-upload"></i>
                    <span v-text="$ml.get('button_upload_text')"></span>
                  </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10">
                  <label v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                    ref="multiselect"
                    v-model="countryId"
                    placeholder="Select Country"
                    :searchable="false"
                    :options="countries"
                    label="name"
                    track-by="value"
                    :loading="isCountriesLoading"
                    :disabled="isCountriesLoading || !isCap && !isCapAdmin"
                    select-label
                    deselect-label
                  ></Multiselect>
                </div>
                <div class="col d-flex" style="flex-direction: column-reverse">
                  <button class="btn btn-sm btn-primary" style="height:40px;width: 100%;margin: auto auto 0px auto" :disabled="isDataLoading" @click="getProductList()">
                    <span>Search</span>
                  </button>
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                    :classes="classes"
                    :rows="productList"
                    :columns="columns"
                    :config="config"
                    :total-rows="total"
                    @on-change-query="onChangeQuery"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isDataLoading"
                        class="bold-text"
                        v-text="$ml.get('no_product_found')"
                      ></div>
                      <div
                        v-if="isDataLoading"
                        class="loading mt-3"
                      ></div>
                    </template>

                    <template slot="country" slot-scope="props">
                      <span> {{ props.row.countryCode }} </span>
                    </template>

                    <template v-if="isCapAdmin || isCap" slot="Action" slot-scope="props">
                      <div
                        class="inner-buttons btn-group float-right"
                        role="group"
                      >
                        <button
                          class="btn btn-warning btn-xs"
                          @click="toggleModal(props.row)"
                        >
                          <i class="fa fa-edit"></i>
                          <span v-text="$ml.get('button_edit_text')"></span>
                        </button>
                      </div>
                    </template>
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BModal ref="upload-modal" size="xl" hide-footer title="Upload">
      <uploadForm
        :close-modal="toggleUploadModal"
      />
    </BModal>

    <BModal ref="mtm-modal" size="lg" hide-footer :title="setFormTitle">
      <mtmForm
        :close-modal="toggleModal"
        :product-selected="selectedMTM"
        :load-data="getProductList"
        current-action="edit"
        :country-code="countryId.code"
      />
    </BModal>

  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Message from "element-ui";
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { export_json_to_excel } from "../../utils/Export2Excel";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import Multiselect from "vue-multiselect";
import uploadForm from "./sku-products-upload.vue";
import mtmForm from "../../components/product/mtm_form";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import { PRODUCT_SKU_LIST_REQUEST, PRODUCT_SKU_LIST_EXPORT_REQUEST } from "../../store/modules/product/actions";

import $ from 'jquery';

export default {
  name: "SKUproducts",
  components: {
    breadcrumb: breadcrumb,
    VueBootstrap4Table: VueBootstrap4Table,
    Multiselect: Multiselect,
    uploadForm: uploadForm,
    mtmForm: mtmForm,
    BModal: BModal
  },
  computed: {},
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "MTM SRP",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "country",
          label: "CTRY",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "mtmNo",
          label: "MTM",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "model",
          label: "Model",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "productGroup",
          label: "Product Group",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "category",
          label: "Product Category",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "srp",
          label: "SRP",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      countries: [],
      productList: [],
      isCountriesLoading: false,
      countryId: 0,
      isDataLoading: false,
      isCapAdmin: false,
      isCap: false,
      isCountryView: false,
      setFormTitle: "Edit MTM",
      selectedMTM: {}
    };
  },
  watch: {
    "queryParams.page": function() {
      return this.getProductList();
    },
    "queryParams.per_page": function() {
      return this.getProductList();
    }
  },
  methods: {
    closeOverlay(){
      $('.overlay').css('display', 'none');
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    toggleUploadModal(shouldRefresh = false) {
      this.$refs["upload-modal"].toggle("#toggle-btn");
      if (shouldRefresh) this.getProductList();
    },
    toggleModal(row) {
      this.selectedMTM = row;
      this.$refs["mtm-modal"].toggle("#toggle-btn2");
    },
    doExport() {
      const tHeader = [
        "Country",
        "MTM",
        "Model",
        "Product Group",
        "Product Category",
        "SRP",
      ];
      const filterVal = [
        "countryCode",
        "mtmNo",
        "model",
        "productGroup",
        "productCategory",
        "srp",
      ];
      $('.loading-wrapper').css('display', 'initial');
      $('.arrow-wrapper').css('display', 'inline-flex');
      $('.loading-wrapper').html('<div class="loading loading-white"></div>');
      $('.overlay .close-btn').css('display', 'none');
      $('.overlay').css('display', 'block');
      let pdata = {};
      pdata.countryCode = this.countries.find(country => country.value === this.countryId.value).value;
      this.$store.dispatch(PRODUCT_SKU_LIST_EXPORT_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          const data = this.formatJson(filterVal, res.content.productSkuWithPricingList, this.countries);
          const fileName = "sku-products";
          export_json_to_excel({
            header: tHeader,
            data,
            filename: fileName,
            sheetName: "sku-products",
            autoWidth: true,
            bookType: "xlsx"
          }).then(() => {
            $('.loading-wrapper').css('display', 'flex');
            $('.loading-wrapper').html('<h2>&#10003;</h2><br><h3>File is downloaded!</h3>');
            $('.arrow-wrapper').css('display', 'none');
            $('.overlay .close-btn').css('display', 'block');
            $('.overlay').on('click', this.closeOverlay);
          });
          this.isDataLoading = false;
        } else {
          $('.loading-wrapper').css('display', 'flex');
          $('.loading-wrapper').html('<h2>&#10005;</h2><br><h3>Please try again!</h3>');
          $('.arrow-wrapper').css('display', 'none');
          $('.overlay .close-btn').css('display', 'block');
          $('.overlay').on('click', this.closeOverlay);
          this.isDataLoading = false;
        }
      })
    },

    formatJson(filterVal, jsonData, countries) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if(j === "countryCode"){
            if(v[j] !== null){
              return countries.find(country => country.value === v[j]) ? countries.find(country => country.value === v[j]).value : v[j];
            }
          } else if(v[j] === null){
            return "-"
          } else {
            return v[j];
          }
        })
      );
    },
    async getCountries() {
      this.isCountriesLoading = true;

      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.countries = await res.content.countryDropdown;
        //listen parameter "countryCode"
        this.isCountriesLoading = false;
        if(this.isCapAdmin || this.isCap){
          this.countryId = {name: "CAP", value: "CAP"}
        } else {
          this.countryId = this.countries.find(country => country.value === this.$store.getters.getCountryCode);
        }
      } else {
        this.isCountriesLoading = false;
      }
    },

    getProductList() {
      this.isDataLoading = true;
      this.productList = [];
      const countryCode = this.countries.find(country => country.value === this.countryId.value).value;

      let pdata = {
        countryCode: countryCode
      };

      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      this.$store.dispatch(PRODUCT_SKU_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.total = res.content.productSkuWithPricingList.length;
          let list = [...res.content.productSkuWithPricingList]
          list.sort(function(a, b) {
            return (a.srp != null) - (b.srp != null) || a.srp - b.srp;
          });
          this.productList = list;
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
      });
    }
  },

  async mounted() {
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCountryView = this.$store.getters.isCountryView;

    if (!this.isCapAdmin || !this.isCap) {
      this.columns.push({
        name: "Action",
        label: "Action",
        row_text_alignment: "text-left",
        column_text_alignment: "text-left"
      });
    }
    await this.getCountries();

    this.getProductList();
  },

  created() {},
};
</script>
