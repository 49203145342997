import moment from "moment";

export const convertDateFormat = function(UNIX_timestamp, format) {
  let date = new Date(UNIX_timestamp);
  return moment(String(date)).format(format);
};

export const phoneValidate = function(phone, reg) {
  let phoneRe = /^[+]?[0-9]+$/; ///^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

  if (reg) {
    return phoneRe;
  } else {
    return phoneRe.test(phone);
  }
};

export const postalCodeValidate = function(code, reg) {
  let codeRe = /^[0-9]+$/; ///^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  if (reg) {
    return codeRe;
  } else {
    return codeRe.test(code);
  }
};

export const lppCodeValidate = function(code, reg) {
  let codeRe = /^[a-zA-Z0-9 _-]+$/; ///^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

  if (reg) {
    return codeRe;
  } else {
    return codeRe.test(code);
  }
};

export const emailValidate = function(email, reg) {
  let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (reg) {
    return re;
  } else {
    return re.test(email);
  }
};

export const nameValidate = function() {
  return true; ///^[a-zA-Z ]+$/.test(name);
};

export const urlValidate = function(url) {
  if (url === null) {
    return false;
  } else if (
    url.match(
      // eslint-disable-next-line no-useless-escape
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    ) !== null
  ) {
    return true;
  }

  return false;
};

export const replaceImageByDefault = function(e) {
  e.target.src = "";
};

export const defaultFiscalYear = function(){
  let currentYear;
  let nextYear;
  let currentMonth = new Date().getMonth();

  if(currentMonth  < 3){
    currentYear = (new Date().getFullYear() - 1).toString().substr(-2);
    nextYear = new Date().getFullYear().toString().substr(-2);
  } else {
    currentYear = new Date().getFullYear().toString().substr(-2);
    nextYear = (new Date().getFullYear() + 1).toString().substr(-2);
  }
  return `FY${currentYear}${nextYear}`;
}

export const defaultQuarter = function(){
  let currentMonth = new Date().getMonth();
  let quarter;
  if(3 <= currentMonth && currentMonth <= 5){
    quarter = 1;
  } else if(5 < currentMonth && currentMonth <= 8){
    quarter = 2;
  } else if(8 < currentMonth && currentMonth <= 11){
    quarter = 3;
  } else {
    quarter = 4;
  }
  return `Q${quarter}`;
}

export const limitDecimalNum = function(value, decimalNumToLimit = 2) {
  if (value !== null && value !== undefined) {
    return value.toFixed(decimalNumToLimit)
  }
}

export const isNumeric = (str) => {
  if (typeof str === "number") return true
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}