import store from "@/store";
import PassThrough from "@/views/PassThrough";
import DealersOutletsDetails from "@/views/dealer-outlets/outlets_list.vue";

import {
  ROLE_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_VIEW,
  ROLE_GLN_COUNTRY_ADMIN,
  ROLE_COUNTRY_TRAINER,
  ROLE_PROMOTER_LEADER,
  ROLE_CAP_ADMIN,
  ROLE_CAP,
  ROLE_DEALER,
  LAYOUT_ADMIN,
  ROLE_OUTLET_PIC,
  ROLE_AGENCY,
  ROLE_COUNTRY_AGENCY,
  ROLE_REGION_MANAGER,
  ROLE_STATE_MANAGER,
  ROLE_MERC_LEADER
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const dealerOutletRouter = {
  path: "/dealer-outlets",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
      ROLE_CAP_ADMIN,
      ROLE_CAP,
      ROLE_COUNTRY_ADMIN,
      ROLE_COUNTRY_VIEW,
      ROLE_GLN_COUNTRY_ADMIN,
      ROLE_COUNTRY_TRAINER,
      ROLE_PROMOTER_LEADER,
      ROLE_DEALER,
      ROLE_OUTLET_PIC,
      ROLE_AGENCY,
      ROLE_COUNTRY_AGENCY,
      ROLE_REGION_MANAGER,
      ROLE_STATE_MANAGER,
      ROLE_MERC_LEADER
    ]
  },
  children: [
    {
      path: "outlets-list",
      name: "Dealers/Outlets Details",
      component: DealersOutletsDetails,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_DEALER,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_TRAINER,
          ROLE_OUTLET_PIC,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_MERC_LEADER
        ]
      }
    },
    {
      path: "outlets-list/:dealerOutletCode",
      name: "Outlet Details ",
      component: DealersOutletsDetails,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_DEALER,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_TRAINER,
          ROLE_OUTLET_PIC,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_MERC_LEADER
        ]
      }
    },
    {
      path: "outlets-list/:countryCode/:lppNumber",
      name: "Dealer Details ",
      component: DealersOutletsDetails,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_DEALER,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_TRAINER,
          ROLE_OUTLET_PIC,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_MERC_LEADER
        ]
      }
    },

  ],
  beforeEnter: ifAuthenticated
};

export default dealerOutletRouter;
