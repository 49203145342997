<template>
  <div id="merchandiser-leader-list">
    <breadcrumb
      title="Dashboard"
      subtitle="Staff Management"
      sub-sub-title="Merchandiser Leaders"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row">
                <div
                  class="col-lg-12 add-employee-button d-flex align-items-center"
                >
                  <div class="w-100 d-inline-flex justify-content-between position-relative">
                    <b-field class="mr-auto align-items-center" grouped group-multiline>
                      <div
                        class="control clickable"
                        @click="filterBy='registered'"
                      >
                        <b-taglist attached>
                          <b-tag class="mb-0" type="is-dark" size="is-large">Registered</b-tag>
                          <b-tag class="mb-0" type="is-success" size="is-large">
                            <strong>{{ getFilteredList('registered').length }}</strong>
                          </b-tag>
                        </b-taglist>
                      </div>
                      <div
                          class="control clickable"
                          @click="filterBy='active'"
                      >
                        <b-taglist attached>
                          <b-tag class="mb-0" type="is-dark" size="is-large">Active</b-tag>
                          <b-tag class="mb-0" type="is-success" size="is-large">
                            <strong>{{ getFilteredList('active').length }}</strong>
                          </b-tag>
                        </b-taglist>
                      </div>
                      <div
                        class="control clickable"
                        @click="filterBy='deactivated'"
                      >
                        <b-taglist attached>
                          <b-tag class="mb-0" type="is-dark" size="is-large">Deactivated</b-tag>
                          <b-tag class="mb-0" type="is-danger" size="is-large">
                            <strong>{{ getFilteredList('deactivated').length }}</strong>
                          </b-tag>
                        </b-taglist>
                      </div>
                    </b-field>
                    <div class="d-inline">
                      <button
                        class="btn btn-sm btn-primary"
                        @click="toggleModal(null)"
                      >
                        <i class="fa fa-plus"></i>
                        <span>Add Merchandiser Leader</span>
                      </button>

                      <button
                        :disabled="isLoading"
                        class="btn btn-sm btn-primary"
                        @click="doExport"
                      >
                        <i class="fa fa-file-excel-o"></i>
                        <span v-text="$ml.get('export_to_excel')"></span>
                      </button>
                    </div>
                  </div>
                  <br>
                  <br>
                  <br>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      :disabled="!isCapAdmin && !isCap"
                      label="name"
                      track-by="id"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-4">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-3">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-1">
                  <button class="btn btn-sm btn-primary" style="margin-top:24px;height:40px;width: 100%" :disabled="isLoading" @click="getList()">
                    <span class="position-absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">Search</span>
                  </button>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                    :classes="classes"
                    :rows="getFilteredList()"
                    :columns="columns"
                    :config="config"
                    :total-rows="total"
                    @on-change-query="onChangeQuery"
                    @refresh-data="getList"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isLoading"
                        class="bold-text"
                      >
                        No Merchandiser Leader found!
                      </div>
                      <div
                        v-if="isLoading"
                        class="loading mt-3"
                      ></div>
                    </template>

                    <template slot="createdCountryCode" slot-scope="props">
                      <div class="inner-data">
                      <span>{{ props.row.createdCountryCode }}</span>
                      </div>
                    </template>

                    <template slot="id" slot-scope="props">
                      <div class="inner-data">
                      <span>{{ props.row.id }}</span>
                      </div>
                    </template>

                    <template slot="name" slot-scope="props">
                      <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, true)">
                        <span style="color:#F70201">
                          {{ props.row.name }}
                        </span>
                      </div>
                    </template>

                    <template slot="plan" slot-scope="props">
                      <div class="inner-data">
                        <span>{{ props.row.plan }}</span>
                      </div>
                    </template>

                    <template slot="actual" slot-scope="props">
                      <div class="inner-data">
                        <span>{{ props.row.actual }}</span>
                      </div>
                    </template>

                    <template slot="attainRate" slot-scope="props">
                      <div class="inner-data">
                        <span>{{ limitDecimalNum(props.row.attainRate, 1) }}%</span>
                      </div>
                    </template>

                    <template slot="status" slot-scope="props">
                      <lnvStatusTextBox :active="props.row.status" />
                    </template>

                    <template slot="Action" slot-scope="props">
                      <div
                        class="inner-buttons btn-group float-right"
                        role="group"
                      >
                        <abbr title="Edit Merchandiser Leader">
                          <button
                            class="btn btn-warning btn-xs"
                            @click="toggleModal(props.row)"
                          >
                            <i class="fa fa-edit"></i>
                            Edit
                          </button>
                        </abbr>
                        <abbr title="Change Status">
                          <button
                            class="btn btn-danger btn-xs"
                            @click="doChangeStatus(props.row)"
                          >
                            <i class="fa fa-exchange"></i>
                            Change Status
                          </button>
                        </abbr>
                      </div>
                    </template>
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BModal ref="modal" size="lg" hide-footer :title="formTitle">
      <merchandiserLeaderForm
        :close-modal="toggleModal"
        :detail-passed-in="formDetail"
        :reload-list="getList"
        :action="formAction"
        :filters="filters"
      />
    </BModal>

  </div>
</template>

<script>
import breadcrumb from "@/components/breadcrumb/breadcrumb";
import merchandiserLeaderForm from "@/components/staff-management/merchandiser_leader_form";
import cloneDeep from "lodash/cloneDeep"
import { BModal } from "bootstrap-vue";
import { defaultQuarter, defaultFiscalYear } from "@/utils/helpers";

import VueBootstrap4Table from "@/components/ui/VueBootstrap4Table";
import { export_json_to_excel } from "@/utils/Export2Excel";

import { GET_MERCHANDISER_LEADER_LIST_REQUEST, GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG, POST_STAFF_REQUEST } from "@/store/modules/staff/actions";
import { DASHBOARD_TOP_FILTERS_GET } from "@/store/modules/dashboard/actions";
import { COUNTRY_LIST_REQUEST } from "@/store/modules/utility/actions"
import Multiselect from "vue-multiselect";
import lnvStatusTextBox from "@/components/ui/statusTextBox";
import { NOTIFICATION_SUCCESS } from "@/store/modules/ui/actions";
import { limitDecimalNum } from "@/utils/helpers"

export default {
  name: "MerchandiserLeaderStaff",
  components: {
    breadcrumb: breadcrumb,
    merchandiserLeaderForm: merchandiserLeaderForm,
    BModal: BModal,
    VueBootstrap4Table: VueBootstrap4Table,
    Multiselect: Multiselect,
    lnvStatusTextBox: lnvStatusTextBox
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Merchandiser Leader",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false,
          init: {
            value : ""
          }
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "createdCountryCode",
          label: "CTRY",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "id",
          label: "User ID",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "name",
          label: "Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "plan",
          label: "Planned Visitation",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "actual",
          label: "Actual Visitation",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "attainRate",
          label: "Attainment Rate",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          initial_sort: true,
          initial_sort_order: "desc",
          width: "5%"
        },
        {
          name: "Action",
          label: "Action",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "3%"
        }
      ],
      isCapAdmin: this.$store.getters.isCapAdmin,
      isCap: this.$store.getters.isCap,
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isGLNCountryAdmin: this.$store.getters.isGLNCountryAdmin,
      isCountryView: this.$store.getters.isCountryView,

      isLoading: false,

      total: 0,
      filterBy: null,
      list: [],

      formTitle: "",
      formDetail: null,
      formAction: null,
      
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      filters: {
        countrySelected: {},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      },
      limitDecimalNum
    };
  },
  methods: {
    toggleModal(row, viewOnly) {
      if (row) {
        if (viewOnly) {
          this.formTitle = "View Merchandiser Leader";
          this.formAction = "view";
        } else {
          this.formTitle = "Edit Merchandiser Leader";
          this.formAction = "edit";
        }
        this.formDetail = row;
      } else {
        this.formTitle = "Add Merchandiser Leader";
        this.formAction = "add";
        this.formDetail = null;
      }

      this.$refs["modal"].toggle("#toggle-btn");
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getList();
    },
    getList() {
      const countryId = this.filters.countrySelected.id == 'CAP' ? null : this.filters.countrySelected.id
      let pdata = {
        countryId: countryId,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
      };
      this.isLoading = true;
      this.list = [];

      this.$store.dispatch(GET_MERCHANDISER_LEADER_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.list = res.content;
          this.total = this.list.length;
        }
      })
      .finally(() => this.isLoading = false)
    },
    getFilteredList(filter = null) {
      const filterBy = filter || this.filterBy

      if (filterBy == "active") {
        return this.list.filter(el => el.status == true);
      } else if (filterBy == "deactivated") {
        return this.list.filter(el => el.status == false);
      } else {
        return this.list;
      }
    },
    async doChangeStatus(row) {
      let isPendingApproval;
      await this.$store.dispatch(GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG, row.id).then(res => {
        if (res.resultCode === 0) {
          isPendingApproval = res.content;
        }
      });
      if (!(this.isCap || this.isCapAdmin) && isPendingApproval) {
        this.$buefy.dialog.alert({
          title: 'Pending for approval',
          message: 'Sorry, this merchandiser leader is pending for approval and can\'t be changed now.',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$buefy.dialog.confirm({
          message: `Do you want to <b class='red-heighlighted'>${row.status ? 'deactivate' : 'active'}</b> ${row.name}`,
          onConfirm: () => {
            let postData = {
              type: "merchandiserLeader",
              staffId: row.id,
              data: {
                id: row.id,
                status: !row.status
              }
            }

            this.$store.dispatch(POST_STAFF_REQUEST, postData).then(res => {
              if (res.resultCode === 0) {
                if (this.isCap || this.isCapAdmin) {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: `Merchandiser Leader ${row.status ? 'Deactivated' : 'Activated'}.`,
                    timeOut: true
                  });
                } else {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: "Request is successfully placed for further approval",
                    timeOut: true
                  });
                }
                this.getList();
              }
            });
          }
        });
      }
    },
    doExport() {
      const tHeader = [
        "Country",
        "Region",
        "Sub Region",
        "User ID",
        "Name",
        "Plan",
        "Actual",
        "Attain Rate",
        "Status",
      ];
      const filterVal = [
        "createdCountryCode",
        "id",
        "name",
        "plan",
        "actual",
        "attainRate",
        "status",
      ];
      const data = this.formatJson(filterVal, this.list);
      const fileName = "merchandiserLeaders";
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "merchandiserLeaders",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "status" ) {
            return v[j] ? "ACTIVE" : "DEACTIVATED";
          } else {
            return v[j];
          }
        })
      );
    },
    
    getFilters() {
      this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET)
      .then(res => {
        if (res.content) {
          this.yearOptions = res.content.fiscalYearDropdown;
          this.quarterOptions = res.content.quarterDropdown;
        }
      })
      this.$store.dispatch(COUNTRY_LIST_REQUEST, { withRegion: false })
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = cloneDeep(res.content)
          .filter(el => {
            return el.code != "KR" && el.code != "KH"
          })
          .map(el => {
            return {
              id: el.id,
              name: el.text,
              code: el.code
            }
          })
          if (this.isCap || this.isCapAdmin) {
            const capObj = {
              id: "CAP",
              name: "CAP",
              code: "CAP"
            }
            this.countryOptions.unshift(capObj)
            this.filters.countrySelected = capObj
          } else {
            this.filters.countrySelected = this.countryOptions.find(el => el.code === this.$store.getters.getCountryCode);
          }
        }
      })
    },
    collapseSideMenu() {
      /* eslint-disable no-undef */
      $("#main-wrapper").toggleClass("menu-toggle");
      $(".hamburger").toggleClass("is-active");
    },
  },
  mounted() {
    this.collapseSideMenu()
    
    this.getFilters()
  },
};
</script>
<style>
.table>thead>tr>th {
  font-weight: 500;
  font-size: 13px;
}

</style>