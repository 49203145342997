<template>
  <div id="visitation-schedule">
    <breadcrumb
      title="Dashboard"
      subtitle="Data Management"
      sub-sub-title="Visitation Schedule"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- FILTER  -->
            <b-row>
              <b-col>
                <b-row align-h="end" class="mb-3">
                  <b-col lg="3" class="text-right">
                    <button v-if="isCap || isCapAdmin" :disabled="isLoading" class="btn btn-sm btn-primary btn-filter" @click="toggleModal">
                      <i class="fa fa-upload"></i> Upload
                    </button>
                    <button :disabled="isLoading" class="btn btn-sm btn-primary btn-filter" @click="doExport">
                      <i class="fa fa-download"></i> Export to Excel
                    </button>
                  </b-col>
                </b-row>
                <b-row align-h="end" align-v="center" class="mb-3">
                  <b-col lg="3">
                    <label
                      class="text-label"
                      v-text="$ml.get('sd_title_country')"
                    ></label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="countryOptions"
                      :disabled="!isCapAdmin && !isCap"
                      label="name"
                      name="country"
                      track-by="id"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    >
                    </Multiselect>
                  </b-col>
                  <b-col lg="3">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    >
                    </Multiselect>
                  </b-col>
                  <b-col lg="3">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </b-col>
                  <b-col lg="2">
                    <label class="text-label">Week</label>
                    <Multiselect
                      ref="quarter"
                      v-model="filters.weekSelected"
                      :searchable="false"
                      :options="weekOptions"
                      label="name"
                      track-by="value"
                      :loading="!weekOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </b-col>
                  <b-col lg="1">
                    <button class="btn btn-sm btn-primary btn-filter" style="margin-top:24px;width: 100%" :disabled="isLoading" @click="getList()">
                      <span class="position-absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">Search</span>
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- / FILTER  -->

             <b-row>
              <b-col>
                <VueBootstrap4Table
                  :classes="classes"
                  :rows="list"
                  :columns="columns"
                  :config="config"
                  :total-rows="total"
                  @on-change-query="onChangeQuery"
                ></VueBootstrap4Table>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

    <BModal ref="upload-modal" size="lg" hide-footer title="Upload">
      <div>
        <visitUpload
          :close-modal="toggleModal"
          :show-data-list="true"
          :submit-button-text="'Submit'"
          :filters="filters"
        />
      </div>
    </BModal>

  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import Multiselect from "vue-multiselect";
import cloneDeep from "lodash/cloneDeep";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import visitUpload from "../../components/target/upload_visitation.vue";
import { BModal } from "bootstrap-vue";
import { defaultQuarter, defaultFiscalYear } from "@/utils/helpers";
import { DASHBOARD_TOP_FILTERS_GET } from "@/store/modules/dashboard/actions";
import { COUNTRY_LIST_REQUEST } from "../../store/modules/utility/actions";
import { GET_WEEKLY_VISITATION_REQUEST, DOWNLOAD_WEEKLY_VISITATION_REQUEST } from "../../store/modules/target/actions";
import { QUATERLY_WEEK_LIST } from "@/constants"

const CAP = { id:null, code:"CAP", name:"CAP" }

export default {
  name: "VisitationSchedule",
  components: {
    breadcrumb: breadcrumb,
    BModal: BModal,
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table,
    visitUpload: visitUpload
  },
  data() {
    return {
      filters: {
        countrySelected: {},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        weekSelected: { name: "ALL", value: null },
      },
      isCapAdmin: this.$store.getters.isCapAdmin,
      isCap: this.$store.getters.isCap,
      isLoading: false,
      total: 0,
      list: [],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      weekOptions: cloneDeep(QUATERLY_WEEK_LIST),
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Visitation Schedule",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "userId",
          label: "User Id",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "merchandiserName",
          label: "Merchandiser",
          width: "13%",
          column_text_alignment: "text-center"
        },
        {
          name: "fiscalYear",
          label: "Fiscal Year",
          width: "10%",
          column_text_alignment: "text-center"
        },
        {
          name: "quarter",
          label: "Quarter",
          width: "8%",
          column_text_alignment: "text-center"
        },       
      ],
    };
  },
  watch: {},
  methods: {
    async getFilters() {
      await this.getCountries();
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, { withRegion: false })
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = res.content;
          this.countryOptions = cloneDeep(res.content)
          .filter(el => {
            return el.code != "KR" && el.code != "KH"
          })
          .map(el => {
            return {
              id: el.id,
              name: el.text,
              code: el.code
            }
          })
          if (this.isCap || this.isCapAdmin) {
            this.countryOptions.unshift(CAP)
            this.filters.countrySelected = CAP
          } else {
            this.filters.countrySelected = this.countryOptions.find(el => el.code === this.$store.getters.getCountryCode);
          }
        }
      });
    },
    formatTableColumns() {
      this.weekOptions.forEach((week,index) => {
        if(week.value != null){
          this.columns.push(
            {
              name: `details[${index-1}].${week.value}`,
              label: week.name,
              column_text_alignment: "text-center"
            }
          )
        }
      })
    },
    getList() {
      let pdata = {
        countryId: this.filters.countrySelected.id,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        wf: this.filters.weekSelected.value,
      };
      this.isLoading = true;
      this.list = [];
      this.$store.dispatch(GET_WEEKLY_VISITATION_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.list = res.content;
          this.total = this.list.length;
        }
      })
      .finally(() => this.isLoading = false)
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    doExport() {
      let pdata = {
        countryId: this.filters.countrySelected.id,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        wf: this.filters.weekSelected.value,
      };
      this.isLoading = true;
      this.$store.dispatch(DOWNLOAD_WEEKLY_VISITATION_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          window.open(res.content.link)
        }
      })
      .finally(() => this.isLoading = false)
    },
    toggleModal() {
      this.$refs["upload-modal"].toggle("#toggle-btn");
    },
  },
  async mounted() {
    this.getFilters();
    this.formatTableColumns()
  },
};
</script>
<style lang="scss">
#visitation-schedule {
  .btn-filter {
    height:40px;
    min-width: 35%;
  }
}
</style>