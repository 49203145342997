<template>
  <div>
    <breadcrumb title="Dashboard" subtitle="Add Salesperson/Dealer Incentive" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 mb-3">
                <form @submit.prevent="handleSubmit">
                  <div class="row mb-5">
                    <div class="col-lg-12 mb-3">
                      <label class="text-label" v-text="$ml.get('incp_title_incentive_name')"></label>
                      <span class="required-star">*</span>
                      <input 
                        v-model="newSalespersonProgram.name"
                        class="form-control" 
                      />
                      <span v-if="newSalespersonProgramErrors.name" class="invalid-field mt-2">{{
                        newSalespersonProgramErrors.name
                      }}</span>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-3">
                      <div class="w-100 mr-3">
                        <label class="text-label" v-text="$ml.get('sd_title_region')"></label>
                        <span class="required-star">*</span>
                        <Multiselect
                          ref="region"
                          v-model="newSalespersonProgram.regionList"
                          :searchable="true"
                          :options="regionOptions"
                          label="region"
                          track-by="region"
                          :multiple="true"
                          :loading="!Array.isArray(regionOptions) || !regionOptions.length"
                          :allow-empty="true"
                          :show-labels="false"
                        ></Multiselect>
                        <span v-if="newSalespersonProgramErrors.regionList" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.regionList
                        }}</span>
                      </div>
                      <div class="d-flex justify-content-end mb-1" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'regionList', regionOptions)"
                        >
                          Select All
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-3">
                      <div class="w-100 mr-3">
                        <label class="text-label" v-text="$ml.get('form_outlet_state')"></label>
                        <span class="required-star">*</span>
                        <Multiselect
                          ref="state"
                          v-model="newSalespersonProgram.stateList"
                          :searchable="true"
                          :multiple="true"
                          :options="stateOptions"
                          label="state"
                          track-by="state"
                          :allow-empty="true"
                          :show-labels="false"
                        ></Multiselect>
                        <span v-if="newSalespersonProgramErrors.stateList" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.stateList
                        }}</span>
                      </div>
                      <div class="d-flex justify-content-end mb-1" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'stateList', stateOptions)"
                        >
                          Select All
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-3">
                      <div class="w-100 mr-3">
                        <label class="text-label" v-text="$ml.get('dealers_title')"></label>
                        <span class="required-star">*</span>
                        <Multiselect
                          ref="dealer"
                          v-model="newSalespersonProgram.dealerList"
                          :searchable="true"
                          :multiple="true"
                          :options="dealerOptions"
                          label="dealerName"
                          track-by="dealerName"
                          :allow-empty="true"
                          :show-labels="false"
                        ></Multiselect>
                        <span v-if="newSalespersonProgramErrors.dealerList" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.dealerList
                        }}</span>
                      </div>
                      <div class="d-flex justify-content-end mb-1" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'dealerList', dealerOptions)"
                        >
                          Select All
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-3">
                      <div class="w-100 mr-3"
                        @click="
                          newSalespersonProgram.dealerList.length > 0 ? outletModalToggle() : ''"
                      >
                        <label class="text-label" v-text="$ml.get('outlets_title')"></label>
                        <span class="required-star">*</span>
                        <Multiselect
                          ref="outlets"
                          v-model="newSalespersonProgram.dealerOutletsId"
                          :searchable="true"
                          :multiple="true"
                          :disabled="true"
                          :options="outletOptions"
                          label="name"
                          track-by="id"
                          :allow-empty="true"
                          :show-labels="false"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }"
                          >
                            <span
                              v-if="values.length &amp;&amp; !isOpen"
                              class="multiselect__single"
                            >{{ values.length }} options selected</span>
                          </template>
                        </Multiselect>
                        <span v-if="newSalespersonProgramErrors.dealerOutletsId" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.dealerOutletsId
                        }}</span>
                      </div>
                      <div class="d-flex justify-content-start mt-5" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'dealerOutletsId', outletOptions)"
                        >
                          Select All
                        </button>
                      </div>
                      <div class="d-flex justify-content-start mt-5" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'dealerOutletsId', outletOptions, 0)"
                        >
                          With Promoter
                        </button>
                      </div>
                      <div class="d-flex justify-content-start mt-5" style="flex-direction: column">
                        <button
                          class="btn btn-primary pull-right"
                          @click="selectAll($event, 'dealerOutletsId', outletOptions, 1)"
                        >
                          Without Promoter
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-4">
                      <div class="col-lg-6 pl-0">
                        <label class="text-label">Start Date</label>
                        <span class="required-star">*</span>
                        <Datepicker
                          v-model="newSalespersonProgram.startDate"
                          :minimum-view="'day'"
                          :open-date="today"
                          :maximum-view="'year'"
                          :initial-view="'day'"
                          name="Start Date"
                          :bootstrap-styling="true"
                          :required="true"
                          placeholder="Select Start Date"
                          :disabled-dates="disabledDates"
                          @closed="checkDates"
                        ></Datepicker>
                        <span v-if="newSalespersonProgramErrors.startDate" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.startDate
                        }}</span>
                      </div>
                      <div class="col-lg-6 pr-0">
                        <label class="text-label">End Date</label>
                        <span class="required-star">*</span>
                        <Datepicker
                          v-model="newSalespersonProgram.endDate"
                          :minimum-view="'day'"
                          :open-date="new Date(newSalespersonProgram.startDate)"
                          :maximum-view="'year'"
                          :initial-view="'day'"
                          name="End Date"
                          :bootstrap-styling="true"
                          :required="true"
                          placeholder="Select End Date"
                          :disabled-dates="disabledDates"
                          @closed="checkDates"
                        ></Datepicker>

                        <span class="invalid-field mt-2">{{ errors.first("End Date") }}</span>
                        <span v-if="newSalespersonProgramErrors.endDate" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.endDate
                        }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 d-inline-flex mb-4">
                      <div class="col-lg-6 pl-0">
                        <label class="text-label">Min Quantity</label>
                        <input 
                          v-model="newSalespersonProgram.min"
                          class="form-control only-numbers" 
                          type="number"
                        />
                        <span v-if="newSalespersonProgramErrors.min" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.min
                        }}</span>
                      </div>
                      <div class="col-lg-6 pr-0">
                        <label class="text-label">Max Quantity</label>
                        <input 
                          v-model="newSalespersonProgram.max"
                          class="form-control only-numbers" 
                          type="number"
                        />
                        <span v-if="newSalespersonProgramErrors.max" class="invalid-field mt-2">{{
                          newSalespersonProgramErrors.max
                        }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 d-flex flex-column">
                      <div>
                        <label class="text-label">Incentive File Upload</label>
                        <span class="required-star">*</span>
                      </div>
                      <div class="d-flex">
                        <div class="d-flex flex-column w-100">
                          <div class="d-flex align-items-center">
                            <div class="w-100">
                              <div class="w-100 d-flex align-items-center">
                                <div class="w-100 d-inline-flex">
                                  <BFormFile
                                    id="file"
                                    ref="fileUploader"
                                    v-model="fileUpload"
                                    v-validate="'required'"
                                    name="file"
                                    accept=".xlsx"
                                    size="lg"
                                    class="cursor-pointer"
                                    @change="handleFileUpload($event)"
                                  ></BFormFile>
                                  <button
                                    type="button"
                                    class="btn btn-warning d-flex align-items-center ml-3 mt-1"
                                    @click="resetFileUploader"
                                  >
                                    Reset file
                                  </button>
                                </div>
                              </div>
                              <div class="mt-3">
                                <span class="invalid-field">{{
                                  newSalespersonProgramErrors.file
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="mt-2">
                            <button
                              type="button"
                              class="ml-0 p-0 mt-1 btn-link btn text-info width-fit-content"
                              @click="doExport()"
                            >
                              Incentive list template
                              <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="newSalespersonProgram.incentiveProductSkuList.length > 0">
                      <div class="col-lg-12 d-inline-flex">
                        <div class="col-lg-4 pl-0">
                          <label class="text-label">MTM Number</label>
                        </div>
                        <div class="col-lg-2">
                          <label class="text-label">Min Quantity</label>
                        </div>
                        <div class="col-lg-2 pl-0">
                          <label class="text-label">Max Quantity</label>
                        </div>
                        <div class="col-lg-2 pl-0">
                          <label class="text-label">Unit to hit</label>
                        </div>
                        <div class="col-lg-2 pl-0">
                          <label class="text-label">Incentive</label>
                        </div>
                      </div>
                      <div v-for="list in newSalespersonProgram.incentiveProductSkuList" :key="list.id" class="col-lg-12 d-inline-flex mb-4">
                        <div class="col-lg-4 pl-0">
                          <input 
                            v-model="list.mtm"
                            class="form-control" 
                            :disabled="true"
                            type="text"
                          />
                        </div>
                        <div class="col-lg-2 pl-0">
                          <input 
                            v-model="list.min"
                            class="form-control" 
                            :disabled="true"
                            type="number"
                          />
                        </div>
                        <div class="col-lg-2 pl-0">
                          <input 
                            v-model="list.max"
                            class="form-control" 
                            :disabled="true"
                            type="number"
                          />
                        </div>
                        <div class="col-lg-2 pl-0">
                          <input 
                            v-model="list.unit"
                            class="form-control" 
                            :disabled="true"
                            type="number"
                          />
                        </div>
                        <div class="col-lg-2 pl-0 pr-0">
                          <input 
                            v-model="list.incentive"
                            class="form-control" 
                            :disabled="true"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 d-inline-flex justify-content-end pr-0">
                    <router-link to="/dashboard/incentive/salesperson-normal">
                      <span
                        class="btn btn-primary d-flex align-items-center mr-2"
                      >
                        Back 
                      </span>
                    </router-link>
                    <span
                      class="btn btn-primary d-flex align-items-center mr-2"
                      @click="handleSubmit"
                    >
                      Confirm 
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
         <BModal
          ref="outlets-modal"
          size="lg"
          hide-footer
          title="Select Outlets"
        >
          <div>
            <VueBootstrap4Table
              :classes="classes"
              :rows="outletOptions"
              :columns="columns"
              :config="config"
              :total-rows="total"
              :selected_items="newSalespersonProgram.dealerOutletsId"
              :show-loader="true"
              @on-change-query="onChangeQuery"
              @refresh-data="getOutletOptions"
              @on-select-row="outletInput"
              @on-all-select-rows="outletInput"
              @on-unselect-row="outletInput"
              @on-all-unselect-rows="outletInput"
            >
              <template slot="sort-asc-icon">
                <i class="mdi mdi-sort-ascending"></i>
              </template>
              <template slot="sort-desc-icon">
                <i class="mdi mdi-sort-descending"></i>
              </template>
              <template slot="no-sort-icon">
                <i class="mdi mdi-sort-variant"></i>
              </template>
              <template slot="empty-results">
                <div class="bold-text">No Outlets Found!</div>
              </template>
            </VueBootstrap4Table>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button
              type="btn"
              class="btn btn-primary btn-sm"
              @click="outletModalToggle"
            >
              Close
            </button>
          </div>
         </BModal>
      </div>
    </div>
  </div>
</template>
<script>

import breadcrumb from "../../components/breadcrumb/breadcrumb";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { ALL_DEALER_OUTLETS_BY_REGION_REQUEST } from "../../store/modules/outlet/actions";
import { 
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST, 
  GET_INCENTIVE_DETAILS_BY_ID_REQUEST,
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST 
} from "../../store/modules/new-incentive/action";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { BFormFile, BModal } from "bootstrap-vue";

import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "../../store/modules/ui/actions";
import { export_json_to_excel } from "../../utils/Export2Excel";
import XLSX from "xlsx";
import $ from 'jquery';

export default {
  name: "SalespersonIncentiveAdd",
  components: {
    breadcrumb,
    Multiselect,
    Datepicker,
    BFormFile,
    BModal,
    VueBootstrap4Table: VueBootstrap4Table,
  },
  data(){
    return{
      config: {
        checkbox_rows: true,
        rows_selectable: true,
        server_mode: false,
        card_title: "Outlets",
        card_mode: false,
        show_refresh_button: true,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      today: new Date(),
      newSalespersonProgram: {
        name: "",
        regionList: [],
        stateList: [],
        dealerList: [],
        dealerOutletsId: [],
        incentiveProductSkuList: [],
        startDate: new Date(),
        endDate: ""
      }, 
      regionList: [],
      regionOptions: [],
      stateOptions: [],
      dealerOptions: [],
      outletOptions: [],
      newSalespersonProgramErrors: {},
      disabledDates: {
        to: "", // Disable all dates up to specific date
        from: "" // Disable all dates after specific date
      },
      fileUpload: [],
      tHeader: ["MTM Number", "Min Quantity", "Max Quantity", "Unit To Hit", "Incentive Amount"],
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      columns: [
        {
          name: "id",
          label: "Id",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          uniqueId: true,
          visibility: false
        },
        {
          name: "dealerName",
          label: "Dealer Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "lppCode",
          label: "Dealer LPP Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "dealerOutletCode",
          label: "Dealer Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "name",
          label: "Outlet Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "address",
          label: "Outlet Address",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      total: 0,
    }
  },
  watch: {
    "newSalespersonProgram.regionList": function(event){
      this.getStateOptions(event);
      // this.getDealerOptions(this.newSalespersonProgram.stateList);
      // this.getOutletOptions(this.newSalespersonProgram.dealerList);
    },
    "newSalespersonProgram.stateList": function(event){
      this.getDealerOptions(event);
    },
    "newSalespersonProgram.dealerList": function(event){
      this.getOutletOptions(event);
    }
  },
  methods: {
    async outletInput(outlets) {
      const localOutlets = await outlets.selected_items;
      this.newSalespersonProgram.dealerOutletsId = [];
      // Filtering out deactivated dealers
      this.newSalespersonProgram.dealerOutletsId = [
        ...localOutlets.filter(item => item.status === "ACTIVE")
      ];
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getOutletOptions();
    },
    outletModalToggle(){
      this.$refs["outlets-modal"].toggle();
    },
    checkFormValidity() {
      this.newSalespersonProgramErrors = {};
      let error = 0;
      if(!this.newSalespersonProgram.name){
        this.newSalespersonProgramErrors["name"] = "Name field can't be empty";
        error++;
      } 
      if(this.newSalespersonProgram.regionList.length === 0){
        this.newSalespersonProgramErrors["regionList"] = "At least one region must be selected";
        error++;
      } 
      if(this.newSalespersonProgram.stateList.length === 0){
        this.newSalespersonProgramErrors["stateList"] = "At least one state must be selected";
        error++;
      } 
      if(this.newSalespersonProgram.dealerList.length === 0){
        this.newSalespersonProgramErrors["dealerList"] = "At least one dealer must be selected";
        error++;
      } 
      if(this.newSalespersonProgram.dealerOutletsId.length === 0){
        this.newSalespersonProgramErrors["dealerOutletsId"] = "At least one outlet must be selected";
        error++;
      } 
      if(!this.newSalespersonProgram.endDate){
        this.newSalespersonProgramErrors["endDate"] = "Date must be selected";
        error++;
      } 
      if(this.newSalespersonProgram.incentiveProductSkuList.length === 0){
        this.newSalespersonProgramErrors["file"] = "File must be uploaded";
        error++;
      } 
      if(this.newSalespersonProgram.min < 0){
        this.newSalespersonProgramErrors["min"] = "Min can't be negative";
        error++;
      } 
      if(Number(this.newSalespersonProgram.max) !== 0 && this.newSalespersonProgram.max < this.newSalespersonProgram.min){
        this.newSalespersonProgramErrors["max"] = "Max can't be less than min";
        error++;
      } 
      if(this.newSalespersonProgram.max < 0){
        this.newSalespersonProgramErrors["max"] = "Max can't be negative";
        error++;
      } 
      if (error) {
        return false;
      } else {
        return true;
      }
    },
    getRegionList(){
      this.$store.dispatch(ALL_DEALER_OUTLETS_BY_REGION_REQUEST).then(res => {
        if(res.content && res.resultCode === 0){
          this.regionOptions = res.content;
          if(this.$route.params.program){
            this.getSalespersonIncentiveProgram();
          }
        }
      });
    },
    getStateOptions(event){
      let arr = [];
      let i;
      let k;
      for (i = 0; i < event.length; i++) {
        for (k = 0; k < this.newSalespersonProgram.stateList.length; k++) {
          if(event[i].stateList.some(element => element.state === this.newSalespersonProgram.stateList[k].state )){
            arr.push(this.newSalespersonProgram.stateList[k]);
          }
        }
      }
      this.stateOptions = this.newSalespersonProgram.regionList.map(el => el.stateList).flat();
      this.newSalespersonProgram.stateList = arr.filter(this.onlyUnique);
    },
    getDealerOptions(event){
      let arr = [];
      let i;
      let k;
      for (i = 0; i < event.length; i++) {
        for (k = 0; k < this.newSalespersonProgram.dealerList.length; k++) {
          if(event[i].dealerList.some(element => element.dealerName === this.newSalespersonProgram.dealerList[k].dealerName )){
            arr.push(this.newSalespersonProgram.dealerList[k]);
          }
        }
      }
      this.dealerOptions = this.newSalespersonProgram.stateList.map(el => el.dealerList).flat();
      this.newSalespersonProgram.dealerList = arr.filter(this.onlyUnique);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getOutletOptions(event){
      let arr = [];
      let i;
      let k;
      for (i = 0; i < event.length; i++) {
        for (k = 0; k < this.newSalespersonProgram.dealerOutletsId.length; k++) {
          if(event[i].dealerOutletList.some(element => element.name === this.newSalespersonProgram.dealerOutletsId[k].name )){
            arr.push(this.newSalespersonProgram.dealerOutletsId[k]);
          }
        }
      }
      this.outletOptions = this.newSalespersonProgram.dealerList.map(el => el.dealerOutletList).flat();

      this.newSalespersonProgram.dealerOutletsId = arr.filter(this.onlyUnique);
      // if(action === 'add'){
      //   this.outletOptions = this.newSalespersonProgram.dealerOutletsId.map(el => el).flat();
      // } else {
      //   this.newSalespersonProgram.dealerOutletsId = this.newSalespersonProgram.dealerOutletsId.filter( function( el ) {
      //     return !event.dealerOutletList.includes( el );
      //   });
      // }
    },
    selectAll(event, list, options, promoter){
      event.preventDefault();
      let listx = [];
      for (let item of options) {
        if(promoter === 0){
          if(item.hasPromoter){
            listx.push(item);
            this.$emit('handle-select', item);
          }
        } else if(promoter === 1){
          if(item.hasPromoter === false){
            listx.push(item);
            this.$emit('handle-select', item);
          }
        } else {
          listx.push(item);
          this.$emit('handle-select', item);
        }
      }
      if(list === 'regionList'){
        this.newSalespersonProgram.regionList = listx;
        this.getStateOptions(this.newSalespersonProgram.regionList);
      } else if(list === 'stateList'){
        this.newSalespersonProgram.stateList = listx;
        this.getDealerOptions(this.newSalespersonProgram.stateList);
      } else if(list === 'dealerList'){
        this.newSalespersonProgram.dealerList = listx;
        this.getOutletOptions(this.newSalespersonProgram.dealerList);
      } else if(list === 'dealerOutletsId'){
        this.newSalespersonProgram.dealerOutletsId = listx;
      }
    },
    checkDates() {
      let startDate = moment(this.newSalespersonProgram.startDate).format("YYYY-MM-DD");
      let endDate = moment(this.newSalespersonProgram.endDate).format("YYYY-MM-DD");

      if (!moment(endDate).isSame(startDate) && !moment(endDate).isAfter(startDate)) {
        this.newSalespersonProgramErrors.endDate = "End date must be after or equal to start date";
      } else {
        delete this.newSalespersonProgramErrors.endDate;
      }
    },
    resetFileUploader() {
      this.$refs.fileUploader.reset();
      this.newSalespersonProgram.incentiveProductSkuList = [];
    },
    handleFileUpload(event) {
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Validating file..."
      });

      const file = event.target.files[0];
      
      // Validating file upload extension
      if (this.checkUploadType(file.name)) {
        this.getBase64(file).then(data => {
          var workbook = XLSX.read(data, { type: "base64" });
          var first_sheet_name = workbook.SheetNames[0];

          // Get worksheet
          let worksheet = workbook.Sheets[first_sheet_name];
          const sheet = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          //Validating file upload columns
          if (sheet.length > 0) {
            if(this.checkFileData(sheet)){
              this.dataAdjustmentsAfterUpload(sheet);
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "File validation completed successfully",
                timeOut: true
              });
            } else {
              this.$store.dispatch(NOTIFICATION_ERROR, {
              message:
                "Data is incorrect. " + this.newSalespersonProgramErrors["fileErrors"]
            });
            }
          } else {
            this.$store.dispatch(NOTIFICATION_ERROR, {
              message:
                "File format incorrect. Please use provided incentive list template"
            });
          }
        });
      }
    },
    checkUploadType(fileName) {
      if (fileName) {
        let filename = fileName.split(".").pop();
        if (filename === "xlsx") {
          delete this.newSalespersonProgramErrors.file;
          return true;
        } else {
          this.newSalespersonProgramErrors.file = "File type must be .xlsx";
          return false;
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    checkFileData(sheet){
      let valid = true;
      for(var i = 0; i < sheet.length; i++) {
        if (!(sheet[i]["MTM Number"] && sheet[i]["Unit To Hit"] && sheet[i]["Incentive Amount"])) {
          valid = false;
          this.newSalespersonProgramErrors["fileErrors"] = "Min Quantity, Max Quantity, Unit To Hit can't be empty";
          break;
        } 
        if( (/[^0-9]+/.test(sheet[i]["Min Quantity"])) || (/[^0-9]+/.test(sheet[i]["Max Quantity"])) || (/[^0-9]+/.test(sheet[i]["Unit To Hit"]))){
          valid = false;
          this.newSalespersonProgramErrors["fileErrors"] = "Min Quantity, Max Quantity, Unit To Hit invalid input";
          break;
        }
        if( sheet[i]["Min Quantity"] < 0 && sheet[i]["Max Quantity"] < 0 && sheet[i]["Unit To Hit"] < 0 ){
          valid = false;
          this.newSalespersonProgramErrors["fileErrors"] = "Min Quantity, Max Quantity, Unit To Hit can't be negative";
          break;
        }
        if( sheet[i]["Max Quantity"] !== 0 && sheet[i]["Min Quantity"] > sheet[i]["Max Quantity"] ){
          valid = false;
          this.newSalespersonProgramErrors["fileErrors"] = "Max can't be less than min";
          break;
        }
      }
      return valid;
    },
    dataAdjustmentsAfterUpload(sheet) {
      let formattedArrayParams = sheet.map(item => {
        return {
          mtm: item["MTM Number"],
          min: item["Min Quantity"],
          max: item["Max Quantity"],
          unit: item["Unit To Hit"],
          incentive: item["Incentive Amount"],
        };
      });
      this.newSalespersonProgram.incentiveProductSkuList = formattedArrayParams;
      // this.newSalespersonProgram.fileName = name;
    },
    doExport() {
      const data = "";
      const fileName = "incentive list template";
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: fileName,
        sheetName: fileName,
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    handleSubmit(){
      if(this.checkFormValidity()){
        let program = {...this.newSalespersonProgram};
        program.dealerOutletsId = program.dealerOutletsId.map(el => el.id);
        let startDate = new Date(program.startDate);
        let endDate = new Date(program.endDate);
        program.startDate = this.getTime(startDate);
        program.endDate = this.getTime(endDate);
        program.min = Number(program.min);
        program.max = Number(program.max);
        program.incentiveProductSkuList.forEach(el => el.min = Number(el.min));
        program.incentiveProductSkuList.forEach(el => el.max = Number(el.max));
        delete program.fileName;
        delete program.dealerList;
        delete program.regionList;
        delete program.stateList;
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: "Salesperson Incentive Program is submitting..."
        });
        let url;
        if(this.newSalespersonProgram.id){
          url = UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST;
        } else {
          url = ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST;
        }
        this.$store.dispatch(url, program).then(res => {
          if(res.content && res.resultCode === 0){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: this.newSalespersonProgram.id ? "Salesperson Incentive Program is updated" : "Salesperson Incentive Program is submitted",
              timeOut: true
            });
            this.$router.push({ name: 'Salesperson Incentive' })
          }
        }).catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err,
            timeOut: true
          });
        });
      }
    },
    getTime(date){
      return date.getFullYear().toString()+"-"+((date.getMonth()+1).toString().length==2?(date.getMonth()+1).toString():"0"+(date.getMonth()+1).toString())+"-"+(date.getDate().toString().length==2?date.getDate().toString():"0"+date.getDate().toString())+" "+(date.getHours().toString().length==2?date.getHours().toString():"0"+date.getHours().toString())+":"+((parseInt(date.getMinutes()/5)*5).toString().length==2?(parseInt(date.getMinutes()/5)*5).toString():"0"+(parseInt(date.getMinutes()/5)*5).toString())+":00";
    },
    getSalespersonIncentiveProgram(){
      this.$store.dispatch(GET_INCENTIVE_DETAILS_BY_ID_REQUEST, this.$route.params.program).then(res => {
        if(res.content && res.resultCode === 0){
          this.newSalespersonProgram = {...res.content};
          this.newSalespersonProgram.regionList = this.newSalespersonProgram.regionList.map(el => {
            let element = {}; 
            element["region"] = el;
            element["stateList"] = this.regionOptions.find(element => element.region === el).stateList;
            return element
          });
          this.newSalespersonProgram.stateList = this.newSalespersonProgram.stateList.map(el => {
            let element = {}; 
            let state;
            let i;
            for (i = 0; i < this.regionOptions.length; i++) {
              if(this.regionOptions[i].stateList.find(state => state.state === el)){
                state = this.regionOptions[i].stateList.find(state => state.state === el);
              }
            }
            element = state;
            return element
          });
          this.newSalespersonProgram.dealerList = this.newSalespersonProgram.dealerList.map(el => {
            let element = {};
            let dealerK;
            let i;
            for (i = 0; i < this.regionOptions.length; i++) {
              this.regionOptions[i].stateList.map(state => state.dealerList.map(dealer => {
                if(dealer.dealerName === el.name){
                  dealerK = dealer;
                }
              }))
            }
            element = dealerK;
            return element
          })
          this.newSalespersonProgram.dealerOutletsId = this.newSalespersonProgram.outletList;
          this.getStateOptions(this.newSalespersonProgram.regionList);
          this.getDealerOptions(this.newSalespersonProgram.stateList);
          this.getOutletOptions(this.newSalespersonProgram.dealerList);
        }
      })
    }
  },
  async mounted() {
    await this.getRegionList();
    $(".only-numbers").on("keypress keyup blur",function (event) {    
      $(this).val($(this).val().replace(/[^\d].+/, ""));
      if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
      }
    });

  }
}
</script>