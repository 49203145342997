import request from "@/utils/request";
import {
  GET_TARGET_COUNTRY_BY_YEAR_SERVICE,
  ADD_TARGET_COUNTRY_BY_YEAR_SERVICE,
  GET_TARGET_PROMOTER_BY_YEAR_SERVICE,
  ADD_TARGET_PROMOTER_BY_YEAR_SERVICE,
  ADD_TARGET_COUNTRY_BY_WEEK_SERVICE,
  ADD_TARGET_PROMOTER_BY_WEEK_SERVICE,
  GET_TARGET_COUNTRY_BY_WEEK_SERVICE,
  GET_TARGET_PROMOTER_BY_WEEK_SERVICE,
  GET_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE,
  ADD_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE,
  UPLOAD_TARGET_PROMOTER_BY_REGION,
  ADD_TARGET_PROMOTER_BY_ID,
  UPLOAD_TARGET_PROMOTER_LEADER_BY_REGION,
  ADD_TARGET_PROMOTER_LEADER_BY_ID,
  GET_SUB_REGION_TARGET_SERVICE,
  GET_SUB_REGION_SERVICE,
  UPLOAD_SUB_REGION_TARGET_BY_REGION,
  ADD_SUB_REGION_TARGET_BY_ID,
  GET_DEALER_TARGET_SERVICE,
  GET_DEALER_SERVICE,
  UPLOAD_DEALER_TARGET_BY_REGION,
  ADD_DEALER_TARGET_BY_ID,
  WEEKLY_VISITATION_SERVICE,
  DOWNLOAD_WEEKLY_VISITATION_SERVICE,
  UPLOAD_WEEKLY_VISITATION_SERVICE,
  DOWNLOAD_VISITATION_TEMPLATE_SERVICE,
  VALIDATE_VISITATION_SERVICE
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

export async function getCountryDataList(postData) {
  let year = new Date().getFullYear();
  let type = null;
  let quarter = null;
  let url = "";
  if (postData.year) {
    year = postData.year;
  }

  if (postData.type) {
    type = postData.type;
  }

  if (postData.quarter > 0 && postData.quarter < 5) {
    quarter = "Q" + postData.quarter;
  }

  if (postData.monthly) {
    url = GET_TARGET_COUNTRY_BY_YEAR_SERVICE + year + "/" + type;
  }

  if (postData.weekly) {
    url = GET_TARGET_COUNTRY_BY_WEEK_SERVICE + year + "/" + quarter + "/" + type;
  }

  return await request({
    url: url,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadTragetCountryData(postData) {
  if (postData.type === "monthly") {
    return await request({
      url: ADD_TARGET_COUNTRY_BY_YEAR_SERVICE,
      method: "post",
      data: postData.data,
      timeout: DEFAULT_TIMEOUT
    });
  } else if (postData.type === "weekly") {
    return await request({
      url: ADD_TARGET_COUNTRY_BY_WEEK_SERVICE,
      method: "put",
      data: postData.data,
      timeout: DEFAULT_TIMEOUT
    });
  } else {
    return false;
  }
}

export async function getPromoterDataList(postData) {
  let year = new Date().getFullYear();
  let type = null;
  let quarter = null;
  let country = null;
  let url = "";

  if (postData.year) {
    year = postData.year;
  }

  if (postData.type) {
    type = postData.type;
  }

  if (postData.quarter > 0 && postData.quarter < 5) {
    quarter = "Q" + postData.quarter;
  }

  if (postData.monthly) {
    url = GET_TARGET_PROMOTER_BY_YEAR_SERVICE + year + "/" + type;
  }

  if (postData.weekly) {
    url = GET_TARGET_PROMOTER_BY_WEEK_SERVICE + year + "/" + quarter + "/" + type;
  }

  if (postData.country) {
    country = postData.country.id;
    url = GET_TARGET_PROMOTER_BY_WEEK_SERVICE + country + "/" + year + "/" + quarter + "/" + type;
  }

  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadTragetPromoterData(postData) {
  if (postData.type === "monthly") {
    return await request({
      url: ADD_TARGET_PROMOTER_BY_YEAR_SERVICE,
      method: "post",
      data: postData.data,
      timeout: DEFAULT_TIMEOUT
    });
  } else if (postData.type === "weekly") {
    return await request({
      url: ADD_TARGET_PROMOTER_BY_WEEK_SERVICE,
      method: "put",
      data: postData.data,
      timeout: DEFAULT_TIMEOUT
    });
  } else {
    return false;
  }
}

export async function getPromoterLeaderDataList(postData) {
  let year = new Date().getFullYear();
  let type = null;
  let quarter = null;
  let country = null;
  let url = "";

  if (postData.year) {
    year = postData.year;
  }

  if (postData.type) {
    type = postData.type;
  }

  if (postData.quarter > 0 && postData.quarter < 5) {
    quarter = "Q" + postData.quarter;
  }

  if (postData.weekly) {
    url = GET_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE + year + "/" + quarter + "/" + type;
  }

  if (postData.country) {
    country = postData.country.id;
    url = GET_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE + country + "/" + year + "/" + quarter + "/" + type;
  }

  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadTragetPromoterLeaderData(postData) {
  if (postData.type === "monthly") {
    // eslint-disable-next-line
  } else if (postData.type === "weekly") {
    return await request({
      url: ADD_TARGET_PROMOTER_LEADER_BY_WEEK_SERVICE,
      method: "put",
      data: postData.data,
      timeout: DEFAULT_TIMEOUT
    });
  } else {
    return false;
  }
}

export async function targetPromoterFileCheck(postData) {
  return await request({
    url: UPLOAD_TARGET_PROMOTER_BY_REGION,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadTargetPromoterById(postData) {
  return await request({
    url: ADD_TARGET_PROMOTER_BY_ID,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function targetPromoterLeaderFileCheck(postData) {
  return await request({
    url: UPLOAD_TARGET_PROMOTER_LEADER_BY_REGION,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadTargetPromoterLeaderById(postData) {
  return await request({
    url: ADD_TARGET_PROMOTER_LEADER_BY_ID,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSubRegionTarget(postData) {
  let year = new Date().getFullYear();
  let type = null;
  let quarter = null;
  let country = null;
  let url = "";

  if (postData.year) {
    year = postData.year;
}

  if (postData.type) {
    type = postData.type;
  }

  if (postData.quarter > 0 && postData.quarter < 5) {
    quarter = "Q" + postData.quarter;
  }

  if (postData.weekly) {
    url = GET_SUB_REGION_TARGET_SERVICE + year + "/" + quarter + "/" + type;
  }

  if (postData.country) {
    country = postData.country.id;
    url = GET_SUB_REGION_TARGET_SERVICE + year + "/" + quarter + "/" + type  + "/" + country ;
  }

  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSubRegion() {
  return await request({
    url: GET_SUB_REGION_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function targetSubRegionFileCheck(postData) {
  return await request({
    url: UPLOAD_SUB_REGION_TARGET_BY_REGION,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadSubRegionTargetById(postData) {
  return await request({
    url: ADD_SUB_REGION_TARGET_BY_ID,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerTarget(postData) {
  let year = new Date().getFullYear();
  let type = null;
  let quarter = null;
  let country = null;
  let url = "";

  if (postData.year) {
    year = postData.year;
  }

  if (postData.type) {
    type = postData.type;
  }

  if (postData.quarter > 0 && postData.quarter < 5) {
    quarter = "Q" + postData.quarter;
  }

  if(postData.country == null) {
    url = GET_DEALER_TARGET_SERVICE + year + "/" + quarter + "/" + type;
  } else {
    if(postData.country.id !== 0) {
      country = postData.country.id
      if (country !== undefined || country !== 0) {
        url = GET_DEALER_TARGET_SERVICE + year + "/" + quarter + "/" + type + "/" + country;
      }
    } else {
      url = GET_DEALER_TARGET_SERVICE + year + "/" + quarter + "/" + type;
    }
  }
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealer() {
  return await request({
    url: GET_DEALER_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function targetDealerFileCheck(postData) {
  return await request({
    url: UPLOAD_DEALER_TARGET_BY_REGION,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadDealerTargetById(postData) {
  return await request({
    url: ADD_DEALER_TARGET_BY_ID,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getWeeklyVisitation(postData) {
  return await request({
    url: WEEKLY_VISITATION_SERVICE,
    method: "get",
    params: postData, 
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadWeeklyVisitation(postData) {
  return await request({
    url: DOWNLOAD_WEEKLY_VISITATION_SERVICE,
    method: "get",
    params: postData, 
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadVisitationTemplate(postData) {
  return await request({
    url: DOWNLOAD_VISITATION_TEMPLATE_SERVICE,
    method: "get",
    params: postData, 
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadWeeklyVisitation(postData) {
  return await request({
    url: UPLOAD_WEEKLY_VISITATION_SERVICE,
    method: "post",
    data: postData, 
    timeout: DEFAULT_TIMEOUT
  });
}

export async function validWeeklyVisitation(postData) {
  return await request({
    url: VALIDATE_VISITATION_SERVICE,
    method: "post",
    data: postData, 
    timeout: DEFAULT_TIMEOUT
  });
}
