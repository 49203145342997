import request from "@/utils/request";
import {
  PROMOTER_ATTENDANCE_DATE_SERVICE,
  DOWNLOAD_PROMOTER_ATTENDANCE_SERVICE,
  DOWNLOAD_INCENTIVE_PAYABLE_SERVICE,
  DOWNLOAD_NORMAL_INC_ENT_SERVICE,
  DOWNLOAD_TARGET_INC_ENT_SERVICE,
  DOWNLOAD_BONUS_INC_ENT_SERVICE,
  DOWNLOAD_DEALER_INC_ENT_SERVICE,
  DOWNLOAD_VISITATION_SERVICE,
  SEND_VISITATION_SERVICE,
  DOWNLOAD_VISITATION_SUMMARY_SERVICE,
  DOWNLOAD_VISITATION_DASHBOARD_SERVICE,
  SEND_VISITATION_SUMMARY_SERVICE,
  DOWNLOAD_WEARTEAR_SERVICE,
  SEND_WEARTEAR_SERVICE,
  DOWNLOAD_HEARTBEAT_SERVICE,
  SEND_HEARTBEAT_SERVICE,
} from "../constants/services";

import { NO_TIMEOUT } from "../constants/index";

export async function getPromoterAttendanceDate(postData) {
  return await request({
    url: PROMOTER_ATTENDANCE_DATE_SERVICE + "?fyf=" + postData.fyf + "&qf=" + postData.qf ,
    method: "GET",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadPromoterAttendance(postData) {
  return await request({
    url: DOWNLOAD_PROMOTER_ATTENDANCE_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadIncentivePayableFile(postData) {
  return await request({
    url: DOWNLOAD_INCENTIVE_PAYABLE_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadNormalIncentiveEntitlement(postData) {
  return await request({
    url: DOWNLOAD_NORMAL_INC_ENT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadBonusIncentiveEntitlement(postData) {
  return await request({
    url: DOWNLOAD_BONUS_INC_ENT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadTargetIncentiveEntitlement(postData) {
  return await request({
    url: DOWNLOAD_TARGET_INC_ENT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadDealerIncentiveEntitlement(postData) {
  return await request({
    url: DOWNLOAD_DEALER_INC_ENT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadVisitation(postData) {
  return await request({
    url: DOWNLOAD_VISITATION_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function sendVisitation(postData) {
  return await request({
    url: SEND_VISITATION_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadVisitationSummary(postData) {
  return await request({
    url: DOWNLOAD_VISITATION_SUMMARY_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadVisitationDashboard(postData) {
  return await request({
    url: DOWNLOAD_VISITATION_DASHBOARD_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function sendVisitationSummary(postData) {
  return await request({
    url: SEND_VISITATION_SUMMARY_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadWearTear(postData) {
  return await request({
    url: DOWNLOAD_WEARTEAR_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function sendWearTear(postData) {
  return await request({
    url: SEND_WEARTEAR_SERVICE,
    method: "get",
    params: postData,
    timeout: NO_TIMEOUT
  });
}

export async function downloadHeartbeat(postData) {
  return await request({
    url: DOWNLOAD_HEARTBEAT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}

export async function sendHeartbeat(postData) {
  return await request({
    url: SEND_HEARTBEAT_SERVICE,
    method: "post",
    data: postData,
    timeout: NO_TIMEOUT
  });
}