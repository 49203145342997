export const ADMIN_CLEAN_CACHE_REQUEST = "ADMIN_CLEAN_CACHE_REQUEST";
export const ADMIN_CLEAN_CACHE_REQUEST_SUCCESS = "ADMIN_CLEAN_CACHE_REQUEST_SUCCESS";
export const ADMIN_CLEAN_CACHE_REQUEST_ERROR = "ADMIN_CLEAN_CACHE_REQUEST_ERROR";

export const ADMIN_SERIAL_REMATCH_REQUEST = "ADMIN_SERIAL_REMATCH_REQUEST";
export const ADMIN_SERIAL_REMATCH_REQUEST_SUCCESS = "ADMIN_SERIAL_REMATCH_REQUEST_SUCCESS";
export const ADMIN_SERIAL_REMATCH_REQUEST_ERROR = "ADMIN_SERIAL_REMATCH_REQUEST_ERROR";

export const ADMIN_REGISTRATION_SUBMIT = "ADMIN_REGISTRATION_SUBMIT";
export const ADMIN_REGISTRATION_SUBMIT_SUCCESS = "ADMIN_REGISTRATION_SUBMIT_SUCCESS";
export const ADMIN_REGISTRATION_SUBMIT_ERROR = "ADMIN_REGISTRATION_SUBMIT_ERROR";

export const SALES_PERSON_REGISTRATION_SUBMIT = "SALES_PERSON_REGISTRATION_SUBMIT";
export const SALES_PERSON_REGISTRATION_SUBMIT_SUCCESS = "SALES_PERSON_REGISTRATION_SUBMIT_SUCCESS";
export const SALES_PERSON_REGISTRATION_SUBMIT_ERROR = "SALES_PERSON_REGISTRATION_SUBMIT_ERROR";

export const SALES_PERSON_CHECK_REQUEST = "SALES_PERSON_CHECK_REQUEST";
export const SALES_PERSON_CHECK_REQUEST_SUCCESS = "SALES_PERSON_CHECK_REQUEST_SUCCESS";
export const SALES_PERSON_CHECK_REQUEST_ERROR = "SALES_PERSON_CHECK_REQUEST_ERROR";

export const REGIONAL_ADMIN_REGISTRATION_SUBMIT = "REGIONAL_ADMIN_REGISTRATION_SUBMIT";
export const REGIONAL_ADMIN_REGISTRATION_SUBMIT_SUCCESS = "REGIONAL_ADMIN_REGISTRATION_SUBMIT_SUCCESS";
export const REGIONAL_ADMIN_REGISTRATION_SUBMIT_ERROR = "REGIONAL_ADMIN_REGISTRATION_SUBMIT_ERROR";

export const REGIONAL_REGISTRATION_SUBMIT = "REGIONAL_REGISTRATION_SUBMIT";
export const REGIONAL_REGISTRATION_SUBMIT_SUCCESS = "REGIONAL_REGISTRATION_SUBMIT_SUCCESS";
export const REGIONAL_REGISTRATION_SUBMIT_ERROR = "REGIONAL_REGISTRATION_SUBMIT_ERROR";

export const COUNTRY_HEAD_REGISTRATION_SUBMIT = "COUNTRY_HEAD_REGISTRATION_SUBMIT";
export const COUNTRY_HEAD_REGISTRATION_SUBMIT_SUCCESS = "COUNTRY_HEAD_REGISTRATION_SUBMIT_SUCCESS";
export const COUNTRY_HEAD_REGISTRATION_SUBMIT_ERROR = "COUNTRY_HEAD_REGISTRATION_SUBMIT_ERROR";

export const DEALER_REGISTRATION_SUBMIT = "DEALER_REGISTRATION_SUBMIT";
export const DEALER_REGISTRATION_SUBMIT_SUCCESS = "DEALER_REGISTRATION_SUBMIT_SUCCESS";
export const DEALER_REGISTRATION_SUBMIT_ERROR = "DEALER_REGISTRATION_SUBMIT_ERROR";

export const PROMOTER_LEADER_REGISTRATION_SUBMIT = "PROMOTER_LEADER_REGISTRATION_SUBMIT";
export const PROMOTER_LEADER_REGISTRATION_SUBMIT_SUCCESS = "PROMOTER_LEADER_REGISTRATION_SUBMIT_SUCCESS";
export const PROMOTER_LEADER_REGISTRATION_SUBMIT_ERROR = "PROMOTER_LEADER_REGISTRATION_SUBMIT_ERROR";

export const PROMOTER_CHECK_REQUEST = "PROMOTER_CHECK_REQUEST";
export const PROMOTER_CHECK_REQUEST_SUCCESS = "PROMOTER_CHECK_REQUEST_SUCCESS";
export const PROMOTER_CHECK_REQUEST_ERROR = "PROMOTER_CHECK_REQUEST_ERROR";

export const PROMOTER_REGISTRATION_SUBMIT = "PROMOTER_REGISTRATION_SUBMIT";
export const PROMOTER_REGISTRATION_SUBMIT_SUCCESS = "PROMOTER_REGISTRATION_SUBMIT_SUCCESS";
export const PROMOTER_REGISTRATION_SUBMIT_ERROR = "PROMOTER_REGISTRATION_SUBMIT_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";

export const RESET_PASSWORD_WEB_REQUEST = "RESET_PASSWORD_WEB_REQUEST";
export const RESET_PASSWORD_WEB_REQUEST_SUCCESS = "RESET_PASSWORD_WEB_REQUEST_SUCCESS";
export const RESET_PASSWORD_WEB_REQUEST_ERROR = "RESET_PASSWORD_WEB_REQUEST_ERROR";

export const AGENCY_REGISTRATION_SUBMIT = "AGENCY_REGISTRATION_SUBMIT";
export const AGENCY_REGISTRATION_SUBMIT_SUCCESS = "AGENCY_REGISTRATION_SUBMIT_SUCCESS";
export const AGENCY_REGISTRATION_SUBMIT_ERROR = "AGENCY_REGISTRATION_SUBMIT_ERROR";

export const CHECKER_REGISTRATION_SUBMIT = "CHECKER_REGISTRATION_SUBMIT";
export const CHECKER_REGISTRATION_SUBMIT_SUCCESS = "CHECKER_REGISTRATION_SUBMIT_SUCCESS";
export const CHECKER_REGISTRATION_SUBMIT_ERROR = "CHECKER_REGISTRATION_SUBMIT_ERROR";
