<template>
  <div id="media-management-list">
    <breadcrumb 
      title="Dashboard" 
      subtitle="Media Management (Home)" 
      sub-title-url="/media-management/list"
    />
    <b-row class="lnv-main-card">
      <b-col>
        <b-card>
          <b-row align-h="end" class="mb-3">
            <b-col lg="3" class="text-right">
              <button
                v-if="canEdit"
                class="btn btn-sm btn-primary btn-filter"
                @click="$router.push('/media-management/list/form')"
              >
                <i class="fa fa-plus"></i>
                <span v-text="$ml.get('add_media')"></span>
              </button>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <VueBootstrap4Table
                :classes="classes"
                :rows="list"
                :columns="columns"
                :config="config"
                :total-rows="total"
                @on-change-query="onChangeQuery"
              >
                <template slot="sort-asc-icon">
                  <i class="mdi mdi-sort-ascending"></i>
                </template>
                <template slot="sort-desc-icon">
                  <i class="mdi mdi-sort-descending"></i>
                </template>
                <template slot="no-sort-icon">
                  <i class="mdi mdi-sort-variant"></i>
                </template>
                <template slot="empty-results">
                  <div v-if="!isLoading" class="bold-text">No Media found!</div>
                  <div v-if="isLoading" class="loading mt-3"></div>
                </template>

                <template slot="mediaManagementCountryListResponseDto[0].countryCode" slot-scope="props">
                  <div class="inner-data">
                    <span>{{ props.row.mediaManagementCountryListResponseDto[0].countryCode || '-' }}</span>                  
                  </div>
                </template>

                <template slot="mediaType" slot-scope="props">
                  <div v-if="props.row.mediaType === 'PHOTO'" v-viewer class="media-img-container mr-3">
                    <img
                      v-for="(data, index) in props.row.mediaManagementContentListResponseDto" :key="index"
                      class="img-fluid media-img"
                      :class="{'d-none': index != 0}"
                      :src="data.imageUrl"
                      alt=""
                      @error="replaceImageByDefault"
                    />
                  </div>
                  <div v-else>
                    <template v-if="props.row.mediaManagementContentListResponseDto.length > 0">                  
                      <a v-for="(data, index) in props.row.mediaManagementContentListResponseDto" 
                        :key="index" 
                        :href="data.url" target="_blank"
                      >
                        Video
                      </a>
                    </template>
                    <span v-else>
                      -
                    </span>
                  </div>
                </template>

                <template slot="displayOn" slot-scope="props">
                  <div class="inner-data">
                    <span>{{ getDisplayText(props.row.displayOn) }}</span>
                  </div>
                </template>

                <template slot="startDate" slot-scope="props">
                  <div class="inner-data">                                        
                    <span>{{ moment(props.row.startDate).format("YYYY-MM-DD") }}</span>
                  </div>
                </template>
                <template slot="endDate" slot-scope="props">
                  <div class="inner-data">                                         
                    <span>{{ moment(props.row.endDate).format("YYYY-MM-DD") }}</span>
                  </div>
                </template>

                <template slot="displayStatus" slot-scope="props">
                  <span :class="'label label-rounded text-white '+getStatusClass(props.row.displayStatus)">
                  {{ getDisplayText(props.row.displayStatus) }}
                  </span>
                </template>
                <template slot="status" slot-scope="props">
                  <lnvStatusTextBox :active="props.row.status === 'ACTIVE'" />
                </template>

                <template slot="Action" slot-scope="props">
                  <div class="btn-group float-left" role="group">
                    <abbr title="Edit">
                      <button class="btn m-0" @click.prevent="goToPage(props.row.id)">
                        <i v-if="canEdit" class="fa fa-edit"></i>
                        <i v-else class="fa fa-eye"></i>
                      </button>
                    </abbr>
                    <template v-if="canEdit && props.row.displayStatus !== 'ON_GOING'">
                      <span class="ml-2">&nbsp;</span>
                      <abbr title="Delete">
                        <button class="btn btn-danger m-0" @click.prevent="doDelete(props.row)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </abbr>
                    </template>
                  </div>
                </template>
              </VueBootstrap4Table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import breadcrumb from "@/components/breadcrumb/breadcrumb";
import VueBootstrap4Table from "@/components/ui/VueBootstrap4Table";
import { MEDIA_LIST_REQUEST, DELETE_MEDIA_REQUEST } from "@/store/modules/media/actions";
import lnvStatusTextBox from "@/components/ui/statusTextBox";
import moment from "moment";
import default_image from "@/assets/default_image.jpg";
import { Message } from "element-ui";

export default {
  name: "MediaManagementPage",
  components: {
    breadcrumb: breadcrumb,
    VueBootstrap4Table: VueBootstrap4Table,
    lnvStatusTextBox: lnvStatusTextBox
  },
  computed: {
    canEdit() {
      return this.isCountryAdmin || this.isCountryMedia
    }
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Media Management(Home)",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false,
          init: {
            value: "",
          },
        },
        highlight_row_hover_color: "grey",
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function () {
            return true;
          },
        },
        row: {},
        cell: {},
      },
      columns: [
        {
          name: "mediaManagementCountryListResponseDto[0].countryCode",
          label: "CTRY",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "5%"
        },
        {
          name: "name",
          label: "Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "mediaType",
          label: "Media Type",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "displayOn",
          label: "Display Type",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },        
        {
          name: "startDate",
          label: "Start Date",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "10%",
        },
        {
          name: "endDate",
          label: "End Date",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "10%",
        },
        {
          name: "displayStatus",
          label: "Status",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          width: "10%",
        },
        {
          name: "Action",
          label: "Action",
          sort: true,
          width: "5%",
        },
      ],
      isLoading: false,
      total: 0,
      filterBy: null,
      list: [],
      moment,      
      defaultImage: default_image,
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isCountryMedia: this.$store.getters.isCountryMedia,
    };
  },
  methods: {
    doDelete(row) {
      this.$buefy.dialog.confirm({
        message: "Do you want to delete " + row.name,
        onConfirm: () => {          
          this.$store.dispatch(DELETE_MEDIA_REQUEST, row.id).then(res => {
            if (res.resultCode === 0) {
              Message({
                message: "Media Deleted",
                type: "success",
                duration: 5 * 1000
              });
              this.getList();
            }
          });
        }
      });
    },
    replaceImageByDefault(e) {
			e.target.src = default_image;
		},
    getStatusClass(status) {
      let className = ""
      switch(status) {
        case "SCHEDULED":
          className = "label-success"
          break
        case "ON_GOING":
          className = "label-warning"
          break
        case "ENDED":
          className = "label-dark"
          break

        default:
          break
      }
      return className
    },
    getDisplayText(type) {
      let text = "-"
      switch(type) {
        case "PRODUCT_CATEGORY":
          text = "By Product Category"
          break
        case "MODEL_NAME":
          text = "By Model Name"
          break
        case "PRODUCT_GROUP":
          text = "By Product Group"
          break
        case "ALL":
          text = "All"
          break
        case "SCHEDULED":
          text = "Scheduled"
          break
        case "ON_GOING":
          text = "On Going"
          break
        case "ENDED":
          text = "End"
          break
        default:
          break
      }
      return text
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
    },
    getList() {

      this.isLoading = true;
      this.list = [];

      const pData = { mediaTabsOption: 'HOMEPAGE'}

      this.$store.dispatch(MEDIA_LIST_REQUEST,pData).then(res => {
        if (res.content && res.resultCode === 0) {
          this.list = res.content;
          this.total = this.list.length;
        }
      })
      .finally(() => this.isLoading = false)
    },
    goToPage(id) {            
      this.$router.push('/media-management/list/form/'+id);
    },
  },
  mounted() {
    this.getList()
  },
};
</script>
<style lang="scss">
#media-management-list {
  .btn-filter {
    height: 40px;
    min-width: 35%;
  }
  .media-img-container {
    display: flex;
  }
  .media-img {
    width: 4em;
    height: 4em;
    border-radius: 5px;
    margin-right: 5px;
  }
  abbr[title] {
    text-decoration: none;
    display: flex;
    height: 100%;
  }
}
</style>
