<template>
<!-- Merchandiser dont have yearly/quarterly splited out into diff component, all in one -->
  <div id="merchandiser-dashboard">
    <breadcrumb title="Dashboard" subtitle="Merchandiser Dashboard" />
    <div class="d-flex flex-column">
      <div class="row lnv-main-card">
        <div class="col-lg-12">
          <div class="card rounded-0">
            <div class="card-body">
              <b-tabs v-model="activeTab">
                <div class="d-flex flex-wrap align-items-center justify-content-end mt-3 ml-3 mr-3 mb-1 ">
                  <div v-if="activeTab !== 2 && isCapRole" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :options="filteredCountries"
                      label="name"
                      track-by="value"
                      :loading="!filteredCountries"
                      :allow-empty="false"
                      :show-labels="false"
                      @input="getCountryId"
                    ></Multiselect>
                  </div>
                  <div v-if="activeTab !== 2" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div v-if="activeTab === 1" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>
                <b-tab-item v-for="i in [0, 1]" :key="i" :label="getBTabLabel(i)">
                  <div id="merchandiser-quarter">
                    <div class="border-primary w-100 mb-0">
                      <!-- First row (Visitation Country View + Visitation Score & Grade Country View) -->
                      <div class="col-lg-12 p-0 clear-fix">
                        <div class="col-lg-6 float-left border-right border-left border-top">
                          <VisitationAttainmentPlan
                            :ref="'visitationCountry'+i"
                            :filters="filters"
                          />
                        </div>

                        <div class="col-lg-6 float-right border-right border-left border-top" style="padding-bottom: 27px">
                          <VisitationScoreGrade
                            :ref="'visitationScoreGrade'+i"
                            :filters="filters"
                          />
                        </div>
                      </div>

                      <!-- Second row (Store Identity vs Category Score View) -->
                      <div class="border col-lg-12">
                        <StoreVsCategory
                          :ref="'storeVsCategory'+i"
                          :filters="filters"
                        />
                      </div>

                      <div class="col-lg-12 p-0">
                        <!-- Third row (Visitation Sub-Region View + Score & Grade Sub-Region View) -->
                        <div class="col-lg-6 float-left border-right border-left border-top border-bottom chart-box">
                          <VisitationSubRegion
                            :ref="'visitationSubRegion'+i"
                            :filters="filters"
                          />
                        </div>
                        <div class="col-lg-6 float-right border-right border-left border-top border-bottom chart-box">
                          <VisitationScoreGradeSubRegion
                            :ref="'visitationScoreGradeSubRegion'+i"
                            :filters="filters"
                          />
                        </div>

                        <!-- Optional row only for CAP (Average Score & Grade By Top Dealer View for all countries except Cambodia) -->
                        <template v-if="isCapFilter">
                          <div class="col-lg-12 pr-0 pl-0 d-inline-flex">
                            <h4 class="top-dealer-header">Top Dealers</h4>
                            <a
                              target="_blank"
                              class="mt-auto mb-auto ml-3 mr-2 pointer"
                              @click="$refs['visitationScoreGradeByTopDealerMultiple'+i][0].exportChart($event, 'CAP')"
                            >
                              <i class="fa fa-download"></i>
                            </a>
                            <a
                              ref="export-link"
                              target="_blank"
                              class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
                            />
                          </div>
                          <div v-for="el in topDealerCountriesInOrder" :key="el.id" class="col-lg-6 float-left border-right border-left border-top chart-box">
                            <VisitationScoreGradeByTopDealer
                              :ref="'visitationScoreGradeByTopDealerMultiple'+i"
                              :filters="filters"
                              :country-id-selected="el.id"
                              :country-name-selected="el.text"
                            />
                          </div>
                        </template>

                      <!-- Last row (Average Score & Grade By Top Dealer View if not CAP + Lenovo vs Competitor View) -->
                        <div v-if="!isCapFilter" class="col-lg-6 float-left border-right border-left border-top chart-box">
                          <VisitationScoreGradeByTopDealer
                            :ref="'visitationScoreGradeByTopDealerSingle'+i"
                            :filters="filters"
                          />
                        </div>

                        <div class="col-lg-6 float-left border-right border-left border-top">
                          <LenovoVsCompetitor
                            :ref="'visitationLenovoVSCompetitor'+i"
                            :filters="filters"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import cloneDeep from "lodash/cloneDeep"
import debounce from 'lodash/debounce';
import breadcrumb from "@/components/breadcrumb/breadcrumb";
import Multiselect from "vue-multiselect";
import { defaultFiscalYear, defaultQuarter } from '@/utils/helpers';
import { DASHBOARD_TOP_FILTERS_GET } from "@/store/modules/dashboard/actions";
import { COUNTRY_LIST_REQUEST } from "@/store/modules/utility/actions";
import VisitationAttainmentPlan from '@/components/charts/visitation-attainment-plan.vue';
import VisitationScoreGrade from '@/components/charts/visitation-score-grade.vue';
import StoreVsCategory from '@/components/charts/store-vs-category.vue';
import VisitationSubRegion from '@/components/charts/visitation-sub-region.vue';
import VisitationScoreGradeSubRegion from '@/components/charts/visitation-score-grade-sub-region.vue';
import VisitationScoreGradeByTopDealer from '@/components/charts/visitation-score-grade-by-top-dealer.vue';
import LenovoVsCompetitor from '@/components/charts/lenovo-vs-competitor.vue';

export default {
  name: "Merchandiser",
  components: {
    breadcrumb,
    Multiselect,
    VisitationAttainmentPlan,
    VisitationScoreGrade,
    StoreVsCategory,
    VisitationSubRegion,
    VisitationScoreGradeSubRegion,
    VisitationScoreGradeByTopDealer,
    LenovoVsCompetitor
  },
  computed: {
    isCapRole() {
      return this.$store.getters.isCap
    },
    isCapFilter() {
      return this.filters.countrySelected.value == "CAP";
    },
    filteredCountries() {
      // remove Korea country from Merchandiser dashboard
      return this.countryOptions ? this.countryOptions.filter( el => el.value !== "KR" ) : [];
    },
    topDealerCountriesInOrder() {
      const list = cloneDeep(this.countries)
      list.sort((a, b) => {
        return this.getTopDealerDisplayRank(a) - this.getTopDealerDisplayRank(b)
      })
      return list
    }
  },
  data() {
    return {
      filters: {
        countrySelected: cloneDeep(this.$store.getters.topFilterCountryData),
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },        
        isExport: false
      },
      countries: [],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      activeTab: 1,
      handleDebouncedScroll: null,
      loadedComponents: [],

    };
  },
  watch: {
    activeTab(tab) { 
      if (tab === 0) this.filters.quarterSelected = {}
      else this.filters.quarterSelected = null
      // then filters watcher will getDashboardData
    },
    filters: {
      handler() { 
        this.$nextTick(() => {
          this.getDashboardData()
        })
      }, deep: true
    }
  },
  methods: {
    getTopDealerDisplayRank(el) {
      if (el.code === "MY") return 1
      else if (el.code === "ID") return 2
      else if (el.code === "PH") return 3
      else if (el.code === "TH") return 4
      else if (el.code === "SG") return 5
      else if (el.code === "TW") return 6
      else if (el.code === "VN") return 7
      else if (el.code === "HK") return 8
      else if (el.code === "ROI") return 9
    },
    async getFilters() {
      await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET)
      .then(res => {
        if (res.content) {
          this.countryOptions = res.content.countryDropdown;
          this.yearOptions = res.content.fiscalYearDropdown;
          this.quarterOptions = res.content.quarterDropdown;
        }
      })
    },
    async getCountries() {
      await this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content.filter(el => el.code != "KH" && el.code != "KR")
          this.getCountryId();
        }
      });
    },
    getCountryId() {
      if(this.filters.countrySelected.value === 'CAP'){
        this.filters.countryIdSelected = '';
      } else {
        const foundCountry = this.countries.find(el => el.code === this.filters.countrySelected.value)
        this.filters.countryIdSelected = foundCountry.id;
      }
    },
    assignCountryFilterForRole() {
      if (this.isCapRole) {
        this.filters.countryIdSelected = '';
      } else if (this.$store.getters.isCountryAdmin
              || this.$store.getters.isCountryView
              || this.$store.getters.isMercLeader) {
        this.filters.countrySelected.value = this.$store.getters.getCountryCode
        this.filters.countryIdSelected = this.$store.getters.getCountryId
      }
    },
    getBTabLabel(i) {
      if (i == 0) {
        return "Year To Date"
      } else {
        return "Quarter"
      }
    },
    getDashboardData() {
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: defaultQuarter(), value: defaultQuarter()};
      }
      if (!this.filters.yearSelected) {
        this.filters.yearSelected = {name: defaultFiscalYear(), value: defaultFiscalYear()};
      }

      // ALL components are lazy loading by this.onScroll()
      this.loadedComponents = [] // reload those component
      this.onScroll()
    },
    onScroll() {
      const tolerance = 600 // load before reaching

      const componentToLoad = ['visitationCountry', 'visitationScoreGrade', 'storeVsCategory',
      'visitationSubRegion', 'visitationScoreGradeSubRegion', 'visitationScoreGradeByTopDealerSingle', 'visitationLenovoVSCompetitor']
      if (this.isCapFilter) componentToLoad.push('visitationScoreGradeByTopDealerMultiple')

      componentToLoad.forEach(ref => {
        ref += this.activeTab
        const refComponentList = this.$refs[ref]

        if (refComponentList) {
          refComponentList.forEach(comp => {
            const idYPosition = comp.$el.getBoundingClientRect().top;
            let ref2 = ref
            if (idYPosition == 0) return // Fix initial loading when all position is 0
  
            if (refComponentList.length > 1) {
              ref2 += ''+comp.countryIdSelected
            }
            
            const isLoaded = this.loadedComponents.findIndex(el => el == ref2) >= 0
            if (!isLoaded && idYPosition - tolerance < 0) {
              comp.getData()
              this.loadedComponents.push(ref2)
            }
          })
        }
      })
    }
  },
  async mounted() {
    this.getFilters();
    if (this.isCapRole) await this.getCountries()
    this.assignCountryFilterForRole()

    // Lazy Loading components by scrolling
    this.handleDebouncedScroll = debounce(this.onScroll, 100);
    document.getElementsByClassName('main-container')[0].addEventListener('scroll', this.handleDebouncedScroll)
    this.$nextTick(() => {
      this.getDashboardData(); // needed for initial loading on page
    })
  },
  beforeDestroy() {
    document.getElementsByClassName('main-container')[0]
      .removeEventListener('scroll', this.handleDebouncedScroll)
  }
};
</script>
<style lang="scss">
#merchandiser-dashboard {
  .multiselect__option--selected:after {
    display: none;
  }
  .multiselect__input,
  .multiselect__single {
    background: inherit;
  }
  .multiselect {
    min-width: 7em;
  }
  .chart-box {
    height: 55vh;
    .stacked-bar-chart {
      height: 30vh
    }
  }
  .top-dealer-header {
    line-height: 5rem;
  }
  .pointer {
    cursor: pointer;
  }
  .clear-fix {
    overflow: auto;
  }
}
</style>
