<template>
  <div id="sku-products-upload" class="row">
    <div class="col-md-12 container">
      <div class="input-group mb-3">
        <SpringSpinner
          v-if="isLoading"
          class="m-auto spin-loader"
          :size="100"
          color="#f70301"
        />

        <template v-else>
          <div class="vue-xlsx-container btn-lg">
            <button type="button" class="xlsx-button" @click="$refs.file.click()">
              Select File 
            <small>** Only .xlsx files are allowed.</small>
            </button>
          </div>
          <input
            id="file"
            ref="file"
            v-validate="'required'"
            type="file"
            name="file"
            accept=".xlsx, .xls"
            class="d-none"
            @change="handleFileUpload($event)"
          />
        </template>
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <a class="link lnv-link" @click="exportExcelTemplate">
        <i class="fa fa-download"></i>
        <span class="ml-2">Download Excel Template File</span>
      </a>
      <br />
      <br />

      <p class="black-text">
        Your EXCEL data file should contain all the columns specific to the operation you are performing.
        The sample EXCEL file has most of the columns required for the operation. Do not edit the colunm labels in the sample file and make sure the file extension is .xlsx file.
      </p>
    </div>

    <div class="col-lg-12 mb-5">
      <div v-if="isValid && !errorFilePath" class="p-3 alert-success">
        Validation completed, please click submit to continue
      </div>
      <div v-else-if="errorFilePath" class="alert-danger p-4">
        We found that file you uploaded contains error(s). Please re-upload the file (for you convenience we highlighted errors in red)
        <a :href="errorFilePath" target="_blank" class="btn btn-success float-right download-btn">Download</a>
      </div>
    </div>

    <div class="col-lg-12">
      <button 
        v-if="isValid && !errorFilePath" 
        type="submit" 
        :disabled="isLoading"
        class="btn btn-secondary pull-right" 
        @click="handleSubmit($event)"
      >
        Submit
      </button>
      <button type="button" class="btn btn-light pull-right" @click="closeModal()">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { SpringSpinner } from "epic-spinners";
import { export_json_to_excel } from "@/utils/Export2Excel";
import { PRODUCT_SKU_LIST_UPLOAD_FILE_CHECK_REQUEST, PRODUCT_SKU_LIST_UPLOAD_REQUEST} from "../../store/modules/product/actions";

export default {
  name: "SkuProductUpload",
  components: {
    SpringSpinner,
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isValid: false,
      errorFilePath: null,
      base64: null,
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        let postData = {}
        this.getBase64(file)
        .then(data => {
          postData.base64 = data
          this.base64 = data
        })
        .finally(() => {
          this.checkFile(postData)
        })
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    checkFile(postData) {
      // reset
      this.errorFilePath = null
      this.isValid = false
      this.isLoading = true

      this.$store.dispatch(
        PRODUCT_SKU_LIST_UPLOAD_FILE_CHECK_REQUEST, 
        postData
      )
      .then(res => {
        if (res.resultCode !== 0) return

        this.errorFilePath = res.content.errorFilePath
        if (!this.errorFilePath) {
          this.isValid = true
        }
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    exportExcelTemplate() {
      export_json_to_excel({
        header: ["Country", "MTM", "SRP"],
        data: [],
        filename: "upload-sku-products-template",
        sheetName: "Sheet 1",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    handleSubmit() {
      if (!this.isValid || this.isLoading) return

      this.isLoading = true
      let postData = {};
      postData.base64 = this.base64;

      this.$store.dispatch(PRODUCT_SKU_LIST_UPLOAD_REQUEST, postData).then(res => {
        if (res.resultCode !== 0) return

        this.closeModal(true);
        Message({
          message: "Upload completed",
          type: "success",
          duration: 5 * 1000
        });
      })
      .finally(() => {
        this.isLoading = false
      })
    },

  },
  created() {},
  mounted() {
  }
};
</script>

<style lang="scss">
#sku-products-upload {
  .container {
    height: 120px;
    max-width: none;
  }
  .spin-loader {
    margin: 1rem 0;
  }
  .vue-xlsx-container {
    width: 100% !important;
  }
  .xlsx-button {
    width: 100%;
    height: 130px;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #ccc !important;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    padding: 7px 9px;
    font-size: 15px;
    border-radius: 4px;
    color: #949090 !important;
    background-color: #f9f2f2 !important;
  }
  .link {
    cursor: pointer;
  }
}
</style>
