//Actual SO by Sub Region
export const ACTUAL_SO_BY_SUB_REGION_GET = "ACTUAL_SO_BY_SUB_REGION_GET";
export const ACTUAL_SO_BY_SUB_REGION_GET_SUCCESS = "ACTUAL_SO_BY_SUB_REGION_GET_SUCCESS";
export const ACTUAL_SO_BY_SUB_REGION_GET_ERROR = "ACTUAL_SO_BY_SUB_REGION_GET_ERROR";
export const EXPORT_ACTUAL_SO_BY_SUB_REGION = "EXPORT_ACTUAL_SO_BY_SUB_REGION";
export const EXPORT_ACTUAL_SO_BY_SUB_REGION_SUCCESS = "EXPORT_ACTUAL_SO_BY_SUB_REGION_SUCCESS";
export const EXPORT_ACTUAL_SO_BY_SUB_REGION_ERROR = "EXPORT_ACTUAL_SO_BY_SUB_REGION_ERROR";

//SO Heat Map
export const SO_HEAT_MAP_GET = "SO_HEAT_MAP_GET";
export const SO_HEAT_MAP_GET_SUCCESS = "SO_HEAT_MAP_GET_SUCCESS";
export const SO_HEAT_MAP_GET_ERROR = "SO_HEAT_MAP_GET_ERROR";

//Promoter SO performance
export const PROMOTER_SO_PERFORMANCE_GET = "PROMOTER_SO_PERFORMANCE_GET";
export const PROMOTER_SO_PERFORMANCE_GET_SUCCESS = "PROMOTER_SO_PERFORMANCE_GET_SUCCESS";
export const PROMOTER_SO_PERFORMANCE_GET_ERROR = "PROMOTER_SO_PERFORMANCE_GET_ERROR";

export const EXPORT_PROMOTER_SO_PERFORMANCE = "EXPORT_PROMOTER_SO_PERFORMANCE";
export const EXPORT_PROMOTER_SO_PERFORMANCE_SUCCESS = "EXPORT_PROMOTER_SO_PERFORMANCE_SUCCESS";
export const EXPORT_PROMOTER_SO_PERFORMANCE_ERROR = "EXPORT_PROMOTER_SO_PERFORMANCE_ERROR";

//SO Performance by Promoter/Salesperson/Dealer vs Target
export const SO_PERFORMANCE_VS_TARGET_GET = "SO_PERFORMANCE_VS_TARGET_GET";
export const SO_PERFORMANCE_VS_TARGET_GET_SUCCESS = "SO_PERFORMANCE_VS_TARGET_GET_SUCCESS";
export const SO_PERFORMANCE_VS_TARGET_GET_ERROR = "SO_PERFORMANCE_VS_TARGET_GET_ERROR";

export const EXPORT_SO_PERFORMANCE_VS_TARGET = "EXPORT_SO_PERFORMANCE_VS_TARGET";
export const EXPORT_SO_PERFORMANCE_VS_TARGET_SUCCESS = "EXPORT_SO_PERFORMANCE_VS_TARGET_SUCCESS";
export const EXPORT_SO_PERFORMANCE_VS_TARGET_ERROR = "EXPORT_SO_PERFORMANCE_VS_TARGET_ERROR";

//Product Performance by Category
export const PRODUCT_PERFORMANCE_BY_CATEGORY_GET = "PRODUCT_PERFORMANCE_BY_CATEGORY_GET";
export const PRODUCT_PERFORMANCE_BY_CATEGORY_GET_SUCCESS = "PRODUCT_PERFORMANCE_BY_CATEGORY_GET_SUCCESS";
export const PRODUCT_PERFORMANCE_BY_CATEGORY_GET_ERROR = "PRODUCT_PERFORMANCE_BY_CATEGORY_GET_ERROR";

export const EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY = "EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY";
export const EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_SUCCESS = "EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_SUCCESS";
export const EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_ERROR = "EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_ERROR";

//Product Performance by Component
export const PRODUCT_PERFORMANCE_BY_COMPONENT_GET = "PRODUCT_PERFORMANCE_BY_COMPONENT_GET";
export const PRODUCT_PERFORMANCE_BY_COMPONENT_GET_SUCCESS = "PRODUCT_PERFORMANCE_BY_COMPONENT_GET_SUCCESS";
export const PRODUCT_PERFORMANCE_BY_COMPONENT_GET_ERROR = "PRODUCT_PERFORMANCE_BY_COMPONENT_GET_ERROR";

export const EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT = "EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT";
export const EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_SUCCESS = "EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_SUCCESS";
export const EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_ERROR = "EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_ERROR";

//Promoter Dealer Performance
export const PROMOTER_DEALER_PERFORMANCE_GET = "PROMOTER_DEALER_PERFORMANCE_GET";
export const PROMOTER_DEALER_PERFORMANCE_GET_SUCCESS = "PROMOTER_DEALER_PERFORMANCE_GET_SUCCESS";
export const PROMOTER_DEALER_PERFORMANCE_GET_ERROR = "PROMOTER_DEALER_PERFORMANCE_GET_ERROR";

export const EXPORT_PROMOTER_DEALER_PERFORMANCE = "EXPORT_PROMOTER_DEALER_PERFORMANCE";
export const EXPORT_PROMOTER_DEALER_PERFORMANCE_SUCCESS = "EXPORT_PROMOTER_DEALER_PERFORMANCE_SUCCESS";
export const EXPORT_PROMOTER_DEALER_PERFORMANCE_ERROR = "EXPORT_PROMOTER_DEALER_PERFORMANCE_ERROR";

//Promoter Outlet Performance
export const PROMOTER_OUTLET_PERFORMANCE_GET = "PROMOTER_OUTLET_PERFORMANCE_GET";
export const PROMOTER_OUTLET_PERFORMANCE_GET_SUCCESS = "PROMOTER_OUTLET_PERFORMANCE_GET_SUCCESS";
export const PROMOTER_OUTLET_PERFORMANCE_GET_ERROR = "PROMOTER_OUTLET_PERFORMANCE_GET_ERROR";

export const EXPORT_PROMOTER_OUTLET_PERFORMANCE = "EXPORT_PROMOTER_OUTLET_PERFORMANCE";
export const EXPORT_PROMOTER_OUTLET_PERFORMANCE_SUCCESS = "EXPORT_PROMOTER_OUTLET_PERFORMANCE_SUCCESS";
export const EXPORT_PROMOTER_OUTLET_PERFORMANCE_ERROR = "EXPORT_PROMOTER_OUTLET_PERFORMANCE_ERROR";

//Promoter Performance
export const PROMOTER_PERFORMANCE_GET = "PROMOTER_PERFORMANCE_GET";
export const PROMOTER_PERFORMANCE_GET_SUCCESS = "PROMOTER_PERFORMANCE_GET_SUCCESS";
export const PROMOTER_PERFORMANCE_GET_ERROR = "PROMOTER_PERFORMANCE_GET_ERROR";

export const EXPORT_PROMOTER_PERFORMANCE = "EXPORT_PROMOTER_PERFORMANCE";
export const EXPORT_PROMOTER_PERFORMANCE_SUCCESS = "EXPORT_PROMOTER_PERFORMANCE_SUCCESS";
export const EXPORT_PROMOTER_PERFORMANCE_ERROR = "EXPORT_PROMOTER_PERFORMANCE_ERROR";

//Top Dealers
export const TOP_DEALERS_GET = "TOP_DEALERS_GET";
export const TOP_DEALERS_GET_SUCCESS = "TOP_DEALERS_GET_SUCCESS";
export const TOP_DEALERS_GET_ERROR = "TOP_DEALERS_GET_ERROR";

export const EXPORT_TOP_DEALERS_WITH_FILTERS = "EXPORT_TOP_DEALERS_WITH_FILTERS";
export const EXPORT_TOP_DEALERS_WITH_FILTERS_SUCCESS = "EXPORT_TOP_DEALERS_WITH_FILTERS_SUCCESS";
export const EXPORT_TOP_DEALERS_WITH_FILTERS_ERROR = "EXPORT_TOP_DEALERS_WITH_FILTERS_ERROR";

//SO Performance
export const SO_PERFORMANCE_GET = "SO_PERFORMANCE_GET";
export const SO_PERFORMANCE_GET_SUCCESS = "SO_PERFORMANCE_GET_SUCCESS";
export const SO_PERFORMANCE_GET_ERROR = "SO_PERFORMANCE_GET_ERROR";

//Salesperson Performance
export const SALESPERSON_PERFORMANCE_GET = "SALESPERSON_PERFORMANCE_GET";
export const SALESPERSON_PERFORMANCE_GET_SUCCESS = "SALESPERSON_PERFORMANCE_GET_SUCCESS";
export const SALESPERSON_PERFORMANCE_GET_ERROR = "SALESPERSON_PERFORMANCE_GET_ERROR";

//Product Performance
export const PRODUCT_PERFORMANCE_GET = "PRODUCT_PERFORMANCE_GET";
export const PRODUCT_PERFORMANCE_GET_SUCCESS = "PRODUCT_PERFORMANCE_GET_SUCCESS";
export const PRODUCT_PERFORMANCE_GET_ERROR = "PRODUCT_PERFORMANCE_GET_ERROR";

//Outlet Performance
export const OUTLET_PERFORMANCE_GET = "OUTLET_PERFORMANCE_GET";
export const OUTLET_PERFORMANCE_GET_SUCCESS = "OUTLET_PERFORMANCE_GET_SUCCESS";
export const OUTLET_PERFORMANCE_GET_ERROR = "OUTLET_PERFORMANCE_GET_ERROR";

// Merchandiser Dashboard
export const DASHBOARD_MERCHANDISER_VISITATION_GET = "DASHBOARD_MERCHANDISER_VISITATION_GET";
export const DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS = "DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_VISITATION_GET_ERROR = "DASHBOARD_MERCHANDISER_VISITATION_GET_ERROR";

export const DASHBOARD_MERCHANDISER_SCORE_GRADE_GET = "DASHBOARD_MERCHANDISER_SCORE_GRADE_GET";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS = "DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_ERROR = "DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_ERROR";

export const DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET = "DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET";
export const DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_SUCCESS = "DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_ERROR = "DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_ERROR";

export const DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET = "DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET";
export const DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS = "DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_ERROR = "DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_ERROR";

export const DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET = "DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS = "DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_ERROR = "DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_ERROR";

export const DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET = "DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS = "DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_ERROR = "DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_ERROR";

export const DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET = "DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET";
export const DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_SUCCESS = "DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_SUCCESS";
export const DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_ERROR = "DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_ERROR";