import { export_json_to_excel } from "@/utils/Export2Excel";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "@/store/modules/ui/actions";
import XLSX from "xlsx";

const excelColumnTitle = "Model Name"

export const fileMixin = {
  data() {
    return {
      tHeader: [excelColumnTitle]
    }
  },
  methods:{
    doExport() {
      const data = "";
      const fileName = "model name template";
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: fileName,
        sheetName: fileName,
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    checkFileData(sheet) {
      let valid = true;
      for(var i = 0; i < sheet.length; i++) {
        if (!(sheet[i][excelColumnTitle])) {
          valid = false;
          break;
        } 
      }
      return valid;
    },
    dataAdjustmentsAfterUpload(sheet) {
      let formattedArrayParams = sheet.map(item => {
        if(item[excelColumnTitle]) return item[excelColumnTitle]
      });
      return formattedArrayParams;
    }, 
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = (error) => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    handleFileUpload(event) {
      // Clear list before upload
      this.selectedModelName = [];

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Validating file..."
      });
      const file = event.target.files[0];

      this.getBase64(file).then(data => {
          var workbook = XLSX.read(data, { type: "base64" });
          var first_sheet_name = workbook.SheetNames[0];

          // Get worksheet
          let worksheet = workbook.Sheets[first_sheet_name];
          const sheet = XLSX.utils.sheet_to_json(worksheet, { raw: true });

          //Validating file upload columns
          if (sheet.length > 0) {
            if(this.checkFileData(sheet)){

              this.selectedModelName = this.dataAdjustmentsAfterUpload(sheet);         
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "File validation completed successfully",
                timeOut: true
              });

            } else {
              this.$store.dispatch(NOTIFICATION_ERROR, {
                message:
                  "Data is incorrect."
              });
            }
          }
          
        });
    },
    resetFileUploader() {
      this.$refs.fileUploader.reset();
      this.selectedModelName = [];
    },
  }
}