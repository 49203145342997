<template>
  <div class="row">
    <div class="col-lg-6 d-flex" style="flex-direction: column">
      <label>Min price</label>
      <input v-model="min" class="form-control" @input="updateValue(selectedRow, 'minPrice', $event)" />
    </div>
    <div class="col-lg-6 d-flex" style="flex-direction: column">
      <label>Max price</label>
      <input v-model="max" class="form-control" @input="updateValue(selectedRow, 'maxPrice', $event)" />
    </div>
    <div class="col-lg-12 d-flex justify-content-end mt-4">
      <button type="btn" class="input-group-text btn-success btn-reload btn" @click="toggleModelModal">Filter</button>
    </div>
    <div class="col-lg-12 mb-0 mt-3 d-inline-flex">
      <VueBootstrap4Table
        :classes="classes"
        :rows="modelList"
        :columns="columns"
        :config="config"
        :total-rows="total"
        :selected_items="modelsSelected"
        :show-loader="true"
        @on-change-query="onChangeQuery"
        @refresh-data="submitItems"
        @on-select-row="modelsInput"
        @on-all-select-rows="modelsInput"
        @on-unselect-row="modelsInput"
        @on-all-unselect-rows="modelsInput"
      >
        <template slot="sort-asc-icon">
          <i class="mdi mdi-sort-ascending"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="mdi mdi-sort-descending"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="mdi mdi-sort-variant"></i>
        </template>
        <template slot="empty-results">
          <div class="bold-text">Please Filter Model</div>
        </template>
      </VueBootstrap4Table>
    </div>
    <div class="col-lg-12 d-flex justify-content-end mt-4">
      <button
        class="btn btn-secondary pull-right mr-3"
        @click="submitItems"
        v-text="$ml.get('button_submit_text')"
      ></button>
    </div>
    <BModal
      ref="model-modal"
      size="lg"
      hide-footer
      title="Model"
    >
      <div class="col-lg-12">
        <label class="text-label" v-text="$ml.get('incentive_add_form_product_category')"></label>
        <Multiselect
          ref="productCategory"
          v-model="productCategory"
          :searchable="false"
          :options="productCategoryList"
          label="productCategory"
          track-by="productCategory"
          :multiple="true"
          :loading="!productCategoryList"
          :allow-empty="true"
          :show-labels="false"
        ></Multiselect>
      </div>
      <div class="col-lg-12 d-flex justify-content-end mt-4">
        <button
          class="btn btn-secondary pull-right mr-3"
          @click="applyFilter"
          v-text="$ml.get('button_apply_text')"
        ></button>
      </div>
    </BModal>
  </div>
</template>
<script>
import VueBootstrap4Table from "../ui/VueBootstrap4Table";
import { BModal } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { INCENTIVE_PRODUCT_CATEGORY_BY_PRICE } from "../../store/modules/incentive/action";

export default {
  name: "SelectModelModal",
  components: {
    VueBootstrap4Table,
    BModal,
    Multiselect,
  },
  props: {
    countryId: {type: Number, default: 0},
    selectedRow: {type: Number, default: null},
    productGroupList: {type: Array, default: () => []},
    submit: {type: Function, default: () => {}},
    objectiveList: {type: Array, default: () => []},
    changeValue: {type: Function, default: () => {}}
  },
  data(){
    return{
      config: {
        checkbox_rows: true,
        rows_selectable: true,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      columns: [
        {
          name: "id",
          label: "Id",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          visibility: false
        },
        {
          name: "model",
          label: "Model",
          uniqueId: true,
          width: "70%",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }
      ],
      total: 0,
      modelList: [],
      productCategory: [],
      productCategoryList: [],
      modelsSelected: [],
      categoryArray: [],
      min: "",
      max: "",
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.submitItems();
    },
    async modelsInput(models) {
      const localModels = await models.selected_items;
      this.modelsSelected = [];
      // Filtering out deactivated dealers
      this.modelsSelected = [
        ...localModels
      ];
    },
    toggleModelModal(){
      this.$refs["model-modal"].toggle();
    },
    getProductCategoryList(){
      this.productCategoryList = this.productGroupList.filter(el => el.value !== 0).map(element => element.productCategoryList).flat();
    },
    applyFilter(){
      let modelNames = this.productCategory.map(element => element.productModelList.map(el => el.model)).flat().filter(el => this.objectiveList.map((el, index) => {
        if(index + 1 !== this.selectedRow){
          return el.mtm
        }})
        .flat().includes(el))
      this.modelList = this.productCategory.map(el => el.productModelList).flat().filter(el => !modelNames.includes(el.model));
      this.modelsSelected = this.modelsSelected.filter(el => this.modelList.includes(el));
      this.$refs["model-modal"].toggle();
    },
    submitItems(){
      this.submit(this.modelsSelected);
    },
    updateValue(id, field, event){
      this.changeValue(id, field, event);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getModelList(){
      // this.isDataLoading = true;
      let pdata = {};
      let selectedObject = this.objectiveList.find((el, index) => index + 1 === this.selectedRow);
      pdata.countryId = this.countryId;
      pdata.modelList = selectedObject.productFamily;
      this.$store.dispatch(INCENTIVE_PRODUCT_CATEGORY_BY_PRICE, pdata).then(res => {
        if(res.content && res.resultCode === 0){
          this.categoryArray = res.content.map(el => el.productCategory).filter(this.onlyUnique);
          this.productCategory = this.productCategoryList.filter(
            element => this.categoryArray.includes(element.productCategory));
          this.getProductCategoryList();
        }
      }).then(() => {
        this.modelList = this.productCategoryList.filter(element => this.categoryArray.includes(element.productCategory)).map(el => el.productModelList).flat();
        this.modelsSelected = this.modelList.filter(el => selectedObject.productFamily.includes(el.model));
      })
    }
  },
  mounted(){
    this.min = this.objectiveList.find((el, index) => index + 1 === this.selectedRow).minPrice;
    this.max = this.objectiveList.find((el, index) => index + 1 === this.selectedRow).maxPrice;
    if(this.objectiveList.find((el, index) => index + 1 === this.selectedRow).productFamily.length > 0){
      this.getModelList();
    }
    this.getProductCategoryList();
  }
}
</script>