<template>
  <div>
    <p
      v-if="action === 'edit' && detail.pendingApprovalStatus"
      class="required-title"
    >
      Note: Merchandiser has some information pending for approval. Refer "Pending Approval" menu for details.
    </p>
    <p
      v-if="action === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    />

    <div v-if="isLoading" style="height: 500px"><div class="loading loading-big"></div></div>

    <form v-else @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Full Name as per ID</label>
            <span class="required-star">*</span>
            <input
              id="name"
              v-model="detail.name"
              name="name"
              :disabled="action=='view'"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.name = ''"
            />
            <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Country</label>
            <span class="required-star">*</span>
            <Multiselect
              v-model="selectedCountry"
              placeholder="Select country"
              :searchable="true"
              :disabled="action!='add' || isCountryAdmin"
              :options="countryOptions"
              label="text"
              track-by="id"
              select-label
              deselect-label
            />
            <span v-if="formErrors.createdCountryId" class="invalid-field">{{ formErrors.createdCountryId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Gender</label>
            <span class="required-star">*</span>
            <Multiselect
              v-model="selectedGender"
              placeholder="Select Gender"
              :searchable="true"
              :disabled="action=='view'"
              :options="genderOptions"
              label="text"
              track-by="id"
              select-label
              deselect-label
            />
            <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Contact Number</label>
            <span class="required-star">*</span>
            <input
              v-model="detail.phoneNumber"
              name="phoneNumber"
              :disabled="action=='view'"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.phoneNumber = ''"
            />
            <span v-if="formErrors.phoneNumber" class="invalid-field">{{
              formErrors.phoneNumber
            }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Email Address</label>
            <span class="required-star">*</span>
            <input
              v-model="detail.email"
              name="email"
              :disabled="action=='view'"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.email = ''"
            />
            <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
          </div>
        </div>
        <div v-if="!isMercLeader" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Merchandiser Leader's Email Address</label>
            <span class="required-star">*</span>
            <Multiselect
              v-model="selectedLeader"
              placeholder="Select Leader"
              :searchable="true"
              :disabled="action=='view'"
              :options="leaderOptions"
              label="text"
              track-by="id"
              select-label
              deselect-label
            />
            <span v-if="formErrors.merchandiserLeaderId" class="invalid-field">{{ formErrors.merchandiserLeaderId }}</span>
          </div>
        </div>
        <div v-if="detail.createdCountryId != null" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Sub Region</label>
            <span class="required-star">*</span>
            <Multiselect
              v-model="selectedSubRegion"
              placeholder="Select Sub Region"
              :searchable="true"
              :disabled="action=='view'"
              :options="filteredSubRegionOptions"
              label="text"
              track-by="id"
              select-label
              deselect-label
            />
            <span v-if="formErrors.subRegionId" class="invalid-field">{{ formErrors.subRegionId }}</span>
          </div>
        </div>
        <div v-if="detail.createdCountryId != null" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Region</label>
            <span class="required-star">*</span>
            <Multiselect
              v-model="selectedRegion"
              placeholder="Select sub region to autopopulate this field"
              :options="filteredRegionOptions"
              disabled
              label="text"
              track-by="id"
            />
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-lg-12">
              <button 
                v-if="action!='view'" type="submit" 
                :disabled="action=='edit' && detail.pendingApprovalStatus && !(isCap || isCapAdmin)"
                class="btn btn-secondary pull-right"
              >
                <span v-if="action=='add'">Add Merchandiser</span>
                <span v-if="action=='edit'">Update Merchandiser</span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="close()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import { emailValidate, phoneValidate , defaultFiscalYear, defaultQuarter} from "@/utils/helpers";

import { POST_STAFF_REQUEST, GET_MERCHANDISER_LEADER_LIST_REQUEST, GET_MERCHANDISER_PENDING_APPROVAL_FLAG, GET_MERCHANDISER_LIST_REQUEST } from "@/store/modules/staff/actions";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "@/store/modules/ui/actions";
import { COUNTRY_LIST_REQUEST, GENDER_LIST_REQUEST, GET_ALL_REGION_AND_SUBREGION_REQUEST } from "@/store/modules/utility/actions";

import Multiselect from "vue-multiselect";

export default {
  name: "MerchandiserForm",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    reloadList: { type: Function, default: () => {} },
    detailPassedIn: { type: Object, default: () => null },
    action: { type: String, default: "" },
  },
  computed: {
    selectedGender: {
      get() { return this.genderOptions.find(el => el.id == this.detail.genderId) },
      set(obj) { this.detail.genderId = obj.id }
    },
    selectedCountry: {
      get() { return this.countryOptions.find(el => el.id == this.detail.createdCountryId) },
      set(obj) { 
        this.detail.createdCountryId = obj.id
        if (this.filteredSubRegionOptions.length > 0) this.selectedSubRegion = this.filteredSubRegionOptions[0]
      }
    },
    selectedSubRegion: {
      get() { return this.filteredSubRegionOptions.find(el => el.id == this.detail.subRegionId) },
      set(obj) { this.detail.subRegionId = obj.id }
    },
    selectedRegion: {
      get() { return this.filteredRegionOptions.find(el => el.id == this.detail.subRegionId) }
    },
    selectedLeader: {
      get() { return this.leaderOptions.find(el => el.id == this.detail.merchandiserLeaderId) },
      set(obj) { this.detail.merchandiserLeaderId = obj.id }
    },

    filteredSubRegionOptions() {
      return this.allSubRegionOptions.filter(el => el.countryId == this.detail.createdCountryId)
    },
    filteredRegionOptions() {
      return this.allRegionOptions.filter(el => el.countryId == this.detail.createdCountryId)
    }
  },
  data() {
    return {
      isLoading: false,

      detail: {
        name: null,
        createdCountryId: null,
        genderId: null,
        phoneNumber: "",
        email: "",
        merchandiserLeaderId: null,
        subRegionId: null,
      },
      isCap: this.$store.getters.isCap,
      isCapAdmin: this.$store.getters.isCapAdmin,
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isMercLeader: this.$store.getters.isMercLeader,

      formErrors: [],

      countryOptions: [],
      allSubRegionOptions: [],
      allRegionOptions: [],
      genderOptions: [],
      leaderOptions: [],
    };
  },
  methods: {
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.reloadList();
    },
    checkFormValidity() {
      this.formErrors = {}
      let haveError = false

      if (this.detail.name == null || this.detail.name == "") {
        this.formErrors.name = "Name is required."
        haveError = true
      }
      if (this.detail.createdCountryId == null) {
        this.formErrors.createdCountryId = "Country is required."
        haveError = true
      }
      if (this.detail.email == null) {
        this.formErrors.email = "Email address is required."
        haveError = true
      } else {
        if (!emailValidate(this.detail.email)) {
          this.formErrors.email = "Invalid email address."
          haveError = true
        }
      }

      if (this.detail.phoneNumber == null) {
        this.formErrors.phoneNumber = "Contact number is required."
        haveError = true
      } else {
        if (!phoneValidate(this.detail.phoneNumber)) {
          this.formErrors.phoneNumber = this.$ml.get("invalid_phone_number")
          haveError = true
        }
      }

      if (this.detail.genderId == null) {
        this.formErrors.genderId = "Gender is required."
        haveError = true
      }
      if (this.isMercLeader == false) {
        if (this.detail.merchandiserLeaderId == null) {
          this.formErrors.merchandiserLeaderId = "Merchandiser leader is required."
          haveError = true
        }
      }
      if (this.detail.subRegionId == null) {
        this.formErrors.subRegionId = "Region is required."
        haveError = true
      }

      return haveError == false
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      } else {
        this.isLoading = true
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_submitting")
        });

        let data = cloneDeep(this.detail)
        if (this.action == 'add') {
          data = [data]
        }

        let postData = {
          type: "merchandiser",
          staffId: this.detail.id,
          data
        }
        this.$store.dispatch(POST_STAFF_REQUEST, postData).then(res => {
          if (res.resultCode === 0) {
            this.success();
            if (this.detail.id) {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "Merchandiser Successfully Updated."
              });
            } else {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: "Merchandiser Successfully Added."
              });
            }
          }
        })
        .finally(() => this.isLoading = false)
      }
    },
    async getCountries() {
      await this.$store.dispatch(COUNTRY_LIST_REQUEST, { withRegion: true })
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = cloneDeep(res.content)
        }
      })
    },
    async getRegionsAndSubRegions() {
      await this.$store.dispatch(GET_ALL_REGION_AND_SUBREGION_REQUEST)
      .then(res => {
        if (res.resultCode === 0) {
          this.allSubRegionOptions = cloneDeep(res.content).map(el => {
            el.text = el.subRegion
            return el
          })
          this.allRegionOptions = cloneDeep(res.content).map(el => {
            el.text = el.region
            return el
          })
        }
      })
    },
    async getGenders() {
      await this.$store.dispatch(GENDER_LIST_REQUEST)
      .then(res => {
        if (res.content && res.resultCode === 0) {
          this.genderOptions = cloneDeep(res.content)
        }
      });
    },
    async getLeaders() {
      const postData = {
        fyf: defaultFiscalYear(),
        qf: defaultQuarter()
      }
      await this.$store.dispatch(GET_MERCHANDISER_LEADER_LIST_REQUEST, postData)
      .then(res => {
        if (res.content && res.resultCode === 0) {
          this.leaderOptions = cloneDeep(res.content).map(el => {
            el.text = el.email
            return el
          })
        }
      })
    },
    applyFilterForRole() {
      if (!(this.isCap || this.isCapAdmin)) {
        this.detail.createdCountryId = this.$store.getters.getCountryId
      }
      if (this.isMercLeader) {
        this.detail.merchandiserLeaderId = null // BE will take his own id
      }
    },
    getDetail() {
      this.isLoading = true
      let pdata = {};
      pdata.pif = this.detailPassedIn.id;
      this.$store.dispatch(GET_MERCHANDISER_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.detail = cloneDeep(res.content[0])
        }
      }).finally(()=>{this.isLoading = false});
    },
    async checkForPendingApprovalFlag() {
      await this.$store.dispatch(GET_MERCHANDISER_PENDING_APPROVAL_FLAG, this.detail.id).then(res => {
        if (res.resultCode === 0) {
          this.detail.pendingApprovalStatus = res.content;
        }
      });
    }
  },
  async mounted() {
    this.isLoading = true
    if (this.detailPassedIn != null) this.detail = cloneDeep(this.detailPassedIn)

    let promises = [
      this.getCountries(),
      this.getRegionsAndSubRegions(),
      this.getGenders()
    ]
    if (this.isMercLeader == false) promises.push(this.getLeaders())
    if (this.detail.id != null) promises.push(this.checkForPendingApprovalFlag())

    await Promise.all(promises)
    this.isLoading = false


    this.applyFilterForRole()
    if (typeof this.detail.newApprovalRequest !== 'undefined' || this.detail.newApprovalRequest === null) this.getDetail()
    
  }
};
</script>
