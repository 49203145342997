<template>
  <div id="generate-promoter-incentive-payable-file">
    <breadcrumb title="Dashboard" subtitle="Generate Promoter Commission Payable" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class>
              <div class="row">
                <div v-if="isCapAdmin || isCap" class="col-lg-4">
                   <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                      <Multiselect
                          ref="multiselect"
                          v-model="search.countryId"
                          placeholder="Select Country"
                          :searchable="true"
                          :options="countries"
                          label="text"
                          track-by="id"
                          :loading="isCountriesLoading"
                          :disabled="isCountriesLoading"
                          select-label
                          deselect-label
                          @input="
                          onChangeCountry();
                          doSearch();
                        "
                      ></Multiselect>
                </div>

                <div class="col-lg-4">
                  <label class="text-label" v-text="$ml.get('title_year')"></label>
                  <Multiselect
                    ref="multiselect-nyr"
                    v-model="selectedYear"
                    placeholder="Select Year"
                    :searchable="true"
                    :options="yearList"
                    label="text"
                    track-by="id"
                    select-label
                    deselect-label
                    @input="onYearChange"
                  ></Multiselect>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                    :classes="classes"
                    :rows="payableList"
                    :columns="columns"
                    :config="config"
                    :total-rows="total"
                    @on-change-query="onChangeQuery"
                    @refresh-data="getPayableDataList()"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!isDataLoading"
                        class="bold-text"
                        v-text="$ml.get('no_payout_data_found')"
                      ></div>
                      <div
                        v-if="isDataLoading"
                        class="loading mt-3"
                      ></div>
                    </template>

                    <template slot="id" slot-scope="props">
                      <span>{{ props.row.month }}</span>
                    </template>
                    <template slot="month" slot-scope="props">
                      <div class="inner-data">
                        {{ formatDateToMonth(props.row.year + "-" + props.row.month + "-01") }}
                      </div>
                    </template>

                    <template slot="Action" slot-scope="props">
                      <div v-if="!isCountryView" class="inner-buttons btn-group float-right" role="group">
                        <button
                          v-if="props.row.downloadLink !== null"
                          type="button"
                          class="btn btn-info"
                          @click="downloadFile(props.row)"
                        >
                          <i class="fa fa-excel color-info"></i>DOWNLOAD
                        </button>
                        <!-- change v-if="props.row.readyToGenerate" to true for Generate button. Temporary use as requested by Fabian -->
                        <span
                          v-if="props.row.downloadLink !== null"
                          id="date-remarks"
                          class="generate-date"
                          >{{ props.row.remarks }}</span>
                          <!-- v-if="isProduction ? props.row.readyToGenerate : true"-->
                        <button
                          v-if="!props.row.fileStatus"
                          type="button"
                          class="btn btn-warning"
                          @click="generateIncentiveFile(props.row)"
                        >
                          <i class="fa fa-download color-warning"></i>GENERATE
                        </button>
                        <button
                          v-if="!props.row.fileStatus && (isCap || isCapAdmin) && props.row.downloadLink !== null"
                          type="button"
                          class="btn btn-warning"
                          @click="updateIncentiveFileToPaid(props.row)"
                        >
                          PAID
                        </button>
                        <span
                          v-if="props.row.fileStatus !== null"
                          id="date-remarks"
                          class="generate-date"
                          >{{ props.row.fileStatusUpdateRemarks }}</span>
                        <!-- v-if="props.row.bonusIncentiveNotreadyList.length > 0" -->
                        <button
                          v-if="false"
                          type="button"
                          class="btn btn-warning"
                          @click="viewDetails(props.row)"
                        >
                          <i class="fa fa-eye color-warning"></i>VIEW DETAILS
                        </button>
                      </div>
                    </template>
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View -->
    <BModal ref="incentive-view-modal" size="lg" hide-footer title="Details">
      <div class="row">
        <div class="col-lg-12">
          <div
            v-for="(row, index1) of selectedIncentiveRow"
            :key="index1"
            class="form-group flex-grow-1"
          >
            <label class="text-label">Name: {{ row.name }}</label>
            <br />
            <label class="text-label">Type: {{ row.type }}</label>
            <br />
            <label class="text-label">Period: {{ row.period }}</label>
            <br />
            <label class="text-label">
              Generation of payable file is pending for:
              <b v-for="(pendingMonth, index2) of row.pendingMonths" :key="index2">
                <b>{{ formatDateToMonth(pendingMonth.year + "-" + pendingMonth.month + "-01") }}
                  {{ " " }}</b>
              </b>
            </label>
            <br />
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { convertDateFormat, urlValidate } from "../../utils/helpers";
import { Message } from "element-ui";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";

import { BModal } from "bootstrap-vue";
import Multiselect from "vue-multiselect";

import {
  GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST,
  GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST_COUNTRY,
  PROMOTER_INCENTIVE_PAYABLE_GENERATE_REQUEST,
  UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST
} from "../../store/modules/incentive/action";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "../../store/modules/ui/actions";

export default {
  name: "PromoterIncentivePayable",
  components: {
    breadcrumb: breadcrumb,
    VueBootstrap4Table: VueBootstrap4Table,
    BModal: BModal,
    Multiselect: Multiselect
  },
  computed: {
    isProduction() {
      return process.env.NODE_ENV == "production";
    }
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Sales Data",
        card_mode: false,
        show_refresh_button: true,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 12,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default,
        },
        {
          name: "month",
          label: "Month",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "totalSubmission",
          label: "Total Submission",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "validated",
          label: "Validated",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "noValidated",
          label: "Not Validated",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "resubmissionMessage",
          label: "Resubmission Message",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "resubmissionPendingValidation",
          label: "Resubmission Pending for validation",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "Action",
          label: "Action",
          width: "20%"
        }
      ],
      total: 0,
      payableList: [],
      isCapAdmin: false,
      isCap: false,
      isDealer: false,
      isCountryView: false,
      countries: [],
      search: {
        status: {}
      },
      countryId: null,
      yearList: [],
      selectedYear: {
        id: new Date().getFullYear(),
        text: new Date().getFullYear()
      },
      month: new Date().getMonth(),
      selectedIncentiveRow: [],
      isCountriesLoading: false,
      isDataLoading: false
    };
  },
  methods: {
    updateIncentiveFileToPaid(row){
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: this.$ml.get("request_submitting")
      });
      let pdata = {};
      pdata.month = row.month;
      pdata.year = row.year;
      pdata.countryId = this.countryId;
      this.$store.dispatch(UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.$store.dispatch(NOTIFICATION_SUCCESS, {
            message: "Status is updated"
          });
          this.getPayableDataList();
        } else {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: "Something went wrong. Please try again"
          });
        }
      }).catch(err => {
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: err
        });
      });
    },
    downloadFile(row) {
      if (urlValidate(row.downloadLink)) {
        let fileLink = row.downloadLink;
        var win = window.open(fileLink, "_blank");
        win.focus();
      } else {
        return Message({
          message: " Incentive file not found",
          type: "error",
          duration: 5 * 1000
        });
      }
    },
    generateIncentiveFile(row) {
      let d = new Date(row.year + "-" + row.month + "-01");
      let incYear = d.getFullYear();
      let incMonth = d.getMonth() + 1;
      let pData = {
        month: incMonth,
        year: incYear
      };

      this.$store.dispatch(PROMOTER_INCENTIVE_PAYABLE_GENERATE_REQUEST, pData).then(res => {
        if (res.resultCode === 0) {

          Message({
            message: "File created.",
            type: "success",
            duration: 5 * 1000
          });

          this.payableList.filter(function(elem) {
            if (row.month === elem.month) {
              elem.downloadLink = res.content.fileLink;
              elem.readyToGenerate = false;
            }

            return elem;
          });
        } else {
          Message({
            message: "File creation failed..",
            type: "error",
            duration: 5 * 1000
          });
        }
      });
    },
    viewDetails(row) {
      this.selectedIncentiveRow = row.bonusIncentiveNotreadyList;
      this.$refs["incentive-view-modal"].toggle("#toggle-btn");
    },
    onYearChange() {
      this.getPayableDataList();
    },
    onChangeCountry(value) {
      if (value) {
        this.countryId = value.id;
      }
    },
    doSearch() {
      this.countryId = this.search.countryId ? this.search.countryId.id : null;
      this.getPayableDataList();
    },
    updateDateValues() {},
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPayableDataList();
    },
    getPayableDataList() {
      let pData = {
        year: this.selectedYear.id,
      };
      this.payableList = [];
      this.isDataLoading = true;
      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });

      }else if(this.isCapAdmin || this.isCap || this.countryId){
        if (this.countryId) {
          pData.countryId = this.countryId;
        }
        this.$store.dispatch(GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST_COUNTRY, pData).then(res => {
          if (res.content && res.resultCode === 0) {
            if (res.content.length <= 0) {
              Message({
                message: "No Payable Data Found!",
                type: "error",
                duration: 5 * 1000
              });
            }
            this.payableList = res.content;
            this.sorting();
            this.total = this.payableList.length;
            this.isDataLoading = false;
          } else {
            this.isDataLoading = false;
          }
        });
      } else {
        if (this.countryId) {
          pData.countryId = this.countryId;
        }
        this.$store.dispatch(GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST, pData).then(res => {
          if (res.content && res.resultCode === 0) {
            if (res.content.length <= 0) {
              Message({
                message: "No Payable Data Found!",
                type: "error",
                duration: 5 * 1000
              });
            }
            this.payableList = res.content;
            this.sorting();
            this.total = this.payableList.length;
            this.isDataLoading = false;
          } else {
            this.isDataLoading = false;
          }
        });
      }
    },
    getCountries() {
      this.isCountriesLoading = true;
      this.$store
          .dispatch(COUNTRY_LIST_REQUEST, { withRegion: false })
          .then(res => {
            if (res.resultCode === 0) {
              this.countries = res.content;
              this.isCountriesLoading = false;
            } else {
              this.isCountriesLoading = false;
            }
          });
    },
    doDownload() {},
    sorting() {
      this.payableList.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    formatDate: function(date) {
      return convertDateFormat(date, "YYYY-MM-DD");
    },
    formatDateToMonth: function(date) {
      return convertDateFormat(date, "MMMM YYYY");
    },
    getDataRowByName(arr, name) {
      let c = arr.filter(function(elem) {
        if (elem.text.toLowerCase() === name.toLowerCase()) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    setYears() {
      const yearRange = this.selectedYear.id - 3;
      for (let i = 0; i <= 6; i++) {
        let yr = yearRange + i;
        this.yearList.push({ id: yr, text: yr });
      }
      return this.yearList;
    }
  },
  mounted() {
    this.setYears();
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isDealer = this.$store.getters.isDealer;
    this.isCountryView = this.$store.getters.isCountryView;
    if (!this.isCapAdmin && !this.isCap) {
      this.getPayableDataList();
    } else {
      this.getCountries();
    }

  }
};
</script>
<style scoped>
  .inner-buttons{
    display: flex!important;
    flex-direction: column;
    align-items: center;
  }
</style>
