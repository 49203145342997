<template>
  <div v-if="!isOutletPerformanceLoading">
    <div class="mt-4 ml-2 mb-4 mb-4 d-inline-flex justify-content-between w-100">
      <span class="mt-auto mb-auto">
        <strong>{{ outletPerformance.name }}</strong>
      </span>
      <div class="d-inline-flex">
        <div class="mr-2 enlarge-icon" @click="enlargeChart(outletPerformance, 'stackedBarChart')">
          <span> &#10070; </span>
        </div>
      </div>
    </div>

    <stacked-bar-chart
      id="actualoutletPerformanceLowerSection"
      :got-lower-section="true"
      :data.sync="outletPerformance.data"
      lower-section-id="outletPerformanceLowerSection"
      title=""
      chart-left="160"
      :max-characters="18"
      height-size="heightSize"
    ></stacked-bar-chart>
    <div id="outletPerformanceLowerSection"></div>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 450px"><div class="loading loading-big"></div></div>
</template>
<script>
import { OUTLET_PERFORMANCE_GET } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import stackedbarChart from "../dashboard/stacked-bar-chart";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data() {
    return{
      outletPerformance: {},
      isOutletPerformanceLoading: true,
      link: ""
    }
  },
  methods: {
    async getOutletPerformance() {  
      this.isOutletPerformanceLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
      };
      await this.$store.dispatch(OUTLET_PERFORMANCE_GET, pdata).then((res) => {
        if(res){
          this.outletPerformance = res;
        }
        this.isOutletPerformanceLoading = false;
      })
    },
  },
}
</script>