import store from "@/store";
import PassThrough from "@/views/PassThrough";
import PromoterSupervisors from "@/views/staff-management/promoter_leaders.vue";
import PromoterStaff from "@/views/staff-management/promoters.vue";
import SalespersonStaff from "@/views/staff-management/salespersons.vue";
import StateManagers from "@/views/staff-management/state_managers.vue";
import RegionalManagers from "@/views/staff-management/regional_managers.vue";
import MerchandiserStaff from "@/views/staff-management/merchandiser.vue";
import MerchandiserLeaderStaff from "@/views/staff-management/merchandiser_leader.vue";

import {
  ROLE_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_VIEW,
  ROLE_GLN_COUNTRY_ADMIN,
  ROLE_PROMOTER_LEADER,
  ROLE_CAP_ADMIN,
  ROLE_CAP,
  ROLE_DEALER,
  LAYOUT_ADMIN,
  ROLE_OUTLET_PIC,
  ROLE_AGENCY,
  ROLE_COUNTRY_AGENCY,
  ROLE_REGION_MANAGER,
  ROLE_STATE_MANAGER,
  ROLE_MERC_LEADER
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const staffMngmtRouter = {
  path: "/staff-management",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
      ROLE_CAP_ADMIN,
      ROLE_CAP,
      ROLE_COUNTRY_ADMIN,
      ROLE_COUNTRY_VIEW,
      ROLE_GLN_COUNTRY_ADMIN,
      ROLE_PROMOTER_LEADER,
      ROLE_DEALER,
      ROLE_OUTLET_PIC,
      ROLE_AGENCY,
      ROLE_COUNTRY_AGENCY,
      ROLE_REGION_MANAGER,
      ROLE_STATE_MANAGER
    ]
  },
  children: [
    {
      path: "promoter-leaders",
      name: "Promoter Supervisors",
      component: PromoterSupervisors,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "promoter-leaders/:fullname",
      name: "Promoter Supervisors ",
      component: PromoterSupervisors,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "promoters",
      name: "Promoters",
      component: PromoterStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "promoters/:fullname",
      name: "Promoters ",
      component: PromoterStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "salespersons",
      name: "Salespersons",
      component: SalespersonStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "salespersons:fullname",
      name: "Salespersons ",
      component: SalespersonStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "state-manager",
      name: "State Manager",
      component: StateManagers,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "regional-manager",
      name: "Regional Manager",
      component: RegionalManagers,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "merchandiser",
      name: "Merchandiser",
      component: MerchandiserStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_MERC_LEADER
        ]
      }
    },
    {
      path: "merchandiser-leader",
      name: "Merchandiser Leader",
      component: MerchandiserLeaderStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_MERC_LEADER
        ]
      }
    },
  ],
  beforeEnter: ifAuthenticated
};

export default staffMngmtRouter;
