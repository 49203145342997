<template>
  <div v-if="!isGetSOheatMapLoading">
    <div id="geoHeatMapTitle" class="position-absolute d-inline-flex justify-content-between" style="width: 95%">
      <span>
        <strong>{{ soHeatMap.name }}</strong>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <div id="toolTipContainer" class="border position-absolute d-none"></div>
      <div id="geoDataInfoContainer" class="border p-1 overflow-scroll position-absolute">
        <ul class="mb-0 d-flex">
          <li>
            <ul class="mr-2">
              <li><strong>Country</strong></li>
              <li v-for="(data, index) in soHeatMap.dataTable" :key="index">
                <span>{{ data.country }}</span>
              </li>
            </ul>
          </li>
          <li>
            <ul class="mr-2 text-right">
              <li><strong>Actual</strong></li>
              <li v-for="(data, index) in soHeatMap.dataTable" :key="index">
                <span>{{ data.actual }}</span>
              </li>
            </ul>
          </li>
          <li>
            <ul class="mr-2 text-right">
              <li><strong>Target</strong></li>
              <li v-for="(data, index) in soHeatMap.dataTable" :key="index">
                <span>{{ data.target }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-if="isCap" id="geoChartKR" class="border p-1 overflow-scroll position-absolute">
        <div>
          <geo-chart-kr
            :cap.sync="isCap"
            :data.sync="soHeatMap.data"
            :region.sync="sOHeatMapRegion1"
            :color-axis-values.sync="soHeatMap.colorAxisValues"
            :color-axis-colors.sync="soHeatMap.colorAxisColors"
          ></geo-chart-kr>
        </div>
      </div>
      <div id="geoColorInfoContainer" class="border p-1 overflow-scroll position-absolute">
        <ul class="mb-0 d-flex">
          <li>
            <ul class="mr-2">
              <li><strong>Attainment Rate </strong></li>
              <li v-for="(data, index) in soHeatMap.colorTable" :key="index">
                <span>{{ data.attainmentRate }}</span>
              </li>
            </ul>
          </li>
          <li>
            <ul class="mr-2 text-right">
              <li><strong>Color</strong></li>
              <li v-for="(data, index) in soHeatMap.colorTable" :key="index">
                <span class="tag" :style="`height: 1.2em;background: ${data.color}`"></span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <geo-chart
          :cap.sync="isCap"
          :data.sync="soHeatMap.data"
          :region.sync="sOHeatMapRegion"
          :color-axis-values.sync="soHeatMap.colorAxisValues"
          :color-axis-colors.sync="soHeatMap.colorAxisColors"
        ></geo-chart>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>

<script>
import { SO_HEAT_MAP_GET } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import geoChart from "../dashboard/geo-chart";
import geoChartKR from "../dashboard/geo-chart-kr";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "geo-chart": geoChart,
    "geo-chart-kr": geoChartKR
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    grouping: {type: String, default: ""},
  },
  computed: {
    sOHeatMapRegion() {
      return this.soHeatMap.data && (this.soHeatMap.data.length == 2 || this.soHeatMap.data[1][0] === "MM")
        ? this.soHeatMap.data[1][0]
        : "142";
    },
    sOHeatMapRegion1() {
      return this.soHeatMap.data && (this.soHeatMap.data.length == 2 || this.soHeatMap.data[1][0] === "MM")
        ? this.soHeatMap.data[1][0]
        : "KR";
    },   
    isCap() {
      return this.soHeatMap.data && (this.soHeatMap.data.length == 2 || this.soHeatMap.data[1][0] === "MM") ? false : true;
    }
  },
  data(){
    return{
      isGetSOheatMapLoading: false,
      soHeatMap: {},
    }
  },
  watch: {
    filters: function(){
      this.getSOHeatMap();
    }
  },
  methods: {
    async getSOHeatMap() {
      this.isGetSOheatMapLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(SO_HEAT_MAP_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }).then((res) => {
        if(res){
          this.soHeatMap = {...res};
        }
        this.isGetSOheatMapLoading = false;
      })
    },
  }
}
</script>