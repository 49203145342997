<template>
  <div v-if="!isProductPerformanceByCategoryLoading">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <div class="d-flex align-items-center">
        <div class="mr-4">
          <span>
            <strong>{{ productPerformanceByCategory.name }}</strong>
          </span>
        </div>
        <div class="mr-4">
          <Multiselect
            ref="catbcs"
            v-model="productFilters.catbcsSelected"
            :searchable="false"
            :options="catbcsOptions"
            label="name"
            track-by="value"
            :loading="!catbcsOptions"
            :allow-empty="false"
            :show-labels="false"
            @input="getProductPerformanceByCategory"
          ></Multiselect>
        </div>
        <div v-if="grouping === 'quarterly'" class="mr-4">
          <Multiselect
            ref="catws"
            v-model="productFilters.catwsSelected"
            :searchable="false"
            :options="catwsOptions"
            label="name"
            track-by="value"
            :loading="!catwsOptions"
            :allow-empty="false"
            :show-labels="false"
            @input="getProductPerformanceByCategory"
          ></Multiselect>
        </div>
        <!-- <div class="mr-4">
          <Multiselect
            ref="catrs"
            v-model="productFilters.catrsSelected"
            :searchable="false"
            :options="catrsOptions"
            label="name"
            track-by="value"
            :loading="!catrsOptions"
            :allow-empty="false"
            :show-labels="false"
            @input="getProductPerformanceByCategory"
          ></Multiselect>
        </div> -->
        <div class="mr-4">
          <Multiselect
            ref="catdf"
            v-model="productFilters.catdfSelected"
            :searchable="true"
            :options="catdfOptions"
            label="dealerName"
            track-by="id"
            :loading="!catdfOptions"
            :allow-empty="false"
            :show-labels="false"
            @input="getProductPerformanceByCategory"
          ></Multiselect>
        </div>
      </div>
      <div class="position-relative d-inline">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-3"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
        ></a>
        <span 
          class="enlarge-icon mt-auto mb-auto mr-2" 
          @click="enlargeChart(
            productPerformanceByCategory, 
            'productPerformanceChart', 
            {tab: productPerformanceByCategoryActiveLegendTab, prFilters: productFilters}
          )"
        > 
          &#10070;
        </span>
      </div>
    </div>
    <div>
      <stacked-bar-chart
        id="actualproductPerformanceByCategoryLowerSection"
        :got-lower-section="true"
        :is-stacked="true"
        :data.sync="productPerformanceByCategory.data"
        title=""
        :product-filters.sync="productFilters"
        lower-section-id="productPerformanceByCategoryLowerSection"
        chart-left="15%"
        :max-characters="21"
      ></stacked-bar-chart>
      <div id="productPerformanceByCategoryLowerSection"></div>
    </div>
    <div class="mt-4">
      <b-tabs
        v-model="productPerformanceByCategoryActiveLegendTab"
        position="is-centered"
        size="is-small"
      >
        <b-tab-item
          v-for="(legend, index) in productPerformanceByCategory.customLegend"
          :key="index"
          :label="legend.category"
        >
          <b-taglist class="pt-3 pb-5">
            <b-tag
              v-for="(name, index2) in legend.productNames"
              :key="index2"
              size="is-medium"
              type="is-white"
              :style="`background: ${name.color}`"
              class="mr-2"
            >
              {{ name.name }}
            </b-tag>
          </b-taglist>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>

<script>
import stackedbarChart from "../../components/dashboard/stacked-bar-chart";
import Multiselect from "vue-multiselect";
import { 
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET, 
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY 
} from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
    Multiselect,
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    catbcsOptions: {type: Array, default: () => []},
    catwsOptions: {type: Array, default: () => []},
    catrsOptions: {type: Array, default: () => []},
    catdfOptions: {type: Array, default: () => []},
    productPerformanceByCategoryActiveLegendTab: {type: Number, default: 0},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data(){
    return{
      isProductPerformanceByCategoryLoading: true,
      productPerformanceByCategory: {},
      link: "",
      loading: false
    }
  },
  methods: {
    exportChart(event){
      event.preventDefault();
      if(!this.loading){
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_downloading")
        });
        this.loading = true;
        this.$store.dispatch(EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY, {
          grouping: this.grouping,
          filters: this.localFilters,
          // productFilters: this.productFilters
        }).then(res => {
          if (res.content && res.resultCode === 0) {
            this.$refs["export-link"].href = res.content.link;
            this.$refs["export-link"].click();
            this.loading = false;
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: this.$ml.get("request_downloading_success")
            });
          } 
        });
      }
    },
    async getProductPerformanceByCategory() {
      this.isProductPerformanceByCategoryLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(PRODUCT_PERFORMANCE_BY_CATEGORY_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }).then((res) => {
        if(res){
          res["id"] = "productPerformanceByCategoryLowerSection";
          this.productPerformanceByCategory = res;
        }
        this.isProductPerformanceByCategoryLoading = false;
      })
    },
  },
  mounted(){
  }
}
</script>