<template>
  <div>
  <div v-if="!isRendering">
    <p
      v-if="canEdit && currentAction === 'edit' && outlet.pendingApprovalStatus && !selectedOutlet.newApprovalRequest"
      class="required-title"
      v-text="$ml.get('outlet_pending_approval_request')"
    ></p>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="canEdit && currentAction === 'edit'" @submit.prevent="handleSubmit">
      <div class="row">
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_name')"></label>
            <span class="required-star">*</span>
            <div class="d-flex row m-0 p-0">
              <textarea
                v-model="outlet.name"
                :disabled="selectedOutlet.newApprovalRequest || isPendingApprovalPage"
                type="text"
                class="form-control col"
                placeholder
                @keyup="formErrors.name = ''"
              />
              <!-- Change Request -->
              <div v-if="outletAfterChanges.name" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                  v-model="outletAfterChanges.name"
                  disabled
                  type="text"
                  class="form-control after-change"
                />
              </div>
            </div>
            <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
          </div>
        </div>
        <div v-if="outlet.id && !selectedOutlet.newApprovalRequest && !isChangeStatus" class="col-lg-6">
          <label class="text-label" v-text="$ml.get('form_news_status')"></label><br>
          <div class="d-inline-flex pt-3">
            <div class="row d-flex p-0 m-0">
              <td class="mr-4 none-border"><lnvStatusTextBox :status="outlet.outletStatus" /></td>
            </div>
            <button
              v-if="!(isCountryView || isPendingApprovalPage)"
              class="btn btn-outline-dark m-auto"
              @click="doChangeStatus($event, 'outlet')"
            > 
              <i class="fa fa-exchange"></i>
            </button>
            <label v-if="outlet.activationDate !== null || outlet.deactivationDate !== null" class="text-label ml-4 mt-2">
              Since: {{ outlet.outletStatus == 'ACTIVE' ? outlet.activationDate : outlet.deactivationDate }}
            </label>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
            <span class="required-star">*</span>
            <div class="d-flex row m-0 p-0">
              <Multiselect
                  ref="country"
                  v-model="outlet.countryCode"
                  class="col"
                  :searchable="false"
                  :options="countryOptions"
                  :disabled="isPendingApprovalPage || selectedOutlet.newApprovalRequest || isCountryView"
                  label="name"
                  track-by="value"
                  :loading="!countryOptions"
                  :allow-empty="false"
                  :show-labels="false"
                  @input="onChangeCountry"
              ></Multiselect>
            </div>

            <span v-if="errors.first('Country')" class="invalid-field">
              {{ errors.first("Country") }}
            </span>
            <span v-if="formErrors.countryCode" class="invalid-field">{{ formErrors.countryCode }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_state')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex p-0 m-0">
              <Multiselect
                  ref="multiselect-state"
                  v-model="outlet.stateId"
                  class="col"
                  :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                  placeholder="Select State"
                  :searchable="true"
                  :options="stateList"
                  label="text"
                  track-by="id"
                  select-label
                  deselect-label
                  :loading="isStatesLoading"
                  @input="getRegionSubRegion"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.stateId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="multiselect-state-after"
                  v-model="outletAfterChanges.stateId"
                  class="col after-change"
                  disabled
                  :options="stateList"
                  label="text"
                  track-by="id"
                  :loading="isStatesLoading"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.stateId" class="invalid-field">{{ formErrors.stateId }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('sd_title_region')"></label>
            <div class="row d-flex m-0 p-0">
              <Multiselect
                ref="multiselect-state"
                v-model="outlet.region"
                class="col"
                placeholder="Select State"
                :options="[outlet.region]"
                :disabled="true"
                label="name"
                track-by="id"
                select-label
                deselect-label
                :loading="isRegionsLoading"
              ></Multiselect>
            </div>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('sd_title_subregion')"></label>
            <div class="row d-flex m-0 p-0">
              <Multiselect
                ref="multiselect-state-after"
                v-model="outlet.subRegion"
                class="col"
                placeholder="Select State"
                :options="[outlet.subRegion]"
                :disabled="true"
                label="name"
                track-by="id"
                select-label
                deselect-label
                :loading="isRegionsLoading"
              ></Multiselect>
            </div>
          </div>
        </div>
        <div v-if="showDealerField && !isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_dealer_name')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex m-0 p-0">
              <Multiselect
                ref="dealer"
                v-model="outlet.dealerId"
                class="col"
                :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                :searchable="true"
                placeholder="Select Dealer"
                :options="dealerOptions"
                label="dealerName"
                track-by="id"
                :allow-empty="false"
                :show-labels="false"
                :loading="isDealersLoading"
                @input="mdmCode = outlet.dealerId.prmLppName"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.dealerId" class="col-6 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                  <Multiselect
                  ref="dealer-after"
                  v-model="outletAfterChanges.dealerId"
                  class="after-change"
                  disabled
                  :options="dealerOptions"
                  label="dealerName"
                  track-by="id"
                  :loading="isDealersLoading"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.dealerId" class="invalid-field">{{ formErrors.dealerId }}</span>
          </div>
        </div>
        <!-- <div v-if="!isPicOutlet && !isCountryAdmin" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_pic_name')"></label>
            <Multiselect
                ref="multiselect-pic"
                v-model="outlet.outletPicId"
                :disabled="selectedOutlet.newApprovalRequest"
                placeholder="Select PIC"
                :searchable="true"
                :options="picList"
                label="picName"
                track-by="id"
                select-label
                deselect-label
                :loading="isPicLoading"
            ></Multiselect>
            <span v-if="formErrors.outletPicId" class="invalid-field">
              {{ formErrors.outletPicId }}
            </span>
          </div>
        </div> -->
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_mdm_code')"></label>
            <input
                v-model="mdmCode"
                :disabled="true"
                type="text"
                class="form-control"
                placeholder
            />
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_address')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <textarea
                  v-model="outlet.address"
                  :disabled="selectedOutlet.newApprovalRequest || isPendingApprovalPage"
                  type="text"
                  class="col form-control"
                  placeholder
                  @keyup="formErrors.address = ''"
              />
              <!-- Change Request -->
              <div v-if="outletAfterChanges.address" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                  v-model="outletAfterChanges.address"
                  disabled
                  type="text"
                  class="col form-control after-change"
                />
              </div>
              <!--                @change="initGeoCoding()"-->


              <!--              <b-button-->
              <!--                type="button"-->
              <!--                class="btn addressRefreshButton btn-sm"-->
              <!--                @click="initGeoCoding()"-->
              <!--                v-text="$ml.get('form_outlet_update_map')"-->
              <!--              ></b-button>-->
            </div>

            <!-- <GmapAutocomplete
              :value="outlet.address"
              class="form-control"
              @place_changed="setPlace"
            ></GmapAutocomplete>-->
            <span v-if="formErrors.address" class="invalid-field">{{ formErrors.address }}</span>
          </div>
        </div>

        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_postcode')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <textarea
                  v-model="outlet.postCode"
                  v-validate="'integer'"
                  :disabled="selectedOutlet.newApprovalRequest || isPendingApprovalPage"
                  name="Postcode"
                  type="text"
                  class="form-control col"
                  placeholder
                  @keyup="formErrors.postCode = ''"
              />
              <!-- Change Request -->
              <div v-if="outletAfterChanges.postCode" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                  v-model="outletAfterChanges.postCode"
                  disabled
                  type="text"
                  class="form-control after-change"
                />
              </div>
            </div>
            <span v-if="formErrors.postCode" class="invalid-field">{{ formErrors.postCode }}</span>
            <span v-if="errors.first('Postcode')" class="invalid-field">
              {{ errors.first("Postcode") }}
            </span>
          </div>
        </div>


        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_category')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <Multiselect
                  ref="multiselect-type"
                  v-model="outlet.typeId"
                  class="col"
                  :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                  placeholder="Select Type"
                  :searchable="true"
                  :options="categoryList"
                  label="text"
                  track-by="id"
                  select-label
                  deselect-label
                  :loading="isCategoryLoading"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.typeId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="multiselect-type-after"
                  v-model="outletAfterChanges.typeId"
                  class="col after-change"
                  disabled
                  :options="categoryList"
                  label="text"
                  track-by="id"
                  :loading="isCategoryLoading"
              ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.typeId" class="invalid-field">{{ formErrors.typeId }}</span>
          </div>
        </div>

        <!-- <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Accreditation</label>
            <Multiselect
                ref="multiselect-type"
                v-model="outlet.accreditationId"
                :disabled="selectedOutlet.newApprovalRequest"
                placeholder="Select Accreditation"
                :searchable="true"
                :options="accreditationList"
                label="text"
                track-by="id"
                select-label
                deselect-label
                :loading="isAccreditationLoading"
            ></Multiselect>
            <span v-if="formErrors.accreditationId" class="invalid-field">{{ formErrors.accreditationId }}</span>
          </div>
        </div> -->
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Store Type</label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <Multiselect
                  ref="multiselect-type"
                  v-model="outlet.storeTypeId"
                  class="col"
                  :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                  placeholder="Select Type"
                  :searchable="true"
                  :options="storeTypeList"
                  label="text"
                  track-by="id"
                  select-label
                  deselect-label
                  :loading="isStoreTypeLoading"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.storeTypeId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="multiselect-type-after"
                  v-model="outletAfterChanges.storeTypeId"
                  class="col after-change"
                  disabled
                  :options="storeTypeList"
                  label="text"
                  track-by="id"
                  :loading="isStoreTypeLoading"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.storeTypeId" class="invalid-field">{{ formErrors.storeTypeId }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Focus Product</label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <Multiselect
                  ref="multiselect-type"
                  v-model="outlet.focusProductId"
                  class="col"
                  :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                  placeholder="Select Product"
                  :searchable="true"
                  :options="focusProductList"
                  label="text"
                  track-by="id"
                  select-label
                  deselect-label
                  :loading="isFocusProductLoading"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.focusProductId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="multiselect-type-after"
                  v-model="outletAfterChanges.focusProductId"
                  class="col after-change"
                  disabled
                  :options="focusProductList"
                  label="text"
                  track-by="id"
                  :loading="isFocusProductLoading"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.focusProductId" class="invalid-field">{{ formErrors.focusProductId }}</span>
          </div>
        </div>
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_outlet_area')"></label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <textarea
                  v-model="outlet.area"
                  :disabled="selectedOutlet.newApprovalRequest || isPendingApprovalPage"
                  type="text"
                  class="form-control col"
                  placeholder
                  @keyup="formErrors.area = ''"
              />
              <!-- Change Request -->
              <div v-if="outletAfterChanges.area" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <textarea
                  v-model="outletAfterChanges.area"
                  disabled
                  type="text"
                  class="form-control col after-change"
              />
              </div>
            </div>
            <span v-if="formErrors.area" class="invalid-field">{{ formErrors.area }}</span>
          </div>
        </div>

        <!-- START: STORE IDENTITY -->
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Store Identity</label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <Multiselect
                  ref="multiselect-type"
                  v-model="outlet.storeIdentityId"
                  class="col"
                  :disabled="selectedOutlet.newApprovalRequest || !(!isCountryView || !outlet.id) || isPendingApprovalPage"
                  placeholder="Select Store Identity"
                  :searchable="true"
                  :options="storeIdentityList"
                  label="text"
                  track-by="id"
                  select-label
                  deselect-label
                  :loading="isStoreIdentityLoading"
              ></Multiselect>
              <!-- Change Request -->
              <div v-if="outletAfterChanges.storeIdentityId" class="col-7 d-inline-flex m-0 p-0">
                <span style="margin: auto 1rem; font-size:2rem;">&#8594;</span>
                <Multiselect
                  ref="multiselect-type-after"
                  v-model="outletAfterChanges.storeIdentityId"
                  class="col after-change"
                  disabled
                  :options="storeIdentityList"
                  label="text"
                  track-by="id"
                  :loading="isStoreIdentityLoading"
                ></Multiselect>
              </div>
            </div>
            <span v-if="formErrors.storeIdentityId" class="invalid-field">{{ formErrors.storeIdentityId }}</span>
          </div>
        </div>
        <!-- END: STORE IDENTITY -->

        <!-- START: Quarterly Total PC Sellout Capacity (Lenovo+Competitor) -->
        <div v-if="!isChangeStatus" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Quarterly Total PC Sellout Capacity (Lenovo+Competitor)</label>
            <span class="required-star">*</span>
            <div class="row d-flex align-items-center m-0 p-0">
              <!-- REMINDER: Cap is CapAdmin, CapAdmin is Cap View Only -->
              <textarea
                v-model.number="outlet.totalPcSOCapacity"
                v-validate="'integer'"
                :disabled="!(!outlet.id || isCap)"
                type="number"
                class="form-control col"
                placeholder
                @keyup="formErrors.totalPcSOCapacity = ''"
              />
              <!-- Change Request (there is no changes in this field, only CAP admin can change) -->
            </div>
            <span v-if="formErrors.totalPcSOCapacity" class="invalid-field">{{ formErrors.totalPcSOCapacity }}</span>
          </div>
        </div>
        <!-- END: Quarterly Total PC Sellout Capacity (Lenovo+Competitor) -->

        <!-- eslint-disable vue/no-v-html -->
        <div v-if="isChangeStatus" class="col-lg-12 mb-4" v-html="changeStatusMessage">
        </div>
        <!--eslint-enable-->
        <div v-if="selectedOutlet.newApprovalRequest || isPendingApprovalPage || (!isCapAdmin && !isCap)" class="col-lg-12">
          <div class="form-group flex-grow-1">
            <label class="text-label">Reason</label>
            <span class="required-star">*</span>
            <textarea
              v-model="outlet.reason"
              :disabled="selectedOutlet.newApprovalRequest || isPendingApprovalPage"
              type="text"
              class="form-control col"
              :placeholder="outlet.id ? 'Kindly insert reason to update outlet here' : 'Kindly insert reason to add outlet here' "
              @keyup="formErrors.reason = ''"
            />
            <span v-if="formErrors.reason" class="invalid-field">{{ formErrors.reason }}</span>
          </div>
        </div>
      </div>

      <!--      <div class="col-lg-12">-->
      <!--        <div class="form-group">-->
      <!--          <GmapMap-->
      <!--            style="width: 100%; height: 300px"-->
      <!--            :zoom="17"-->
      <!--            :center="{ lat: location.lat, lng: location.lng }"-->
      <!--          >-->
      <!--            <GmapMarker-->
      <!--              v-if="location"-->
      <!--              label="★"-->
      <!--              :position="{-->
      <!--                lat: location.lat,-->
      <!--                lng: location.lng-->
      <!--              }"-->
      <!--            />-->
      <!--          </GmapMap>-->
      <!--        </div>-->
      <!--      </div>-->

      <div v-if="!(selectedOutlet.newApprovalRequest || isPendingApprovalPage)" class="row">
        <div v-if="!isCountryView || !outlet.id" class="col-lg-12">
          <button
              v-if="!outlet.id"
              type="submit"
              class="btn btn-secondary pull-right"
              v-text="$ml.get('form_outlet_add_button')"
          ></button>
          <button
              v-if="outlet.id && userRole !== 'CAP'"
              :disabled="outlet.pendingApprovalStatus && isCountryView"
              type="submit"
              class="btn btn-secondary pull-right"
              v-text="$ml.get('form_outlet_update_button')"
          ></button>
          <button
              v-if="userRole !== 'CAP'"
              type="button"
              class="btn btn-light pull-right"
              data-dismiss="modal"
              @click="close()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>


    <!-- View -->

    <div v-if="canView && currentAction === 'view'">
      <div class="col-12">
        <!-- <hr /> -->
        <div class="d-inline-flex w-100 justify-content-between mb-3 mt-3">
          <h4
            class="text-primary"
            style="margin: auto auto auto 0px"
            v-text="$ml.get('form_outlet_info')"
          ></h4>
          <button
            v-if="canEdit"
            class="btn btn-outline-dark btn-xs"
            style="margin: auto 0px auto auto auto"
            @click="closeModal(outlet, 4); closeModal(outlet, 4);"
          >
            <i class="fa fa-edit"></i>
          </button>
        </div>
        <br />
      </div>
      <table class="table">
        <tbody>
        <tr>
          <td v-text="$ml.get('form_outlet_dealer_outlet_code')"></td>
          <td>{{ outlet.dealerOutletCode }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_name')"></td>
          <td>{{ outlet.name }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_address')"></td>
          <td>{{ outlet.address }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_category')"></td>
          <td>{{ outlet.outletCategory }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_postcode')"></td>
          <td>{{ outlet.postCode }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_pic_name')"></td>
          <td>{{ outlet.outletPicName }}</td>
        </tr>
        <tr class="border-top-light">
          <td class="" v-text="$ml.get('sd_title_status')"></td>
          <div class="d-inline-flex pt-3">
            <td class="mr-4 none-border"><lnvStatusTextBox :status="outlet.outletStatus" /></td>
            <button
              v-if="canChangeStatus"
              class="btn btn-outline-dark m-auto"
              @click="doChangeStatus($event, 'outlet')"
            > 
              <i class="fa fa-exchange"></i>
            </button> 
          </div>
        </tr>
        </tbody>
      </table>

      <div class="col-12">
        <hr />
        <div class="d-inline-flex w-100 justify-content-between mb-3 mt-3">
          <h4
            class="text-primary"
            style="margin: auto auto auto 0px"
            v-text="$ml.get('form_outlet_dealer_info')"
          ></h4>
          <button
            v-if="canEdit"
            class="btn btn-outline-dark btn-xs"
            style="margin: auto 0px auto auto auto"
            @click="closeModal(); closeModalDealer(dealer, 1);"
          >
            <i class="fa fa-edit"></i>
          </button>
        </div>
        <br />
      </div>

      <table class="table">
        <tbody>
        <tr>
          <td v-text="$ml.get('form_dealer_lpp_code')"></td>
          <td>{{ outlet.lppCode }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_dealer_name')"></td>
          <td>{{ outlet.dealerName }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_dealer_account_manager')"></td>
          <td>{{ outlet.accountManager }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_dealer_accreditation')"></td>
          <td>{{ outlet.accreditation }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_area')"></td>
          <td>{{ outlet.area }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_storeType')"></td>
          <td>{{ outlet.storeType }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_outlet_focusProduct')"></td>
          <td>{{ outlet.focusProduct }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_dealer_contact_number')"></td>
          <td>{{ outlet.contactNumber }}</td>
        </tr>
        <tr>
          <td v-text="$ml.get('form_dealer_email')"></td>
          <td>{{ outlet.dealerEmail }}</td>
        </tr>
        <tr class="border-top-light">
          <td v-text="$ml.get('sd_title_status')"></td>
          <div class="d-inline-flex pt-3">
            <td class="mr-4 none-border"><lnvStatusTextBox :status="outlet.dealerStatus" /></td>
            <button
              v-if="canChangeStatus"
              class="btn btn-outline-dark m-auto"
              @click="doChangeStatus($event, 'dealer')"
            > 
              <i class="fa fa-exchange"></i>
            </button> 
          </div>
        </tr>
        </tbody>
      </table>


    </div>


    <!-- Promoters -->

    <div v-if="canView && currentAction === 'promoters'">
      <VueBootstrap4Table
          :classes="classes"
          :rows="promoterList"
          :columns="columns"
          :config="config"
          :total-rows="total"
          @on-change-query="onChangeQuery"
          @refresh-data="getPromoterList"
      >
        <template slot="sort-asc-icon">
          <i class="mdi mdi-sort-ascending"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="mdi mdi-sort-descending"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="mdi mdi-sort-variant"></i>
        </template>
        <template slot="empty-results">
          <div
            v-if="!isPromoterListLoading"
            class="bold-text"
            v-text="$ml.get('no_promoter_found')"
          ></div>
          <div
            v-if="isPromoterListLoading"
            class="loading mt-3"
          ></div>
        </template>

        <template slot="fullname" slot-scope="props">
          <div v-if="isCountryTrainer" class="inner-data">
            <span>{{ props.row.fullname }}</span>
          </div>
          <div v-else class="inner-data">
            <router-link :to="{ name : 'Promoters ', params : { fullname : props.row.fullname } }">
              <span style="color:#F70201">{{ props.row.fullname }}</span>
            </router-link>
          </div>
        </template>
        <template slot="status" slot-scope="props">
          <lnvStatusTextBox :status="props.row.bEnabled === true ? 'Active' : 'Deactivated' " />
        </template>
      </VueBootstrap4Table>
    </div>

    <!-- salespersons -->

    <div v-if="canView && currentAction === 'salespersons'">
      <VueBootstrap4Table
          :classes="classes"
          :rows="salespersonList"
          :columns="columnsSalesperson"
          :config="config"
          :total-rows="total"
          @on-change-query="onChangeQuery"
          @refresh-data="getSalespersonList"
      >
        <template slot="sort-asc-icon">
          <i class="mdi mdi-sort-ascending"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="mdi mdi-sort-descending"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="mdi mdi-sort-variant"></i>
        </template>
         <template slot="empty-results">
          <div
            v-if="!isSalespersonListLoading"
            class="bold-text"
            v-text="$ml.get('no_salesperson_found')"
          ></div>
          <div
            v-if="isSalespersonListLoading"
            class="loading mt-3"
          ></div>
        </template>
        <template slot="name" slot-scope="props">
          <div v-if="isCountryTrainer" class="inner-data">
            <span>{{ props.row.name }}</span>
          </div>
          <div v-else class="inner-data">
            <router-link :to="{ name : 'Salespersons ', params : { fullname : props.row.name } }">
              <span style="color:#F70201">{{ props.row.name }}</span>
            </router-link>
          </div>
        </template>
        <template slot="status" slot-scope="props">
          <lnvStatusTextBox :status="props.row.status === true ? 'Active' : 'Deactivated'" />
        </template>
      </VueBootstrap4Table>
    </div>

    </div>
    <div v-else style="height: 450px"><div class="loading loading-big"></div></div>
  </div>
</template>

<script>
import Vue from "vue";
import cloneDeep from "lodash/cloneDeep"
import debounce from "lodash/debounce"
import {
  OUTLET_POST_REQUEST,
  OUTLET_REQUEST,
  OUTLET_CHANGES_REQUEST,
  //OUTLET_PIC_LIST_REQUEST,
  // GET_PIC_LIST_BY_DEALER_ID_REQUEST
} from "../../store/modules/outlet/actions";
import {
  STATE_LIST_REQUEST,
  CATEGORY_LIST_REQUEST,
  STORE_TYPE_LIST_REQUEST,
  ACCREDITATION_LIST_REQUEST,
  FOCUS_PRODUCT_LIST_REQUEST,
  COUNTRY_LIST_REQUEST,
  GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID,
  STORE_IDENTITY_REQUEST
} from "../../store/modules/utility/actions";
import {DEALER_LIST_WITH_PARAMS_REQUEST} from "../../store/modules/dealer/actions";
import {GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG} from "../../store/modules/staff/actions";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import lnvStatusTextBox from "../../components/ui/statusTextBox";
import { convertDateFormat, isNumeric } from "../../utils/helpers";
import { postalCodeValidate } from "../../utils/helpers";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import {Message} from "element-ui";
import {GET_SALESPERSON_LIST_REQUEST, GET_STAFF_PROMOTER_LIST_REQUEST} from "@/store/modules/staff/actions";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";

const storeIdentityOptionOrder = ["SI_LES", "SI_KS", "SI_MPS", "SI_LPS"]

export default {
  components: {
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table,
    lnvStatusTextBox: lnvStatusTextBox
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    closeModalDealer: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    selectedOutlet: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectedDealer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    canEdit: { type: Boolean },
    canView: { type: Boolean },
    canChangeStatus: {type: Boolean},
    currentAction: { type: String, default: "" },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {},
  data() {
    return {
      isRendering: false,
      isPendingApprovalPage: this.$router.currentRoute.path == '/dashboard/change-requests-approval',
      outletAfterChanges: {},
      outlet: {
        accreditation: this.selectedOutlet.accreditation,
        createdCountryId: this.selectedOutlet.countryId,
        countryCode: this.selectedOutlet.countryCode,
        dealerId: this.selectedOutlet.dealerId,
        lppCode: this.selectedOutlet.lppCode,
        accountManager: this.selectedOutlet.accountManager,
        dealerEmail: this.selectedOutlet.dealerEmail,
        contactNumber: this.selectedOutlet.contactNumber,
        dealerStatus: this.selectedOutlet.dealerStatus,
        outletCategory: this.selectedOutlet.outletCategory,
        storeType: this.selectedOutlet.storeType,
        focusProduct: this.selectedOutlet.focusProduct,
        dealerName: this.selectedOutlet.dealerName,

        id: this.selectedOutlet.id,
        firstApprovalId: this.selectedOutlet.firstApprovalId,
        name: this.selectedOutlet.name,
        dealerOutletCode: this.selectedOutlet.dealerOutletCode,
        outletPicName: this.selectedOutlet.picName,
        outletStatus: this.selectedOutlet.status || 'Active',
        stateId: this.selectedOutlet.stateId,
        area: this.selectedOutlet.area,
        postCode: this.selectedOutlet.postCode,
        address: this.selectedOutlet.address,
        outletCategoryId: this.selectedOutlet.outletCategoryId,
        storeTypeId: this.selectedOutlet.storeTypeId, //LMB.LES
        accreditationId: this.selectedOutlet.accreditationId,
        focusProductId: this.selectedOutlet.focusProductId,
        storeIdentityId: this.selectedOutlet.storeIdentityId,
        outletPicId: this.selectedOutlet.picId,
        region: {},
        subRegion: {},

        latitude: this.selectedOutlet.latitude ? this.selectedOutlet.latitude : 0,
        longitude: this.selectedOutlet.longitude ? this.selectedOutlet.longitude : 0,
        totalPcSOCapacity: this.selectedOutlet.totalPcSOCapacity,
      },
      outletBeforeChange: {},
      dealer: {
        joinedDate: this.changeDate(this.selectedDealer.joinedDate),
        dealerName: this.selectedDealer.dealerName,
        storeId: this.selectedDealer.storeId,
        prmLppName: this.selectedDealer.prmLppName,
        email: this.selectedDealer.email,
        ownerName: this.selectedDealer.ownerName,
        ownerIcOrId: this.selectedDealer.ownerIcOrId,
        contactNum: this.selectedDealer.contactNum,
        bulkUpload: this.selectedDealer.bulkUpload,
        businessRegNo: this.selectedDealer.businessRegNo,
        accountManager: this.selectedDealer.accountManager,
        bankId: this.selectedDealer.bankId ? this.selectedDealer.bankId : "",
        bankAccNum: this.selectedDealer.bankAccNum,
        bankAccNam: this.selectedDealer.bankAccHolderName,
        id: this.selectedDealer.id,
        status: this.selectedDealer.status,
        countryName: this.selectedDealer.countryName,
        outletList: [],
        picList: [],
        salespersonList: [],
        countries: [],
        isCountriesLoading: false,
      },
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoters",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 3,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "fullname",
          label: "Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "accId",
          label: "User ID",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actual",
          label: "Actual",
          sort: true,
          width: "15%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "15%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        },
      ],
      columnsSalesperson: [
        {
          name: "name",
          label: "Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "id",
          label: "User ID",
          sort: true,
          width: "15%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "actual",
          label: "Actual",
          sort: true,
          width: "15%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        },
        {
          name: "status",
          label: "Status",
          sort: true,
          width: "15%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      promoterList: [],
      salespersonList: [],
      mdmCode: "",
      dealerList: [],
      stateList: [],
      categoryList: [],
      storeTypeList: [],
      accreditationList: [],
      focusProductList: [],
      storeIdentityList: [],
      picList: [],
      countryOptions: [],
      formErrors: [],
      dealerOptions: [],
      countryId: 0,
      userRole: "",
      isDealer: false,
      userId: 0,
      isChangeStatus: false,
      allowToChangeStatus: false,
      changeStatusFor: '',
      changeStatusMessage: '',
      isPicOutlet: false,
      showDealerField: false,
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      isCapAdmin: false,
      isCountryTrainer: false,
      isCountryView: false,
      isLeader: false,
      isCap: false,
      place: null,
      markers: [],
      isDealersLoading: false,
      isPicLoading: false,
      isRegionsLoading: false,
      isStatesLoading: false,
      isCategoryLoading: false,
      isStoreTypeLoading: false,
      isAccreditationLoading: false,
      isFocusProductLoading: false,
      isStoreIdentityLoading: false,
      isDataLoading: false,
      isSalespersonListLoading: false,
      isPromoterListLoading: false,
      location: {}
    };
  },
  methods: {
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    changeDate(date) {
      return convertDateFormat(date, "YYYY-MM-DD");
    },
    getRegionSubRegion(){
      this.isRegionsLoading = true;
      let stateId = this.outlet.stateId.id || this.outlet.stateId;
      this.$store.dispatch(GET_REGION_AND_SUBREGION_REQUEST_BY_STATE_ID, stateId).then(res => {
        if(res.content && res.resultCode === 0){
          this.outlet.region = {name: res.content[0].region};
          this.outlet.subRegion = {name: res.content[0].subRegion, id: res.content[0].subRegionId};
          this.isRegionsLoading = false;
        }
      })
      if (this.currentAction !== 'view') {
        this.getDealers();
      }
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;
      let haveChanges = false

      if (!this.outlet.dealerId) {
        this.formErrors.dealerId = this.$ml.get("form_outlet_dealer_name_required");
        error++;
      } else if (this.outletBeforeChange.dealerId != this.outlet.dealerId.id) {
        haveChanges = true
      }

      if (!this.outlet.countryCode) {
        this.formErrors.countryCode = this.$ml.get("form_outlet_country_required");
        error++;
      } else if (this.outletBeforeChange.countryCode && this.outletBeforeChange.countryCode.value != this.outlet.countryCode.value) {
        haveChanges = true
      }

      if (!this.outlet.name) {
        this.formErrors.name = this.$ml.get("form_outlet_name_required");
        error++;
      } else if (this.outletBeforeChange.name != this.outlet.name) {
        haveChanges = true
      }

      if (!this.outlet.stateId) {
        this.formErrors.stateId = this.$ml.get("form_outlet_state_required");
        error++;
      } else if (this.outlet.stateId && this.outletBeforeChange.stateId != this.outlet.stateId.id) {
        haveChanges = true
      }

      if (!this.outlet.address) {
        this.formErrors.address = this.$ml.get("form_outlet_address_required");
        error++;
      } else if (this.outletBeforeChange.address != this.outlet.address) {
        haveChanges = true
      }

      if (!this.outlet.storeTypeId) {
        this.formErrors.storeTypeId = this.$ml.get("form_outlet_store_type_required");
        error++;
      } else if (this.outletBeforeChange.storeTypeId != this.outlet.storeTypeId.id) {
        haveChanges = true
      }

      if (!this.outlet.focusProductId) {
        this.formErrors.focusProductId = this.$ml.get("form_outlet_focus_product_required");
        error++;
      } else if (this.outletBeforeChange.focusProductId != this.outlet.focusProductId.id) {
        haveChanges = true
      }

      if (!this.outlet.storeIdentityId) {
        this.formErrors.storeIdentityId = this.$ml.get("form_outlet_store_identity_required");
        error++;
      } else if (this.outletBeforeChange.storeIdentityId != this.outlet.storeIdentityId.id) {
        haveChanges = true
      }

      if (!this.outlet.totalPcSOCapacity && this.outlet.totalPcSOCapacity !== 0 /* will be handled by next validation */) {
        this.formErrors.totalPcSOCapacity = this.$ml.get("form_outlet_total_pc_so_capacity_required")
        error++;
      } else if (
        this.outlet.totalPcSOCapacity < 1
        || isNumeric(this.outlet.totalPcSOCapacity) === false
      ) {
        this.formErrors.totalPcSOCapacity = "Number must be larger than 0"
        error++;
      } else if (this.outletBeforeChange.totalPcSOCapacity != this.outlet.totalPcSOCapacity) {
        haveChanges = true
      }
      

      if (!this.outlet.area) {
        this.formErrors.area = this.$ml.get("form_outlet_area_required");
        error++;
      } else if (this.outletBeforeChange.area != this.outlet.area) {
        haveChanges = true
      }

      if (!this.outlet.postCode) {
        this.formErrors.postCode = this.$ml.get("form_outlet_postcode_required");
        error++;
      } else {
        if (!postalCodeValidate(this.outlet.postCode)) {
          this.formErrors.postCode = this.$ml.get("form_outlet_postcode_invalid");
          error++;
        }
        if (this.outletBeforeChange.postCode != this.outlet.postCode) {
          haveChanges = true
        }
      }

      if (!this.outlet.typeId) {
        this.formErrors.typeId = this.$ml.get("form_outlet_category_required");
        error++;
      } else if (this.outletBeforeChange.typeId != this.outlet.typeId.id) {
        haveChanges = true
      }

      if (!this.outlet.reason && !this.isCapAdmin && !this.isCap) {
        this.formErrors.reason = "This field is required";
        error++;
      } else if (this.isChangeStatus) {
        // change status only need reason
        return true
      } else if (this.outlet.id && !haveChanges) {
        this.formErrors.reason = "There is no changes in the form. Please make some changes before submit";
        error++;
      }
      return !error;
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit: debounce(function(e) {
      if (!this.checkFormValidity(e)) {
        return;
      } else {
        if (this.isChangeStatus) {
          this.doChangeStatus(null, this.changeStatusFor)
          return
        }
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_submitting")
        });
        this.outlet.countryId = this.countryId === 0 ? 
          this.outlet.createdCountryId ? this.outlet.createdCountryId.id || this.outlet.createdCountryId : this.$store.getters.getCountryId 
          : this.countryId;
        // outlet.dealerId = this.outlet.dealerId.id;
        console.log(this.outlet)
        if (this.outlet.id) this.outlet.updateReason = this.outlet.reason
        this.$store.dispatch(OUTLET_POST_REQUEST, this.outlet).then(res => {
          if (res.resultCode === 0) {
            this.success();
            if (this.outlet.id) {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: this.$ml.get("form_outlet_updated")
              });
            } else {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: this.$ml.get("form_outlet_added")
              });
            }
          }
        });
      }
    }, 500),
    doChangeStatus(event, type) {
      if (event) event.preventDefault();
      if (!this.isChangeStatus) {
        this.changeStatusFor = type
        this.isChangeStatus = true
        let status = type === 'outlet' ? this._props.selectedOutlet.status : this._props.selectedOutlet.dealerStatus;
        if (
          // (this.isCountryAdmin || this.isGLNCountryAdmin) && 
        this.outlet.id && this.outlet.pendingApprovalStatus){
          this.changeStatusMessage = 'Sorry, this outlet is pending for approval and can\'t be changed now.'
          this.allowToChangeStatus = false
        } else {
          this.changeStatusMessage = status == "ACTIVE"
                ? "Do you want to <b class='red-heighlighted'>deactivate</b> " +
                  this._props.selectedOutlet.name
                : "Do you want to <b class='red-heighlighted'>activate</b> " +
                  this._props.selectedOutlet.name
          this.allowToChangeStatus = true
        }
        return
      }
      if (this.allowToChangeStatus === false) return
      this.formErrors.reason = {}
      this._props.selectedOutlet.change_dealer_status = false;
      this._props.selectedOutlet.change_status = false;
      type === 'outlet' ? this._props.selectedOutlet.change_status = true : this._props.selectedOutlet.change_dealer_status = true;
      // let status =  this._props.selectedOutlet.status : this._props.selectedOutlet.dealerStatus;

      this._props.selectedOutlet.statusUpdateReason = this.outlet.reason
      this.$store.dispatch(OUTLET_POST_REQUEST, this._props.selectedOutlet).then(() => {
        this.loadData();
        Message({
          message:
            type === 'outlet' ?
              status == "ACTIVE"
                ? "Outlet Deactivated."
                : "Outlet Activated."
              : status == "ACTIVE"
                ? "Dealer Deactivated."
                : "Dealer Activated.",
          type: "success",
          duration: 5 * 1000
        });
        this.close();
      });
    },
    async getCountries() {
      this.countries = [];
      this.isDataLoading = true;
      await this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countries.push(country);
          });

          if (this.outlet.createdCountryId) {
            this.getStates();
            this.outlet.createdCountryId = this.getSelectedCountry(this.outlet.createdCountryId);
            res.content.filter(country => {
              if(country.code === this.outlet.countryCode.value) {
                this.countryId = country.id;
              }
            })
          }
        }
        this.isDataLoading = false;
      });
    },
    getSelectedCountry(id) {
      let c = this.countries.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getOutlet(id) {
      if (id) {
        this.$store.dispatch(OUTLET_REQUEST, { id: id }).then(res => {
          if (res.resultCode === 0) {
            const outlet = res.content;
            this.outlet.name = outlet.name;
            this.outlet.typeId = outlet.typeId;
            this.outlet.countryId = outlet.countryId;
            this.outlet.stateId = outlet.stateId;
            this.outlet.area = outlet.area;
            this.outlet.postCode = outlet.postCode;
            this.outlet.latitude = outlet.latitude ? outlet.latitude : 0;
            this.outlet.longitude = outlet.longitude ? outlet.longitude : 0;
            this.outlet.address = outlet.address;
            this.outlet.id = outlet.id;
            this.outlet.dealerOutletCode = outlet.dealerOutletCode;
            this.outlet.dealerId = outlet.dealerId;
            this.outlet.storeTypeId = outlet.storeTypeId;
            this.outlet.accreditationId = outlet.accreditationId;
            this.outlet.focusProductId = outlet.focusProductId;
            this.outlet.storeIdentityId = outlet.storeIdentityId;
            this.outlet.totalPcSOCapacity = outlet.totalPcSOCapacity;
            this.outlet.countryCode = {name: this.countries.find(el => el.id === outlet.countryId).text, value: this.countries.find(el => el.id === outlet.countryId).code};
            this.outlet.deactivationDate = outlet.deactivationDate ? this.changeDate(outlet.deactivationDate) : null
            this.outlet.activationDate = outlet.activationDate ? this.changeDate(outlet.activationDate) : null
            // if (this.isDealer) {
            //   this.getDealersPIC({});
            // } else 
            if (this.isPicOutlet) {
              this.outlet.dealerId = { id: res.content.dealerId };
              this.outlet.outletPicId = { id: res.content.outletPicId };
            } else if (this.currentAction !== 'view') {
              this.getDealers();
            }

            if(this.selectedOutlet.newApprovalRequest){
              this.outlet.reason = outlet.reason;
            }

            if (this.outlet.latitude && this.outlet.longitude) {
              this.markers.push({
                position: {
                  lat: this.outlet.latitude ? this.outlet.latitude : 0,
                  lng: this.outlet.longitude ? this.outlet.longitude : 0
                }
              });
            }
            this.getRegionSubRegion();
            this.getStates();
            this.getCategories();
            this.getStoreType();
            this.getAccreditation();
            this.getFocusProduct();
            this.getStoreIdentity();
            this.outletBeforeChange = cloneDeep(this.outlet)
          }
        });
      } else {
        if (this.isDealer) {
          this.outlet.dealerId = { id: this.selectedOutlet.dealerId };
          this.outlet.outletPicId = { id: this.selectedOutlet.picId };
          // this.getDealersPIC(this.outlet.dealerId);
        } else if (this.isPicOutlet) {
          this.outlet.dealerId = { id: this.selectedOutlet.dealerId };
          this.outlet.outletPicId = { id: this.selectedOutlet.picId };
        } else if (this.currentAction !== 'view') {
          this.getDealers();
        }
        this.getStates();
        this.getCategories();
        this.getStoreType();
        this.getAccreditation();
        this.getFocusProduct();
        this.getStoreIdentity();
      }
    },
    getFilters() {
      this.countryOptions = [];
      this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET).then(res => {
        if (res.content) {
          res.content.countryDropdown.forEach(ctry => {
            if (ctry.name !== "CAP") {
              this.countryOptions.push(ctry);
            }
          });
        }
        if (this.outlet.countryCode) {
          this.outlet.countryCode = this.getDataRowByCode(this.countryOptions, this.outlet.countryCode);
        } else if(!this.isCap && !this.isCapAdmin)  {
          this.outlet.countryCode = this.getDataRowByCode(this.countryOptions, this.$store.getters.getCountryCode);
          this.getStates();
          if(this.outlet.id > 0 && this.currentAction !== 'view'){
            this.getDealers();
          }
        }
      });
    },

    getDealers() {
      this.dealerOptions = [];
      this.isDealersLoading = true;
      // this.isPicLoading = true;
      let countryId = this.countryId || this.outlet.countryId || this.selectedOutlet.countryId || this.$store.getters.getCountryId;
      this.$store.dispatch(DEALER_LIST_WITH_PARAMS_REQUEST, { countryId: countryId, status: 'ACTIVE' }).then(res => {
        if (res.content) {
          this.isDealersLoading = false;
          this.dealerOptions = res.content;
        } else {
          this.isStatesLoading = false;
        }
        if (this.outlet.dealerId > 0) {
          this.outlet.dealerId = this.getDataRowById(this.dealerOptions, this.outlet.dealerId);
          if (this.outletAfterChanges.dealerId) this.outletAfterChanges.dealerId = this.getDataRowById(this.dealerOptions, this.outletAfterChanges.dealerId);
          this.mdmCode = this.outlet.dealerId.prmLppName;
          // this.getDealersPIC();
        }
      });
    },
    onChangeCountry() {
      this.countries.filter(country => {
        if(country.code === this.outlet.countryCode.value) {
          this.countryId = country.id;
        }
      });
      this.outlet.dealerId = "";
      this.mdmCode = "";
      this.outlet.stateId = "";
      this.outlet.outletPicId = "";
      this.outlet.region = {};
      this.outlet.subRegion = {};
      // this.getDealers();
      this.getStates();
    },
    getStates() {
      this.stateList = [];
      this.isStatesLoading = true;
      let ccf = this.outlet.countryCode.value || this.outlet.countryCode;

      this.$store.dispatch(STATE_LIST_REQUEST, { ccf: ccf }).then(res => {
        if (res.resultCode === 0) {
          this.isStatesLoading = false;
          this.stateList = res.content;

          if (this.outlet.stateId > 0) {
            this.outlet.stateId = this.getDataRowById(this.stateList, this.outlet.stateId);
            if (this.outletAfterChanges.stateId) this.outletAfterChanges.stateId = this.getDataRowById(this.stateList, this.outletAfterChanges.stateId);
          }
        } else {
          this.isStatesLoading = false;
        }
      });
    },
    // getDealersPIC() {
    //   this.isPicLoading = true;
    //   this.picList = [];
      
    //   let dId = this.outlet.dealerId.id;

    //   this.$store.dispatch(GET_PIC_LIST_BY_DEALER_ID_REQUEST, { dId: dId }).then(res => {
    //     if (res.resultCode === 0) {
    //       this.isPicLoading = false;
    //       this.picList = res.content;

    //     } else {
    //       this.isPicLoading = false;
    //     }
    //     if (this.outlet.outletPicId.id > 0) {
    //       this.outlet.outletPicId = this.getDataRowById(this.picList, this.outlet.outletPicId.id);
    //     }
    //     // console.log(this.outlet.outletPicId)
    //   });
    // },
    getCategories() {
      this.isCategoryLoading = true;
      this.$store.dispatch(CATEGORY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.categoryList = res.content;
          this.isCategoryLoading = false;
        } else {
          this.isCategoryLoading = false;
        }
        if (this.outlet.typeId > 0) {
          this.outlet.typeId = this.getDataRowById(this.categoryList, this.outlet.typeId);
          if (this.outletAfterChanges.typeId) this.outletAfterChanges.typeId = this.getDataRowById(this.categoryList, this.outletAfterChanges.typeId);
        }
      });
    },
    getStoreType() {
      this.isStoreTypeLoading = true;
      this.$store.dispatch(STORE_TYPE_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.storeTypeList = res.content;
          this.isStoreTypeLoading = false;
        } else {
          this.isStoreTypeLoading = false;
        }
        if (this.outlet.storeTypeId > 0) {
          this.outlet.storeTypeId = this.getDataRowById(this.storeTypeList, this.outlet.storeTypeId);
          if (this.outletAfterChanges.storeTypeId) this.outletAfterChanges.storeTypeId = this.getDataRowById(this.storeTypeList, this.outletAfterChanges.storeTypeId);
        }
      });
    },
    getAccreditation() {
      this.isAccreditationLoading = true;
      this.$store.dispatch(ACCREDITATION_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.accreditationList = res.content;
          this.isAccreditationLoading = false;
          if (this.outlet.accreditationId > 0) {
            this.outlet.accreditationId = this.getDataRowById(this.accreditationList, this.outlet.accreditationId);
          if (this.outletAfterChanges.accreditationId) this.outletAfterChanges.accreditationId = this.getDataRowById(this.accreditationList, this.outletAfterChanges.accreditationId);
          }
        } else {
          this.isAccreditationLoading = false;
        }
      });
    },
    getFocusProduct() {
      this.isFocusProductLoading = true;
      this.$store.dispatch(FOCUS_PRODUCT_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.focusProductList = res.content;
          this.isFocusProductLoading = false;
          if (this.outlet.focusProductId > 0) {
            this.outlet.focusProductId = this.getDataRowById(this.focusProductList, this.outlet.focusProductId);
            if (this.outletAfterChanges.focusProductId) this.outletAfterChanges.focusProductId = this.getDataRowById(this.focusProductList, this.outletAfterChanges.focusProductId);
          }
        } else {
          this.isFocusProductLoading = false;
        }
      });
    },
    getStoreIdentity() {
      this.isStoreIdentityLoading = true;
      this.$store.dispatch(STORE_IDENTITY_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.storeIdentityList = res.content.sort((a, b) => {
            return storeIdentityOptionOrder.indexOf(a.code) - storeIdentityOptionOrder.indexOf(b.code)
          })
          if (this.outlet.storeIdentityId > 0) {
            this.outlet.storeIdentityId = this.getDataRowById(this.storeIdentityList, this.outlet.storeIdentityId);
            if (this.outletAfterChanges.storeIdentityId) this.outletAfterChanges.storeIdentityId = this.getDataRowById(this.storeIdentityList, this.outletAfterChanges.storeIdentityId);
          }
        }
        this.isStoreIdentityLoading = false;
      });
    },
    getDataRowByCode(arr, code) {
      let c = arr.filter(function(elem) {
        if (elem.value === code) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },

    getDataRowById(arr, id) {
      let c = arr.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    initGeoCoding() {
      Vue.$geocoder.setDefaultMode("address"); // this is default
      let addressObj = {
        address_line_1: this.outlet.address
      };
      Vue.$geocoder.send(addressObj, response => {
        this.location = response.results[0].geometry.location;
      });
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPromoterList();
    },
    getPromoterList() {
      let pdata = {
        ccf: this.outlet.countryCode,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        oif: this.outlet.id,
      };
      this.isPromoterListLoading = true;
      this.promoterList = [];
      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      this.$store.dispatch(GET_STAFF_PROMOTER_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.promoterList = res.content;
          this.total = this.promoterList.length;
          this.isPromoterListLoading = false;
        } else {
          this.isPromoterListLoading = false;
        }
      });
    },
    getSalespersonList() {
      let pdata = {
        ccf: this.outlet.countryCode,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        oif: this.outlet.id,
      };
      this.isSalespersonListLoading = true;
      this.salespersonList = [];
      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      this.$store.dispatch(GET_SALESPERSON_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.salespersonList = res.content;
          this.total = this.salespersonList.length;
          this.isSalespersonListLoading = false;
        } else {
          this.isSalespersonListLoading = false;
        }
      });
    },
    getOutletChanges() {
      const isPending = this.outlet.outletStatus === "Pending"
      const postData = {
        id: isPending ? 0 : this.outlet.firstApprovalId,
        outletId: isPending ? this.outlet.id : 0
      }
        
      this.$store.dispatch(OUTLET_CHANGES_REQUEST, postData).then(res => {
        if (res.content && res.resultCode === 0) {
          this.outletAfterChanges = res.content
          this.outlet.reason = this.outletAfterChanges.reason
        }
      })
    },
    checkForPendingApprovalFlag(id){
      this.$store.dispatch(GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG, id).then(res => {
        if (res.resultCode === 0) {
          this.outlet.pendingApprovalStatus = res.content;
        }
      });
    }
  },
  created() {
    // this.getBanks();
  },
  async mounted() {
    this.isRendering = true
    this.isCountryTrainer = this.$store.getters.isCountryTrainer;
    await this.getCountries();
    if(this.currentAction==='promoters'){
      this.getPromoterList();
    }
    else if(this.currentAction==='salespersons'){
      this.getSalespersonList();
    }
    // else if(this.currentAction==='view'){
    //   //this.initGeoCoding();
    // }
    else{
      if(!this.selectedOutlet.newApprovalRequest){
        this.getFilters();
      }
      // this.countryId = this.$store.getters.getCountryId;
      this.userRole = this.$store.getters.getUserRole;
      this.userId = this.$store.getters.getUserId;
      this.isDealer = this.$store.getters.isDealer;
      this.isPicOutlet = this.$store.getters.isPicOutlet;
      this.isCountryAdmin = this.$store.getters.isCountryAdmin;
      this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
      this.isCap = this.$store.getters.isCap;
      this.isCapAdmin = this.$store.getters.isCapAdmin;
      this.isCountryView = this.$store.getters.isCountryView;
      this.isLeader = this.$store.getters.isPromoLeader;
      if (this.selectedOutlet.id && !this.selectedOutlet.newApprovalRequest && !this.isPendingApprovalPage) {
        this.outlet.dealerId = { id: this.selectedOutlet.dealerId };
        this.outlet.outletPicId = { id: this.selectedOutlet.outletPicId };
        this.getRegionSubRegion();
        if(!this.isCountryTrainer && this.currentAction != "view"){
          this.checkForPendingApprovalFlag(this.selectedOutlet.id);
        }
      }

      if (this.isDealer) {
        // console.log(this.isDealer);
      } else if (this.isPicOutlet) {
        this.outlet.dealerId = { id: this.selectedOutlet.dealerId };
        this.outlet.outletPicId = { id: this.selectedOutlet.outletPicId };
      } else {
        // this.getDealers();
      }
      if (this.outlet.id > 0 || this.isDealer) {
        this.getOutlet(this.outlet.id);
      } else {
        //this.getStates();
        this.getCategories();
        this.getStoreType();
        this.getAccreditation();
        this.getFocusProduct();
        this.getStoreIdentity();
      }
      this.showDealerField = !(this.isDealer || this.isPicOutlet);
      if (this.isPendingApprovalPage && !this.selectedOutlet.newApprovalRequest) {
        this.getOutletChanges();
      }
    }
    this.isRendering = false
  }
};
</script>
<style lang="scss" scoped>
.addressRefreshButton,
.addressRefreshButton:focus,
.addressRefreshButton:active,
.addressRefreshButton:hover {
  min-height: 4rem;
  height: 4rem;
  font-size: 1.3rem;
  border-radius: 0;
  outline-color: rgb(221, 223, 225);
  border-color: rgb(221, 223, 225);
}
.border-top-light{
  border-top: .1rem solid rgba(120, 130, 140, .13);
}
.none-border{
  border: none;
}

.after-change {
  border: 1px solid red;
}

.multiselect.col {
  padding: 0;
}
</style>
