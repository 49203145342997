<template>
  <div>
    <p
      v-if="canEdit && currentAction === 'edit' && leader.pendingApprovalStatus && !leader.changeRequest"
      class="required-title"
      v-text="$ml.get('promoter_leader_pending_approval_request')"
    ></p>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="(canEdit && currentAction === 'edit') || leader.changeRequest" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_leader_name')"></label>
            <span class="required-star">*</span>
            <input
              id="name"
              v-model="leader.name"
              :disabled="leader.changeRequest"
              name="name"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.name = ''"
            />
            <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
            <span class="required-star">*</span>
            <Multiselect
                ref="multiselect"
                v-model="leader.countryId"
                placeholder="Select country"
                :searchable="true"
                :disabled="(isCountryAdmin || isGLNCountryAdmin && true) || leader.changeRequest"
                :options="countries"
                label="text"
                track-by="id"
                select-label
                deselect-label
                :loading="isLeadersLoading"
            ></Multiselect>
            <span v-if="errors.first('Country')" class="invalid-field">
              {{ errors.first("Country") }}
            </span>
            <span v-if="formErrors.countryId" class="invalid-field">{{ formErrors.countryId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_leader_email')"></label>
            <span class="required-star">*</span>
            <input
              v-model="leader.email"
              v-validate="{ regex: getEmailRegex() }"
              :disabled="leader.changeRequest"
              name="form_leader_email"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.email = ''"
            />
            <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
            <!-- eslint-disable vue/no-v-html  -->
            <span
              v-if="errors.firstByRule('form_leader_email', 'regex') && !formErrors.email"
              class="invalid-field"
              v-html="$ml.get('invalid_email_address')"
            ></span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_leader_contact_number')"></label>
            <span class="required-star">*</span>
            <input
              v-model="leader.phoneNumber"
              v-validate="{ regex: getPhoneRegex() }"
              :disabled="leader.changeRequest"
              name="form_leader_phone"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.phoneNumber = ''"
            />
            <span v-if="formErrors.phoneNumber" class="invalid-field">{{
              formErrors.phoneNumber
            }}</span>
            <span
              v-if="errors.firstByRule('form_leader_phone', 'regex') && !formErrors.phoneNumber"
              class="invalid-field"
              v-html="$ml.get('invalid_phone_number')"
            ></span>
          </div>
        </div>

        <div v-if="!leader.changeRequest" class="col-12">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" :disabled="leader.staffId && leader.pendingApprovalStatus && (isCountryAdmin || isGLNCountryAdmin)" class="btn btn-secondary pull-right">
                <span v-if="!leader.staffId" v-text="$ml.get('form_leader_add_button')"></span>
                <span v-if="leader.staffId" v-text="$ml.get('form_leader_update_button')"></span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="close()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- View -->
    <div v-if="canView && currentAction === 'view'">
      <table class="table">
        <tbody>
          <tr>
            <td v-text="$ml.get('form_leader_join_date')"></td>
            <td>{{ leader.joinedDate }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_leader_email')"></td>
            <td>{{ leader.email }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_leader_contact_number')"></td>
            <td>{{ leader.phoneNumber }}</td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col-lg-12 add-employee-button d-flex align-items-center">
          <b-field class="mr-auto" grouped group-multiline>
            <div class="control clickable" @click="filterList('active')">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-large">Active</b-tag>
                <b-tag type="is-success" size="is-large">
                  <strong>{{ leaderPromoterListActiveTotal.length }}</strong>
                </b-tag>
              </b-taglist>
            </div>

            <div class="control clickable" @click="filterList('fullTime')">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-large">Full Time</b-tag>
                <b-tag type="is-success" size="is-large">
                  <strong>{{ leaderPromoterListFullTimeTotal.length }}</strong>
                </b-tag>
              </b-taglist>
            </div>

            <div class="control clickable" @click="filterList('partTime')">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-large">Part Time</b-tag>
                <b-tag type="is-success" size="is-large">
                  <strong>{{ leaderPromoterListPartTimeTotal.length }}</strong>
                </b-tag>
              </b-taglist>
            </div>

            <div class="control clickable" @click="filterList('tablet')">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-large">Tablet</b-tag>
                <b-tag type="is-success" size="is-large">
                  <strong>{{ leaderPromoterListTabletTotal.length }}</strong>
                </b-tag>
              </b-taglist>
            </div>

            <div v-if="canFilterMBG()" class="control clickable" @click="filterList('mbg')">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-large">MBG</b-tag>
                <b-tag type="is-success" size="is-large">
                  <strong>{{ leaderPromoterListMBGTotal.length }}</strong>
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
          <button class="btn btn-sm btn-primary">
            <router-link :to="{ name : 'Promoters ', params : { fullname : leader.name } }">
              <span style="color:white">View More</span>
            </router-link>
          </button>
          <br>
          <br>
          <br>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <VueBootstrap4Table
              :classes="classes"
              :rows="filterState"
              :columns="columns"
              :config="config"
              :total-rows="total"
              @on-change-query="onChangeQuery"
              @refresh-data="getLeaderPromotersList"
          >
            <template slot="sort-asc-icon">
              <i class="mdi mdi-sort-ascending"></i>
            </template>
            <template slot="sort-desc-icon">
              <i class="mdi mdi-sort-descending"></i>
            </template>
            <template slot="no-sort-icon">
              <i class="mdi mdi-sort-variant"></i>
            </template>
            <template slot="empty-results">
              <div
                  v-if="!isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('no_promoter_found')"
              ></div>
              <div
                  v-if="isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('data_loading')"
              ></div>
            </template>

            <template slot="fullname" slot-scope="props">
              <div class="inner-data">
                <router-link :to="{ name : 'Promoters ', params : { fullname : props.row.fullname } }">
                  <span style="color:#F70201">{{ props.row.fullname }}</span>
                </router-link>
              </div>
            </template>
            <template slot="outletName" slot-scope="props">
              <div class="inner-data">
                <router-link :to="{ name : 'Outlet Details ', params : { dealerOutletCode : props.row.dealerOutletCode } }">
                  <span style="color:#F70201">{{ props.row.outletName }}</span>
                </router-link>
              </div>
            </template>
            <template slot="bEnabled" slot-scope="props">
              <div class="inner-data">
                <lnvStatusTextBox :active="props.row.bEnabled" />
              </div>
            </template>
            <template slot="promoterType" slot-scope="props">
              <div class="inner-data">
                <span>{{ props.row.promoterType }}</span>
              </div>
            </template>
          </VueBootstrap4Table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST,
  //GET_STAFF_LIST_REQUEST,
  POST_STAFF_REQUEST
} from "../../store/modules/staff/actions";
import lnvStatusTextBox from "../../components/ui/statusTextBox";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import {nameValidate, emailValidate, phoneValidate, convertDateFormat} from "../../utils/helpers";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";
import Multiselect from "vue-multiselect";
import {GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG} from "../../store/modules/staff/actions";

export default {
  name: "LeaderForm",
  components: {
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table,
    lnvStatusTextBox: lnvStatusTextBox
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    leaderSelected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    canEdit: { type: Boolean },
    canView: { type: Boolean },
    currentAction: { type: String, default: "" },
    fiscalYearFilter: { type: Object, default: () => {} },
    quarterFilter: { type: Object, default: () => {} }
  },
  computed: {
    filterState() {
      if (this.normal) {
        return this.leaderPromoterList;
      } else if (this.activeList) {
        return this.leaderPromoterListActive;
      } else if (this.fullTimeList) {
        return this.leaderPromoterListFullTime;
      } else if (this.partTimeList) {
        return this.leaderPromoterListPartTime;
      } else if (this.tabletList) {
        return this.leaderPromoterListTablet;
      } else if (this.mbgList) {
        return this.leaderPromoterListMBG;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Leader Promoters",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 3,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "accId",
          label: "User ID",
          sort: true,
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "fullname",
          label: "Promoter Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },

        {
          name: "dealerOutletCode",
          label: "Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "outletName",
          label: "Outlet Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "promoterType",
          label: "Full Time / Part Time",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "bEnabled",
          label: "Active / Deactive",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      leaderPromoterList: [],
      genders: [],
      assignedPromoters: this.leaderSelected.promoterList ? this.leaderSelected.promoterList : [],
      assignedPromotersTotal: 0,
      leader: {
        name: this.leaderSelected.fullName || this.leaderSelected.userFullName,
        countryId: this.leaderSelected.countryId,
        email: this.leaderSelected.username || this.leaderSelected.userEmail,
        phoneNumber: this.leaderSelected.phoneNumber,
        staffId: this.leaderSelected.accId || this.leaderSelected.profileId,
        joinedDate: this.leaderSelected.createdDate,
        changeRequest: this.leaderSelected.changeRequest,
        type: "leaders"
      },
      uniqueLeaderPromoterList: [],
      leaderPromoterListActive: [],
      leaderPromoterListFullTime: [],
      leaderPromoterListPartTime: [],
      leaderPromoterListTablet: [],
      leaderPromoterListMBG: [],
      leaderPromoterListActiveTotal: [],
      leaderPromoterListFullTimeTotal: [],
      leaderPromoterListPartTimeTotal: [],
      leaderPromoterListTabletTotal: [],
      leaderPromoterListMBGTotal: [],
      normal: true,
      activeList: false,
      fullTimeList: false,
      partTimeList: false,
      tabletList: false,
      mbgList: false,
      submitted: false,
      isCountryAdmin: false,
      isCountryView: false,
      isGLNCountryAdmin: false,
      isCap: false,
      isCapAdmin: false,
      isRegionManager: false,
      userCountryId: null,
      formErrors: [],
      countries: [],
      isLeadersLoading: false,
      isDataLoading: false,
    };
  },
  methods: {
    getPhoneRegex() {
      return phoneValidate(this.leader.phoneNumber, 1);
    },
    getEmailRegex() {
      return emailValidate(this.leader.email, 1);
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.leader.name) {
        this.formErrors.name = this.$ml.get("form_leader_name_required");
        error++;
      } else {
        if (!nameValidate(this.leader.name)) {
          this.formErrors.name = this.$ml.get("form_leader_name_invalid");
          error++;
        }
      }
      if (!this.leader.countryId) {
        this.formErrors.countryId = this.$ml.get("form_leader_country_required");
        error++;
      }
      if (!this.leader.email) {
        this.formErrors.email = this.$ml.get("form_leader_email_required");
        error++;
      } else {
        if (!emailValidate(this.leader.email)) {
          this.formErrors.email = this.$ml.get("form_leader_email_invalid");
          error++;
        }
      }

      if (!this.leader.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get("form_leader_contact_number_required");
        error++;
      } else {
        if (!phoneValidate(this.leader.phoneNumber)) {
          this.formErrors.phoneNumber = this.$ml.get("invalid_phone_number");
          error++;
        }
      }

      if (error) {
        return false;
      } else {
        return true;
      }
    },
    checkForPendingApprovalFlag(){
      this.$store.dispatch(GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG, this.leader.staffId).then(res => {
        if (res.resultCode === 0) {
          this.leader.pendingApprovalStatus = res.content;
        }
      });
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (!this.checkFormValidity(e)) {
        return;
      } else {
        this.$store.dispatch(NOTIFICATION_REQUEST, {
          message: this.$ml.get("request_submitting")
        });
        let pdata = {};
        if (this.leader.staffId > 0) {
          pdata.data = this.leader;
          pdata.staffId = this.leader.staffId;
          pdata.data.countryId = this.leader.countryId.id || this.leader.countryId;
          pdata.data.id = this.leader.staffId;
        } else {
          let leader = {...this.leader};
          pdata.data = [leader];
          pdata.data[0].countryId = pdata.data[0].countryId.id || pdata.data[0].countryId;
        }
        pdata.type = this.leader.type;

        this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
          if (res.resultCode === 0) {
            this.success();
            if (this.leader.staffId) {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: this.$ml.get("form_leader_updated")
              });
            } else {
              this.$store.dispatch(NOTIFICATION_SUCCESS, {
                message: this.$ml.get("form_leader_added")
              });
            }
          }
        });
      }
    },
    getCountries() {
      this.countries = [];
      this.isLeadersLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countries.push(country);
          });

          if(this.leader.changeRequest){
            this.leader.countryId = this.countries.find(el => el.code === this.leaderSelected.submitterCountryCode);
          } else if (this.leader.countryId) {
            this.leader.countryId = this.getSelectedCountry(this.leader.countryId);
          } else if(!this.isCap && !this.isCapAdmin) {
            this.leader.countryId = this.getSelectedCountry(this.$store.getters.getCountryId);
          }
        }
        this.isLeadersLoading = false;
      });
    },
    getSelectedCountry(id) {
      let c = this.countries.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getLeaderPromotersList() {
      let pdata = {
        sif: this.leader.staffId,
        // fyf: this.fiscalYearFilter.value,
        // qf: this.quarterFilter.value
      };
      this.isDataLoading = true;
      this.leaderPromoterList = [];
      this.$store.dispatch(GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.leaderPromoterList = res.content;
          this.total = this.leaderPromoterList.length;
          this.getStats();
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
        // this.$store.dispatch(GET_STAFF_LIST_REQUEST, { type: "promoters" }).then(res => {
        //    if (res.content && res.resultCode === 0) {
        //      res.content.forEach(item => {
        //        if (item.promoterLeaderId === this.leader.accId) {
        //          this.leaderPromoterList.push(item);
        //        }
        //      });
        //
        //      this.total = this.leaderPromoterList.length;
        //    }
      });
    },
    getStats() {
      this.leaderPromoterListActive = [];
      this.leaderPromoterListFullTime = [];
      this.leaderPromoterListPartTime = [];
      this.leaderPromoterListTablet = [];
      this.leaderPromoterListMBG = [];

      this.uniqueLeaderPromoterList =  [...new Set(this.leaderPromoterList.map(({ accId }) => accId))];

      this.leaderPromoterListActive = this.leaderPromoterList.filter(list => {
        if (list.bEnabled) {
          return list;
        }
      });
      this.leaderPromoterListActiveTotal = [...new Set(this.leaderPromoterListActive.map(({ accId }) => accId))];

      this.leaderPromoterListFullTime = this.leaderPromoterList.filter(list => {
        if (list.promoterTypeId === 4699) {
          return list;
        }
      });
      this.leaderPromoterListFullTimeTotal = [...new Set(this.leaderPromoterListFullTime.map(({ accId }) => accId))];

      this.leaderPromoterListPartTime = this.leaderPromoterList.filter(list => {
        if (list.promoterTypeId === 4700) {
          return list;
        }
      });
      this.leaderPromoterListPartTimeTotal = [...new Set(this.leaderPromoterListPartTime.map(({ accId }) => accId))];

      this.leaderPromoterListTablet = this.leaderPromoterList.filter(list => {
        if (list.promoterTypeId === 4793) {
          return list;
        }
      });
      this.leaderPromoterListTabletTotal = [...new Set(this.leaderPromoterListTablet.map(({ accId }) => accId))];

      const MBGTypeCode = process.env.NODE_ENV === "production" ? 5154 : 5153
      this.leaderPromoterListMBG = this.leaderPromoterList.filter(list => {
        if (list.promoterTypeId === MBGTypeCode) {
          return list;
        }
      });
      this.leaderPromoterListMBGTotal = [...new Set(this.leaderPromoterListMBG.map(({ accId }) => accId))];
    },
    filterList(list) {
      this.normal = false;
      this.activeList = false;
      this.fullTimeList = false;
      this.partTimeList = false;
      this.tabletList = false;
      this.mbgList = false;

      if (list == "normal") this.normal = true;
      else if (list == "active") this.activeList = true;
      else if (list == "fullTime") this.fullTimeList = true;
      else if (list == "partTime") this.partTimeList = true;
      else if (list == "tablet") this.tabletList = true;
      else if (list == "mbg") this.mbgList = true;
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPromoterList();
    },
    canFilterMBG() {
      if (this.isCapAdmin || this.isCap || this.isRegionManager) return true
      if (this.userCountryId == 25) { // PH
        if (this.isCountryAdmin || this.isCountryView || this.isGLNCountryAdmin) return true
      }
      return false
    },
  },
  created() {},
  mounted() {
    // this.closeModal();
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isCountryView = this.$store.getters.isCountryView;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isRegionManager = this.$store.getters.isRegionManager;
    this.userCountryId = this.$store.getters.getCountryId;
    this.getCountries();
    if(this.canView && this.currentAction === 'view'){
      this.getLeaderPromotersList();
    } else if(this.isCountryAdmin || this.isGLNCountryAdmin) {
      this.leader.countryId = this.$store.getters.getCountryId;
    }
    this.leader.joinedDate = convertDateFormat(this.leader.joinedDate, "YYYY-MM-DD");
    if(this.leader.staffId > 0 && !this.leaderSelected.changeRequest){
      this.checkForPendingApprovalFlag();
    }
  }
};
</script>
