import {
  getPromoterAttendanceDate,
  downloadPromoterAttendance,
  downloadIncentivePayableFile,
  downloadNormalIncentiveEntitlement,
  downloadBonusIncentiveEntitlement,
  downloadTargetIncentiveEntitlement,
  downloadDealerIncentiveEntitlement,
  downloadVisitation,
  downloadVisitationSummary,
  downloadVisitationDashboard,
  sendVisitationSummary,
  sendVisitation,
  downloadWearTear,
  sendWearTear,
  downloadHeartbeat,
  sendHeartbeat,
} from "@/api/reports";
import {
  PROMOTER_ATTENDANCE_DATE_REQUEST,
  PROMOTER_ATTENDANCE_DATE_SUCCESS,
  PROMOTER_ATTENDANCE_DATE_ERROR,
  DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST,
  DOWNLOAD_PROMOTER_ATTENDANCE_SUCCESS,
  DOWNLOAD_PROMOTER_ATTENDANCE_ERROR,
  DOWNLOAD_INCENTIVE_PAYABLE_REQUEST,
  DOWNLOAD_INCENTIVE_PAYABLE_SUCCESS,
  DOWNLOAD_INCENTIVE_PAYABLE_ERROR,
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST,
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_SUCCESS,
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_ERROR,
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST,
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_SUCCESS,
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_ERROR,
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_REQUEST,
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_SUCCESS,
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_ERROR,
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST,
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_SUCCESS,
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_ERROR,
  DOWNLOAD_VISITATION_REQUEST,
  DOWNLOAD_VISITATION_SUCCESS,
  DOWNLOAD_VISITATION_ERROR,
  DOWNLOAD_VISITATION_SUMMARY_REQUEST,
  DOWNLOAD_VISITATION_DASHBOARD_REQUEST,
  SEND_VISITATION_SUMMARY_REQUEST,
  SEND_VISITATION_REQUEST,
  SEND_VISITATION_SUCCESS,
  SEND_VISITATION_ERROR,
  DOWNLOAD_WEARTEAR_REQUEST,
  DOWNLOAD_WEARTEAR_SUCCESS,
  DOWNLOAD_WEARTEAR_ERROR,
  SEND_WEARTEAR_REQUEST,
  SEND_WEARTEAR_SUCCESS,
  SEND_WEARTEAR_ERROR,
  DOWNLOAD_HEARTBEAT_REQUEST,
  DOWNLOAD_HEARTBEAT_SUCCESS,
  DOWNLOAD_HEARTBEAT_ERROR,
  SEND_HEARTBEAT_REQUEST,
  SEND_HEARTBEAT_SUCCESS,
  SEND_HEARTBEAT_ERROR,
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  attendance: [],
  status: "",
  incentivesD: [],
  hasLoadedOnce: false,
  normalInceEnt: [],
  bonusInceEnt: [],
  targetInceEnt: [],
  dealerInceEnt: [],
  visitationData: [],
};

const getters = {
  getAttendance: state => state.attendance,
  getNormalEntitlement: state => state.normalInceEnt,
  getBonusEntitlement: state => state.bonusInceEnt,
  getTargetEntitlement: state => state.targetInceEnt,
  getDealerEntitlement: state => state.dealerInceEnt
};

const actions = {
  [PROMOTER_ATTENDANCE_DATE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_ATTENDANCE_DATE_REQUEST);
      getPromoterAttendanceDate(postData)
          .then(resp => {
            commit(PROMOTER_ATTENDANCE_DATE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(PROMOTER_ATTENDANCE_DATE_ERROR, err);
            reject(err);
          });
    });
  },
  [DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST);
      downloadPromoterAttendance(postData)
        .then(resp => {
          commit(DOWNLOAD_PROMOTER_ATTENDANCE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_PROMOTER_ATTENDANCE_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_INCENTIVE_PAYABLE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_INCENTIVE_PAYABLE_REQUEST);
      downloadIncentivePayableFile(postData)
        .then(resp => {
          commit(DOWNLOAD_INCENTIVE_PAYABLE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_INCENTIVE_PAYABLE_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST);
      downloadNormalIncentiveEntitlement(postData)
        .then(resp => {
          commit(DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST);
      downloadBonusIncentiveEntitlement(postData)
        .then(resp => {
          commit(DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_REQUEST);
      downloadTargetIncentiveEntitlement(postData)
        .then(resp => {
          commit(DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST);
      downloadDealerIncentiveEntitlement(postData)
        .then(resp => {
          commit(DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_VISITATION_REQUEST);
      downloadVisitation(postData)
        .then(resp => {
          commit(DOWNLOAD_VISITATION_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_VISITATION_ERROR, err);
          reject(err);
        });
    });
  },
  [SEND_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SEND_VISITATION_REQUEST);
      sendVisitation(postData)
        .then(resp => {
          commit(SEND_VISITATION_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SEND_VISITATION_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_VISITATION_SUMMARY_REQUEST]: (_, postData) => {
    return new Promise((resolve, reject) => {
      downloadVisitationSummary(postData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_VISITATION_DASHBOARD_REQUEST]: (_, postData) => {
    return new Promise((resolve, reject) => {
      downloadVisitationDashboard(postData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [SEND_VISITATION_SUMMARY_REQUEST]: (_, postData) => {
    return new Promise((resolve, reject) => {
      sendVisitationSummary(postData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_WEARTEAR_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_WEARTEAR_REQUEST);
      downloadWearTear(postData)
        .then(resp => {
          commit(DOWNLOAD_WEARTEAR_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_WEARTEAR_ERROR, err);
          reject(err);
        });
    });
  },
  [SEND_WEARTEAR_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SEND_WEARTEAR_REQUEST);
      sendWearTear(postData)
        .then(resp => {
          commit(SEND_WEARTEAR_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SEND_WEARTEAR_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_HEARTBEAT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_HEARTBEAT_REQUEST);
      downloadHeartbeat(postData)
        .then(resp => {
          commit(DOWNLOAD_HEARTBEAT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_HEARTBEAT_ERROR, err);
          reject(err);
        });
    });
  },
  [SEND_HEARTBEAT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SEND_HEARTBEAT_REQUEST);
      sendHeartbeat(postData)
        .then(resp => {
          commit(SEND_HEARTBEAT_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SEND_HEARTBEAT_ERROR, err);
          reject(err);
        });
    });
  },
};

const mutations = {
  PROMOTER_ATTENDANCE_DATE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_ATTENDANCE_DATE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      attendance: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_ATTENDANCE_DATE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      attendance: [],
      hasLoadedOnce: true
    });
  },

  DOWNLOAD_PROMOTER_ATTENDANCE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_PROMOTER_ATTENDANCE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      attendance: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_PROMOTER_ATTENDANCE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      attendance: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_INCENTIVE_PAYABLE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_INCENTIVE_PAYABLE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      incentivesD: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_INCENTIVE_PAYABLE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      incentivesD: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      normalInceEnt: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_NORMAL_INCENTIVE_ENTITLEMENT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      normalInceEnt: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      bonusInceEnt: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_BONUS_INCENTIVE_ENTITLEMENT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      bonusInceEnt: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      targetInceEnt: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_TARGET_INCENTIVE_ENTITLEMENT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      targetInceEnt: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      dealerInceEnt: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_DEALER_INCENTIVE_ENTITLEMENT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      dealerInceEnt: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_VISITATION_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      visitationData: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      visitationData: [],
      hasLoadedOnce: true
    });
  },
  SEND_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SEND_VISITATION_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  SEND_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_WEARTEAR_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_WEARTEAR_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      visitationData: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_WEARTEAR_ERROR: state => {
    Object.assign(state, {
      status: "error",
      visitationData: [],
      hasLoadedOnce: true
    });
  },
  SEND_WEARTEAR_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SEND_WEARTEAR_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  SEND_WEARTEAR_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_HEARTBEAT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_HEARTBEAT_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_HEARTBEAT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  SEND_HEARTBEAT_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SEND_HEARTBEAT_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  SEND_HEARTBEAT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
