<template>

  <!-- GROUP PHOTO  -->
  <span class="float-left">
    <small><b>{{ index == 0 ? 'Photo' : `&nbsp;` }}</b></small>
    <div v-viewer="options" class="media-img-container mr-5">
      <img class="img-fluid media-img square-img" :alt="photo.text"
        :src="photo.response ? photo.response : defaultImage"
        :data-source="photo.response ? photo.response : defaultImage" @error="replaceImageByDefault" 
      />
    </div>
    <small class="text-gray">{{ photo.text }}</small>
  </span>
  <!-- /GROUP PHOTO  -->

</template>

<script>
import default_image from "../../assets/default_image.jpg";
import "viewerjs/dist/viewer.css";

export default {
  name: "VisitationGroupPhoto",
  props: {
		photo: { type: Object, default: () => {}},
    index: { type: Number, default: 0 }
	},
	computed: {},
  data() {
    return {
      isLoading: false,
      defaultImage: default_image,
      options: {
        toolbar: false,
        tooltip: true,
        movable: false,
        url: "data-source",
        focus: false,
      },
    };
  },
	watch: {},
	methods: {
		replaceImageByDefault: function(e) {
			e.target.src = default_image;
		}
  }

};
</script>