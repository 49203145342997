<template>
  <div v-if="!isTopDealerComponentLoading">
    <div class="mt-4 ml-2 mb-4 d-inline-flex justify-content-between w-100">
      <span v-if="isCap || isCapAdmin" class="mt-auto mb-auto">
        <strong>{{ chart.country }}</strong>
      </span>
      <div class="position-relative d-inline-flex" :class="!isCap && !isCapAdmin && 'justify-content-end w-100'">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-4"
          @click="exportChart($event, link)"
        >
          <i class="fa fa-download"></i>
        </a>
        <div class="mr-2 enlarge-icon" @click="enlargeChart(chart, 'stackedBarChart')">
          <span> &#10070; </span>
        </div>

      </div>
    </div>
    <stacked-bar-chart
      :id="chartId"
      :got-lower-section="true"
      :data.sync="chart.data"
      title=""
      chart-left="160"
      :lower-section-id="lowerSection"
      :max-characters="18"
    ></stacked-bar-chart>
    <div :id="lowerSection"></div>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(242, 142, 43);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">To Go</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(154, 205, 50);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Over Achievement</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import stackedbarChart from "../dashboard/stacked-bar-chart";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
  },
  props: {
    chart: {type: Object, default: () => {}},
    isTopDealerComponentLoading: {type: Boolean, default: false},
    enlargeChart: {type: Function, default: () => {}},
    exportChart: {type: Function, default: () => {}},
    link: {type: String, default: ""},
    isLinkLoading: {type: Boolean, default: false}
  },
  computed: {
    chartId: function() {
      return "actual" + this.chart.countryCode + "topdealersLowerSection"
    },
    lowerSection: function() {
      return this.chart.countryCode + "topdealersLowerSection";
    }
  },
  data(){
    return{
      isCap: false,
      isCapAdmin: false
    }
  },
  mounted(){
    this.isCap = this.$store.getters.isCap;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
  }
}
</script>