<template>
  <div id="dealer_inventory">
    <breadcrumb title="Dashboard" subtitle="Dealer Sell Thru Inventory" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="mb-3 w-100 d-inline-flex">
                <div class="col-lg-3">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                    ref="country"
                    v-model="filters.countrySelected"
                    :searchable="false"
                    :options="countryOptions"
                    label="name"
                    track-by="value"
                    :loading="!countryOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getDealerStockList(true)"
                  ></Multiselect>
                </div>
                <div class="col-lg-3">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                    ref="year"
                    v-model="filters.yearSelected"
                    :searchable="false"
                    :options="yearOptions"
                    label="name"
                    track-by="value"
                    :loading="!yearOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getDealerStockList"
                  ></Multiselect>
                </div>
                <div class="col-lg-3">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                    ref="quarter"
                    v-model="filters.quarterSelected"
                    :searchable="false"
                    :options="quarterOptions"
                    label="name"
                    track-by="value"
                    :loading="!quarterOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getDealerStockList"
                  ></Multiselect>
                </div>
                <div id="week-multiselect" class="col-lg-3">
                  <label class="text-label">Week</label>
                  <Multiselect
                    ref="week"
                    v-model="filters.weekSelected"
                    :searchable="false"
                    :options="weekOptions"
                    label="name"
                    track-by="value"
                    :loading="!weekOptions"
                    :allow-empty="false"
                    :show-labels="false"
                    @input="getDealerStockList"
                  >
                    <template
                      slot="option"
                      slot-scope="props"
                    >
                      <span :key="props.option.id"
                        class="week-option"
                      >
                        <b v-if="latestCountryWeek && weekOptions.find(item => item.value === latestCountryWeek.week).id >= props.option.id">
                          {{ props.option.name }} - <span class="text-muted">uploaded</span>
                        </b> 
                        <span v-else>{{ props.option.name }} </span>
                      </span>
                    </template>
                  </Multiselect>
                </div>
              </div>
              <div class="col-12 d-flex align-items-center">
                <div class="w-100">
                  <label class="text-label">Dealer</label>
                  <Multiselect
                    v-model="filters.dealerSelected"
                    v-validate="'required'"
                    name="Dealer"
                    :open-direction="'bottom'"
                    :loading="dealerList.length === 0"
                    :disabled="!dealerList"
                    track-by="id"
                    label="dealerName"
                    placeholder="Select Dealer"
                    :options="dealerList"
                    :searchable="true"
                    :allow-empty="false"
                    @input="getDealerStockList"
                  ></Multiselect>
                  <!-- <span
                    v-if="dealerListErrors.dealer"
                    class="invalid-field mt-2"
                  >
                    {{ dealerListErrors.dealer }}
                  </span> -->
                </div>
                <!-- <div class="ml-4">
                  <button
                    :disabled="
                      dealerSelected.length <= 0 ||
                        dealerStockDownloadContent.length <= 0
                    "
                    type="button"
                    class="btn btn-xs btn-primary"
                    @click="downloadDealerList"
                  >
                    Download
                  </button>
                </div> -->
              </div>
              <div class="col-lg-12 d-flex mt-4 mb-2 justify-content-end position-relative">
                <a
                  :href="exportLink" 
                  target="_blank"
                  :class="{disabled: isDataExportLoading}"
                  style="line-height: 3rem;"
                  class="btn btn-sm btn-primary"
                >
                  <i class="fa fa-file-excel-o"></i>
                  <span v-text="$ml.get('export_to_excel')"></span>
                  <div 
                    v-if="isDataExportLoading" 
                    class="loading ml-3" 
                    style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                  >
                  </div>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <VueBootstrap4Table
                  :classes="classes"
                  :rows="dealerStockList"
                  :columns="columns"
                  :config="config"
                  :total-rows="dealerStockList.length"
                  @on-change-query="getDealerStockList"
                >
                  <template slot="sort-asc-icon">
                    <i class="mdi mdi-sort-ascending"></i>
                  </template>
                  <template slot="sort-desc-icon">
                    <i class="mdi mdi-sort-descending"></i>
                  </template>
                  <template slot="no-sort-icon">
                    <i class="mdi mdi-sort-variant"></i>
                  </template>
                  <template slot="empty-results">
                    <div
                      v-if="!isDealerInventoryLoading"
                      class="bold-text"
                    >No Dealer Inventory Data Found!</div>
                    <div
                      v-if="isDealerInventoryLoading"
                      class="loading mt-3"
                    ></div>
                  </template>

                  <!-- <template slot="edit" slot-scope="props">
                    <div>
                      <button
                        :disabled="!props.row.productSkuId"
                        class="btn btn-warning btn-xs"
                        @click.prevent="
                          inventoryHistory(props.row.productSkuId)
                        "
                      >
                        View
                      </button>
                    </div>
                  </template> -->
                </VueBootstrap4Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
// import { BModal } from "bootstrap-vue";
// import XLSX from "xlsx";
// import { saveAs } from "file-saver";
import {
  DEALER_LIST_WITH_PARAMS_REQUEST,
  DEALER_SELL_THRU_LIST_REQUEST,
  EXPORT_DEALER_SELL_THRU_DATA_REQUEST,
  GET_SELL_THRU_LATEST_WEEKS_REQUEST
  // DEALER_STOCK_LIST_MONTHLY_REQUEST,
  // DEALER_STOCK_LIST_DOWNLOAD_REQUEST
} from "../../store/modules/dealer/actions";
// import moment from "moment";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';

export default {
  name: "DealerSellInventory",
  components: {
    breadcrumb,
    Multiselect,
    VueBootstrap4Table,
    // BModal
  },
  computed:{
    latestCountryWeek: function(){
      return this.latestWeeks.find(el => el.countryCode === this.filters.countrySelected.value);
    }
  },
  data() {
    return {
      isDataExportLoading: false,
      exportLink: "",
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "country",
          label: "Country",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "lppCode",
          label: "Dealer(MDM Code)",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        // {
        //   name: "productFamily",
        //   label: "Product Family",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left"
        // },
        {
          name: "mtmNo",
          label: "MTM",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "week",
          label: "Week",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "openingBalance",
          label: "Opening Stock",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "sellThru",
          label: "Sell Thru",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "salesOut",
          label: "Sell Out",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "stockOnHand",
          label: "SOH",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      countryOptions: [],
      countries: [],
      yearOptions: [],
      quarterOptions: [],
      weekOptions: [],
      countryIdSelected: null,
      filters: {
        countrySelected: {},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        weekSelected: { id: 0, name: "Week 01", value: "W1" },
        dealerSelected: null,
      },
      dealerList: [],
      dealerStockList: [],
      isDealerInventoryLoading: false,
      latestWeeks: [],
    };
  },
  methods: {
    async getDealers() {
      let pdata = {};
      this.dealerList = [];
      this.filters.dealerSelected = null;
      await this.getCountryId();
      pdata.countryId = this.countryIdSelected;
      const res = await this.$store.dispatch(DEALER_LIST_WITH_PARAMS_REQUEST, pdata);
      if (res.resultCode == 0) {
        this.dealerList.push({id: 0, dealerName: "Select All"});
        res.content.forEach(el => this.dealerList.push(el));
      }
    },
    getLatestWeeks(){
      this.$store.dispatch(GET_SELL_THRU_LATEST_WEEKS_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.latestWeeks = res.content;
        }
      });
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          this.getDealers();
        }
      });
    },
    getCountryId() {
      this.countries.filter(country => {
        if(country.code === this.filters.countrySelected.value) {
          return this.countryIdSelected = country.id;
        }
      });
    },
    getExportLink(){
      this.isDataExportLoading = true;
      let pdata = {
        ccf: this.filters.countrySelected.value,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        wf: this.filters.weekSelected.value,
      };
      if(this.filters.dealerSelected !== null && this.filters.dealerSelected.id !== 0){
        pdata.df = this.filters.dealerSelected.id
      }
      this.$store.dispatch(EXPORT_DEALER_SELL_THRU_DATA_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.isDataExportLoading = false;
          this.exportLink = res.content.link;
        } else {
          this.isDataExportLoading = false;
        }
      }).catch(() => {
        this.isDataExportLoading = false;
      });
    },
    async getDealerStockList(dealers) {
      this.isDealerInventoryLoading = true;
      if(dealers){
        this.getDealers();
      }
      let pdata = {
        ccf: this.filters.countrySelected.value,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        wf: this.filters.weekSelected.value,
      };
      if(this.filters.dealerSelected !== null && this.filters.dealerSelected.id !== 0){
        pdata.df = this.filters.dealerSelected.id
      }
      const res = await this.$store.dispatch(DEALER_SELL_THRU_LIST_REQUEST, pdata);
      if (res.resultCode == 0) {
        this.dealerStockList = res.content;
        this.isDealerInventoryLoading = false;
        this.getExportLink();
      }
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown.filter(el => el.value !== "CAP");
        this.quarterOptions = await res.content.quarterDropdown;
        this.weekOptions = [
          {id: 0, name: "Week 01", value: "W1"},
          {id: 1, name: "Week 02", value: "W2"},
          {id: 2, name: "Week 03", value: "W3"},
          {id: 3, name: "Week 04", value: "W4"},
          {id: 4, name: "Week 05", value: "W5"},
          {id: 5, name: "Week 06", value: "W6"},
          {id: 6, name: "Week 07", value: "W7"},
          {id: 7, name: "Week 08", value: "W8"},
          {id: 8, name: "Week 09", value: "W9"},
          {id: 9, name: "Week 10", value: "W10"},
          {id: 10, name: "Week 11", value: "W11"},
          {id: 11, name: "Week 12", value: "W12"},
          {id: 12, name: "Week 13", value: "W13"},
        ];
        this.filters.countrySelected = this.countryOptions.find(el => el.value === this.$store.getters.getCountryCode);
        this.getDealerStockList();
      }
    },
  },
  mounted() {
    this.getLatestWeeks();
    this.getCountries();
    this.getFilters();
  }
};
</script>

<style></style>
