<template>
  <div>
    <div v-if="type === 'model'" class="col-lg-12 d-inline-flex align-items-center">
      <div class="square-red mr-4"></div> 
      <h6 class="mb-0"> - not matched SRP</h6>
      <br><br>
    </div>
    <VueBootstrap4Table
      :id="type === 'model' && 'model-show-table'"
      :classes="classes"
      :rows="productList"
      :columns="columns"
      :loading="isDataLoading"
      :config="config"
      :total-rows="total"
      :show-loader="true"
    >
      <template slot="sort-asc-icon">
        <i class="mdi mdi-sort-ascending"></i>
      </template>
      <template slot="sort-desc-icon">
        <i class="mdi mdi-sort-descending"></i>
      </template>
      <template slot="no-sort-icon">
        <i class="mdi mdi-sort-variant"></i>
      </template>
      <template v-if="type === 'model'" slot="mtm" slot-scope="props">
        <div v-for="(mtm, index) in props.row.mtm" :key="index" :style="!mtm.valid && 'background: rgb(255 224 222)'">
          <span>{{ mtm.mtm }}</span>
          <br>
        </div>
      </template>
      <template v-if="type === 'model'" slot="srp" slot-scope="props">
        <div v-for="(srp, index) in props.row.srp" :key="index" :style="!srp.valid && 'background: rgb(255 224 222)'">
          <span>{{ srp.srp }}</span>
          <br>
        </div>
      </template>
      <template slot="empty-results">
        <div
          v-if="!isDataLoading"
          class="bold-text"
        >No MTM or Model Found!</div>
        <div
          v-if="isDataLoading"
          class="loading mt-3"
        ></div>
      </template>
    </VueBootstrap4Table>
  </div>
</template>
<script>
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { INCENTIVE_PRODUCT_CATEGORY_BY_PRICE } from "../../store/modules/incentive/action";
export default {
  name: "IncentiveShowMTMModelModal",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
  },
  props: {
    list: {type: Array, default: () => []},
    countryId: {type: Number, default: 0},
    type: {type: String, default: ""},
    min: {type: Number, default: 0},
    max: {type: Number, default: 0}
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: this.rowClasses(),
        cell: {}
      },
      columns: [
        {
          name: "mtm",
          label: "MTM",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "model",
          label: "Model",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "productGroup",
          label: "Group",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "productCategory",
          label: "Category",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "srp",
          label: "SRP",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }
      ],
      total: 0,
      productList: [],
      isDataLoading: false
    }
  }, 
  methods: {
    rowClasses(){
      let type = this.type;
      let min = this.min;
      let max = this.max;
      let obj = {};
      obj["highlight-light-red"] = 
        function checkValid(row){
          if( type === 'model'){
            if(min || max){
              if(!min && row.srp.filter(el => el.srp > max).length === row.srp.length){
                return true;
              } else if(!max && row.srp.filter(el => el.srp < min).length === row.srp.length){
                return true;
              } else if(min && max && (row.srp.filter(el => el.srp > max).length === row.srp.length || row.srp.filter(el => el.srp < min).length === row.srp.length)){
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        }
      return obj;
    },
    getProductList(){
      this.isDataLoading = true;
      let pdata = {};
      pdata.countryId = this.countryId;
      if(this.type === 'mtm'){
        pdata.mtmList = this.list;
      } else if(this.type === 'model'){
        pdata.modelList = this.list;
      }
      // pdata.minPrice = this.min;
      // pdata.maxPrice = this.max;
      this.$store.dispatch(INCENTIVE_PRODUCT_CATEGORY_BY_PRICE, pdata).then(res => {
        if(res.content && res.resultCode === 0){
          if(res.content.length === 0){
            this.productList = this.type === 'mtm' ? this.list.map(el => { let item = {}; item.mtm = el; return item}) : this.list.map(el => { let item = {}; item.model = el; return item});
          } else {
            if(this.type === 'model'){
              let productList = Object.values(
                res.content.reduce( (c, e) => {
                  let accumulator = {...c};
                  let currentValue = {...e};
                  if (!accumulator[currentValue.model]) {
                    accumulator[currentValue.model] = currentValue;
                    let valid = true;
                    if(this.min || this.max){
                      if(!this.min && currentValue["srp"] > this.max){
                        valid = false;
                      } else if(!this.max && currentValue["srp"] < this.min){
                        valid = false;
                      } else if(this.min && this.max && (currentValue["srp"] > this.max || currentValue["srp"] < this.min)){
                        valid = false;
                      } else {
                        valid = true;
                      }
                    } else {
                      valid = true;
                    }
                    accumulator[currentValue.model]["srp"] = [{valid: valid, srp: currentValue["srp"]}];
                    accumulator[currentValue.model]["mtm"] = [{valid: valid, mtm: currentValue["mtm"]}];
                  } else {
                    let valid = true;
                    if(this.min || this.max){
                      if(!this.min && currentValue["srp"] > this.max){
                        valid = false;
                      } else if(!this.max && currentValue["srp"] < this.min){
                        valid = false;
                      } else if(this.min && this.max && (currentValue["srp"] > this.max || currentValue["srp"] < this.min)){
                        valid = false;
                      } else {
                        valid = true;
                      }
                    } else {
                      valid = true;
                    }
                    accumulator[currentValue.model]["mtm"].push({valid: valid, mtm: currentValue["mtm"]});
                    accumulator[currentValue.model]["srp"].push({valid: valid, srp: currentValue["srp"]});
                  }
                  return accumulator
                }, {})
              );
              this.productList = productList;
            } else if(this.type === 'mtm'){
              this.productList = res.content;
            }
          }
          this.isDataLoading = false;
        }
      });
    }
  },
  mounted(){
    if(this.list.length !== 0){
      this.getProductList();
    } else {
      this.productList = [];
    }
  }
}
</script>
<style lang="scss" scoped>
  .square-red{
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
    background: rgb(255 224 222);
  }

</style>