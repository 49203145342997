import store from "@/store";
import DashboardPage from "@/views/dashboard/dashboard.vue";
import RegionalPage from "@/views/dashboard/regional.vue";
import CountryPage from "@/views/dashboard/country.vue";
import PromoterLeaderDashboard from "@/views/dashboard/promoter_leader.vue";
import AgencyDashboard from "@/views/dashboard/agency.vue";
import DealerDashboard from "@/views/dashboard/dealer.vue";
import OutletDashboard from "@/views/dashboard/outlet.vue";
import StateManagerDashboard from "@/views/dashboard/state_manager.vue";
import RegionalManagerDashboard from "@/views/dashboard/region_manager.vue";
import MerchandiserDashboard from "@/views/dashboard/merchandiser.vue";

import NormalIncentivePage from "@/views/incentiveM/normal.vue";
import NormalIncentiveAddPage from "@/views/incentiveM/normal_add.vue";
import NormalIncentiveEditPage from "@/views/incentiveM/normal_edit.vue";

import PromoterNormalIncentive from "@/views/incentiveM/normal_promoter.vue";
import PromoterNormalIncentiveAdd from "@/views/incentiveM/normal_promoter_add.vue";
import PromoterNormalIncentiveEdit from "@/views/incentiveM/normal_promoter_edit.vue";

import SalespersonNormalIncentive from "@/views/incentiveM/normal_salesperson.vue";
import SalespersonNormalIncentiveAdd from "@/views/incentiveM/normal_salesperson_add.vue";

import BonusIncentivePage from "@/views/incentiveM/bonus.vue";
import BonusIncentiveAddPage from "@/views/incentiveM/bonus_add.vue";
import BonusIncentiveEditPage from "@/views/incentiveM/bonus_edit.vue";

import TargetIncentivePage from "@/views/incentiveM/target.vue";
import TargetIncentiveAddPage from "@/views/incentiveM/target_add.vue";
import TargetIncentiveEditPage from "@/views/incentiveM/target_edit.vue";
import GenerateIncentivePayable from "@/views/incentiveM/incentive_payable.vue";
import GeneratePromoterIncentivePayable from "@/views/incentiveM/incentive_payable_promoter.vue";

import PromoterIncentivePage from "@/views/incentiveM/promoter.vue";
import PromoterIncentiveAddPage from "@/views/incentiveM/promoter_add.vue";
import PromoterIncentiveEditPage from "@/views/incentiveM/promoter_edit.vue";

import UploadReceipt from "@/views/incentiveM/upload_receipt.vue";

import DealerListPage from "@/views/dealer/dealer_list.vue";
import DealerIncentivePage from "@/views/dealer/dealer_incentive.vue";
import DealerSellIn from "@/views/dealer/dealer_sell_in.vue";
import DealerSellOut from "@/views/dealer/dealer_sell_out.vue";
import DealerInventory from "@/views/dealer/dealer_inventory.vue";
import DealerIncentiveEntitlement from "@/views/dealer/dealer_incentive_entitlement.vue";

import NewsListPage from "@/views/news/news_list.vue";

import OutletPicPage from "@/views/outlets/pic_details.vue";
import OutletPage from "@/views/outlets/outlet_list.vue";

import ProductsPage from "@/views/products/product_list.vue";

import SalespersonPendingApprovalPage from "@/views/staff/salesperson_pending_approval.vue";
// import PendingPromoterPage from "@/views/staff/promoter_pending_approval.vue";
import ChangeRequestsApprovalPage from "@/views/staff/change_requests_approval.vue";

import MessagePage from "@/views/message";

import DownloadSalesDataPage from "@/views/download/sales_data.vue";
import DownloadPromoterAttPage from "@/views/download/promoter_attendance.vue";
import DownloadIncentivePayable from "@/views/download/incentive_payable.vue";
import DownloadDealerIncentiveEntitlementPage from "@/views/download/dealer_incentive_entitlement.vue";

import BulkSaleUpload from "@/views/sales/bulk_sale_upload.vue";
import VerifySerial from "@/views/sales/verify_serial.vue";
import SalesDataIndex from "@/views/sales/sales_data_index.vue";

import PromoterLeaders from "@/views/staff/promoter_leaders.vue";
import PromoterStaff from "@/views/staff/promoters.vue";
import SalespersonStaff from "@/views/staff/salespersons.vue";
import StateManagers from "@/views/staff/state_managers.vue";
import RegionalManagers from "@/views/staff/regional_managers.vue";

import TargetCountryData from "@/views/targets/country.vue";
import TargetPromoterData from "@/views/targets/promoter.vue";
// import TargetPromoterLeaderData from "@/views/targets/promoter_leader.vue";
import SubRegionTarget from "@/views/targets/subregion.vue";
import DealerTarget from "@/views/targets/dealer.vue";

import DistributorPage from "@/views/distributor/index.vue";
import CheckSerialNumber from "@/views/sales/check_serial.vue";

import SKUProducts from "@/views/other/sku-products.vue";

import SettingsPage from "@/views/settings/index.vue";
import LockSerialNumber from "@/views/serialNo/lock_serial_number.vue";
import ExclusiveMTM from "@/views/mtm/mtm.vue";

import {
  ROLE_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_MARKETING,
  ROLE_COUNTRY_VIEW,
  ROLE_GLN_COUNTRY_ADMIN,
  ROLE_COUNTRY_TRAINER,
  ROLE_PROMOTER_LEADER,
  ROLE_CAP_ADMIN,
  ROLE_CAP_MARKETING,
  ROLE_CAP,
  ROLE_DEALER,
  ROLE_CHECKER,
  LAYOUT_ADMIN,
  ROLE_OUTLET_PIC,
  ROLE_AGENCY,
  ROLE_COUNTRY_AGENCY,
  ROLE_REGION_MANAGER,
  ROLE_STATE_MANAGER,
  ROLE_MERC_LEADER
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const dashboardRouter = {
  path: "/dashboard",
  component: DashboardPage,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
      ROLE_CAP_ADMIN,
      ROLE_CAP_MARKETING,
      ROLE_CAP,
      ROLE_COUNTRY_ADMIN,
      ROLE_COUNTRY_MARKETING,
      ROLE_COUNTRY_VIEW,
      ROLE_GLN_COUNTRY_ADMIN,
      ROLE_COUNTRY_TRAINER,
      ROLE_PROMOTER_LEADER,
      ROLE_DEALER,
      ROLE_OUTLET_PIC,
      ROLE_AGENCY,
      ROLE_COUNTRY_AGENCY,
      ROLE_REGION_MANAGER,
      ROLE_STATE_MANAGER
    ]
  },
  children: [
    {
      path: "",
      props: true,
      name: "Dashboard",
      component: store.getters.isPromoLeader
        ? PromoterLeaderDashboard
        : store.getters.isCountryAgency
        ? AgencyDashboard
        : store.getters.isDealer
        ? DealerDashboard
        : store.getters.isPicOutlet
        ? OutletDashboard
        : store.getters.isAgency
        ? DownloadSalesDataPage
        : store.getters.isCapAdmin
        ? DownloadSalesDataPage
        : (store.getters.isCap || store.getters.isCapMarketing)
        ? RegionalPage
        : store.getters.isStateManager
        ? StateManagerDashboard
        : store.getters.isRegionManager
        ? RegionalManagerDashboard
        : CountryPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_PROMOTER_LEADER,
          ROLE_DEALER,
          ROLE_OUTLET_PIC,
          ROLE_CHECKER,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      }
    },
    {
      path: "regional",
      name: "Regional Dashboard",
      component: RegionalPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_CAP_MARKETING]
      }
    },
    {
      path: "country",
      name: "Country Dashboard",
      component: CountryPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "merchandiser",
      name: "Merchandiser Dashboard",
      component: MerchandiserDashboard,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_MERC_LEADER]
      }
    },
    {
      path: "incentive/normal",
      name: "Normal Incentive",
      component: NormalIncentivePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/normal/add",
      name: "Add Normal Incentive",
      component: NormalIncentiveAddPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/normal/:type/:id",
      name: "Edit Normal Incentive",
      component: NormalIncentiveEditPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/promoter-normal",
      name: "Promoter Commission Schemes",
      component: PromoterNormalIncentive,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/salesperson-normal",
      name: "Salesperson Incentive",
      component: SalespersonNormalIncentive,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN
        ]
      }
    },
    {
      path: "incentive/salesperson-normal/add",
      name: "Add Salesperson/Dealer Incentive",
      component: SalespersonNormalIncentiveAdd,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN
        ]
      }
    },
    {
      path: "incentive/salesperson-normal/edit",
      name: "Edit Salesperson/Dealer Incentive",
      component: SalespersonNormalIncentiveAdd,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN
        ]
      }
    },
    {
      path: "incentive/promoter-normal/add",
      name: "Add Promoter Incentive",
      component: PromoterNormalIncentiveAdd,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/promoter-normal/:type/:id",
      name: "Edit Promoter Normal Incentive",
      component: PromoterNormalIncentiveEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/bonus",
      name: "Bonus Incentive",
      component: BonusIncentivePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/bonus/add",
      name: "Add Bonus Incentive",
      component: BonusIncentiveAddPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/bonus/edit/:id",
      name: "Edit Bonus Incentive",
      component: BonusIncentiveEditPage,
      beforeEnter: ifAuthenticated,
      props: true,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/target",
      name: "Target Incentive",
      component: TargetIncentivePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive/target/add",
      name: "Add Target Incentive",
      component: TargetIncentiveAddPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/target/edit/:id",
      name: "Edit Target Incentive ",
      component: TargetIncentiveEditPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/promoter",
      name: "Promoter Incentive",
      component: PromoterIncentivePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/promoter/add",
      name: "Add Promoter Incentive",
      component: PromoterIncentiveAddPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/promoter/edit/:id",
      name: "Edit Promoter Incentive",
      component: PromoterIncentiveEditPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "dealers",
      name: "Dealer Details",
      component: DealerListPage,
      beforeEnter: ifAuthenticated,
      props: (route) => ({ query: route.query.q }),
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },

    {
      path: "download/dealer-incentive-entitlement",
      name: "Download Dealer Incentive Entitlement",
      component: DownloadDealerIncentiveEntitlementPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_DEALER]
      }
    },
    {
      path: "dealer-incentive-entitlement",
      name: "Dealer Incentive Entitlement",
      component: DealerIncentiveEntitlement,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_DEALER]
      }
    },
    {
      path: "distributors",
      name: "Distributor Details",
      component: DistributorPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "incentive/dealer",
      name: "Dealer Incentive Management",
      component: DealerIncentivePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "dealer-sell-in",
      name: "Dealer Sell In",
      component: DealerSellIn,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_GLN_COUNTRY_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "dealer-sell-out",
      name: "Dealer Sell Out",
      component: DealerSellOut,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_DEALER
        ]
      }
    },
    {
      path: "dealer-inventory",
      name: "Dealer Inventory",
      component: DealerInventory,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "bulk-upload",
      name: "Bulk Upload Submission",
      component: BulkSaleUpload,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_DEALER]
      }
    },
    {
      path: "outlets/pic",
      name: "Outlet PIC Details",
      component: OutletPicPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_DEALER, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "outlets",
      name: "Outlet Details",
      component: OutletPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_DEALER,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_OUTLET_PIC,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_CAP_ADMIN,
          ROLE_CAP
        ]
      }
    },
    {
      path: "staff/promoter-leaders",
      name: "Promoter Supervisors",
      component: PromoterLeaders,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "staff/promoters",
      name: "Promoters",
      component: PromoterStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "staff/salespersons",
      name: "Salesperson",
      component: SalespersonStaff,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "staff/state-manager",
      name: "State Manager",
      component: StateManagers,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "incentive-payable-promoter",
      name: "Generate Promoter Commission Payable File",
      component: GeneratePromoterIncentivePayable,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_CAP,
          ROLE_CAP_ADMIN
        ]
      }
    },
    {
      path: "staff/regional-manager",
      name: "Regional Manager",
      component: RegionalManagers,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY
        ]
      }
    },
    {
      path: "salesperson-pending-approval",
      name: "Pending Approval",
      component: SalespersonPendingApprovalPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY
        ]
      }
    },
    // {
    //   path: "promoter-pending-approval",
    //   name: "Promoter Pending Approval",
    //   component: PendingPromoterPage,
    //   beforeEnter: ifAuthenticated,
    //   meta: {
    //     permission: [
    //       ROLE_ADMIN,
    //       ROLE_COUNTRY_ADMIN,
    //       ROLE_GLN_COUNTRY_ADMIN,
    //       ROLE_DEALER,
    //       ROLE_CAP_ADMIN,
    //       ROLE_CAP,
    //       ROLE_PROMOTER_LEADER
    //     ]
    //   }
    // },
    {
      path: "change-requests-approval",
      name: "Change Requests",
      component: ChangeRequestsApprovalPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_MERC_LEADER
        ]
      }
    },
    {
      path: "products",
      name: "Products",
      component: ProductsPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "targets/country",
      name: "Country Target",
      component: TargetCountryData,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "targets/promoter",
      name: "Promoter Target",
      component: TargetPromoterData,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN
        ]
      }
    },
    // {
    //   path: "targets/promoter-leader",
    //   name: "Promoter Supervisor Target",
    //   component: TargetPromoterLeaderData,
    //   beforeEnter: ifAuthenticated,
    //   meta: {
    //     permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
    //   }
    // },
    {
      path: "targets/subregions",
      name: "Sub Region Target",
      component: SubRegionTarget,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "targets/dealer",
      name: "Top Dealer Target",
      component: DealerTarget,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "sales-data",
      name: "Sales Validation",
      component: SalesDataIndex,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_OUTLET_PIC,
          ROLE_CHECKER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive-payable",
      name: "Generate Incentive Payable File",
      component: GenerateIncentivePayable,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "incentive-payable/upload-receipt",
      name: "Upload Receipt",
      component: UploadReceipt,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW]
      }
    },
    {
      path: "download/sales-data",
      name: "Sales Data",
      component: DownloadSalesDataPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "download/promoter-attendance",
      name: "Promoter Attendance",
      component: DownloadPromoterAttPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "downloads/incentive-payable",
      name: "Incentive Payable File",
      component: DownloadIncentivePayable,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER
        ]
      }
    },
    {
      path: "news",
      name: "News",
      component: NewsListPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_TRAINER,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "messages",
      name: "Message",
      component: MessagePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "data-management/verify-serial",
      name: "Unknown Serial Number",
      component: VerifySerial,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP, ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "check-serial",
      name: "Check Serial/IMEI Number",
      component: CheckSerialNumber,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_PROMOTER_LEADER,
          ROLE_AGENCY
        ]
      }
    },
    {
      path: "settings",
      name: "Mobile Application Settings",
      component: SettingsPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
    {
      path: "other/mtm",
      name: "MTM SRP",
      component: SKUProducts,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
          ROLE_COUNTRY_ADMIN, 
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN
        ]
      }
    },
    {
      path: "lock-serial",
      name: "Lock SERIAL Numbers",
      component: LockSerialNumber,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW]
      }
    },
    {
      path: "exclusive-mtm",
      name: "Exclusive MTM",
      component: ExclusiveMTM,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW, ROLE_GLN_COUNTRY_ADMIN]
      }
    },
  ],
  beforeEnter: ifAuthenticated
};

export default dashboardRouter;
