import {
  getPicListByDealerId,
  getOutletPicList,
  getOutletPICById,
  addOrUpdateOutletPic,
  getOutletList,
  getOutletsListWithDealer,
  getOutletWithPICList,
  getOutletById,
  addOrUpdateOutlet,
  outletBulkUpload,
  getDealerOutletList,
  getExportData,
  getAllDealerOutletsByRegion,
  getOutletChanges,
  getMerchandiserVisitByOutletId,
  getVisitationTaskById
} from "@/api/outlet";
import {
  GET_PIC_LIST_BY_DEALER_ID_REQUEST,
  GET_PIC_LIST_BY_DEALER_ID_SUCCESS,
  GET_PIC_LIST_BY_DEALER_ID_ERROR,
  OUTLET_PIC_LIST_REQUEST,
  OUTLET_PIC_LIST_ERROR,
  OUTLET_PIC_LIST_SUCCESS,
  OUTLET_PIC_POST_REQUEST,
  OUTLET_PIC_POST_SUCCESS,
  OUTLET_PIC_POST_ERROR,
  OUTLET_PIC_REQUEST,
  OUTLET_PIC_SUCCESS,
  OUTLET_PIC_ERROR,
  OUTLET_LIST_REQUEST,
  OUTLET_LIST_ERROR,
  OUTLET_LIST_SUCCESS,
  OUTLETS_LIST_WITH_DEALERS_REQUEST,
  OUTLETS_LIST_WITH_DEALERS_SUCCESS,
  OUTLETS_LIST_WITH_DEALERS_ERROR,
  OUTLET_POST_REQUEST,
  OUTLET_POST_SUCCESS,
  OUTLET_POST_ERROR,
  OUTLET_REQUEST,
  OUTLET_SUCCESS,
  OUTLET_ERROR,
  OUTLET_BULK_UPLOAD_REQUEST,
  OUTLET_BULK_UPLOAD_SUCCESS,
  OUTLET_BULK_UPLOAD_ERROR,
  OUTLET_WITH_PIC_LIST_REQUEST,
  OUTLET_WITH_PIC_LIST_SUCCESS,
  OUTLET_WITH_PIC_LIST_ERROR,
  DEALER_OUTLET_LIST_REQUEST,
  DEALER_OUTLET_LIST_SUCCESS,
  DEALER_OUTLET_LIST_ERROR,
  EXPORT_DEALER_OUTLET_DATA_REQUEST,
  EXPORT_DEALER_OUTLET_DATA_SUCCESS,
  EXPORT_DEALER_OUTLET_DATA_ERROR,
  ALL_DEALER_OUTLETS_BY_REGION_REQUEST,
  ALL_DEALER_OUTLETS_BY_REGION_SUCCESS,
  ALL_DEALER_OUTLETS_BY_REGION_ERROR,
  OUTLET_CHANGES_REQUEST,
  OUTLET_CHANGES_SUCCESS,
  OUTLET_CHANGES_ERROR,
  MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST,
  MERCHANDISER_VISIT_BY_OUTLET_ID_SUCCESS,
  MERCHANDISER_VISIT_BY_OUTLET_ID_ERROR,
  TASK_BY_VISITATION_ID_REQUEST,
  TASK_BY_VISITATION_ID_SUCCESS,
  TASK_BY_VISITATION_ID_ERROR
  ,
} from "./actions";

import { NOTIFICATION_ERROR } from "../ui/actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  outletpic: {},
  outletpics: [],
  outlet: {},
  outlets: [],
  status: "",
  hasLoadedOnce: false,
  visitation: {}
};

const getters = {
  getPicOutlet: state => state.outletpic,
  getPicOutlets: state => state.outletpics,
  getOutLets: state => state.outlets,
  getOutLet: state => state.outlet,
  getVisitation: state => state.visitation
};

const actions = {
  [GET_PIC_LIST_BY_DEALER_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PIC_LIST_BY_DEALER_ID_REQUEST);
      getPicListByDealerId(postData)
          .then(resp => {
            commit(GET_PIC_LIST_BY_DEALER_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_PIC_LIST_BY_DEALER_ID_ERROR, err);
            reject(err);
          });
    });
  },

  [OUTLET_PIC_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_PIC_LIST_REQUEST);
      getOutletPicList(postData)
        .then(resp => {
          commit(OUTLET_PIC_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_PIC_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_PIC_POST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_PIC_POST_REQUEST);
      addOrUpdateOutletPic(postData)
        .then(resp => {
          commit(OUTLET_PIC_POST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_PIC_POST_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_PIC_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_PIC_REQUEST);
      getOutletPICById(postData)
        .then(resp => {
          commit(OUTLET_PIC_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_PIC_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_LIST_REQUEST);
      getOutletList(postData)
        .then(resp => {
          commit(OUTLET_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLETS_LIST_WITH_DEALERS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLETS_LIST_WITH_DEALERS_REQUEST);
      getOutletsListWithDealer(postData)
          .then(resp => {
            commit(OUTLETS_LIST_WITH_DEALERS_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(OUTLETS_LIST_WITH_DEALERS_ERROR, err);
            reject(err);
          });
    });
  },

  [OUTLET_WITH_PIC_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_WITH_PIC_LIST_REQUEST);
      getOutletWithPICList(postData)
        .then(resp => {
          commit(OUTLET_WITH_PIC_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_WITH_PIC_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_POST_REQUEST]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_PIC_POST_REQUEST);
      addOrUpdateOutlet(postData)
        .then(resp => {
          if (resp.resultCode == -501) {
            dispatch(NOTIFICATION_ERROR, {
              message: resp.content.message,
            });
            throw(resp)
          }
          commit(OUTLET_POST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_POST_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_REQUEST);
      getOutletById(postData)
        .then(resp => {
          commit(OUTLET_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_BULK_UPLOAD_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_BULK_UPLOAD_REQUEST);
      outletBulkUpload(postData)
        .then(resp => {
          commit(OUTLET_BULK_UPLOAD_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_BULK_UPLOAD_ERROR, err);
          reject(err);
        });
    });
  },
  [OUTLET_CHANGES_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_CHANGES_REQUEST);
      getOutletChanges(postData)
        .then(resp => {
          commit(OUTLET_CHANGES_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(OUTLET_CHANGES_ERROR, err);
          reject(err);
        });
    });
  },
  [DEALER_OUTLET_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DEALER_OUTLET_LIST_REQUEST);
      getDealerOutletList(postData)
        .then(resp => {
          commit(DEALER_OUTLET_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DEALER_OUTLET_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [EXPORT_DEALER_OUTLET_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_DEALER_OUTLET_DATA_REQUEST);
      getExportData(postData)
        .then(resp => {
          commit(EXPORT_DEALER_OUTLET_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(EXPORT_DEALER_OUTLET_DATA_ERROR, err);
          reject(err);
        });
    });
  },
  [ALL_DEALER_OUTLETS_BY_REGION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ALL_DEALER_OUTLETS_BY_REGION_REQUEST);
      getAllDealerOutletsByRegion(postData)
        .then(resp => {
          commit(ALL_DEALER_OUTLETS_BY_REGION_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ALL_DEALER_OUTLETS_BY_REGION_ERROR, err);
          reject(err);
        });
    });
  },
  [MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST);
      getMerchandiserVisitByOutletId(postData)
          .then(resp => {
            commit(MERCHANDISER_VISIT_BY_OUTLET_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(MERCHANDISER_VISIT_BY_OUTLET_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [TASK_BY_VISITATION_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TASK_BY_VISITATION_ID_REQUEST);
      getVisitationTaskById(postData)
          .then(resp => {
            commit(TASK_BY_VISITATION_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(TASK_BY_VISITATION_ID_ERROR, err);
            reject(err);
          });
    });
  },
};

const mutations = {
  GET_PIC_LIST_BY_DEALER_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PIC_LIST_BY_DEALER_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      outletpics: data,
      hasLoadedOnce: true
    });
  },
  GET_PIC_LIST_BY_DEALER_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      dealers: [],
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_PIC_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      outletpics: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      dealers: [],
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_PIC_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      outletpic: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outletpic: {},
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_POST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_PIC_POST_ERROR: (state, data) => {
    Object.assign(state, {
      state: "",
      outletpic: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_PIC_POST_SUCCESS: state => {
    Object.assign(state, {
      status: "error",
      dealer: {},
      hasLoadedOnce: true
    });
  },
  OUTLET_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      outlets: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outlets: [],
      hasLoadedOnce: true
    });
  },

  OUTLETS_LIST_WITH_DEALERS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLETS_LIST_WITH_DEALERS_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      outlets: data,
      hasLoadedOnce: true
    });
  },
  OUTLETS_LIST_WITH_DEALERS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outlets: [],
      hasLoadedOnce: true
    });
  },
  OUTLET_WITH_PIC_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_WITH_PIC_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      outlets: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_WITH_PIC_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outlets: [],
      hasLoadedOnce: true
    });
  },
  OUTLET_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      outlet: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outlet: {},
      hasLoadedOnce: true
    });
  },
  OUTLET_POST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_POST_ERROR: (state, data) => {
    Object.assign(state, {
      state: "",
      outlet: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_POST_SUCCESS: state => {
    Object.assign(state, {
      status: "error",
      outlet: {},
      hasLoadedOnce: true
    });
  },
  OUTLET_BULK_UPLOAD_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_BULK_UPLOAD_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      outlet: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_BULK_UPLOAD_ERROR: state => {
    Object.assign(state, {
      status: "error",
      outlet: {},
      hasLoadedOnce: true
    });
  },
  OUTLET_CHANGES_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_CHANGES_SUCCESS: (state) => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  OUTLET_CHANGES_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DEALER_OUTLET_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DEALER_OUTLET_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  DEALER_OUTLET_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  EXPORT_DEALER_OUTLET_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_DEALER_OUTLET_DATA_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  EXPORT_DEALER_OUTLET_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  ALL_DEALER_OUTLETS_BY_REGION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ALL_DEALER_OUTLETS_BY_REGION_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  ALL_DEALER_OUTLETS_BY_REGION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  MERCHANDISER_VISIT_BY_OUTLET_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      visitation: data,
      hasLoadedOnce: true
    });
  },
  MERCHANDISER_VISIT_BY_OUTLET_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      visitation: {},
      hasLoadedOnce: true
    });
  },
  TASK_BY_VISITATION_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TASK_BY_VISITATION_ID_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  TASK_BY_VISITATION_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
