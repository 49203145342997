<template>
  <div id="dealer-dashboard">
    <regional-breadcrumb title="Dashboard" subtitle="Dealer Dashboard" />
    <div class="d-flex flex-column">
      <div class="row lnv-main-card">
        <div class="col-lg-12">
          <div class="card rounded-0">
            <div class="card-body">
              <b-tabs v-model="activeTab">
                <div
                  class="d-flex flex-wrap align-items-center justify-content-end mt-3 ml-3 mr-3 mb-1 "
                >
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div v-if="activeTab === 1" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="unit"
                      v-model="filters.unitSelected"
                      :searchable="false"
                      :options="unitOptions"
                      label="name"
                      track-by="value"
                      :loading="!unitOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="currency"
                      v-model="filters.currencySelected"
                      :searchable="false"
                      :options="currencyOptions"
                      label="name"
                      track-by="value"
                      :loading="!currencyOptions"
                      :allow-empty="false"
                      :disabled="!unitCheck || isCap"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>
                <div class="d-none d-xl-flex mt-3 ml-3 mr-3 mb-1 justify-content-center">
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalSalespersons : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Salespersons
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalPendingApproval : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Pending Approval
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        <span>$</span>
                        {{ abbreviateNumber(topRowData ? topRowData.totalSalesAmount : "--") }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Sales Amount
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalSalesUnit : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Sales Unit
                      </div>
                    </div>
                  </div>
                </div>
                <b-tab-item label="Year to Date">
                  <DealerYearToDate
                    v-if="activeTab === 0"
                    ref="dealer-year"
                    :filters.sync="filters"
                  ></DealerYearToDate>
                </b-tab-item>
                <b-tab-item label="Quarter">
                  <DealerQuarter
                    v-if="activeTab === 1"
                    ref="dealer-quarter"
                    :filters.sync="filters"
                  ></DealerQuarter>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import Multiselect from "vue-multiselect";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';
import { DASHBOARD_TOP_FILTERS_GET } from "../../store/modules/dashboard/actions";
import { DASHBOARD_DEALER_TOP_ROW_DATA_GET } from "@/store/modules/dashboard/actions";
import DealerQuarter from "./dealer_quarter";
import DealerYearToDate from "./dealer_year";
import { generalMixin } from "@/utils/generalMixin";

export default {
  name: "Dealer",
  components: {
    "regional-breadcrumb": breadcrumb,
    Multiselect,
    DealerQuarter,
    DealerYearToDate,
  },
  mixins: [generalMixin],
  computed: {
    unitCheck() {
      return this.filters.unitSelected.value == "amount";
    },
    isCap() {
      return this.filters.countrySelected.value == "CAP";
    }
  },
  data() {
    return {
      filters: {
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        unitSelected: { name: "Unit", value: "unit" },
        currencySelected: { name: "USD", value: "usd" },
      },
      topRowData: {},
      yearOptions: [],
      quarterOptions: [],
      unitOptions: [],
      currencyOptions: [],
      activeTab: 1
    };
  },
  watch: {
    activeTab: function(){
      this.getTopRowData();
    },
    filters: {
      deep: true,
      async handler() {
        this.getTopRowData();
      }
    }
  },
  methods: {
    abbreviateNumber(number) {
      if (number) {
        let SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        let tier = (Math.log10(number) / 3) | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        let suffix = SI_SYMBOL[tier];
        let scale = Math.pow(10, tier * 3);

        // scale the number
        let scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(2) + suffix;
      } else {
        return 0;
      }
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
        this.currencyOptions = await res.content.currencyDropdown;
        this.unitOptions = await res.content.typeDropdown;
      }
    },
    async getTopRowData() {
      let pdata = {};
      pdata.filters = this.filters;
      pdata.grouping = this.activeTab === 0 ? 'yearly' : 'quarterly';
      const res = await this.$store.dispatch(DASHBOARD_DEALER_TOP_ROW_DATA_GET, pdata);
      this.topRowData = res.content;
    },
  },
  mounted() {
    this.getFilters();
    this.getTopRowData();
  }
};
</script>
<style lang="scss"></style>
