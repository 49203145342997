export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_ERROR = "NEWS_LIST_ERROR";
export const NEWS_REQUEST = "NEWS_REQUEST";
export const NEWS_SUCCESS = "NEWS_SUCCESS";
export const NEWS_ERROR = "NEWS_ERROR";
export const NEWS_POST_REQUEST = "NEWS_POST_REQUEST";
export const NEWS_POST_SUCCESS = "NEWS_POST_SUCCESS";
export const NEWS_POST_ERROR = "NEWS_POST_ERROR";
export const NEWS_DELETE_REQUEST = "NEWS_DELETE_REQUEST";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_ERROR = "NEWS_DELETE_ERROR";
export const NEWS_IMAGE_UPLOAD_REQUEST = "NEWS_IMAGE_UPLOAD_REQUEST";
export const NEWS_IMAGE_UPLOAD_SUCCESS = "NEWS_IMAGE_UPLOAD_SUCCESS";
export const NEWS_IMAGE_UPLOAD_ERROR = "NEWS_IMAGE_UPLOAD_ERROR";
