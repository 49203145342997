var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"mobile-settings"}},[_c('breadcrumb',{attrs:{"title":"Dashboard","subtitle":"Mobile Application Settings"}}),_c('form',[_c('div',{staticClass:"row lnv-main-card"},_vm._l((_vm.settings.appSettingByRoleList),function(role,index){return _c('div',{key:index,staticClass:"col-xl-6"},[_c('div',{staticClass:"card forms-card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v(_vm._s(role.role))]),_c('div',{staticClass:"basic-form"},_vm._l((role.appSettingList),function(setting,index2){return _c('div',{key:index2,staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-6 col-form-label text-label"},[_vm._v(_vm._s(setting.setting))]),_c('div',{class:setting.code === 'AP13' ||
                      setting.code === 'AP12' ||
                      setting.code === 'AP13'
                      ? 'col-sm-5'
                      : 'col-sm-6'},[(
                      setting.code != 'AP11' &&
                        setting.code != 'AP12' &&
                        setting.code != 'AP13'
                    )?_c('div',{staticClass:"input-group"},[_c('label',{staticClass:"switch",attrs:{"for":setting.id}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(setting.bActive),expression:"setting.bActive"}],attrs:{"id":setting.id,"type":"checkbox"},domProps:{"checked":Array.isArray(setting.bActive)?_vm._i(setting.bActive,null)>-1:(setting.bActive)},on:{"change":[function($event){var $$a=setting.bActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(setting, "bActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(setting, "bActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(setting, "bActive", $$c)}},function($event){return _vm.updateSettings(setting)}]}}),_c('div',{staticClass:"slider round"})])]):_vm._e(),(setting.code === 'AP11')?_c('div',{staticClass:"input-group d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(setting.value),expression:"setting.value"}],staticClass:"form-control",attrs:{"id":setting.code,"type":"number","min":"0","value":"0","step":"1"},domProps:{"value":(setting.value)},on:{"change":function($event){return _vm.updateSettings(setting)},"input":function($event){if($event.target.composing)return;_vm.$set(setting, "value", $event.target.value)}}}),_c('div',{staticClass:"ml-3"},[_vm._v("days")])])]):_vm._e(),(setting.code === 'AP12' || setting.code === 'AP13')?_c('div',{staticClass:"input-group"},[_c('VueTimepicker',{attrs:{"id":setting.code,"format":"hh:mm A"},on:{"change":function($event){return _vm.updateSettings(setting)}},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}})],1):_vm._e()])])}),0)])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }