import request from "@/utils/request";
import { 
  API_ADMIN_CLEAN_CACHE, 
  API_ADMIN_SERIAL_REMATCH,
  API_ADMIN_REGISTRATION,
  API_SALES_PERSON_REGISTRATION, 
  API_SALESPERSON_CHECK,
  API_REGIONAL_ADMIN_REGISTRATION, 
  API_REGIONAL_REGISTRATION, 
  API_COUNTRY_HEAD_REGISTRATION,
  API_DEALER_REGISTRATION,
  API_PROMOTER_LEADER_REGISTRATION,
  API_PROMOTER_CHECK_REQUEST,
  API_PROMOTER_REGISTRATION,
  API_RESET_PASSWORD,
  API_RESET_PASSWORD_WEB,
  API_AGENCY_REGISTRATION,
  API_CHECKER_REGISTRATION
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

export async function adminCleanCache(postData) {
  const url = API_ADMIN_CLEAN_CACHE + postData.cache === "all" ? "/dashboard/v2/all" : postData.cache;
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function adminSerialRematch() {
  const url = API_ADMIN_SERIAL_REMATCH;
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function adminRegistrationSubmit(postData) {
  const url = API_ADMIN_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function salesPersonRegistrationSubmit(postData) {
  const url = API_SALES_PERSON_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function salespersonCheckRequest(postData) {
  const url = API_SALESPERSON_CHECK;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function regionalAdminRegistrationSubmit(postData) {
  const url = API_REGIONAL_ADMIN_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function regionalRegistrationSubmit(postData) {
  const url = API_REGIONAL_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function countryHeadRegistrationSubmit(postData) {
  const url = API_COUNTRY_HEAD_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function dealerRegistrationSubmit(postData) {
  const url = API_DEALER_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function promoterLeaderRegistrationSubmit(postData) {
  const url = API_PROMOTER_LEADER_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function promoterCheckRequest(postData) {
  const url = API_PROMOTER_CHECK_REQUEST;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function promoterRegistration(postData) {
  const url = API_PROMOTER_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function resetPasswordRequest(postData) {
  const url = API_RESET_PASSWORD;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function resetPasswordWebRequest(postData) {
  const url = API_RESET_PASSWORD_WEB;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function agencyRegistrationSubmit(postData) {
  const url = API_AGENCY_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function checkerRegistrationSubmit(postData) {
  const url = API_CHECKER_REGISTRATION;
  return await request({
    url: url,
    data: postData,
    method: "post",
    timeout: DEFAULT_TIMEOUT
  });
}