export const GET_COUNTRY_TARGET_DATA_LIST_REQUEST = "GET_COUNTRY_TARGET_DATA_LIST_REQUEST";
export const GET_COUNTRY_TARGET_DATA_LIST_SUCCESS = "GET_COUNTRY_TARGET_DATA_LIST_SUCCESS";
export const GET_COUNTRY_TARGET_DATA_LIST_ERROR = "GET_COUNTRY_TARGET_DATA_LIST_ERROR";

export const UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST = "UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST";
export const UPLOAD_COUNTRY_TARGET_DATA_LIST_SUCCESS = "UPLOAD_COUNTRY_TARGET_DATA_LIST_SUCCESS";
export const UPLOAD_COUNTRY_TARGET_DATA_LIST_ERROR = "UPLOAD_COUNTRY_TARGET_DATA_LIST_ERROR";

export const GET_PROMOTER_TARGET_DATA_LIST_REQUEST = "GET_PROMOTER_TARGET_DATA_LIST_REQUEST";
export const GET_PROMOTER_TARGET_DATA_LIST_SUCCESS = "GET_PROMOTER_TARGET_DATA_LIST_SUCCESS";
export const GET_PROMOTER_TARGET_DATA_LIST_ERROR = "GET_PROMOTER_TARGET_DATA_LIST_ERROR";

export const UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST = "UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST";
export const UPLOAD_PROMOTER_TARGET_DATA_LIST_SUCCESS = "UPLOAD_PROMOTER_TARGET_DATA_LIST_SUCCESS";
export const UPLOAD_PROMOTER_TARGET_DATA_LIST_ERROR = "UPLOAD_PROMOTER_TARGET_DATA_LIST_ERROR";

export const GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST = "GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST";
export const GET_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS = "GET_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS";
export const GET_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR = "GET_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR";

export const UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST = "UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST";
export const UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS = "UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS";
export const UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR = "UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR";

export const TARGET_PROMOTER_CHECK_FILE_REQUEST = "TARGET_PROMOTER_CHECK_FILE_REQUEST";
export const TARGET_PROMOTER_CHECK_FILE_SUCCESS = "TARGET_PROMOTER_CHECK_FILE_SUCCESS";
export const TARGET_PROMOTER_CHECK_FILE_ERROR = "TARGET_PROMOTER_CHECK_FILE_ERROR";

export const UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST = "UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST";
export const UPLOAD_TARGET_PROMOTER_BY_ID_SUCCESS = "UPLOAD_TARGET_PROMOTER_BY_ID_SUCCESS";
export const UPLOAD_TARGET_PROMOTER_BY_ID_ERROR = "UPLOAD_TARGET_PROMOTER_BY_ID_ERROR";

export const TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST = "TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST";
export const TARGET_PROMOTER_LEADER_CHECK_FILE_SUCCESS = "TARGET_PROMOTER_LEADER_CHECK_FILE_SUCCESS";
export const TARGET_PROMOTER_LEADER_CHECK_FILE_ERROR = "TARGET_PROMOTER_LEADER_CHECK_FILE_ERROR";

export const UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST = "UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST";
export const UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_SUCCESS = "UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_SUCCESS";
export const UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_ERROR = "UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_ERROR";

export const GET_SUB_REGION_TARGET_DATA_LIST_REQUEST = "GET_SUB_REGION_TARGET_DATA_LIST_REQUEST";
export const GET_SUB_REGION_TARGET_DATA_LIST_SUCCESS = "GET_SUB_REGION_TARGET_DATA_LIST_SUCCESS";
export const GET_SUB_REGION_TARGET_DATA_LIST_ERROR = "GET_SUB_REGION_TARGET_DATA_LIST_ERROR";

export const GET_SUB_REGION_LIST_REQUEST = "GET_SUB_REGION_LIST_REQUEST";
export const GET_SUB_REGION_LIST_SUCCESS = "GET_SUB_REGION_LIST_SUCCESS";
export const GET_SUB_REGION_LIST_ERROR = "GET_SUB_REGION_LIST_ERROR";

export const TARGET_SUB_REGION_CHECK_FILE_REQUEST = "TARGET_SUB_REGION_CHECK_FILE_REQUEST";
export const TARGET_SUB_REGION_CHECK_FILE_SUCCESS = "TARGET_SUB_REGION_CHECK_FILE_SUCCESS";
export const TARGET_SUB_REGION_CHECK_FILE_ERROR = "TARGET_SUB_REGION_CHECK_FILE_ERROR";

export const UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST = "UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST";
export const UPLOAD_TARGET_SUB_REGION_BY_ID_SUCCESS = "UPLOAD_TARGET_SUB_REGION_BY_ID_SUCCESS";
export const UPLOAD_TARGET_SUB_REGION_BY_ID_ERROR = "UPLOAD_TARGET_SUB_REGION_BY_ID_ERROR";

export const GET_DEALER_TARGET_DATA_LIST_REQUEST = "GET_DEALER_TARGET_DATA_LIST_REQUEST";
export const GET_DEALER_TARGET_DATA_LIST_SUCCESS = "GET_DEALER_TARGET_DATA_LIST_SUCCESS";
export const GET_DEALER_TARGET_DATA_LIST_ERROR = "GET_DEALER_TARGET_DATA_LIST_ERROR";

export const GET_DEALER_LIST_REQUEST = "GET_DEALER_LIST_REQUEST";
export const GET_DEALER_LIST_SUCCESS = "GET_DEALER_LIST_SUCCESS";
export const GET_DEALER_LIST_ERROR = "GET_DEALER_LIST_ERROR";

export const TARGET_DEALER_CHECK_FILE_REQUEST = "TARGET_DEALER_CHECK_FILE_REQUEST";
export const TARGET_DEALER_CHECK_FILE_SUCCESS = "TARGET_DEALER_CHECK_FILE_SUCCESS";
export const TARGET_DEALER_CHECK_FILE_ERROR = "TARGET_DEALER_CHECK_FILE_ERROR";

export const UPLOAD_TARGET_DEALER_BY_ID_REQUEST = "UPLOAD_TARGET_DEALER_BY_ID_REQUEST";
export const UPLOAD_TARGET_DEALER_BY_ID_SUCCESS = "UPLOAD_TARGET_DEALER_BY_ID_SUCCESS";
export const UPLOAD_TARGET_DEALER_BY_ID_ERROR = "UPLOAD_TARGET_DEALER_BY_ID_ERROR";

export const GET_WEEKLY_VISITATION_REQUEST = "GET_WEEKLY_VISITATION_REQUEST";
export const GET_WEEKLY_VISITATION_SUCCESS = "GET_WEEKLY_VISITATION_SUCCESS";
export const GET_WEEKLY_VISITATION_ERROR = "GET_WEEKLY_VISITATION_ERROR";

export const DOWNLOAD_WEEKLY_VISITATION_REQUEST = "DOWNLOAD_WEEKLY_VISITATION_REQUEST";
export const DOWNLOAD_WEEKLY_VISITATION_SUCCESS = "DOWNLOAD_WEEKLY_VISITATION_SUCCESS";
export const DOWNLOAD_WEEKLY_VISITATION_ERROR = "DOWNLOAD_WEEKLY_VISITATION_ERROR";

export const UPLOAD_WEEKLY_VISITATION_REQUEST = "UPLOAD_WEEKLY_VISITATION_REQUEST";
export const UPLOAD_WEEKLY_VISITATION_SUCCESS = "UPLOAD_WEEKLY_VISITATION_SUCCESS";
export const UPLOAD_WEEKLY_VISITATION_ERROR = "UPLOAD_WEEKLY_VISITATION_ERROR";

export const DOWNLOAD_VISITATION_TEMPLATE_REQUEST = "DOWNLOAD_VISITATION_TEMPLATE_REQUEST";
export const DOWNLOAD_VISITATION_TEMPLATE_SUCCESS = "DOWNLOAD_VISITATION_TEMPLATE_SUCCESS";
export const DOWNLOAD_VISITATION_TEMPLATE_ERROR = "DOWNLOAD_VISITATION_TEMPLATE_ERROR";

export const VALIDATE_VISITATION_REQUEST = "VALIDATE_VISITATION_REQUEST";
export const VALIDATE_VISITATION_SUCCESS = "VALIDATE_VISITATION_SUCCESS";
export const VALIDATE_VISITATION_ERROR = "VALIDATE_VISITATION_ERROR";
