export const DEALER_LIST_REQUEST = "DEALER_LIST_REQUEST";
export const DEALER_LIST_SUCCESS = "DEALER_LIST_SUCCESS";
export const DEALER_LIST_ERROR = "DEALER_LIST_ERROR";

export const DEALER_LIST_WITH_PARAMS_REQUEST = "DEALER_LIST_WITH_PARAMS_REQUEST";
export const DEALER_LIST_WITH_PARAMS_SUCCESS = "DEALER_LIST_WITH_PARAMS_SUCCESS";
export const DEALER_LIST_WITH_PARAMS_ERROR = "DEALER_LIST_WITH_PARAMS_ERROR";

export const DEALER_LIST_WITH_FILTERS_REQUEST = "DEALER_LIST_WITH_FILTERS_REQUEST";
export const DEALER_LIST_WITH_FILTERS_SUCCESS = "DEALER_LIST_WITH_FILTERS_SUCCESS";
export const DEALER_LIST_WITH_FILTERS_ERROR = "DEALER_LIST_WITH_FILTERS_ERROR";

export const DEALER_REQUEST = "DEALER_REQUEST";
export const DEALER_SUCCESS = "DEALER_SUCCESS";
export const DEALER_ERROR = "DEALER_ERROR";

export const DEALER_CHANGES_REQUEST = "DEALER_CHANGES_REQUEST";
export const DEALER_CHANGES_SUCCESS = "DEALER_CHANGES_SUCCESS";
export const DEALER_CHANGES_ERROR = "DEALER_CHANGES_ERROR";

export const DEALER_POST_REQUEST = "DEALER_POST_REQUEST";
export const DEALER_POST_SUCCESS = "DEALER_POST_SUCCESS";
export const DEALER_POST_ERROR = "DEALER_POST_ERROR";

export const DEALER_INCENTIVE_POST_REQUEST = "DEALER_INCENTIVE_POST_REQUEST";
export const DEALER_INCENTIVE_POST_SUCCESS = "DEALER_INCENTIVE_POST_SUCCESS";
export const DEALER_INCENTIVE_POST_ERROR = "DEALER_INCENTIVE_POST_ERROR";

export const DEALERS_OUTLETS_LIST_REQUEST = "DEALERS_OUTLETS_LIST_REQUEST";
export const DEALERS_OUTLETS_LIST_SUCCESS = "DEALERS_OUTLETS_LIST_SUCCESS";
export const DEALERS_OUTLETS_LIST_ERROR = "DEALERS_OUTLETS_LIST_ERROR";

export const DEALER_SELL_IN_LIST_REQUEST = "DEALER_SELL_IN_LIST_REQUEST";
export const DEALER_SELL_IN_LIST_SUCCESS = "DEALER_SELL_IN_LIST_SUCCESS";
export const DEALER_SELL_IN_LIST_ERROR = "DEALER_SELL_IN_LIST_ERROR";

export const DEALER_SELL_IN_ADD_REQUEST = "DEALER_SELL_IN_ADD_REQUEST";
export const DEALER_SELL_IN_ADD_SUCCESS = "DEALER_SELL_IN_ADD_SUCCESS";
export const DEALER_SELL_IN_ADD_ERROR = "DEALER_SELL_IN_ADD_ERROR";

export const DEALER_SELL_OUT_LIST_REQUEST = "DEALER_SELL_OUT_LIST_REQUEST";
export const DEALER_SELL_OUT_LIST_SUCCESS = "DEALER_SELL_OUT_LIST_SUCCESS";
export const DEALER_SELL_OUT_LIST_ERROR = "DEALER_SELL_OUT_LIST_ERROR";

export const DEALER_SELL_OUT_ADD_REQUEST = "DEALER_SELL_OUT_ADD_REQUEST";
export const DEALER_SELL_OUT_ADD_SUCCESS = "DEALER_SELL_OUT_ADD_SUCCESS";
export const DEALER_SELL_OUT_ADD_ERROR = "DEALER_SELL_OUT_ADD_ERROR";

export const DEALER_STOCK_LIST_REQUEST = "DEALER_STOCK_LIST_REQUEST";
export const DEALER_STOCK_LIST_SUCCESS = "DEALER_STOCK_LIST_SUCCESS";
export const DEALER_STOCK_LIST_ERROR = "DEALER_STOCK_LIST_ERROR";

export const DEALER_STOCK_LIST_MONTHLY_REQUEST = "DEALER_STOCK_LIST_MONTHLY_REQUEST";
export const DEALER_STOCK_LIST_MONTHLY_SUCCESS = "DEALER_STOCK_LIST_MONTHLY_SUCCESS";
export const DEALER_STOCK_LIST_MONTHLY_ERROR = "DEALER_STOCK_LIST_MONTHLY_ERROR";

export const DEALER_STOCK_LIST_DOWNLOAD_REQUEST = "DEALER_STOCK_LIST_DOWNLOAD_REQUEST";
export const DEALER_STOCK_LIST_DOWNLOAD_SUCCESS = "DEALER_STOCK_LIST_DOWNLOAD_SUCCESS";
export const DEALER_STOCK_LIST_DOWNLOAD_ERROR = "DEALER_STOCK_LIST_DOWNLOAD_ERROR";

export const DEALER_INCENTIVE_LIST_REQUEST = "DEALER_INCENTIVE_LIST_REQUEST";
export const DEALER_INCENTIVE_LIST_SUCCESS = "DEALER_INCENTIVE_LIST_SUCCESS";
export const DEALER_INCENTIVE_LIST_ERROR = "DEALER_INCENTIVE_LIST_ERROR";

export const DEALER_INC_RECEIPT_LIST_REQUEST = "DEALER_INC_RECEIPT_LIST_REQUEST";
export const DEALER_INC_RECEIPT_LIST_SUCCESS = "DEALER_INC_RECEIPT_LIST_SUCCESS";
export const DEALER_INC_RECEIPT_LIST_ERROR = "DEALER_INC_RECEIPT_LIST_ERROR";

export const RECEIPT_UPLOAD_BY_DEALER_REQUEST = "RECEIPT_UPLOAD_BY_DEALER_REQUEST";
export const RECEIPT_UPLOAD_BY_DEALER_SUCCESS = "RECEIPT_UPLOAD_BY_DEALER_SUCCESS";
export const RECEIPT_UPLOAD_BY_DEALER_ERROR = "RECEIPT_UPLOAD_BY_DEALER_ERROR";

export const RECEIPT_UPLOAD_BY_MONTH_REQUEST = "RECEIPT_UPLOAD_BY_MONTH_REQUEST";
export const RECEIPT_UPLOAD_BY_MONTH_SUCCESS = "RECEIPT_UPLOAD_BY_MONTH_SUCCESS";
export const RECEIPT_UPLOAD_BY_MONTH_ERROR = "RECEIPT_UPLOAD_BY_MONTH_ERROR";

export const DEALER_SELL_THRU_LIST_REQUEST = "DEALER_SELL_THRU_LIST_REQUEST";
export const DEALER_SELL_THRU_LIST_REQUEST_SUCCESS = "DEALER_SELL_THRU_LIST_REQUEST_SUCCESS";
export const DEALER_SELL_THRU_LIST_REQUEST_ERROR = "DEALER_SELL_THRU_LIST_REQUEST_ERROR";

export const GET_SELL_THRU_UPLOADS_ALL = "GET_SELL_THRU_UPLOADS_ALL";
export const GET_SELL_THRU_UPLOADS_ALL_SUCCESS = "GET_SELL_THRU_UPLOADS_ALL_SUCCESS";
export const GET_SELL_THRU_UPLOADS_ALL_ERROR = "GET_SELL_THRU_UPLOADS_ALL_ERROR";

export const DEALER_SELL_TRHU_UPLOAD_REQUEST = "DEALER_SELL_TRHU_UPLOAD_REQUEST";
export const DEALER_SELL_TRHU_UPLOAD_REQUEST_SUCCESS = "DEALER_SELL_TRHU_UPLOAD_REQUEST_SUCCESS";
export const DEALER_SELL_TRHU_UPLOAD_REQUEST_ERROR = "DEALER_SELL_TRHU_UPLOAD_REQUEST_ERROR";

export const EXPORT_DEALER_SELL_THRU_DATA_REQUEST = "EXPORT_DEALER_SELL_THRU_DATA_REQUEST";
export const EXPORT_DEALER_SELL_THRU_DATA_REQUEST_SUCCESS = "EXPORT_DEALER_SELL_THRU_DATA_REQUEST_SUCCESS";
export const EXPORT_DEALER_SELL_THRU_DATA_REQUEST_ERROR = "EXPORT_DEALER_SELL_THRU_DATA_REQUEST_ERROR";

export const DEALERS_SELL_THRU_UPLOAD_BY_ID = "DEALERS_SELL_THRU_UPLOAD_BY_ID";
export const DEALERS_SELL_THRU_UPLOAD_BY_ID_SUCCESS = "DEALERS_SELL_THRU_UPLOAD_BY_ID_SUCCESS";
export const DEALERS_SELL_THRU_UPLOAD_BY_ID_ERROR = "DEALERS_SELL_THRU_UPLOAD_BY_ID_ERROR";

export const DOWNLOAD_DEALER_SELL_THRU_REQUEST = "DOWNLOAD_DEALER_SELL_THRU_REQUEST";
export const DOWNLOAD_DEALER_SELL_THRU_REQUEST_SUCCESS = "DOWNLOAD_DEALER_SELL_THRU_REQUEST_SUCCESS";
export const DOWNLOAD_DEALER_SELL_THRU_REQUEST_ERROR = "DOWNLOAD_DEALER_SELL_THRU_REQUEST_ERROR";

export const GET_SELL_THRU_LATEST_WEEKS_REQUEST = "GET_SELL_THRU_LATEST_WEEKS_REQUEST";
export const GET_SELL_THRU_LATEST_WEEKS_REQUEST_SUCCESS = "GET_SELL_THRU_LATEST_WEEKS_REQUEST_SUCCESS";
export const GET_SELL_THRU_LATEST_WEEKS_REQUEST_ERROR = "GET_SELL_THRU_LATEST_WEEKS_REQUEST_ERROR";
