export const PENDING_SALESP_LIST_REQUEST = "PENDING_SALESP_LIST_REQUEST";
export const PENDING_SALESP_LIST_SUCCESS = "PENDING_SALESP_LIST_SUCCESS";
export const PENDING_SALESP_LIST_ERROR = "PENDING_SALESP_LIST_ERROR";

export const PENDING_SALESP_STATUS_REQUEST = "PENDING_SALESP_STATUS_REQUEST";
export const PENDING_SALESP_STATUS_SUCCESS = "PENDING_SALESP_STATUS_SUCCESS";
export const PENDING_SALESP_STATUS_ERROR = "PENDING_SALESP_STATUS_ERROR";

export const GET_STAFF_LIST_REQUEST = "GET_STAFF_LIST_REQUEST";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";

export const GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST = "GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST";
export const GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_SUCCESS = "GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_SUCCESS";
export const GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_ERROR = "GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_ERROR";

export const GET_SALESPERSON_LIST_REQUEST = "GET_SALESPERSON_LIST_REQUEST";
export const GET_SALESPERSON_LIST_SUCCESS = "GET_SALESPERSON_LIST_SUCCESS";
export const GET_SALESPERSON_LIST_ERROR = "GET_SALESPERSON_LIST_ERROR";

export const GET_STAFF_PROMOTER_LIST_REQUEST = "GET_STAFF_PROMOTER_LIST_REQUEST";
export const GET_STAFF_PROMOTER_LIST_REQUEST_SUCCESS = "GET_STAFF_PROMOTER_LIST_REQUEST_SUCCESS";
export const GET_STAFF_PROMOTER_LIST_REQUEST_ERROR = "GET_STAFF_PROMOTER_LIST_REQUEST_ERROR";

export const GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST = "GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST";
export const GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_SUCCESS = "GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_SUCCESS";
export const GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_ERROR = "GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_ERROR";

export const GET_ACTIVE_PROMOTERS_LIST_REQUEST = "GET_ACTIVE_PROMOTERS_LIST_REQUEST";
export const GET_ACTIVE_PROMOTERS_LIST_SUCCESS = "GET_ACTIVE_PROMOTERS_LIST_SUCCESS";
export const GET_ACTIVE_PROMOTERS_LIST_ERROR = "GET_ACTIVE_PROMOTERS_LIST_ERROR";

export const POST_STAFF_REQUEST = "POST_STAFF_REQUEST";
export const POST_STAFF_SUCCESS = "POST_STAFF_SUCCESS";
export const POST_STAFF_ERROR = "POST_STAFF_ERROR";

export const POST_PROMOTER_OUTLET_REQUEST = "POST_PROMOTER_OUTLET_REQUEST";
export const POST_PROMOTER_OUTLET_SUCCESS = "POST_PROMOTER_OUTLET_SUCCESS";
export const POST_PROMOTER_OUTLET_ERROR = "POST_PROMOTER_OUTLET_ERROR";

export const POST_MANAGER_STATE_REQUEST = "POST_MANAGER_STATE_REQUEST";
export const POST_MANAGER_STATE_SUCCESS = "POST_MANAGER_STATE_SUCCESS";
export const POST_MANAGER_STATE_ERROR = "POST_MANAGER_STATE_ERROR";

export const GET_ALL_PROMOTERS_LIST_REQUEST = "GET_ALL_PROMOTERS_LIST_REQUEST";
export const GET_ALL_PROMOTERS_LIST_SUCCESS = "GET_ALL_PROMOTERS_LIST_SUCCESS";
export const GET_ALL_PROMOTERS_LIST_ERROR = "GET_ALL_PROMOTERS_LIST_ERROR";

export const PENDING_DEALER_LIST_REQUEST = "PENDING_DEALER_LIST_REQUEST";
export const PENDING_DEALER_LIST_REQUEST_SUCCESS = "PENDING_DEALER_LIST_REQUEST_SUCCESS";
export const PENDING_DEALER_LIST_REQUEST_ERROR = "PENDING_DEALER_LIST_REQUEST_ERROR";

export const PENDING_PROMOTER_LEADER_LIST_REQUEST = "PENDING_PROMOTER_LEADER_LIST_REQUEST";
export const PENDING_PROMOTER_LEADER_LIST_REQUEST_SUCCESS = "PENDING_PROMOTER_LEADER_LIST_REQUEST_SUCCESS";
export const PENDING_PROMOTER_LEADER_LIST_REQUEST_ERROR = "PENDING_PROMOTER_LEADER_LIST_REQUEST_ERROR";

export const PENDING_DEALER_OUTLET_LIST_REQUEST = "PENDING_DEALER_OUTLET_LIST_REQUEST";
export const PENDING_DEALER_OUTLET_LIST_REQUEST_SUCCESS = "PENDING_DEALER_OUTLET_LIST_REQUEST_SUCCESS";
export const PENDING_DEALER_OUTLET_LIST_REQUEST_ERROR = "PENDING_DEALER_OUTLET_LIST_REQUEST_ERROR";

export const PENDING_PROMOTER_LIST_REQUEST = "PENDING_PROMOTER_LIST_REQUEST";
export const PENDING_PROMOTER_LIST_REQUEST_SUCCESS = "PENDING_PROMOTER_LIST_REQUEST_SUCCESS";
export const PENDING_PROMOTER_LIST_REQUEST_ERROR = "PENDING_PROMOTER_LIST_REQUEST_ERROR";

export const PENDING_MERCHANDISER_LIST_REQUEST = "PENDING_MERCHANDISER_LIST_REQUEST";
export const PENDING_MERCHANDISER_LIST_REQUEST_SUCCESS = "PENDING_MERCHANDISER_LIST_REQUEST_SUCCESS";
export const PENDING_MERCHANDISER_LIST_REQUEST_ERROR = "PENDING_MERCHANDISER_LIST_REQUEST_ERROR";

export const PENDING_MERCHANDISER_LEADER_LIST_REQUEST = "PENDING_MERCHANDISER_LEADER_LIST_REQUEST";

export const UPDATE_APPROVAL_PROMOTER_REQUEST = "UPDATE_APPROVAL_PROMOTER_REQUEST";
export const UPDATE_APPROVAL_PROMOTER_REQUEST_SUCCESS = "UPDATE_APPROVAL_PROMOTER_REQUEST_SUCCESS";
export const UPDATE_APPROVAL_PROMOTER_REQUEST_ERROR = "UPDATE_APPROVAL_PROMOTER_REQUEST_ERROR";

export const GET_DEALER_PENDING_APPROVAL_FLAG = "GET_DEALER_PENDING_APPROVAL_FLAG";
export const GET_DEALER_PENDING_APPROVAL_FLAG_SUCCESS = "GET_DEALER_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_DEALER_PENDING_APPROVAL_FLAG_ERROR = "GET_DEALER_PENDING_APPROVAL_FLAG_ERROR";

export const GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG = "GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG";
export const GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_SUCCESS = "GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_ERROR = "GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_ERROR";

export const GET_PROMOTER_PENDING_APPROVAL_FLAG = "GET_PROMOTER_PENDING_APPROVAL_FLAG";
export const GET_PROMOTER_PENDING_APPROVAL_FLAG_SUCCESS = "GET_PROMOTER_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_PROMOTER_PENDING_APPROVAL_FLAG_ERROR = "GET_PROMOTER_PENDING_APPROVAL_FLAG_ERROR";

export const GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG = "GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG";
export const GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_SUCCESS = "GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_ERROR = "GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_ERROR";

export const UPDATE_APPROVAL_DEALER_OUTLET_REQUEST = "UPDATE_APPROVAL_DEALER_OUTLET_REQUEST";
export const UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_SUCCESS = "UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_SUCCESS";
export const UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_ERROR = "UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_ERROR";

export const UPDATE_APPROVAL_DEALER_REQUEST = "UPDATE_APPROVAL_DEALER_REQUEST";
export const UPDATE_APPROVAL_DEALER_REQUEST_SUCCESS = "UPDATE_APPROVAL_DEALER_REQUEST_SUCCESS";
export const UPDATE_APPROVAL_DEALER_REQUEST_ERROR = "UPDATE_APPROVAL_DEALER_REQUEST_ERROR";

export const UPDATE_APPROVAL_MERCHANDISER_REQUEST = "UPDATE_APPROVAL_MERCHANDISER_REQUEST";
export const UPDATE_APPROVAL_MERCHANDISER_REQUEST_SUCCESS = "UPDATE_APPROVAL_MERCHANDISER_REQUEST_SUCCESS";
export const UPDATE_APPROVAL_MERCHANDISER_REQUEST_ERROR = "UPDATE_APPROVAL_MERCHANDISER_REQUEST_ERROR";

export const UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST = "UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST";

export const UPDATE_APPROVAL_PROM_REQUEST = "UPDATE_APPROVAL_PROM_REQUEST";
export const UPDATE_APPROVAL_PROM_REQUEST_SUCCESS = "UPDATE_APPROVAL_PROM_REQUEST_SUCCESS";
export const UPDATE_APPROVAL_PROM_REQUEST_ERROR = "UPDATE_APPROVAL_PROM_REQUEST_ERROR";

export const GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG = "GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG";
export const GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_SUCCESS = "GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_ERROR = "GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_ERROR";

export const GET_MERCHANDISER_LIST_REQUEST = "GET_MERCHANDISER_LIST_REQUEST";
export const GET_MERCHANDISER_LIST_SUCCESS = "GET_MERCHANDISER_LIST_SUCCESS";
export const GET_MERCHANDISER_LIST_ERROR = "GET_MERCHANDISER_LIST_ERROR";

export const GET_MERCHANDISER_LEADER_LIST_REQUEST = "GET_MERCHANDISER_LEADER_LIST_REQUEST";
export const GET_MERCHANDISER_LEADER_LIST_SUCCESS = "GET_MERCHANDISER_LEADER_LIST_SUCCESS";
export const GET_MERCHANDISER_LEADER_LIST_ERROR = "GET_MERCHANDISER_LEADER_LIST_ERROR";

export const GET_MERCHANDISER_PENDING_APPROVAL_FLAG = "GET_MERCHANDISER_PENDING_APPROVAL_FLAG";
export const GET_MERCHANDISER_PENDING_APPROVAL_FLAG_SUCCESS = "GET_MERCHANDISER_PENDING_APPROVAL_FLAG_SUCCESS";
export const GET_MERCHANDISER_PENDING_APPROVAL_FLAG_ERROR = "GET_MERCHANDISER_PENDING_APPROVAL_FLAG_ERROR";

export const GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG = "GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG";

export const GET_MERCHANDISER_LIST_BY_ID_REQUEST = "GET_MERCHANDISER_LIST_BY_ID_REQUEST";
export const GET_MERCHANDISER_LIST_BY_ID_SUCCESS = "GET_MERCHANDISER_LIST_BY_ID_SUCCESS";
export const GET_MERCHANDISER_LIST_BY_ID_ERROR = "GET_MERCHANDISER_LIST_BY_ID_ERROR";