<template>
  <GChart
    style="height:350px"
    type="ComboChart"
    :data="comboData"
    :options="isStacked ? comboOptions2 : comboOptions"
  />
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  props: {
    data: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    country: { type: String, default: "" },
    isStacked: { type: Boolean, default: false },
    showTtl: { type: Boolean, default: false },
    seriesOptions: { type: Object, default: () => [] },
    hideTooltip: { type: Boolean, default: false },
    ticks: { type: [ String, Array ], default: "auto" },
    hAxis: { type: Object, default: () => {} }
  },
  computed: {},
  data() {
    return {
      comboData: this.data,
      comboOptions: {
        title: this.title,
        seriesType: "bars",
        series: {
          0: { color: "#4e79a7" },
          1: { type: "line", color: "#edc948" }
        },
        legend: { position: "bottom", alignment: "start" },
        chartArea: { left: "10%", width: "100%" },
        fontSize: 12,        
      },
      comboOptions2: {
        title: this.title,
        vAxis: {
          viewWindow:{ min: 0 },
          ticks: this.ticks
        },
        seriesType: "bars",
        vAxes: {
          0: {
            gridlines: {
              color: 'transparent'
            }
          },
        },
        hAxis: this.hAxis || null ,
        isStacked: true,
        tooltip: { 
          isHtml: true,
          fontSize: 11,
          trigger: this.hideTooltip ? 'none' : 'focus'
        },
        series: this.seriesOptions,
        legend: {position: 'none'},
        //legend: { position: "bottom", alignment: "start" },
        chartArea: { left: "10%", width: "80%" },
        fontSize: 12,
        annotations: {
          highContrast: false,
          textStyle: { color: "black" },
          alwaysOutside: true,
          datum: {
            stem : { 
              length: 5,
              color: "transparent",
            }
          },
        }
      }
    };
  },
  watch: {
    data: {
      deep: true,
      async handler(newValue) {
        this.comboData = await newValue;
        GChart.methods.drawChart();
      }
    },
    isStacked: {
      deep: true,
      async handler() {
        GChart.methods.drawChart();
      }
    },
    showTtl: {
      deep: true,
      async handler() {
        GChart.methods.drawChart();
      }
    },
    seriesOptions: {
      deep: true,
      async handler() {
        GChart.methods.drawChart();
      }
    },
  },
  methods: {},
  mounted() {
    if (this.isStacked && this.showTtl) {
      this.comboOptions2.series = this.seriesOptions;
    }
    if(this.data[0].length === 16 && this.data[14][8] + this.data[14][10] + this.data[14][12] + this.data[14][14] === 0 || 
        this.data[0].length === 7 && this.data[14][3] + this.data[14][5] === 0){
          this.comboOptions2.vAxis.viewWindow["max"] = 3;
    }
  }
};
</script>
<style lang="scss">
    .google-visualization-tooltip {
      width: 200px !important;
      padding: 10px !important;
      font-family: "arial";
    }
</style>