export const LOCK_SERIAL_LIST_REQUEST = "LOCK_SERIAL_LIST_REQUEST";
export const LOCK_SERIAL_LIST_SUCCESS = "LOCK_SERIAL_LIST_SUCCESS";
export const LOCK_SERIAL_LIST_ERROR = "LOCK_SERIAL_LIST_ERROR";

export const LOCK_SERIAL_UPDATE_REQUEST = "LOCK_SERIAL_UPDATE_REQUEST";
export const LOCK_SERIAL_UPDATE_SUCCESS = "LOCK_SERIAL_UPDATE_SUCCESS";
export const LOCK_SERIAL_UPDATE_ERROR = "LOCK_SERIAL_UPDATE_ERROR";

export const LOCK_SERIAL_DOWNLOAD_REQUEST = "LOCK_SERIAL_DOWNLOAD_REQUEST";
export const LOCK_SERIAL_DOWNLOAD_SUCCESS = "LOCK_SERIAL_DOWNLOAD_SUCCESS";
export const LOCK_SERIAL_DOWNLOAD_ERROR = "LOCK_SERIAL_DOWNLOAD_ERROR";

export const CANCEL_SERIAL_REQUEST = "CANCEL_SERIAL_REQUEST";
export const CANCEL_SERIAL_SUCCESS = "CANCEL_SERIAL_SUCCESS";
export const CANCEL_SERIAL_ERROR = "CANCEL_SERIAL_ERROR";
