import request from "@/utils/request";
import {
  API_ACTUAL_SO_BY_SUB_REGION_GET,
  API_DEALER_DASHBOARD_GET,
  API_MERCHANDISER_DASHBOARD_VISITATION,
  API_MERCHANDISER_DASHBOARD_SCORE_GRADE,
  API_MERCHANDISER_DASHBOARD_STORE_VS_CATEGORY,
  API_MERCHANDISER_DASHBOARD_VISITATION_SUB_REGION,
  API_MERCHANDISER_DASHBOARD_SCORE_GRADE_SUB_REGION,
  API_MERCHANDISER_DASHBOARD_SCORE_GRADE_BY_TOP_DEALER,
  API_MERCHANDISER_DASHBOARD_LENOVO_VS_COMPETITOR
} from "../constants/services";
import { DEFAULT_TIMEOUT, EXTENDED_TIMEOUT } from "../constants/index";


//CHARTS
export function actualSObySubRegionGet(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/actual-so-by-subregion/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function soHeatMap(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/so-heat-map/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function promoterSOperformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-so-performance/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function soPerformanceVStarget(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/so-performance-vs-target/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function productPerformanceByCategory(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/product-performance-by-category/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${
    postData.productFilters.catbcsSelected
      ? `&catbcs=${postData.productFilters.catbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpbcsSelected
      ? `&cmpbcs=${postData.productFilters.cmpbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpsSelected
      ? `&cmps=${postData.productFilters.cmpsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpwsSelected
      ? `&cmpws=${postData.productFilters.cmpwsSelected.value}`
      : ``
  }${
      postData.productFilters.cmprsSelected
      ? `&cmprs=${postData.productFilters.cmprsSelected.value}`
      : ``
  }${
      postData.productFilters.cmpdfSelected
      ? `&cmpdf=${postData.productFilters.cmpdfSelected.id}`
      : ``  
  }${
    postData.productFilters.catwsSelected
      ? `&catws=${postData.productFilters.catwsSelected.value}`
      : ``
  }${
      postData.productFilters.catrsSelected
      ? `&catrs=${postData.productFilters.catrsSelected.value}`
      : ``
  }${
      postData.productFilters.catdfSelected
      ? `&catdf=${postData.productFilters.catdfSelected.id}`
      : ``
  }`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: EXTENDED_TIMEOUT
  });
}

export function exportProductPerformanceByCategory(postData) {
  console.log(postData.filters.currencySelected.value)
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/product-performance-by-category/export${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.productFilters ? `${
    postData.productFilters.catbcsSelected
      ? `&catbcs=${postData.productFilters.catbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpbcsSelected
      ? `&cmpbcs=${postData.productFilters.cmpbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpsSelected
      ? `&cmps=${postData.productFilters.cmpsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpwsSelected
      ? `&cmpws=${postData.productFilters.cmpwsSelected.value}`
      : ``
  }${
      postData.productFilters.cmprsSelected
      ? `&cmprs=${postData.productFilters.cmprsSelected.value}`
      : ``
  }${
      postData.productFilters.cmpdfSelected
      ? `&cmpdf=${postData.productFilters.cmpdfSelected.id}`
      : ``  
  }${
    postData.productFilters.catwsSelected
      ? `&catws=${postData.productFilters.catwsSelected.value}`
      : ``
  }${
      postData.productFilters.catrsSelected
      ? `&catrs=${postData.productFilters.catrsSelected.value}`
      : ``
  }${
      postData.productFilters.catdfSelected
      ? `&catdf=${postData.productFilters.catdfSelected.id}`
      : ``
  }` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: EXTENDED_TIMEOUT
  });
}

export function productPerformanceByComponent(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/product-performance-by-component/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${
    postData.productFilters.catbcsSelected
      ? `&catbcs=${postData.productFilters.catbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpbcsSelected
      ? `&cmpbcs=${postData.productFilters.cmpbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpsSelected
      ? `&cmps=${postData.productFilters.cmpsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpwsSelected
      ? `&cmpws=${postData.productFilters.cmpwsSelected.value}`
      : ``
  }${
      postData.productFilters.cmprsSelected
      ? `&cmprs=${postData.productFilters.cmprsSelected.value}`
      : ``
  }${
      postData.productFilters.cmpdfSelected
      ? `&cmpdf=${postData.productFilters.cmpdfSelected.id}`
      : ``  
  }${
    postData.productFilters.catwsSelected
      ? `&catws=${postData.productFilters.catwsSelected.value}`
      : ``
  }${
      postData.productFilters.catrsSelected
      ? `&catrs=${postData.productFilters.catrsSelected.value}`
      : ``
  }${
      postData.productFilters.catdfSelected
      ? `&catdf=${postData.productFilters.catdfSelected.id}`
      : ``
  }`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: EXTENDED_TIMEOUT
  });
}

export function exportProductPerformanceByComponent(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/product-performance-by-component/export${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.productFilters ? `${
    postData.productFilters.catbcsSelected
      ? `&catbcs=${postData.productFilters.catbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpbcsSelected
      ? `&cmpbcs=${postData.productFilters.cmpbcsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpsSelected
      ? `&cmps=${postData.productFilters.cmpsSelected.value}`
      : ``
  }${
    postData.productFilters.cmpwsSelected
      ? `&cmpws=${postData.productFilters.cmpwsSelected.value}`
      : ``
  }${
      postData.productFilters.cmprsSelected
      ? `&cmprs=${postData.productFilters.cmprsSelected.value}`
      : ``
  }${
      postData.productFilters.cmpdfSelected
      ? `&cmpdf=${postData.productFilters.cmpdfSelected.id}`
      : ``  
  }${
    postData.productFilters.catwsSelected
      ? `&catws=${postData.productFilters.catwsSelected.value}`
      : ``
  }${
      postData.productFilters.catrsSelected
      ? `&catrs=${postData.productFilters.catrsSelected.value}`
      : ``
  }${
      postData.productFilters.catdfSelected
      ? `&catdf=${postData.productFilters.catdfSelected.id}`
      : ``
  }` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: EXTENDED_TIMEOUT
  });
}

export function promoterDealerPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-dealer-performance/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function promoterOutletPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-outlet-performance/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function promoterPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-performance/data${
    postData.filters.countrySelected ? `?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}` : ``
  }${postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function topDealers(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/top-dealers/data?ccf=${postData.ccf}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportTopDealersWithFilters(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/top-dealers/export?ccf=${postData.ccf}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportPromoterDealerPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-dealer-performance/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportPromoterOutletPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-outlet-performance/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportPromoterPerformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-performance/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportPromoterSOperformance(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/promoter-so-performance/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportSoPerformanceVStarget(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/so-performance-vs-target/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function exportActualSObySubRegion(postData) {
  const url = `${API_ACTUAL_SO_BY_SUB_REGION_GET}/${postData.grouping}/charts/actual-so-by-subregion/export?ccf=${postData.filters.countrySelected.value || postData.filters.countrySelected}${
    postData.filters.yearSelected ? `&fyf=${postData.filters.yearSelected.value}` : ``}${
    postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``
  }${postData.promoterType ? `&ptf=${postData.promoterType}` : ``}`;
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function soPerformanceRequest(postData) {
  const url = `${API_DEALER_DASHBOARD_GET}/${postData.grouping}/charts/so-performance-vs-target/data${
    postData.filters.yearSelected ? `?fyf=${postData.filters.yearSelected.value}` : ``
  }${postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function salespersonPerformanceRequest(postData) {
  const url = `${API_DEALER_DASHBOARD_GET}/${postData.grouping}/charts/salesperson-performance/data${
    postData.filters.yearSelected ? `?fyf=${postData.filters.yearSelected.value}` : ``
  }${postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function productPerformanceRequest(postData) {
  const url = `${API_DEALER_DASHBOARD_GET}/${postData.grouping}/charts/product-performance/data${
    postData.filters.yearSelected ? `?fyf=${postData.filters.yearSelected.value}` : ``
  }${postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export function outletPerformanceRequest(postData) {
  const url = `${API_DEALER_DASHBOARD_GET}/${postData.grouping}/charts/outlet-performance/data${
    postData.filters.yearSelected ? `?fyf=${postData.filters.yearSelected.value}` : ``
  }${postData.filters.quarterSelected && postData.grouping !== 'yearly' ? `&qf=${postData.filters.quarterSelected.value}` : ``
  }${postData.filters.unitSelected ? `&tf=${postData.filters.unitSelected.value}` : ``}${
    postData.filters.currencySelected ? `&cf=${postData.filters.currencySelected.value}` : ``}`;
  return request({
    url: url,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

// Merchandiser
export function getMerchandiserVisitation(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_VISITATION
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }

  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserScoreGrade(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_SCORE_GRADE
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&storeIdentity=${postData.filters.storeIdentity.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserStoreVSCategory(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_STORE_VS_CATEGORY
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&storeIdentity=${postData.filters.storeIdentity.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserVisitationSubRegion(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_VISITATION_SUB_REGION
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  if (postData.filters.regionSelected.id != null) {
    url += `&regId=${postData.filters.regionSelected.id}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserScoreGradeSubRegion(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_SCORE_GRADE_SUB_REGION
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  if (postData.filters.regionSelected.id != null) {
    url += `&regId=${postData.filters.regionSelected.id}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserScoreGradeByTopDealer(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_SCORE_GRADE_BY_TOP_DEALER
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export function getMerchandiserLenovoVSCompetitor(postData) {
  let url = `${API_MERCHANDISER_DASHBOARD_LENOVO_VS_COMPETITOR
    }?countryId=${postData.filters.countryIdSelected
    }&fyf=${postData.filters.yearSelected.value
    }&isExport=${postData.filters.isExport}`
  if (postData.filters.quarterSelected.value != null) {
    url += `&qf=${postData.filters.quarterSelected.value}`
  }
  if (postData.filters.subRegionSelectedName != null) {
    url += `&subRegionSelectedName=${postData.filters.subRegionSelectedName}`
  }
  return request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
