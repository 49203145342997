import {
  getCountryDataList,
  uploadTragetCountryData,
  getPromoterDataList,
  uploadTragetPromoterData,
  getPromoterLeaderDataList,
  uploadTragetPromoterLeaderData,
  targetPromoterFileCheck,
  uploadTargetPromoterById,
  targetPromoterLeaderFileCheck,
  uploadTargetPromoterLeaderById,
  getSubRegionTarget,
  getSubRegion,
  targetSubRegionFileCheck,
  uploadSubRegionTargetById,
  getDealerTarget,
  getDealer,
  targetDealerFileCheck,
  uploadDealerTargetById,
  getWeeklyVisitation,
  downloadWeeklyVisitation,
  uploadWeeklyVisitation,
  downloadVisitationTemplate,
  validWeeklyVisitation
} from "@/api/targets";
import {
  GET_COUNTRY_TARGET_DATA_LIST_REQUEST,
  GET_COUNTRY_TARGET_DATA_LIST_SUCCESS,
  GET_COUNTRY_TARGET_DATA_LIST_ERROR,
  UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST,
  UPLOAD_COUNTRY_TARGET_DATA_LIST_SUCCESS,
  UPLOAD_COUNTRY_TARGET_DATA_LIST_ERROR,
  GET_PROMOTER_TARGET_DATA_LIST_REQUEST,
  GET_PROMOTER_TARGET_DATA_LIST_SUCCESS,
  GET_PROMOTER_TARGET_DATA_LIST_ERROR,
  UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST,
  UPLOAD_PROMOTER_TARGET_DATA_LIST_SUCCESS,
  UPLOAD_PROMOTER_TARGET_DATA_LIST_ERROR,
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST,
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS,
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR,
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST,
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS,
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR,
  TARGET_PROMOTER_CHECK_FILE_REQUEST,
  TARGET_PROMOTER_CHECK_FILE_SUCCESS,
  TARGET_PROMOTER_CHECK_FILE_ERROR,
  UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST,
  UPLOAD_TARGET_PROMOTER_BY_ID_SUCCESS,
  UPLOAD_TARGET_PROMOTER_BY_ID_ERROR,
  TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST,
  TARGET_PROMOTER_LEADER_CHECK_FILE_SUCCESS,
  TARGET_PROMOTER_LEADER_CHECK_FILE_ERROR,
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST,
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_SUCCESS,
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_ERROR,
  GET_SUB_REGION_TARGET_DATA_LIST_REQUEST,
  GET_SUB_REGION_TARGET_DATA_LIST_SUCCESS,
  GET_SUB_REGION_TARGET_DATA_LIST_ERROR,
  GET_SUB_REGION_LIST_REQUEST,
  GET_SUB_REGION_LIST_SUCCESS,
  GET_SUB_REGION_LIST_ERROR,
  TARGET_SUB_REGION_CHECK_FILE_REQUEST,
  TARGET_SUB_REGION_CHECK_FILE_SUCCESS,
  TARGET_SUB_REGION_CHECK_FILE_ERROR,
  UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST,
  UPLOAD_TARGET_SUB_REGION_BY_ID_SUCCESS,
  UPLOAD_TARGET_SUB_REGION_BY_ID_ERROR,
  GET_DEALER_TARGET_DATA_LIST_REQUEST,
  GET_DEALER_TARGET_DATA_LIST_SUCCESS,
  GET_DEALER_TARGET_DATA_LIST_ERROR,
  GET_DEALER_LIST_REQUEST,
  GET_DEALER_LIST_SUCCESS,
  GET_DEALER_LIST_ERROR,
  TARGET_DEALER_CHECK_FILE_REQUEST,
  TARGET_DEALER_CHECK_FILE_SUCCESS,
  TARGET_DEALER_CHECK_FILE_ERROR,
  UPLOAD_TARGET_DEALER_BY_ID_REQUEST,
  UPLOAD_TARGET_DEALER_BY_ID_SUCCESS,
  UPLOAD_TARGET_DEALER_BY_ID_ERROR,
  GET_WEEKLY_VISITATION_REQUEST,
  GET_WEEKLY_VISITATION_SUCCESS,
  GET_WEEKLY_VISITATION_ERROR,
  DOWNLOAD_WEEKLY_VISITATION_REQUEST,
  DOWNLOAD_WEEKLY_VISITATION_SUCCESS,
  DOWNLOAD_WEEKLY_VISITATION_ERROR,
  UPLOAD_WEEKLY_VISITATION_REQUEST,
  UPLOAD_WEEKLY_VISITATION_SUCCESS,
  UPLOAD_WEEKLY_VISITATION_ERROR,
  DOWNLOAD_VISITATION_TEMPLATE_REQUEST,
  DOWNLOAD_VISITATION_TEMPLATE_SUCCESS,
  DOWNLOAD_VISITATION_TEMPLATE_ERROR,
  VALIDATE_VISITATION_REQUEST,
  VALIDATE_VISITATION_SUCCESS,
  VALIDATE_VISITATION_ERROR
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  data: [],
  status: "",
  hasLoadedOnce: false
};

const getters = {
  getCountryData: state => state.data,
  getPromoterData: state => state.data
};

const actions = {
  [GET_COUNTRY_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_COUNTRY_TARGET_DATA_LIST_REQUEST);
      getCountryDataList(postData)
        .then(resp => {
          commit(GET_COUNTRY_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_COUNTRY_TARGET_DATA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST);
      uploadTragetCountryData(postData)
        .then(resp => {
          commit(UPLOAD_COUNTRY_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_COUNTRY_TARGET_DATA_LIST_ERROR);
          reject(err);
        });
    });
  },
  [GET_PROMOTER_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_TARGET_DATA_LIST_REQUEST);
      getPromoterDataList(postData)
        .then(resp => {
          commit(GET_PROMOTER_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PROMOTER_TARGET_DATA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST);
      uploadTragetPromoterData(postData)
        .then(resp => {
          commit(UPLOAD_PROMOTER_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_PROMOTER_TARGET_DATA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST);
      getPromoterLeaderDataList(postData)
        .then(resp => {
          commit(GET_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST);
      uploadTragetPromoterLeaderData(postData)
        .then(resp => {
          commit(UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [TARGET_PROMOTER_CHECK_FILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TARGET_PROMOTER_CHECK_FILE_REQUEST);
      targetPromoterFileCheck(postData)
          .then(resp => {
            commit(TARGET_PROMOTER_CHECK_FILE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(TARGET_PROMOTER_CHECK_FILE_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST);
      uploadTargetPromoterById(postData)
          .then(resp => {
            commit(UPLOAD_TARGET_PROMOTER_BY_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(UPLOAD_TARGET_PROMOTER_BY_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST);
      targetPromoterLeaderFileCheck(postData)
          .then(resp => {
            commit(TARGET_PROMOTER_LEADER_CHECK_FILE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(TARGET_PROMOTER_LEADER_CHECK_FILE_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST);
      uploadTargetPromoterLeaderById(postData)
          .then(resp => {
            commit(UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_SUB_REGION_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_SUB_REGION_TARGET_DATA_LIST_REQUEST);
      getSubRegionTarget(postData)
          .then(resp => {
            commit(GET_SUB_REGION_TARGET_DATA_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_SUB_REGION_TARGET_DATA_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_SUB_REGION_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_SUB_REGION_LIST_REQUEST);
      getSubRegion(postData)
          .then(resp => {
            commit(GET_SUB_REGION_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_SUB_REGION_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [TARGET_SUB_REGION_CHECK_FILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TARGET_SUB_REGION_CHECK_FILE_REQUEST);
      targetSubRegionFileCheck(postData)
          .then(resp => {
            commit(TARGET_SUB_REGION_CHECK_FILE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(TARGET_SUB_REGION_CHECK_FILE_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST);
      uploadSubRegionTargetById(postData)
          .then(resp => {
            commit(UPLOAD_TARGET_SUB_REGION_BY_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(UPLOAD_TARGET_SUB_REGION_BY_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_DEALER_TARGET_DATA_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DEALER_TARGET_DATA_LIST_REQUEST);
      getDealerTarget(postData)
          .then(resp => {
            commit(GET_DEALER_TARGET_DATA_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_DEALER_TARGET_DATA_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_DEALER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DEALER_LIST_REQUEST);
      getDealer(postData)
          .then(resp => {
            commit(GET_DEALER_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_DEALER_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [TARGET_DEALER_CHECK_FILE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TARGET_DEALER_CHECK_FILE_REQUEST);
      targetDealerFileCheck(postData)
          .then(resp => {
            commit(TARGET_DEALER_CHECK_FILE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(TARGET_DEALER_CHECK_FILE_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_TARGET_DEALER_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_TARGET_DEALER_BY_ID_REQUEST);
      uploadDealerTargetById(postData)
          .then(resp => {
            commit(UPLOAD_TARGET_DEALER_BY_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(UPLOAD_TARGET_DEALER_BY_ID_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_WEEKLY_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_WEEKLY_VISITATION_REQUEST);
      getWeeklyVisitation(postData)
          .then(resp => {
            commit(GET_WEEKLY_VISITATION_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_WEEKLY_VISITATION_ERROR, err);
            reject(err);
          });
    });
  },
  [DOWNLOAD_WEEKLY_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_WEEKLY_VISITATION_REQUEST);
      downloadWeeklyVisitation(postData)
          .then(resp => {
            commit(DOWNLOAD_WEEKLY_VISITATION_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(DOWNLOAD_WEEKLY_VISITATION_ERROR, err);
            reject(err);
          });
    });
  },
  [UPLOAD_WEEKLY_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_WEEKLY_VISITATION_REQUEST);
      uploadWeeklyVisitation(postData)
          .then(resp => {
            commit(UPLOAD_WEEKLY_VISITATION_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(UPLOAD_WEEKLY_VISITATION_ERROR, err);
            reject(err);
          });
    });
  },
  [DOWNLOAD_VISITATION_TEMPLATE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_VISITATION_TEMPLATE_REQUEST);
      downloadVisitationTemplate(postData)
          .then(resp => {
            commit(DOWNLOAD_VISITATION_TEMPLATE_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(DOWNLOAD_VISITATION_TEMPLATE_ERROR, err);
            reject(err);
          });
    });
  },
  [VALIDATE_VISITATION_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(VALIDATE_VISITATION_REQUEST);
      validWeeklyVisitation(postData)
          .then(resp => {
            commit(VALIDATE_VISITATION_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(VALIDATE_VISITATION_ERROR, err);
            reject(err);
          });
    });
  },
};

const mutations = {
  GET_COUNTRY_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_COUNTRY_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_COUNTRY_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_COUNTRY_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_COUNTRY_TARGET_DATA_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_COUNTRY_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_PROMOTER_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_PROMOTER_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_PROMOTER_LEADER_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  TARGET_PROMOTER_CHECK_FILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TARGET_PROMOTER_CHECK_FILE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  TARGET_PROMOTER_CHECK_FILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_TARGET_PROMOTER_BY_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_PROMOTER_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  TARGET_PROMOTER_LEADER_CHECK_FILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TARGET_PROMOTER_LEADER_CHECK_FILE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  TARGET_PROMOTER_LEADER_CHECK_FILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_PROMOTER_LEADER_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_SUB_REGION_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_SUB_REGION_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_SUB_REGION_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_SUB_REGION_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_SUB_REGION_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_SUB_REGION_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  TARGET_SUB_REGION_CHECK_FILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TARGET_SUB_REGION_CHECK_FILE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  TARGET_SUB_REGION_CHECK_FILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_SUB_REGION_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_TARGET_SUB_REGION_BY_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_SUB_REGION_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_DEALER_TARGET_DATA_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DEALER_TARGET_DATA_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_DEALER_TARGET_DATA_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_DEALER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DEALER_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_DEALER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  TARGET_DEALER_CHECK_FILE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TARGET_DEALER_CHECK_FILE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  TARGET_DEALER_CHECK_FILE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_DEALER_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_TARGET_DEALER_BY_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_TARGET_DEALER_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  GET_WEEKLY_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_WEEKLY_VISITATION_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  GET_WEEKLY_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_WEEKLY_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_WEEKLY_VISITATION_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_WEEKLY_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_WEEKLY_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_WEEKLY_VISITATION_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_WEEKLY_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_VISITATION_TEMPLATE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_VISITATION_TEMPLATE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_VISITATION_TEMPLATE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  VALIDATE_VISITATION_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  VALIDATE_VISITATION_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  VALIDATE_VISITATION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
