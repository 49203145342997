import { render, staticRenderFns } from "./promoters.vue?vue&type=template&id=095c2c62&"
import script from "./promoters.vue?vue&type=script&lang=js&"
export * from "./promoters.vue?vue&type=script&lang=js&"
import style0 from "./promoters.vue?vue&type=style&index=0&id=095c2c62&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports