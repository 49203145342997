<template>
  <div class="row">
    <div class="col-md-12 uploadSection">
      <div class="input-group mb-3">
        <div class="vue-xlsx-container btn-lg">
          <button type="button" class="xlsx-button" @click="$refs.file.click()">
            Select File 
          <small>** Only .xlsx files are allowed.</small>
          </button>
        </div>
        <input
          id="file"
          ref="file"
          v-validate="'required'"
          type="file"
          name="file"
          accept=".xlsx, .xls"
          class="d-none"
          @change="handleFileUpload($event)"
        />
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <a v-if="doExportDefault" class="link lnv-link" @click="doExportDefault">
        <i class="fa fa-download"></i>
        <span class="ml-2">Download Excel Template File</span>
      </a>
      <br />
      <br />

      <p v-if="contentHtml" class="black-text">{{ contentHtml }}</p>
    </div>

    <div v-if="isSubmit" class="col-lg-12 mb-5">
      <div v-if="!errorFilePath" class="p-3 alert-success">
        Validation completed, please click submit to continue
      </div>
      <div v-else class="alert-danger p-4">
        We found that file you uploaded contains error(s). Please re-upload the file (for you convenience we highlighted errors in red)
        <a :href="errorFilePath" target="_blank" class="btn btn-success float-right download-btn">Download</a>
      </div>
    </div>

    <div v-if="isSubmit && !errorFilePath" class="col-lg-12">
      <button type="submit" class="btn btn-secondary pull-right" @click="handleSubmit($event)">
        {{ submitButton }}
      </button>
      <button type="button" class="btn btn-light pull-right" @click="close()">Cancel</button>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
import {
  VALIDATE_VISITATION_REQUEST,
  UPLOAD_WEEKLY_VISITATION_REQUEST,
  DOWNLOAD_VISITATION_TEMPLATE_REQUEST
} from "../../store/modules/target/actions";

export default {
  name: "UploadVisitationForm",
  components: {},
  props: {
    closeModal: { type: Function, default: () => {} },
    submitButtonText: { type: String, default: "" },
    getUploadFile: { type: Function, default: () => {} },
    filters: { type: Object, default: () => {}},
  },
  computed: {},
  data() {
    return {
      validateFields: this.validations,
      contentHtml: "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the colunm labels in the sample file and make sure the file extension is .xlsx file.",
      displayDataTable: this.showDataList,
      submitButton: this.submitButtonText ? this.submitButtonText : "Upload",
      rows: [],
      headers: [],
      file: {},
      errorFilePath: null,
      isSubmit: false,
      loaderOption:{
        container:  this.$refs.formContainer,
        canCancel: false,
        width: 125,
        height: 125,
        color: '#f70301',
        backgroundColor: '#4f4f4f'
      },
      base64: null
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if(file) {
        let pdata = {}
        this.getBase64(file).then(data => {
          pdata.base64 = data;
          this.base64 = data
        }).finally(()=> this.checkFile(pdata))
      }
    },
    async checkFile(base64Obj){
      this.isSubmit = false;
      const res = await this.$store.dispatch(VALIDATE_VISITATION_REQUEST, base64Obj);
      if (res.resultCode === 0) {
        this.errorFilePath = res.content.errorFilePath;
        this.isSubmit = true;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    close() {
      this.base64 = null;
      this.closeModal();
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit() {
      if(this.isSubmit && this.base64){
        let loader = this.$loading.show(this.loaderOption);
        let data = {
          base64: this.base64
        }
        this.$store.dispatch(UPLOAD_WEEKLY_VISITATION_REQUEST, data).then(res => {
          if (res.resultCode === 0) {
            Message({
              message: "Upload completed",
              type: "success",
              duration: 5 * 1000
            });
            loader.hide();
            this.close();
          }
        });        
      }
    },
    doExportDefault() {
      let pdata = {
        countryId: this.filters.countrySelected.id,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        wf: this.filters.weekSelected.value,
      };
      let loader = this.$loading.show(this.loaderOption);
      this.$store.dispatch(DOWNLOAD_VISITATION_TEMPLATE_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          window.open(res.content.link)
        }
      })
      .finally(() => loader.hide())
    },
  },
  created() {},
  mounted() {}
};
</script>

<style>
.vue-xlsx-container {
  width: 100% !important;
}
.xlsx-button {
  width: 100%;
  height: 130px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #ccc !important;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 7px 9px;
  font-size: 15px;
  border-radius: 4px;
  color: #949090 !important;
  background-color: #f9f2f2 !important;
}

.link {
  cursor: pointer;
}

.uploadSection {
  height: 120px;
}

.download-btn{
  bottom: 4px;
}
</style>
