<template>
  <BModal
    ref="key-store-modal"
    v-model="isModalOpen"
    :title="selectedOutlet.name ? $options.filters.textCase(selectedOutlet.name) : ''"
    size="lg"
    hide-footer
  >
    <div id="key-store-modal">
      <!-- LOADING -->
      <div v-if="isLoading" class="loading mt-3"></div>

      <!-- CONTENT -->
      <div v-else class="m-4">
        <!-- VERTICAL COLLAPSE CONTENT -->
        <div v-if="details" class="accordion" role="tablist">

          <!-- latestVisitation -->
          <b-row v-if="details.latestVisitation" align-v="center" class="visit-row">
            <b-col><b>Latest Visitation: </b>
              {{ details.latestVisitation.checkOutDate || ' - ' }}
              <br>
              ({{ details.latestVisitation.merchandiserName || " - " }})
            </b-col>
            <b-col>
              <b>
                Score {{ limitDecimalNum(details.latestVisitation.visitationScore, 1) || '0' }} / Grade
                {{ details.latestVisitation.visitationGrade }}
              </b>
            </b-col>
            <b-col cols="2">
              <b-button
                v-b-toggle.latestVisitation
                class="btn btn-borderless btn-xs"
								:disabled="isEmpty(details.latestVisitation)"
              >
                View
                <i class="ml-auto mdi mdi-chevron-right "></i>
              </b-button>
            </b-col>
          </b-row>
					<BCollapse
						id="latestVisitation"
						accordion="visit-accordion"
						role="tabpanel"
            @show="getTask('latestTask',details.latestVisitation.visitationId)"
					>
						<b-row class="content">
              <VisitationTasks ref="latestTask" />
            </b-row>
					</BCollapse>

          <!-- previousVisitation -->
          <b-row v-if="details.previousVisitation" align-v="center" class="visit-row">
            <b-col><b>Previous Visitation: </b>
              {{ details.previousVisitation.checkOutDate || ' - ' }}
              <br>
              ({{ details.previousVisitation.merchandiserName || " - " }})
            </b-col>
            <b-col>
              <b>
                Score {{ limitDecimalNum(details.previousVisitation.visitationScore, 1) || '0' }} / Grade
                {{ details.previousVisitation.visitationGrade }}
              </b>
            </b-col>
            <b-col cols="2">
              <b-button
                v-b-toggle.previousVisitation
                class="btn btn-borderless btn-xs"
								:disabled="isEmpty(details.previousVisitation)"
              >
                View
                <i class="ml-auto mdi mdi-chevron-right "></i>
              </b-button>
            </b-col>
          </b-row>
          <BCollapse
            id="previousVisitation"
            accordion="visit-accordion"
            role="tabpanel"
            @show="getTask('prevTask',details.previousVisitation.visitationId)"
          >
            <b-row class="content">
              <VisitationTasks ref="prevTask" />
            </b-row>
          </BCollapse>

           <!-- shareOfSpaceRank -->
          <b-row v-if="details.shareOfSpaceRank" align-v="center" class="visit-row">
            <b-col><b>Demo Share Of Space: </b></b-col>
            <b-col>
              <b>Rank {{ getRankNumber }}</b>
            </b-col>
            <b-col cols="2">
              <b-button
                v-b-toggle.shareOfSpaceRank
                class="btn btn-borderless btn-xs"
								:disabled="rankList.length <= 0"
              >
                View
                <i class="ml-auto mdi mdi-chevron-right "></i>
              </b-button>
            </b-col>
          </b-row>
          <BCollapse
            id="shareOfSpaceRank"
            accordion="visit-accordion"
            role="tabpanel"
          >
            <b-row class="content">
              <ul class="ranking">
                <li v-for="data in rankList" :key="data.brandName">
                  <span><span class="rank-num">{{ data.rank }}</span> <b>{{ data.brandName }} &nbsp; : &nbsp;</b></span>
                  <span><b>{{ data.percentage }}%</b></span>
                </li>
              </ul>
            </b-row>
          </BCollapse>

          <!-- totalFixtures -->
          <b-row v-if="details.totalFixtures" align-v="center" class="visit-row">
            <b-col><b>Total Lenovo Fixtures: </b></b-col>
            <b-col>
              <b>{{ getTotalFixturesCount }} Units</b>
            </b-col>
            <b-col cols="2">
              <b-button
                v-b-toggle.totalFixtures
                class="btn btn-borderless btn-xs"
								:disabled="getTotalFixturesCount <= 0"
              >
                View
                <i class="ml-auto mdi mdi-chevron-right "></i>
              </b-button>
            </b-col>
          </b-row>
          <BCollapse
            id="totalFixtures"
            accordion="visit-accordion"
            role="tabpanel"
          >
             <b-row class="content">
              <ul class="ranking">
                <li v-for="(data, index) in getTotalFixtures" :key="index">
                  <span><span class="rank-num">{{ index +1 }}</span> <b>{{ data.fixtureName }} &nbsp; : &nbsp;</b></span>
                  <span><b>{{ data.fixtureCount }}</b></span>
                </li>
              </ul>
            </b-row>
          </BCollapse>



        </div>
      </div>

      <!-- FOOTER -->
      <b-row class="text-center">
        <b-col>
          <button
            type="button"
            class="btn btn-light btn-xs"
            @click="isModalOpen = !isModalOpen"
          >
            Cancel
          </button>
        </b-col>
      </b-row>

    </div>
  </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { BCollapse } from "bootstrap-vue";
import { MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST } from "../../store/modules/outlet/actions";
import VisitationTasks from './visit_task_tabs.vue'
import { limitDecimalNum } from "@/utils/helpers"
// import cloneDeep from "lodash/cloneDeep";

export default {
  name: "KeyStoreModal",
  components: {
    BModal: BModal,
    BCollapse: BCollapse,
    VisitationTasks
  },
  filters: {
    textCase(str){
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    selectedOutlet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    isModalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getRankNumber() {
      let found = this.rankList.find(el => el.brandName === "Lenovo")
			return found ? found.rank : 0
		},
    getTotalFixturesCount() {
      let x = this.details.totalFixtures
      let count = 0
      x.map( x => { count = count + x.fixtureCount})
      return x ? count : 0
    },
    getTotalFixtures() {
      let list = this.details.totalFixtures || []
      // Hide 0 fixtureCount from UI
      const result = list.filter(x => x.fixtureCount > 0);
      return result
    }
  },
  data() {
    return {
      isLoading: false,
      details: {},
      rankList: [],
      limitDecimalNum
    };
  },
  watch: {
    isModalOpen(val) {
      if (val) this.getVisitByOutletId();
    }
  },
  methods: {
    getVisitByOutletId() {
      this.isLoading = true;
      let pdata = {
        id: this.selectedOutlet.id
      };
      this.$store
        .dispatch(MERCHANDISER_VISIT_BY_OUTLET_ID_REQUEST, pdata)
        .then(res => {
          if (res.content && res.resultCode === 0) {
            this.details = res.content;
            this.getRankList()
            this.isLoading = false;
          }
        });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    getTask(ref,vId) {
      this.$refs[ref].getVisitationTask(vId)
    },
    getRankList() {
      let prevValue = -1
      let prevRank = 0
      this.rankList = []

      this.details.shareOfSpaceRank.forEach((el, index) => {
        let rank = index + 1
        if (el.percentage == prevValue) {
          rank = prevRank
        }
        this.rankList.push({
          brandName: el.brandName,
          rank: rank,
          percentage: el.percentage
        })
        prevValue = el.percentage
        prevRank = rank
      })
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
#key-store-modal {
  color: #464a53;

	.visit-row {
		padding: 1rem 0;
		border-bottom: 1px solid #dedede;
		.btn {
			color: #f70201 !important;
			font-weight: bold;
		}
	}

	.accordion .visit-row.row:nth-last-child(2) {
			border-bottom: 0!important;
	}

  .content {
    padding: 1rem 0;
    border-bottom: 1px solid #dedede;
    display: flex;
    .ranking {
      min-width: 20%;
      width: auto;
      li {
        display: flex;
        justify-content: space-between;
      }
      .rank-num {
        background: #ebebeb;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;
        font-size: 11px;
        line-height: 20px;
        margin-right: 5px;
      }
    }
  }

  .collapse:last-child .content {
		border-top: 1px solid #dedede;
	}

}
</style>
