<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <VueBootstrap4Table
          :classes="classes"
          :rows="filterState"
          :columns="columns"
          :config="config"
          :total-rows="total"
          @on-change-query="onChangeQuery"
          @refresh-data="getOutletList"
        >
          <template slot="sort-asc-icon">
            <i class="mdi mdi-sort-ascending"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="mdi mdi-sort-descending"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="mdi mdi-sort-variant"></i>
          </template>
          <template slot="empty-results">
            <div
              v-if="!isDataLoading"
              class="bold-text"
              v-text="$ml.get('no_dealer_found')"
            ></div>
            <div
              v-if="isDataLoading"
              class="loading mt-3"
            ></div>
          </template>

          <template slot="promotersCount" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModalCount(props.row, 2)">
              <span style="color:#F70201">
                {{ props.row.promotersCount }}
              </span>
            </div>
          </template>

          <template slot="salespersonsCount" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleSalespersonModal(props.row.id)">
              <span style="color:#F70201">
                {{ props.row.salespersonsCount }}
              </span>
            </div>
          </template>

          <template slot="dealerOutletsCount" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleOutletModal(props.row.id)">
              <span style="color:#F70201">
                {{ props.row.dealerOutletsCount }}
              </span>
            </div>
          </template>

          <!-- <template slot="oldLppCode" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 1)">
              <span style="color:#F70201">
                {{ props.row.oldLppCode }}
              </span>
            </div>
          </template> -->

          <template slot="prmLppName" slot-scope="props">
            <div class="inner-data" style="cursor: pointer" @click="toggleModal(props.row, 1)">
              <span style="color:#F70201">
                {{ props.row.prmLppName }}
              </span>
            </div>
          </template>

          <template slot="dealerName" slot-scope="props">
            <div class="inner-data">
              <span class="bold-text">{{ props.row.dealerName }}</span>
            </div>
          </template>

          <template slot="status" slot-scope="props">
            <lnvStatusTextBox :status="props.row.status" />
          </template>
          <template slot="bulkUpload" slot-scope="props">
            <span>{{ props.row.bulkUpload ? "Yes" : "No" }}</span>
          </template>

          <!-- <template slot="Action" slot-scope="props">
            <div
              class="inner-buttons btn-group float-right"
              role="group"
            >
              <button
                v-if="canEdit"
                class="btn btn-warning btn-xs"
                @click="toggleModal(props.row, 4)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
          </template> -->
        </VueBootstrap4Table>
      </div>
      <BModal ref="dealer-outlet-modal" size="lg" hide-footer title="Outlets">
        <div v-if="isOutletListLoading" class="loading"></div>
        <DealerOutletList v-else :outlet-list="outletList" />
      </BModal>

      <BModal ref="dealer-salesp-modal" size="lg" hide-footer title="Salesperson">
        <div v-if="isSalespersonListLoading" class="loading"></div>
        <DealerSalesPList v-else :salesp-list="salespersonList" />
      </BModal>
    </div>
  </div>
</template>

<script>

// import moment from "moment-timezone";
//import {GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST} from "@/store/modules/staff/actions";
import lnvStatusTextBox from "../ui/statusTextBox";
import VueBootstrap4Table from "../ui/VueBootstrap4Table";
import DealerOutletList from "../dealer/dealer_outlets";
import DealerSalesPList from "../dealer/dealer_salespersons";
import { BModal } from "bootstrap-vue";
import { DEALER_REQUEST } from "../../store/modules/dealer/actions";

export default {
  name: "OutletsPage",
  components: {
    lnvStatusTextBox: lnvStatusTextBox,
    VueBootstrap4Table: VueBootstrap4Table,
    DealerOutletList: DealerOutletList,
    DealerSalesPList: DealerSalesPList,
    BModal
  },
  props: {
    total: {type: Number, default: 0},
    filterState: {type: [Array, Boolean], default: false},
    isDataLoading: {type: Boolean, default: false},
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Outlets",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false,
          init: {
            value : ""
          }
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      outletList: [],
      isOutletListLoading: false,
      salespersonList: [],
      isSalespersonListLoading: false,
      columns: [
        {
          name: "id",
          label: "ID",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          initial_sort: true,
          initial_sort_order: "asc",
          visibility: false,
        },
        {
          name: "countryCode",
          label: "CTRY",
          sort: true,
          width: "4%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "dealerName",
          label: "Dealer Name",
          sort: true,
          // width: "13%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        // {
        //   name: "businessRegNo",
        //   label: "Business Registration Number",
        //   sort: true,
        //   width: "13%",
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        // },
        {
          name: "prmLppName",
          label: "MDM Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "oldLppCode",
          label: "LPP Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "accreditation",
          label: "Accreditation",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        // {
        //   name: "bulkUpload",
        //   label: "Allow bulk upload",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        // },
        {
          name: "status",
          label: "Dealer Status",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "actual",
          label: "Actual",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        // {
        //   name: "picsCount",
        //   label: "# PICs",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        // },
        {
          name: "dealerOutletsCount",
          label: "# Outlets",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        // {
        //   name: "promotersCount",
        //   label: "# Promoters",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        // },
        // {
        //   name: "salespersonsCount",
        //   label: "# Salespersons",
        //   sort: true,
        //   row_text_alignment: "text-left",
        //   column_text_alignment: "text-left",
        //   visibility: !this.isLeader,
        // },
      ],
      isLeader: false,
    };
  },
  methods: {
    onChangeQuery(){
      this.$emit("on-change-query");
    },
    getOutletList(){
      this.$emit("get-outlet-list");
    },
    toggleModal(row, view){
      this.$emit("toggle-modal", row, view);
    },
    toggleOutletModal(id){
      this.isOutletListLoading = true;
      this.$store.dispatch(DEALER_REQUEST, { id: id }).then(res => {
        if(res.content && res.resultCode === 0){
          this.outletList = res.content.outletList;
          this.isOutletListLoading = false;
        }
      })
      this.$refs["dealer-outlet-modal"].toggle("#toggle-btn");
    },
    toggleSalespersonModal(id){
      this.isSalespersonListLoading = true;
      this.$store.dispatch(DEALER_REQUEST, { id: id }).then(res => {
        if(res.content && res.resultCode === 0){
          this.salespersonList = res.content.salespersonList;
          this.isSalespersonListLoading = false;
        }
      })
      this.$refs["dealer-salesp-modal"].toggle("#toggle-btn");
    }
  },
  
  mounted() {
    this.isLeader = this.$store.getters.isPromoLeader
    if (!this.isLeader) {
      this.columns.push(
        {
          name: "salespersonsCount",
          label: "# Salespersons",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          visibility: !this.isLeader,
        }
      )
    }
    if(this.$route.params.lppNumber !== undefined) {
      this.config.global_search.init.value = this.$route.params.lppNumber
      this.onChangeQuery();
      this.getOutletList();
    }
  },
  created() {
  }
};
</script>

<style scoped>
.barcode-icon {
  font-size: 3em;
}
.table>thead>tr>th {
  font-weight: 500;
  font-size: 13px;
}
.row-btn-wrapper{
  margin: auto 0px 0px auto;
}
</style>
