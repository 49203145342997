<template>
  <div id="lock-serial-number">
    <breadcrumb title="Dashboard" subtitle="Lock SERIAL Numbers" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group flex-grow-1">
                  <button class="btn btn-primary pull-right" @click="downloadLockSerial(null)">
                    <i class="fa fa-download"></i> Download
                  </button>
                  <button v-if="!isCountryView" class="btn btn-primary pull-right" @click="toggleModal(null)">
                    <i class="fa fa-upload"></i> Upload
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-12">
                <VueBootstrap4Table
                  :classes="classes"
                  :rows="serialList"
                  :columns="columns"
                  :config="config"
                  :total-rows="total"
                  @on-change-query="onChangeQuery"
                  @refresh-data="getLockSerialNumberList"
                >
                  <template slot="sort-asc-icon">
                    <i class="mdi mdi-sort-ascending"></i>
                  </template>
                  <template slot="sort-desc-icon">
                    <i class="mdi mdi-sort-descending"></i>
                  </template>
                  <template slot="no-sort-icon">
                    <i class="mdi mdi-sort-variant"></i>
                  </template>
                  <template slot="empty-results">
                    <div class="bold-text">No Lock Serial Number Found!</div>
                  </template>

                  <template slot="fileName" slot-scope="props">
                    <a
                      class="lnv-link"
                      href="javascript:void(0)"
                      @click="downloadLockSerial(props.row)"
                      >{{ props.row.fileName ? props.row.fileName : "-" }}</a>
                  </template>
                  <template slot="Action" slot-scope="props">
                    <div v-if="!props.row.unlockDate && !isCountryView">
                      <div v-if="compareDate(props.row.lockDate)" class="inner-buttons float-left">
                        <button class="btn btn-danger btn-xs" @click="toggleModal(props.row)">
                          <i class="fa fa-exchange"></i>
                          <span> UNLOCK</span>
                        </button>
                      </div>
                      <div v-if="!compareDate(props.row.lockDate)" class="inner-buttons float-left">
                        <button class="btn btn-danger btn-xs" @click="cancelLock(props.row)">
                          <i class="fa fa-exchange"></i>
                          <span> CANCEL</span>
                        </button>
                      </div>
                    </div>
                    <div v-if="props.row.unlockDate">
                      Unlocked on: {{ props.row.unlockDate }} <br />
                      Effective Date: {{ props.row.updateDate }}
                    </div>
                  </template>
                </VueBootstrap4Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BModal ref="form-modal" size="lg" hide-footer :title="modalTitle">
      <LockForm
        :close-modal="toggleModal"
        :can-edit="canEdit"
        :selected-serial="serial"
        :load-data="getLockSerialNumberList"
      />
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { Message } from "element-ui";
import { BModal } from "bootstrap-vue";
import { export_json_to_excel } from "../../utils/Export2Excel";
import LockForm from "../../components/serialNo/lock_unlock_form";
import {
  LOCK_SERIAL_LIST_REQUEST,
  CANCEL_SERIAL_REQUEST,
  LOCK_SERIAL_DOWNLOAD_REQUEST
} from "../../store/modules/serialNo/actions";
import moment from "moment";

export default {
  name: "LockSerialNumber",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    breadcrumb: breadcrumb,
    BModal: BModal,
    LockForm: LockForm
  },
  computed: {},
  data() {
    return {
      serialList: [],
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Lock Serial Number",
        card_mode: false,
        show_refresh_button: true,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          visibility: false,
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "fileName",
          label: "File Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "lockDate",
          label: "Effective Date",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "uploadDate",
          label: "Upload Date",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "Action",
          label: "Action",
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      total: 0,
      today: moment().format("L"),
      serial: {},
      canEdit: false,
      modalTitle: "Upload Lock SERIAL Numbers",
      downloadList: [],
      isCountryView: false
    };
  },
  methods: {
    compareDate(lockDate) {
      const date = moment(lockDate).format("x");
      const now = moment(this.today).format("x");
      return now >= date;
    },
    getLockSerialNumberList() {
      this.$store.dispatch(LOCK_SERIAL_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.serialList = res.content;
        }
      });
    },
    toggleModal(data) {
      if (data) {
        this.modalTitle = "Unlock SERIAL Numbers";
        this.serial = data;
        this.canEdit = true;
      } else {
        this.modalTitle = "Upload Lock SERIAL Numbers";
        this.serial = {};
        this.canEdit = false;
      }
      this.$refs["form-modal"].toggle("#toggle-btn");
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getLockSerialNumberList();
    },
    cancelLock(row) {
      const data = {
        id: row.id
      };
      this.$buefy.dialog.confirm({
        message: "Do you want to <b class='red-heighlighted'>cancel</b> ?",
        onConfirm: () => {
          this.$store.dispatch(CANCEL_SERIAL_REQUEST, data).then(() => {
            this.getLockSerialNumberList();
            Message({
              message: "Successfully cancelled.",
              type: "success",
              duration: 5 * 1000
            });
          });
        }
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j] ? v[j] : "-";
        })
      );
    },
    doExport(name) {
      let tHeader = ["Serial Number", "Submission by", "Submission Date"];
      let filterVal = ["serial", "submittedBy", "submissionDate"];
      const data = this.formatJson(filterVal, this.downloadList);
      const fileName = name ? name : "LockSerialDownload";
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "LockSerialDownload",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    downloadLockSerial(data) {
      const paramId = data ? data.id : null;
      const fileName = data ? data.fileName.replace(/\.[^/.]+$/, "") : null;
      this.downloadList = [];
      this.$store.dispatch(LOCK_SERIAL_DOWNLOAD_REQUEST, paramId).then(res => {
        if (res.content && res.resultCode === 0) {
          this.downloadList = res.content;
          this.doExport(fileName ? fileName : null);
        }
      });
    }
  },
  mounted() {
    this.isCountryView = this.$store.getters.isCountryView;
    this.getLockSerialNumberList();
  }
};
</script>
