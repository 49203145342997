<template>
  <div id="regional-year" class="row w-100">
    <!-- Geo chart -->
    <div
      v-if="!(filters.promoterTypeSelected.value === 'PT03' && filters.countrySelected.value === 'PH')"
      id="geoDatContainer"
      class="col-lg-6 border d-flex justify-content-center"
    >
      <soHeatMap
        id="soHeatMap"
        ref="soHeatMap"
        :filters.sync="filters"
        grouping="yearly"
        :local-filters.sync="localFilters"
        :product-filters.sync="productFilters"
      ></soHeatMap>
    </div>

    <div v-if="!(filters.promoterTypeSelected.value === 'PT03' && filters.countrySelected.value === 'PH')" class="col-lg-6 float-left border">
      <actualSObySubRegion
        id="actualSObySubRegion"
        :key="actualSOBySubRegionKey"
        ref="actualSObySubRegion"
        :filters.sync="filters"
        grouping="yearly"
        :local-filters.sync="localFilters"
        :product-filters.sync="productFilters"
        :enlarge-chart="enlargeChart"
      ></actualSObySubRegion>
    </div>

    <!-- /Geo chart -->

    <!-- SO Charts(3) -->
    <div class="col-lg-12 p-0 ">
      <div class="col-lg-6 float-left border">
        <promoterSOperformance
          id="promoterSOperformance"
          :key="promoterSOperformanceKey"
          ref="promoterSOperformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :product-filters.sync="productFilters"
          :enlarge-chart="enlargeChart"
        ></promoterSOperformance>
      </div>

      <div class="col-lg-6 float-left border">
        <soPerformanceVStarget
          id="soPerformanceVStarget"
          :key="soPerformanceVStargetKey"
          ref="soPerformanceVStarget"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :product-filters.sync="productFilters"
          :enlarge-chart="enlargeChart"
        ></soPerformanceVStarget>
      </div>

      <!-- <div class="col-lg-12 float-left border">
        <div class="mt-4 ml-2 mb-4">
          <span>
            <strong>{{ dashboardData.promoterSOPerformance.name }}</strong>
          </span>
        </div>
        <combo-bar-chart
          :data.sync="dashboardData.promoterSOPerformance.data"
          :title="''"
        ></combo-bar-chart>
      </div> -->
    </div>
    <!-- /SO Charts(3) -->

    <!-- Product Performance Charts(2) -->
    <div v-if="!(filters.promoterTypeSelected.value === 'PT03' && filters.countrySelected.value === 'PH')" class="col-lg-12 border">
      <productPerformanceByCategory
        id="productPerformanceByCategory"
        :key="productPerformanceByCategoryKey"
        ref="productPerformanceByCategory"
        :filters.sync="filters"
        grouping="yearly"
        :local-filters.sync="localFilters"
        :product-filters.sync="productFilters"
        :catbcs-options.sync="catbcsOptions"
        :catws-options.sync="catwsOptions"
        :catrs-options.sync="catrsOptions"
        :catdf-options.sync="catdfOptions"
        :product-performance-by-category-active-legend-tab.sync="productPerformanceByCategoryActiveLegendTab"
        :enlarge-chart="enlargeChart"
      ></productPerformanceByCategory>
    </div>
    <div v-if="!(filters.promoterTypeSelected.value === 'PT03' && filters.countrySelected.value === 'PH')" class="col-lg-12 border">
      <productPerformanceByComponent
        id="productPerformanceByComponent"
        :key="productPerformanceByComponentKey"
        ref="productPerformanceByComponent"
        :filters.sync="filters"
        grouping="yearly"
        :local-filters.sync="localFilters"
        :product-filters.sync="productFilters"
        :cmpbcs-options.sync="cmpbcsOptions"
        :cmps-options.sync="cmpsOptions"
        :cmpws-options.sync="cmpwsOptions"
        :cmprs-options.sync="cmprsOptions"
        :cmpdf-options.sync="cmpdfOptions"
        :product-performance-by-component-active-legend-tab.sync="productPerformanceByComponentActiveLegendTab"
        :enlarge-chart="enlargeChart"
      ></productPerformanceByComponent>
    </div>
    <!-- /Product Performance Charts(2) -->

    <!-- Performance Charts(3) -->
    <div class="col-lg-12 p-0">
      <div class="col-lg-6 float-left border ">
        <promoterDealerPerformance
          id="promoterDealerPerformance"
          :key="promoterDealerPerformanceKey"
          ref="promoterDealerPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :product-filters.sync="productFilters"
          :enlarge-chart="enlargeChart"
        ></promoterDealerPerformance>
      </div>
      <div class="col-lg-6 float-left border ">
        <promoterOutletPerformance
          id="promoterOutletPerformance"
          :key="promoterOutletPerformanceKey"
          ref="promoterOutletPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :product-filters.sync="productFilters"
          :enlarge-chart="enlargeChart"
        ></promoterOutletPerformance>
      </div>
      <div class="col-lg-12 float-left border ">
        <promoterPerformance
          id="promoterPerformance"
          :key="promoterPerformanceKey"
          ref="promoterPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :product-filters.sync="productFilters"
          :enlarge-chart="enlargeChart"
        ></promoterPerformance>
      </div>
    </div>
    <!-- /Performance Charts(3) -->

    <!-- Pop up modals -->
    
    <BModal ref="overlay-graph" size="xl" hide-footer hide-header>
      <enlargeChart
        :close-modal="enlargeChart"
        :content="content"
        :modal="enlargeModal"
      />
    </BModal>

    <!-- /Pop up modals -->
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import enlargeChart from "../../components/dashboard/enlargeChartModal";
import {
  REGIONAL_DASHBOARD_DATA_GET,
  DASHBOARD_PRODUCT_FILTERS_GET,
  DASHBOARD_COMPONENT_FILTERS_GET
} from "../../store/modules/dashboard/actions";
import actualSObySubRegion from "../../components/charts/actual-so-by-sub-region";
import soHeatMap from "../../components/charts/so-heat-map";
import promoterSOperformance from "../../components/charts/promoter-so-performance";
import soPerformanceVStarget from "../../components/charts/so-performance-vs-target";
import productPerformanceByCategory from "../../components/charts/product-performance-by-category";
import productPerformanceByComponent from "../../components/charts/product-performance-by-component";
import promoterDealerPerformance from "../../components/charts/promoter-dealer-performance";
import promoterOutletPerformance from "../../components/charts/promoter-outlet-performance";
import promoterPerformance from "../../components/charts/promoter-performance";
import {DEALER_LIST_WITH_PARAMS_REQUEST} from "@/store/modules/dealer/actions";
// import { defaultQuarter } from '../../utils/helpers';
import debounce from 'lodash/debounce';
import cloneDeep from "lodash/cloneDeep"

export default {
  name: "RegionalYear",
  components: {
    // "geo-chart": geoChart,
    // "geo-chart-kr": geoChartKR,
    // "stacked-bar-chart": stackedbarChart,
    "actualSObySubRegion": actualSObySubRegion,
    "soHeatMap": soHeatMap,
    "promoterSOperformance": promoterSOperformance,
    "soPerformanceVStarget": soPerformanceVStarget,
    "productPerformanceByCategory": productPerformanceByCategory,
    "productPerformanceByComponent": productPerformanceByComponent,
    "promoterDealerPerformance": promoterDealerPerformance,
    "promoterOutletPerformance": promoterOutletPerformance,
    "promoterPerformance": promoterPerformance,
    // "combo-bar-chart": comboChart,
    // Multiselect,
    BModal,
    enlargeChart
  },
  props: {
    filters: { type: Object, default: () => {} },
    country: { type: Object, default: () => {} }
  },
  computed: {
    hasLoaded() {
      const dashboardData = Object.keys(this.dashboardData).length !== 0;

      const localFilters = Object.keys(this.localFilters).length !== 0;
      const productFilters = Object.keys(this.productFilters).length !== 0;

      const catbcsOptions = Object.keys(this.catbcsOptions).length !== 0;
      const catwsOptions = Object.keys(this.catwsOptions).length !== 0;
      const catrsOptions = Object.keys(this.catrsOptions).length !== 0;
      const catdfOptions = Object.keys(this.catdfOptions).length !== 0;
      const cmpbcsOptions = Object.keys(this.cmpbcsOptions).length !== 0;
      const cmpsOptions = Object.keys(this.cmpsOptions).length !== 0;
      const cmpwsOptions = Object.keys(this.cmpwsOptions).length !== 0;
      const cmprsOptions = Object.keys(this.cmprsOptions).length !== 0;
      const cmpdfOptions = Object.keys(this.cmpdfOptions).length !== 0;

      if (
        dashboardData &&
        localFilters &&
        productFilters &&
        catbcsOptions &&
        catwsOptions &&
        catrsOptions &&
        catdfOptions &&
        cmpbcsOptions &&
        cmpsOptions &&
        cmpwsOptions &&
        cmprsOptions &&
        cmpdfOptions
      ) {
        // this.$Progress.finish();
        return true;
      }
      return false;
    },
    sOHeatMapRegion() {
      return this.dashboardData.sOHeatMap.data.length == 2  || this.dashboardData.sOHeatMap.data[1][0] === "MM"
        ? this.dashboardData.sOHeatMap.data[1][0]
        : "142";
    },
    isCap() {
      return this.dashboardData.sOHeatMap.data.length == 2  || this.dashboardData.sOHeatMap.data[1][0] === "MM" ? false : true;
    }
  },
  data() {
    return {
      isLoading: true,
      handleDebouncedScroll: null,
      loadedComponents: [],
      enlargeModal: false,
      content: {},
      countries: [],
      dashboardData: {},
      catbcsOptions: [],
      catwsOptions: [],
      catrsOptions: [],
      catdfOptions: [],
      cmpbcsOptions: [],
      cmpsOptions: [],
      cmpwsOptions: [],
      cmprsOptions: [],
      cmpdfOptions: [],
      productFilters: {
        cmpsSelected: { name: "CPU", value: "CPU" },
        cmpbcsSelected: { name: "MOB", value: "MOB" },
        cmpwsSelected: { name: "ALL WEEKS", value: "" },
        cmprsSelected: { name: "ALL", value: "" },
        cmpdfSelected: { dealerName: "ALL DEALERS", id: "" },
        catbcsSelected: { name: "MOB", value: "MOB" },
        catwsSelected: { name: "ALL WEEKS", value: "" },
        catrsSelected: { name: "ALL", value: "" },
        catdfSelected: { dealerName: "ALL DEALERS", id: "" }
      },
      localFilters: this.filters,
      linkComponent: "",
      linkCategory: "",
      productPerformanceByCategoryActiveLegendTab: 0,
      productPerformanceByComponentActiveLegendTab: 0,
      actualSOBySubRegionKey: 0,
      promoterSOperformanceKey: 0,
      soPerformanceVStargetKey: 0,
      productPerformanceByCategoryKey: 0,
      productPerformanceByComponentKey: 0,
      promoterDealerPerformanceKey: 0,
      promoterOutletPerformanceKey: 0,
      promoterPerformanceKey: 0,
      promoterType: {...this.filters.promoterTypeSelected}
    };
  },
  watch: {
    filters: {
      deep: true,
      async handler(newValue) {
        this.filters = await newValue;
        if(newValue.promoterTypeSelected.value === 'PT03' && this.filters.countrySelected.value === 'PH'){
          this.$refs.promoterDealerPerformance.getPromoterDealerPerformance();
          this.$refs.promoterOutletPerformance.getPromoterOutletPerformance();
          this.$refs.promoterPerformance.getPromoterPerformance();
          this.$refs.promoterSOperformance.getPromoterSOperformance();
          this.$refs.soPerformanceVStarget.getSOperformanceVStarget();
          this.promoterType = newValue.promoterTypeSelected;
        } else {
          await this.getDashboardData();
        }
      }
    },
    country: {
      deep: true,
      async handler(newValue) {
        this.localFilters.countrySelected = await newValue;
        await this.getDashboardData();
      }
    }
  },
  methods: {
    enlargeChart(chart, type, extras){
      this.enlargeModal = !this.enlargeModal;
      this.content = {chart: chart, type: type, extras: extras};
      this.$refs['overlay-graph'].toggle('#toggle-btn');
    },
    async getDashboardData() {
      //remove quarter selection in yearly dashboard
      let tempFilter = cloneDeep(this.localFilters);
      delete tempFilter.quarterSelected;

      this.isLoading = true;

      // ALL components are lazy loading by this.onScroll()
      this.loadedComponents = [] // reload those component
      this.onScroll()

      await this.$store.dispatch(REGIONAL_DASHBOARD_DATA_GET, {
        grouping: "yearly",
        filters: tempFilter,
        productFilters: this.productFilters
      });
      this.dashboardData = this.$store.getters.dashboardData;
      await this.getDealers();
      this.isLoading = false;
    },
    async getProductPerformanceDropDowns() {
      const res = await this.$store.dispatch(DASHBOARD_PRODUCT_FILTERS_GET);
      this.catbcsOptions = await res.content.brandCode;
      this.catwsOptions = await res.content.week;
      this.catrsOptions = await res.content.role;
    },
    async getComponentPerformanceDropDowns() {
      const res = await this.$store.dispatch(DASHBOARD_COMPONENT_FILTERS_GET);
      this.cmpbcsOptions = await res.content.brandCode;
      this.cmpsOptions = await res.content.componentCode;
      this.cmpwsOptions = await res.content.week;
      this.cmprsOptions = await res.content.role;
    },
    async getDealers() {
      this.catdfOptions = [];
      this.cmpdfOptions = [];

      const res = await this.$store.dispatch(DEALER_LIST_WITH_PARAMS_REQUEST, {
        countryId: this.localFilters.countryIdSelected,
      });
      if (res.content) {
        this.catdfOptions = await res.content;
        this.catdfOptions.unshift({ dealerName: "ALL DEALERS", id: "" });
        this.productFilters.catdfSelected = { dealerName: "ALL DEALERS", id: "" };
        this.cmpdfOptions = this.catdfOptions;
        this.productFilters.cmpdfSelected = { dealerName: "ALL DEALERS", id: "" };
      }
    },
    addCommaSeparator(number, person) {
      if (!person) {
        if (number) {
          const rawNumber = number;
          return rawNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return 0;
        }
      } else {
        return number;
      }
    },
    onScroll() {
      const tolerance = 600 // load before reaching

      const componentToLoad = ['soHeatMap', 'actualSObySubRegion', 'promoterSOperformance',
      'soPerformanceVStarget', 'productPerformanceByCategory', 'productPerformanceByComponent',
      'promoterDealerPerformance', 'promoterOutletPerformance', 'promoterPerformance']

      componentToLoad.forEach(id => {
        const idYPosition = document.getElementById(id).getBoundingClientRect().top;
        const isLoaded = this.loadedComponents.findIndex(el => el == id) >= 0
        if (idYPosition == 0) return // Fix initial loading when all position is 0

        if (!isLoaded && idYPosition - tolerance < 0) {
          if (id == 'soHeatMap') this.$refs.soHeatMap.getSOHeatMap();
          if (id == 'actualSObySubRegion') this.$refs.actualSObySubRegion.getActualSObySubRegion();
          if (id == 'promoterSOperformance') this.$refs.promoterSOperformance.getPromoterSOperformance();
          if (id == 'soPerformanceVStarget') this.$refs.soPerformanceVStarget.getSOperformanceVStarget();
          if (id == 'productPerformanceByCategory') this.$refs.productPerformanceByCategory.getProductPerformanceByCategory();
          if (id == 'productPerformanceByComponent') this.$refs.productPerformanceByComponent.getProductPerformanceByComponent();
          if (id == 'promoterDealerPerformance') this.$refs.promoterDealerPerformance.getPromoterDealerPerformance();
          if (id == 'promoterOutletPerformance') this.$refs.promoterOutletPerformance.getPromoterOutletPerformance();
          if (id == 'promoterPerformance') this.$refs.promoterPerformance.getPromoterPerformance();

          this.loadedComponents.push(id)
        }
      })
    }
  },
  mounted() {
    this.getProductPerformanceDropDowns();
    this.getComponentPerformanceDropDowns();
    this.getDashboardData();

    // Lazy Loading components by scrolling
    this.handleDebouncedScroll = debounce(this.onScroll, 100);
    document.getElementsByClassName('main-container')[0]
      .addEventListener('scroll', this.handleDebouncedScroll)
    this.$nextTick(() => {
      this.onScroll(); // needed for initial loading on page
    })
  },
  beforeDestroy() {
    document.getElementsByClassName('main-container')[0]
      .removeEventListener('scroll', this.handleDebouncedScroll)
  }
};
</script>
<style lang="scss">
#geoDatContainer {
  overflow: hidden;
  #geoDataInfoContainer {
    background: white;
    height: fit-content;
    width: fit-content;
    z-index: 1;
    right: 0;
    bottom: 0;
    li {
      font-size: 1rem;
    }
  }
  #geoColorInfoContainer {
    background: white;
    height: fit-content;
    width: fit-content;
    z-index: 1;
    left: 0;
    bottom: 0;
    li {
      font-size: 1rem;
    }
  }
  #toolTipContainer {
    g {
      display: flex;
      align-items: center;
      text:first-child {
        margin-right: 0.5em;
        font-weight: bold;
        font-size: 12px;
      }
    }
    z-index: 1;
    background: white;
    border: solid 1px #bdbdbd;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
    font-size: 12px;
    padding: 0.5em 1em;
    -moz-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.6);
    position: absolute;
    top: 0;
    right: 0;
  }
  #geoHeatMapTitle {
    z-index: 1;
    left: 0;
    margin-left: 1em;
    margin-top: 1em;
  }
}
#subRegionLowerSection,
#promoterPerformanceLowerSection,
#top10subRegionLowerSection,
#top10dealersLowerSection,
#top10outletsLowerSection,
#productPerformanceByCategoryLowerSection,
#productPerformanceByComponentLowerSection,
#mytopdealersLowerSection,
#idtopdealersLowerSection,
#phtopdealersLowerSection,
#thtopdealersLowerSection,
#sgtopdealersLowerSection,
#twtopdealersLowerSection,
#vntopdealersLowerSection,
#hktopdealersLowerSection {
  position: relative;
  overflow: hidden;
  height: 40px;
}
#krtopdealersLowerSection {
  position: relative;
  overflow: hidden;
  height: 40px;
}
#roitopdealersLowerSection {
  position: relative;
  overflow: hidden;
  height: 40px;
}
#actualsubRegionLowerSection {
  height: 350px;
}
.legendIcon{
  margin-top: 3px;
  margin-bottom: 3px;
  height: 1.5em;
}
</style>
