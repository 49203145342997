<template>
  <div id="download-visitation">
    <breadcrumb
      title="Dashboard"
      subtitle="Download"
      sub-sub-title="Visitation"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs v-model="activeTab">
              <b-tab-item key="0" label="Visitation">
                <div class="card">
                  <div class="card-body">
                    <div class="contact-list-wrapper">
                      <div class="row mb-5 inner-data custom-control">
                        <input
                          v-model="type"
                          name="byYearAndQuarter"
                          type="radio"
                          value="byYearAndQuarter"
                        />
                        <label class="text-label sales-data mr-4" @click="changeType('byYearAndQuarter')">By Year and Quarter</label>
                        <input
                          v-model="type"
                          type="radio"
                          name="byDate"
                          value="byDate"
                        />
                        <label class="text-label sales-data" @click="changeType('byDate')">By Date</label>
                      </div>
                      <div class="row mb-3">
                        <div class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                          <Multiselect
                              ref="country"
                              v-model="filters.countrySelected"
                              :searchable="false"
                              :options="countryOptions"
                              :disabled="!isCapAdmin && !isCap"
                              label="name"
                              name="country"
                              track-by="id"
                              :loading="!countryOptions"
                              :allow-empty="false"
                              :show-labels="false"
                            >
                            </Multiselect>
                        </div>
                        <div v-if="type !== 'byDate'" :class="isCapAdmin || isCap ? 'col-lg-2' : 'col-lg-2'" class="d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Fiscal Year</label>
                          <Multiselect
                            ref="year"
                            v-model="filters.yearSelected"
                            :searchable="false"
                            :options="yearOptions"
                            :disabled="type === 'byDate'"
                            label="name"
                            track-by="value"
                            :loading="!yearOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div v-if="type !== 'byDate'" :class="isCapAdmin || isCap ? 'col-lg-2' : 'col-lg-2'" class="d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Quarter</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.quarterSelected"
                            :searchable="false"
                            :options="quarterOptions"
                            label="name"
                            track-by="value"
                            :loading="!quarterOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div v-if="type === 'byDate'" class="col-lg-4 d-flex justify-content-end" style="flex-direction: column">
                          <div class="form-group flex-grow-1 mb-0 d-flex justify-content-end" style="flex-direction: column; margin-bottom: 0!important">
                            <label class="text-label">Select Period</label>
                            <DateRangePicker
                              ref="picker"
                              v-model="dateRange"
                              :date-range="dateRange"
                              :opens="datePicker.opens"
                              :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                              :min-date="datePicker.minDate"
                              :max-date="datePicker.maxDate"
                              :single-date-picker="datePicker.singleDatePicker"
                              :time-picker="datePicker.timePicker"
                              :time-picker24hour="datePicker.timePicker24Hour"
                              :show-week-numbers="datePicker.showWeekNumbers"
                              :show-dropdowns="datePicker.showDropdowns"
                              :auto-apply="datePicker.autoApply"
                              :linked-calendars="datePicker.linkedCalendars"
                              :date-format="datePicker.dateFormat"
                              :ranges="datePicker.ranges"
                            >
                              <div
                                slot="input"
                                slot-scope="picker"
                                style="min-width: 310px; width: 100%; float: left;"
                              >
                                <span v-if="picker.startDate && picker.endDate">{{ formatDate(picker.startDate) }} to
                                  {{ formatDate(picker.endDate) }}</span>
                              </div>
                            </DateRangePicker>
                          </div>
                        </div>
                        <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Section(s)</label>
                          <Multiselect
                            ref="subSection"
                            v-model="filters.sectionSelected"
                            class="multiple-selection-dropdown"
                            :searchable="false"
                            :options="sectionOptions"
                            label="label"
                            name="subSection"
                            track-by="value"
                            :loading="!sectionOptions"
                            :allow-empty="false"
                            :show-labels="false"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                          >
                            <template slot="selection" slot-scope="{ values, isOpen }">
                              <span v-if="values.length == 4 && !isOpen" class="multiselect__single">All sections selected</span>
                              <span v-else-if="values.length && !isOpen" class="multiselect__single">{{ values.length }} section(s) selected</span>
                            </template>
                          </Multiselect>
                        </div>
                        <div class="col-lg-3 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                          <div class="d-inline-flex">
                            <button class="btn btn-sm btn-primary mb-0" style="margin-top:24px;height:40px;width: 100%" :disabled="isDataLoading || isLoading" @click="toggleModal">
                              <span>Send Email</span>
                            </button>
                            <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading || isLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                              <span>Download</span>
                              <div 
                                v-if="isDataLoading || isLoading" 
                                class="loading ml-3" 
                                style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                              >
                              </div>
                            </button>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item key="1" label="Summary">
                <div class="card">
                  <div class="card-body">
                    <div class="contact-list-wrapper">
                      <div class="row mb-3">
                        <div class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                          <Multiselect
                            ref="country"
                            v-model="filters.countrySelected"
                            :searchable="false"
                            :options="countryOptions"
                            :disabled="!isCapAdmin && !isCap"
                            label="name"
                            name="country"
                            track-by="id"
                            :loading="!countryOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Fiscal Year</label>
                          <Multiselect
                            ref="year"
                            v-model="filters.yearSelected"
                            :searchable="false"
                            :options="yearOptions"
                            label="name"
                            track-by="value"
                            :loading="!yearOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Quarter</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.quarterSelected"
                            :searchable="false"
                            :options="quarterOptions"
                            label="name"
                            track-by="value"
                            :loading="!quarterOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Week From</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekFrom"
                            :searchable="false"
                            :options="allWeekFrom"
                            :loading="!allWeekFrom"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Week To</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekTo"
                            :searchable="false"
                            :options="allWeekTo"
                            :loading="!allWeekTo"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="col-lg-2 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                          <div class="d-inline-flex">
                            <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                              <span>Download</span>
                              <div 
                                v-if="isDataLoading" 
                                class="loading ml-3" 
                                style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                              >
                              </div>
                            </button>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>

              <!-- Only CAP ADMIN can see -->
              <b-tab-item v-if="isCap" key="2" label="Dashboard">
                <div class="card">
                  <div class="card-body">
                    <div class="contact-list-wrapper">
                      <div class="row mb-3">
                        <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Store Identity</label>
                          <Multiselect
                            ref="storeIdentity"
                            v-model="filters.storeIdentityFilter"
                            class="multiple-selection-dropdown"
                            :searchable="false"
                            :options="storeIdentityOptions"
                            label="text"
                            name="code"
                            track-by="code"
                            :loading="!storeIdentityOptions"
                            :allow-empty="false"
                            :show-labels="false"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                          >
                            <template slot="selection" slot-scope="{ values, isOpen }">
                              <span v-if="values.length == 4 && !isOpen" class="multiselect__single">All store identity selected</span>
                              <span v-else-if="values.length && !isOpen" class="multiselect__single">{{ values.length }} store identity selected</span>
                            </template>
                          </Multiselect>
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Fiscal Year</label>
                          <Multiselect
                            ref="year"
                            v-model="filters.yearSelected"
                            :searchable="false"
                            :options="yearOptions"
                            label="name"
                            track-by="value"
                            :loading="!yearOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Quarter</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.quarterSelected"
                            :searchable="false"
                            :options="quarterOptions"
                            label="name"
                            track-by="value"
                            :loading="!quarterOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg" style="flex-direction: column">
                          <label class="text-label">Week From</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekFrom"
                            :searchable="false"
                            :options="allWeekFrom"
                            :loading="!allWeekFrom"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg" style="flex-direction: column">
                          <label class="text-label">Week To</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekTo"
                            :searchable="false"
                            :options="allWeekTo"
                            :loading="!allWeekTo"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="col-lg-2 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                          <div class="d-inline-flex">
                            <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                              <span>Download</span>
                              <div 
                                v-if="isDataLoading" 
                                class="loading ml-3" 
                                style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                              >
                              </div>
                            </button>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>

            </b-tabs>
          </div>
        </div>
      </div>
    </div>
      

    
    <BModal ref="send-email-modal" size="lg" hide-footer title="Send Email">
        <div class="row">
          <div class="col-lg-12 d-inline-flex justify-content-between mt-5 mb-3">
            <div class="col-lg-8">
              <input 
                v-model="email"
                v-validate="'required'"
                type="text"
                name="Email"
                class="form-control"
              >
            </div>
            <div class="col-lg-4">
              <button class="btn btn-sm btn-primary mt-0 w-100" style="height:40px" :disabled="sendingEmail" @click="doSendEmail">
                <span>Send</span>
              </button>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-lg-12">
              <p class="invalid-field">{{ error }}</p>
            </div>
          </div>
        </div>
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { convertDateFormat, defaultFiscalYear, defaultQuarter } from "../../utils/helpers";
import DateRangePicker from "../../components/ui/vue2-daterange-picker/src";
import "../../components/ui/vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { DOWNLOAD_VISITATION_REQUEST, DOWNLOAD_VISITATION_SUMMARY_REQUEST, DOWNLOAD_VISITATION_DASHBOARD_REQUEST, SEND_VISITATION_REQUEST, SEND_VISITATION_SUMMARY_REQUEST } from "../../store/modules/download/actions";
import { BModal } from "bootstrap-vue";
import { 
  NOTIFICATION_REQUEST, 
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR 
} from "../../store/modules/ui/actions";
import cloneDeep from "lodash/cloneDeep";
import Multiselect from "vue-multiselect";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import {COUNTRY_LIST_REQUEST, STORE_IDENTITY_REQUEST} from "@/store/modules/utility/actions";

const storeIdentityOrderList = ["SI_LES", "SI_KS", "SI_MPS", "SI_LPS"]

const today = new Date();
const CAP = { id:null, code:"CAP", name:"CAP" }
const alSecOpt = [
  { label: "1 Time Scoring", value: 1 },
  { label: "Lenovo Share of Space", value: 2 },
  { label: "Lenovo Ambience", value: 3 },
  { label: "Branding/User Experience", value: 4 },
]

export default {
  name: "VisitationPage",
  components: {
    breadcrumb: breadcrumb,
    DateRangePicker: DateRangePicker,
    Multiselect: Multiselect,
    BModal: BModal,
  },
  computed: {
    allWeekTo() {
      const from = this.filters.weekFrom
      if (from) {
        return this.allWeek.filter(el => el >= from)
      } else {
        return this.allWeek
      }
    },
    allWeekFrom() {
      const to = this.filters.weekTo
      if (to) {
        return this.allWeek.filter(el => el <= to)
      } else {
        return this.allWeek
      }
    },
  },
  data() {
    return {
      isLoading: false,
      activeTab: 0,
      email: this.$store.getters.user.name,
      error: "",
      type: "byYearAndQuarter",
      sendingEmail: false,
      dataList: [],
      storeIdentityOptions: [],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      dateOption:[],
      sectionOptions: cloneDeep(alSecOpt),
      filters: {
        storeIdentityFilter: null,
        countrySelected: null,
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        sectionSelected: cloneDeep(alSecOpt), // default select all
        weekFrom: 1,
        weekTo: 13,
      },
      dateRange: {
        startDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth(), 1),
          "YYYY-MM-DD"
        ),
        endDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
          "YYYY-MM-DD"
        )
      },
      datePicker: {
        opens: "center",
        // minDate: "2019-08-01",
        // maxDate: "2019-12-31",
        singleDatePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        timePicker: false,
        autoApply: false,
        ranges: false
      },
      allWeek: [1,2,3,4,5,6,7,8,9,10,11,12,13],
      isCapAdmin: false,
      isCap: false,
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      countries: [],
      countryCode: this.$store.getters.getCountryCode,
      isCountriesLoading: false,
      isDataLoading:false,
      link: ""
    }
  },
  watch: {},
  methods: {
    changeType(type){
      this.type = type;
    },
    emailValidate(value){
      let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return re.test(value);
    },
    async doSendEmail(){
      let action = SEND_VISITATION_REQUEST
      if(this.email === ""){
        return this.error = "Email can't be blank"
      } 
      if(!this.emailValidate(this.email)){
        return this.error = "Invalid email"
      }
      
      this.sendingEmail = true;
      let startDate = convertDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      let endDate = convertDateFormat(this.dateRange.endDate, "YYYY-MM-DD");
      let countryCode = this.filters.countrySelected.id;

      let pdata = {
        countryId: countryCode,
        email: this.email
      };

      if(this.type === 'byDate'){
        pdata.sdf = startDate;
        pdata.edf = endDate;
      } else {
        pdata.fyf = this.filters.yearSelected.value;
        pdata.qf = this.filters.quarterSelected.value;
      }

      if (this.activeTab == 1) {
        action = SEND_VISITATION_SUMMARY_REQUEST
        pdata.weekFrom = "W"+this.filters.weekFrom
        pdata.weekTo = "W"+this.filters.weekTo
      }

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: this.$ml.get("request_submitting")
      });
      this.$store.dispatch(action, pdata)
        .then(res => {
          if(res.resultCode === 0){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Please check your email"
            });
            this.toggleModal();
          }
          this.sendingEmail = false;
        })
        .catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
          this.sendingEmail = false;
        });
      
    },
    toggleModal(){
      this.sendingEmail = false;
      this.email = this.$store.getters.user.name;
      this.error = "";
      this.$refs["send-email-modal"].toggle("#toggle-btn");
    },
    changeDateFormat(date, format) {
      return convertDateFormat(date, format);
    },

    doExport() {
      let action = DOWNLOAD_VISITATION_REQUEST
      this.isDataLoading = true;
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Downloading..."
      });
      let startDate = convertDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      let endDate = convertDateFormat(this.dateRange.endDate, "YYYY-MM-DD");
      let countryCode = this.filters.countrySelected.id;
      
      let pData = {
        countryId: countryCode,
      };
      
      if(this.type === 'byDate'){
        pData.sdf = startDate;
        pData.edf = endDate;
      } else {
        pData.fyf = this.filters.yearSelected.value;
        pData.qf = this.filters.quarterSelected.value;
      }

      if (this.filters.sectionSelected.length > 0) {
        pData.sif = this.filters.sectionSelected.map(el => el.value).join(",")
      }

      if (this.activeTab == 1) {
        action = DOWNLOAD_VISITATION_SUMMARY_REQUEST
        pData.weekFrom = "W"+this.filters.weekFrom
        pData.weekTo = "W"+this.filters.weekTo
      } else if (this.activeTab === 2) {
        action = DOWNLOAD_VISITATION_DASHBOARD_REQUEST
        pData.weekFrom = "W"+this.filters.weekFrom
        pData.weekTo = "W"+this.filters.weekTo


        if (this.filters.storeIdentityFilter && this.filters.storeIdentityFilter.length > 0) {
          if (this.filters.storeIdentityFilter.length === storeIdentityOrderList.length) {
            pData.storeIdentityFilter = null
          } else {
            pData.storeIdentityFilter = this.filters.storeIdentityFilter.map((el) => el.code).join(",")
          }
        }
        delete pData.sif
      }
      
      this.$store.dispatch(action, pData).then(res => {
        if(res.resultCode === 0 && res.content){
          window.open(res.content.link);
        }
        this.isDataLoading = false;
      }).catch(err => {
        this.isDataLoading = false;
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: err
        });
      })
    },

    formatDate: function(date) {
      return convertDateFormat(String(date), "YYYY-MM-DD");
    },
    getStoreIdentities() {
      this.$store.dispatch(STORE_IDENTITY_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.storeIdentityOptions = res.content
          this.storeIdentityOptions = this.storeIdentityOptions.sort((a, b) => {
            return storeIdentityOrderList.indexOf(a.code) - storeIdentityOrderList.indexOf(b.code)
          })
          // default select all
          this.filters.storeIdentityFilter = this.storeIdentityOptions
        }
      });
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, { withRegion: false })
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = res.content;
          this.countryOptions = cloneDeep(res.content)
          .filter(el => {
            return el.code != "KR" && el.code != "KH"
          })
          .map(el => {
            return {
              id: el.id,
              name: el.text,
              code: el.code
            }
          })
          if (this.isCap || this.isCapAdmin) {
            this.countryOptions.unshift(CAP)
            this.filters.countrySelected = CAP
          } else {
            this.filters.countrySelected = this.countryOptions.find(el => el.code === this.$store.getters.getCountryCode);
          }
        }
      });
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
  },
  async mounted() {
    this.getStoreIdentities()
    this.getCountries();
    this.getFilters();
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
  },
};
</script>
<style lang="scss">
.salesTheaderNoWrap {
  .table > thead > tr > th {
    white-space: nowrap !important;
  }
}

.text-label.sales-data::after, .text-label.sales-data::before{
  margin: 1px .4rem .4rem .4rem;
}
.multiple-selection-dropdown .multiselect__tags {
  white-space: nowrap;
  overflow: hidden; 
}
</style>