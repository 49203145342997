<template>
  <div id="promoter-weekly-target">
    <div class="row">
      <div class="col-lg-12">
        <div class="row d-flex mb-3">
          <div class="col-lg-12 d-flex align-items-center justify-content-end">
            <div v-if="isCap || isCapAdmin">
              <button class="btn btn-sm btn-primary" @click="toggleModal">
                <i class="fa fa-upload"></i> Upload
              </button>
            </div>
            <!-- <div v-else>
              <button class="btn btn-sm btn-primary" @click="downloadTemplate">
                Download Template
              </button>
            </div> -->
            <div>
              <button class="btn btn-sm btn-primary" :disabled="isDataLoading" @click="doExport">
                <i class="fa fa-download"></i> Export
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div v-if="isCapAdmin || isCap" class="col-lg-2 d-flex justify-content-end" style="flex-direction: column">
            <label class="text-label">Countries</label>
            <Multiselect
              ref="multiselect-country"
              v-model="target_country"
              placeholder="Select Country"
              :searchable="true"
              :options="countries"
              label="text"
              name="country"
              track-by="id"
              select-label
              deselect-label
              :loading="isCountriesLoading"
            ></Multiselect>
          </div>
          <div :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-4'" class="d-flex justify-content-end" style="flex-direction: column">
            <label class="text-label">Year</label>
            <select
              id="year"
              v-model="target_year"
              class="form-control"
            >
              <option v-for="(year, index) of fiscalYearList" :key="index">
                {{ year }}
              </option>
            </select>
          </div>
          <div :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-4'" class="d-flex justify-content-end" style="flex-direction: column">
            <label class="text-label">Quarter</label>
            <select
              id="type"
              v-model="target_quarter"
              class="form-control"
            >
              <option v-for="(type, index) of quarterList" :key="index" :value="type.id">
                {{ type.text }}
              </option>
            </select>
          </div>
          <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
            <label class="text-label">Type</label>
            <select
              id="type"
              v-model="target_type"
              class="form-control"
            >
              <option v-for="(type, index) of targetTypes" :key="index" :value="type.id">
                {{ type.text }}
              </option>
            </select>
          </div>
          <div class="col-lg-1 pl-0 d-flex justify-content-end" style="flex-direction: column">
            <button class="btn btn-sm btn-primary mb-0" style="margin-top:24px;height:40px;width: 100%" :disabled="isDataLoading" @click="doSearch()">
              <span>Search</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <VueBootstrap4Table
              :classes="classes"
              :rows="dataList"
              :columns="columns"
              :config="config"
              :total-rows="total"
              @on-change-query="onChangeQuery"
            >
              <template slot="sort-asc-icon">
                <i class="mdi mdi-sort-ascending"></i>
              </template>
              <template slot="sort-desc-icon">
                <i class="mdi mdi-sort-descending"></i>
              </template>
              <template slot="no-sort-icon">
                <i class="mdi mdi-sort-variant"></i>
              </template>
              <template slot="empty-results">
                 <div
                  v-if="!isDataLoading"
                  class="bold-text"
                >
                  No Data Found!
                </div>
                <div
                  v-if="isDataLoading"
                  class="loading mt-3"
                ></div>
              </template>

              <template slot="id" slot-scope="props">
                <span>{{ props.row.vbt_id }}</span>
              </template>
              <template slot="outletCode" slot-scope="props">
                <span>{{ props.row.outletCode }}</span>
              </template>
              <template slot="fiscalYear">
                <span>{{ target_year }}</span>
              </template>
              <template slot="quarter">
                <span>Q{{ target_quarter }}</span>
              </template>
            </VueBootstrap4Table>
          </div>
        </div>
      </div>
    </div>

    <BModal ref="upload-modal" size="xl" hide-footer title="Upload">
      <div>
        <regionUpload
          :close-modal="toggleModal"
          :sample-file="sampleFile"
          :content="content"
          :set-post-data="setPostData"
          :show-data-list="true"
          :submit-button-text="'Submit'"
          :file-columns="fileColumnStructure"
          :file-data="fileColumnData"
        />
      </div>
      <!-- <div v-else>
        <excelUploadForm
          :close-modal="toggleModal"
          :validations="validateFields"
          :sample-file="sampleFile"
          :content="content"
          :set-post-data="setPostData"
          :show-data-list="true"
          :submit-button-text="'Submit'"
          :get-upload-file="getUploadFile"
          :file-columns="fileColumnStructure"
          :file-data="fileColumnData"
        />
      </div> -->

    </BModal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { Message } from "element-ui";
// import excelUploadForm from "../../components/excel_upload/index";
import regionUpload from "../../components/target/region_upload_promoter_target";
import { export_json_to_excel } from "../../utils/Export2Excel";
import Multiselect from "vue-multiselect";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import {
  GET_PROMOTER_TARGET_DATA_LIST_REQUEST,
  UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST
} from "../../store/modules/target/actions";
import {COUNTRY_LIST_REQUEST, TARGET_SALE_TYPE_REQUEST} from "../../store/modules/utility/actions";
import { defaultQuarter, defaultFiscalYear } from '../../utils/helpers';
import { FISCAL_YEAR_LIST } from "@/constants";

export default {
  name: "TargetPromoterWeeklyData",
  components: {
    BModal: BModal,
    VueBootstrap4Table: VueBootstrap4Table,
    // excelUploadForm: excelUploadForm,
    regionUpload: regionUpload,
    Multiselect: Multiselect
  },
  props: {
    promoters: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    fileName() {
      return `Promoter-target-${this.target_quarter}-${this.target_year}`;
    }
  },
  data() {
    return {
      allPromoters: this.promoters,
      sampleFile: "targetPromoterUpload",
      content:
        "Your EXCEL data file should contain all the columns specific to the operation you are performing. The sample EXCEL file has most of the columns required for the operation. Do not edit the colunm labels in the sample file and make sure the file extension is .xlsx file.",
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Traget",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "No",
          sort: true,
          initial_sort: true, // "false" by default
          initial_sort_order: "desc" // "asc" by default
        },
        {
          name: "promoter",
          label: "Promoter",
          width: "13%",
          column_text_alignment: "text-center"
        },
        {
          name: "outletCode",
          label: "Outlet Code",
          width: "13%",
          column_text_alignment: "text-center"
        },
        {
          name: "fiscalYear",
          label: "Fiscal Year",
          width: "10%",
          column_text_alignment: "text-center"
        },
        {
          name: "quarter",
          label: "Quarter",
          width: "8%",
          column_text_alignment: "text-center"
        },
        {
          name: "week1",
          label: "Week 01",
          column_text_alignment: "text-center"
        },
        {
          name: "week2",
          label: "Week 02",
          column_text_alignment: "text-center"
        },
        {
          name: "week3",
          label: "Week 03",
          column_text_alignment: "text-center"
        },
        {
          name: "week4",
          label: "Week 04",
          column_text_alignment: "text-center"
        },
        {
          name: "week5",
          label: "Week 05",
          column_text_alignment: "text-center"
        },
        {
          name: "week6",
          label: "Week 06",
          column_text_alignment: "text-center"
        },
        {
          name: "week7",
          label: "Week 07",
          column_text_alignment: "text-center"
        },
        {
          name: "week8",
          label: "Week 08",
          column_text_alignment: "text-center"
        },
        {
          name: "week9",
          label: "Week 09",
          column_text_alignment: "text-center"
        },
        {
          name: "week10",
          label: "Week 10",
          column_text_alignment: "text-center"
        },
        {
          name: "week11",
          label: "Week 11",
          column_text_alignment: "text-center"
        },
        {
          name: "week12",
          label: "Week 12",
          column_text_alignment: "text-center"
        },
        {
          name: "week13",
          label: "Week 13",
          column_text_alignment: "text-center"
        }
      ],
      fileColumnStructure: [
        "Promoter Name",
        "Id",
        "Outlet Code",
        "Fiscal Year",
        "Year",
        "Quarter",
        "Week 1",
        "Week 2",
        "Week 3",
        "Week 4",
        "Week 5",
        "Week 6",
        "Week 7",
        "Week 8",
        "Week 9",
        "Week 10",
        "Week 11",
        "Week 12",
        "Week 13",
        "Amount Per Unit"
      ],
      total: 0,
      dataList: [],
      target_year: null,
      target_type: null,
      target_quarter: null,
      target_country:null,
      postTargetData: {},
      promoterList: this.promoters,
      yearList: [],
      targetTypes: [],
      quarterList: [],
      fileColumnData: [],
      tHeader: [
        "Promoter Name",
        "Id",
        "Outlet Code",
        "Fiscal Year",
        "Year",
        "Quarter",
        "Week 1",
        "Week 2",
        "Week 3",
        "Week 4",
        "Week 5",
        "Week 6",
        "Week 7",
        "Week 8",
        "Week 9",
        "Week 10",
        "Week 11",
        "Week 12",
        "Week 13",
        "Amount Per Unit"
      ],
      filterVal: [
        "promoter",
        "promoterId",
        "outletCode",
        "fiscalYear",
        "year",
        "quarter",
        "week1",
        "week2",
        "week3",
        "week4",
        "week5",
        "week6",
        "week7",
        "week8",
        "week9",
        "week10",
        "week11",
        "week12",
        "week13",
        "unit"
      ],
      fiscalYearList: FISCAL_YEAR_LIST,
      isCountryAdmin: false,
      isCapAdmin: false,
      isCap: false,
      countries: [],
      isDataLoading: false,
      isCountriesLoading: false
    };
  },
  watch: {
    promoters: function(valNew) {
      this.allPromoters = this.promoters;
      this.promoterList = this.promoters;
      this.setExcelSampleData(valNew);
    }
  },
  methods: {
    setExcelSampleData(arr) {
      this.fileColumnData = [];
      if (!this.isCountryAdmin && !this.isCapAdmin && !this.isCap) {
        arr.forEach(item => {
          let row = [item.fullname, item.accId, item.dealerOutletCode, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
          this.fileColumnData.push(row);
          this.fileColumnStructure = [
            "Promoter Name",
            "Id",
            "Outlet Code",
            "Fiscal Year",
            "Year",
            "Quarter",
            "Week 1",
            "Week 2",
            "Week 3",
            "Week 4",
            "Week 5",
            "Week 6",
            "Week 7",
            "Week 8",
            "Week 9",
            "Week 10",
            "Week 11",
            "Week 12",
            "Week 13",
            "Amount Per Unit"
          ];
        });
      } else {
        arr.forEach(item => {
          let row = [
            item.fullname,
            item.accId,
            item.outletCode,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          ];
          this.fileColumnData.push(row);
        });
        this.fileColumnStructure = [
          "Promoter Name",
          "Id",
          "Outlet Code",
          "Fiscal Year",
          "Year",
          "Quarter",
          "Week 1",
          "Week 2",
          "Week 3",
          "Week 4",
          "Week 5",
          "Week 6",
          "Week 7",
          "Week 8",
          "Week 9",
          "Week 10",
          "Week 11",
          "Week 12",
          "Week 13",
          "Amount Per Unit"
        ];
      }
    },
    hideModal() {
      this.$refs["upload-modal"].hide();
    },
    toggleModal() {
      this.$refs["upload-modal"].toggle("#toggle-btn");
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      // this.getPromoterDataList({});
    },
    setYears() {
      const year = new Date().getFullYear() - 3;
      for (let i = 0; i <= 6; i++) {
        let yr = year + i;
        this.yearList.push(yr);
      }

      return this.yearList;
    },
    getPromoterDataList(postd) {
      this.isDataLoading = true;
      this.$store.dispatch(GET_PROMOTER_TARGET_DATA_LIST_REQUEST, postd).then(res => {
        if (res.resultCode === 0) {
          this.formatDataArray(res.content.targetByPromoterWeeklyList);
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
      });
    },
    formatDataArray(data) {
      let rows = [];
      data.forEach(row => {
        let a = {
          promoter: row.promoterName,
          promoterId: row.promoterId,
          outletCode: row.data[0].outletCode || "-",
          week1: this.filterWeeklyTarget("W1", row.data),
          week2: this.filterWeeklyTarget("W2", row.data),
          week3: this.filterWeeklyTarget("W3", row.data),
          week4: this.filterWeeklyTarget("W4", row.data),
          week5: this.filterWeeklyTarget("W5", row.data),
          week6: this.filterWeeklyTarget("W6", row.data),
          week7: this.filterWeeklyTarget("W7", row.data),
          week8: this.filterWeeklyTarget("W8", row.data),
          week9: this.filterWeeklyTarget("W9", row.data),
          week10: this.filterWeeklyTarget("W10", row.data),
          week11: this.filterWeeklyTarget("W11", row.data),
          week12: this.filterWeeklyTarget("W12", row.data),
          week13: this.filterWeeklyTarget("W13", row.data)
        };

        rows.push(a);
      });

      this.dataList = rows;
    },
    filterWeeklyTarget(id, arr) {
      let week = arr.filter(function(elem) {
        if (elem.week === id) return elem;
      });
      if (week[0]) {
        return this.addCommaSeparator(week[0].target ? week[0].target : 0);
      } else {
        return 0;
      }
    },
    setDefaultData() {
      if (!this.target_year) {
        this.target_year = this.fiscalYearList.find(item => item === defaultFiscalYear());
      }
      if (!this.target_type) {
        if (this.targetTypes[0]) {
          this.target_type = this.targetTypes[0].id;
        }
      }
      if (!this.target_quarter) {
        this.target_quarter = this.quarterList.find(item => item.text === defaultQuarter())["id"];
      }
      this.onFilterChanged();
    },
    onYearChange() {
      const tdata = {
        country: this.target_country,
        year: this.target_year,
        type: this.target_type,
        quarter: this.target_quarter,
        monthly: false,
        weekly: true
      };
      this.getPromoterDataList(tdata);
    },
    onTargetTypeChange() {
      const tdata = {
        country: this.target_country,
        type: this.target_type,
        year: this.target_year,
        quarter: this.target_quarter,
        monthly: false,
        weekly: true
      };
      this.getPromoterDataList(tdata);
    },
    onFilterChanged() {
      const tdata = {
        country: this.target_country,
        type: this.target_type,
        year: this.target_year,
        quarter: this.target_quarter,
        monthly: false,
        weekly: true
      };
      this.getPromoterDataList(tdata);
    },
    // validateFields(value, field) {
    //   //   return true;
    //   let isValid = false;
    //   let promoters = this.promoterList;
    //   let targetTypes = this.targetTypes;
    //   let quarterList = this.quarterList;
    //   let fiscalyearList = this.fiscalYearList;
    //   let promoter = promoters.filter(function(elem) {
    //     if (elem.name.toLowerCase() === value.toLowerCase()) return elem.id;
    //   });
    //   let type = targetTypes.filter(function(elem) {
    //     if (elem.text.toLowerCase() === value.toLowerCase()) return elem.id;
    //   });
    //   let quarter = quarterList.filter(function(elem) {
    //     if (elem.text.toLowerCase() === value.toLowerCase()) return elem.id;
    //   });
    //   let fiscalyear = fiscalyearList.filter(function(elem) {
    //     if (elem.toLowerCase() === value.toLowerCase()) return elem;
    //   });
    //   let RE = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/; // /^-{0,1}\d*\.{0,1}\d+$/;
    //   switch (field) {
    //     case "PROMOTER NAME":
    //       if (promoter[0]) {
    //         isValid = true;
    //       }
    //       break;
    //     case "Year":
    //       isValid = true;
    //       break;
    //     case "Type":
    //       if (type[0]) {
    //         isValid = true;
    //       }
    //       break;
    //     case "Quarter":
    //       if (quarter[0]) {
    //         isValid = true;
    //       }
    //       break;
    //     case "Fiscal Year":
    //       if (fiscalyear[0]) {
    //         isValid = true;
    //       }
    //       break;
    //     case "Week 1":
    //     case "Week 2":
    //     case "Week 3":
    //     case "Week 4":
    //     case "Week 5":
    //     case "Week 6":
    //     case "Week 7":
    //     case "Week 8":
    //     case "Week 9":
    //     case "Week 10":
    //     case "Week 11":
    //     case "Week 12":
    //     case "Week 13":
    //       isValid = RE.test(value.trim());
    //       break;
    //     default:
    //       isValid = true;
    //       break;
    //   }

    //   if (!isValid) {
    //     value = '<span class="invalid-field">' + value + "</span>";
    //   }

    //   return {
    //     value: value,
    //     valid: isValid
    //   };
    // },
    setPostData(pdata) {
      let tdata = [];
      let promoters = this.promoterList;
      let quarters = this.quarterList;
      // let targettypes = this.targetTypes;
      let fiscalyears = this.fiscalYearList;

      pdata.forEach(function(row) {
        let promoter = promoters.filter(function(elem) {
          if (elem.name.toLowerCase() === row[0].toLowerCase()) return elem;
        });

        let quarter = quarters.filter(function(elem) {
          if (elem.text.toLowerCase() === row[5].toLowerCase()) return elem.id;
        });

        let fiscalyear = fiscalyears.filter(function(elem) {
          if (elem.toLowerCase() === row[3].toLowerCase()) return elem;
        });

        if (!promoter[0]) {
          Message({
            message: "Invalid promoter (" + row[0] + ").",
            type: "error",
            duration: 5 * 1000
          });
          //  break;
          return true;
        } else if (!quarter[0]) {
          Message({
            message: "Invalid quarter (" + row[5] + ").",
            type: "error",
            duration: 5 * 1000
          });
          return true;
        } else if (!fiscalyear[3]) {
          Message({
            message: "Invalid fiscal year (" + row[1] + ").",
            type: "error",
            duration: 5 * 1000
          });
          return true;
        } else {
          let promoterId = promoter[1].id;
          let quarterId = quarter[5].id;
          let yearId = parseInt(row[4]);
          let fiscalYear = fiscalyear[3];
          for (let m = 1; m <= 13; m++) {
            let tindx = m + 4;
            if (row[tindx]) {
              tdata.push({
                promoterId: promoterId,
                year: yearId,
                week: "W" + m,
                quarter: "Q" + quarterId,
                target: row[tindx].trim(),
                fiscalYear: fiscalYear
              });
            }
          }
          console.log(tdata)
        }
      });

      this.postTargetData.data = tdata;
      this.postTargetData.type = "weekly";
      if (this.postTargetData.data.length > 0) {
        this.submitTargetData();
      }
    },
    submitTargetData() {
      this.$store
        .dispatch(UPLOAD_PROMOTER_TARGET_DATA_LIST_REQUEST, this.postTargetData)
        .then(res => {
          if (res.resultCode === 0) {
            this.toggleModal();
            Message({
              message: "Promoter target data Successfully Added.",
              type: "success",
              duration: 5 * 1000
            });
            this.onFilterChanged();
          }
        });
    },
    getUploadFile() {},
    async setTargetTypes() {
      await this.$store.dispatch(TARGET_SALE_TYPE_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.targetTypes = res.content;
          if (!this.target_type) {
            this.target_type = this.targetTypes[0].id;
            // this.setDefaultData();
          }
        }
      });
    },
    getquarterList() {
      this.quarterList = [
        { id: 1, text: "Q1" },
        { id: 2, text: "Q2" },
        { id: 3, text: "Q3" },
        { id: 4, text: "Q4" }
      ];
    },
    addCommaSeparator(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    doExport() {
      let data = this.formatJson(this.filterVal, this.dataList);
      export_json_to_excel({
        header: this.tHeader,
        data,
        filename: this.fileName,
        sheetName: this.fileName,
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    async downloadTemplate() {
      await this.setExcelSampleData(this.promoters);
      let data = await this.fileColumnData;
      let header = [
        "Promoter Name",
        "Id",
        "Outlet Code",
        "Fiscal Year",
        "Year",
        "Quarter",
        "Week 1",
        "Week 2",
        "Week 3",
        "Week 4",
        "Week 5",
        "Week 6",
        "Week 7",
        "Week 8",
        "Week 9",
        "Week 10",
        "Week 11",
        "Week 12",
        "Week 13",
        "Amount Per Unit"
      ];
      export_json_to_excel({
        header: header,
        data,
        filename: this.sampleFile,
        sheetName: this.sampleFile,
        autoWidth: false,
        bookType: "xlsx"
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if(j === "fiscalYear"){
            return this.target_year
          }
          else if(j === "quarter"){
            return "Q" + this.target_quarter
          }
          return v[j];
        })
      );
    },
    doSearch(){
      const tdata = {
        country: this.target_country,
        year: this.target_year,
        type: this.target_type,
        quarter: this.target_quarter,
        monthly: false,
        weekly: true
      };
      this.getPromoterDataList(tdata);
    },
    getCountries() {
      this.isCountriesLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          this.countries.unshift({id:0, text:"CAP"});
          if (!this.target_country) {
            this.target_country = this.countries[0];
            // this.onFilterChanged();
          }
          this.isCountriesLoading = false;
        } else {
          this.isCountriesLoading = false;
        }
      });
    }
  },
  async mounted() {
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;

    if(this.isCapAdmin || this.isCap){
      this.getCountries();
    }
    await this.setYears();
    await this.setTargetTypes();
    await this.getquarterList();
    this.setDefaultData();
  }
};
</script>
