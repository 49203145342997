var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dual-list"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 dual-box",style:({ height: _vm.options.height + 20 || '' })},[_c('div',{staticClass:"card dual-card"},[_c('div',{staticClass:"card-body"},[(_vm.options.inputOptions.isRequired)?_c('div',{staticStyle:{"margin-bottom":"0px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",style:({
                    'text-transform': _vm.options.inputOptions.uppercase
                      ? 'uppercase'
                      : ''
                  }),attrs:{"placeholder":_vm.options.placeholder,"required":""},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]):_vm._e(),(!_vm.options.inputOptions.isRequired)?_c('div',{staticStyle:{"margin-bottom":"0px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",style:({
                    'text-transform': _vm.options.inputOptions.uppercase
                      ? 'uppercase'
                      : ''
                  }),attrs:{"placeholder":_vm.options.placeholder},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"basic-list-group",class:_vm.options.resizeBox},[_c('ul',{staticClass:"list-group",style:({ height: _vm.options.height || '' })},_vm._l((_vm.filtering),function(f){return _c('li',{key:f.id,staticClass:"list-group-item",on:{"click":function($event){_vm.transferToRight(_vm.options.items.indexOf(f))}}},[_c('a',{style:({ color: _vm.options.colorItems || '#1E90FF' })},[_vm._v(" "+_vm._s(f.name)+" ⇒")])])}),0)])])])]),_c('div',{staticClass:"col-md-2 dual-box",style:({ height: _vm.options.height + 20 || '' })},[_c('div',{staticClass:"control-buttons"},[_c('div',{staticClass:"mb-3"},[_c('button',{staticClass:"btn btn-rounded btn-block",on:{"click":function($event){_vm.transferToRight(_vm.options.items.indexOf(_vm.item))}}},[_vm._v(" "+_vm._s(_vm.options.buttonOption.textLeft)+" "),_c('i',{staticClass:"ml-auto mdi mdi-arrow-right-bold-circle"})])]),_c('div',[_c('button',{staticClass:"btn btn-rounded btn-block",on:{"click":function($event){return _vm.transferToLeft(-1)}}},[_c('i',{staticClass:"mr-auto mdi mdi-arrow-left-bold-circle"}),_vm._v(" "+_vm._s(_vm.options.buttonOption.textRight)+" ")])])])]),_c('div',{staticClass:"col-md-5 dual-box",style:({ height: _vm.options.height + 20 || '' })},[_c('div',{staticClass:"card dual-card"},[_c('div',{staticClass:"card-body"},[(_vm.options.inputOptions.isRequired)?_c('div',{staticStyle:{"margin-bottom":"0px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search2),expression:"search2"}],staticClass:"form-control",style:({
                    'text-transform': _vm.options.inputOptions.uppercase
                      ? 'uppercase'
                      : ''
                  }),attrs:{"placeholder":_vm.options.placeholder,"required":""},domProps:{"value":(_vm.search2)},on:{"input":function($event){if($event.target.composing)return;_vm.search2=$event.target.value}}})]):_vm._e(),(!_vm.options.inputOptions.isRequired)?_c('div',{staticStyle:{"margin-bottom":"0px"}},[_c('label',{attrs:{"for":"filter"}},[_vm._v(_vm._s(_vm.options.label))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search2),expression:"search2"}],staticClass:"form-control",style:({
                    'text-transform': _vm.options.inputOptions.uppercase
                      ? 'uppercase'
                      : ''
                  }),attrs:{"placeholder":_vm.options.placeholder},domProps:{"value":(_vm.search2)},on:{"input":function($event){if($event.target.composing)return;_vm.search2=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"basic-list-group",class:_vm.options.resizeBox},[_c('ul',{staticClass:"list-group",style:({ height: _vm.options.height || '' })},_vm._l((_vm.filtering2),function(item2){return _c('li',{key:item2.name,staticClass:"list-group-item",on:{"click":function($event){_vm.transferToLeft(_vm.options.selectedItems.indexOf(item2))}}},[_c('a',{style:({ color: _vm.options.colorItems || '#1E90FF' })},[_vm._v(" ⇐ "+_vm._s(item2.name)+" ")])])}),0)])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }