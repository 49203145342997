import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";
import {
  API_GET_MEDIA,
  API_ADD_MEDIA,
  API_UPDATE_MEDIA,
  API_PRODUCT_CATEGORY_GROUP,
  API_DELETE_MEDIA,
  API_GET_MEDIA_BY_ID
} from "../constants/services";

export async function getMediaList(postData) {
  return await request({
    url: API_GET_MEDIA,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}
export async function getMediaById(id) {
  return await request({
    url: API_GET_MEDIA_BY_ID + id,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
export async function deleteMediaById(id) {
  return await request({
    url: API_DELETE_MEDIA + id,
    method: "delete",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addMedia(postData) {
  return await request({
    url: API_ADD_MEDIA,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateMedia(postData) {
  return await request({
    url: API_UPDATE_MEDIA,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getProductCategoryAndGroup() {
  return await request({
    url: API_PRODUCT_CATEGORY_GROUP,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}