<template>
  <div class="nk-sidebar">
    <div class="nk-nav-scroll">
      <ul id="menu" class="metismenu">
        <li
          v-for="(menu, index) of routerLinks"
          :key="index"
          class="mega-menu mega-menu-lg"
          :class="isActiveMenu(menu) ? 'lenovo-active active' : ''"
          @click="menu.subMenu.length == 0 ? setActiveMenu(menu, {}) : ''"
        >
          <a v-if="(isCountryTrainer && menu.subMenu.length > 0 && (index === 0 || index === 1)) || 
            (!isCountryTrainer && menu.subMenu.length > 0)" 
            class="has-arrow link d-flex align-items-center" aria-expanded="false"
          >
            <i :class="getIcon(menu)"></i>
            <span class="nav-text">
              {{ menu.menu }}
              <!-- v-if="unreadMessageCount > 0"  -->
            </span>
            <span
              v-if="
                ((SRPCount > 0 || unknownSerialNumberCount > 0) && menu.menu === 'Data Management' && !isCapAdmin && !isCap) ||
                (ApprovalCount > 0 && menu.menu === 'Pending Approval' && !isCapAdmin && !isCap && !isMercLeader) ||
                ((approvalCountDealer > 0 || approvalCountDealerOutlet > 0 || approvalCountPromoter > 0 || approvalCountPromoterLeader > 0 || approvalCountMerchandiser > 0) 
                  && menu.menu === 'Pending Approval' && (isCap || isCapAdmin)) || 
                (menu.menu === 'Pending Approval' && (isCountryAdmin || isGLNCountryAdmin) && (approvalCountPromoterUpdate > 0 || approvalCountPromoterLeaderUpdate > 0)) ||
                (menu.menu === 'Pending Approval' && (isMercLeader) && (approvalCountMerchandiser > 0))
              "
              class="unread-signal-nav pull-right"
            />
          </a>
          <ul v-if="menu.subMenu.length > 0" aria-expanded="false">
            <li
              v-for="(subm, index2) of menu.subMenu"
              :key="index2"
              @click="setActiveMenu(menu, subm)"
            >
              <router-link :to="getRouterLink(subm)" class="d-flex align-items-center">
                <span @click="loadNewMessage">{{ subm.menu }}</span>
                <span
                  v-if="
                    (SRPCount > 0 && subm.menu === 'SRP List' && !isCapAdmin && !isCap) ||
                    (unknownSerialNumberCount > 0 && subm.menu === 'Unknown Serial Number' && !isCapAdmin && !isCap) ||
                    (ApprovalCount > 0 && menu.menu === 'Pending Approval' && subm.menu === 'Salesperson' && !isCapAdmin && !isCap) ||
                    ((approvalCountDealer > 0 || approvalCountDealerOutlet > 0 || approvalCountPromoter > 0 || approvalCountPromoterLeader > 0 || approvalCountMerchandiser > 0)
                      && menu.menu === 'Pending Approval' && subm.menu === 'Change Request' && (isCapAdmin || isCap)) || 
                    (menu.menu === 'Pending Approval' && subm.menu === 'Change Request' && (isCountryAdmin || isGLNCountryAdmin) && (approvalCountPromoterUpdate > 0 || approvalCountPromoterLeaderUpdate > 0))

                  "
                  class="unread-signal-nav pull-right"
                />
              </router-link>
            </li>
          </ul>
          <router-link
            v-if="(isCountryTrainer && menu.subMenu.length == 0 && (index === 0 || index === 1)) || 
            (!isCountryTrainer && menu.subMenu.length == 0)"
            class="d-flex align-items-center"
            :to="getRouterLink(menu)"
          >
            <i :class="getIcon(menu)" @click="loadNewMessage"></i>
            <span class="nav-text" @click="loadNewMessage">{{ menu.menu }} {{ " " }}</span>
            <span
              v-if="
                unreadMessageCount > 0 && menu.menu === 'Message' && !isDealer && !isCapAdmin && !isCap
              "
              class="unread-signal-nav pull-right"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    unreadMessages: { type: Number, default: 0 },
    recountMessages: { type: Function, default: () => {} },
    pendingApprovalCount: { type: Number, default: 0 },
    pendingApprovalCountDealer: { type: Number, default: 0 },
    pendingApprovalCountDealerOutlet: { type: Number, default: 0 },
    pendingApprovalCountPromoter: { type: Number, default: 0 },
    pendingApprovalCountPromoterLeader: { type: Number, default: 0 },
    pendingApprovalCountPromoterLeaderUpdate: { type: Number, default: 0 },
    pendingApprovalCountPromoterUpdate: { type: Number, default: 0 },
    pendingApprovalCountMerchandiser: { type: Number, default: 0 },
    pendingApprovalCountMerchandiserLeader: { type: Number, default: 0 },
    missingSrpCount: { type: Number, default: 0 },
    unknownSerialNumber: { type: Number, default: 0 },
    reRenderComponent: { type: Boolean }
  },
  computed: {
    getAuthUser() {
      return this.$store.getters.user;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    dashboardCheck() {
      return (
        this.$route.path.includes("dashboard/regional") ||
        this.$route.path.includes("dashboard/country")
      );
    },
    isCapAdmin() {
      return this.$store.getters.isCapAdmin;
    },
    isCap() {
      return this.$store.getters.isCap;
    }
  },
  data() {
    return {
      routerLinks: [],
      currentUrl: this.$route.path,
      activeMenu: "",
      isLoading: false,
      unreadMessageCount: this.unreadMessages,
      ApprovalCount: this.pendingApprovalCount,
      approvalCountDealer: this.pendingApprovalCountDealer,
      approvalCountDealerOutlet: this.pendingApprovalCountDealerOutlet,
      approvalCountPromoter: this.pendingApprovalCountPromoter,
      approvalCountPromoterLeader: this.pendingApprovalCountPromoterLeader,
      approvalCountPromoterLeaderUpdate: this.pendingApprovalCountPromoterLeaderUpdate,
      approvalCountPromoterUpdate: this.pendingApprovalCountPromoterUpdate,
      approvalCountMerchandiser: this.pendingApprovalCountMerchandiser,
      approvalCountMerchandiserLeader: this.pendingApprovalCountMerchandiserLeader,
      SRPCount: this.missingSrpCount,
      unknownSerialNumberCount: this.unknownSerialNumber,
      isDealer: false,
      countryId: 0,
      reloadData: false,
      isCountryTrainer: false,
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      isMercLeader: false
    };
  },
  watch: {
    unreadMessages: function(newVal) {
      this.unreadMessageCount = newVal;
    },
    missingSrpCount: function(newVal) {
      this.SRPCount = newVal;
    },
    pendingApprovalCount: function(newVal) {
      this.ApprovalCount = newVal;
    },
    pendingApprovalCountDealer: function(newVal) {
      this.approvalCountDealer = newVal;
    },
    pendingApprovalCountDealerOutlet: function(newVal) {
      this.approvalCountDealerOutlet = newVal;
    },
    pendingApprovalCountPromoter: function(newVal) {
      this.approvalCountPromoter = newVal;
    },
    pendingApprovalCountPromoterLeader: function(newVal) {
      this.approvalCountPromoterLeader = newVal;
    },
    pendingApprovalCountPromoterLeaderUpdate: function(newVal) {
      this.approvalCountPromoterLeaderUpdate = newVal;
    },
    pendingApprovalCountPromoterUpdate: function(newVal) {
      this.approvalCountPromoterUpdate = newVal;
    },
    pendingApprovalCountMerchandiser: function(newVal) {
      this.approvalCountMerchandiser = newVal;
    },
    pendingApprovalCountMerchandiserLeader: function(newVal) {
      this.approvalCountMerchandiserLeader = newVal;
    },
    unknownSerialNumber: function(newVal) {
      this.unknownSerialNumberCount = newVal;
    },
    reRenderComponent: function(newVal) {
      this.reloadData = newVal;
    }
  },
  methods: {
    getIcon(menu) {
      return menu.icon;
    },
    getRouterLink(menu) {
      return menu.route;
    },
    setActiveMenu(menu) {
      /* eslint-disable no-undef */
      if (this.isMobile) {
        $("#main-wrapper").toggleClass("menu-toggle");
        $(".hamburger").toggleClass("is-active");
      }
      if($(".hamburger").hasClass("is-active")){
        $(".brand-logo-wrapper").css("margin-left", "0rem");
      } else {
        $(".brand-logo-wrapper").css("margin-left", `-${$(".hamburger").width()/2}px`);
      }
      this.activeMenu = menu.id;
      this.currentUrl = this.$route.path;
      this.loadNewMessage();
    },
    isActiveMenu(menu) {
      if (this.activeMenu === menu.id) {
        return true;
      } else {
        return false;
      }
    },
    getRouterLinksArray() {
      this.routerLinks = [];
      this.isLoading = true;
      if (this.$store.getters.user.countryRoleList) {
        this.routerLinks = this.$store.getters.user.countryRoleList[0].menuItemList;
        if (this.isDealer && !this.getAuthUser.canBulkUpload) {
          this.routerLinks = this.routerLinks.filter(link => {
            return link.id !== 29;
          });
        }
      }
      this.isLoading = false;
    },
    getCurrentlyActive() {
      const route = this.$route.name;
      this.routerLinks.forEach(item => {
        if (Object.keys(item.subMenu).length > 0) {
          item.subMenu.forEach(sub => {
            if (sub.menu == route) {
              this.activeMenu = item.id;
            }
          });
        } else {
          if (item.menu == route) {
            this.activeMenu = item.id;
          }
        }
      });
    },
    loadNewMessage() {
      this.recountMessages();
    }
  },
  mounted() {
    this.isDealer = this.$store.getters.isDealer;
    this.isCountryTrainer = this.$store.getters.isCountryTrainer;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isMercLeader = this.$store.getters.isMercLeader;
    this.countryId = this.$store.getters.getCountryId;
    if(this.isCountryTrainer){
      this.routerLinks = this.$store.getters.user.countryRoleList[0].menuItemList.concat(this.$store.getters.user.countryRoleList[0].menuItemList, this.$store.getters.user.countryRoleList[0].menuItemList);
    }
    if (!this.isLoading) {
      this.getCurrentlyActive();
    }
  },
  created() {
    this.getRouterLinksArray();
  }
};
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>
