<template>
  <div class="chart-modal">

    <!-- stacked-bar-chart -->

    <div v-if="content.type === 'stackedBarChart'" id="stackedBarChart">
      <div class="mt-5 ml-5 mb-4 d-inline-flex justify-content-between w-100">
        <span class="chart-name mt-auto mb-auto">
          <strong>{{ content.chart.country ? content.chart.country : content.chart.name }}</strong>
        </span>
        <span class="mr-5 close" @click="close">
          &#10005;
        </span>
      </div>
      <stacked-bar-chart
        id="actualsubRegionLowerSection"
        :got-lower-section="true"
        :data.sync="content.chart.data"
        lower-section-id="subRegionLowerSection"
        title=""
        chart-left="300"
        :max-characters="35"
        :modal="modal"
      ></stacked-bar-chart>
      <div id="subRegionLowerSection"></div>
      <div class="mt-4">
        <div class="b-tabs">
          <nav class="tabs is-small is-centered">
            <ul></ul>
          </nav>
          <section class="tab-content">
            <div class="tab-item" style="">
              <div class="tags d-flex justify-content-center pb-3">
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(242, 142, 43);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">To Go</span>
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(154, 205, 50);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Over Achievement</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- / stacked-bar-chart -->

    <!-- Geo chart -->
    <div v-if="content.type === 'geoChartKR'">
      <div id="geoHeatMapTitle" class="position-absolute d-inline-flex justify-content-between" style="width: 95%">
        <div class="mt-5 ml-5 mb-4 d-inline-flex justify-content-between w-100">
          <span class="chart-name mt-auto mb-auto">
            <strong>{{ content.chart.name }}</strong>
          </span>
          <span class="mr-5 close" @click="close">
            &#10005;
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div id="toolTipContainer" class="border position-absolute d-none"></div>
        <div id="geoDataInfoContainer" class="border p-1 overflow-scroll position-absolute">
          <ul class="mb-0 d-flex">
            <li>
              <ul class="mr-2">
                <li><strong>Country</strong></li>
                <li v-for="(data, index) in dashboardData.heatMapTableData" :key="index">
                  <span>{{ data.country }}</span>
                </li>
              </ul>
            </li>
            <li>
              <ul class="mr-2 text-right">
                <li><strong>Actual</strong></li>
                <li v-for="(data, index) in dashboardData.heatMapTableData" :key="index">
                  <span>{{ data.actual }}</span>
                </li>
              </ul>
            </li>
            <li>
              <ul class="mr-2 text-right">
                <li><strong>Target</strong></li>
                <li v-for="(data, index) in dashboardData.heatMapTableData" :key="index">
                  <span>{{ data.target }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-if="isCap" id="geoChartKR" class="border p-1 overflow-scroll position-absolute">
          <div>
            <geo-chart-kr
              :cap.sync="isCap"
              :data.sync="content.chart.data"
              :region.sync="sOHeatMapRegion1"
              :color-axis-values.sync="content.chart.colorAxisValues"
              :color-axis-colors.sync="content.chart.colorAxisColors"
            ></geo-chart-kr>
          </div>
        </div>
        <div id="geoColorInfoContainer" class="border p-1 overflow-scroll position-absolute">
          <ul class="mb-0 d-flex">
            <li>
              <ul class="mr-2">
                <li><strong>Attainment Rate </strong></li>
                <li v-for="(data, index) in dashboardData.heatMapColorData" :key="index">
                  <span>{{ data.attainmentRate }}</span>
                </li>
              </ul>
            </li>
            <li>
              <ul class="mr-2 text-right">
                <li><strong>Color</strong></li>
                <li v-for="(data, index) in dashboardData.heatMapColorData" :key="index">
                  <span class="tag" :style="`height: 1.2em;background: ${data.color}`"></span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <geo-chart
            :cap.sync="isCap"
            :data.sync="content.chart.data"
            :region.sync="sOHeatMapRegion"
            :color-axis-values.sync="content.chart.colorAxisValues"
            :color-axis-colors.sync="content.chart.colorAxisColors"
          ></geo-chart>
        </div>
      </div>
    </div>

    <!-- / Geo Chart -->

    <!-- SO Charts -->
    <div v-if="content.type === 'comboBarChart'">
      <div class="mt-5 mb-4 d-inline-flex justify-content-between w-100">
        <div class="mt-4 mb-4 d-inline-flex justify-content-between w-100">
          <span class="chart-name mt-auto mb-auto ml-5">
            <strong>{{ content.chart.name }}</strong>
          </span>
          <span class="mr-5 close" @click="close">
            &#10005;
          </span>
        </div>
      </div>
      <combo-bar-chart
        :data.sync="content.chart.data"
        :title="''"
        :is-stacked="true"
        :show-ttl="true"
        :series-options.sync="content.chart.seriesOptions"
      ></combo-bar-chart>
      <div class="mt-4">
        <div class="b-tabs">
          <nav class="tabs is-small is-centered">
            <ul></ul>
          </nav>
          <section v-if="content.chart.name === 'SO Performance by Promoter/Salesperson/Dealer vs Target'" class="tab-content">
            <div class="tab-item" style="">
              <div class="tags d-flex justify-content-center pb-3">
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(51, 102, 204);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Promoter</span>
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(220, 57, 18);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Salesperson</span>
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(255, 153, 0);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Dealer</span>
                <hr class="tag mr-2 is-white is-medium legendIcon" style="border: 0; width: 10px; height: 3px; background: rgb(238, 205, 85);">
                <span class="tag mr-2 is-white is-medium legendIcons" style="width:2em; border-radius: 15px; background: rgb(238, 205, 85);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Target</span>
              </div>
            </div>
          </section>
          <section v-if="content.chart.name === 'Promoter SO Performance'" class="tab-content">
            <div class="tab-item" style="">
              <div class="tags d-flex justify-content-center pb-3">
                <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
                <hr class="tag mr-2 is-white is-medium legendIcon" style="border: 0; width: 10px; height: 3px; background: rgb(238, 205, 85);">
                <span class="tag mr-2 is-white is-medium legendIcons" style="width:2em; border-radius: 15px; background: rgb(238, 205, 85);"></span>
                <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Target</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <!-- / SO Charts -->

    <!-- Product Performance Charts -->
    <div v-if="content.type === 'productPerformanceChart'">
      <div class="mt-5 ml-5 mb-4 d-flex align-items-center">
        <div class="mr-4 d-inline-flex justify-content-between w-100">
          <span class="chart-name mb-auto mt-auto">
            <strong>{{ content.chart.name }}</strong>
          </span>
          <span class="mr-5 close" @click="close">
            &#10005;
          </span>
        </div>
      </div>
      <div>
        <stacked-bar-chart
          :id="'actual' + content.chart.id"
          :got-lower-section="true"
          :is-stacked="true"
          :data.sync="content.chart.data"
          title=""
          :product-filters.sync="content.extras.prFilters"
          :lower-section-id="content.chart.id"
          chart-left="20%"
          :max-characters="38"
          :modal="true"
        ></stacked-bar-chart>
        <div :id="content.chart.id"></div>
      </div>
      <div class="mt-4">
        <b-tabs
          v-model="content.extras.tab"
          position="is-centered"
          size="is-small"
        >
          <b-tab-item
            v-for="(legend, index) in content.chart.customLegend"
            :key="index"
            :label="legend.category"
          >
            <b-taglist class="pt-3 pb-5">
              <b-tag
                v-for="(name, index2) in legend.productNames"
                :key="index2"
                size="is-medium"
                type="is-white"
                :style="`background: ${name.color}`"
                class="mr-2"
              >
                {{ name.name }}
              </b-tag>
            </b-taglist>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <!-- / Product Performance Charts -->

  </div>
</template>
<script>
import geoChart from "../../components/dashboard/geo-chart";
import geoChartKR from "../../components/dashboard/geo-chart-kr";
import stackedbarChart from "../../components/dashboard/stacked-bar-chart";
import comboChart from "../../components/dashboard/combo-chart";

export default {
  name: "EnlargeChartModal",
  components: {    
    "geo-chart": geoChart,
    "geo-chart-kr": geoChartKR,
    "stacked-bar-chart": stackedbarChart,
    "combo-bar-chart": comboChart,
  },
  props: {
    content: {
      type: Object,
      default: () => {
        return {};
      }
    },
    modal: {type: Boolean, default: false},
    closeModal: { type: Function, default: () => {} },

  },
  computed:{
    sOHeatMapRegion() {
      return this.dashboardData.sOHeatMap.data.length == 2
        ? this.dashboardData.sOHeatMap.data[1][0]
        : "142";
    },
    sOHeatMapRegion1() {
      return this.dashboardData.sOHeatMap.data.length == 2
        ? this.dashboardData.sOHeatMap.data[1][0]
        : "KR";
    },
    isCap() {
      return this.dashboardData.sOHeatMap.data.length == 2 ? false : true;
    },
  },
  data(){
    return{
      dashboardData: {}
    }
  },
  methods: {
    close() {
      this.closeModal();
    },
  },
  mounted(){
    this.dashboardData = this.$store.getters.dashboardData;
  }
}
</script>
<style scoped>
.chart-name, .tags{
  color: #4a4a4a;
}
.chart-modal{
  height: calc(100vh - 200px)!important;
}
#stackedBarChart .gotLowerSection{
  height: calc(100vh - 400px)!important;
}
#actualproductPerformanceByCategoryLowerSection{
  height: calc(100vh - 520px)!important;
}
#actualproductPerformanceByComponentLowerSection{
  height: calc(100vh - 620px)!important;
}
#stackedBarChart{
  margin-right: 30px;
}
.close{
  font-size: 20px;
  cursor: pointer;
}
</style>