<template>
  <div class="row">
    <div class="col-md-12 uploadSection">
      <div class="input-group mb-3">
        <vue-xlsx-table class="btn-lg" @on-select-file="handleSelectedFile">
          Select File
          <small>** Only .xlsx files are allowed.</small>
        </vue-xlsx-table>
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <a v-if="doExportDefault" class="link lnv-link" @click="doExportDefault">
        <i class="fa fa-download"></i>
        <span class="ml-2">Download sample EXCEL file</span>
      </a>
      <br />
      <br />

      <p v-if="contentHtml" class="black-text">{{ contentHtml }}</p>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { export_json_to_excel } from "../../utils/Export2Excel";

export default {
  name: "ExcelUploadWithoutChecking",
  components: {},
  props: {
    content: { type: String, default: "" },
    sampleFile: { type: String, default: "" },
    getUploadData: { type: Function, default: () => {} },
    fileColumns: { type: Array, default: () => [] },
    fileData: { type: Array, default: () => [] }
  },
  computed: {},
  data() {
    return {
      contentHtml: this.content,
      rows: [],
      headers: [],
      file: {},
      dataErrors: [],
      fileValid: false,
      fileErrors: 0,
      defaultFileName: this.sampleFile,
      fileHeaderColumns: this.fileColumns ? this.fileColumns : [],
      fileSampleData: this.fileData ? this.fileData : []
    };
  },
  methods: {
    handleSelectedFile(convertedData) {
      this.headers = [];
      this.rows = [];

      try {
        const validFileHeader = this.isHeaderMatch(this.fileHeaderColumns, convertedData.header);

        if (validFileHeader) {
          this.rows = [];
          this.fileErrors = 0;
          this.fileValid = false;

          this.headers = this.fileHeaderColumns; //convertedData.header;
          const items = convertedData.body;
          let arr = [];

          items.forEach(row => {
            let i = [];
            this.headers.forEach((row2, index2) => {
              i[index2] = row[row2] ? row[row2] : null;
            });
            arr.push(i);
          });
          this.setRows(arr);
        } else {
          Message({
            message:
              "Invalid header titles found, correct and upload again. (Download the correct sample file below)",
            type: "error",
            duration: 5 * 1000
          });

          this.headers = [];
        }
      } catch (err) {
        // console.log(err);
      }
    },
    isHeaderMatch(a, b) {
      return b.every(val => a.includes(val));
    },
    setRows(data) {
      data.forEach((row) => {
        this.rows.push(row[0]);
      });
      this.getUploadData(this.rows);
    },
    resetUpload() {
      this.headers = [];
      this.rows = [];
      this.outletId = null;
      this.fileError = 0;
      this.fileValid = false;
    },
    doExportDefault() {
      const tHeader = this.fileHeaderColumns;
      const data = this.fileSampleData;
      const fileName = this.defaultFileName;
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "Sheet 1",
        autoWidth: true,
        bookType: "xlsx"
      });
    }
  },
  created() {},
  mounted() {
  }
};
</script>

<style>
.vue-xlsx-container {
  width: 100% !important;
}
.xlsx-button {
  width: 100%;
  height: 130px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #ccc !important;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 7px 9px;
  font-size: 15px;
  border-radius: 4px;
  color: #949090 !important;
  background-color: #f9f2f2 !important;
}

.link {
  cursor: pointer;
}

.uploadSection {
  height: 120px;
}
</style>
