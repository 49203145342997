export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR";

export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_ERROR = "PRODUCT_ERROR";

export const PRODUCT_POST_REQUEST = "PRODUCT_POST_REQUEST";
export const PRODUCT_POST_SUCCESS = "PRODUCT_POST_SUCCESS";
export const PRODUCT_POST_ERROR = "PRODUCT_POST_ERROR";

export const PRODUCT_BULK_POST_REQUEST = "PRODUCT_BULK_POST_REQUEST";
export const PRODUCT_BULK_POST_SUCCESS = "PRODUCT_BULK_POST_SUCCESS";
export const PRODUCT_BULK_POST_ERROR = "PRODUCT_BULK_POST_ERROR";

export const PRODUCT_SKU_LIST_REQUEST = "PRODUCT_SKU_LIST_REQUEST";
export const PRODUCT_SKU_LIST_SUCCESS = "PRODUCT_SKU_LIST_SUCCESS";
export const PRODUCT_SKU_LIST_ERROR = "PRODUCT_SKU_LIST_ERROR";

export const PRODUCT_GUILD_UPLOAD_REQUEST = "PRODUCT_GUILD_UPLOAD_REQUEST";
export const PRODUCT_GUILD_UPLOAD_SUCCESS = "PRODUCT_GUILD_UPLOAD_SUCCESS";
export const PRODUCT_GUILD_UPLOAD_ERROR = "PRODUCT_GUILD_UPLOAD_ERROR";

export const PRODUCT_SKU_UPDATE_SRP = "PRODUCT_SKU_UPDATE_SRP";
export const PRODUCT_SKU_UPDATE_SRP_SUCCESS = "PRODUCT_SKU_UPDATE_SRP_SUCCESS";
export const PRODUCT_SKU_UPDATE_SRP_ERROR = "PRODUCT_SKU_UPDATE_SRP_ERROR";

export const PRODUCT_SKU_LIST_EXPORT_REQUEST = "PRODUCT_SKU_LIST_EXPORT_REQUEST";
export const PRODUCT_SKU_LIST_EXPORT_SUCCESS = "PRODUCT_SKU_LIST_EXPORT_SUCCESS";
export const PRODUCT_SKU_LIST_EXPORT_ERROR = "PRODUCT_SKU_LIST_EXPORT_ERROR";

export const PRODUCT_SKU_LIST_UPLOAD_FILE_CHECK_REQUEST = "PRODUCT_SKU_LIST_UPLOAD_FILE_CHECK_REQUEST";
export const PRODUCT_SKU_LIST_UPLOAD_REQUEST = "PRODUCT_SKU_LIST_UPLOAD_REQUEST";
export const PRODUCT_SKU_LIST_UPLOAD_SUCCESS = "PRODUCT_SKU_LIST_UPLOAD_SUCCESS";
export const PRODUCT_SKU_LIST_UPLOAD_ERROR = "PRODUCT_SKU_LIST_UPLOAD_ERROR";
