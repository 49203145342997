<template>
  <div>
    <p
      v-if="canEdit && currentAction === 'edit' && promoter.pendingApprovalStatus && !promoterSelected.changeRequest"
      class="required-title"
      v-text="$ml.get('promoter_pending_approval_request')"
    ></p>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="canEdit && currentAction === 'edit' && !isPromoterLoading" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_name')"></label>
            <span class="required-star">*</span>
            <input
              id="fullname"
              v-model="promoter.fullname"
              :disabled="promoterSelected.changeRequest"
              name="fullname"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.fullname = ''"
            />
            <span v-if="formErrors.fullname" class="invalid-field">{{ formErrors.fullname }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect"
              v-model="promoter.createdCountryId"
              placeholder="Select country"
              :searchable="true"
              :disabled="isCountryAdmin || isGLNCountryAdmin || isLeader || promoterSelected.changeRequest && true"
              :options="countries"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isLeadersLoading"
            ></Multiselect>
            <span v-if="errors.first('Country')" class="invalid-field">
              {{ errors.first("Country") }}
            </span>
            <span v-if="formErrors.createdCountryId" class="invalid-field">{{ formErrors.createdCountryId }}</span>
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_ic_number')"></label>
            <span class="required-star">*</span>
            <input
              id="nationalIdNo"
              v-model="promoter.nationalIdNo"
              :disabled="promoterSelected.changeRequest"
              v-validate="'alpha_num'"
              name="IC Number"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.nationalIdNo = ''"
            />
            <span v-if="errors.first('IC Number')" class="invalid-field">
              {{ errors.first("IC Number") }}
            </span>
            <span v-if="formErrors.nationalIdNo" class="invalid-field">{{ formErrors.nationalIdNo }}</span>
          </div>
        </div> -->
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_gender')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-gender"
              v-model="promoter.genderId"
              :disabled="promoterSelected.changeRequest"
              placeholder="Select Gender"
              :searchable="true"
              :options="genders"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isGendersLoading"
            ></Multiselect>
            <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_contact_number')"></label>
            <span class="required-star">*</span>
            <input
              v-model="promoter.phoneNumber"
              v-validate="{ regex: getPhoneRegex() }"
              :disabled="promoterSelected.changeRequest"
              name="form_promoter_phone"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.phoneNumber = ''"
            />
            <span v-if="formErrors.phoneNumber" class="invalid-field">
              {{ formErrors.phoneNumber }}
            </span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="errors.firstByRule('form_promoter_phone', 'regex') && !formErrors.phoneNumber"
              class="invalid-field"
              v-html="$ml.get('invalid_phone_number')"
            ></span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_email')"></label>
            <span class="required-star">*</span>
            <input
              v-model="promoter.username"
              v-validate="{ regex: getEmailRegex() }"
              :disabled="promoterSelected.changeRequest"
              name="form_promoter_email"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.username = ''"
            />
            <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="errors.firstByRule('form_promoter_email', 'regex') && !formErrors.username"
              class="invalid-field"
              v-html="$ml.get('invalid_email_address')"
            ></span>
          </div>
        </div>
        <div v-if="!isLeader" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_leader_email')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect"
              v-model="promoter.promoterLeaderId"
              :disabled="promoterSelected.changeRequest"
              placeholder="Select Email"
              :searchable="true"
              :options="promoLeaders"
              label="email"
              track-by="id"
              select-label
              deselect-label
              :loading="isLeadersLoading"
            ></Multiselect>

            <span v-if="formErrors.promoterLeaderId" class="invalid-field">
              {{ formErrors.promoterLeaderId }}
            </span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Promoter Types</label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-type"
              v-model="promoter.promoterTypeId"
              :disabled="promoterSelected.changeRequest"
              placeholder="Select Promoter Type"
              :searchable="true"
              :options="promoterTypes"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isPromoterTypesLoading"
            ></Multiselect>

            <span v-if="formErrors.promoterTypeId" class="invalid-field">
              {{ formErrors.promoterTypeId }}
            </span>
          </div>
        </div>
        <br />
        <!-- <div class="col-12 mt-4">
          <h4 class="text-primary">
            Bank Information
          </h4>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
            <Multiselect
              ref="multiselect"
              v-model="promoter.bankId"
              :disabled="promoterSelected.changeRequest"
              placeholder="Select Bank"
              :searchable="true"
              :options="banks"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="getBanksIsLoading"
            ></Multiselect>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_acc_number')"></label>
            <input
              v-model="promoter.bankAccount"
              :disabled="promoterSelected.changeRequest"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.bankAccount = ''"
            />
            <span v-if="formErrors.bankAccount" class="invalid-field">{{
              formErrors.bankAccount
            }}</span>
          </div>
        </div> -->

        <div v-if="!promoterSelected.changeRequest" class="col-12">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" :disabled="promoter.staffId && promoter.pendingApprovalStatus && (isCountryAdmin || isGLNCountryAdmin)" class="btn btn-secondary pull-right">
                <span v-if="!promoter.staffId" v-text="$ml.get('form_promoter_add_button')"></span>
                <span
                  v-if="promoter.staffId"
                  v-text="$ml.get('form_promoter_update_button')"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="close()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      v-if="canEdit && currentAction === 'edit' && isPromoterLoading"
      class="loading mt-3"
    ></div>
    <!-- View -->

    <div v-if="canView && currentAction === 'view'">
      <table class="table">
        <tbody>
          <tr>
            <td v-text="$ml.get('form_promoter_join_date')"></td>
            <td>{{ promoter.joinedDate }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_contact_number')"></td>
            <td>{{ promoter.phoneNumber }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_email')"></td>
            <td>{{ promoter.username }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_type')"></td>
            <td>{{ promoter.promoterType }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_leader_email')"></td>
            <td>{{ promoter.promoterLeaderEmail }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-lg-12">
          <VueBootstrap4Table
              :classes="classes"
              :rows="promoterOutletList"
              :columns="columns"
              :config="config"
              :total-rows="total"
              @on-change-query="onChangeQuery"
              @refresh-data="getPromoterOutletList"
          >
            <template slot="sort-asc-icon">
              <i class="mdi mdi-sort-ascending"></i>
            </template>
            <template slot="sort-desc-icon">
              <i class="mdi mdi-sort-descending"></i>
            </template>
            <template slot="no-sort-icon">
              <i class="mdi mdi-sort-variant"></i>
            </template>
            <template slot="assigned" slot-scope="props">
              <div class="inner-data">
                <div class="d-flex text-center" style="flex-direction: column">
                  <span style="line-height: 1.5">Start Week: {{ props.row.startWeek }}</span>
                  <span style="line-height: 1.5">End Week: {{ props.row.endWeek }}</span>
                </div>
              </div>
            </template>
            <template slot="empty-results">
              <div
                  v-if="!isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('no_outlet_found')"
              ></div>
              <div
                  v-if="isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('data_loading')"
              ></div>
            </template>

            <!-- <template slot="outletName" slot-scope="props">
              <div class="inner-data">
                <router-link :to="{ name : 'Outlet Details ', params : { dealerOutletCode : props.row.dealerOutletCode } }">
                  <span style="color:#F70201">{{ props.row.outletName }}</span>
                </router-link>
              </div>
            </template> -->
          </VueBootstrap4Table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  POST_STAFF_REQUEST,
  GET_STAFF_LIST_REQUEST,
  GET_STAFF_PROMOTER_LIST_REQUEST
} from "../../store/modules/staff/actions";
import {BANK_LIST_REQUEST, COUNTRY_LIST_REQUEST} from "../../store/modules/utility/actions";
import {GET_PROMOTER_PENDING_APPROVAL_FLAG} from "../../store/modules/staff/actions";
import {
  GENDER_LIST_REQUEST,
  PROMOTER_TYPE_LIST_REQUEST
} from "../../store/modules/utility/actions";
import {nameValidate, emailValidate, phoneValidate, convertDateFormat} from "../../utils/helpers";
import Multiselect from "vue-multiselect";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import VueBootstrap4Table from "@/components/ui/VueBootstrap4Table";

export default {
  name: "PromoterForm",
  components: {
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    promoterSelected: { type: Object, default: () => {} },
    canView: { type: Boolean },
    canEdit: { type: Boolean },
    currentAction: { type: String, default: "" },filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {},
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Leader Promoters",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 3,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "dealerName",
          label: "Dealer",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "outletName",
          label: "Outlet Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },

        {
          name: "outletAddress",
          label: "Address",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "assigned",
          label: "Assigned",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      promoterOutletList: [],
      promoter: {
        fullname: this.promoterSelected.fullname,
        username: this.promoterSelected.username,
        phoneNumber: this.promoterSelected.phoneNumber,
        createdCountryId: this.promoterSelected.createdCountryId,
        nationalIdNo: this.promoterSelected.nationalIdNo,
        genderId: this.promoterSelected.genderId,
        promoterTypeId: this.promoterSelected.promoterTypeId,
        promoterLeaderId: this.promoterSelected.promoterLeaderId,
        promoterLeaderEmail: this.promoterSelected.promoterLeaderEmail,
        staffId: this.promoterSelected.accId || this.promoterSelected.id,
        type: "promoters",
        promoterType: this.promoterSelected.promoterType,
        bankId: this.promoterSelected.bankId,
        bankAccount: this.promoterSelected.bankAccount,
        joinedDate: this.promoterSelected.createdDate,
      },
      submitted: false,
      formErrors: [],
      genders: [],
      promoterTypes: [],
      countries: [],
      promoLeaders: [],
      banks: [],
      userRole: "",
      isLeader: false,
      userId: 0,
      isGendersLoading: false,
      isLeadersLoading: false,
      isPromoterTypesLoading: false,
      getBanksIsLoading: false,
      isDataLoading: false,
      isPromoterLoading: false,
      isCountryAdmin: false, 
      isGLNCountryAdmin: false,
      isCapAdmin: false,
      isCap: false
    };
  },
  watch: {
    'promoter.createdCountryId' : {
      handler() {this.getPromoterTypes()},
      deep: true
    }
  },
  methods: {
    getPhoneRegex() {
      return phoneValidate(this.promoter.phoneNumber, 1);
    },
    getEmailRegex() {
      return emailValidate(this.promoter.username, 1);
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.promoter.fullname) {
        this.formErrors.fullname = this.$ml.get("form_promoter_name_required");
        error++;
      } else {
        if (!nameValidate(this.promoter.fullname)) {
          this.formErrors.fullname = this.$ml.get("form_promoter_name_invalid");
          error++;
        }
      }

      if (!this.promoter.username) {
        this.formErrors.username = this.$ml.get("form_promoter_email_required");
        error++;
      } else {
        if (!emailValidate(this.promoter.username)) {
          this.formErrors.username = this.$ml.get("form_promoter_email_invalid");
          error++;
        }
      }

      if (!this.promoter.createdCountryId) {
        this.formErrors.createdCountryId = this.$ml.get("form_promoter_country_required");
        error++;
      }

      if (!this.promoter.promoterTypeId) {
        this.formErrors.promoterTypeId = this.$ml.get("form_promoter_type_required");
        error++;
      }

      // if (!this.promoter.nationalIdNo) {
      //   this.formErrors.nationalIdNo = this.$ml.get("form_promoter_ic_required");
      //   error++;
      // }

      if (!this.promoter.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get("form_promoter_contact_number_required");
        error++;
      } else {
        if (!phoneValidate(this.promoter.phoneNumber)) {
          this.formErrors.phoneNumber = this.$ml.get("invalid_phone_number");
          error++;
        }
      }

      if (!this.promoter.genderId) {
        this.formErrors.genderId = this.$ml.get("form_promoter_gender_required");
        error++;
      }
      if (!this.isLeader) {
        if (!this.promoter.promoterLeaderId) {
          this.formErrors.promoterLeaderId = this.$ml.get("form_promoter_promoter_leader_required");
          error++;
        }
      }

      if (this.promoter.bankId && !this.promoter.bankAccount) {
        this.formErrors.bankAccount = this.$ml.get("form_dealer_acc_number_required");
        error++;
      }

      return !error;
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (this.checkFormValidity(e)) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch(NOTIFICATION_REQUEST, {
              message: this.$ml.get("request_submitting")
            });
            let pdata = {};
            if (this.promoter.staffId > 0) {
              pdata.data = {}; //this.promoter;
              pdata.staffId = this.promoter.staffId;
              pdata.data.name = this.promoter.fullname;
              pdata.data.createdCountryId = this.promoter.createdCountryId.id;
              pdata.data.createdCountryCode = this.promoter.createdCountryId.code;
              pdata.data.icNumber = this.promoter.nationalIdNo;
              pdata.data.phoneNumber = this.promoter.phoneNumber;
              pdata.data.email = this.promoter.username.toLowerCase();
              pdata.data.id = this.promoter.staffId;
              pdata.data.type = this.promoter.type;
              pdata.data.genderId = this.promoter.genderId ? this.promoter.genderId.id : 0;
              if (this.promoter.promoterTypeId) {
                pdata.data.promoterTypeId = this.promoter.promoterTypeId.id;
              }
              if (!this.isLeader) {
                pdata.data.promoterLeaderId = this.promoter.promoterLeaderId
                  ? this.promoter.promoterLeaderId.id
                  : 0;
              }
              pdata.data.bankId = this.promoter.bankId ? this.promoter.bankId.id : null;
              pdata.data.bankAccount = this.promoter.bankAccount;
            } else {
              let staff = {};
              staff.name = this.promoter.fullname;
              staff.createdCountryId = this.promoter.createdCountryId.id;
              staff.createdCountryCode = this.promoter.createdCountryId.code;
              staff.icNumber = this.promoter.nationalIdNo;
              staff.phoneNumber = this.promoter.phoneNumber;
              staff.email = this.promoter.username.toLowerCase();
              staff.genderId = this.promoter.genderId ? this.promoter.genderId.id : 0;
              if (this.promoter.promoterTypeId) {
                staff.promoterTypeId = this.promoter.promoterTypeId.id;
              }
              if (!this.isLeader) {
                staff.promoterLeaderId = this.promoter.promoterLeaderId
                  ? this.promoter.promoterLeaderId.id
                  : 0;
              }
              staff.bankId = this.promoter.bankId ? this.promoter.bankId.id : 0;
              staff.bankAccount = this.promoter.bankAccount;

              pdata.data = [staff];
            }
            pdata.type = this.promoter.type;
            this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
              if (res.resultCode === 0) {
                this.success();
                if (this.promoter.accId) {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("promoter_added")
                  });
                } else {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("promoter_updated")
                  });
                }
              }
            });
          }
        });
      }
    },
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;

          if (this.promoter.genderId) {
            this.promoter.genderId = this.getSelectedGender(this.promoter.genderId);
          }
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    getPromoterTypes() {
      this.isPromoterTypesLoading = true;
      let postData = null
      if (this.promoter.createdCountryId ? this.promoter.createdCountryId.id == 25 : false) {
        // only send if country = PH
        postData = {countryCode: this.promoter.createdCountryId.id}
      }
      this.$store.dispatch(PROMOTER_TYPE_LIST_REQUEST, postData).then(res => {
        if (res.content && res.resultCode === 0) {
          this.promoterTypes = res.content;

          // if this.promoter.promoterTypeId have id field means that this.promoter.promoterTypeId already processed once
          if (this.promoter.promoterTypeId ? !this.promoter.promoterTypeId.id : false) {
            this.promoter.promoterTypeId = this.getSelectedType(this.promoter.promoterTypeId)
          }
          this.isPromoterTypesLoading = false;
        } else {
          this.isPromoterTypesLoading = false;
        }
      });
    },
    getLeaders() {
      this.promoLeaders = [];
      this.isLeadersLoading = true;
      this.$store.dispatch(GET_STAFF_LIST_REQUEST, { type: "leaders" }).then(res => {
        if (res.resultCode === 0) {
          // this.promoLeaders = res.content;

          res.content.forEach(leader => {
            if (leader.status) {
              this.promoLeaders.push(leader);
            }
          });

          if (this.promoter.promoterLeaderId) {
            this.promoter.promoterLeaderId = this.getSelectedLeader(this.promoter.promoterLeaderId);
          }
          this.isLeadersLoading = false;
        } else {
          this.isLeadersLoading = false;
        }
      });
    },
    getCountries() {
      this.countries = [];
      this.isLeadersLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
              this.countries.push(country);
          });
          if (this.promoter.createdCountryId) {
            this.promoter.createdCountryId = this.getSelectedCountry(this.promoter.createdCountryId);
          } else {
            // createdCountryId empty, get from current user's country
            let countryId =  this.$store.getters.getCountryId
            // but Cap and CapAdmin is not in any country, set to PH since PH have more promoterType option
            if (this.isCap || this.isCapAdmin) countryId = 25
            this.promoter.createdCountryId = this.getSelectedCountry(countryId);
          }
        }
        this.isLeadersLoading = false;
      });
    },
    getStaff() {},
    getSelectedLeader(id) {
      let c = this.promoLeaders.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getSelectedCountry(id) {
      let c = this.countries.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getSelectedGender(id) {
      let c = this.genders.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getSelectedType(id) {
      let c = this.promoterTypes.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return id;
      }
    },
    getBanks() {
      let pdata = { countryCode: this.$store.getters.getCountryCode };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;

          if (this.promoter.bankId > 0) {
            this.promoter.bankId = this.getSelectedBank(this.promoter.bankId);
          }
        }
        this.getBanksIsLoading = false;
      });
    },
    getSelectedBank(id) {
      let c = this.banks.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPromoterList();
    },
    getPromoterOutletList() {
      let pdata = {};

      if(!this.promoterSelected.changeRequest){
        pdata = {
          ccf: this.filters.countrySelected.value,
          fyf: this.filters.yearSelected.value,
          qf: this.filters.quarterSelected.value,
          pif: this.promoter.staffId,
        };
      } else {
        pdata.pif = this.promoter.staffId;
        this.isPromoterLoading = true;
      }

      this.isDataLoading = true;
      this.promoterOutletList = [];

      this.$store.dispatch(GET_STAFF_PROMOTER_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          if(this.promoterSelected.changeRequest){
            this.promoter = res.content.find(el => el.accId === this.promoter.staffId);
            this.promoter.createdCountryId = this.getSelectedCountry(this.promoter.createdCountryId);
            this.promoter.genderId = this.genders.find(item => item.id === this.promoter.genderId);
            this.promoter.promoterTypeId = this.promoterTypes.find(item => item.id === this.promoter.promoterTypeId);
            this.promoter.promoterLeaderId = this.promoLeaders.find(item => item.id === this.promoter.promoterLeaderId);
          } else {
            this.promoterOutletList = this.promoterSelected.promoterOutlets;
            this.total = this.promoterOutletList.length;
          }
          this.isDataLoading = false;
          this.isPromoterLoading = false;
        } else {
          this.isDataLoading = false;
          this.isPromoterLoading = false;
        }
      });
    },
    checkForPendingApprovalFlag(){
      this.$store.dispatch(GET_PROMOTER_PENDING_APPROVAL_FLAG, this.promoter.staffId).then(res => {
        if (res.resultCode === 0) {
          this.promoter.pendingApprovalStatus = res.content;
        }
      });
    }
  },
  created() {},
  mounted() {
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.getPromoterOutletList();
    this.userRole = this.$store.getters.getUserRole;
    this.userId = this.$store.getters.getUserId;
    this.isLeader = this.$store.getters.isPromoLeader;
    this.getGenders();
    this.getBanks();
    this.getPromoterTypes();
    if(this.promoter.staffId > 0 && !this.promoterSelected.changeRequest){
      this.checkForPendingApprovalFlag();
    }
    this.promoter.joinedDate = convertDateFormat(this.promoter.joinedDate, "YYYY-MM-DD");
    if (!this.isLeader) {
      this.getLeaders();
    }
    this.getCountries();
  }
};
</script>
