<template>
  <div id="dealer-year" class="row w-100">
    <!-- SO Charts(3) -->
    <div class="col-lg-12 p-0 ">
      <div class="col-lg-6 float-left border">
        <soPerformance
          :key="soPerformanceKey"
          ref="soPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :enlarge-chart="enlargeChart"
        ></soPerformance>
      </div>
    <!-- /SO Charts(3) -->

    <!-- Performance Charts(3) -->
      <div class="col-lg-6 float-left border ">
        <productPerformance
          :key="productPerformanceKey"
          ref="productPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :enlarge-chart="enlargeChart"
        ></productPerformance>
      </div>
    </div>
    <div class="col-lg-12 p-0">
      <div class="col-lg-6 float-left border ">
        <salespersonPerformance
          :key="salespersonPerformanceKey"
          ref="salespersonPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :enlarge-chart="enlargeChart"
        ></salespersonPerformance>
      </div>
      <div class="col-lg-6 float-left border ">
        <outletPerformance
          :key="outletPerformanceKey"
          ref="outletPerformance"
          :filters.sync="filters"
          grouping="yearly"
          :local-filters.sync="localFilters"
          :enlarge-chart="enlargeChart"
        ></outletPerformance>
      </div>
    </div>
    <!-- /Performance Charts(3) -->

    <BModal ref="overlay-graph" size="xl" hide-footer hide-header>
      <enlargeChart
        :close-modal="enlargeChart"
        :content="content"
        :modal="enlargeModal"
      />
    </BModal>

    <!-- /Pop up modals -->
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import enlargeChart from "../../components/dashboard/enlargeChartModal";
import {
  DASHBOARD_DEALER_TOP_ROW_DATA_GET,
} from "../../store/modules/dashboard/actions";
import soPerformance from "../../components/charts/so-performance";
import productPerformance from "../../components/charts/product-performance";
import salespersonPerformance from "../../components/charts/salesperson-performance";
import outletPerformance from "../../components/charts/outlet-performance";
import { defaultQuarter } from '../../utils/helpers';
import { generalMixin } from "@/utils/generalMixin";

export default {
  name: "DealerYear",
  components: {
    "soPerformance": soPerformance,
    "productPerformance": productPerformance,
    "salespersonPerformance": salespersonPerformance,
    "outletPerformance": outletPerformance,
    BModal,
    enlargeChart
  },
  mixins: [generalMixin],
  props: {
    filters: { type: Object, default: () => {} },
    country: { type: Object, default: () => {} }
  },
  computed: {},
  data() {
    return {
      isLoading: false,
      content: {},
      localFilters: this.filters,
      enlargeModal: false,
      soPerformanceKey: 0,
      productPerformanceKey: 0,
      salespersonPerformanceKey: 0,
      outletPerformanceKey: 0,
    };
  },
  watch: {
    filters: {
      deep: true,
      async handler(newValue) {
        this.filters = await newValue;
        await this.getDashboardData();
      }
    },
  },
  methods: {
    enlargeChart(chart, type, extras){
      this.enlargeModal = !this.enlargeModal;
      this.content = {chart: chart, type: type, extras: extras};
      this.$refs['overlay-graph'].toggle('#toggle-btn');
    },
    getDashboardData() {
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      this.$refs.soPerformance.getSoPerformance();
      this.$refs.productPerformance.getProductPerformance();
      this.$refs.salespersonPerformance.getSalespersonPerformance();
      this.$refs.outletPerformance.getOutletPerformance();
      this.$store.dispatch(DASHBOARD_DEALER_TOP_ROW_DATA_GET, {
        grouping: "yearly",
        filters: this.localFilters,
      });
    },
  },
  mounted() {
    this.getDashboardData();
  }
};
</script>