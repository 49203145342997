import request from "@/utils/request";
import {
  API_DEALER_LIST_SERVICE,
  API_SINGLE_DEALER_SERVICE,
  API_DEALER_LIST_WITH_FILTERS,
  ADD_DEALER_SERVICE,
  UPDATE_DEALER_SERVICE,
  SUBMIT_DEALER_INCENTIVE_SERVICE,
  API_DEALERS_OUTLETS_LIST_SERVICE,
  API_ALL_DEALER_LIST_SERVICE,
  API_DEALER_LIST_WITH_PARAMS_SERVICE,
  API_DEALER_SELL_IN_LIST_SERVICE,
  API_DEALER_SELL_IN_ADD_SERVICE,
  API_DEALER_SELL_OUT_ADD_SERVICE,
  API_DEALER_SELL_OUT_LIST_SERVICE,
  API_DEALER_STOCK_LIST_SERVICE,
  API_DEALER_STOCK_LIST_MONTHLY_SERVICE,
  API_DEALER_STOCK_LIST_DOWNLOAD_SERVICE,
  API_DEALER_INC_ENT_LIST_SERVICE,
  API_DEALER_INC_RECEIPT_LIST_SERVICE,
  API_DEALER_INC_ENT_UPLOAD_BY_DEALER_SERVICE,
  API_DEALER_INC_ENT_UPLOAD_BY_MONTH_SERVICE,
  API_DEALER_SELL_THRU_REQUEST,
  API_GET_SELL_THRU_UPLOADS_ALL,
  API_DEALER_SELL_THRU_UPLOAD_REQUEST,
  API_EXPORT_DEALER_SELL_THRU_REQUEST,
  API_DEALER_SELL_THRU_LATEST_WEEKS,
  API_GET_DEALER_CHANGES,
} from "../constants/services";

import { DEFAULT_TIMEOUT } from "../constants/index";

export async function getDealerList(postData) {
  let countryId = postData.countryId ? "/" + postData.countryId : "";

  let url = API_DEALER_LIST_SERVICE;
  if (postData.type === "all") {
    url = API_ALL_DEALER_LIST_SERVICE + countryId;
  } else {
    url = API_DEALER_LIST_SERVICE + countryId;
  }
  return await request({
    url: url,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealersListWithParams(postData) {
  let countryId ='';
  let status ='';
  if(postData.countryId !== ""){
    countryId = postData.countryId ? "?ccf=" + postData.countryId : "";
  }
  if(postData.status !== ""){
    status = postData.status ? "&sf=" + postData.status : "";
  }

  let url = API_DEALER_LIST_WITH_PARAMS_SERVICE + countryId + status;
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
export async function getDealersListWithFilters(postData) {
  let url = API_DEALER_LIST_WITH_FILTERS + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf;
  return await request({
    url: url,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerById(postData) {
  return await request({
    url: API_SINGLE_DEALER_SERVICE + postData.id,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addOrUpdateDealer(postData) {
  let url = ADD_DEALER_SERVICE;
  let data = {};
  if (postData.change_status) {
    data = {
      status: postData.status === "ACTIVE" ? "DEACTIVATED" : "ACTIVE",
      dealerName: postData.dealerName,
      statusUpdateReason: postData.statusUpdateReason,
    };
  } else {
    data = {
      accreditationId: postData.accreditationId.id,
      dealerName: postData.dealerName,
      ownerName: postData.ownerName,
      ownerIcOrId: postData.ownerIcOrId,
      contactNum: postData.contactNum,
      email: postData.email,
      accountManager: postData.accountManager,
      bulkUpload: postData.bulkUpload ? postData.bulkUpload : false,
      prmLppName: postData.prmLppName,
      bankAccNam: postData.bankAccNam,
      storeId: postData.storeId,
      bankAccNum: postData.bankAccNum,
      countryId: postData.countryId.id || postData.countryId,
      bankId: postData.bankId ? postData.bankId.id : null
    };
    if (postData.updateReason) data.updateReason = postData.updateReason
    else data.reason = postData.reason
  }

  if (!(postData.dealerId || postData.id) && !postData.change_status) {
    data.status = "ACTIVE";
  }

  if (postData.dealerId || postData.id) {
    data.id = postData.dealerId ? postData.dealerId : postData.id;

    url = UPDATE_DEALER_SERVICE;
  }
  return await request({
    url: url,
    method: "put",
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function submitDealerIncentiveList(postData) {
  return await request({
    url: SUBMIT_DEALER_INCENTIVE_SERVICE,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealersOutletsList() {
  return await request({
    url: API_DEALERS_OUTLETS_LIST_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerSellInList(postData) {
  return await request({
    url: API_DEALER_SELL_IN_LIST_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addDealerSellIn(postData) {
  return await request({
    url: API_DEALER_SELL_IN_ADD_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerSellOutList(postData) {
  return await request({
    url: API_DEALER_SELL_OUT_LIST_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addDealerSellOut(postData) {
  return await request({
    url: API_DEALER_SELL_OUT_ADD_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerStockList(postData) {
  return await request({
    url: API_DEALER_STOCK_LIST_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerStockMonthlyList(postData) {
  return await request({
    url: API_DEALER_STOCK_LIST_MONTHLY_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadDealerStock(postData) {
  return await request({
    url: API_DEALER_STOCK_LIST_DOWNLOAD_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerIncentiveList() {
  return await request({
    url: API_DEALER_INC_ENT_LIST_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerUploadList(postData) {
  return await request({
    url: API_DEALER_INC_RECEIPT_LIST_SERVICE + postData.year,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadByDealer(postData) {
  return await request({
    url: API_DEALER_INC_ENT_UPLOAD_BY_DEALER_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadByYearMonth(postData) {
  return await request({
    url: API_DEALER_INC_ENT_UPLOAD_BY_MONTH_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function dealerSellThruRequest(postData) {
  return await request({
    url: API_DEALER_SELL_THRU_REQUEST + 
      `?ccf=${postData.ccf}` + `&fyf=${postData.fyf}` + `&qf=${postData.qf}` + `&wf=${postData.wf}` + `${postData.df ? `&df=${postData.df}` : ""}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSellThruUploadsAll(postData) {
  return await request({
    url: API_GET_SELL_THRU_UPLOADS_ALL + 
      `?fyf=${postData.fyf}` + `&qf=${postData.qf}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function dealerSellThruUploadRequest(postData) {
  return await request({
    url: API_DEALER_SELL_THRU_UPLOAD_REQUEST,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function exportDealerSellThruRequest(postData) {
  return await request({
    url: API_EXPORT_DEALER_SELL_THRU_REQUEST + 
      `?ccf=${postData.ccf}` + `&fyf=${postData.fyf}` + `&qf=${postData.qf}` + `&wf=${postData.wf}` + `${postData.df ? `&df=${postData.df}` : ""}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function dealersSellThruUploadById(postData) {
  return await request({
    url: API_DEALER_SELL_THRU_UPLOAD_REQUEST + "/" + postData,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadDealerSellThruRequest(postData) {
  return await request({
    url: API_DEALER_SELL_THRU_UPLOAD_REQUEST + "/" + postData + "/download",
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSellThruLatestWeeks() {
  return await request({
    url: API_DEALER_SELL_THRU_LATEST_WEEKS,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerChanges(data) {
  return await request({
    url: `${API_GET_DEALER_CHANGES}/${data.id}/${data.dealerId}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
