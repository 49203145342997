<template>
  <div>
    <p
      v-if="canEdit && currentAction === 'edit' && promoter.pendingApprovalStatus && !promoterSelected.newApprovalRequest"
      class="required-title"
      v-text="$ml.get('promoter_pending_approval_request')"
    ></p>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form v-if="canEdit && currentAction === 'edit'" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_name')"></label>
            <span class="required-star">*</span>
            <input
              id="name"
              v-model="promoter.name"
              name="name"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.name = ''"
            />
            <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_ic_number')"></label>
            <span class="required-star">*</span>
            <input
              id="icNumber"
              v-model="promoter.icNumber"
              v-validate="'alpha_num'"
              name="IC Number"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.icNumber = ''"
            />
            <span v-if="errors.first('IC Number')" class="invalid-field">
              {{ errors.first("IC Number") }}
            </span>
            <span v-if="formErrors.icNumber" class="invalid-field">{{ formErrors.icNumber }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_gender')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-gender"
              v-model="promoter.genderId"
              placeholder="Select Gender"
              :searchable="true"
              :options="genders"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isGendersLoading"
            ></Multiselect>
            <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_contact_number')"></label>
            <span class="required-star">*</span>
            <input
              v-model="promoter.phoneNumber"
              v-validate="{ regex: getPhoneRegex() }"
              name="form_promoter_phone"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.phoneNumber = ''"
            />
            <span v-if="formErrors.phoneNumber" class="invalid-field">
              {{ formErrors.phoneNumber }}
            </span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="errors.firstByRule('form_promoter_phone', 'regex') && !formErrors.phoneNumber"
              class="invalid-field"
              v-html="$ml.get('invalid_phone_number')"
            ></span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_email')"></label>
            <span class="required-star">*</span>
            <input
              v-model="promoter.email"
              v-validate="{ regex: getEmailRegex() }"
              name="form_promoter_email"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.email = ''"
            />
            <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="errors.firstByRule('form_promoter_email', 'regex') && !formErrors.email"
              class="invalid-field"
              v-html="$ml.get('invalid_email_address')"
            ></span>
          </div>
        </div>
        <div v-if="!isLeader" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_promoter_leader_email')"></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect"
              v-model="promoter.promoterLeaderId"
              placeholder="Select Email"
              :searchable="true"
              :options="promoLeaders"
              label="email"
              track-by="id"
              select-label
              deselect-label
              :loading="isLeadersLoading"
            ></Multiselect>

            <span v-if="formErrors.promoterLeaderId" class="invalid-field">
              {{ formErrors.promoterLeaderId }}
            </span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">Promoter Types</label>
            <Multiselect
              ref="multiselect-type"
              v-model="promoter.promoterTypeId"
              placeholder="Select Promoter Type"
              :searchable="true"
              :options="promoterTypes"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isPromoterTypesLoading"
            ></Multiselect>
          </div>
        </div>
        <br />
        <!-- <div class="col-12 mt-4">
          <h4 class="text-primary">
            Bank Information
          </h4>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
            <Multiselect
              ref="multiselect"
              v-model="promoter.bankId"
              placeholder="Select Bank"
              :searchable="true"
              :options="banks"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="getBanksIsLoading"
            ></Multiselect>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label" v-text="$ml.get('form_dealer_bank_acc_number')"></label>
            <input
              v-model="promoter.bankAccount"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.bankAccount = ''"
            />
            <span v-if="formErrors.bankAccount" class="invalid-field">{{
              formErrors.bankAccount
            }}</span>
          </div>
        </div> -->

        <div class="col-12">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-secondary pull-right">
                <span v-if="!promoter.staffId" v-text="$ml.get('form_promoter_add_button')"></span>
                <span
                  v-if="promoter.staffId"
                  v-text="isCap || isCapAdmin ? $ml.get('form_promoter_update_button') : $ml.get('form_promoter_request_update_button')"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="close()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- View -->

    <div v-if="canView && currentAction === 'view'">
      <table class="table">
        <tbody>
          <tr>
            <td v-text="$ml.get('form_promoter_name')"></td>
            <td>{{ promoter.name }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_ic_number')"></td>
            <td>{{ promoter.icNumber }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_gender')"></td>
            <td>{{ promoter.genderId.text }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_contact_number')"></td>
            <td>{{ promoter.phoneNumber }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_email')"></td>
            <td>{{ promoter.email }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_promoter_leader_email')"></td>
            <td>{{ promoter.promoterLeaderId.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { 
  POST_STAFF_REQUEST, 
  GET_STAFF_LIST_REQUEST, 
  GET_PROMOTER_PENDING_APPROVAL_FLAG 
} from "../../store/modules/staff/actions";
import { BANK_LIST_REQUEST } from "../../store/modules/utility/actions";
import {
  GENDER_LIST_REQUEST,
  PROMOTER_TYPE_LIST_REQUEST
} from "../../store/modules/utility/actions";
import { nameValidate, emailValidate, phoneValidate } from "../../utils/helpers";
import Multiselect from "vue-multiselect";
import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";

export default {
  name: "PromoterForm",
  components: {
    Multiselect: Multiselect
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    promoterSelected: { type: Object, default: () => {} },
    canView: { type: Boolean },
    canEdit: { type: Boolean },
    currentAction: { type: String, default: "" }
  },
  computed: {},
  data() {
    return {
      promoter: {
        name: this.promoterSelected.name,
        email: this.promoterSelected.email,
        phoneNumber: this.promoterSelected.phoneNumber,
        icNumber: this.promoterSelected.icNumber,
        genderId: this.promoterSelected.genderId,
        promoterTypeId: this.promoterSelected.promoterTypeId,
        promoterLeaderId: this.promoterSelected.promoterLeaderId,
        staffId: this.promoterSelected.id,
        type: "promoters",
        bankId: this.promoterSelected.bankId,
        bankAccount: this.promoterSelected.bankAccount
      },
      submitted: false,
      formErrors: [],
      genders: [],
      promoterTypes: [],
      promoLeaders: [],
      banks: [],
      userRole: "",
      isLeader: false,
      userId: 0,
      isGendersLoading: false,
      isLeadersLoading: false,
      isCap: false,
      isCapAdmin: false,
      isPromoterTypesLoading: false,
      getBanksIsLoading: false
    };
  },
  methods: {
    checkForPendingApprovalFlag(){
      this.$store.dispatch(GET_PROMOTER_PENDING_APPROVAL_FLAG, this.promoter.staffId).then(res => {
        if (res.resultCode === 0) {
          this.promoter.pendingApprovalStatus = res.content;
        }
      });
    },
    getPhoneRegex() {
      return phoneValidate(this.promoter.phoneNumber, 1);
    },
    getEmailRegex() {
      return emailValidate(this.promoter.email, 1);
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.promoter.name) {
        this.formErrors.name = this.$ml.get("form_promoter_name_required");
        error++;
      } else {
        if (!nameValidate(this.promoter.name)) {
          this.formErrors.name = this.$ml.get("form_promoter_name_invalid");
          error++;
        }
      }

      if (!this.promoter.email) {
        this.formErrors.email = this.$ml.get("form_promoter_email_required");
        error++;
      } else {
        if (!emailValidate(this.promoter.email)) {
          this.formErrors.email = this.$ml.get("form_promoter_email_invalid");
          error++;
        }
      }

      if (!this.promoter.icNumber) {
        this.formErrors.icNumber = this.$ml.get("form_promoter_ic_required");
        error++;
      }

      if (!this.promoter.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get("form_promoter_contact_number_required");
        error++;
      } else {
        if (!phoneValidate(this.promoter.phoneNumber)) {
          this.formErrors.phoneNumber = this.$ml.get("invalid_phone_number");
          error++;
        }
      }

      if (!this.promoter.genderId) {
        this.formErrors.genderId = this.$ml.get("form_promoter_gender_required");
        error++;
      }
      if (!this.isLeader) {
        if (!this.promoter.promoterLeaderId) {
          this.formErrors.promoterLeaderId = this.$ml.get("form_promoter_promoter_leader_required");
          error++;
        }
      }

      if (this.promoter.bankId && !this.promoter.bankAccount) {
        this.formErrors.bankAccount = this.$ml.get("form_dealer_acc_number_required");
        error++;
      }

      return !error;
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (this.checkFormValidity(e)) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch(NOTIFICATION_REQUEST, {
              message: this.$ml.get("request_submitting")
            });
            let pdata = {};
            if (this.promoter.staffId > 0) {
              pdata.data = {}; //this.promoter;
              pdata.staffId = this.promoter.staffId;
              pdata.data.name = this.promoter.name;
              pdata.data.icNumber = this.promoter.icNumber;
              pdata.data.phoneNumber = this.promoter.phoneNumber;
              pdata.data.email = this.promoter.email;
              pdata.data.id = this.promoter.staffId;
              pdata.data.type = this.promoter.type;
              pdata.data.genderId = this.promoter.genderId ? this.promoter.genderId.id : 0;
              if (this.promoter.promoterTypeId) {
                pdata.data.promoterTypeId = this.promoter.promoterTypeId.id;
              }
              if (!this.isLeader) {
                pdata.data.promoterLeaderId = this.promoter.promoterLeaderId
                  ? this.promoter.promoterLeaderId.id
                  : 0;
              }
              pdata.data.bankId = this.promoter.bankId ? this.promoter.bankId.id : null;
              pdata.data.bankAccount = this.promoter.bankAccount;
            } else {
              let staff = {};
              staff.name = this.promoter.name;
              staff.icNumber = this.promoter.icNumber;
              staff.phoneNumber = this.promoter.phoneNumber;
              staff.email = this.promoter.email;
              staff.genderId = this.promoter.genderId ? this.promoter.genderId.id : 0;
              if (this.promoter.promoterTypeId) {
                staff.promoterTypeId = this.promoter.promoterTypeId.id;
              }
              if (!this.isLeader) {
                staff.promoterLeaderId = this.promoter.promoterLeaderId
                  ? this.promoter.promoterLeaderId.id
                  : 0;
              }
              staff.bankId = this.promoter.bankId ? this.promoter.bankId.id : 0;
              staff.bankAccount = this.promoter.bankAccount;
              staff.createdCountryId = this.$store.getters.getCountryId;
              staff.createdCountryCode = this.$store.getters.getCountryCode;
              pdata.data = [staff];
            }
            pdata.type = this.promoter.type;
            this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
              if (res.resultCode === 0) {
                this.success();
                if (this.promoter.staffId) {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("promoter_added")
                  });
                } else {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("promoter_updated")
                  });
                }
              }
            });
          }
        });
      }
    },
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;

          if (this.promoter.genderId) {
            this.promoter.genderId = this.getSelectedGender(this.promoter.genderId);
          }
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    getPromoterTypes() {
      this.isPromoterTypesLoading = true;
      this.$store.dispatch(PROMOTER_TYPE_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.promoterTypes = res.content;

          if (this.promoter.promoterTypeId) {
            this.promoter.promoterTypeId = this.getSelectedType(this.promoter.promoterTypeId);
          }
          this.isPromoterTypesLoading = false;
        } else {
          this.isPromoterTypesLoading = false;
        }
      });
    },
    getLeaders() {
      this.promoLeaders = [];
      this.isLeadersLoading = true;
      this.$store.dispatch(GET_STAFF_LIST_REQUEST, { type: "leaders" }).then(res => {
        if (res.resultCode === 0) {
          // this.promoLeaders = res.content;

          res.content.forEach(leader => {
            if (leader.status) {
              this.promoLeaders.push(leader);
            }
          });

          if (this.promoter.promoterLeaderId) {
            this.promoter.promoterLeaderId = this.getSelectedLeader(this.promoter.promoterLeaderId);
          }
          this.isLeadersLoading = false;
        } else {
          this.isLeadersLoading = false;
        }
      });
    },
    getStaff() {},
    getSelectedLeader(id) {
      let c = this.promoLeaders.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getSelectedGender(id) {
      let c = this.genders.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getSelectedType(id) {
      let c = this.promoterTypes.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getBanks() {
      let pdata = { countryCode: this.$store.getters.getCountryCode };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;

          if (this.promoter.bankId > 0) {
            this.promoter.bankId = this.getSelectedBank(this.promoter.bankId);
          }
        }
        this.getBanksIsLoading = false;
      });
    },
    getSelectedBank(id) {
      let c = this.banks.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    }
  },
  created() {},
  mounted() {
    this.userRole = this.$store.getters.getUserRole;
    this.userId = this.$store.getters.getUserId;
    this.isLeader = this.$store.getters.isPromoLeader;
    this.isCap = this.$store.getters.isCap;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.getGenders();
    this.getBanks();
    this.getPromoterTypes();
    if (!this.isLeader) {
      this.getLeaders();
    }
    if(this.promoter.staffId > 0 && !this.promoterSelected.newApprovalRequest){
      this.checkForPendingApprovalFlag();
    }
  }
};
</script>
