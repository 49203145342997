<template>
  <div class="row">
    <div class="col-md-12 uploadSection">
      <div class="input-group mb-3">
        <div class="vue-xlsx-container btn-lg">
          <button type="button" class="xlsx-button" @click="$refs.file.click()">
            Select File
          <small>** Only .xlsx files are allowed.</small>
          </button>
        </div>
        <input
          id="file"
          ref="file"
          v-validate="'required'"
          type="file"
          name="file"
          accept=".xlsx, .xls"
          class="d-none"
          @change="handleFileUpload($event)"
        />
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <a v-if="doExportDefault" class="link lnv-link" @click="doExportDefault">
        <i class="fa fa-download"></i>
        <span class="ml-2">Download Excel Template File</span>
      </a>
      <br />
      <br />

      <p v-if="contentHtml" class="black-text">{{ contentHtml }}</p>
    </div>

    <div v-if="isSubmit" class="col-lg-12 mb-5">
      <div v-if="!errorFilePath" class="p-3 alert-success">
        Validation completed, please click submit to continue
      </div>
      <div v-else class="alert-danger p-4">
        We found that file you uploaded contains error(s). Please re-upload the file (for you convenience we highlighted errors in red)
        <a :href="errorFilePath" target="_blank" class="btn btn-success float-right download-btn">Download</a>
      </div>
    </div>

    <div class="col-lg-12">
      <button type="submit" class="btn btn-secondary pull-right" @click="handleSubmit($event)">
        {{ submitButton }}
      </button>
      <button type="button" class="btn btn-light pull-right" @click="close()">Cancel</button>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { Message } from "element-ui";
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
import { export_json_to_excel } from "../../utils/Export2Excel";
import {
  TARGET_PROMOTER_CHECK_FILE_REQUEST,
  UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST
} from "../../store/modules/target/actions";
import { GET_ALL_PROMOTERS_LIST_REQUEST } from "../../store/modules/staff/actions";

export default {
  name: "RegionUploadPromoterTargetForm",
  components: {},
  props: {
    closeModal: { type: Function, default: () => {} },
    content: { type: String, default: "" },
    sampleFile: { type: String, default: "" },
    setPostData: { type: Function, default: () => {} },
    submitButtonText: { type: String, default: "" },
    getUploadFile: { type: Function, default: () => {} },
    fileColumns: { type: Array, default: () => [] },
    fileData: { type: Array, default: () => [] }
  },
  computed: {},
  data() {
    return {
      validateFields: this.validations,
      contentHtml: this.content,
      displayDataTable: this.showDataList,
      submitButton: this.submitButtonText ? this.submitButtonText : "Upload",
      rows: [],
      headers: [],
      file: {},
      dataErrors: [],
      defaultFileName: this.sampleFile,
      fileHeaderColumns: this.fileColumns ? this.fileColumns : [],
      fileSampleData: [],
      errorFilePath: null,
      targetPromoterWeeklyUploadId: null,
      isSubmit: false,
      promoters: [],
      loaderOption:{
        container:  this.$refs.formContainer,
        canCancel: false,
        width: 125,
        height: 125,
        color: '#f70301',
        backgroundColor: '#4f4f4f'
      }
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (this.checkUploadType(file.name)) {
        this.getBase64(file).then(data => {
          this.checkFile(data);
        });
      }
    },
    async checkFile(fileBase64){
      this.isSubmit = false;
      let data = {
        base64: fileBase64
      };
      const res = await this.$store.dispatch(TARGET_PROMOTER_CHECK_FILE_REQUEST, data);
      if (res.resultCode === 0) {
        this.errorFilePath = res.content.errorFilePath;
        this.targetPromoterWeeklyUploadId = res.content.targetPromoterWeeklyUploadId;
      }
      this.isSubmit = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(btoa(reader.result));
        reader.onerror = error => reject(error);
        reader.readAsBinaryString(file);
      });
    },
    checkUploadType(file) {
      let filename = file.split(".").pop();
      if (filename == "xlsx" || filename == "xlsm" || filename == "xls" || filename == "xltx") {
        return true;
      } else {
        Message({
          message: "File type must be .xlsx, xlsm, xls, or xltx",
          type: "error",
          duration: 5 * 1000
        });
        return false;
      }
    },
    isHeaderMatch(a, b) {
      return b.every(val => a.includes(val));
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit() {
      if(this.isSubmit){
        if(this.targetPromoterWeeklyUploadId){
          let loader = this.$loading.show(this.loaderOption);
          let data = {
            targetPromoterWeeklyUploadId: this.targetPromoterWeeklyUploadId
          }
          this.$store.dispatch(UPLOAD_TARGET_PROMOTER_BY_ID_REQUEST, data).then(res => {
            if (res.resultCode === 0) {
              Message({
                message: "Upload completed",
                type: "success",
                duration: 5 * 1000
              });
              loader.hide();
              this.close();
            }
          });
        }
      }
    },
    addFile() {
      this.fileErrors = 0;
      try {
        readXlsxFile(this.$refs.upFile.files[0]).then(rows => {
          const head = rows[0];
          const items = rows;
          this.headers = head;
          delete items[0];
        });
      } catch (error) {
        // console.log(error);
      }
    },
    setRows() {},
    resetUpload() {
      this.headers = [];
      this.rows = [];
      this.fileError = 0;
      this.fileValid = false;
    },
    doExportDefault() {
      const tHeader = this.fileHeaderColumns;
      const data = this.fileSampleData;
      const fileName = this.defaultFileName;
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "Sheet 1",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    async getAllPromoters(){
      const res = await this.$store.dispatch(GET_ALL_PROMOTERS_LIST_REQUEST);
      if (res.resultCode === 0) {
        this.promoters = res.content;
        this.setExcelSampleData(this.promoters);
      }
    },
    setExcelSampleData(arr){
      this.fileSampleData = [];
      arr.forEach(item => {
        let row = [
          item.fullname, item.accId, item.dealerOutletCode, "", "", "", "", "", "", "", "", "", "", "", "", "", ""
        ];
        this.fileSampleData.push(row);
      });
    }
  },
  created() {},
  mounted() {
    this.getAllPromoters();
  }
};
</script>

<style>
.vue-xlsx-container {
  width: 100% !important;
}
.xlsx-button {
  width: 100%;
  height: 130px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #ccc !important;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 7px 9px;
  font-size: 15px;
  border-radius: 4px;
  color: #949090 !important;
  background-color: #f9f2f2 !important;
}

.link {
  cursor: pointer;
}

.uploadSection {
  height: 120px;
}

.download-btn{
  bottom: 4px;
}
</style>
