import store from "@/store";
import PassThrough from "@/views/PassThrough";

import {
  ROLE_ADMIN,
  LAYOUT_ADMIN,
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const adminRouter = {
  path: "/admin",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
    ]
  },
  children: [
    {
      path: "cache",
      name: "Cache Management",
      component: () => import("../../views/admin/cache.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN
        ]
      }
    },
    {
      path: "registration",
      name: "Registration",
      component: () => import("../../views/admin/registration.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN
        ]
      }
    },
  ],
  beforeEnter: ifAuthenticated
};

export default adminRouter;
