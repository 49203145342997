<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6">
          <label class="text-label">Effective Date</label>
          <Datepicker
            v-model="effectiveDate"
            v-validate="'required'"
            :minimum-view="'day'"
            :open-date="tmr"
            :maximum-view="'year'"
            :initial-view="'day'"
            name="Start Date"
            :bootstrap-styling="true"
            :required="true"
            placeholder="Select Start Date"
            :disabled-dates="disabledDates"
            class="bottom"
            @closed="checkDates"
          ></Datepicker>
        </div>
        <div v-if="!canEdit" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label class="text-label">SERIAL Numbers File</label>
            <div class="input-group mb-3">
              <input
                id="upFile"
                ref="upFile"
                type="file"
                class="form-control"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="addFile($event)"
              />
              <div class="input-group-append">
                <span id="reset-upload" class="input-group-text" @click="resetUpload($event)">Reset</span>
              </div>
            </div>

            <p>
              <a class="link lnv-link" @click="doExportDefault">
                <i class="fa fa-download"></i>
                <span class="ml-2">Download sample EXCEL file</span>
              </a>
            </p>
          </div>
        </div>
      </div>

      <div v-if="displayDataTable" class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th v-for="(item, index) of headers" :key="index">{{ item }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) of rows" :key="index">
                <td v-for="(key, index3) of item" :key="index3">
                  <!--  eslint-disable vue/no-v-html -->
                  <label v-html="key"></label>
                  <!-- <p>{{item.key}}</p> -->
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            v-if="!canEdit"
            type="button"
            class="btn btn-primary pull-right"
            @click="doUpload($event)"
          >
            <i class="fa fa-upload"></i> UPLOAD
          </button>
          <button
            v-if="canEdit"
            type="button"
            class="btn btn-primary pull-right"
            @click="doUpdate($event)"
          >
            <i class="fa fa-upload"></i> UPDATE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { Message } from "element-ui";
import { LOCK_SERIAL_UPDATE_REQUEST } from "../../store/modules/serialNo/actions";
import Datepicker from "vuejs-datepicker";
import { convertDateFormat } from "../../utils/helpers";
import { export_json_to_excel } from "../../utils/Export2Excel";
const today = new Date();

export default {
  name: "LockForm",
  components: { Datepicker },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    selectedSerial: {
      type: Object,
      default: () => {
        return {};
      }
    },
    canEdit: { type: Boolean }
  },
  computed: {
    defaultFileName() {
      return "LockSerialNumber" + ("0" + (today.getMonth() + 1)).slice(-2) + today.getFullYear();
    }
  },
  data() {
    return {
      disabledDates: {
        to: "", // Disable all dates up to specific date
        from: "" // Disable all dates after specific date
      },
      displayDataTable: true,
      headers: [],
      rows: [],
      file: {},
      dataErrors: [],
      fileValid: false,
      fileErrors: 0,
      // defaultFileName: "LockSerialNumber",
      fileHeaderColumns: ["SERIAL Number"],
      today: new Date(),
      tmr: new Date(new Date().setDate(new Date().getDate() + 1)),
      serial: {
        id: this.selectedSerial.id,
        startDate: this.selectedSerial.startDate,
        endDate: this.selectedSerial.endDate
      },
      effectiveDate: new Date(new Date().setDate(new Date().getDate() + 1))
    };
  },
  methods: {
    checkDates() {},
    disablingDates() {
      this.disabledDates.to = new Date(new Date().setDate(new Date().getDate()));
    },
    checkFormValidity(value, row, column) {
      let field = this.headers[column];

      const resp = this.validateFields(value, field);
      this.dataErrors[row] = resp.valid ? true : false;

      if (resp.valid) {
        this.fileValid = true;
      } else {
        this.fileValid = false;
        this.fileErrors++;
      }

      return resp.value;
    },
    validateFields(value, field) {
      let isValid = false;
      switch (field) {
        case "SERIAL Number":
          isValid = true;
          break;
        default:
          isValid = true;
          break;
      }
      if (!isValid) {
        value = '<span class="invalid-field">' + value + "</span>";
      }

      return {
        value: value,
        valid: isValid
      };
    },
    addFile() {
      this.fileErrors = 0;
      // eslint-disable-next-line no-unused-vars
      let file = this.$refs.upFile.files[0];
      this.file = {
        name: this.$refs.upFile.files[0].name,
        size: this.$refs.upFile.files[0].size,
        type: this.$refs.upFile.files[0].type,
        lastModified: this.$refs.upFile.files[0].lastModified
      };
      readXlsxFile(this.$refs.upFile.files[0]).then(rows => {
        const head = rows[0];
        const items = rows;
        this.headers = head;
        delete items[0];

        const validFileHeader = this.isHeaderMatch(this.fileHeaderColumns, this.headers);

        if (validFileHeader) {
          this.setRows(items);
        } else {
          Message({
            message: "Invalid header titles found, correct and upload again.",
            type: "error",
            duration: 5 * 1000
          });

          this.headers = [];
        }
      });
    },
    isHeaderMatch(a, b) {
      return a.toString() === b.toString();
    },
    setRows(data) {
      data.forEach((row, index) => {
        let item = {};
        row.forEach((key, index3) => {
          item[index3] = this.checkFormValidity(key, index, index3);
        });

        this.rows.push(item);
      });
    },
    formatPostData(rows) {
      let arr = [];
      rows.forEach(row => {
        arr.push(row[0]);
      });

      return arr;
    },
    doUpload(e) {
      if (!this.fileValid || this.fileErrors > 0) {
        Message({
          message: "File contain invalid data, correct and upload again.",
          type: "error",
          duration: 5 * 1000
        });
      } else {
        let lockDate = convertDateFormat(this.effectiveDate, "YYYY-MM-DD");
        const serial = {
          fileName: this.file.name,
          serialList: this.formatPostData(this.rows),
          lockDate: lockDate
        };
        this.$store.dispatch(LOCK_SERIAL_UPDATE_REQUEST, serial).then(res => {
          if (res.resultCode === 0) {
            Message({
              message: "Lock SERIAL Numbers uploaded.",
              type: "success",
              duration: 5 * 1000
            });
            this.loadData();
            this.closeModal();
            this.resetUpload(e);
          }
        });
      }
    },
    resetUpload() {
      if (document.getElementById("upFile")) document.getElementById("upFile").value = "";
      this.headers = [];
      this.rows = [];
      this.fileError = 0;
      this.fileValid = false;
    },
    doExportDefault() {
      const tHeader = this.fileHeaderColumns;
      const data = [];
      const fileName = this.defaultFileName;
      export_json_to_excel({
        header: tHeader,
        data,
        filename: fileName,
        sheetName: "Sheet 1",
        autoWidth: true,
        bookType: "xlsx"
      });
    },
    updateDateValues() {},
    doUpdate(e) {
      let unlockDate = convertDateFormat(this.effectiveDate, "YYYY-MM-DD");
      const serial = {
        id: this.serial.id,
        unlockDate: unlockDate
      };
      this.$store.dispatch(LOCK_SERIAL_UPDATE_REQUEST, serial).then(res => {
        if (res.resultCode === 0) {
          Message({
            message: "SERIAL Numbers Successfully Unlock.",
            type: "success",
            duration: 5 * 1000
          });
          this.loadData();
          this.closeModal();
          this.resetUpload(e);
        }
      });
    }
  },
  mounted() {
    this.disablingDates();
  }
};
</script>
