import request from "@/utils/request";
import {
  API_PIC_LIST_BY_DEALER_SERVICE,
  API_OUTLET_PIC_LIST_SERVICE,
  API_SINGLE_OUTLET_PIC_SERVICE,
  UPDATE_OUTLET_PIC_SERVICE,
  ADD_OUTLET_PIC_SERVICE,
  API_OUTLET_LIST_SERVICE,
  API_SINGLE_OUTLET_SERVICE,
  UPDATE_OUTLET_SERVICE,
  UPDATE_DEALER_SERVICE,
  ADD_OUTLET_SERVICE,
  UPLOAD_BULK_OUTLET_SERVICE,
  API_DEALER_OUTLET_LIST_SERVICE,
  GET_DEALERS_OUTLETS_LIST_SERVICE,
  API_EXPORT_DEALER_OUTLET_DATA,
  API_GET_ALL_DEALER_OUTLETS_BY_REGION,
  API_GET_OUTLET_CHANGES,
  GET_MERCHANDISER_VISITATION_BY_OUTLET_ID,
  GET_TASK_BY_VISITATION_ID
} from "../constants/services";

import { DEFAULT_TIMEOUT, EXTENDED_TIMEOUT } from "../constants/index";

export async function getPicListByDealerId(postData) {
  return await request({
    url: API_PIC_LIST_BY_DEALER_SERVICE + "/" + postData.dId,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}
export async function getExportData(postData) {
  return await request({
    url: API_EXPORT_DEALER_OUTLET_DATA + "?ccf=" + postData.ccf + "&fyf=" + postData.fyf + "&qf=" + postData.qf,
    method: "get",
    timeout: EXTENDED_TIMEOUT
  });
}
export async function getOutletPicList(postData) {
  return await request({
    url: API_OUTLET_PIC_LIST_SERVICE,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletPICById(postData) {
  const data = {
    id: postData.id
  };
  return await request({
    url: API_SINGLE_OUTLET_PIC_SERVICE + data.id,
    method: "get",
    params: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addOrUpdateOutletPic(postData) {
  let url = ADD_OUTLET_PIC_SERVICE;
  let userData = JSON.parse(localStorage.getItem("user"));
  let countryId = 0;
  let ownerAccId = 8;
  if (userData) {
    countryId = userData.user.createdCountryId;
  }
  let data = {};

  if (postData.change_status) {
    data = {
      statusType: postData.statusType == "ACTIVE" ? "DEACTIVATED" : "ACTIVE"
    };
    url = UPDATE_OUTLET_PIC_SERVICE;
  } else {
    data = {
      dealerId: postData.dealerId ? postData.dealerId.id : 0,
      picName: postData.picName,
      email: postData.email,
      countryId: countryId,
      ownerAccId: ownerAccId
    };

    if (!postData.id) {
      data.statusType = "ACTIVE";
    }
  }

  if (postData.outletId || postData.id) {
    data.id = postData.outletId ? postData.outletId : postData.id;
    url = UPDATE_OUTLET_PIC_SERVICE;
  }

  return await request({
    url: url,
    method: "put",
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletList(postData) {
  return await request({
    url: API_OUTLET_LIST_SERVICE,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletsListWithDealer(postData) {
  return request({
    url: `${GET_DEALERS_OUTLETS_LIST_SERVICE}${"?ccf=" + postData.ccf}${postData.fyf ? "&fyf=" + postData.fyf : ``}${postData.qf ? "&qf=" + postData.qf : ``}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getDealerOutletList() {
  return await request({
    url: API_DEALER_OUTLET_LIST_SERVICE,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletById(postData) {
  return await request({
    url: API_SINGLE_OUTLET_SERVICE + postData.id,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addOrUpdateOutlet(postData) {

  let url = ADD_OUTLET_SERVICE;
  let data = {};
  
  if (postData.change_status) {
    data = {
      status: postData.status === "DEACTIVATED" ? "ACTIVE" : "DEACTIVATED",
      updatePic: false,
      statusUpdateReason: postData.statusUpdateReason
    };
    url = UPDATE_OUTLET_SERVICE;
  } else if (postData.change_outlet) {
    data = {
      outletPicId: postData.outletPicId ? postData.outletPicId.id : null,
      name: postData.name,
      updatePic: true
    };
    url = UPDATE_OUTLET_SERVICE;
  } else if (postData.change_dealer_status) {
    data = {
      status: postData.dealerStatus === "DEACTIVATED" ? "ACTIVE" : "DEACTIVATED",
      updatePic: false
    };
    url = UPDATE_DEALER_SERVICE;
  } else {
    data = {
      name: postData.name,
      countryId: postData.countryId,
      dealerId: postData.dealerId.id,
      outletPicId: postData.outletPicId ? postData.outletPicId.id : null,
      subregionId: postData.subRegion.id,
      stateId: postData.stateId.id,
      address: postData.address,
      area: postData.area,
      postCode: postData.postCode,
      typeId: postData.typeId ? postData.typeId.id : null,
      storeTypeId: postData.storeTypeId ? postData.storeTypeId.id : null,
      accreditationId: postData.accreditationId ? postData.accreditationId.id : null,
      focusProductId: postData.focusProductId ? postData.focusProductId.id : null,
      storeIdentityId: postData.storeIdentityId ? postData.storeIdentityId.id : null,
      latitude: postData.latitude,
      longitude: postData.longitude,
      updatePic: true,
      totalPcSOCapacity: postData.totalPcSOCapacity,
    };
    if (postData.updateReason) data.updateReason = postData.updateReason
    else data.reason = postData.reason
    
    if (!postData.id) {
      data.status = "ACTIVE";
    }
  }

  if (postData.outletId || postData.id || (postData.dealerId && postData.change_dealer_status)) {
    data.id = postData.change_dealer_status ? postData.dealerId : postData.outletId ? postData.outletId : postData.id;
    url = postData.change_dealer_status ? UPDATE_DEALER_SERVICE : UPDATE_OUTLET_SERVICE;
  }
  return await request({
    url: url,
    method: "put",
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function outletBulkUpload(pData) {
  return await request({
    url: UPLOAD_BULK_OUTLET_SERVICE,
    method: "post",
    data: pData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletWithPICList(pData) {
  return await request({
    url: "",
    method: "post",
    data: pData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getAllDealerOutletsByRegion() {
  return await request({
    url: API_GET_ALL_DEALER_OUTLETS_BY_REGION,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getOutletChanges(data) {
  return await request({
    url: `${API_GET_OUTLET_CHANGES}/${data.id}/${data.outletId}`,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getMerchandiserVisitByOutletId(postData) {
  return request({
    url: GET_MERCHANDISER_VISITATION_BY_OUTLET_ID,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getVisitationTaskById(data) {
  return request({
    url: GET_TASK_BY_VISITATION_ID+ data,
    method: "get",
    timeout: DEFAULT_TIMEOUT
  });
}