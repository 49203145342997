import {
  getPendingSalespList,
  approveOrRejectSalesperson,
  getStaffList,
  getPromoterListByPromoterSupervisorId,
  getStaffPromoterList,
  getStaffPromoterSupervisorList,
  getActivePromoters,
  addOrUpdateStaff,
  addOrUpdatePromoterOutlet,
  getAllPromoters,
  getStaffSalespersonList,
  getPendingDealerListRequest,
  getPendingPromoterLeaderListRequest,
  getPendingDealerOutletListRequest,
  getPendingPromoterListRequest,
  getPendingMerchandiserListRequest,
  getPendingMerchandiserLeaderListRequest,
  updateApprovalPromoterRequest,
  getDealerPendingApprovalFlag,
  getDealerOutletPendingApprovalFlag,
  getPromoterPendingApprovalFlag,
  getPromoterLeaderPendingApprovalFlag,
  updateApprovalDealerOutlet,
  updateApprovalDealer,
  updateApprovalMerchandiser,
  updateApprovalMerchandiserLeader,
  updateApprovalProm,
  getPromoterOutletAssignmentPendingApprovalFlag,
  getAllMerchandisers,
  getAllMerchandiserLeaders,
  getMerchandiserPendingApprovalFlag,
  getMerchandiserLeaderPendingApprovalFlag,
  getMerchandiserByLeaderId
} from "@/api/staff";
import {
  PENDING_SALESP_LIST_REQUEST,
  PENDING_SALESP_LIST_ERROR,
  PENDING_SALESP_LIST_SUCCESS,
  PENDING_SALESP_STATUS_REQUEST,
  PENDING_SALESP_STATUS_SUCCESS,
  PENDING_SALESP_STATUS_ERROR,
  GET_STAFF_LIST_REQUEST,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST_ERROR,
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST,
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_SUCCESS,
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_ERROR,
  GET_SALESPERSON_LIST_REQUEST,
  GET_SALESPERSON_LIST_SUCCESS,
  GET_SALESPERSON_LIST_ERROR,
  GET_STAFF_PROMOTER_LIST_REQUEST,
  GET_STAFF_PROMOTER_LIST_REQUEST_SUCCESS,
  GET_STAFF_PROMOTER_LIST_REQUEST_ERROR,
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST,
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_SUCCESS,
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_ERROR,
  POST_STAFF_REQUEST,
  POST_STAFF_SUCCESS,
  POST_STAFF_ERROR,
  POST_PROMOTER_OUTLET_REQUEST,
  POST_PROMOTER_OUTLET_SUCCESS,
  POST_PROMOTER_OUTLET_ERROR,
  GET_ACTIVE_PROMOTERS_LIST_REQUEST,
  GET_ACTIVE_PROMOTERS_LIST_SUCCESS,
  GET_ACTIVE_PROMOTERS_LIST_ERROR,
  GET_ALL_PROMOTERS_LIST_REQUEST,
  GET_ALL_PROMOTERS_LIST_SUCCESS,
  GET_ALL_PROMOTERS_LIST_ERROR,
  PENDING_DEALER_LIST_REQUEST,
  PENDING_DEALER_LIST_REQUEST_SUCCESS,
  PENDING_DEALER_LIST_REQUEST_ERROR,
  PENDING_PROMOTER_LEADER_LIST_REQUEST,
  PENDING_PROMOTER_LEADER_LIST_REQUEST_SUCCESS,
  PENDING_PROMOTER_LEADER_LIST_REQUEST_ERROR,
  PENDING_DEALER_OUTLET_LIST_REQUEST,
  PENDING_DEALER_OUTLET_LIST_REQUEST_SUCCESS,
  PENDING_DEALER_OUTLET_LIST_REQUEST_ERROR,
  PENDING_PROMOTER_LIST_REQUEST,
  PENDING_PROMOTER_LIST_REQUEST_SUCCESS,
  PENDING_PROMOTER_LIST_REQUEST_ERROR,
  PENDING_MERCHANDISER_LIST_REQUEST,
  PENDING_MERCHANDISER_LIST_REQUEST_SUCCESS,
  PENDING_MERCHANDISER_LIST_REQUEST_ERROR,
  PENDING_MERCHANDISER_LEADER_LIST_REQUEST,
  UPDATE_APPROVAL_PROMOTER_REQUEST,
  UPDATE_APPROVAL_PROMOTER_REQUEST_SUCCESS,
  UPDATE_APPROVAL_PROMOTER_REQUEST_ERROR,
  GET_DEALER_PENDING_APPROVAL_FLAG,
  GET_DEALER_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_DEALER_PENDING_APPROVAL_FLAG_ERROR,
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG,
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_ERROR,
  GET_PROMOTER_PENDING_APPROVAL_FLAG,
  GET_PROMOTER_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_PROMOTER_PENDING_APPROVAL_FLAG_ERROR,
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG,
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_ERROR,
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST,
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_SUCCESS,
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_ERROR,
  UPDATE_APPROVAL_DEALER_REQUEST,
  UPDATE_APPROVAL_DEALER_REQUEST_SUCCESS,
  UPDATE_APPROVAL_DEALER_REQUEST_ERROR,
  UPDATE_APPROVAL_MERCHANDISER_REQUEST,
  UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST,
  UPDATE_APPROVAL_MERCHANDISER_REQUEST_SUCCESS,
  UPDATE_APPROVAL_MERCHANDISER_REQUEST_ERROR,
  UPDATE_APPROVAL_PROM_REQUEST,
  UPDATE_APPROVAL_PROM_REQUEST_SUCCESS,
  UPDATE_APPROVAL_PROM_REQUEST_ERROR,
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG,
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_ERROR,
  GET_MERCHANDISER_LIST_REQUEST,
  GET_MERCHANDISER_LIST_SUCCESS,
  GET_MERCHANDISER_LIST_ERROR,
  GET_MERCHANDISER_LEADER_LIST_REQUEST,
  GET_MERCHANDISER_LEADER_LIST_SUCCESS,
  GET_MERCHANDISER_LEADER_LIST_ERROR,
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG,
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG_SUCCESS,
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG_ERROR,
  GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG,
  GET_MERCHANDISER_LIST_BY_ID_REQUEST,
  GET_MERCHANDISER_LIST_BY_ID_SUCCESS,
  GET_MERCHANDISER_LIST_BY_ID_ERROR
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  pendingStaff: [],
  staffList: [],
  staff: {},
  status: "",
  hasLoadedOnce: false
};

const getters = {
  getPendingSalesP: state => state.pendingStaff
};

const actions = {
  [PENDING_SALESP_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_SALESP_LIST_REQUEST);
      getPendingSalespList(postData)
        .then(resp => {
          commit(PENDING_SALESP_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PENDING_SALESP_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_SALESP_STATUS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_SALESP_STATUS_REQUEST);
      approveOrRejectSalesperson(postData)
        .then(resp => {
          commit(PENDING_SALESP_STATUS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(PENDING_SALESP_STATUS_ERROR, err);
          reject(err);
        });
    });
  },

  [GET_STAFF_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_STAFF_LIST_REQUEST);
      getStaffList(postData)
        .then(resp => {
          commit(GET_STAFF_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_STAFF_LIST_ERROR, err);
          reject(err);
        });
    });
  },

  [GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST);
      getPromoterListByPromoterSupervisorId(postData)
          .then(resp => {
            commit(GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_ERROR, err);
            reject(err);
          });
    });
  },

  [GET_STAFF_PROMOTER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_STAFF_PROMOTER_LIST_REQUEST);
      getStaffPromoterList(postData)
          .then(resp => {
            commit(GET_STAFF_PROMOTER_LIST_REQUEST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_STAFF_PROMOTER_LIST_REQUEST_ERROR, err);
            reject(err);
          });
    });
  },

  [GET_SALESPERSON_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_SALESPERSON_LIST_REQUEST);
      getStaffSalespersonList(postData)
          .then(resp => {
            commit(GET_SALESPERSON_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_SALESPERSON_LIST_ERROR, err);
            reject(err);
          });
    });
  },

  [GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST);
      getStaffPromoterSupervisorList(postData)
          .then(resp => {
            commit(GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_ACTIVE_PROMOTERS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_ACTIVE_PROMOTERS_LIST_REQUEST);
      getActivePromoters(postData)
        .then(resp => {
          commit(GET_ACTIVE_PROMOTERS_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_ACTIVE_PROMOTERS_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_ALL_PROMOTERS_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_ALL_PROMOTERS_LIST_REQUEST);
      getAllPromoters(postData)
          .then(resp => {
            commit(GET_ALL_PROMOTERS_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_ALL_PROMOTERS_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [POST_STAFF_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(POST_STAFF_REQUEST);
      addOrUpdateStaff(postData)
        .then(resp => {
          commit(POST_STAFF_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(POST_STAFF_ERROR, err);
          reject(err);
        });
    });
  },
  [POST_PROMOTER_OUTLET_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(POST_PROMOTER_OUTLET_REQUEST);
      addOrUpdatePromoterOutlet(postData)
        .then(resp => {
          commit(POST_PROMOTER_OUTLET_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(POST_PROMOTER_OUTLET_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_DEALER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_DEALER_LIST_REQUEST);
      getPendingDealerListRequest(postData)
        .then(resp => {
          commit(PENDING_DEALER_LIST_REQUEST_SUCCESS, resp);
          resolve({res: resp, list: 'dealer'});
        })
        .catch(err => {
          commit(PENDING_DEALER_LIST_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_PROMOTER_LEADER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_PROMOTER_LEADER_LIST_REQUEST);
      getPendingPromoterLeaderListRequest(postData)
        .then(resp => {
          commit(PENDING_PROMOTER_LEADER_LIST_REQUEST_SUCCESS, resp);
          resolve({res: resp, list: 'promoter-leader'});
        })
        .catch(err => {
          commit(PENDING_PROMOTER_LEADER_LIST_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_DEALER_OUTLET_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_DEALER_OUTLET_LIST_REQUEST);
      getPendingDealerOutletListRequest(postData)
        .then(resp => {
          commit(PENDING_DEALER_OUTLET_LIST_REQUEST_SUCCESS, resp);
          resolve({res: resp, list: 'outlet'});
        })
        .catch(err => {
          commit(PENDING_DEALER_OUTLET_LIST_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_PROMOTER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_PROMOTER_LIST_REQUEST);
      getPendingPromoterListRequest(postData)
        .then(resp => {
          commit(PENDING_PROMOTER_LIST_REQUEST_SUCCESS, resp);
          resolve({res: resp, list: 'promoter'});
        })
        .catch(err => {
          commit(PENDING_PROMOTER_LIST_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_MERCHANDISER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PENDING_MERCHANDISER_LIST_REQUEST);
      getPendingMerchandiserListRequest(postData)
        .then(resp => {
          commit(PENDING_MERCHANDISER_LIST_REQUEST_SUCCESS, resp);
          resolve({res: resp, list: 'merchandiser'});
        })
        .catch(err => {
          commit(PENDING_MERCHANDISER_LIST_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [PENDING_MERCHANDISER_LEADER_LIST_REQUEST]: (_, postData) => {
    return new Promise((resolve, reject) => {
      getPendingMerchandiserLeaderListRequest(postData)
        .then(resp => {
          resolve({res: resp, list: 'merchandiserLeader'});
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_PROMOTER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_APPROVAL_PROMOTER_REQUEST);
      updateApprovalPromoterRequest(postData)
        .then(resp => {
          commit(UPDATE_APPROVAL_PROMOTER_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_APPROVAL_PROMOTER_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_DEALER_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DEALER_PENDING_APPROVAL_FLAG);
      getDealerPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_DEALER_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_DEALER_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG);
      getDealerOutletPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PROMOTER_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_PENDING_APPROVAL_FLAG);
      getPromoterPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_PROMOTER_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PROMOTER_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG);
      getPromoterLeaderPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_DEALER_OUTLET_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_APPROVAL_DEALER_OUTLET_REQUEST);
      updateApprovalDealerOutlet(postData)
        .then(resp => {
          commit(UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_DEALER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_APPROVAL_DEALER_REQUEST);
      updateApprovalDealer(postData)
        .then(resp => {
          commit(UPDATE_APPROVAL_DEALER_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_APPROVAL_DEALER_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_MERCHANDISER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_APPROVAL_MERCHANDISER_REQUEST);
      updateApprovalMerchandiser(postData)
        .then(resp => {
          commit(UPDATE_APPROVAL_MERCHANDISER_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_APPROVAL_MERCHANDISER_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_MERCHANDISER_LEADER_REQUEST]: (_, postData) => {
    return new Promise((resolve, reject) => {
      updateApprovalMerchandiserLeader(postData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [UPDATE_APPROVAL_PROM_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_APPROVAL_PROM_REQUEST);
      updateApprovalProm(postData)
        .then(resp => {
          commit(UPDATE_APPROVAL_PROM_REQUEST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_APPROVAL_PROM_REQUEST_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG);
      getPromoterOutletAssignmentPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_MERCHANDISER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_MERCHANDISER_LIST_REQUEST);
      getAllMerchandisers(postData)
          .then(resp => {
            commit(GET_MERCHANDISER_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_MERCHANDISER_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_MERCHANDISER_LEADER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_MERCHANDISER_LEADER_LIST_REQUEST);
      getAllMerchandiserLeaders(postData)
          .then(resp => {
            commit(GET_MERCHANDISER_LEADER_LIST_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_MERCHANDISER_LEADER_LIST_ERROR, err);
            reject(err);
          });
    });
  },
  [GET_MERCHANDISER_PENDING_APPROVAL_FLAG]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_MERCHANDISER_PENDING_APPROVAL_FLAG);
      getMerchandiserPendingApprovalFlag(postData)
        .then(resp => {
          commit(GET_MERCHANDISER_PENDING_APPROVAL_FLAG_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_MERCHANDISER_PENDING_APPROVAL_FLAG_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_MERCHANDISER_LEADER_PENDING_APPROVAL_FLAG]: (_, postData) => {
    return new Promise((resolve, reject) => {
      getMerchandiserLeaderPendingApprovalFlag(postData)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_MERCHANDISER_LIST_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_MERCHANDISER_LIST_BY_ID_REQUEST);
      getMerchandiserByLeaderId(postData)
          .then(resp => {
            commit(GET_MERCHANDISER_LIST_BY_ID_SUCCESS, resp);
            resolve(resp);
          })
          .catch(err => {
            commit(GET_MERCHANDISER_LIST_BY_ID_ERROR, err);
            reject(err);
          });
    });
  },
};

const mutations = {
  PENDING_SALESP_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_SALESP_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      pendingStaff: data,
      hasLoadedOnce: true
    });
  },
  PENDING_SALESP_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      products: [],
      hasLoadedOnce: true
    });
  },
  PENDING_SALESP_STATUS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_SALESP_STATUS_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      pendingStaff: [],
      hasLoadedOnce: true
    });
  },
  PENDING_SALESP_STATUS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      product: {},
      hasLoadedOnce: true
    });
  },
  GET_STAFF_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_STAFF_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      staffList: data,
      hasLoadedOnce: true
    });
  },
  GET_STAFF_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      staffList: [],
      hasLoadedOnce: true
    });
  },
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      staffList: data,
      hasLoadedOnce: true
    });
  },
  GET_STAFF_PROMOTER_SUPERVISOR_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      staffList: [],
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      staffList: data,
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LIST_BY_PROMOTER_SUPERVISOR_ID_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      staffList: [],
      hasLoadedOnce: true
    });
  },
  POST_STAFF_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  POST_STAFF_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      staff: data,
      hasLoadedOnce: true
    });
  },
  POST_STAFF_ERROR: state => {
    Object.assign(state, {
      status: "error",
      staff: {},
      hasLoadedOnce: true
    });
  },
  POST_PROMOTER_OUTLET_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  POST_PROMOTER_OUTLET_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      staff: data,
      hasLoadedOnce: true
    });
  },
  POST_PROMOTER_OUTLET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      staff: {},
      hasLoadedOnce: true
    });
  },
  GET_STAFF_PROMOTER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_STAFF_PROMOTER_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_STAFF_PROMOTER_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },

  GET_SALESPERSON_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_SALESPERSON_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_SALESPERSON_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },

  GET_ACTIVE_PROMOTERS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_ACTIVE_PROMOTERS_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_ACTIVE_PROMOTERS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_ALL_PROMOTERS_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_ALL_PROMOTERS_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_ALL_PROMOTERS_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PENDING_DEALER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_DEALER_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PENDING_DEALER_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PENDING_PROMOTER_LEADER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_PROMOTER_LEADER_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PENDING_PROMOTER_LEADER_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PENDING_DEALER_OUTLET_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_DEALER_OUTLET_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PENDING_DEALER_OUTLET_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PENDING_PROMOTER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_PROMOTER_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PENDING_PROMOTER_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PENDING_MERCHANDISER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PENDING_MERCHANDISER_LIST_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PENDING_MERCHANDISER_LIST_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_PROMOTER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_APPROVAL_PROMOTER_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_PROMOTER_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_DEALER_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DEALER_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_DEALER_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_DEALER_OUTLET_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_LEADER_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_DEALER_OUTLET_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_DEALER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_APPROVAL_DEALER_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_DEALER_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_PROM_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_APPROVAL_PROM_REQUEST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  UPDATE_APPROVAL_PROM_REQUEST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_PROMOTER_OUTLET_ASSIGNMENT_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_MERCHANDISER_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LEADER_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_MERCHANDISER_LEADER_LIST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LEADER_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_PENDING_APPROVAL_FLAG_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LIST_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_MERCHANDISER_LIST_BY_ID_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  GET_MERCHANDISER_LIST_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
