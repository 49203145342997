export const INCENTIVE_NORMAL_LIST_REQUEST = "INCENTIVE_NORMAL_LIST_REQUEST";
export const INCENTIVE_NORMAL_LIST_SUCCESS = "INCENTIVE_NORMAL_LIST_SUCCESS";
export const INCENTIVE_NORMAL_LIST_ERROR = "INCENTIVE_NORMAL_LIST_ERROR";
export const INCENTIVE_NORMAL_LIST_ADD = "INCENTIVE_NORMAL_LIST_ADD";
export const INCENTIVE_NORMAL_LIST_ADD_SUCCESS = "INCENTIVE_NORMAL_LIST_ADD_SUCCESS";
export const INCENTIVE_NORMAL_LIST_ADD_ERROR = "INCENTIVE_NORMAL_LIST_ADD_ERROR";
export const INCENTIVE_NORMAL_LIST_UPDATE = "INCENTIVE_NORMAL_LIST_UPDATE";
export const INCENTIVE_NORMAL_LIST_UPDATE_SUCCESS = "INCENTIVE_NORMAL_LIST_UPDATE_SUCCESS";
export const INCENTIVE_NORMAL_LIST_UPDATE_ERROR = "INCENTIVE_NORMAL_LIST_UPDATE_ERROR";
export const INCENTIVE_NORMAL_GET_BY_ID = "INCENTIVE_NORMAL_GET_BY_ID";
export const INCENTIVE_NORMAL_GET_BY_ID_SUCCESS = "INCENTIVE_NORMAL_GET_BY_ID_SUCCESS";
export const INCENTIVE_NORMAL_GET_BY_ID_ERROR = "INCENTIVE_NORMAL_GET_BY_ID_ERROR";
export const INCENTIVE_NORMAL_ALL_REQUEST = "INCENTIVE_NORMAL_ALL_REQUEST";
export const INCENTIVE_NORMAL_ALL_SUCCESS = "INCENTIVE_NORMAL_ALL_SUCCESS";
export const INCENTIVE_NORMAL_ALL_ERROR = "INCENTIVE_NORMAL_ALL_ERROR";
export const INCENTIVE_NORMAL_GET_ALL_MTM = "INCENTIVE_NORMAL_GET_ALL_MTM";
export const INCENTIVE_NORMAL_GET_ALL_MTM_SUCCESS = "INCENTIVE_NORMAL_GET_ALL_MTM_SUCCESS";
export const INCENTIVE_NORMAL_GET_ALL_MTM_ERROR = "INCENTIVE_NORMAL_GET_ALL_MTM_ERROR";

export const INCENTIVE_BONUS_LIST_REQUEST = "INCENTIVE_BONUS_LIST_REQUEST";
export const INCENTIVE_BONUS_LIST_SUCCESS = "INCENTIVE_BONUS_LIST_SUCCESS";
export const INCENTIVE_BONUS_LIST_ERROR = "INCENTIVE_BONUS_LIST_ERROR";
export const INCENTIVE_BONUS_UTILITY_REQUEST = "INCENTIVE_BONUS_UTILITY_REQUEST";
export const INCENTIVE_BONUS_UTILITY_SUCCESS = "INCENTIVE_BONUS_UTILITY_SUCCESS";
export const INCENTIVE_BONUS_UTILITY_ERROR = "INCENTIVE_BONUS_UTILITY_ERROR";
export const INCENTIVE_BONUS_ADD = "INCENTIVE_BONUS_ADD";
export const INCENTIVE_BONUS_ADD_SUCCESS = "INCENTIVE_BONUS_ADD_SUCCESS";
export const INCENTIVE_BONUS_ADD_ERROR = "INCENTIVE_BONUS_ADD_ERROR";
export const INCENTIVE_BONUS_UPDATE = "INCENTIVE_BONUS_UPDATE";
export const INCENTIVE_BONUS_UPDATE_SUCCESS = "INCENTIVE_BONUS_UPDATE_SUCCESS";
export const INCENTIVE_BONUS_UPDATE_ERROR = "INCENTIVE_BONUS_UPDATE_ERROR";
export const INCENTIVE_BONUS_GET_BY_ID = "INCENTIVE_BONUS_GET_BY_ID";
export const INCENTIVE_BONUS_GET_BY_ID_SUCCESS = "INCENTIVE_BONUS_GET_BY_ID_SUCCESS";
export const INCENTIVE_BONUS_GET_BY_ID_ERROR = "INCENTIVE_BONUS_GET_BY_ID_ERROR";

export const INCENTIVE_TARGET_LIST_REQUEST = "INCENTIVE_TARGET_LIST_REQUEST";
export const INCENTIVE_TARGET_LIST_SUCCESS = "INCENTIVE_TARGET_LIST_SUCCESS";
export const INCENTIVE_TARGET_LIST_ERROR = "INCENTIVE_TARGET_LIST_ERROR";
export const INCENTIVE_TARGET_ADD = "INCENTIVE_TARGET_ADD";
export const INCENTIVE_TARGET_ADD_SUCCESS = "INCENTIVE_TARGET_ADD_SUCCESS";
export const INCENTIVE_TARGET_ADD_ERROR = "INCENTIVE_TARGET_ADD_ERROR";

export const INCENTIVE_PAYABLE_GENERATE_REQUEST = "INCENTIVE_PAYABLE_GENERATE_REQUEST";
export const INCENTIVE_PAYABLE_GENERATE_SUCCESS = "INCENTIVE_PAYABLE_GENERATE_SUCCESS";
export const INCENTIVE_PAYABLE_GENERATE_ERROR = "INCENTIVE_PAYABLE_GENERATE_ERROR";
export const INCENTIVE_TARGET_UPDATE = "INCENTIVE_TARGET_UPDATE";
export const INCENTIVE_TARGET_UPDATE_SUCCESS = "INCENTIVE_TARGET_UPDATE_SUCCESS";
export const INCENTIVE_TARGET_UPDATE_ERROR = "INCENTIVE_TARGET_UPDATE_ERROR";
export const INCENTIVE_TARGET_GET_BY_ID = "INCENTIVE_TARGET_GET_BY_ID";
export const INCENTIVE_TARGET_GET_BY_ID_SUCCESS = "INCENTIVE_TARGET_GET_BY_ID_SUCCESS";
export const INCENTIVE_TARGET_GET_BY_ID_ERROR = "INCENTIVE_TARGET_GET_BY_ID_ERROR";
export const INCENTIVE_TARGET_UTILITY_REQUEST = "INCENTIVE_TARGET_UTILITY_REQUEST";
export const INCENTIVE_TARGET_UTILITY_SUCCESS = "INCENTIVE_TARGET_UTILITY_SUCCESS";
export const INCENTIVE_TARGET_UTILITY_ERROR = "INCENTIVE_TARGET_UTILITY_ERROR";

export const GET_PAYOUT_BY_YEAR_REQUEST = "GET_PAYOUT_BY_YEAR_REQUEST";
export const GET_PAYOUT_BY_YEAR_ERROR = "GET_PAYOUT_BY_YEAR_ERROR";
export const GET_PAYOUT_BY_YEAR_SUCCESS = "GET_PAYOUT_BY_YEAR_SUCCESS";

export const INCENTIVE_PROMOTER_LIST_REQUEST = "INCENTIVE_PROMOTER_LIST_REQUEST";
export const INCENTIVE_PROMOTER_LIST_SUCCESS = "INCENTIVE_PROMOTER_LIST_SUCCESS";
export const INCENTIVE_PROMOTER_LIST_ERROR = "INCENTIVE_PROMOTER_LIST_ERROR";
export const INCENTIVE_PROMOTER_ADD = "INCENTIVE_PROMOTER_ADD";
export const INCENTIVE_PROMOTER_ADD_SUCCESS = "INCENTIVE_PROMOTER_ADD_SUCCESS";
export const INCENTIVE_PROMOTER_ADD_ERROR = "INCENTIVE_PROMOTER_ADD_ERROR";
export const INCENTIVE_PROMOTER_UPDATE = "INCENTIVE_PROMOTER_UPDATE";
export const INCENTIVE_PROMOTER_UPDATE_SUCCESS = "INCENTIVE_PROMOTER_UPDATE_SUCCESS";
export const INCENTIVE_PROMOTER_UPDATE_ERROR = "INCENTIVE_PROMOTER_UPDATE_ERROR";
export const INCENTIVE_PROMOTER_GET_BY_ID = "INCENTIVE_PROMOTER_GET_BY_ID";
export const INCENTIVE_PROMOTER_GET_BY_ID_SUCCESS = "INCENTIVE_PROMOTER_GET_BY_ID_SUCCESS";
export const INCENTIVE_PROMOTER_GET_BY_ID_ERROR = "INCENTIVE_PROMOTER_GET_BY_ID_ERROR";
export const INCENTIVE_PROMOTER_PRODUCT_FAMILY_REQUEST = "INCENTIVE_PROMOTER_PRODUCT_FAMILY_REQUEST";
export const INCENTIVE_PROMOTER_PRODUCT_FAMILY_SUCCESS = "INCENTIVE_PROMOTER_PRODUCT_FAMILY_SUCCESS";
export const INCENTIVE_PROMOTER_PRODUCT_FAMILY_ERROR = "INCENTIVE_PROMOTER_PRODUCT_FAMILY_ERROR";
export const PROMOTER_INCENTIVE_PAYABLE_GENERATE_REQUEST = "PROMOTER_INCENTIVE_PAYABLE_GENERATE_REQUEST";
export const PROMOTER_INCENTIVE_PAYABLE_GENERATE_SUCCESS = "PROMOTER_INCENTIVE_PAYABLE_GENERATE_SUCCESS";
export const PROMOTER_INCENTIVE_PAYABLE_GENERATE_ERROR = "PROMOTER_INCENTIVE_PAYABLE_GENERATE_ERROR";
export const GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST = "GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST";
export const GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST_COUNTRY = "GET_PROMOTER_PAYOUT_BY_YEAR_REQUEST_COUNTRY";
export const GET_PROMOTER_PAYOUT_BY_YEAR_ERROR = "GET_PROMOTER_PAYOUT_BY_YEAR_ERROR";
export const GET_PROMOTER_PAYOUT_BY_YEAR_SUCCESS = "GET_PROMOTER_PAYOUT_BY_YEAR_SUCCESS";
export const GET_PROMOTER_PAYOUT_BY_YEAR_ERROR_COUNTRY = "GET_PROMOTER_PAYOUT_BY_YEAR_ERROR_COUNTRY";
export const GET_PROMOTER_PAYOUT_BY_YEAR_SUCCESS_COUNTRY = "GET_PROMOTER_PAYOUT_BY_YEAR_SUCCESS_COUNTRY";

export const INCENTIVE_PROMOTER_QUERY_MTM_REQUEST = "INCENTIVE_PROMOTER_QUERY_MTM_REQUEST";
export const INCENTIVE_PROMOTER_QUERY_MTM_SUCCESS = "INCENTIVE_PROMOTER_QUERY_MTM_SUCCESS";
export const INCENTIVE_PROMOTER_QUERY_MTM_ERROR = "INCENTIVE_PROMOTER_QUERY_MTM_ERROR";
export const INCENTIVE_PROMOTER_CHECK_MTM_REQUEST = "INCENTIVE_PROMOTER_CHECK_MTM_REQUEST";
export const INCENTIVE_PROMOTER_CHECK_MTM_SUCCESS = "INCENTIVE_PROMOTER_CHECK_MTM_SUCCESS";
export const INCENTIVE_PROMOTER_CHECK_MTM_ERROR = "INCENTIVE_PROMOTER_CHECK_MTM_ERROR";

export const INCENTIVE_NORMAL_PROMOTER_LIST_REQUEST = "INCENTIVE_NORMAL_PROMOTER_LIST_REQUEST";
export const INCENTIVE_NORMAL_PROMOTER_LIST_SUCCESS = "INCENTIVE_NORMAL_PROMOTER_LIST_SUCCESS";
export const INCENTIVE_NORMAL_PROMOTER_LIST_ERROR = "INCENTIVE_NORMAL_PROMOTER_LIST_ERROR";

export const INCENTIVE_NORMAL_PROMOTER_ADD = "INCENTIVE_NORMAL_PROMOTER_ADD";
export const INCENTIVE_NORMAL_PROMOTER_ADD_SUCCESS = "INCENTIVE_NORMAL_PROMOTER_ADD_SUCCESS";
export const INCENTIVE_NORMAL_PROMOTER_ADD_ERROR = "INCENTIVE_NORMAL_PROMOTER_ADD_ERROR";

export const INCENTIVE_NORMAL_PROMOTER_GET_BY_ID = "INCENTIVE_NORMAL_PROMOTER_GET_BY_ID";
export const INCENTIVE_NORMAL_PROMOTER_GET_BY_ID_SUCCESS = "INCENTIVE_NORMAL_PROMOTER_GET_BY_ID_SUCCESS";
export const INCENTIVE_NORMAL_PROMOTER_GET_BY_ID_ERROR = "INCENTIVE_NORMAL_PROMOTER_GET_BY_ID_ERROR";

export const INCENTIVE_NORMAL_PROMOTER_UPDATE = "INCENTIVE_NORMAL_PROMOTER_UPDATE";
export const INCENTIVE_NORMAL_PROMOTER_UPDATE_SUCCESS = "INCENTIVE_NORMAL_PROMOTER_UPDATE_SUCCESS";
export const INCENTIVE_NORMAL_PROMOTER_UPDATE_ERROR = "INCENTIVE_NORMAL_PROMOTER_UPDATE_ERROR";

export const INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST = "INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST";
export const INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST_SUCCESS = "INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST_SUCCESS";
export const INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST_ERROR = "INCENTIVE_PROMOTER_PRODUCT_GROUP_LIST_REQUEST_ERROR";

export const INCENTIVE_PROMOTER_EDIT_REQUEST = "INCENTIVE_PROMOTER_EDIT_REQUEST";
export const INCENTIVE_PROMOTER_EDIT_REQUEST_SUCCESS = "INCENTIVE_PROMOTER_EDIT_REQUEST_SUCCESS";
export const INCENTIVE_PROMOTER_EDIT_REQUEST_ERROR = "INCENTIVE_PROMOTER_EDIT_REQUEST_ERROR";

export const INCENTIVE_PRODUCT_CATEGORY_BY_PRICE = "INCENTIVE_PRODUCT_CATEGORY_BY_PRICE";
export const INCENTIVE_PRODUCT_CATEGORY_BY_PRICE_SUCCESS = "INCENTIVE_PRODUCT_CATEGORY_BY_PRICE_SUCCESS";
export const INCENTIVE_PRODUCT_CATEGORY_BY_PRICE_ERROR = "INCENTIVE_PRODUCT_CATEGORY_BY_PRICE_ERROR";

export const INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST = "INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST";
export const INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST_SUCCESS = "INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST_SUCCESS";
export const INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST_ERROR = "INCENTIVE_NEW_PROMOTER_CHECK_MTM_REQUEST_ERROR";

export const INCENTIVE_PROMOTER_ADD_REQUEST = "INCENTIVE_PROMOTER_ADD_REQUEST";
export const INCENTIVE_PROMOTER_ADD_REQUEST_SUCCESS = "INCENTIVE_PROMOTER_ADD_REQUEST_SUCCESS";
export const INCENTIVE_PROMOTER_ADD_REQUEST_ERROR = "INCENTIVE_PROMOTER_ADD_REQUEST_ERROR";

export const INCENTIVE_PROMOTER_ADD_NEW = "INCENTIVE_PROMOTER_ADD_NEW";
export const INCENTIVE_PROMOTER_ADD_NEW_SUCCESS = "INCENTIVE_PROMOTER_ADD_NEW_SUCCESS";
export const INCENTIVE_PROMOTER_ADD_NEW_ERROR = "INCENTIVE_PROMOTER_ADD_NEW_ERROR";

export const UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST = "UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST";
export const UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST_SUCCESS = "UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST_SUCCESS";
export const UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST_ERROR = "UPDATE_PROMOTER_INCENTIVE_PAYABLE_TO_PAID_REQUEST_ERROR";
