import Vue from "vue";
import Router from "vue-router";
import $ from "jquery";
import dashboardRouter from "./modules/dashboard";
import dataManagementRouter from "./modules/data-management";
import adminRouter from "./modules/admin";
import staffMngmtRouter from "./modules/staff-management";
// import PromoterLeaderDashboard from "@/views/dashboard/promoter_leader.vue";
import DealerDashboard from "@/views/dashboard/dealer.vue";
import OutletDashboard from "@/views/dashboard/outlet.vue";
import RegionalPage from "@/views/dashboard/regional.vue";
import CountryPage from "@/views/dashboard/country.vue";
import DownloadSalesDataPage from "@/views/download/sales_data.vue";
import dealerOutletRouter from "@/router/modules/dealer_outlets";
import downloadData from  "@/router/modules/download-data";
import operationRouter from  "@/router/modules/operation";
import mediaManagementRouter from  "@/router/modules/media-management";


import store from "../store";
import {
  ROLE_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_GLN_COUNTRY_ADMIN,
  ROLE_COUNTRY_TRAINER,
  ROLE_COUNTRY_MARKETING,
  ROLE_COUNTRY_VIEW,
  ROLE_PROMOTER_LEADER,
  ROLE_CAP,
  ROLE_CAP_ADMIN,
  ROLE_CAP_MARKETING,
  ROLE_DEALER,
  ROLE_CHECKER,
  LAYOUT_ADMIN,
  LAYOUT_AUTH,
  ROLE_OUTLET_PIC,
  ROLE_AGENCY,
  ROLE_COUNTRY_AGENCY,
  ROLE_REGION_MANAGER,
  ROLE_STATE_MANAGER
} from "../constants";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  jQueryInit();
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  jQueryInit();
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/signin");
};

function jQueryInit() {
  $(window).on("load", function() {
    $("#preloader").fadeOut(500);
    $("#main-wrapper").addClass("show");

    $("body").attr("data-sidebar-style") === "mini"
      ? $(".hamburger").addClass("is-active")
      : $(".hamburger").removeClass("is-active");
    if($(".hamburger").hasClass("is-active")){
      $(".brand-logo-wrapper").css("margin-left", "0rem");
    } else {
      $(".brand-logo-wrapper").css("margin-left", `-${$(".hamburger").width()/2}px`);
    }
  });
}

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: store.getters.isCountryTrainer ? "/dealer-outlets/outlets-list" 
              : store.getters.isCountryMedia ? "/media-management/list"
              : store.getters.isCapMedia ? "/media-management/list"
              : store.getters.isMercLeader ? "/dashboard/merchandiser"
              : store.getters.isPromoLeader && "/dashboard/download/sales-data",
      component: store.getters.isPromoLeader
        ? DownloadSalesDataPage
        : store.getters.isDealer
        ? DealerDashboard
        : store.getters.isPicOutlet
        ? OutletDashboard
        : store.getters.isAgency
        ? DownloadSalesDataPage
        : (store.getters.isCapAdmin || store.getters.isCapMarketing)
        ? RegionalPage
        : store.getters.isCap
        ? RegionalPage
        : CountryPage,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/not-allowed",
      component: () => import("../views/tmpls/denied_page.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_COUNTRY_TRAINER,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CHECKER,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      }
    },
    {
      path: "/profile",
      component: () => import("../views/auth/profile.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      }
    },
    {
      path: "/signin",
      component: () => import("../views/auth/signin.vue"),
      meta: {
        layout: LAYOUT_AUTH
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/forgot-password",
      component: () => import("../views/auth/forgot-password.vue"),
      meta: {
        rule: 'isPublic',
        layout: LAYOUT_AUTH
      },
      beforeEnter: ifNotAuthenticated
    },
    adminRouter,
    dashboardRouter,
    dataManagementRouter,
    {
      path: "*",
      redirect: "/",
      hidden: true,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CHECKER,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      },
      beforeEnter: ifAuthenticated
    },
    staffMngmtRouter,
    {
      path: "*",
      redirect: "/",
      hidden: true,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CHECKER,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      },
      beforeEnter: ifAuthenticated
    },
    dealerOutletRouter,
    {
      path: "*",
      redirect: "/",
      hidden: true,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CHECKER,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      },
      beforeEnter: ifAuthenticated
    },
    downloadData,
    {
      path: "*",
      redirect: "/",
      hidden: true,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          ROLE_ADMIN,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MARKETING,
          ROLE_COUNTRY_VIEW,
          ROLE_GLN_COUNTRY_ADMIN,
          ROLE_DEALER,
          ROLE_PROMOTER_LEADER,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MARKETING,
          ROLE_CHECKER,
          ROLE_OUTLET_PIC,
          ROLE_AGENCY,
          ROLE_COUNTRY_AGENCY,
          ROLE_REGION_MANAGER,
          ROLE_STATE_MANAGER
        ]
      },
      beforeEnter: ifAuthenticated
    },
    operationRouter,
    mediaManagementRouter
  ]
});
