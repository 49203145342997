<template>
  <div class="col-lg-12 p-0  mt-4">
    <div class="col-lg-12 pr-0 pl-0 d-inline-flex">
      <h4>Top Dealers</h4>
      <a
        :href="link" 
        target="_blank"
        class="mt-auto mb-auto ml-3 mr-2"
        @click="exportChart($event, 'CAP')"
      >
        <i class="fa fa-download"></i>
      </a>
       <a
        ref="export-link"
        :href="link" 
        target="_blank"
        class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
      ></a>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersMYloading"
        :chart="topDealersMY"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="MY"
        :is-link-loading="isExportLinkLoadingMY"
        chart-name="Malaysia"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersIDloading"
        :chart="topDealersID"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="ID"
        :is-link-loading="isExportLinkLoadingID"
        chart-name="Indonesia"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersPHloading"
        :chart="topDealersPH"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="PH"
        :is-link-loading="isExportLinkLoadingPH"
        chart-name="Philippines"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersTHloading"
        :chart="topDealersTH"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="TH"
        :is-link-loading="isExportLinkLoadingTH"
        chart-name="Thailand"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersSGloading"
        :chart="topDealersSG"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="SG"
        :is-link-loading="isExportLinkLoadingSG"
        chart-name="Singapore"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersTWloading"
        :chart="topDealersTW"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="TW"
        :is-link-loading="isExportLinkLoadingTW"
        chart-name="Taiwan"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersVNloading"
        :chart="topDealersVN"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="VN"
        :is-link-loading="isExportLinkLoadingVN"
        chart-name="Vietnam"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersHKloading"
        :chart="topDealersHK"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="HK"
        :is-link-loading="isExportLinkLoadingHK"
        chart-name="Hong Kong"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersROIloading"
        :chart="topDealersROI"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="ROI"
        :is-link-loading="isExportLinkLoadingROI"
        chart-name="ROI"
      ></top-dealer-per-country>
    </div>
    <div class="col-lg-6 float-left border">
      <top-dealer-per-country
        :is-top-dealer-component-loading="isGetTopDealersKRloading"
        :chart="topDealersKR"
        :enlarge-chart="enlargeChart"
        :export-chart="exportChart"
        link="KR"
        :is-link-loading="isExportLinkLoadingKR"
        chart-name="Korea"
      ></top-dealer-per-country>
    </div>
  </div>
</template>
<script>
import { TOP_DEALERS_GET, EXPORT_TOP_DEALERS_WITH_FILTERS } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import topDealerComponent from "./top-dealer-per-country";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "top-dealer-per-country": topDealerComponent
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data() {
    return{
      topDealersMY: {},
      isGetTopDealersMYloading: true,
      topDealersID: {},
      isGetTopDealersIDloading: true,
      topDealersPH: {},
      isGetTopDealersPHloading: true,
      topDealersTH: {},
      isGetTopDealersTHloading: true,
      topDealersSG: {},
      isGetTopDealersSGloading: true,
      topDealersTW: {},
      isGetTopDealersTWloading: true,
      topDealersVN: {},
      isGetTopDealersVNloading: true,
      topDealersHK: {},
      isGetTopDealersHKloading: true,
      topDealersKR: {},
      isGetTopDealersKRloading: true,
      topDealersROI: {},
      isGetTopDealersROIloading: true,
      isExportLinkLoadingMY: false,
      isExportLinkLoadingID: false,
      isExportLinkLoadingPH: false,
      isExportLinkLoadingTH: false,
      isExportLinkLoadingSG: false,
      isExportLinkLoadingTW: false,
      isExportLinkLoadingVN: false,
      isExportLinkLoadingHK: false,
      isExportLinkLoadingKR: false,
      isExportLinkLoadingROI: false,
      isLinkLoading: false,
      linkMY: "",
      linkID: "",
      linkPH: "",
      linkTH: "",
      linkSG: "",
      linkTW: "",
      linkVN: "",
      linkHK: "",
      linkKR: "",
      linkROI: "",
      link: ""
    }
  },
  methods: {
    async getTopDealersMY(){
      this.isGetTopDealersMYloading = true;
      this.isExportLinkLoadingMY = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "MY"
      }).then(res => {
        if(res){
          res["countryCode"] = "my";
          res["country"] = "Malaysia";
          this.topDealersMY = res;
        }
        this.isGetTopDealersMYloading = false;
      })
    },
    async getTopDealersID(){
      this.isGetTopDealersIDloading = true;
      this.isExportLinkLoadingID = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "ID"
      }).then(res => {
        if(res){
          res["countryCode"] = "id";
          res["country"] = "Indonesia";
          this.topDealersID = res;
        }
        this.isGetTopDealersIDloading = false;
      })
    },
    async getTopDealersPH(){
      this.isGetTopDealersPHloading = true;
      this.isExportLinkLoadingPH = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "PH"
      }).then(res => {
        if(res){
          res["countryCode"] = "ph";
          res["country"] = "Philippines";
          this.topDealersPH = res;
        }
        this.isGetTopDealersPHloading = false;
      })
    },
    async getTopDealersTH(){
      this.isGetTopDealersTHloading = true;
      this.isExportLinkLoadingTH = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "TH"
      }).then(res => {
        if(res){
          res["countryCode"] = "th";
          res["country"] = "Thailand";
          this.topDealersTH = res;
        }
        this.isGetTopDealersTHloading = false;
      })
    },
    async getTopDealersSG(){
      this.isGetTopDealersSGloading = true;
      this.isExportLinkLoadingSG = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "SG"
      }).then(res => {
        if(res){
          res["countryCode"] = "sg";
          res["country"] = "Singapore";
          this.topDealersSG = res;
        }
        this.isGetTopDealersSGloading = false;
      })
    },
    async getTopDealersTW(){
      this.isGetTopDealersTWloading = true;
      this.isExportLinkLoadingTW = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "TW"
      }).then(res => {
        if(res){
          res["countryCode"] = "tw";
          res["country"] = "Taiwan";
          this.topDealersTW = res;
        }
        this.isGetTopDealersTWloading = false;
      })
    },
    async getTopDealersVN(){
      this.isGetTopDealersVNloading = true;
      this.isExportLinkLoadingVN = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "VN"
      }).then(res => {
        if(res){
          res["countryCode"] = "vn";
          res["country"] = "Vietnam";
          this.topDealersVN = res;
        }
        this.isGetTopDealersVNloading = false;
      })
    },
    async getTopDealersHK(){
      this.isGetTopDealersHKloading = true;
      this.isExportLinkLoadingHK = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "HK"
      }).then(res => {
        if(res){
          res["countryCode"] = "hk";
          res["country"] = "Hong Kong";
          this.topDealersHK = res;
        }
        this.isGetTopDealersHKloading = false;
      })
    },
    async getTopDealersKR(){
      this.isGetTopDealersKRloading = true;
      this.isExportLinkLoadingKR = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "KR"
      }).then(res => {
        if(res){
          res["countryCode"] = "kr";
          res["country"] = "Korea";
          this.topDealersKR = res;
        }
        this.isGetTopDealersKRloading = false;
      })
    },
    async getTopDealersROI(){
      this.isGetTopDealersROIloading = true;
      this.isExportLinkLoadingROI = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      await this.$store.dispatch(TOP_DEALERS_GET, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: "ROI"
      }).then(res => {
        if(res){
          res["countryCode"] = "roi";
          res["country"] = "ROI";
          this.topDealersROI = res;
        }
        this.isGetTopDealersROIloading = false;
      })
    },
    exportChart(event, country){
      event.preventDefault();
      this.isLinkLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      this.$store.dispatch(EXPORT_TOP_DEALERS_WITH_FILTERS, {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
        ccf: country
      }).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
          this.isLinkLoading = false;
        } 
      });
    }
  },
  mounted(){}
}
</script>