<template>
    <GChart
            id="chartDiv1"
            ref="chartDiv1"
            type="GeoChart"
            :create-chart="(el, google) => new google.visualization.GeoChart(el || $el)"
            :settings="{ packages: ['geochart'] }"
            :options="geoOptions"
            :data="geoData"
            :events="chartEvents"
    />
</template>

<script>
    import { GChart } from "vue-google-charts";
    export default {
        components: {
            GChart
        },
        props: {
            data: { type: Array, default: () => [] },
            title: { type: String, default: "" },
            region: { type: String, default: "" },
            colorAxisValues: { type: Array, default: () => [] },
            colorAxisColors: { type: Array, default: () => [] }
        },
        computed: {},
        data() {
            return {
                geoData: [...this.data],
                geoOptions: {
                    region: this.region,
                    resolution: "countries",
                    backgroundColor: "#ffffff",
                    datalessRegionColor: "#ffffff",
                    defaultColor: "#ffffff",
                    keepAspectRatio: false,
                    enableRegionInteractivity: true,
                    tooltip: {
                        textStyle: { fontSize: 9 }
                    },
                    legend: 'none',
                    colorAxis: {
                        values: this.colorAxisValues,
                        colors: this.colorAxisColors
                    },
                    height: 45,
                    width:95,
                    displayMode: "auto"
                },
                chartEvents: {
                    ready: () => {
                        /* eslint-disable no-undef */
                        this.addCountryLabels();
                        $("#geoDatContainer").mousemove(async () => {
                            if ($("#chartDiv1 .google-visualization-tooltip").length == 1) {
                                await $("#toolTipContainer").removeClass("d-none");
                                const toolTip = await $("#chartDiv1 .google-visualization-tooltip");
                                let toolTipContent = "";
                                toolTipContent = await toolTip[0].innerHTML;
                                $("#toolTipContainer")[0].innerHTML = await toolTipContent;
                            } else {
                                $("#chartDiv1 .country-label").mouseleave(() => {
                                    $("#toolTipContainer").addClass("d-none");
                                });
                            }
                        });

                        $("#chartDiv1 .country-label").mousemove(async e => {
                            await $("#toolTipContainer").removeClass("d-none");
                            const selected = await e.target.innerHTML;
                            if (selected != "ROI") {
                                let selectedData = [];
                                this.geoData.forEach(function (data) {
                                    if (data[0] == selected) {
                                        selectedData = [...data];
                                    }
                                });
                                let toolTipContent = `
                                    <g><text font-weight="bold">${selected}</text></g>
                                    <g><text font-weight="bold">${this.addCommaSeparator(selectedData[2], false)}</text></g>`;
                                $("#toolTipContainer")[0].innerHTML = await toolTipContent;
                            }
                        });

                        $("#geoDatContainer").mouseleave(() => {
                            $("#toolTipContainer").addClass("d-none");
                        });
                    }
                }
            };
        },
        watch: {
            data: {
                deep: true,
                async handler(newValue) {
                    this.geoData = await newValue;
                    GChart.methods.drawChart();
                }
            },
            region: {
                deep: true,
                async handler(newValue) {
                    newValue
                        ? (this.geoOptions.region = await newValue)
                        : (this.geoOptions.region = "142");
                    await GChart.methods.drawChart();
                }
            },
            colorAxisValues: {
                deep: true,
                async handler(newValue) {
                    this.geoOptions.colorAxis.values = await newValue;
                    await GChart.methods.drawChart();
                }
            },
            colorAxisColors: {
                deep: true,
                async handler(newValue) {
                    this.geoOptions.colorAxis.colors = await newValue;
                    await GChart.methods.drawChart();
                }
            }
        },
        methods: {
            parseSVG(s) {
                var div = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
                div.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg">' + s + "</svg>";
                var frag = document.createDocumentFragment();
                while (div.firstChild.firstChild) frag.appendChild(div.firstChild.firstChild);
                return frag;
            },
            addCountryLabels() {
                const countryLabels = `<g><text text-anchor="middle" x="43" y="25"
                            font-family="Arial" class="country-label" font-size="12"}" stroke="none" stroke-width="0"
                            fill="black" id="KR">KR</text></g>`;
                document .querySelector(
                        "#chartDiv1 > div > div:nth-child(1) > div > svg > g:nth-child(2) > g:nth-child(2)"
                    ).appendChild(this.parseSVG(countryLabels));
            },


        }
    };
</script>
<style lang="scss">
    #chartDiv1 {
        .google-visualization-tooltip {
            visibility: hidden !important;
        }
        text {
            cursor: pointer;
        }
    }

</style>
