<template>
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end">
      <button type="btn" class="input-group-text btn-success btn-reload btn" @click="toggleModelModal">Filter</button>
    </div>
    <div class="col-lg-12 mb-0 mt-3 d-inline-flex">
      <VueBootstrap4Table
        ref="selectTable"
        :classes="classes"
        :rows="mtmList"
        :columns="columns"
        :selected_items="mtmSelected"
        :config="config"
        :total-rows="total"
        :show-loader="true"
        @on-change-query="onChangeQuery"
        @refresh-data="submitItems"
        @on-select-row="mtmInput"
        @on-all-select-rows="mtmInput"
        @on-unselect-row="mtmInput"
        @on-all-unselect-rows="mtmInput"
      >
        <template slot="sort-asc-icon">
          <i class="mdi mdi-sort-ascending"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="mdi mdi-sort-descending"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="mdi mdi-sort-variant"></i>
        </template>
        <template slot="empty-results">
          <div
            class="bold-text"
          >Please Filter MTM</div>
        </template>
      </VueBootstrap4Table>
    </div>
    <div class="col-lg-12 d-flex justify-content-end mt-4">
      <button
        class="btn btn-secondary pull-right mr-3"
        @click="submitItems"
        v-text="$ml.get('button_submit_text')"
      ></button>
    </div>
    <BModal
      ref="mtm-modal"
      size="lg"
      hide-footer
      title="MTM"
    >
      <div class="col-lg-12">
        <label class="text-label" v-text="$ml.get('incentive_add_form_product_category')"></label>
        <Multiselect
          ref="productCategory"
          v-model="productCategory"
          :searchable="false"
          :options="productCategoryList"
          label="productCategory"
          track-by="productCategory"
          :multiple="true"
          :loading="!productCategoryList"
          :allow-empty="true"
          :show-labels="false"
          @input="getProductFamilyList('add')"
          @remove="getProductFamilyList('remove', $event)"
        ></Multiselect>
      </div>
      <div class="col-lg-12 mt-3">
        <label class="text-label"> Model (Product Family)</label>
        <Multiselect
          ref="productFamily"
          v-model="filteredProductFamily"
          :searchable="false"
          :options="productFamilyList"
          label="model"
          track-by="model"
          :multiple="true"
          :loading="!productFamilyList"
          :allow-empty="true"
          :show-labels="false"
        ></Multiselect>
      </div>
      <div v-if="error" class="col-lg-12">
        <span class="invalid-field">Please select at least one model</span>
      </div>
      <div class="col-lg-12 d-flex mt-3" style="flex-direction: column">
        <label class="text-label">Min price</label>
        <input v-model="minPrice" class="form-control" />
      </div>
      <div class="col-lg-12 d-flex mt-3" style="flex-direction: column">
        <label class="text-label">Max price</label>
        <input v-model="maxPrice" class="form-control" />
      </div>
      <div class="col-lg-12 d-flex justify-content-end mt-4">
        <button
          class="btn btn-secondary pull-right mr-3"
          @click="applyFilter"
          v-text="$ml.get('button_apply_text')"
        ></button>
      </div>
    </BModal>
  </div>
</template>
<script>
import VueBootstrap4Table from "../ui/VueBootstrap4Table";
import { BModal } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { INCENTIVE_PRODUCT_CATEGORY_BY_PRICE } from "../../store/modules/incentive/action";

export default {
  name: "SelectMTMModal",
  components: {
    VueBootstrap4Table,
    BModal,
    Multiselect,
  },
  props: {
    countryId: {type: Number, default: 0},
    productGroupList: {type: Array, default: () => []},
    submit: {type: Function, default: () => {}},
    objectiveList: {type: Array, default: () => []},
    selectedRow: {type: Number, default: 0}
  },
  computed: {
    filteredProductFamily: {
      get: function () {
        return this.productFamily.filter(el => el.value !== 0)
      },
      set: function (value) {
        if(value.find(el => el.value === 0)){
          this.productFamily = [];
          for (let item of this.productFamilyList) {
            if (item.value !== 0) {
              this.productFamily.push(item);
              this.$emit('handle-select', item);
            }
          } 
        } else {
          this.productFamily = value;
        }
      }
    }
  },
  data(){
    return{
      config: {
        checkbox_rows: true,
        rows_selectable: true,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "id",
          label: "Id",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          visibility: false
        },
        {
          name: "mtm",
          label: "MTM",
          uniqueId: true,
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "model",
          label: "Model",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "productGroup",
          label: "Group",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "productCategory",
          label: "Category",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        },
        {
          name: "srp",
          label: "SRP",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
        }
      ],
      total: 0,
      mtmList: [],
      productCategory: [],
      productCategoryList: [],
      productFamily: [],
      productFamilyList: [],
      mtmSelected: [],
      categoryArray: [],
      minPrice: null,
      maxPrice: null,
      error: false,
    }
  },
  methods: {
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.submitItems();
    },
    async mtmInput(mtm) {
      const localMTM = await mtm.selected_items;
      this.mtmSelected = [];
      // Filtering out deactivated dealers
      this.mtmSelected = [
        ...localMTM
      ];
    },
    toggleModelModal(){
      this.$refs["mtm-modal"].toggle();
    },
    getProductGroupList(){
      this.productCategoryList = this.productGroupList.filter(el => el.value !== 0).map(element => element.productCategoryList).flat();
    },
    getProductFamilyList(action, event){
      if(action === 'add'){
        this.productFamilyList = [{model: "Select all", value: 0}];
        this.productFamilyList = this.productFamilyList.concat(this.productCategory.map(element => element.productModelList).flat());
      } else {
        this.productFamily = this.productFamily.filter( function( el ) {
          return !event.productModelList.includes( el );
        });
      }
    },
    applyFilter(){
      if(this.productFamily.length === 0 && this.productCategory.length > 0){
        this.error = true;
      } else {
        this.error = false;
        let pdata = {};
        pdata.countryId = this.countryId;
        pdata.modelList = this.productFamily.map(element => element.model);
        pdata.productCategory = this.productCategory.map(element => element.productCategory);
        pdata.minPrice = this.minPrice ? Number(this.minPrice) : null;
        pdata.maxPrice = this.maxPrice ? Number(this.maxPrice) : null;
        let existingMtm = this.objectiveList.map((el, index) => {
          if(index + 1 !== this.selectedRow){
            return el.mtm
          } 
        }).flat();
        this.$store.dispatch(INCENTIVE_PRODUCT_CATEGORY_BY_PRICE, pdata).then(res => {
          if(res.content && res.resultCode === 0){
            this.mtmList = res.content.filter(el => !existingMtm.includes(el.mtm));
          }
        });
        this.$refs["mtm-modal"].toggle();
      }
    },
    submitItems(){
      this.submit(this.mtmSelected);
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
  },
  mounted(){
    if(this.objectiveList.find((el, index) => index + 1 === this.selectedRow).mtm.length > 0){
      // this.isDataLoading = true;
      let pdata = {};
      let selectedObject = this.objectiveList.find((el, index) => index + 1 === this.selectedRow);
      pdata.countryId = this.countryId;
      pdata.mtmList = selectedObject.mtm
      this.$store.dispatch(INCENTIVE_PRODUCT_CATEGORY_BY_PRICE, pdata).then(res => {
        if(res.content && res.resultCode === 0){
          this.categoryArray = res.content.map(el => el.productCategory).filter(this.onlyUnique);
          this.productCategory = this.productCategoryList.filter(
            element => this.categoryArray.includes(element.productCategory));
          this.getProductFamilyList('add');
          this.mtmSelected = res.content;
          this.filteredProductFamily = this.productFamilyList.filter(element => res.content.map(el => el.model).filter(this.onlyUnique).includes(element.model));
        }
      }).then(() => {
        let sdata = {};
        sdata.countryId = this.countryId;
        sdata.modelList = this.productFamily.map(element => element.model);
        sdata.productCategory = this.productCategory.map(element => element.productCategory);
        sdata.minPrice = this.minPrice ? Number(this.minPrice) : null;
        sdata.maxPrice = this.maxPrice ? Number(this.maxPrice) : null;
        this.$store.dispatch(INCENTIVE_PRODUCT_CATEGORY_BY_PRICE, sdata).then(res => {
          if(res.content && res.resultCode === 0){
            this.mtmList = res.content;
          }
        });
      });
      // this.mtmList = this.objectiveList.find((el, index) => index + 1 === this.selectedRow).mtm;
    }
    this.getProductGroupList();
    this.getProductFamilyList('add');
  }
}
</script>