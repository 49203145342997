<template>
  <div v-if="!isPromoterPerformanceLoading">
    <div class="mt-4 ml-2 mb-4 mb-4 d-inline-flex justify-content-between w-100">
      <span class="mt-auto mb-auto">
        <strong>{{ promoterPerformance.name }}</strong>
      </span>
      <div class="d-inline-flex">
        <a
          :href="link" 
          target="_blank"
          class="mt-2 pt-1 mr-4"
          @click="exportChart($event)"
        >
          <i class="fa fa-download"></i>
        </a>
        <a
          ref="export-link"
          :href="link" 
          target="_blank"
          class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute d-none"
        ></a>
        <div class="mr-2 enlarge-icon" @click="enlargeChart(promoterPerformance, 'stackedBarChart')">
          <span> &#10070; </span>
        </div>
      </div>
    </div>
    
    <!-- <bar-chart
      id="actualpromoterSOPerformanceLowerSection"
      :data.sync="dashboardData.promoterSOPerformance.data"
      :title="''"
      color="#b07aa1"
      :cap.sync="isCap"
      :percent="true"
      :max-height="true"
      lower-section-id="promoterSOPerformanceLowerSection"
      chart-left="160"
      :max-characters="20"
    ></bar-chart> -->

    <stacked-bar-chart
      id="actualpromoterPerformanceLowerSection"
      :got-lower-section="true"
      :data.sync="promoterPerformance.data"
      lower-section-id="promoterPerformanceLowerSection"
      title=""
      chart-left="160"
      :max-characters="18"
    ></stacked-bar-chart>
    <div id="promoterPerformanceLowerSection"></div>
    <div class="mt-4">
      <div class="b-tabs">
        <nav class="tabs is-small is-centered">
          <ul></ul>
        </nav>
        <section class="tab-content">
          <div class="tab-item" style="">
            <div class="tags pb-3">
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(78, 121, 167);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Actual</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(242, 142, 43);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">To Go</span>
              <span class="tag mr-2 is-white is-medium legendIcon" style="background: rgb(154, 205, 50);"></span>
              <span style="font-family: 'Arial';font-size:13px;font-color:black;padding-right:10px;">Over Achievement</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else style="height: 500px"><div class="loading loading-big"></div></div>
</template>
<script>
import { PROMOTER_PERFORMANCE_GET, EXPORT_PROMOTER_PERFORMANCE } from "../../store/modules/charts/actions";
import { defaultQuarter } from '../../utils/helpers';
import stackedbarChart from "../dashboard/stacked-bar-chart";
import { generalMixin } from "@/utils/generalMixin";

export default {
  components: {
    "stacked-bar-chart": stackedbarChart,
  },
  mixins: [generalMixin],
  props: {
    filters: {type: Object, default: () => {}},
    localFilters: {type: Object, default: () => {}},
    productFilters: {type: Object, default: () => {}},
    enlargeChart: {type: Function, default: () => {}},
    grouping: {type: String, default: ""},
  },
  data() {
    return{
      promoterPerformance: {},
      isPromoterPerformanceLoading: true,
      link: ""
    }
  },
  methods: {
    exportChart(event){
      event.preventDefault();
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters
      }
      if(this.localFilters.countrySelected.value === 'PH' || this.localFilters.countrySelected === 'PH'){
        pdata.promoterType = this.localFilters.promoterTypeSelected.value;
      }
      this.$store.dispatch(EXPORT_PROMOTER_PERFORMANCE, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.$refs["export-link"].href = res.content.link;
          this.$refs["export-link"].click();
        } 
      });
    },
    async getPromoterPerformance() {  
      this.isPromoterPerformanceLoading = true;
      if (!this.filters.quarterSelected) {
        this.filters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
      }
      let pdata = {
        grouping: this.grouping,
        filters: this.localFilters,
        productFilters: this.productFilters,
      };
      if(this.localFilters.countrySelected.value === 'PH' || this.localFilters.countrySelected === 'PH'){
        pdata.promoterType = this.localFilters.promoterTypeSelected.value;
      }
      await this.$store.dispatch(PROMOTER_PERFORMANCE_GET, pdata).then((res) => {
        if(res){
          this.promoterPerformance = res;
        }
        this.isPromoterPerformanceLoading = false;
      })
    },
  },
  // mounted(){
  //   this.getPromoterPerformance();
  // }
}
</script>