<template>
  <div id="incentive-normal-promoter">
    <div class="overlay" style="z-index: 9999" @click="closeOverlay">
      <div class="h-100">
        <div class="loading loading-white"></div>
      </div>
    </div>
    <breadcrumb title="Dashboard" subtitle="Promoter Commission Schemes" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="row">
                <div
                  class="col-lg-12 add-employee-button d-flex align-items-center mb-4 flex-row-reverse"
                >
                  <button
                    v-if="isCapAdmin || isCap"
                    class="btn btn-primary d-flex align-items-center"
                    :disabled="incentiveListIsLoading"
                    @click="toggleModalAdd()"
                  >
                    <i class="fa fa-upload mr-2"></i>
                    Add
                    <SpringSpinner
                      v-if="incentiveListIsLoading"
                      class="ml-3"
                      :size="17"
                      color="#fff"
                    />
                  </button>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-3">
                  <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                  <Multiselect
                    ref="country"
                    v-model="filters.countrySelected"
                    :searchable="false"
                    :disabled="!isCap && !isCapAdmin"
                    :options="countryOptions"
                    label="name"
                    track-by="value"
                    :loading="!countryOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-4">
                  <label class="text-label">Fiscal Year</label>
                  <Multiselect
                    ref="year"
                    v-model="filters.yearSelected"
                    :searchable="false"
                    :options="yearOptions"
                    label="name"
                    track-by="value"
                    :loading="!yearOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-4">
                  <label class="text-label">Quarter</label>
                  <Multiselect
                    ref="quarter"
                    v-model="filters.quarterSelected"
                    :searchable="false"
                    :options="quarterOptions"
                    label="name"
                    track-by="value"
                    :loading="!quarterOptions"
                    :allow-empty="false"
                    :show-labels="false"
                  ></Multiselect>
                </div>
                <div class="col-lg-1">
                  <button class="btn btn-sm btn-primary" style="margin-top:24px;height:40px;width: 100%" @click="getIncentiveLists">
                    <span class="position-absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">Search</span>
                  </button>
                </div>
              </div>
              <!-- <div v-if="isCapAdmin || isCap" class="row align-items-center">
                <div class="col-lg-12 mb-3">
                  <div class="form-group flex-grow-1 w-100">
                    <Multiselect
                      ref="multiselect"
                      v-model="search.countryId"
                      placeholder="Select Country"
                      :searchable="true"
                      :options="countries"
                      label="text"
                      track-by="id"
                      :open-direction="'bottom'"
                      :loading="isCountriesLoading"
                      select-label
                      deselect-label
                      @input="onChangeCountry"
                    ></Multiselect>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-lg-12">
                  <VueBootstrap4Table
                    :classes="classes"
                    :rows="incentiveLists"
                    :columns="columns"
                    :config="config"
                    :total-rows="total"
                    :show-loader="true"
                    @on-change-query="onChangeQuery"
                    @refresh-data="getIncentiveLists"
                  >
                    <template slot="sort-asc-icon">
                      <i class="mdi mdi-sort-ascending"></i>
                    </template>
                    <template slot="sort-desc-icon">
                      <i class="mdi mdi-sort-descending"></i>
                    </template>
                    <template slot="no-sort-icon">
                      <i class="mdi mdi-sort-variant"></i>
                    </template>
                    <template slot="empty-results">
                      <div
                        v-if="!incentiveListIsLoading"
                        class="bold-text"
                      ></div>
                        No Focus Product Found!
                      <div
                        v-if="incentiveListIsLoading"
                        class="loading mt-3"
                      ></div>
                    </template>
                    <template slot="objective1List" slot-scope="props">
                      <div class="inner-data">
                        <p v-for="(tier, index) in props.row.objective1List" :key="index">
                          <b> Tier {{ tier.tierLevel + 1 }}: </b> 
                          {{ tier.tierAchievementMin }} {{ tier.tierAchievementMax ? " - " + tier.tierAchievementMax : null }} 
                          ({{ tier.tierReward }}%)
                        </p>
                      </div>
                    </template>
                    <template slot="objective2List" slot-scope="props">
                      <div class="inner-data">
                        <p v-for="(tier, index) in props.row.objective2List" :key="index">
                          <b> {{ tier.name }} </b> ({{ tier.tierReward }}%)
                        </p>
                      </div>
                    </template>
                    <template slot="productCategory" slot-scope="props">
                      <div class="inner-data">
                        <span style="color:#F70201; cursor: pointer" @click="toggleShowModal(props.row.productCategory, props.row.countryId)">{{ props.row.productCategory !== null ? props.row.productCategory.length : 0 }}</span>
                      </div>
                    </template>

                    <template slot="edit" slot-scope="props">
                      <button
                        class="btn btn-warning btn-xs"
                        @click="toggleModalEdit(props.row)"
                      >
                        <i class="fa fa-edit"></i>
                        Edit
                      </button>
                    </template>
                  </VueBootstrap4Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BModal
      ref="incentive-normal-promoter-modal-upload"
      size="lg"
      hide-footer
      :title="selectedIncentiveProgram.id ? 'Edit Incentive List' : 'Add Incentive List'"
    >
      <incentive_add_form 
        :selected-incentive-program="selectedIncentiveProgram"
        :close-modal="toggleModalEdit"
        :year-options="yearOptions"
        :quarter-options="quarterOptions"
        :country-options="countryOptions"
      />
    </BModal>
    <BModal
      ref="incentive-normal-promoter-modal-show"
      size="lg"
      hide-footer
      title="Show"
    >
      <incentive_show_modal 
        :product-show="productShow"
      />
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { BModal } from "bootstrap-vue";
import { SpringSpinner } from "epic-spinners";
import Multiselect from "vue-multiselect";
import {
  // INCENTIVE_NORMAL_GET_BY_ID,
  INCENTIVE_NORMAL_PROMOTER_LIST_REQUEST
} from "../../store/modules/incentive/action";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import incentive_add_form from "../../components/incentive/incentive_add_form.vue"
import incentive_show_modal from "../../components/incentive/incentive_show_modal.vue"

// import { Message } from "element-ui";
import $ from "jquery";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';

export default {
  name: "PromoterNormalIncentive",
  components: {
    VueBootstrap4Table: VueBootstrap4Table,
    BModal,
    incentive_add_form,
    incentive_show_modal,
    Multiselect,
    breadcrumb,
    SpringSpinner
  },
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "countryCode",
          label: "CTRY",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "productCategory",
          label: "Objective 1 Product Category",
          width: "10%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "objective1List",
          label: "Objective 1 tiers",
          width: "30%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "objective2List",
          label: "Objective 2 Focus Product",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        }
      ],
      total: 0,
      allMtm: [],
      selectedIncentiveProgram: {},
      incentiveLists: [],
      incentiveListSelected: "",
      incentiveList: [],
      allIncentiveList: [],
      search: {},
      countryId: null,
      isCapAdmin: false,
      isCap: false,
      tHeader: ["Product Family", "Mtm", "Sales Persons", "Promoters"],
      filterVal: ["mtmName", "mtmNo", "salesPerson", "promoter"],
      incentiveListIsLoading: true,
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      filters: {
        countrySelected: 
          this.$store.getters.isCap || this.$store.getters.isCapAdmin 
          ? this.$store.getters.topFilterCountryData 
          : {name: this.$store.getters.getCountryCode, value: this.$store.getters.getCountryCode},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      },
      productShow: {},
    };
  },
  watch: {
    "selectedIncentiveProgram.objective1List": function(){
      this.getIncentiveLists();
    },
  },
  methods: {
    toggleModalAdd(){
      this.selectedIncentiveProgram = {
        "objective1List": [
          {
            "tierLevel": 0,
            "tierAchievementMin": null,
            "tierAchievementMax": null,
            "tierReward": null
          },
        ],
        "objective2List": [
          {
            "productFamily": [],
            "tierReward": null,
            "mtm": [],
            "minPrice": null,
            "maxPrice": null,
            "attainmentRate": null,
            "name": "",
            "type": null
          },
        ],
        "productGroup": [],
        "productCategory": [],
        "ccf": null,
        "fiscalYear": null,
        "quarter": null,
        "countryId": null
      }
      this.$refs["incentive-normal-promoter-modal-upload"].toggle();
    },
    closeOverlay(){
      $('.overlay').css('display', 'none');
    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    // openEditPage(incentive, type) {
    //   this.$router.push({
    //     name: "Edit Promoter Normal Incentive",
    //     params: { type: type, id: incentive.id }
    //   });
    // },
    // openAddIncentiveModal() {
    //   this.$router.push({
    //     name: "Add Promoter Incentive"
    //   });
    // },
    getIncentiveLists() {
      this.incentiveListIsLoading = true;
      let pdata = {};
      pdata.ccf = this.filters.countrySelected.value;
      pdata.fiscalYear = this.filters.yearSelected.value;
      pdata.quarter = this.filters.quarterSelected.value;
      this.$store.dispatch(INCENTIVE_NORMAL_PROMOTER_LIST_REQUEST, pdata).then(res => {
        if(res.content && res.resultCode === 0){
          this.incentiveLists = res.content;
        }
      });
      this.incentiveListIsLoading = false;
    },
    // getIncentive() {
    //   if (!this.incentiveListSelected) {
    //     Message({
    //       message: "No incentive selected.",
    //       type: "error",
    //       duration: 5 * 1000
    //     });
    //   } else {
    //     this.$store
    //       .dispatch(INCENTIVE_NORMAL_GET_BY_ID, this.incentiveListSelected.id)
    //       .then(res => {
    //         this.incentiveList = res.content;
    //         this.total = this.incentiveList.length;
    //       });
    //   }
    // },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getIncentiveLists();
    },
    toggleModalEdit(incentiveProgram) {
      if(incentiveProgram){
        this.selectedIncentiveProgram = incentiveProgram;
      }
      this.$refs["incentive-normal-promoter-modal-upload"].toggle();
    },
    toggleShowModal(productCategoryList, id) {
      this.productShow = {};
      this.productShow.productCategoryList = productCategoryList;
      this.productShow.id = id;
      this.$refs["incentive-normal-promoter-modal-show"].toggle();
    }
  },
  mounted() {
    this.countryId = this.$store.getters.getCountryId;
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    if(this.isCapAdmin || this.isCap){
      this.columns.push(
        {
          name: "edit",
          label: "Action",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        }
      )
    }
    this.getFilters();
    this.getIncentiveLists();
  }
};
</script>

<style lang="scss">
  .highlight-light-red{
    background-color: #ff000026;
  }
  #model-show-table .table-responsive table{
    tbody tr:not(:last-of-type){
      border-bottom: 3px solid #cdcdcd;
    }
  }
</style>
