<template>
  <div id="download-sales-data">
    <breadcrumb
      title="Dashboard"
      subtitle="Download"
      sub-sub-title="Sales Data"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <b-tabs v-model="activeTab">
              <b-tab-item key="0" label="Sales Data">
                <div class="card">
                  <div class="card-body">
                    <div class="contact-list-wrapper">
                      <div class="row mb-5 inner-data custom-control">
                        <input
                          v-model="type"
                          name="byYearAndQuarter"
                          type="radio"
                          value="byYearAndQuarter"
                        />
                        <label class="text-label sales-data mr-4" @click="changeType('byYearAndQuarter')">By Year and Quarter</label>
                        <input
                          v-model="type"
                          type="radio"
                          name="byDate"
                          value="byDate"
                        />
                        <label class="text-label sales-data" @click="changeType('byDate')">By Date</label>
                      </div>
                      <div class="row mb-3">
                        <div class="col-lg-3 d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                          <Multiselect
                            ref="country"
                            v-model="filters.countrySelected"
                            :searchable="false"
                            :options="countryOptions"
                            label="name"
                            track-by="value"
                            :disabled="!isCapAdmin && !isCap"
                            :loading="isCountriesLoading"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div v-if="type !== 'byDate'" :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-3'" class="d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Fiscal Year</label>
                          <Multiselect
                            ref="year"
                            v-model="filters.yearSelected"
                            :searchable="false"
                            :options="yearOptions"
                            :disabled="type === 'byDate'"
                            label="name"
                            track-by="value"
                            :loading="!yearOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div v-if="type !== 'byDate'" :class="isCapAdmin || isCap ? 'col-lg-3' : 'col-lg-3'" class="d-flex justify-content-end" style="flex-direction: column">
                          <label class="text-label">Quarter</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.quarterSelected"
                            :searchable="false"
                            :options="quarterOptions"
                            label="name"
                            track-by="value"
                            :loading="!quarterOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div v-if="type === 'byDate'" class="col-lg-4 d-flex justify-content-end" style="flex-direction: column">
                          <div class="form-group flex-grow-1 mb-0 d-flex justify-content-end" style="flex-direction: column; margin-bottom: 0!important">
                            <label
                              class="text-label"
                              v-text="$ml.get('att_sales_data_title_period')"
                            ></label>
                            <DateRangePicker
                              ref="picker"
                              v-model="dateRange"
                              :date-range="dateRange"
                              :opens="datePicker.opens"
                              :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                              :min-date="datePicker.minDate"
                              :max-date="datePicker.maxDate"
                              :single-date-picker="datePicker.singleDatePicker"
                              :time-picker="datePicker.timePicker"
                              :time-picker24hour="datePicker.timePicker24Hour"
                              :show-week-numbers="datePicker.showWeekNumbers"
                              :show-dropdowns="datePicker.showDropdowns"
                              :auto-apply="datePicker.autoApply"
                              :linked-calendars="datePicker.linkedCalendars"
                              :date-format="datePicker.dateFormat"
                              :ranges="datePicker.ranges"
                            >
                              <div
                                slot="input"
                                slot-scope="picker"
                                style="min-width: 310px; width: 100%; float: left;"
                              >
                                <span v-if="picker.startDate && picker.endDate">{{ formatDate(picker.startDate) }} to
                                  {{ formatDate(picker.endDate) }}</span>
                              </div>
                            </DateRangePicker>
                          </div>
                        </div>
                        <!-- <div class="col-lg-1 pl-0 d-flex justify-content-end" style="flex-direction: column">
                          <button class="btn btn-sm btn-primary mb-0" style="margin-top:24px;height:40px;width: 100%" @click="getSalesData">
                            <span>Search</span>
                          </button>
                        </div> -->
                        <div class="col-lg-3 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                          <div class="d-inline-flex">
                            <button class="btn btn-sm btn-primary mb-0" style="margin-top:24px;height:40px;width: 100%" @click="toggleModal">
                              <span>Send Email</span>
                            </button>
                            <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                              <span>Download</span>
                              <div 
                                v-if="isDataLoading" 
                                class="loading ml-3" 
                                style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                              />
                            </button>
                          </div>
                        </div>
                        
                      </div>
        
                      <!-- <div class="row">
                        <div class="col-lg-12 salesTheaderNoWrap" style="width: 1px;">
                          <VueBootstrap4Table
                            :classes="classes"
                            :rows="dataList"
                            :columns="columns"
                            :config="config"
                            :total-rows="total"
                            @on-change-query="changeQuery"
                          >
                            <template slot="status" slot-scope="props">
                              <span v-text="props.row.status" />
                            </template>
        
                            <template slot="submissionDate" slot-scope="props">
                              <span v-if="props.row.submissionDate">{{
                                changeDateFormat(
                                  props.row.submissionDate,
                                  "YYYY-MM-DD"
                                )
                              }}</span>
                            </template>
        
                            <template slot="invoiceDate" slot-scope="props">
                              <span v-if="props.row.invoiceDate">{{
                                changeDateFormat(
                                  props.row.invoiceDate,
                                  "YYYY-MM-DD"
                                )
                              }}</span>
                            </template>
        
                            <template slot="country" slot-scope="props">
                              <div class="inner-data">
                                <span>
                                  {{ 
                                    props.row.countryCode
                                  }}
                                </span>
                              </div>
                            </template>
                            <template slot="sort-asc-icon">
                              <i class="mdi mdi-sort-ascending"></i>
                            </template>
                            <template slot="sort-desc-icon">
                              <i class="mdi mdi-sort-descending"></i>
                            </template>
                            <template slot="no-sort-icon">
                              <i class="mdi mdi-sort-variant"></i>
                            </template>
                            <template slot="empty-results">
                              <div
                                v-if="!isDataLoading"
                                class="bold-text"
                                v-text="$ml.get('no_data_found')"
                              ></div>
                              <div
                                v-if="isDataLoading"
                                class="loading mt-3"
                              ></div>
                            </template>
        
                          </VueBootstrap4Table>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item key="1" label="Retail BMS">
                <div class="card">
                  <div class="card-body">
                    <div class="contact-list-wrapper">
                      <div class="row mb-3">
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                          <Multiselect
                            ref="country"
                            v-model="filters.countrySelected"
                            :searchable="false"
                            :options="countryOptions"
                            label="name"
                            track-by="value"
                            :disabled="!isCapAdmin && !isCap"
                            :loading="isCountriesLoading"
                            :allow-empty="false"
                            :show-labels="false"
                          ></Multiselect>
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Fiscal Year</label>
                          <Multiselect
                            ref="year"
                            v-model="filters.yearSelected"
                            :searchable="false"
                            :options="yearOptions"
                            label="name"
                            track-by="value"
                            :loading="!yearOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Quarter</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.quarterSelected"
                            :searchable="false"
                            :options="quarterOptions"
                            label="name"
                            track-by="value"
                            :loading="!quarterOptions"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Week From</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekFrom"
                            :searchable="false"
                            :options="allWeekFrom"
                            :loading="!allWeekFrom"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="d-flex justify-content-end col-lg-2" style="flex-direction: column">
                          <label class="text-label">Week To</label>
                          <Multiselect
                            ref="quarter"
                            v-model="filters.weekTo"
                            :searchable="false"
                            :options="allWeekTo"
                            :loading="!allWeekTo"
                            :allow-empty="false"
                            :show-labels="false"
                          />
                        </div>
                        <div class="col-lg-2 pl-0 d-inline-flex justify-content-end" style="flex-direction: column">
                          <div class="d-inline-flex">
                            <button class="btn btn-sm btn-primary mb-0" :disabled="isDataLoading" style="margin-top:24px;height:40px;width: 100%" @click="doExport">
                              <span>Download</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>


      </div>
    </div>
    <BModal ref="send-email-modal" size="lg" hide-footer title="Send Email">
        <div class="row">
          <div class="col-lg-12 d-inline-flex justify-content-between mt-5 mb-3">
            <div class="col-lg-8">
              <input 
                v-model="email"
                v-validate="'required'"
                type="text"
                name="Email"
                class="form-control"
              >
            </div>
            <div class="col-lg-4">
              <button class="btn btn-sm btn-primary mt-0 w-100" style="height:40px" :disabled="sendingEmail" @click="doSendEmail">
                <span>Send</span>
              </button>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="col-lg-12">
              <p class="invalid-field">{{ error }}</p>
            </div>
          </div>
        </div>
    </BModal>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { convertDateFormat, defaultFiscalYear, defaultQuarter } from "../../utils/helpers";
import DateRangePicker from "../../components/ui/vue2-daterange-picker";
import "../../components/ui/vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import {
//   PROMOTER_ATTENDANCE_DATE_REQUEST
// } from "../../store/modules/download/actions";
import { EXPORT_SALES_DATA_REQUEST, SALES_DATA_SEND_EMAIL_REQUEST, DOWNLOAD_RETAIL_BMS_REQUEST } from "../../store/modules/sales/actions";
import { BModal } from "bootstrap-vue";
import { 
  NOTIFICATION_REQUEST, 
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR 
} from "../../store/modules/ui/actions";

import Multiselect from "vue-multiselect";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";

const today = new Date();

export default {
  name: "SalesDataDPage",
  components: {
    breadcrumb: breadcrumb,
    DateRangePicker: DateRangePicker,
    Multiselect: Multiselect,
    BModal: BModal,
    // VueBootstrap4Table: VueBootstrap4Table,
  },
  computed: {
    allWeekTo() {
      const from = this.filters.weekFrom
      if (from) {
        return this.allWeek.filter(el => el >= from)
      } else {
        return this.allWeek
      }
    },
    allWeekFrom() {
      const to = this.filters.weekTo
      if (to) {
        return this.allWeek.filter(el => el <= to)
      } else {
        return this.allWeek
      }
    }
  },
  data() {
    return {
      activeTab: 0,
      email: this.$store.getters.user.name,
      error: "",
      type: "byYearAndQuarter",
      sendingEmail: false,
      dataList: [],
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      dateOption:[],
      filters: {
        countrySelected: null,
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        weekFrom: 1,
        weekTo: 13,
      },
      dateRange: {
        startDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth(), 1),
          "YYYY-MM-DD"
        ),
        endDate: convertDateFormat(
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
          "YYYY-MM-DD"
        )
      },
      datePicker: {
        opens: "center",
        // minDate: "2019-08-01",
        // maxDate: "2019-12-31",
        singleDatePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        timePicker: false,
        autoApply: false,
        ranges: false
      },
      allWeek: [1,2,3,4,5,6,7,8,9,10,11,12,13],
      isCapAdmin: false,
      isCap: false,
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      countries: [],
      countryCode: this.$store.getters.getCountryCode,
      isCountriesLoading: false,
      isDataLoading:false,
      link: ""
    }
  },
  watch: {
    "queryParams.page": function() {
      return this.getSalesData();
    },
    "queryParams.per_page": function() {
      return this.getSalesData();
    }
  },
  methods: {
    changeType(type){
      this.type = type;
    },
    emailValidate(value){
      let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return re.test(value);
    },
    async doSendEmail(){
      if(this.email === ""){
        return this.error = "Email can't be blank"
      } 
      if(!this.emailValidate(this.email)){
        return this.error = "Invalid email"
      }
      
      this.sendingEmail = true;
      let startDate = convertDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      let endDate = convertDateFormat(this.dateRange.endDate, "YYYY-MM-DD");
      let countryCode = this.filters.countrySelected.value || this.filters.countrySelected;

      let pdata = {
        ccf: countryCode,
        email: this.email
      };

      if(this.type === 'byDate'){
        pdata.sdf = startDate;
        pdata.edf = endDate;
      } else {
        pdata.fyf = this.filters.yearSelected.value;
        pdata.qf = this.filters.quarterSelected.value;
      }

      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: this.$ml.get("request_submitting")
      });
      this.$store.dispatch(SALES_DATA_SEND_EMAIL_REQUEST, pdata)
        .then(res => {
          if(res.resultCode === 0){
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Please check your email"
            });
            this.toggleModal();
          }
          this.sendingEmail = false;
        })
        .catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
          this.sendingEmail = false;
        });
      
    },
    toggleModal(){
      this.sendingEmail = false;
      this.email = this.$store.getters.user.name;
      this.error = "";
      this.$refs["send-email-modal"].toggle("#toggle-btn");
    },
    changeDateFormat(date, format) {
      return convertDateFormat(date, format);
    },

    doExport() {
      this.isDataLoading = true;
      this.$store.dispatch(NOTIFICATION_REQUEST, {
        message: "Downloading..."
      });
      let startDate = convertDateFormat(this.dateRange.startDate, "YYYY-MM-DD");
      let endDate = convertDateFormat(this.dateRange.endDate, "YYYY-MM-DD");
      let countryCode = this.filters.countrySelected.value || this.filters.countrySelected;
      
      let pData = {
        ccf: countryCode,
      };

      let action = EXPORT_SALES_DATA_REQUEST

      if (this.activeTab == 1) {
        action = DOWNLOAD_RETAIL_BMS_REQUEST
        pData.weekFrom = this.filters.weekFrom
        pData.weekTo = this.filters.weekTo
        pData.fyf = this.filters.yearSelected.value;
        pData.qf = this.filters.quarterSelected.value;

      } else {
        if(this.type === 'byDate'){
          pData.sdf = startDate;
          pData.edf = endDate;
        } else {
          pData.fyf = this.filters.yearSelected.value;
          pData.qf = this.filters.quarterSelected.value;
        }
      }
      
      this.$store.dispatch(action, pData).then(res => {
        if(res.resultCode === 0 && res.content){
          window.open(res.content.link);
        }
        this.isDataLoading = false;
      }).catch(err => {
        this.isDataLoading = false;
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: err
        });
      })
    },

    formatDate: function(date) {
      return convertDateFormat(String(date), "YYYY-MM-DD");
    },
    getCountries() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          let country = res.content.find(el => el.code === this.$store.getters.getCountryCode).text;
          this.filters.countrySelected = this.isCapAdmin || this.isCap 
          ? this.$store.getters.topFilterCountryData : {name: country, value: this.$store.getters.getCountryCode};
        }
      });
    },

    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    
    // async getSalesDataDate() {
    //   let pdata = {
    //     fyf: this.filters.yearSelected.value,
    //     qf: this.filters.quarterSelected.value,
    //   };
    //   // this.isDataLoading = true;
    //   this.dateOption = [];

    //   await this.$store.dispatch(PROMOTER_ATTENDANCE_DATE_REQUEST, pdata).then(res => {
    //     if (res.content && res.resultCode === 0) {
    //       this.dateOption = res.content;
    //       this.dateRange.startDate = convertDateFormat(this.dateOption.startDate);
    //       this.dateRange.endDate = convertDateFormat(this.dateOption.endDate);
    //       // this.isDataLoading = false;
    //     } else {
    //       // this.isDataLoading = false;
    //     }
    //   });
    // },
  },
  async mounted() {
    this.getCountries();
    this.getFilters();
    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    // await this.getSalesDataDate();
  },
};
</script>
<style lang="scss">
.salesTheaderNoWrap {
  .table > thead > tr > th {
    white-space: nowrap !important;
  }
}

.text-label.sales-data::after, .text-label.sales-data::before{
  margin: 1px .4rem .4rem .4rem;
}
</style>