<template>
  <div id="donut-chart">
    <VueApexCharts
      ref="donutChart" 
      :options="options" 
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
    colors: { type: Array, default: () => [] },
    labelFormatter: { type: Function, default: (val) => { return val }},
    height: { type: Number, default: 400 },
    hideTooltip: { type: Boolean, default: false },
    hideLegends: { type: Boolean, default: false },
  },
  computed: {
    options() {
      return {
        chart: {
          type: 'donut',
          height: this.height + "px"
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            dataLabels: {
              minAngleToShowLabel: 0,
            },
          },
        },
        colors: this.colors,
        tooltip: {
          enabled: !this.hideTooltip
        },
        labels: this.labels,
        dataLabels: {
          enabled: true,
          formatter: this.labelFormatter,
          style: {
            colors: ["black"]
          },
          background: {
            enabled: true
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center', 
          show: !this.hideLegends
        }
      }
    },
  },
  watch: {
    series: {
      handler(value) { this.$refs.donutChart.updateSeries(value) },
      deep: true
    }    
  },
};
</script>