import store from "@/store";
import OpsCalendar from "@/views/other/OpsCalendar.vue";
import DealerInventory from "@/views/dealer/dealer_inventory_new.vue";
import PassThrough from "@/views/PassThrough";
import DealerSellThru from "@/views/dealer/dealer_sell_thru.vue";
import VisitationSchedule from "@/views/data-management/visitation_schedule.vue"

import {
  ROLE_ADMIN,
  ROLE_COUNTRY_ADMIN,
  ROLE_COUNTRY_VIEW,
  ROLE_GLN_COUNTRY_ADMIN,
  ROLE_PROMOTER_LEADER,
  ROLE_CAP_ADMIN,
  ROLE_CAP,
  ROLE_DEALER,
  LAYOUT_ADMIN,
  ROLE_OUTLET_PIC,
  ROLE_AGENCY,
  ROLE_COUNTRY_AGENCY,
  ROLE_REGION_MANAGER,
  ROLE_STATE_MANAGER,
  ROLE_MERC_LEADER
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const dataManagementRouter = {
  path: "/data-management",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
      ROLE_CAP_ADMIN,
      ROLE_CAP,
      ROLE_COUNTRY_ADMIN,
      ROLE_COUNTRY_VIEW,
      ROLE_GLN_COUNTRY_ADMIN,
      ROLE_PROMOTER_LEADER,
      ROLE_DEALER,
      ROLE_OUTLET_PIC,
      ROLE_AGENCY,
      ROLE_COUNTRY_AGENCY,
      ROLE_REGION_MANAGER,
      ROLE_STATE_MANAGER
    ]
  },
  children: [
    {
      path: "ops-calendar",
      name: "Lenovo Sales Ops Calendar",
      component: OpsCalendar,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP_ADMIN,
          ROLE_CAP,
        ]
      }
    },
    {
      path: "dealer-inventory",
      name: "Dealer Sell Thru Inventory",
      component: DealerInventory,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "dealer-sell-thru",
      name: "Dealer Sell Thru",
      component: DealerSellThru,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP_ADMIN, ROLE_CAP]
      }
    },
    {
      path: "visitation-schedule",
      name: "Visitation Schedule",
      component: VisitationSchedule,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [ROLE_ADMIN, ROLE_CAP, ROLE_CAP_ADMIN, ROLE_MERC_LEADER,ROLE_COUNTRY_ADMIN, ROLE_COUNTRY_VIEW]
      }
    },
  ],
  beforeEnter: ifAuthenticated
};

export default dataManagementRouter;
