import {
  getIncentiveNormalListSalesperson,
  addIncentiveNormalListSalesperson,
  getIncentiveDetailsById,
  updateIncentiveNormalListSalesperson
} from "@/api/new-incentive.js";

import {
  INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST,
  INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS,
  INCENTIVE_NORMAL_SALESPERSON_LIST_ERROR,
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST,
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS,
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR,
  GET_INCENTIVE_DETAILS_BY_ID_REQUEST,
  GET_INCENTIVE_DETAILS_BY_ID_SUCCESS,
  GET_INCENTIVE_DETAILS_BY_ID_ERROR,
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST,
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS,
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR
} from "./action";

const state = {
  status: "",
  hasLoadedOnce: false
};

const mutations = {
  INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      incentiveNormalListData: data,
      hasLoadedOnce: true
    });
  },
  INCENTIVE_NORMAL_SALESPERSON_LIST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      incentiveNormalListData: [],
      hasLoadedOnce: true
    });
  },
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      incentiveNormalListData: data,
      hasLoadedOnce: true
    });
  },
  ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR: state => {
    Object.assign(state, {
      status: "error",
      incentiveNormalListData: [],
      hasLoadedOnce: true
    });
  },
  GET_INCENTIVE_DETAILS_BY_ID_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_INCENTIVE_DETAILS_BY_ID_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      incentiveNormalListData: data,
      hasLoadedOnce: true
    });
  },
  GET_INCENTIVE_DETAILS_BY_ID_ERROR: state => {
    Object.assign(state, {
      status: "error",
      incentiveNormalListData: [],
      hasLoadedOnce: true
    });
  },
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      incentiveNormalListData: data,
      hasLoadedOnce: true
    });
  },
  UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR: state => {
    Object.assign(state, {
      status: "error",
      incentiveNormalListData: [],
      hasLoadedOnce: true
    });
  },
}

const actions = {
  [INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS);
      getIncentiveNormalListSalesperson()
        .then(resp => {
          commit(INCENTIVE_NORMAL_SALESPERSON_LIST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(INCENTIVE_NORMAL_SALESPERSON_LIST_ERROR, err);
          reject(err);
        });
    });
  },
  [ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS);
      addIncentiveNormalListSalesperson(postData)
        .then(resp => {
          commit(ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(ADD_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_INCENTIVE_DETAILS_BY_ID_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_INCENTIVE_DETAILS_BY_ID_SUCCESS);
      getIncentiveDetailsById(postData)
        .then(resp => {
          commit(GET_INCENTIVE_DETAILS_BY_ID_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_INCENTIVE_DETAILS_BY_ID_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS);
      updateIncentiveNormalListSalesperson(postData)
        .then(resp => {
          commit(UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_NEW_SALESPERSON_INCENTIVE_PROGRAM_ERROR, err);
          reject(err);
        });
    });
  },
}

export default {
  state,
  actions,
  mutations
};