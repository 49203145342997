<template>
  <div id="outlet-list">
    <breadcrumb
      title="Dashboard"
      subtitle="Dealer/Outlet Management"
      :sub-sub-title="activeTab === 1 ? 'Outlet Details' : 'Dealer Details'"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="contact-list-wrapper">
              <div class="col-lg-12 d-inline-flex pl-0 pr-0 justify-content-between mb-4">
                <div class="b-tabs col-lg-10 mb-0 pl-0 pr-0">
                  <nav class="tabs mb-0">
                    <ul class="mb-0">
                      <b-nav-item :class="activeTab === 0 && 'is-active'" @click="activeTab = 0">
                        Dealer
                      </b-nav-item>
                      <b-nav-item :class="activeTab === 1 && 'is-active'" @click="activeTab = 1">
                        Outlet
                      </b-nav-item>
                    </ul>
                  </nav>
                </div>
                <div class="col-lg-2 pl-0 pr-0 d-flex justify-content-end position-relative" style="border-bottom: 1px solid #dbdbdb;">
                  <a
                    v-if="canExport"
                    :href="link" 
                    target="_blank"
                    :class="{disabled: isDataExportLoading || link === ''}"
                    style="line-height: 3rem; top: -5px"
                    class="btn btn-sm btn-primary mb-2 pb-2 mr-0 position-absolute"
                  >
                    <i class="fa fa-file-excel-o"></i>
                    <span v-text="$ml.get('export_to_excel')"></span>
                    <div 
                      v-if="isDataExportLoading" 
                      class="loading ml-3" 
                      style="width: 15px; height: 15px; border-top-color: #f9f9f9; top: 2px; left: auto" 
                    >
                    </div>
                  </a>
                </div>
              </div>
                <div class="row">
                  <div
                    class="col-lg-12 add-employee-button d-flex align-items-center justify-content-between"
                  >
                    <div class="d-inline-flex">
                      <div class="mr-5 text-left">
                        <b-field class="mr-auto" grouped group-multiline>
                          <div
                            class="control clickable"
                            @click="filterList('normal')"
                          >
                            <b-taglist attached>
                              <b-tag type="is-dark" size="is-large">Registered</b-tag>
                              <b-tag type="is-info" size="is-large">
                                <strong>{{ activeTab === 0 ? dealerList.length : outletList.length }}</strong>
                              </b-tag>
                            </b-taglist>
                          </div>
                          <div
                            class="control clickable"
                            @click="filterList('active')"
                          >
                            <b-taglist attached>
                              <b-tag type="is-dark" size="is-large">Active</b-tag>
                              <b-tag type="is-success" size="is-large">
                                <strong>{{ activeTab === 0 ? dealerListActive.length : outletListActive.length }}</strong>
                              </b-tag>
                            </b-taglist>
                          </div>
                          <div
                            class="control clickable"
                            @click="filterList('deactivated')"
                          >
                            <b-taglist attached>
                              <b-tag type="is-dark" size="is-large">
                                Deactivated
                              </b-tag>
                              <b-tag type="is-danger" size="is-large">
                                <strong>{{ activeTab === 0 ? dealerListDeactivate.length : outletListDeactivate.length }}</strong>
                              </b-tag>
                            </b-taglist>
                          </div>

                        </b-field>
                      </div>
                    </div>
                    <div class="d-inline-flex row-btn-wrapper">
                      <button
                        v-if="canAdd && activeTab === 0"
                        class="btn btn-sm btn-primary mb-0"
                        @click="toggleModalDealer()"
                      >
                        <i class="fa fa-plus"></i>
                        <span v-text="$ml.get('add_dealer_title')"></span>
                      </button>
                      <button
                        v-if="canAdd && activeTab === 1"
                        class="btn btn-sm btn-primary mb-0"
                        @click="toggleModal(null)"
                      >
                        <i class="fa fa-plus"></i>
                        <span v-text="$ml.get('add_outlet_title')"></span>
                      </button>
                    </div>
                  </div>
                </div>
                  <br>
                <div class="row">
                  <div class="col-lg-4">
                    <label class="text-label" v-text="$ml.get('sd_title_country')"></label>
                    <Multiselect
                      ref="country"
                      v-model="filters.countrySelected"
                      :searchable="false"
                      :disabled="!isCapAdmin && !isCap"
                      :options="countryOptions"
                      label="name"
                      track-by="value"
                      :loading="!countryOptions"
                      :allow-empty="false"
                      :show-labels="false"
                      @input="(e) => onChangeCountry(e)"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-4">
                    <label class="text-label">Fiscal Year</label>
                    <Multiselect
                        ref="year"
                        v-model="filters.yearSelected"
                        :searchable="false"
                        :options="yearOptions"
                        label="name"
                        track-by="value"
                        :loading="!yearOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-3">
                    <label class="text-label">Quarter</label>
                    <Multiselect
                        ref="quarter"
                        v-model="filters.quarterSelected"
                        :searchable="false"
                        :options="quarterOptions"
                        label="name"
                        track-by="value"
                        :loading="!quarterOptions"
                        :allow-empty="false"
                        :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="col-lg-1">
                    <button class="btn btn-sm btn-primary" style="margin-top:24px;height:40px;width: 100%" :disabled="activeTab === 0 ? isDataDealerLoading : isDataOutletLoading" @click="getOutletList(); if(canExport)getExportLink()">
                      <span class="position-absolute" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">Search</span>
                    </button>
                  </div>

                </div>
                <br />
              <b-tabs v-model="activeTab" class="nav-d-none">
                <b-tab-item>
                  <div v-if="activeTab === 0">
                    <DealerComponent 
                      :total="total"
                      :filter-state="filterState"
                      :is-data-loading="isDataDealerLoading"
                      @toggle-modal="toggleModalDealer"
                      @get-outlet-list="getOutletList"
                    />
                  </div>
                </b-tab-item>
                <b-tab-item>
                  <div v-if="activeTab === 1">
                    <OutletComponent 
                      :total="total"
                      :filter-state="filterState"
                      :is-data-loading="isDataOutletLoading"
                      @toggle-modal="toggleModal"
                      @toggle-key-store="keyStoreModal"
                      @on-change-query="onChangeQuery"
                      @get-outlet-list="getOutletList"
                    />
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BModal ref="outlet-modal" size="lg" hide-footer :title="outletFormTitle">
      <outletForm
        :close-modal="toggleModal"
        :close-modal-dealer="toggleModalDealer"
        :selected-outlet="outlet"
        :selected-dealer="dealer"
        :load-data="getOutletList"
        :can-edit="canEdit"
        :can-view="canView"
        :current-action="currentAction"
        :filters="filters"
        :can-change-status="canChangeStatus"
      />
    </BModal>

    <BModal ref="dealer-modal" size="lg" hide-footer :title="setFormTitle">
      <dealerForm
        :close-modal="toggleModalDealer"
        :can-edit="canEdit"
        :can-view="canView"
        :selected-dealer="dealer"
        :load-data="getOutletList"
        :current-action="currentAction"
      />
    </BModal>

    <BModal ref="outlet-qr-modal" size="md" hide-footer title="QR CODE">
      <qr-code class="qr-code-lg" :text="selectedDealerCode"></qr-code>
    </BModal>
    
    <KeyStoreModal 
      v-model="isKeyStore" 
      :selected-outlet="outlet"
    />
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import OutletComponent from "../../components/dealer-outlets/outlet_component";
import DealerComponent from "../../components/dealer-outlets/dealer_component";
import Message from "element-ui";
import outletForm from "../../components/dealer-outlets/outlet_form";
import dealerForm from "../../components/dealer/dealer_form";
import { BModal } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import {
  OUTLETS_LIST_WITH_DEALERS_REQUEST,
  OUTLET_PIC_LIST_REQUEST,
  EXPORT_DEALER_OUTLET_DATA_REQUEST
} from "../../store/modules/outlet/actions";
import { DEALER_LIST_WITH_FILTERS_REQUEST } from "../../store/modules/dealer/actions";
import { COUNTRY_LIST_REQUEST } from "../../store/modules/utility/actions";
import { DEALER_LIST_REQUEST } from "../../store/modules/dealer/actions";
import {
  STATE_LIST_REQUEST,
  CATEGORY_LIST_REQUEST
} from "../../store/modules/utility/actions";
import "viewerjs/dist/viewer.css";
import {DASHBOARD_TOP_FILTERS_GET} from "@/store/modules/dashboard/actions";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';
import KeyStoreModal from "../../components/dealer-outlets/key_store_modal";

export default {
  name: "OutletsPage",
  components: {
    breadcrumb: breadcrumb,
    OutletComponent: OutletComponent,
    DealerComponent: DealerComponent,
    outletForm: outletForm,
    dealerForm: dealerForm,
    BModal: BModal,
    Multiselect: Multiselect,
    KeyStoreModal
  },
  computed: {
    filterState() {
      if (this.normal) {
        return this.activeTab === 0 ? this.dealerList : this.outletList;
      } else if (this.activeList) {
        return this.activeTab === 0 ? this.dealerListActive : this.outletListActive;
      } else if (this.deactivatedList) {
        return this.activeTab === 0 ? this.dealerListDeactivate : this.outletListDeactivate;
      } else if (this.noPicList) {
        return this.activeTab === 0 ? this.dealerListNoPic : this.outletListNoPic;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      isDataExportLoading: false,
      link: "",
      outletFormTitle: "Add Outlet",
      total: 0,
      activeTab: 1,
      postOutletData: [],
      dealers: [],
      outletList: [],
      outletListActive: [],
      outletListDeactivate: [],
      outletListNoPic: [],
      dealerList: [],
      dealerListActive: [],
      dealerListDeactivate: [],
      dealerListNoPic: [],
      setFormTitle: "Add Dealer",
      dealer: {},
      outlet: {},
      states: [],
      countryId: null,
      categories: [],
      a: [],
      isPicOutlet: false,
      selectedDealerCode: "",
      canAdd: false,
      canEdit: false,
      canUpload: false,
      canView: false,
      canExport: false,
      isPromoLeader: false,
      canChangeStatus: false,
      isDealer: false,
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      isDataOutletLoading: false,
      isCountryTrainer: false,
      isCountryView: false,
      isDataDealerLoading: false,
      isCapAdmin: false,
      isCap: false,
      isAgency: false,
      currentAction: false,
      normal: true,
      activeList: false,
      deactivatedList: false,
      noPicList: false,
      countryOptions: [],
      yearOptions: [],
      quarterOptions: [],
      filters: {
        countrySelected: this.$store.getters.isCapAdmin || this.$store.getters.isCap ? this.$store.getters.topFilterCountryData : 
        {name: this.$store.getters.getCountryCode, value: this.$store.getters.getCountryCode},
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
      },
      isKeyStore: false
    }
  },
  // watch: {
  //   activeTab(){
  //     return this.getOutletList()
  //   }
  // },
  methods: {
    showModal() {
      this.$refs["outlet-modal"].show();
    },
    hideModal() {
      this.$refs["outlet-modal"].hide();
    },
    toggleQrModal(row) {
      this.selectedDealerCode = row.dealerCode;
      this.$refs["outlet-qr-modal"].toggle("#toggle-btn");
    },
    toggleModalDealer(row , view) {
      if (row) {
        this.dealer = row;
        if (view === 1) {
          if(this.isCapAdmin || this.isCap || this.isCountryAdmin || this.isGLNCountryAdmin || this.isCountryView){
            this.setFormTitle = "Update Dealer";
            this.currentAction = "edit";
          } else {
            this.setFormTitle = "View Dealer";
            this.currentAction = "view";
          }
        }
      } else {
        this.dealer = {};
        this.setFormTitle = "Add Dealer";
        this.currentAction = "edit";
      }
      this.$refs["dealer-modal"].toggle("#toggle-btn");
    },
    toggleModal(row , view) {
      if (row) {
        this.outlet = row;
        if (view === 1) {
          this.dealer = this.isValidDealer(row.dealerName);
          this.outletFormTitle = "View Dealer/Outlets";
          this.currentAction = "view";
        } else if(view === 2){
          this.outletFormTitle = "Promoters";
          this.currentAction = "promoters";
        } else if(view === 3){
          this.outletFormTitle = "Salespersons";
          this.currentAction = "salespersons";
        } else if(view === 4){
          if(this.isCap || this.isCapAdmin || this.isCountryAdmin || this.isGLNCountryAdmin || this.isCountryView){
            this.outletFormTitle = "Update Outlet";
            this.currentAction = "edit";
          } else {
            this.outletFormTitle = "View Outlet";
            this.currentAction = "view";
          }
        }
      } else {
        this.outlet = {};
        this.outletFormTitle = "Add Outlet";
        this.currentAction = "edit";
      }
      this.$refs["outlet-modal"].toggle("#toggle-btn");
    },
    keyStoreModal(row) {
      this.outlet = row;
      this.isKeyStore = !this.isKeyStore
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getOutletList();
    },
    onChangeCountry(value) {
      if (value) {
        this.countryId = this.getSelectedCountry(value).id
        // this.countryId = value.id;
      }
    },
    filterList(list) {
      if (list == "normal") {
        this.normal = true;
        this.activeList = false;
        this.deactivatedList = false;
        this.noPicList = false;
      } else if (list == "active") {
        this.normal = false;
        this.activeList = true;
        this.deactivatedList = false;
        this.noPicList = false;
      } else if (list == "deactivated") {
        this.normal = false;
        this.activeList = false;
        this.deactivatedList = true;
        this.noPicList = false;
      } else if (list == "noPic") {
        this.normal = false;
        this.activeList = false;
        this.deactivatedList = false;
        this.noPicList = true;
      }
    },
    getOutletList() {
      let pdata = {
        ccf: this.filters.countrySelected.value,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value
      };
      this.outletList = [];
      this.outletListActive = [];
      this.outletListDeactivate = [];
      this.dealerListActive = [];
      this.dealerListDeactivate = [];
      this.dealerList = [];
      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }
      if (this.countryId) {
        pdata.countryId = this.countryId;
      }
      if(this.activeTab === 0){
        this.isDataDealerLoading = true;
        this.$store.dispatch(DEALER_LIST_WITH_FILTERS_REQUEST, pdata).then(res => {
          if (res.content && res.resultCode === 0) {
            this.dealerList = res.content;
            this.total = this.dealerList.length;
            this.getStats();
            this.isDataDealerLoading = false;
          } 
        });
      } else {
        this.isDataOutletLoading = true;
        this.$store.dispatch(OUTLETS_LIST_WITH_DEALERS_REQUEST, pdata).then(res => {
          if (res.content && res.resultCode === 0) {
            this.outletList = res.content;
            this.total = this.outletList.length;
            this.getStats();
            this.isDataOutletLoading = false;
            // this.$store.dispatch(DEALER_LIST_WITH_FILTERS_REQUEST, pdata).then(res => {
            //   if (res.content && res.resultCode === 0) {
            //     this.dealerList = res.content;
            //     // this.outletList = outletList;
            //     this.getStats();
            //     this.isDataLoading = false;
            //   } 
            // });
            // this.getStats();
          } else {
            this.isDataOutletLoading = false;
          }
        });
      }      
    },
    doDelete(row) {
      window.alert("deleting item ID" + JSON.stringify(row));
    },
    doEdit(row) {
      this.outletFormTitle = "Update Outlet";
      this.outlet = row;
      this.toggleModal();
    },
    doDeactivate() {},
    doActivate() {},
    isValidDealer(name) {
      let dealer = this.dealerList.filter(function(elem) {
        if (elem.dealerName.toLowerCase() === name.toLowerCase()) return elem;
      });
      if (dealer[0]) {
        return dealer[0];
      } else {
        return false;
      }
    },
    isValidCategory(name) {
      let cat = this.categories.filter(function(elem) {
        if (elem.text.toLowerCase() === name.toLowerCase()) return elem;
      });
      if (cat[0]) {
        return cat[0];
      } else {
        return false;
      }
    },
    isValidState(name) {
      let state = this.states.filter(function(elem) {
        if (elem.text.toLowerCase() === name.toLowerCase()) return elem;
      });
      if (state[0]) {
        return state[0];
      } else {
        return false;
      }
    },
    isValidPic(name) {
      if (!name) {
        return true;
      }
      let pic = this.picList.filter(function(elem) {
        if (elem.picName.toLowerCase() === name.toLowerCase()) return elem;
      });
      if (pic[0]) {
        return pic[0];
      } else {
        return false;
      }
    },
    getDealers() {
      this.$store.dispatch(DEALER_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.dealers = res.content;
        }
      });
    },
    getCountries() {
      this.countries = [];
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countries.push(country);
          });
        }
      });
    },
    getSelectedCountry(value) {
      let c = this.countries.filter(function(elem) {
        if (elem.code === value.value) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return {};
      }
    },
    getStates() {
      const countryId = this.$store.getters.getCountryId;
      this.$store
        .dispatch(STATE_LIST_REQUEST, { countryId: countryId })
        .then(res => {
          if (res.resultCode === 0) {
            this.states = res.content;
          }
        });
    },
    getCategories() {
      this.$store.dispatch(CATEGORY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.categories = res.content;
        }
      });
    },
    getPicList() {
      this.$store.dispatch(OUTLET_PIC_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.picList = res.content;
        }
      });
    },
    getStats() {
      this.outletListActive = [];
      this.outletListDeactivate = [];
      this.outletListNoPic = [];
      this.dealerListActive = [];
      this.dealerListDeactivate = [];
      this.dealerListNoPic = [];

      this.outletList.filter(list => {
        if (list.status == "ACTIVE") {
          this.outletListActive.push(list);
        }
        else if (list.status == "DEACTIVATED") {
          this.outletListDeactivate.push(list);
        }
        else if (list.picName && list.picEmail) {
          this.outletListNoPic.push(list);
        }
      });

      this.dealerList.filter(list => {
        if (list.status == "ACTIVE") {
          this.dealerListActive.push(list);
        }
        else if (list.status == "DEACTIVATED") {
          this.dealerListDeactivate.push(list);
        }
        else if (list.picName && list.picEmail) {
          this.dealerListNoPic.push(list);
        }
      });

    },
    async getFilters() {
      const res = await this.$store.dispatch(DASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.countryOptions = await res.content.countryDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
      }
    },
    getExportLink(){
      this.isDataExportLoading = true;

      let pdata = {
        ccf: this.filters.countrySelected.value,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value
      };
      this.$store.dispatch(EXPORT_DEALER_OUTLET_DATA_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.isDataExportLoading = false;
          this.link = res.content.link;
        }
      });
    }
  },
  mounted() {
    this.getCountries();

    this.isCapAdmin = this.$store.getters.isCapAdmin;
    this.isCap = this.$store.getters.isCap;
    this.isPromoLeader = this.$store.getters.isPromoLeader;
    this.isDealer = this.$store.getters.isDealer;
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isPicOutlet = this.$store.getters.isPicOutlet;
    this.isAgency = this.$store.getters.isAgency;
    this.isAdmin = this.$store.getters.isAdmin;
    this.isCountryTrainer = this.$store.getters.isCountryTrainer;
    this.isCountryView = this.$store.getters.isCountryView;
    
    if (this.isCap) {
      this.canAdd = true;
      this.canUpload = true;
      this.canDownload = true;
      this.canEdit = true;
      this.canDelete = false;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = true;
      // this.columns.push({
      //   name: "Action",
      //   label: "Action",
      //   row_text_alignment: "text-left",
      //   column_text_alignment: "text-left"
      // });
    } else if (this.isCapAdmin) {
      this.canAdd = true;
      this.canUpload = false;
      this.canDownload = true;
      this.canEdit = true;
      this.canDelete = false;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = true;
    } else if (this.isPromoLeader) {
      this.canAdd = false;
      this.canEdit = false;
      this.canUpload = false;
      this.canView = true;
      this.canExport = false;
      this.canChangeStatus = false;
    } else if (this.isDealer) {
      this.canAdd = false;
      this.canEdit = true;
      this.canUpload = false;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = false;
    } else if (this.isPicOutlet) {
      this.canAdd = false;
      this.canEdit = true;
      this.canUpload = false;
      this.canView = false;
      this.canExport = true;
      this.canChangeStatus = false;
    } else if (this.isCountryAdmin || this.isGLNCountryAdmin) {
      this.canAdd = true;
      this.canEdit = true;
      this.canUpload = true;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = false;
      // this.columns.push({
      //   name: "Action",
      //   label: "Action",
      //   width: "10%",
      //   row_text_alignment: "text-left",
      //   column_text_alignment: "text-left"
      // });
    } else if (this.isCountryView) {
      this.canAdd = false;
      this.canEdit = true;
      this.canUpload = true;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = false;
    } else {
      this.canAdd = false;
      this.canEdit = false;
      this.canUpload = true;
      this.canView = true;
      this.canExport = true;
      this.canChangeStatus = false;
      // this.columns.push({
      //   name: "Action",
      //   label: "Action",
      //   width: "10%",
      //   row_text_alignment: "text-left",
      //   column_text_alignment: "text-left"
      // });
    }

    // if (!this.isDealer && !this.isPicOutlet) {
    //   this.columns.splice(2, 0, {
    //     name: "dealerName",
    //     label: "Dealer Info",
    //     sort: true,
    //     width: "15%",
    //     row_text_alignment: "text-left",
    //     column_text_alignment: "text-left"
    //   });
    // }

    // if (!this.isPromoLeader && !this.isPicOutlet) {
    //   this.columns.splice(4, 0, {
    //     name: "picName",
    //     label: "PIC",
    //     sort: true,
    //     width: "15%",
    //     row_text_alignment: "text-left",
    //     column_text_alignment: "text-left"
    //   });
    //
    //   this.columns.splice(5, 0, {
    //     name: "outletCode",
    //     label: "SCAN",
    //     sort: true,
    //     width: "10%",
    //     row_text_alignment: "text-left",
    //     column_text_alignment: "text-left"
    //   });
    // }
    if(this.$route.params.lppNumber !== undefined) {
      this.activeTab = 0;
    }

    if (this.isPicOutlet) {
      this.columns.splice(2, 0, {
        name: "outletCode",
        label: "SCAN",
        sort: true,
        width: "10%",
        row_text_alignment: "text-left",
        column_text_alignment: "text-left"
      });
    }
    this.getFilters();
    if (!this.isCapAdmin && !this.isCap && !this.isCountryTrainer && !this.isPromoLeader) {
      this.getExportLink();
    }
    /* eslint-disable no-undef */
    $("#main-wrapper").toggleClass("menu-toggle");
    $(".hamburger").toggleClass("is-active");
  }
}
</script>
<style lang="scss">

.nav-d-none {
  nav.tabs{
    display: none!important;
  }
  .tab-content{
    padding-right: 0;
    padding-left: 0;
  }
}
</style>