import {
  actualSObySubRegionGet,
  soHeatMap,
  promoterSOperformance,
  soPerformanceVStarget,
  productPerformanceByCategory,
  productPerformanceByComponent,
  promoterDealerPerformance,
  promoterOutletPerformance,
  promoterPerformance,
  topDealers,
  exportProductPerformanceByCategory,
  exportProductPerformanceByComponent,
  exportTopDealersWithFilters,
  exportPromoterDealerPerformance,
  exportPromoterOutletPerformance,
  exportPromoterPerformance,
  exportPromoterSOperformance,
  exportSoPerformanceVStarget,
  soPerformanceRequest,
  salespersonPerformanceRequest,
  productPerformanceRequest,
  outletPerformanceRequest,
  getMerchandiserVisitation,
  getMerchandiserScoreGrade,
  getMerchandiserStoreVSCategory,
  getMerchandiserVisitationSubRegion,
  getMerchandiserScoreGradeSubRegion,
  getMerchandiserScoreGradeByTopDealer,
  getMerchandiserLenovoVSCompetitor,
  exportActualSObySubRegion
} from "@/api/charts";
import {
  ACTUAL_SO_BY_SUB_REGION_GET,
  ACTUAL_SO_BY_SUB_REGION_GET_SUCCESS,
  ACTUAL_SO_BY_SUB_REGION_GET_ERROR,
  EXPORT_ACTUAL_SO_BY_SUB_REGION,
  EXPORT_ACTUAL_SO_BY_SUB_REGION_SUCCESS,
  EXPORT_ACTUAL_SO_BY_SUB_REGION_ERROR,
  SO_HEAT_MAP_GET,
  SO_HEAT_MAP_GET_SUCCESS,
  SO_HEAT_MAP_GET_ERROR,
  PROMOTER_SO_PERFORMANCE_GET,
  PROMOTER_SO_PERFORMANCE_GET_SUCCESS,
  PROMOTER_SO_PERFORMANCE_GET_ERROR,
  SO_PERFORMANCE_VS_TARGET_GET,
  SO_PERFORMANCE_VS_TARGET_GET_SUCCESS,
  SO_PERFORMANCE_VS_TARGET_GET_ERROR,
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET,
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET_SUCCESS,
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET_ERROR,
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET,
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET_SUCCESS,
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET_ERROR,
  PROMOTER_DEALER_PERFORMANCE_GET,
  PROMOTER_DEALER_PERFORMANCE_GET_SUCCESS,
  PROMOTER_DEALER_PERFORMANCE_GET_ERROR,
  EXPORT_PROMOTER_DEALER_PERFORMANCE,
  EXPORT_PROMOTER_DEALER_PERFORMANCE_SUCCESS,
  EXPORT_PROMOTER_DEALER_PERFORMANCE_ERROR,
  PROMOTER_OUTLET_PERFORMANCE_GET,
  PROMOTER_OUTLET_PERFORMANCE_GET_SUCCESS,
  PROMOTER_OUTLET_PERFORMANCE_GET_ERROR,
  EXPORT_PROMOTER_OUTLET_PERFORMANCE,
  EXPORT_PROMOTER_OUTLET_PERFORMANCE_SUCCESS,
  EXPORT_PROMOTER_OUTLET_PERFORMANCE_ERROR,
  PROMOTER_PERFORMANCE_GET,
  PROMOTER_PERFORMANCE_GET_SUCCESS,
  PROMOTER_PERFORMANCE_GET_ERROR,
  TOP_DEALERS_GET,
  TOP_DEALERS_GET_SUCCESS,
  TOP_DEALERS_GET_ERROR,
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY,
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_SUCCESS,
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_ERROR,
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT,
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_SUCCESS,
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_ERROR,
  EXPORT_TOP_DEALERS_WITH_FILTERS,
  EXPORT_TOP_DEALERS_WITH_FILTERS_SUCCESS,
  EXPORT_TOP_DEALERS_WITH_FILTERS_ERROR,
  EXPORT_PROMOTER_PERFORMANCE,
  EXPORT_PROMOTER_PERFORMANCE_SUCCESS,
  EXPORT_PROMOTER_PERFORMANCE_ERROR,
  EXPORT_PROMOTER_SO_PERFORMANCE,
  EXPORT_PROMOTER_SO_PERFORMANCE_SUCCESS,
  EXPORT_PROMOTER_SO_PERFORMANCE_ERROR,
  EXPORT_SO_PERFORMANCE_VS_TARGET,
  EXPORT_SO_PERFORMANCE_VS_TARGET_SUCCESS,
  EXPORT_SO_PERFORMANCE_VS_TARGET_ERROR,
  SO_PERFORMANCE_GET,
  SO_PERFORMANCE_GET_SUCCESS,
  SO_PERFORMANCE_GET_ERROR,
  SALESPERSON_PERFORMANCE_GET,
  SALESPERSON_PERFORMANCE_GET_SUCCESS,
  SALESPERSON_PERFORMANCE_GET_ERROR,
  PRODUCT_PERFORMANCE_GET,
  PRODUCT_PERFORMANCE_GET_SUCCESS,
  PRODUCT_PERFORMANCE_GET_ERROR,
  OUTLET_PERFORMANCE_GET,
  OUTLET_PERFORMANCE_GET_SUCCESS,
  OUTLET_PERFORMANCE_GET_ERROR,
  DASHBOARD_MERCHANDISER_VISITATION_GET,
  DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_VISITATION_GET_ERROR,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_ERROR,
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET,
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_ERROR,
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET,
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_ERROR,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_ERROR,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_ERROR,
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET,
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_SUCCESS,
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_ERROR,
  
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  data: [],
  status: "",
  hasLoadedOnce: false,
};

const mutations = {
  ACTUAL_SO_BY_SUB_REGION_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  ACTUAL_SO_BY_SUB_REGION_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  ACTUAL_SO_BY_SUB_REGION_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_ACTUAL_SO_BY_SUB_REGION: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_ACTUAL_SO_BY_SUB_REGION_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_ACTUAL_SO_BY_SUB_REGION_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SO_HEAT_MAP_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SO_HEAT_MAP_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SO_HEAT_MAP_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_SO_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_SO_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_SO_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SO_PERFORMANCE_VS_TARGET_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SO_PERFORMANCE_VS_TARGET_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SO_PERFORMANCE_VS_TARGET_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_BY_CATEGORY_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_BY_COMPONENT_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_DEALER_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_DEALER_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_DEALER_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_DEALER_PERFORMANCE: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PROMOTER_DEALER_PERFORMANCE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_DEALER_PERFORMANCE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_OUTLET_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_OUTLET_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_OUTLET_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_OUTLET_PERFORMANCE: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PROMOTER_OUTLET_PERFORMANCE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_OUTLET_PERFORMANCE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PROMOTER_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROMOTER_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PROMOTER_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_PERFORMANCE_SUCCESS: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PROMOTER_PERFORMANCE_SUCCESS_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_PERFORMANCE_SUCCESS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  TOP_DEALERS_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  TOP_DEALERS_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  TOP_DEALERS_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_TOP_DEALERS_WITH_FILTERS: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_TOP_DEALERS_WITH_FILTERS_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_TOP_DEALERS_WITH_FILTERS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_SO_PERFORMANCE: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_PROMOTER_SO_PERFORMANCE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_PROMOTER_SO_PERFORMANCE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_SO_PERFORMANCE_VS_TARGET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_SO_PERFORMANCE_VS_TARGET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_SO_PERFORMANCE_VS_TARGET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SO_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SO_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SO_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  SALESPERSON_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SALESPERSON_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  SALESPERSON_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PRODUCT_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  PRODUCT_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  OUTLET_PERFORMANCE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  OUTLET_PERFORMANCE_GET_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      data: data,
      hasLoadedOnce: true
    });
  },
  OUTLET_PERFORMANCE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      data: [],
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

const actions = {
  [ACTUAL_SO_BY_SUB_REGION_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(ACTUAL_SO_BY_SUB_REGION_GET);
      actualSObySubRegionGet(postData)
      .then(resp => {
        resp.content["action"] = ACTUAL_SO_BY_SUB_REGION_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(ACTUAL_SO_BY_SUB_REGION_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [SO_HEAT_MAP_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SO_HEAT_MAP_GET);
      soHeatMap(postData)
      .then(resp => {
        dispatch("dataFormattingSOHeatMap", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(SO_HEAT_MAP_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [PROMOTER_SO_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_SO_PERFORMANCE_GET);
      promoterSOperformance(postData)
      .then(resp => {
        resp.content["action"] = PROMOTER_SO_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PROMOTER_SO_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PROMOTER_SO_PERFORMANCE]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PROMOTER_SO_PERFORMANCE);
      exportPromoterSOperformance(postData)
      .then(resp => {
        commit(EXPORT_PROMOTER_SO_PERFORMANCE_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PROMOTER_SO_PERFORMANCE_ERROR, err);
        reject(err);
      });
    });
  },
  [SO_PERFORMANCE_VS_TARGET_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SO_PERFORMANCE_VS_TARGET_GET);
      soPerformanceVStarget(postData)
      .then(resp => {
        resp.content["action"] = SO_PERFORMANCE_VS_TARGET_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(SO_PERFORMANCE_VS_TARGET_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_SO_PERFORMANCE_VS_TARGET]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_SO_PERFORMANCE_VS_TARGET);
      exportSoPerformanceVStarget(postData)
      .then(resp => {
        commit(EXPORT_SO_PERFORMANCE_VS_TARGET_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_SO_PERFORMANCE_VS_TARGET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_ACTUAL_SO_BY_SUB_REGION]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_ACTUAL_SO_BY_SUB_REGION);
      exportActualSObySubRegion(postData)
      .then(resp => {
        commit(EXPORT_ACTUAL_SO_BY_SUB_REGION_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_ACTUAL_SO_BY_SUB_REGION_ERROR, err);
        reject(err);
      });
    });
  },
  [PRODUCT_PERFORMANCE_BY_CATEGORY_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_PERFORMANCE_BY_CATEGORY_GET);
      productPerformanceByCategory(postData)
      .then(resp => {
        resp.content["action"] = PRODUCT_PERFORMANCE_BY_CATEGORY_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PRODUCT_PERFORMANCE_BY_CATEGORY_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [PRODUCT_PERFORMANCE_BY_COMPONENT_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_PERFORMANCE_BY_COMPONENT_GET);
      productPerformanceByComponent(postData)
      .then(resp => {
        resp.content["action"] = PRODUCT_PERFORMANCE_BY_COMPONENT_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PRODUCT_PERFORMANCE_BY_COMPONENT_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [PROMOTER_DEALER_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_DEALER_PERFORMANCE_GET);
      promoterDealerPerformance(postData)
      .then(resp => {
        resp.content["action"] = PROMOTER_DEALER_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PROMOTER_DEALER_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PROMOTER_DEALER_PERFORMANCE]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PROMOTER_DEALER_PERFORMANCE);
      exportPromoterDealerPerformance(postData)
      .then(resp => {
        commit(EXPORT_PROMOTER_DEALER_PERFORMANCE_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PROMOTER_DEALER_PERFORMANCE_ERROR, err);
        reject(err);
      });
    });
  },
  [PROMOTER_OUTLET_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_OUTLET_PERFORMANCE_GET);
      promoterOutletPerformance(postData)
      .then(resp => {
        resp.content["action"] = PROMOTER_OUTLET_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PROMOTER_OUTLET_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PROMOTER_OUTLET_PERFORMANCE]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PROMOTER_OUTLET_PERFORMANCE);
      exportPromoterOutletPerformance(postData)
      .then(resp => {
        commit(EXPORT_PROMOTER_OUTLET_PERFORMANCE_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PROMOTER_OUTLET_PERFORMANCE_ERROR, err);
        reject(err);
      });
    });
  },
  [PROMOTER_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PROMOTER_PERFORMANCE_GET);
      promoterPerformance(postData)
      .then(resp => {
        resp.content["action"] = PROMOTER_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PROMOTER_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PROMOTER_PERFORMANCE]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PROMOTER_PERFORMANCE);
      exportPromoterPerformance(postData)
      .then(resp => {
        commit(EXPORT_PROMOTER_PERFORMANCE_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PROMOTER_PERFORMANCE_ERROR, err);
        reject(err);
      });
    });
  },
  [TOP_DEALERS_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(TOP_DEALERS_GET);
      topDealers(postData)
      .then(resp => {
        resp.content["action"] = TOP_DEALERS_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(TOP_DEALERS_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY);
      exportProductPerformanceByCategory(postData)
      .then(resp => {
        commit(EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PRODUCT_PERFORMANCE_BY_CATEGORY_ERROR, err);
        reject(err);
      });
    });
  },
  [EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT);
      exportProductPerformanceByComponent(postData)
      .then(resp => {
        commit(EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_PRODUCT_PERFORMANCE_BY_COMPONENT_ERROR, err);
        reject(err);
      });
    });
  },
  [SO_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SO_PERFORMANCE_GET);
      soPerformanceRequest(postData)
      .then(resp => {
        resp.content["action"] = SO_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(SO_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [SALESPERSON_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SALESPERSON_PERFORMANCE_GET);
      salespersonPerformanceRequest(postData)
      .then(resp => {
        resp.content["action"] = SALESPERSON_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(SALESPERSON_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [PRODUCT_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(PRODUCT_PERFORMANCE_GET);
      productPerformanceRequest(postData)
      .then(resp => {
        resp.content["action"] = PRODUCT_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(PRODUCT_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [OUTLET_PERFORMANCE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(OUTLET_PERFORMANCE_GET);
      outletPerformanceRequest(postData)
      .then(resp => {
        resp.content["action"] = OUTLET_PERFORMANCE_GET_SUCCESS;
        dispatch("dataFormatting", resp.content).then((res) => {
          resolve(res)
        })
      })
      .catch(err => {
        commit(OUTLET_PERFORMANCE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_VISITATION_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_VISITATION_GET);
      getMerchandiserVisitation(postData)
      .then(resp => {
        if(postData.filters.isExport) {
          commit(DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS, resp);
          resolve(resp);
        } else {
          resp.content["action"] = DASHBOARD_MERCHANDISER_VISITATION_GET_SUCCESS;
          dispatch("dataFormatting", resp.content).then((res) => {
            resolve(res)
          })
        }

      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_VISITATION_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_SCORE_GRADE_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_GET);
      getMerchandiserScoreGrade(postData)
      .then(resp => {
        if(postData.filters.isExport) {
          commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS, resp);
          resolve(resp);
        } else {
          resp.content["action"] = DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_SUCCESS;
          dispatch("dataFormatting", resp.content).then((res) => {
            resolve(res)
          })
        }
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET);
      getMerchandiserStoreVSCategory(postData)
      .then(resp => {
        commit(DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_SUCCESS, resp)
        resolve(resp)
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_STORE_VS_CATEGORY_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET);
      getMerchandiserVisitationSubRegion(postData)
      .then(resp => {
        if(postData.filters.isExport) {
          commit(DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS, resp);
          resolve(resp);
        } else {
          resp.content["action"] = DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_SUCCESS;
          dispatch("dataFormatting", resp.content).then((res) => {
            resolve(res)
          })
        }
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_VISITATION_SUB_REGION_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET);
      getMerchandiserScoreGradeSubRegion(postData)
      .then(resp => {
        if(postData.filters.isExport) {
          commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS, resp);
          resolve(resp);
        } else {
          resp.content["action"] = DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_SUCCESS;
          dispatch("dataFormatting", resp.content).then((res) => {
            resolve(res)
          })
        }
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_SUB_REGION_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET]: ({ commit, dispatch }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET);
      getMerchandiserScoreGradeByTopDealer(postData)
      .then(resp => {
        if(postData.filters.isExport) {
          commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS, resp);
          resolve(resp);
        } else {
          resp.content["action"] = DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_SUCCESS;
          dispatch("dataFormatting", resp.content).then((res) => {
            resolve(res)
          })
        }
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_SCORE_GRADE_BY_TOP_DEALER_GET_ERROR, err);
        reject(err);
      });
    });
  },
  [DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET);
      getMerchandiserLenovoVSCompetitor(postData)
      .then(resp => {
        commit(DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_SUCCESS, resp)
        resolve(resp)
      })
      .catch(err => {
        commit(DASHBOARD_MERCHANDISER_LENOVO_VS_COMPETITOR_GET_ERROR, err);
        reject(err);
      });
    });
  },
  
  [EXPORT_TOP_DEALERS_WITH_FILTERS]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_TOP_DEALERS_WITH_FILTERS);
      exportTopDealersWithFilters(postData)
      .then(resp => {
        commit(EXPORT_TOP_DEALERS_WITH_FILTERS_SUCCESS, resp.content)
        resolve(resp)
      })
      .catch(err => {
        commit(EXPORT_TOP_DEALERS_WITH_FILTERS_ERROR, err);
        reject(err);
      });
    });
  },
  dataFormattingSOHeatMap: async ({ commit }, postData) => {
    const localDashboardData = {
      name: postData.mainData.chartName,
      data: [postData.mainData.series].concat(postData.mainData.data),
      colorAxisColors: postData.mainData.options != null && postData.mainData.options.colorAxis != null ? postData.mainData.options.colorAxis.colors : null,
      colorAxisValues: postData.mainData.options != null && postData.mainData.options.colorAxis != null ? postData.mainData.options.colorAxis.values : null,
      customLegend: postData.mainData.customLegend ? postData.mainData.customLegend : null,
      seriesOptions: postData.mainData.options != null && postData.mainData.options.series != null ? postData.mainData.options.series : null,
      dataTable: postData.dataTable.heatmapDataEntries !== null ? postData.dataTable.heatmapDataEntries : null,
      colorTable: postData.colorTable.heatmapColorEntries !== null ? postData.colorTable.heatmapColorEntries : null
    };
    commit(SO_HEAT_MAP_GET_SUCCESS, localDashboardData);
    return localDashboardData;
  },
  dataFormatting: async ({ commit }, postData) => {
    const localDashboardData = {
      name: postData.chartName,
      data: [postData.series].concat(postData.data),
      colorAxisColors: postData.options != null && postData.options.colorAxis != null ? postData.options.colorAxis.colors : null,
      colorAxisValues: postData.options != null && postData.options.colorAxis != null ? postData.options.colorAxis.values : null,
      customLegend: postData.customLegend ? postData.customLegend : null,
      seriesOptions: postData.options != null && postData.options.series != null ? postData.options.series : null
    };
    commit(postData.action, localDashboardData);
    return localDashboardData;
  },
};

export default {
  state,
  actions,
  mutations
};
