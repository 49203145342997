import store from "@/store";
import PassThrough from "@/views/PassThrough";
import MediaHomePage from "@/views/media/home/list.vue";
import HomeDetailPage from "@/views/media/home/form.vue";
import PromotionPage from "@/views/media/promo/list.vue";
import PromotionDetailPage from "@/views/media/promo/form.vue";

import {
  ROLE_ADMIN,
  ROLE_CAP,
  ROLE_CAP_ADMIN,
  ROLE_CAP_MEDIA,
  ROLE_COUNTRY_ADMIN,
  LAYOUT_ADMIN,
  ROLE_COUNTRY_MEDIA
} from "../../constants";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/signin");
};

const mediaManagementRouter = {
  path: "/media-management",
  component: PassThrough,
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      ROLE_ADMIN,
      ROLE_CAP,
      ROLE_CAP_ADMIN,
      ROLE_CAP_MEDIA,
      ROLE_COUNTRY_ADMIN,
      ROLE_COUNTRY_MEDIA
    ]
  },
  children: [
    {
      path: "list",
      name: "Home",
      component: MediaHomePage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      },
    },
    {
      path: "list/form",
      name: "Home Details",
      component: HomeDetailPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      }
    },
    {
      path: "list/form/:id",
      name: "Home Details",
      component: HomeDetailPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      }
    },
    {
      path: "promo",
      name: "Promotion",
      component: PromotionPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      }
    },
    {
      path: "promo/form",
      name: "Promotion Details",
      component: PromotionDetailPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      }
    },
    {
      path: "promo/form/:id",
      name: "Promotion Details",
      component: PromotionDetailPage,
      beforeEnter: ifAuthenticated,
      meta: {
        permission: [
          ROLE_ADMIN,
          ROLE_CAP,
          ROLE_CAP_ADMIN,
          ROLE_CAP_MEDIA,
          ROLE_COUNTRY_ADMIN,
          ROLE_COUNTRY_MEDIA
        ]
      }
    },
  ],
  beforeEnter: ifAuthenticated
};

export default mediaManagementRouter;
