import {
  getBulkSaleList,
  uploadBulkSale,
  getUnknownSerialList,
  getSalesData,
  downloadBulkSaleById,
  updateSalesData,
  downloadSalesData,
  checkSerialNumber,
  sendEmail,
  exportSalesDataRequest,
  validateUnknownSerialNumberRequest,
  exportRetailBmsRequest
} from "@/api/sales";
import {
  GET_BULK_SALE_REQUEST,
  GET_BULK_SALE_SUCCESS,
  GET_BULK_SALE_ERROR,
  UPLOAD_BULK_SALE_REQUEST,
  UPLOAD_BULK_SALE_SUCCESS,
  UPLOAD_BULK_SALE_ERROR,
  UNKNOWN_SERIAL_REQUEST,
  UNKNOWN_SERIAL_SUCCESS,
  UNKNOWN_SERIAL_ERROR,
  GET_SALES_DATA_REQUEST,
  GET_SALES_DATA_SUCCESS,
  GET_SALES_DATA_ERROR,
  DOWNLOAD_BULK_SALE_REQUEST,
  DOWNLOAD_BULK_SALE_SUCCESS,
  DOWNLOAD_BULK_SALE_ERROR,
  UPDATE_SALES_DATA_REQUEST,
  UPDATE_SALES_DATA_SUCCESS,
  UPDATE_SALES_DATA_ERROR,
  DOWNLOAD_SALES_DATA_REQUEST,
  DOWNLOAD_SALES_DATA_SUCCESS,
  DOWNLOAD_SALES_DATA_ERROR,
  CHECK_SERIAL_REQUEST,
  CHECK_SERIAL_SUCCESS,
  CHECK_SERIAL_ERROR,
  SALES_DATA_SEND_EMAIL_REQUEST,
  SALES_DATA_SEND_EMAIL_SUCCESS,
  SALES_DATA_SEND_EMAIL_ERROR,
  EXPORT_SALES_DATA_REQUEST,
  EXPORT_SALES_DATA_SUCCESS,
  EXPORT_SALES_DATA_ERROR,
  VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST,
  VALIDATE_UNKNOWN_SERIAL_NUMBER_SUCCESS,
  VALIDATE_UNKNOWN_SERIAL_NUMBER_ERROR,
  DOWNLOAD_RETAIL_BMS_REQUEST,
  DOWNLOAD_RETAIL_BMS_SUCCESS,
  DOWNLOAD_RETAIL_BMS_ERROR
} from "./actions";

const state = {
  token: localStorage.getItem("user-token") || "",
  bulkSales: [],
  serialList: [],
  salesData: [],
  bulkSaleData: [],
  status: "",
  hasLoadedOnce: false
};

const getters = {
  bulkSales: state => state.sales
};

const actions = {
  [GET_BULK_SALE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_BULK_SALE_REQUEST);
      getBulkSaleList(postData)
        .then(resp => {
          commit(GET_BULK_SALE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_BULK_SALE_ERROR, err);
          reject(err);
        });
    });
  },
  [UPLOAD_BULK_SALE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_BULK_SALE_REQUEST);
      uploadBulkSale(postData)
        .then(resp => {
          commit(UPLOAD_BULK_SALE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPLOAD_BULK_SALE_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_BULK_SALE_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_BULK_SALE_REQUEST);
      downloadBulkSaleById(postData)
        .then(resp => {
          commit(DOWNLOAD_BULK_SALE_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_BULK_SALE_ERROR, err);
          reject(err);
        });
    });
  },
  [UNKNOWN_SERIAL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UNKNOWN_SERIAL_REQUEST);
      getUnknownSerialList(postData)
        .then(resp => {
          commit(UNKNOWN_SERIAL_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UNKNOWN_SERIAL_ERROR, err);
          reject(err);
        });
    });
  },
  [GET_SALES_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(GET_SALES_DATA_REQUEST);
      getSalesData(postData)
        .then(resp => {
          commit(GET_SALES_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(GET_SALES_DATA_ERROR, err);
          reject(err);
        });
    });
  },
  [UPDATE_SALES_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_SALES_DATA_REQUEST);
      updateSalesData(postData)
        .then(resp => {
          commit(UPDATE_SALES_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(UPDATE_SALES_DATA_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_SALES_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_SALES_DATA_REQUEST);
      downloadSalesData(postData)
        .then(resp => {
          commit(DOWNLOAD_SALES_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_SALES_DATA_ERROR, err);
          reject(err);
        });
    });
  },
  [DOWNLOAD_RETAIL_BMS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(DOWNLOAD_RETAIL_BMS_REQUEST);
      exportRetailBmsRequest(postData)
        .then(resp => {
          commit(DOWNLOAD_RETAIL_BMS_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(DOWNLOAD_RETAIL_BMS_ERROR, err);
          reject(err);
        });
    });
  },
  [CHECK_SERIAL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(CHECK_SERIAL_REQUEST);
      checkSerialNumber(postData)
        .then(resp => {
          commit(CHECK_SERIAL_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(CHECK_SERIAL_ERROR, err);
          reject(err);
        });
    });
  },
  [SALES_DATA_SEND_EMAIL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(SALES_DATA_SEND_EMAIL_REQUEST);
      sendEmail(postData)
        .then(resp => {
          commit(SALES_DATA_SEND_EMAIL_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SALES_DATA_SEND_EMAIL_ERROR, err);
          reject(err);
        });
    });
  },
  [EXPORT_SALES_DATA_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(EXPORT_SALES_DATA_REQUEST);
      exportSalesDataRequest(postData)
        .then(resp => {
          commit(EXPORT_SALES_DATA_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(EXPORT_SALES_DATA_ERROR, err);
          reject(err);
        });
    });
  },
  [VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST);
      validateUnknownSerialNumberRequest(postData)
        .then(resp => {
          commit(VALIDATE_UNKNOWN_SERIAL_NUMBER_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(VALIDATE_UNKNOWN_SERIAL_NUMBER_ERROR, err);
          reject(err);
        });
    });
  }
};

const mutations = {
  GET_BULK_SALE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_BULK_SALE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      sales: data,
      hasLoadedOnce: true
    });
  },
  GET_BULK_SALE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      sales: [],
      hasLoadedOnce: true
    });
  },
  UPLOAD_BULK_SALE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPLOAD_BULK_SALE_SUCCESS: (state, data) => {
    Object.assign(state, {
      state: "",
      sales: data,
      hasLoadedOnce: true
    });
  },
  UPLOAD_BULK_SALE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      sales: [],
      hasLoadedOnce: true
    });
  },
  UNKNOWN_SERIAL_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UNKNOWN_SERIAL_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      serialList: data,
      hasLoadedOnce: true
    });
  },
  UNKNOWN_SERIAL_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialList: [],
      hasLoadedOnce: true
    });
  },
  GET_SALES_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  GET_SALES_DATA_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      salesData: data,
      hasLoadedOnce: true
    });
  },
  GET_SALES_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      salesData: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_BULK_SALE_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_BULK_SALE_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      bulkSaleData: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_BULK_SALE_ERROR: state => {
    Object.assign(state, {
      status: "error",
      bulkSaleData: [],
      hasLoadedOnce: true
    });
  },
  UPDATE_SALES_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  UPDATE_SALES_DATA_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      salesData: data,
      hasLoadedOnce: true
    });
  },
  UPDATE_SALES_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      salesData: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_SALES_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_SALES_DATA_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      salesData: data,
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_SALES_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialList: [],
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_RETAIL_BMS_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  DOWNLOAD_RETAIL_BMS_SUCCESS: (state) => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  DOWNLOAD_RETAIL_BMS_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  CHECK_SERIAL_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  CHECK_SERIAL_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      serialList: data,
      hasLoadedOnce: true
    });
  },
  CHECK_SERIAL_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialData: [],
      hasLoadedOnce: true
    });
  },
  EXPORT_SALES_DATA_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  EXPORT_SALES_DATA_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      serialList: data,
      hasLoadedOnce: true
    });
  },
  EXPORT_SALES_DATA_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialData: [],
      hasLoadedOnce: true
    });
  },
  SALES_DATA_SEND_EMAIL_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  SALES_DATA_SEND_EMAIL_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      serialList: data,
      hasLoadedOnce: true
    });
  },
  SALES_DATA_SEND_EMAIL_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialData: [],
      hasLoadedOnce: true
    });
  },
  VALIDATE_UNKNOWN_SERIAL_NUMBER_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  VALIDATE_UNKNOWN_SERIAL_NUMBER_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      serialList: data,
      hasLoadedOnce: true
    });
  },
  VALIDATE_UNKNOWN_SERIAL_NUMBER_ERROR: state => {
    Object.assign(state, {
      status: "error",
      serialData: [],
      hasLoadedOnce: true
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
