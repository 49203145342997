<template>
  <div>
    <breadcrumb title="Dashboard" subtitle="Salesperson Incentive" />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="isCountryAdmin || isGLNCountryAdmin" class="row">
              <div
                class="col-lg-12 add-employee-button d-flex align-items-center mb-4 flex-row-reverse"
              >
                <router-link to="/dashboard/incentive/salesperson-normal/add">
                  <span
                    class="btn btn-primary d-flex align-items-center"
                  >
                    <i class="fa fa-upload mr-2"></i>
                    Add 
                  </span>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <VueBootstrap4Table
                  :classes="classes"
                  :rows="incentiveLists"
                  :columns="columns"
                  :config="config"
                  :total-rows="total"
                  :show-loader="true"
                  @refresh-data="getIncentiveLists"
                >
                  <template slot="sort-asc-icon">
                    <i class="mdi mdi-sort-ascending"></i>
                  </template>
                  <template slot="sort-desc-icon">
                    <i class="mdi mdi-sort-descending"></i>
                  </template>
                  <template slot="no-sort-icon">
                    <i class="mdi mdi-sort-variant"></i>
                  </template>
                  <template slot="empty-results">
                    <div
                      v-if="!incentiveListIsLoading"
                      class="bold-text"
                    >No Incentive Programs Found!</div>
                    <div
                      v-if="incentiveListIsLoading"
                      class="loading mt-3"
                    ></div>
                  </template>
                  
                  <template v-if="!isCountryView" slot="edit" slot-scope="props">
                    <router-link :to="{ name :'Edit Salesperson/Dealer Incentive', params: { program : props.row.id} }">
                      <div class="m-auto" style="width: fit-content">
                        <span
                          class="btn btn-warning d-flex align-items-center"
                        >
                          <i class="fa fa-edit"></i>
                          Edit 
                        </span>
                      </div>
                    </router-link>
                  </template>
                </VueBootstrap4Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <BModal
      ref="incentive-normal-salesperson-modal-add"
      size="lg"
      hide-footer
      :title="selectedIncentiveProgram.id ? 'Edit Incentive List' : 'Add Incentive List'"
    >
    </BModal> -->
  </div>
</template>
<script>

import breadcrumb from "../../components/breadcrumb/breadcrumb";
// import { BModal } from "bootstrap-vue";
import VueBootstrap4Table from "../../components/ui/VueBootstrap4Table";
import { INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST } from "../../store/modules/new-incentive/action";

export default {
  name: "SalespersonIncentive",
  components: {
    breadcrumb,
    // BModal,
    VueBootstrap4Table: VueBootstrap4Table,

  },
  data(){
    return{
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Promoter Normal Incentives",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 5,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "name",
          label: "Incentive Name",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "startDate",
          label: "Start Date",
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "endDate",
          label: "End Date",
          width: "20%",
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "edit",
          label: "Action",
          width: "15%",
          row_text_alignment: "text-center",
          column_text_alignment: "text-left"
        }
      ],
      total: 0,
      incentiveLists: [],
      incentiveListIsLoading: false,
      selectedIncentiveProgram: {},
      isCountryAdmin: false,
      isGLNCountryAdmin: false,
      isCountryView: false
    }
  },
  methods: {
    getIncentiveLists(){
      this.incentiveListIsLoading = true;
      this.$store.dispatch(INCENTIVE_NORMAL_SALESPERSON_LIST_REQUEST).then(res => {
        if(res.content && res.resultCode === 0){
          this.incentiveLists = res.content;
        }
      });
      this.incentiveListIsLoading = false;
    },
  },
  mounted() {
    this.isCountryAdmin = this.$store.getters.isCountryAdmin;
    this.isGLNCountryAdmin = this.$store.getters.isGLNCountryAdmin;
    this.isCountryView = this.$store.getters.isCountryView;
    this.getIncentiveLists();
  }
}
</script>