<template>
  <div id="country-dashboard">
    <country-breadcrumb
      title="Dashboard"
      :subtitle="
        !country ? `Country ${this.$store.getters.getCountryCode} Dashboard` : `Country Dashboard`
      "
    />
    <div class="d-flex flex-column">
      <div class="d-flex d-xl-none justify-content-center">
        <div class="card mt-3 ml-3 mr-3 mb-1 rounded-0 border-primary w-100">
          <div class="card-body w-100 p-4">
            <b-tabs position="is-centered" class="block w-100">
              <b-tab-item label="TD">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      {{ topRowData ? topRowData.totalDealers : "--" }}
                    </div>
                    <div class="text-primary h5 whitespace-no-wrap">
                      Total Dealers
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="TIP">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      <span>$</span>
                      {{ abbreviateNumber(topRowData ? topRowData.totalIncentivePaid : "--") }}
                    </div>
                    <div class="text-primary h5 whitespace-no-wrap">
                      Total Commission Payable
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="TP">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      {{ abbreviateNumber(topRowData ? topRowData.totalPromoters : "--", true) }}
                    </div>
                    <div class="text-primary h5 whitespace-no-wrap">
                      Total Promoters
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="TSA">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      <span>$</span>
                      {{ abbreviateNumber(topRowData ? topRowData.totalSalesAmount : "--") }}
                    </div>
                    <div class="text-primary h5 whitespace-no-wrap">
                      Total Sales Amount
                    </div>
                    <div class="text-primary h4 whitespace-no-wrap">
                      Target: ${{ abbreviateNumber(topRowData ? topRowData.salesAmountTarget : "--") }}
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="TSU">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      {{ abbreviateNumber(topRowData ? topRowData.totalSalesUnit : "--", true) }}
                    </div>
                    <div class="text-primary h5 whitespace-no-wrap">
                      Total Sales Unit
                    </div>
                    <div class="text-primary h4 whitespace-no-wrap">
                      Target:
                      {{ abbreviateNumber(topRowData ? topRowData.salesUnitTarget : "--", true) }}
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="TSPA">
                <div class="m-3 rounded-0 border-primary">
                  <div
                    class="text-center d-flex align-items-center justify-content-center flex-column"
                  >
                    <div class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap">
                      {{ abbreviateNumber(topRowData ? topRowData.totalPendingApproval : "--", true) }}
                    </div>
                    <div class="text-primary h5 mb-3">
                      Total Salesperson Pending Approval
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
      <div class="row lnv-main-card">
        <div class="col-lg-12">
          <div class="card rounded-0">
            <div class="card-body">
              <b-tabs v-model="activeTab">
                <div
                  class="d-flex flex-wrap align-items-center justify-content-end mt-3 ml-3 mr-3 mb-1 "
                >
                  <div v-if="filters.countrySelected === 'PH'" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="promoterType"
                      v-model="filters.promoterTypeSelected"
                      :searchable="false"
                      :options="promoterTypes"
                      label="name"
                      track-by="value"
                      :loading="!promoterTypes"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="year"
                      v-model="filters.yearSelected"
                      :searchable="false"
                      :options="yearOptions"
                      label="name"
                      track-by="value"
                      :loading="!yearOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div v-if="activeTab === 1" class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="quarter"
                      v-model="filters.quarterSelected"
                      :searchable="false"
                      :options="quarterOptions"
                      label="name"
                      track-by="value"
                      :loading="!quarterOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="unit"
                      v-model="filters.unitSelected"
                      :searchable="false"
                      :options="unitOptions"
                      label="name"
                      track-by="value"
                      :loading="!unitOptions"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                  <div class="ml-4 mb-3 mt-1">
                    <Multiselect
                      ref="currency"
                      v-model="filters.currencySelected"
                      :searchable="false"
                      :options="currencyOptions"
                      label="name"
                      track-by="value"
                      :loading="!currencyOptions"
                      :allow-empty="false"
                      :disabled="!unitCheck"
                      :show-labels="false"
                    ></Multiselect>
                  </div>
                </div>
                <div class="d-none d-xl-flex mt-3 ml-3 mr-3 mb-1 justify-content-center">
                  <div class="card rounded-0 border-primary w-100 mb-0">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalDealers : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Dealers
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        <span>$</span>
                        {{ abbreviateNumber(topRowData ? topRowData.totalIncentivePaid : "--") }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Incentive Paid
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalPromoters : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Promoters
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        <span>$</span>
                        {{ abbreviateNumber(topRowData ? topRowData.totalSalesAmount : "--") }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Sales Amount
                      </div>
                      <div class="text-primary h4 whitespace-no-wrap">
                        Target: ${{
                          abbreviateNumber(topRowData ? topRowData.salesAmountTarget : "--")
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{ abbreviateNumber(topRowData ? topRowData.totalSalesUnit : "--", true) }}
                      </div>
                      <div class="text-primary h5 mb-3 whitespace-no-wrap">
                        Total Sales Unit
                      </div>
                      <div class="text-primary h4 whitespace-no-wrap">
                        Target:
                        {{ abbreviateNumber(topRowData ? topRowData.salesUnitTarget : "--", true) }}
                      </div>
                    </div>
                  </div>
                  <div class="card rounded-0 border-primary w-100 mb-0 ml-3">
                    <div
                      class="card-body text-center p-4 d-flex align-items-center justify-content-center flex-column"
                    >
                      <div
                        class="text-success font-weight-normal display-5 mb-1 whitespace-no-wrap"
                      >
                        {{
                          abbreviateNumber(topRowData ? topRowData.totalPendingApproval : "--", true)
                        }}
                      </div>
                      <div class="text-primary h5 mb-3">
                        Total Pending Approval
                      </div>
                    </div>
                  </div>
                </div>
                <b-tab-item label="Year to Date">
                  <CountryYearToDate
                    v-if="activeTab === 0"
                    ref="country-year"
                    :filters.sync="filters"
                    :country.sync="countrySelectedComputed"
                  ></CountryYearToDate>
                </b-tab-item>
                <b-tab-item label="Quarter">
                  <CountryQuarter
                    v-if="activeTab === 1"
                    ref="country-quarter"
                    :filters.sync="quarterFilters"
                    :country.sync="countrySelectedComputed"
                  ></CountryQuarter>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import { CDASHBOARD_TOP_FILTERS_GET } from "../../store/modules/dashboard/actions";
import Multiselect from "vue-multiselect";
import CountryQuarter from "./country_quarter";
import CountryYearToDate from "./country_year";
import {COUNTRY_LIST_REQUEST} from "@/store/modules/utility/actions";
import { defaultFiscalYear, defaultQuarter } from '../../utils/helpers';
import $ from "jquery";
import { generalMixin } from "@/utils/generalMixin";

export default {
  name: "Country",
  components: {
    "country-breadcrumb": breadcrumb,
    CountryQuarter,
    CountryYearToDate,
    Multiselect
  },
  mixins: [generalMixin],
  computed: {
    country() {
      const id = this.$store.getters.getUserRole;
      if (id == "CAP" || id == "CAP_Admin") {
        return true;
      }
      return false;
    },
    unitCheck() {
      return this.filters.unitSelected.value == "amount";
    },
    topRowData() {
      return this.$store.getters.dashboardData.topRowData;
    },
    countrySelectedComputed() {
      return this.$store.getters.topFilterCountryData;
    },
    quarterFilters() {
      if (this.filters.quarterSelected) {
        return this.filters;
      } else {
        let tempFilters = this.filters;
        tempFilters.quarterSelected = {name: this.defaultSelectedPeriod.quarter || defaultQuarter(), value: this.defaultSelectedPeriod.quarter || defaultQuarter()};
        return tempFilters;
      }
    }
  },
  data() {
    return {
      ticket: "",
      activeTab: 1,
      yearOptions: [],
      quarterOptions: [],
      unitOptions: [],
      currencyOptions: [],
      promoterTypes: [],
      countries: [],
      filters: {
        countrySelected: this.$store.getters.getCountryCode,
        countryIdSelected: "",
        countryTextSelected: "",
        yearSelected: { name: defaultFiscalYear(), value: defaultFiscalYear() },
        quarterSelected: { name: defaultQuarter(), value: defaultQuarter() },
        unitSelected: { name: "Unit", value: "unit" },
        currencySelected: { name: "Local", value: "local" },
        promoterTypeSelected: {name: "PC Promoter", value: "PT01"}
      }
    };
  },
  methods: {
    abbreviateNumber(number) {
      if (number) {
        let SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        let tier = (Math.log10(number) / 3) | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        let suffix = SI_SYMBOL[tier];
        let scale = Math.pow(10, tier * 3);

        // scale the number
        let scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(2) + suffix;
      } else {
        return 0;
      }
    },
    async getFilters() {
      const res = await this.$store.dispatch(CDASHBOARD_TOP_FILTERS_GET);
      if (res.content) {
        this.yearOptions = await res.content.fiscalYearDropdown;
        this.quarterOptions = await res.content.quarterDropdown;
        this.currencyOptions = await res.content.currencyDropdown;
        this.unitOptions = await res.content.typeDropdown;
        this.promoterTypes = [
          {name: "PC Promoter", value: "PT01"},
          {name: "Tablet Promoter", value: "PT03"}
        ]
      }
    },
    getCountryId() {
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          this.countries = res.content;
          this.countries.filter(country => {
            if(country.code === this.$store.getters.getCountryCode) {
              this.filters.countryIdSelected = country.id;
              this.filters.countryTextSelected = country.text;
            }
          });
        }
      });
    },
    addEvent: function(){
      let reference = this.$refs;
      $(".nav-header .nav-control .hamburger").on("click", function(){
        let countryQuarter = reference["country-quarter"];
        let countryYear = reference["country-year"];
        if(document.querySelector("#country-year")){
          countryYear.getDashboardData();
        } else if(document.querySelector("#country-quarter")){
          countryQuarter.getDashboardData();
        }
      }); 
    }
  },
  mounted() {
    this.getCountryId();
    this.getFilters();
    this.addEvent();
  }
};
</script>
<style lang="scss">
#country-dashboard {
  .card {
    box-shadow: none !important;
  }
  .card:hover {
    box-shadow: none !important;
  }
  .page-titles {
    margin-bottom: 0;
  }
  .tabs li {
    padding: 0 0.5em;
  }
  .tab-content {
    padding: 0;
    .tab-item {
      display: flex;
      justify-content: center;
      height: auto !important;
      align-items: center;
    }
  }
  .multiselect__option--selected:after {
    display: none;
  }
  .multiselect__input,
  .multiselect__single {
    background: inherit;
  }
  .multiselect {
    min-width: 7em;
  }
}
</style>
