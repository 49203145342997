<template>
  <div id="radar-chart">
    <VueApexCharts
      ref="radarChart" 
      :height="height" 
      :width="width"
      :options="options" 
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: { type: Array, default: () => [] },

    height: { type: String, default: "100%" },
    width: { type: String, default: "100%" },
    radarSize: { type: Number, default: 200 },
    polygonFillColorsArray: { type: Array, default: () => ['#FFFFFFFF', '#CCFFFFFF', '#99FFFFFF', '#66FFFFFF', '#33FFFFFF'] },
    polygonStrokeColor: { type: Array, default: () => ['#000000', '#000000', '#000000', '#000000', '#000000']},
    chartTitle: { type: String, default: 'Radar with Polygon Fill'},
    seriesFillOpacity: { type: Number, default: 0.5 },
    seriesStrokeColorsArray: { type: Array, default: () => ['red', 'blue', 'green']},
    tooltipValueFormatter: { type: Function, default: function(value) { return value } },
    customTooltip: { type: Function, default: function(value) { return value } },
    categoriesArray: { type: Array, default: () => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] },
    yAxisPrecisionNumber: { type: Number, default: 7 },
    yMax: { type: Number, default: 100},

  },
  computed: {
    options() {
      return {
        chart: { type: 'radar' },
        plotOptions: {
          radar: {
            size: this.radarSize,
            polygons: {
              strokeColors: this.polygonStrokeColor,
              fill: { colors: this.polygonFillColorsArray }
            }
          }
        },
        fill: {
          opacity: this.seriesFillOpacity,
          color: this.seriesStrokeColorsArray,
        },
        title: { text: this.chartTitle },
        colors: this.seriesStrokeColorsArray,
        markers: {
          size: 2,
          colors: this.seriesStrokeColorsArray,
          strokeColor: this.seriesStrokeColorsArray,
          strokeWidth: 1,
        },
        tooltip: {
          y: { formatter: this.tooltipValueFormatter },
          custom: this.customTooltip
        },
        xaxis: { categories: this.categoriesArray },
        yaxis: {
          tickAmount: this.yAxisPrecisionNumber,
          min: 0,
          max: this.yMax,
          labels: {
            formatter: function(val, i) {
              if (i % 2 === 0) return val
              else return ''
            },
            offsetX: -10
          },
        },
        legend: { 
          horizontalAlign: 'left',
          onItemClick: {
            toggleDataSeries: false
          },
        },
      }
    },
  },
  watch: {
    series: {
      handler(value) { this.$refs.radarChart.updateSeries(value) },
      deep: true
    }    
  },
  mounted() {
  }
};
</script>
<style lang="scss">
#radar-chart {
  position: relative;
  .apexcharts-legend {
    border-top: 1px solid #DDDFE1;
    margin-top: 1rem;
    padding-top: 1rem !important;
  }
  .apexcharts-toolbar { display: none !important }

  .apexcharts-pie-label, .apexcharts-datalabels, .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value {
    font-size: 1.4rem;
  }
}
</style>
